import styled from 'styled-components';

export const ComponentCreatorWrapper = styled.div`
    position: absolute;
    bottom: 28px;
    right: 28px;

    .MuiFab-root {
        border-radius: ${({ theme }) => theme.borderRadius.large};
    }
`;
