import { useState } from 'react';

import {
    CheckboxContainer,
    HiddenCheckbox,
    StyledCheckbox,
    Icon,
    CheckboxItemLabel,
    CheckboxItemWrapper,
} from './CheckboxItem.styled';

export type CheckboxProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange'
> & {
    defaultChecked?: boolean;
};

export const CheckboxItem = (props: CheckboxProps): JSX.Element => {
    const { children, defaultChecked = false, ...rest } = props;

    const [isChecked, setIsChecked] = useState(defaultChecked);

    return (
        <CheckboxItemWrapper data-checked={isChecked} {...rest}>
            <CheckboxItemLabel>
                <CheckboxContainer>
                    <HiddenCheckbox
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                    />
                    <StyledCheckbox checked={isChecked}>
                        <Icon viewBox="0 0 24 24">
                            <polyline points="20 6 9 17 4 12" />
                        </Icon>
                    </StyledCheckbox>
                </CheckboxContainer>
                {children}
            </CheckboxItemLabel>
        </CheckboxItemWrapper>
    );
};
