import { useCallback, useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import useAxios from 'hooks/api/useAxios';
import { PageRetrieve } from 'types/api/pageRetrieve';
import { PageMapConfig } from 'types/builder/pageConfig';
import { QuizQuestion } from 'types/builder/quizConfig';
import { addKeyToConfig } from 'utils/builder';

type UseGetPageConfigReturn = {
    page?: PageMapConfig;
    loading: boolean;
    error: false | AxiosError<unknown, unknown>;
    getPage: () => void;
};

export const useGetPageConfig = (
    id?: number,
    builder = true
): UseGetPageConfigReturn => {
    const [page, setPage] = useState<PageMapConfig>();

    useEffect(() => {
        setPage(undefined);
    }, [id]);

    const { enqueueSnackbar } = useSnackbar();

    const [res, { rerun }] = useAxios<PageRetrieve>(
        {
            url: `/api${builder ? '/builder' : ''}/page/info/${id}/`,
            method: 'GET',
            skipRequest: () => id === undefined,
        },
        [id, builder]
    );

    const getPage = useCallback((): void => {
        setPage(undefined);
        rerun();
    }, [rerun]);

    const loading = res.type === 'loading';
    const error = res.type === 'error' && res.data;

    useEffect(() => {
        setPage(undefined);
    }, [error]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (error) {
                getPage();
            }
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
    }, [error, getPage]);

    useEffect(() => {
        if (error && builder) {
            enqueueSnackbar(
                'Что-то пошло не так... Не удалось получить данные о странице',
                { variant: 'error', key: 'page-config-error' }
            );
        }
    }, [error, builder, enqueueSnackbar]);

    useEffect(() => {
        if (res.type === 'success') {
            const pageRes = res.data;

            setPage({
                uuid: pageRes.uuid,
                id: pageRes.id,
                baseRoute: pageRes.route_uuid,
                pageTitle: pageRes.title,
                description: pageRes.description,
                showBottomNavLink: pageRes.show_bottom_navLink,
                showChatButton: pageRes.show_chat_button,
                showNavBar: pageRes.show_nav_bar,
                showTopAppBar: pageRes.show_top_app_bar,
                isPublished: pageRes.is_published,
                path: pageRes.path,
                ...(pageRes.type === 'PAGE'
                    ? {
                          type: 'PAGE',
                          pageConfig: addKeyToConfig(pageRes.config),
                      }
                    : {
                          type: 'QUIZ',
                          quizConfig: pageRes.config as QuizQuestion,
                      }),
            });
        }
    }, [res.type, res.data]);

    return { page, loading, error, getPage };
};
