import styled from 'styled-components';

export const StyledPlaceholder = styled.div`
    border: 2px dashed ${({ theme }) => theme.palette.red};
    padding: 10px;

    ${({ theme }) => theme.typography.title.medium};

    color: ${({ theme }) => theme.palette.red};
`;
