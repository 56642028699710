import { useCallback, useEffect, useMemo } from 'react';

import { useSnackbar } from 'notistack';

import { useMeta } from 'hooks/api/useMeta';
import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { setStatePoints } from 'store/slices/pointBuilderSlice';
import { SelectedLocation } from 'types/map/location';

export type UsePointsListReturn = {
    list: SelectedLocation[] | undefined;
    loading: boolean;
    error: boolean;
    reloadLocations: () => void;
};

export const usePointsList = (): UsePointsListReturn => {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const points = useAppSelector((state) => state.pointBuilder.points);

    const setPointsList = useCallback(
        (list?: SelectedLocation[]) => {
            dispatch(setStatePoints({ list }));
        },
        [dispatch]
    );

    const {
        meta: locations,
        error: locationsError,
        refresh: getLocations,
        loading: locationsLoading,
    } = useMeta('adminLocations');
    const {
        meta: buildings,
        error: buildingsError,
        loading: buildingsLoading,
    } = useMeta('adminBuildings');
    const {
        meta: floors,
        error: floorsError,
        loading: floorsLoading,
    } = useMeta('adminFloors');

    const error = useMemo(
        () => locationsError || buildingsError || floorsError,
        [buildingsError, floorsError, locationsError]
    );

    const loading = useMemo(
        () => floorsLoading || locationsLoading || buildingsLoading,
        [buildingsLoading, floorsLoading, locationsLoading]
    );

    const reloadLocations = useCallback(() => {
        setPointsList(undefined);
        getLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLocations]);

    useEffect(() => {
        dispatch(setStatePoints({ loading }));
    }, [dispatch, loading]);

    useEffect(() => {
        if (error) {
            dispatch(setStatePoints({ error: true }));
            enqueueSnackbar(
                'Что-то пошло не так... Не удалось получить список точек интереса.',
                { variant: 'error', key: 'locations-list-error' }
            );
        } else {
            dispatch(setStatePoints({ error: false }));
        }
    }, [error, enqueueSnackbar, dispatch]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (error) {
                reloadLocations();
            }
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (locations && floors && buildings && !points.list) {
            setPointsList(
                locations.map((el) => ({
                    ...el,
                    floor: floors.find((floor) => floor.id === el.floor),
                    building: buildings.find(
                        (building) => building.id === el.building
                    ),
                }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations, floors, buildings]);

    return {
        list: points.list,
        loading: points.loading || loading,
        error: points.error || error,
        reloadLocations,
    };
};
