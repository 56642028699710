import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SplashShapeWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SplashShapeElement = styled(motion.div)`
    position: absolute;
    z-index: 1;
`;

export const HumansWrapper = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }
`;

export const SplashBlurryEllipse = styled.div`
    background-color: rgba(255, 255, 255, 0.5);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        backdrop-filter: blur(8px);
    }
`;
