import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import {
    ButtonGroup,
    FormControlLabel,
    Button,
    Switch,
    Stack,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';

import { UseUndoRedoReturn } from 'hooks/useUndoRedo';

import { PreviewSizes } from '../types';
import { PreviewSizeWrapper } from './PreviewControl.styled';

type PreviewControlsProps = {
    previewSize: PreviewSizes;
    showBorder: boolean;
    onShowBorderChange: (show: boolean) => void;
    onPreviewSizeChange: (size: PreviewSizes) => void;
    undoRedoActions: UseUndoRedoReturn['2'];
};

export const PreviewControls = ({
    previewSize,
    onPreviewSizeChange,
    onShowBorderChange,
    showBorder,
    undoRedoActions: { canRedo, canUndo, redo, undo },
}: PreviewControlsProps): JSX.Element => {
    return (
        <>
            <PreviewSizeWrapper>
                <div className="size-buttons">
                    <ButtonGroup variant="outlined" size="small">
                        <Button
                            variant={
                                previewSize === 'phone-sm'
                                    ? 'contained'
                                    : undefined
                            }
                            onClick={() => onPreviewSizeChange('phone-sm')}
                        >
                            Мини-смартфон
                        </Button>
                        <Button
                            variant={
                                previewSize === 'phone'
                                    ? 'contained'
                                    : undefined
                            }
                            onClick={() => onPreviewSizeChange('phone')}
                        >
                            Смартфон
                        </Button>
                        <Button
                            variant={
                                previewSize === 'phone-lg'
                                    ? 'contained'
                                    : undefined
                            }
                            onClick={() => onPreviewSizeChange('phone-lg')}
                        >
                            Смартфон-плюс
                        </Button>
                        <Button
                            variant={
                                previewSize === 'tablet'
                                    ? 'contained'
                                    : undefined
                            }
                            onClick={() => onPreviewSizeChange('tablet')}
                        >
                            Планшет
                        </Button>
                    </ButtonGroup>
                </div>
                <div className="size-select">
                    <FormControl fullWidth>
                        <InputLabel id="size-select">Размер окна</InputLabel>
                        <Select
                            value={previewSize}
                            labelId="size-select"
                            size="small"
                            color="primary"
                            label="Размер окна"
                            fullWidth
                            sx={{ '.MuiSelect-select': { py: 0.5 } }}
                        >
                            <MenuItem
                                value="phone-sm"
                                onClick={() => onPreviewSizeChange('phone-sm')}
                            >
                                Мини-смартфон
                            </MenuItem>
                            <MenuItem
                                value="phone"
                                onClick={() => onPreviewSizeChange('phone')}
                            >
                                Смартфон
                            </MenuItem>
                            <MenuItem
                                value="phone-lg"
                                onClick={() => onPreviewSizeChange('phone-lg')}
                            >
                                Смартфон-плюс
                            </MenuItem>
                            <MenuItem
                                value="tablet"
                                onClick={() => onPreviewSizeChange('tablet')}
                            >
                                Планшет
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </PreviewSizeWrapper>
            <Stack spacing={2} direction="row">
                <FormControlLabel
                    control={<Switch />}
                    checked={showBorder}
                    onChange={(e, checked) => onShowBorderChange(checked)}
                    label="Рамка"
                    labelPlacement="start"
                />
                <ButtonGroup variant="outlined" size="small">
                    <Button disabled={!canUndo} onClick={() => undo()}>
                        <UndoIcon />
                    </Button>
                    <Button disabled={!canRedo} onClick={() => redo()}>
                        <RedoIcon />
                    </Button>
                </ButtonGroup>
            </Stack>
        </>
    );
};
