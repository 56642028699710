import { Button, Stack } from '@mui/material';

import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useNewLocationSelector } from 'hooks/api/builder/points/useNewLocationSelector';

export const PointSelectToolBar = (): JSX.Element => {
    const { resetAll } = useNewLocationSelector();

    return (
        <Stack
            spacing={3}
            sx={{ flex: 1, p: '28px', svg: { width: 50, height: 50 } }}
            alignItems="center"
            justifyContent="center"
        >
            <SVGAsset src="icons.24px.mapPin" />
            <Typography variant="title.large" textAlign="center">
                Выберите точку (помещение) на карте
            </Typography>

            <Button variant="outlined" onClick={() => resetAll()}>
                Отмена
            </Button>
        </Stack>
    );
};
