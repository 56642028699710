import { HomePage } from 'components/pages/HomePage';
import { MapPage } from 'components/pages/MapPage';
import { PharmacyPage } from 'components/pages/PharmacyPage';
import { PageMapConfig } from 'types/builder/pageConfig';

export const BASE_ROUTES = {
    home: '/home',
    services: '/services',
    map: '/map',
    pharmacy: '/pharmacy',
    faq: '/faq',
    quiz: '/home/quiz',
};

export const CONFIG_PAGES_MAP: PageMapConfig[] = [
    {
        id: 1,
        baseRoute: BASE_ROUTES.home,
        path: undefined,
        pageTitle: undefined,
        type: 'PAGE',
        definedElement: <HomePage />,
        showTopAppBar: false,
        showBottomNavLink: false,
    },
    {
        id: 2,
        baseRoute: BASE_ROUTES.map,
        path: undefined,
        pageTitle: 'Карта',
        type: 'PAGE',
        showChatButton: false,
        showBottomNavLink: false,
        definedElement: <MapPage />,
    },
    {
        id: 3,
        baseRoute: BASE_ROUTES.pharmacy,
        path: undefined,
        pageTitle: 'Аптека',
        type: 'PAGE',
        definedElement: <PharmacyPage />,
        showChatButton: false,
        showBottomNavLink: false,
    },
];
