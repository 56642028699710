import { useMemo } from 'react';

import {
    CircularProgress,
    IconButton,
    Stack,
    Tab,
    Tooltip,
} from '@mui/material';
import { useLocation, Link } from 'react-router-dom';

import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useSignOut } from 'hooks/api/auth/useSignOut';
import { useUser } from 'hooks/api/auth/useUser';
import { useStaffData } from 'hooks/api/staff/useStaffData';

import { AccSettings } from './AccSettings';
import {
    HeaderTabsWrapper,
    HeaderWrapper,
    RoleTabs,
    TabSegment,
} from './Header.styled';

const rolesToIconsMap: { [key: string]: string } = {
    administrator: 'icons.24px.users',
    support_manager: 'icons.24px.messageCircle',
    content_manager: 'icons.24px.edit',
};

export const Header = (): JSX.Element => {
    const { pathname } = useLocation();

    const { user } = useUser();

    const { signOut, loading } = useSignOut();

    const { roles } = useStaffData();

    const userRoles = useMemo(() => {
        return roles?.filter(
            (el) => user?.roles.includes(1) || user?.roles.includes(el.id)
        );
    }, [roles, user?.roles]);

    const activeTab = useMemo(() => {
        const role = userRoles?.find(
            (el) => el.name === pathname.split('/')[2]
        );

        return role?.name;
    }, [pathname, userRoles]);

    return (
        <HeaderWrapper>
            <HeaderTabsWrapper>
                <Link to="/home">
                    <SVGAsset src="logos.logo67" />
                </Link>
                {userRoles && user ? (
                    <RoleTabs value={activeTab || false} variant="fullWidth">
                        {userRoles.map((role) => (
                            <Tab
                                key={role.id}
                                to={`/admin/${role.name}`}
                                component={Link}
                                label={
                                    <TabSegment className="svg-color-inherit">
                                        <SVGAsset
                                            src={rolesToIconsMap[role.name]}
                                        />
                                        <Typography variant="title.large">
                                            {role.verbose_name}
                                        </Typography>
                                    </TabSegment>
                                }
                                value={role.name}
                            />
                        ))}
                    </RoleTabs>
                ) : undefined}
            </HeaderTabsWrapper>
            <Stack spacing={0.5} direction="row" alignItems="center">
                <Typography variant="title.medium">{user?.email}</Typography>
                <AccSettings />
                <Tooltip
                    title="Выйти из аккаунта"
                    arrow
                    placement="bottom-end"
                    enterDelay={1000}
                >
                    <IconButton color="inherit" onClick={() => signOut()}>
                        {loading ? (
                            <CircularProgress
                                variant="indeterminate"
                                size={20}
                                color="inherit"
                            />
                        ) : (
                            <SVGAsset src="icons.24px.logout" />
                        )}
                    </IconButton>
                </Tooltip>
            </Stack>
        </HeaderWrapper>
    );
};
