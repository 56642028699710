/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { Key } from 'react';

import { v4 as uuid } from 'uuid';

import {
    controlChildConfig,
    newComponentDefaultProps,
} from 'constants/components/controlConfig';
import { PageComponentConfig } from 'types/builder/componentConfig';

export const addKeyToConfig = (
    conf?: readonly PageComponentConfig[]
): readonly PageComponentConfig[] =>
    conf?.map((el) => ({
        ...el,
        _key: el._key || uuid(),
        children: addKeyToConfig(el.children),
    })) || [];

export const findNodeByKey = (
    conf: readonly PageComponentConfig[],
    key: Key
): PageComponentConfig | undefined => {
    let node: PageComponentConfig | undefined;

    conf.some((root) => {
        if (root._key === key) {
            node = root;
            return true;
        }
        if (root.children) {
            node = findNodeByKey(root.children, key);
            if (node) {
                return true;
            }
            return false;
        }
        return false;
    });

    return node;
};

export const deleteNodeByKey = (
    conf: readonly PageComponentConfig[],
    key: Key
): readonly PageComponentConfig[] => {
    const results: PageComponentConfig[] = [];

    conf.forEach((node) => {
        if (node._key === key) {
            return;
        }
        if (node.children) {
            results.push({
                ...node,
                children: deleteNodeByKey(node.children, key),
            });
            return;
        }
        results.push(node);
    });

    return results;
};

export const updateConfigByNode = (
    conf: readonly PageComponentConfig[],
    upd: Pick<PageComponentConfig, '_key' | 'props'>
): readonly PageComponentConfig[] => {
    return conf.map((node) => {
        if (node._key === upd._key) {
            return {
                ...node,
                props: upd.props as never,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateConfigByNode(node.children, upd),
            };
        }
        return node;
    });
};

const loopAndAssignNewKey = (
    conf: readonly PageComponentConfig[]
): readonly PageComponentConfig[] => {
    return conf.map((node) => ({
        ...node,
        _key: uuid(),
        children: loopAndAssignNewKey(node.children || []),
    }));
};

export const duplicateNode = (
    conf: readonly PageComponentConfig[],
    nodeDup: Key
): readonly PageComponentConfig[] => {
    const results: PageComponentConfig[] = [];

    conf.forEach((node) => {
        if (node._key === nodeDup) {
            results.push(node);
            results.push({
                ...node,
                children: loopAndAssignNewKey(node.children || []),
                _key: uuid(),
            });
            return;
        }
        if (node.children) {
            results.push({
                ...node,
                children: duplicateNode(node.children, nodeDup),
            });

            return;
        }
        results.push(node);
    });

    return results;
};

export const addNewComponent = (
    conf: readonly PageComponentConfig[],
    component: PageComponentConfig['component'],
    callBack: (newNode: Key) => void,
    activeNode?: Key
): readonly PageComponentConfig[] => {
    let results: PageComponentConfig[] = [];

    const newComponent: PageComponentConfig = {
        _key: uuid(),
        props: newComponentDefaultProps[component]?.props as any,
        component,
    };

    if (activeNode) {
        conf.forEach((node) => {
            if (
                node._key === activeNode &&
                !controlChildConfig[node.component]
            ) {
                results.push(node);
                results.push(newComponent);
                callBack(newComponent._key as Key);
                return;
            }
            if (
                node._key === activeNode &&
                controlChildConfig[node.component]
            ) {
                results.push({
                    ...node,
                    children: [...(node.children || []), newComponent],
                });
                callBack(newComponent._key as Key);
                return;
            }
            if (node.children) {
                results.push({
                    ...node,
                    children: addNewComponent(
                        node.children,
                        component,
                        callBack,
                        activeNode
                    ),
                });

                return;
            }
            results.push(node);
        });
    } else {
        results = [...conf, newComponent];

        callBack(newComponent._key as Key);
    }

    return results;
};

export const findAllAnchors = (
    conf: readonly PageComponentConfig[]
): PageComponentConfig[] => {
    let nodes: PageComponentConfig[] = [];

    conf.forEach((root) => {
        if (root.component === 'section' && root.props?.id) {
            nodes = [...nodes, root];
        }
        if (root.children) {
            const childNodes = findAllAnchors(root.children) || [];

            nodes = [...nodes, ...childNodes];
        }
    });

    return nodes;
};
