import { createGlobalStyle } from 'styled-components';

import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import 'antd/lib/input/style/index.css';

import { AntDStyles, AntDTreeStyles } from './AntDStyle';
import { CSSBaseline } from './CSSBaseline';

export const GlobalStyles = createGlobalStyle`
    ${CSSBaseline}
    ${AntDStyles}
    ${AntDTreeStyles}

    html, body {
        background-color: ${({ theme }) => theme.palette.common.white};
        color: ${({ theme }) => theme.palette.common.black};
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        min-width: 360px;
        ${({ theme }) => theme.typography.body.medium}
        & > * {
            -webkit-tap-highlight-color: transparent;
            scrollbar-width: thin;
            scrollbar-color: lightgrey rgba(216, 224, 216, 1);
        }
       
        *::-webkit-scrollbar {
            width: 8px;
            border-radius: 50px;
        }
        *::-webkit-scrollbar-track {
            background: rgba(216, 224, 216, 0.2);
            border-radius: 50px;
            width: 100%;
        }
        *::-webkit-scrollbar-thumb {
            background-color: blue;
            border-radius: 50px;
            background-color: ${({ theme }) => theme.palette.grey.bg1};
        }

        .chat-window {
            ::-webkit-scrollbar-track {
                margin-top: 20px;
                background: transparent;
                border-radius: 50px;
                width: 100%;
            }
        }

        &[data-preview="true"] {
            overflow: hidden;
        }
    }

    @supports (-webkit-overflow-scrolling: touch) {
   input {
     font-size: 16px;
   }
}

    html, body, #root {
        min-height: 100vh;
        min-height: -webkit-fill-available;
        height: 100%;
    }
    body.modal-open {
        height: 100vh;
        height: -webkit-fill-available;
        overflow-y: hidden;
    }
    #root {
        display: flex;
        flex-direction: column;
    }
    svg {
        display: block;
    }
    .ReactModal__Overlay {
        z-index: 1800 !important;
    }
    .ril__toolbar {
        height: 80px;
        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            backdrop-filter: blur(16px);
        }

        .ril__toolbarRightSide {
            height: 80px;
            display: flex;
            padding-right: 8px;

        .ril__toolbarItem {
            height: 100%;
            display: flex;
            align-items: center;
            button {
                outline: none;
                width: 50px;
                height: 50px;
                background-size: 25px 25px;
                }
            }
        }
    }
   
   .svg-color-inherit {
        svg {
            * {
                &[stroke] {
                    stroke: currentColor;
                }
                &[fill] {
                    fill: currentColor;
                }
            }
        }
   }

   .SnackbarContent-root {
        padding: 32px 40px !important;
        border-radius: 16px !important;
        .SnackbarItem-message {
            padding: 0 !important;
            font-size: 16px;
        }
        &.SnackbarItem-variantSuccess {
            background-color: ${({ theme }) =>
                theme.palette.green.gamma} !important;
            color: black !important;
        }
   }
`;
