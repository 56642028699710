import { Key, useEffect } from 'react';

export type NodeHighlighterProps = {
    selectedNode?: Key;
    hoveredNode?: Key;
};

export const NodeHighlighter = ({
    hoveredNode,
    selectedNode,
}: NodeHighlighterProps): null => {
    useEffect(() => {
        const element = document.querySelector(
            `[data-key="${selectedNode}"]`
        ) as HTMLElement;
        if (element) {
            element.classList.add('selected');
            element.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
        return () => {
            if (element) {
                element.classList.remove('selected');
            }
        };
    }, [selectedNode]);

    useEffect(() => {
        const element = document.querySelector(
            `[data-key="${hoveredNode}"]`
        ) as HTMLElement;
        if (element) {
            element.classList.add('hovered');
        }
        return () => {
            if (element) {
                element.classList.remove('hovered');
            }
        };
    }, [hoveredNode]);

    return null;
};
