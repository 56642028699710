import { useCallback, useEffect, useMemo } from 'react';

import { renderComponent } from 'components/render';
import { useAppTitle } from 'hooks/store/useAppTitle';
import { PageMapConfig } from 'types/builder/pageConfig';

import { FadeAnimation } from '../FadeWrapper';
import { QuizQuestion } from '../Quiz/QuizQuestion';

type PageTemplateProps = {
    page: PageMapConfig;
    previewMode?: boolean;
    path?: string;
};

export const PageTemplate = ({
    page,
    previewMode,
    path,
}: PageTemplateProps): JSX.Element => {
    const {
        pageTitle,
        description,
        type,
        quizConfig,
        definedElement,
        pageConfig,
    } = page;

    const { setDefaultAppTitle, setAppTitle } = useAppTitle();

    useEffect(() => {
        if (!previewMode) {
            if (pageTitle) {
                setAppTitle(pageTitle);
            } else {
                setDefaultAppTitle();
            }
            if (description) {
                const meta = document.getElementById('meta-description');

                meta?.setAttribute('content', description);
            }
        }
    }, [
        setDefaultAppTitle,
        setAppTitle,
        pageTitle,
        previewMode,
        description,
        path,
    ]);

    const renderConfig = useCallback(() => {
        return pageConfig?.map((el) => renderComponent(el));
    }, [pageConfig]);

    const pageRender = useMemo(() => {
        switch (type) {
            case 'PAGE':
                if (definedElement) {
                    return definedElement;
                }
                return <div>{renderConfig()}</div>;
            case 'QUIZ':
                return <QuizQuestion config={quizConfig} />;
            default:
                return <div>{pageTitle}</div>;
        }
    }, [definedElement, pageTitle, quizConfig, renderConfig, type]);

    useEffect(() => {
        const { hash } = window.location;

        if (hash) {
            const element = document.getElementById(
                decodeURIComponent(hash).replace('#', '')
            );

            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }
    }, [pageRender]);

    return <FadeAnimation key={page.id}>{pageRender}</FadeAnimation>;
};
