import { ComponentPropsWithoutRef } from 'react';

import { StyledPlaceholder } from './Placeholder.styled';

export type PlaceholderProps = ComponentPropsWithoutRef<'div'>;

export const Placeholder = (props: PlaceholderProps): JSX.Element => {
    if (process.env.NODE_ENV === 'production') {
        return <div />;
    }

    return <StyledPlaceholder {...props} />;
};
