import { motion } from 'framer-motion';
import styled from 'styled-components';

export const NavigationInfoMotion = styled(motion.div)`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: initial;
`;

export const NavigationInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding: 12px 16px;

    border-radius: 16px;
    background-color: ${({ theme }) => theme.palette.common.white};
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.23);
`;
