import styled from 'styled-components';

import { IPageLayoutProps } from './types';

export const PageLayoutWrapper = styled.div<IPageLayoutProps>`
    display: flex;
    flex-direction: column;
    flex: 1;

    padding-bottom: ${(props) =>
        `calc(${
            props.footerIsVisible ? '80px' : undefined
        } + env(safe-area-inset-bottom))`};
    padding-top: ${(props) => (props.headerIsVisible ? '64px' : undefined)};
`;
