/* eslint-disable no-underscore-dangle */
import { createElement, useMemo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Stack,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
} from '@mui/material';

import { controlConfig } from 'constants/components/controlConfig';
import { useBuilderToolbars } from 'hooks/api/builder/pages/usePageBuilder';
import { useAppSelector } from 'hooks/store/useAppStore';
import { PageComponentConfig } from 'types/builder/componentConfig';

import { ComponentHeader } from './ComponentHeader';
import { CopyRemoveControl } from './CopyRemoveControl';

type ComponentSettingsControlProps = {
    onNodeChange: (newNode: PageComponentConfig) => void;
    onNodeDuplicate: () => void;
    onNodeDelete: () => void;
};

export const ComponentSettingsControl = ({
    onNodeChange,
    onNodeDelete,
    onNodeDuplicate,
}: ComponentSettingsControlProps): JSX.Element | null => {
    const { selectedNode } = useAppSelector((state) => state.pageBuilder);

    const { setActiveToolBar, toolbars } = useBuilderToolbars();

    const componentControl = useMemo(
        () =>
            selectedNode
                ? createElement(
                      controlConfig[selectedNode.component] as string,
                      {
                          node: selectedNode,
                          onChange: onNodeChange,
                      }
                  )
                : undefined,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedNode]
    );

    return selectedNode && controlConfig[selectedNode.component] ? (
        <Accordion
            sx={{
                borderBottom: 1,
                borderColor: 'divider',
                margin: '0 !important',
            }}
            expanded={toolbars.right === 'nodeSettings'}
            onChange={(e, v) => setActiveToolBar('nodeSettings', v)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ px: '28px' }}
            >
                <ComponentHeader
                    activeNode={selectedNode}
                    expanded={toolbars.right === 'nodeSettings'}
                />
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, position: 'relative' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        overflowY: 'auto',
                    }}
                >
                    <Stack spacing={3} sx={{ p: '28px', pt: '20px' }}>
                        {componentControl}
                        <Divider />
                        <CopyRemoveControl
                            onRequestDelete={onNodeDelete}
                            onRequestDeuplicate={onNodeDuplicate}
                        />
                    </Stack>
                </Box>
            </AccordionDetails>
        </Accordion>
    ) : null;
};
