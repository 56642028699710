/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useEffect, useMemo, useState } from 'react';

import { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';

export type UseUndoRedoReturn<T = any> = [
    state: T,
    setState: (value: T) => void,
    actions: {
        resetState: (init: T) => void;
        undo: (steps?: number) => void;
        redo: (steps?: number) => void;
        canUndo: boolean;
        canRedo: boolean;
        hasChanges: boolean;
        key: string;
    }
];

export const useUndoRedo = <T>(initialState: T): UseUndoRedoReturn<T> => {
    const [states, setStates] = useState([initialState]); // Used to store history of all states

    const [index, setIndex] = useState(0); // Index of current state within `states`

    const [versionKey, setVersionKey] = useState(uuid());

    useEffect(() => {
        setIndex(0);
        setStates([initialState]);
        setVersionKey(uuid());
    }, [initialState]);

    const state = useMemo(() => states[index], [states, index]); // Current state

    const setState = useCallback(
        (value: T): void => {
            if (isEqual(state, value)) {
                return;
            }

            const copy = states.slice(0, index + 1); // This removes all future (redo) states after current index
            copy.push(value);
            setStates(JSON.parse(JSON.stringify(copy)));
            setIndex(copy.length - 1);
        },
        [index, states, state]
    );

    // Clear all state history
    const resetState = (init: T): void => {
        setIndex(0);
        setStates([init]);
        setVersionKey(uuid());
    };

    // Allows you to go back (undo) N steps
    const undo = useCallback(
        (steps?: number): void => {
            setIndex(Math.max(0, index - (steps || 1)));
        },
        [index]
    );

    // Allows you to go forward (redo) N steps
    const redo = useCallback(
        (steps?: number): void => {
            setIndex(Math.min(states.length - 1, index + (steps || 1)));
        },
        [index, states.length]
    );

    return [
        state,
        setState,
        {
            resetState,
            undo,
            redo,
            canUndo: index > 0,
            canRedo: index < states.length - 1,
            hasChanges: states.length > 1,
            key: versionKey,
        },
    ];
};
