import { Key, ReactNode, useEffect, useMemo, useState } from 'react';

import PublishIcon from '@mui/icons-material/Publish';
import { Stack, Tooltip } from '@mui/material';
import Tree, { DataNode } from 'antd/lib/tree';

import { NAVBAR_SEGMENTS } from 'components/templates/layouts/PageLayout/NavigationBar';
import { PageList } from 'types/api/pageList';
import { highlightInText } from 'utils/highlightInText';

import { onSelectFunc } from '../../NodeTree/types';
import { findParentKeys, getParentKey } from '../../NodeTree/utils';

type PageSelectTreeProps = {
    pages: PageList[];
    selectedPage?: number;
    onPageSelect: (page?: number) => void;
    selectDisabled: boolean;
    searchValue?: string;
};

type NodeWithRouteUuid = DataNode & {
    route_uuid?: string;
    published?: boolean;
};

const getTreeData = (
    items: PageList[],
    selected?: number,
    disabled?: boolean
): NodeWithRouteUuid[] => {
    if (items && items.length > 0) {
        const data: NodeWithRouteUuid[] = [];
        const map: {
            [key: string]: NodeWithRouteUuid;
        } = {};

        items.forEach((item) => {
            const { uuid } = item;

            const selectDisabled = disabled && selected !== item.id;

            const iconSegment = NAVBAR_SEGMENTS.find((nav) =>
                `/${item?.href.split('/').filter((e) => !!e)[0]}`.includes(
                    nav.href
                )
            );

            if (!Object.hasOwn(map, uuid)) {
                map[uuid] = {
                    key: item.id,
                    title: item.title,
                    route_uuid: item.route_uuid || '',
                    selectable: !item.is_static && !item.is_fundamental,
                    disabled: selectDisabled,
                    children: [],
                    icon: iconSegment?.icon,
                    published: item.is_published,
                    checkable: item.is_fundamental || item.is_static,
                };
            }
        });

        Object.keys(map).forEach((id) => {
            if (Object.hasOwn(map, id)) {
                const mappedElem: NodeWithRouteUuid = map[id];
                if (
                    mappedElem.route_uuid &&
                    typeof map[mappedElem.route_uuid] !== 'undefined'
                ) {
                    map[mappedElem.route_uuid].children?.push(mappedElem);
                } else {
                    data.push(mappedElem);
                }
            }
        });

        return data;
    }
    return [];
};

type PageTitleWrapperProps = {
    published?: boolean;
    children?: ReactNode;
    disabled?: boolean;
};

const PageTitleWrapper = ({
    disabled,
    published,
    children,
}: PageTitleWrapperProps): JSX.Element => {
    return (
        <Stack alignItems="center" direction="row">
            {children}
            {published ? (
                <Tooltip
                    arrow
                    placement="right"
                    title={disabled ? '' : 'Страница опубликована'}
                    enterDelay={2000}
                >
                    <PublishIcon
                        fontSize="small"
                        color="action"
                        sx={{
                            ml: 'auto',
                            mr: 1,
                        }}
                    />
                </Tooltip>
            ) : undefined}
        </Stack>
    );
};

export const PageSelectTree = ({
    pages,
    onPageSelect,
    selectedPage,
    selectDisabled,
    searchValue = '',
}: PageSelectTreeProps): JSX.Element => {
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    const nodeTrees = useMemo(() => {
        return getTreeData(pages, selectedPage, selectDisabled);
    }, [pages, selectDisabled, selectedPage]);

    useEffect(() => {
        if (searchValue !== '') {
            const newExpandedKeys = pages
                .map((item) => {
                    if (
                        item.title
                            .toLowerCase()
                            .indexOf(searchValue.toLowerCase()) > -1
                    ) {
                        return getParentKey(item.id, nodeTrees);
                    }
                    return null;
                })
                .filter(
                    (item, i, self) => item && self.indexOf(item) === i
                ) as Key[];

            setExpandedKeys(Array.from(new Set([...newExpandedKeys])));
        }
        if (selectedPage) {
            const newExpandedKeys = findParentKeys(selectedPage, nodeTrees);

            setExpandedKeys((keys) =>
                Array.from(new Set([...keys, ...newExpandedKeys]))
            );
        }
        setAutoExpandParent(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, selectedPage, nodeTrees]);

    const onNodeSelect: onSelectFunc = (keys): void => {
        if (keys.length === 1) {
            onPageSelect(Number(keys[0]));
        }
    };

    const treeData = useMemo(() => {
        const loop = (data: NodeWithRouteUuid[]): NodeWithRouteUuid[] =>
            data.map((item) => {
                const title = highlightInText(
                    item.title as string,
                    searchValue
                );

                if (item.children) {
                    return {
                        ...item,
                        title: (
                            <PageTitleWrapper
                                disabled={item.disabled}
                                published={item.published}
                            >
                                {title.element}
                            </PageTitleWrapper>
                        ),
                        className: title.match ? 'indexed' : undefined,
                        children: loop(item.children),
                    };
                }

                return {
                    ...item,
                    className: title.match ? 'indexed' : undefined,
                    title: (
                        <PageTitleWrapper
                            disabled={item.disabled}
                            published={item.published}
                        >
                            {title.element}
                        </PageTitleWrapper>
                    ),
                };
            });

        return loop(nodeTrees);
    }, [nodeTrees, searchValue]);

    const onExpand = (newExpandedKeys: Key[]): void => {
        setExpandedKeys(Array.from(new Set([...newExpandedKeys])));
        setAutoExpandParent(false);
    };

    return (
        <Tree
            showIcon
            blockNode
            treeData={treeData}
            selectedKeys={selectedPage ? [selectedPage] : undefined}
            onSelect={onNodeSelect}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onExpand={onExpand}
            onClick={(e, node) => {
                if (!expandedKeys.includes(node.key)) {
                    if (node.children && node.children.length) {
                        setExpandedKeys((keys) => [...keys, node.key]);
                    }
                } else {
                    setExpandedKeys((keys) =>
                        keys.filter((el) => el !== node.key)
                    );
                }
            }}
        />
    );
};
