import { BasicButton } from 'components/atoms/BasicButton';
import { CheckboxItem } from 'components/atoms/CheckboxItem/index';
import { Box, Row, Col, Section, Spacer } from 'components/atoms/Grid';
import { Image } from 'components/atoms/Image';
import { Placeholder } from 'components/atoms/Placeholder';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { BraceletHeader } from 'components/molecules/BraceletHeader';
import { ButtonWithIcon } from 'components/molecules/Buttons/ButtonWithIcon';
import { CopyList } from 'components/molecules/CopyList';
import { DoctorCard } from 'components/molecules/DoctorCard/index';
import { Dropdown } from 'components/molecules/DropDown';
import { HumanAvatar } from 'components/molecules/HumanAvatar';
import { ItemList } from 'components/molecules/ItemList';
import { SimpleLink } from 'components/molecules/Links/Link';

export const keysToComponentMap = {
    box: Box,
    section: Section,
    row: Row,
    typography: Typography,
    avatar: HumanAvatar,
    buttonWithIcon: ButtonWithIcon,
    list: ItemList,
    dropdown: Dropdown,
    simpleLink: SimpleLink,
    placeholder: Placeholder,
    copyList: CopyList,
    checkbox: CheckboxItem,
    basicButton: BasicButton,
    doctorCard: DoctorCard,
    col: Col,
    svgAsset: SVGAsset,
    braceletHeader: BraceletHeader,
    image: Image,
    spacer: Spacer,
};
