import styled from 'styled-components';

export const DropZoneWrapper = styled.div`
    padding: 44px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 16px;

    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237D887C' stroke-width='4' rx='16' stroke-dasharray='24 24' stroke-dashoffset='0' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    &.active,
    &:active,
    &:focus {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23B7D30C' stroke-width='4' rx='16' stroke-dasharray='24 24' stroke-dashoffset='0' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    }
`;
