import { useEffect } from 'react';

import { Marker } from '@2gis/mapgl/types';

import { useLocationUrl } from 'hooks/api/map/useLocationUrl';
import { useMapInstance } from 'hooks/api/map/useMapInstance';
import { useMapPosition } from 'hooks/api/map/useMapPosition';
import { SelectedLocation } from 'types/map/location';
import { createMarker } from 'utils/map';

type nameProps = {
    selectedLocation?: SelectedLocation;
};

export const SelectedLocationZoom = ({ selectedLocation }: nameProps): null => {
    const { mapInstance, mapgl } = useMapInstance();

    const { location } = useLocationUrl();

    const { setMapToLocation, setMapFloor } = useMapPosition();

    useEffect(() => {
        let marker: Marker | undefined;

        if (selectedLocation && mapInstance && mapgl) {
            setMapToLocation({
                x: selectedLocation.latitude,
                y: selectedLocation.longitude,
                zoom: 21,
            });

            setMapFloor({
                floorLevelIndex: selectedLocation.floor?.floor_number,
                floorPlanId: selectedLocation.building?.floor_plan_id,
            });

            marker = createMarker(
                mapInstance,
                mapgl,
                [selectedLocation.latitude, selectedLocation.longitude],
                location ? 'Я здесь' : undefined,
                location ? 'my-location' : 'location'
            );

            document.querySelector('.floor-control')?.classList.add('hidden');
        }
        return () => {
            marker?.destroy();

            document
                .querySelector('.floor-control')
                ?.classList.remove('hidden');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocation, mapInstance, mapgl, location, setMapFloor]);

    return null;
};
