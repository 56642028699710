import { MouseEvent, useState } from 'react';

import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    IconButton,
    MenuItem,
    Menu,
    Tooltip,
    ListItemIcon,
    CircularProgress,
} from '@mui/material';

import { useUser } from 'hooks/api/auth/useUser';
import { useNotifications } from 'hooks/api/integrations/useNotifications';
import { useTgAttach } from 'hooks/api/integrations/useTgAttach';
import { useTgDetach } from 'hooks/api/integrations/useTgDetach';

import { NewPasswordModal } from './NewPassModal';

export const AccSettings = (): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const [passModalOpen, setPassModalOpen] = useState(false);

    const { detachTelegram, loading } = useTgDetach();

    const { getTgAttachLink, loading: tgLoading } = useTgAttach();

    useNotifications(
        {
            onTgAttach() {
                window.location.reload();
            },
        },
        []
    );

    const { user } = useUser();

    const tgIntegrationRequired = !user?.integrations?.telegram;

    return (
        <>
            <Tooltip
                title="Настройки аккаунта"
                arrow
                placement="bottom-end"
                enterDelay={1000}
            >
                <IconButton
                    color={open ? 'primary' : 'inherit'}
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
                <MenuItem
                    onClick={() => {
                        setPassModalOpen(true);
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <LockOutlinedIcon />
                    </ListItemIcon>
                    Сменить пароль
                </MenuItem>
                {!tgIntegrationRequired ? (
                    <MenuItem onClick={detachTelegram}>
                        <ListItemIcon>
                            {loading ? (
                                <CircularProgress
                                    size={24}
                                    color="inherit"
                                    variant="indeterminate"
                                />
                            ) : (
                                <LinkOffOutlinedIcon />
                            )}
                        </ListItemIcon>
                        Отвязать Telegram
                    </MenuItem>
                ) : undefined}
                {tgIntegrationRequired ? (
                    <MenuItem onClick={getTgAttachLink}>
                        <ListItemIcon>
                            {tgLoading ? (
                                <CircularProgress
                                    size={16}
                                    color="inherit"
                                    variant="indeterminate"
                                />
                            ) : (
                                <LinkOutlinedIcon />
                            )}
                        </ListItemIcon>
                        Привязать Telegram
                    </MenuItem>
                ) : undefined}
            </Menu>
            <NewPasswordModal
                open={passModalOpen}
                onClose={() => setPassModalOpen(false)}
            />
        </>
    );
};
