import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SplashScreenWrapper = styled.main`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &[data-full-screen='true'] {
        min-height: 100vh;
        min-height: -webkit-fill-available;
    }

    overflow: hidden;

    flex: 1;
`;

export const SplashScreenView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding-top: 16px;
    padding-bottom: 32px;
    min-height: 376px;
    &[data-full-screen='true'] {
        min-height: 576px;
    }
`;

export const SplashScreenTitleElements = styled(motion.div)`
    text-align: center;
    padding-bottom: 32px;
`;

export const SplashScreenTitle = styled.h1`
    ${({ theme }) => theme.typography.headline.large};
    color: ${({ theme }) => theme.palette.green.alpha};
    margin-bottom: 8px;
`;

export const SplashScreenInfo = styled.p`
    ${({ theme }) => theme.typography.title.small};
    white-space: pre-line;
`;

export const SplashScreenBottomElements = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 8px;
`;

export const SplashScreenListElements = styled.ul`
    ${({ theme }) => theme.typography.title.small};
    margin-bottom: 32px;
    li {
        display: flex;
        flex-direction: row;
        padding: 4px 0;
        svg {
            margin-right: 16px;
        }
    }
`;
