import { ChangeEventHandler } from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    FormControl,
    IconButton,
    InputAdornment,
    Stack,
    Switch,
    TextField,
    Tooltip,
} from '@mui/material';

import { Typography } from 'components/atoms/Typography';
import { SectionConfig } from 'types/builder/componentConfig';

type SectionControlProps = {
    node?: SectionConfig;
    onChange: (node?: SectionConfig) => void;
};

export const SectionControl = ({
    onChange,
    node,
}: SectionControlProps): JSX.Element => {
    const { noMargin, id } = node?.props || {};

    const onMarginChange = (val: boolean): void => {
        onChange({
            component: 'section',
            props: {
                ...node?.props,
                noMargin: val,
            },
        });
    };
    const onIdChange: ChangeEventHandler<HTMLInputElement> = (e): void => {
        const { value } = e.target;

        onChange({
            component: 'section',
            props: {
                ...node?.props,
                id: value.toLocaleLowerCase(),
            },
        });
    };

    return (
        <Stack spacing={2}>
            <FormControl variant="outlined">
                <TextField
                    label="Ключ раздела"
                    size="small"
                    fullWidth
                    value={id || ''}
                    onChange={onIdChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ mr: -1 }}>
                                <Tooltip
                                    title="Уникальное имя раздела, позволяющее создать «закладку» и перенести пользователей в определенную часть страницы"
                                    placement="bottom-end"
                                    arrow
                                >
                                    <IconButton
                                        size="small"
                                        sx={{ cursor: 'help' }}
                                    >
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>Отступы раздела</Typography>
                <Switch
                    name="showBottomNavLink"
                    checked={!noMargin}
                    onChange={(e, v) => onMarginChange(!v)}
                />
            </Stack>
        </Stack>
    );
};
