import { useState } from 'react';

import { AxiosError } from 'axios';

import useAxios from 'hooks/api/useAxios';
import { PageRetrieve } from 'types/api/pageRetrieve';

type UseGetPageConfigReturn = {
    loading: boolean;
    success: boolean;
    error: false | AxiosError<unknown, unknown>;
    deletePage: (id: number) => void;
};

export const usePageDelete = (): UseGetPageConfigReturn => {
    const [id, setID] = useState<number>();

    const [res, { rerun }] = useAxios<PageRetrieve>(
        {
            url: `/api/builder/page/${id}/`,
            method: 'DELETE',
            skipRequest: () => true,
        },
        [id]
    );

    const loading = res.type === 'loading';
    const success = res.type === 'success';
    const error = res.type === 'error' && res.data;

    const sendDeleteRequest = (pageID: number): void => {
        setID(pageID);
        rerun();
    };

    return { success, loading, error, deletePage: sendDeleteRequest };
};
