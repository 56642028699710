import { useState } from 'react';

import useAxios from 'hooks/api/useAxios';
import { ChangeMyUser } from 'types/api/changeMyUser';

type UsePasswordChangeReturn = {
    changePassword: (password: string) => void;
    loading: boolean;
    error: boolean;
    success: boolean;
};

export const usePasswordChange = (): UsePasswordChangeReturn => {
    const [body, setBody] = useState<ChangeMyUser>();

    const [res, { rerun }] = useAxios<ChangeMyUser>(
        {
            url: '/api/admin/user/',
            method: 'PATCH',
            data: body,
            skipRequest: () => true,
        },
        [body]
    );

    const loading = res.type === 'loading';
    const error = res.type === 'error';
    const success = res.type === 'success';

    const changePassword = (password: string): void => {
        setBody({
            password,
        });
        rerun();
    };

    return { changePassword, loading, error, success };
};
