import { useCallback, useEffect, useMemo, useState } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DirectionsIcon from '@mui/icons-material/Directions';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigationIcon from '@mui/icons-material/Navigation';
import {
    Button,
    CircularProgress,
    IconButton,
    Stack,
    TextField,
} from '@mui/material';
import { AnimatePresence, Variants, motion } from 'framer-motion';

import { useFetchDirections } from 'hooks/api/map/useFetchDirections';
import { useLocationUrl } from 'hooks/api/map/useLocationUrl';
import { RoutingResult } from 'types/map/2gis';
import { SelectedLocation } from 'types/map/location';

import { LocationSelectControl } from '../LocationSelectControl';

const RoutingControlVariants: Variants = {
    visible: {
        opacity: 1,
        x: 0,
    },
    hidden: {
        opacity: 0,
        x: '-100vw',
    },
};
const RoutingControlVariantsAlt: Variants = {
    visible: {
        opacity: 1,
        x: 0,
    },
    hidden: {
        opacity: 0,
        x: '100vw',
    },
};

type RoutingControlProps = {
    initialLocation?: SelectedLocation;
    onCancel?: () => void;
    onSuccessRouteBuild?: (
        route: RoutingResult,
        points: {
            start: SelectedLocation;
            end: SelectedLocation;
        }
    ) => void;
};

export const RoutingControl = ({
    initialLocation,
    onCancel = () => undefined,
    onSuccessRouteBuild = () => undefined,
}: RoutingControlProps): JSX.Element => {
    const { location } = useLocationUrl();

    const [startPoint, setStartPoint] = useState<SelectedLocation | undefined>(
        location
    );
    const [endPoint, setEndPoint] = useState<SelectedLocation | undefined>(
        !location ? initialLocation : undefined
    );

    const switchLocations = (): void => {
        const aTemp = startPoint;
        const bTemp = endPoint;
        setStartPoint(bTemp);
        setEndPoint(aTemp);
    };

    const { fetchDirections, data, error, loading } = useFetchDirections();

    const buttonDisabled = useMemo(() => {
        return !startPoint || !endPoint || loading;
    }, [endPoint, startPoint, loading]);

    const [selectOpen, setSelectOpen] = useState<'start' | 'end'>();

    const onBuildRoute = useCallback(() => {
        if (startPoint && endPoint) {
            fetchDirections([
                {
                    x: startPoint.latitude,
                    y: startPoint.longitude,
                    type: 'walking',
                    floor_id: startPoint.floor?.floor_id,
                },
                {
                    x: endPoint.latitude,
                    y: endPoint.longitude,
                    type: 'walking',
                    floor_id: endPoint.floor?.floor_id,
                },
            ]);
        }
    }, [endPoint, fetchDirections, startPoint]);

    useEffect(() => {
        if (!loading && !error && data && startPoint && endPoint) {
            onSuccessRouteBuild(data.result[0], {
                end: endPoint,
                start: startPoint,
            });
        }
    }, [data, endPoint, error, loading, onSuccessRouteBuild, startPoint]);

    return (
        <AnimatePresence exitBeforeEnter>
            {!selectOpen ? (
                <motion.div
                    key={selectOpen}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                    variants={RoutingControlVariants}
                    initial="visible"
                    animate="visible"
                    exit="hidden"
                    transition={{
                        duration: 0.2,
                    }}
                >
                    <Stack sx={{ flex: 1, pointerEvents: 'initial' }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={0.5}
                        >
                            <IconButton
                                sx={{ p: 0.2, ml: -1 }}
                                onClick={() => onCancel()}
                            >
                                <ChevronLeftIcon fontSize="large" />
                            </IconButton>
                            <Stack
                                justifyContent="space-between"
                                sx={{
                                    height: '100%',
                                    py: 2,
                                    color: 'rgba(0, 0, 0, 0.54)',
                                }}
                            >
                                <NavigationIcon
                                    fontSize="small"
                                    sx={{ transform: 'rotate(180deg)' }}
                                />
                                <MoreVertIcon fontSize="small" />
                                <LocationOnIcon
                                    fontSize="small"
                                    color="error"
                                />
                            </Stack>
                            <Stack spacing={1} sx={{ width: '100%' }}>
                                <TextField
                                    placeholder="Начальная точка"
                                    value={startPoint?.name || ''}
                                    onClick={() => setSelectOpen('start')}
                                    onFocus={() => setSelectOpen('start')}
                                />
                                <TextField
                                    placeholder="Конечная точка"
                                    value={endPoint?.name || ''}
                                    onClick={() => setSelectOpen('end')}
                                    onFocus={() => setSelectOpen('end')}
                                />
                            </Stack>
                            <IconButton
                                sx={{ p: 0.2, mr: -1 }}
                                onClick={() => switchLocations()}
                            >
                                <ImportExportIcon fontSize="large" />
                            </IconButton>
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="stretch"
                            justifyContent="space-between"
                            spacing={1}
                            sx={{ mt: 'auto' }}
                        >
                            <Button
                                variant="outlined"
                                size="large"
                                sx={{ fontSize: 16 }}
                                onClick={() => onCancel()}
                            >
                                Отмена
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={
                                    loading ? (
                                        <CircularProgress
                                            size={22}
                                            variant="indeterminate"
                                            color="inherit"
                                        />
                                    ) : (
                                        <DirectionsIcon />
                                    )
                                }
                                disabled={buttonDisabled}
                                fullWidth
                                size="large"
                                sx={{ px: 1.5 }}
                                onClick={() => onBuildRoute()}
                            >
                                Построить маршрут
                            </Button>
                        </Stack>
                    </Stack>
                </motion.div>
            ) : (
                <motion.div
                    key={selectOpen}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                    variants={RoutingControlVariantsAlt}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    transition={{
                        duration: 0.2,
                    }}
                >
                    <LocationSelectControl
                        onClose={() => setSelectOpen(undefined)}
                        selectedLocation={
                            selectOpen === 'start' ? startPoint : endPoint
                        }
                        filter={
                            selectOpen === 'start'
                                ? endPoint?.id
                                : startPoint?.id
                        }
                        onLocationSelect={(loc) =>
                            selectOpen === 'start'
                                ? setStartPoint(loc)
                                : setEndPoint(loc)
                        }
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
};
