import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
} from '@mui/material';
import { ReactSVG } from 'react-svg';

import centerAlign from 'assets/icons/24px/editor/center-align.svg';
import leftAlign from 'assets/icons/24px/editor/left-align.svg';
import rightAlign from 'assets/icons/24px/editor/right-align.svg';
import { RowProps } from 'components/atoms/Grid';
import { Typography } from 'components/atoms/Typography';
import { RowConfig } from 'types/builder/componentConfig';

import { StyledRowControlGrid } from './RowControl.styled';

type RowControlProps = {
    node?: RowConfig;
    onChange: (node?: RowConfig) => void;
};

export const RowControl = ({
    onChange,
    node,
}: RowControlProps): JSX.Element => {
    const {
        noPadding = false,
        cols,
        hAlign = 'flex-start',
        vAlign = 'flex-start',
    } = node?.props || {};

    const onPaddingChange = (value: boolean): void => {
        onChange({
            component: 'row',
            props: {
                ...node?.props,
                noPadding: value,
            },
        });
    };
    const onHalignChange = (value: RowProps['hAlign']): void => {
        onChange({
            component: 'row',
            props: {
                ...node?.props,
                hAlign: value,
            },
        });
    };

    const onColsChange = (value?: number): void => {
        onChange({
            component: 'row',
            props: {
                ...node?.props,
                cols: value === 0 ? undefined : value,
            },
        });
    };

    const onHValignChange = ({
        h,
        v,
    }: {
        h: RowProps['hAlign'];
        v: RowProps['vAlign'];
    }): void => {
        onChange({
            component: 'row',
            props: {
                ...node?.props,
                hAlign: hAlign !== 'space-between' ? h : 'space-between',
                vAlign: v,
            },
        });
    };

    return (
        <Stack spacing={3}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>Отступ между столбцами</Typography>
                <Switch
                    name="showBottomNavLink"
                    checked={!noPadding}
                    onChange={(e, v) => onPaddingChange(!v)}
                />
            </Stack>
            <Stack spacing={2} direction="row">
                <Stack spacing={2} flex={1}>
                    <FormControl variant="outlined" size="small">
                        <InputLabel id="base-route-label">
                            Расположение
                        </InputLabel>
                        <Select
                            labelId="base-route-label"
                            label="Расположение"
                            value={
                                hAlign !== 'space-between'
                                    ? 'flex-start'
                                    : 'space-between'
                            }
                        >
                            <MenuItem
                                value="flex-start"
                                onClick={() => onHalignChange('flex-start')}
                            >
                                Вплотную
                            </MenuItem>
                            <MenuItem
                                value="space-between"
                                onClick={() => onHalignChange('space-between')}
                            >
                                По ширине
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined">
                        <TextField
                            label="Кол-во столбцов"
                            minRows={1}
                            multiline
                            size="small"
                            type="number"
                            fullWidth
                            value={cols || 'Авто'}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <Stack sx={{ mr: -0.5 }}>
                                        <IconButton
                                            sx={{ p: 0 }}
                                            onClick={() =>
                                                onColsChange((cols || 0) + 1)
                                            }
                                            disabled={(cols || 0) > 5}
                                        >
                                            <ExpandLessIcon />
                                        </IconButton>
                                        <IconButton
                                            sx={{ p: 0 }}
                                            disabled={!cols}
                                            onClick={() =>
                                                onColsChange((cols || 0) - 1)
                                            }
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </Stack>
                                ),
                            }}
                        />
                    </FormControl>
                </Stack>
                <StyledRowControlGrid
                    container
                    sx={{ height: '100%', maxWidth: '130px' }}
                    className="svg-color-inherit"
                >
                    <Grid item xs={4}>
                        <Button
                            variant="text"
                            data-active={
                                (hAlign === 'flex-start' ||
                                    hAlign === 'space-between') &&
                                vAlign === 'flex-start'
                            }
                            onClick={() =>
                                onHValignChange({
                                    h: 'flex-start',
                                    v: 'flex-start',
                                })
                            }
                        >
                            <ReactSVG src={leftAlign} />
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="text"
                            data-active={
                                (hAlign === 'center' ||
                                    hAlign === 'space-between') &&
                                vAlign === 'flex-start'
                            }
                            onClick={() =>
                                onHValignChange({
                                    h: 'center',
                                    v: 'flex-start',
                                })
                            }
                        >
                            <ReactSVG src={leftAlign} />
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="text"
                            data-active={
                                (hAlign === 'flex-end' ||
                                    hAlign === 'space-between') &&
                                vAlign === 'flex-start'
                            }
                            onClick={() =>
                                onHValignChange({
                                    h: 'flex-end',
                                    v: 'flex-start',
                                })
                            }
                        >
                            <ReactSVG src={leftAlign} />
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="text"
                            data-active={
                                (hAlign === 'flex-start' ||
                                    hAlign === 'space-between') &&
                                vAlign === 'center'
                            }
                            onClick={() =>
                                onHValignChange({
                                    h: 'flex-start',
                                    v: 'center',
                                })
                            }
                        >
                            <ReactSVG src={centerAlign} />
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="text"
                            data-active={
                                (hAlign === 'center' ||
                                    hAlign === 'space-between') &&
                                vAlign === 'center'
                            }
                            onClick={() =>
                                onHValignChange({
                                    h: 'center',
                                    v: 'center',
                                })
                            }
                        >
                            <ReactSVG src={centerAlign} />
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="text"
                            data-active={
                                (hAlign === 'flex-end' ||
                                    hAlign === 'space-between') &&
                                vAlign === 'center'
                            }
                            onClick={() =>
                                onHValignChange({
                                    h: 'flex-end',
                                    v: 'center',
                                })
                            }
                        >
                            <ReactSVG src={centerAlign} />
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="text"
                            data-active={
                                (hAlign === 'flex-start' ||
                                    hAlign === 'space-between') &&
                                vAlign === 'flex-end'
                            }
                            onClick={() =>
                                onHValignChange({
                                    h: 'flex-start',
                                    v: 'flex-end',
                                })
                            }
                        >
                            <ReactSVG src={rightAlign} />
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="text"
                            data-active={
                                (hAlign === 'center' ||
                                    hAlign === 'space-between') &&
                                vAlign === 'flex-end'
                            }
                            onClick={() =>
                                onHValignChange({
                                    h: 'center',
                                    v: 'flex-end',
                                })
                            }
                        >
                            <ReactSVG src={rightAlign} />
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="text"
                            data-active={
                                (hAlign === 'flex-end' ||
                                    hAlign === 'space-between') &&
                                vAlign === 'flex-end'
                            }
                            onClick={() =>
                                onHValignChange({
                                    h: 'flex-end',
                                    v: 'flex-end',
                                })
                            }
                        >
                            <ReactSVG src={rightAlign} />
                        </Button>
                    </Grid>
                </StyledRowControlGrid>
            </Stack>
        </Stack>
    );
};
