/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { Key } from 'react';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    PageBuilderState,
    PageBuilderErrors,
    LeftToolbars,
    RightToolbars,
} from 'types/builder/pageBuilder';
import { findNodeByKey } from 'utils/builder';

const initialState: PageBuilderState = {
    errors: [],
    selectedNode: undefined,
    selectedPage: undefined,
    config: undefined,
    toolbars: {
        left: 'pageSelect',
        right: undefined,
    },
};

type SetErrorAction = {
    name: PageBuilderErrors;
    error: boolean;
};

export const pageBuilderSlice = createSlice({
    name: 'pageBuilder',
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<SetErrorAction>) => {
            const { error, name } = action.payload;

            if (!error) {
                state.errors = [...state.errors.filter((el) => el !== name)];
            }

            if (error && !state.errors.includes(name)) {
                state.errors = [...state.errors, name];
            }
        },
        resetErrors: (state) => {
            state.errors = [];
        },

        setSelectedNode: (state, action: PayloadAction<Key | undefined>) => {
            const key = action.payload;

            if (key && state.config?.pageConfig) {
                state.selectedNode = findNodeByKey(
                    [...state.config.pageConfig],
                    key
                ) as typeof state.selectedNode;
            } else {
                state.selectedNode = undefined;
            }
        },
        setSelectedPage: (
            state,
            action: PayloadAction<PageBuilderState['selectedPage']>
        ) => {
            state.selectedPage = action.payload;
        },
        setConfig: (
            state,
            action: PayloadAction<PageBuilderState['config']>
        ) => {
            state.config = action.payload as typeof state.config;

            if (state.config?.pageConfig && state.selectedNode?._key) {
                state.selectedNode = findNodeByKey(
                    [...state.config.pageConfig],
                    state.selectedNode?._key
                ) as typeof state.selectedNode;
            }
        },
        setActiveExpand: (
            state,
            action: PayloadAction<{
                name:
                    | PageBuilderState['toolbars']['left']
                    | PageBuilderState['toolbars']['right'];
                value: boolean;
            }>
        ) => {
            const { name, value } = action.payload;

            const leftToolBars: LeftToolbars[] = [
                'mapPoints',
                'nodeTree',
                'pageSelect',
            ];
            const rightToolBars: RightToolbars[] = [
                'nodeSettings',
                'pageSettings',
                'quizSettings',
                'mapPointSettings',
            ];

            if (leftToolBars.some((el) => el === name)) {
                state.toolbars.left = value
                    ? (name as LeftToolbars)
                    : undefined;
            }
            if (rightToolBars.some((el) => el === name)) {
                state.toolbars.right = value
                    ? (name as RightToolbars)
                    : undefined;
            }
        },
    },
});

export const {
    setError,
    resetErrors,
    setSelectedNode,
    setSelectedPage,
    setConfig,
    setActiveExpand,
} = pageBuilderSlice.actions;

export const pageBuilderSliceReducer = pageBuilderSlice.reducer;
