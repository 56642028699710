import { useState, useEffect } from 'react';

import { GeoJsonSource } from '@2gis/mapgl/types';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { Button, Stack } from '@mui/material';
import { AnimatePresence, Variants } from 'framer-motion';

import { Typography } from 'components/atoms/Typography';
import { useMapInstance } from 'hooks/api/map/useMapInstance';
import { useMapPosition } from 'hooks/api/map/useMapPosition';
import useLocalStorage from 'hooks/useLocalStorage';
import { RoutingResult } from 'types/map/2gis';
import { NavigationLocalSave, SelectedLocation } from 'types/map/location';
import { drawRoute } from 'utils/map';

import {
    NavigationInfoMotion,
    NavigationInfoWrapper,
} from './RouteNavigation.styled';

const NavInfoVariants: Variants = {
    open: {
        y: 0,
        transition: {
            delay: 0.3,
        },
    },
    closed: {
        y: '200%',
    },
};

type RouteNavigationProps = {
    startLocation?: SelectedLocation;
    endLocation?: SelectedLocation;
    route?: RoutingResult;
    onNavCancel?: () => void;
};

export const RouteNavigation = ({
    startLocation,
    endLocation,
    route,
    onNavCancel = () => undefined,
}: RouteNavigationProps): JSX.Element => {
    const [geojsonSource, setGeojsonSource] = useState<GeoJsonSource>();

    const { mapInstance, mapgl } = useMapInstance();

    useEffect(() => {
        if (mapInstance && mapgl) {
            const source = new mapgl.GeoJsonSource(mapInstance, {
                data: {
                    type: 'FeatureCollection',
                    features: [],
                },
                attributes: {
                    foo: 'bar',
                },
            });

            setGeojsonSource(source);
        }
    }, [mapInstance, mapgl]);

    useEffect(() => {
        return () => {
            if (geojsonSource) {
                geojsonSource.destroy();
            }
        };
    }, [geojsonSource]);

    const [, setNavigation] = useLocalStorage<NavigationLocalSave>(
        'info67mapNavigation',
        {}
    );

    useEffect(() => {
        if (geojsonSource && startLocation && endLocation) {
            drawRoute(
                geojsonSource,
                [
                    {
                        x: startLocation.latitude,
                        y: startLocation.longitude,
                        type: 'walking',
                        floor_id: startLocation.floor?.floor_id,
                    },
                    {
                        x: endLocation.latitude,
                        y: endLocation.longitude,
                        type: 'walking',
                        floor_id: endLocation.floor?.floor_id,
                    },
                ],
                route
            );
            document
                .querySelector('.floor-control')
                ?.classList.remove('hidden');
        }
    }, [route, geojsonSource, startLocation, endLocation]);

    const { setMapToLocation, setMapFloor } = useMapPosition();

    useEffect(() => {
        if (startLocation && endLocation && mapInstance) {
            setMapToLocation({
                x: startLocation.latitude,
                y: startLocation.longitude,
                zoom: 21,
            });
            setMapFloor({
                floorLevelIndex: startLocation.floor?.floor_number,
                floorPlanId: startLocation.building?.floor_plan_id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endLocation, mapInstance, startLocation, setMapFloor]);

    return (
        <Stack sx={{ flex: 1 }}>
            <AnimatePresence exitBeforeEnter>
                {route && startLocation && endLocation ? (
                    <NavigationInfoMotion
                        key={route.id}
                        variants={NavInfoVariants}
                        initial="closed"
                        animate="open"
                        exit="closed"
                    >
                        <NavigationInfoWrapper>
                            <Stack spacing={1}>
                                <Typography variant="title.medium">
                                    Маршрут до «{endLocation.name}» (
                                    {route.ui_total_duration})
                                </Typography>
                                <Stack direction="row" spacing={0.5}>
                                    <ApartmentIcon />
                                    <Typography>
                                        {`${endLocation.building?.name} > ${endLocation.floor?.floor_number} этаж`}
                                    </Typography>
                                </Stack>
                                <Button
                                    onClick={() => {
                                        onNavCancel();
                                        setNavigation({});
                                    }}
                                    variant="outlined"
                                >
                                    Отмена
                                </Button>
                            </Stack>
                        </NavigationInfoWrapper>
                    </NavigationInfoMotion>
                ) : undefined}
            </AnimatePresence>
        </Stack>
    );
};
