import styled from 'styled-components';

export const PageEditorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: ${({ theme }) => theme.palette.grey.bg2};

    .hovered {
        position: relative;

        &::after {
            content: '';
            border-radius: inherit;
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #0c6d36;
            opacity: 0.2;
        }
    }

    .selected {
        outline: 2px dashed #0c6d36;
    }

    border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const EditorToolbarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.6);
    &:first-of-type {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
    &:last-of-type {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    .MuiAccordion-root {
        &.Mui-expanded {
            flex: 1;
        }
        .MuiCollapse-wrapper {
            height: 100%;
        }
        .MuiCollapse-wrapper,
        .MuiAccordion-region {
            display: flex;
            flex-direction: column;
            flex: 1;
            & > div {
                display: flex;
                flex-direction: column;
                flex: 1;
            }
        }
        display: flex;
        flex-direction: column;
        .MuiCollapse-entered {
            flex: 1;
        }
    }

    width: 27%;

    max-height: 100vh;

    overflow: auto;

    position: relative;
`;

export const EditorPreviewWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;
