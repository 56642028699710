import { useMemo, useState } from 'react';

import { Box, Button, CircularProgress, Stack, Tooltip } from '@mui/material';

import { useAppSelector } from 'hooks/store/useAppStore';

import { ChangesResetModal } from '../ChangesResetModal';

type PageSaverControlProps = {
    hasChanges: boolean;
    loading: boolean;
    onSave: () => void;
    onReset: () => void;
};

export const PageSaverControl = ({
    hasChanges,
    loading,
    onReset,
    onSave,
}: PageSaverControlProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);

    const { errors } = useAppSelector((state) => state.pageBuilder);

    const error = useMemo(
        () =>
            errors.includes('pageSettings') || errors.includes('quizSettings'),
        [errors]
    );

    return (
        <Stack
            spacing={2}
            sx={{
                p: '20px 28px',
                borderBottom: 1,
                borderColor: 'divider',
            }}
        >
            <Stack direction="row" spacing={2}>
                <Button
                    size="small"
                    variant="outlined"
                    fullWidth
                    color="error"
                    disabled={!hasChanges || loading}
                    onClick={() => setIsOpen(true)}
                >
                    Отменить изменения
                </Button>

                <Tooltip
                    title={error ? 'Исправьте ошибки перед сохранением' : ''}
                    arrow
                    placement="bottom"
                >
                    <Box sx={{ width: '100%' }}>
                        <Button
                            size="small"
                            variant="contained"
                            fullWidth
                            disabled={!hasChanges || loading || error}
                            onClick={() => onSave()}
                            sx={{ height: '100%' }}
                        >
                            {loading ? (
                                <CircularProgress
                                    size={20}
                                    variant="indeterminate"
                                    color="inherit"
                                />
                            ) : (
                                'Сохранить страницу'
                            )}
                        </Button>
                    </Box>
                </Tooltip>
            </Stack>
            <ChangesResetModal
                onClose={() => setIsOpen(false)}
                open={isOpen}
                onConfirm={() => onReset()}
            />
        </Stack>
    );
};
