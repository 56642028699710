export enum NotificationEvents {
    ManagerNewMessageInChat = 'manager.new.message.in.chat',
    GuestNewMessageInChat = 'guest.new.message.in.chat',
    ManagerNewChatAttached = 'manager.new.chat.attached',
    ManagerNewChat = 'manager.new.chat',
    ManagerIntegrationSuccess = 'manager.integration.success',
    ManagerShowTransferChat = 'manager.show.transfer.chat',
}

export type ManagerIntegrationEvent = {
    event: NotificationEvents.ManagerIntegrationSuccess;
    payload: Record<string, never>;
};

export type ManagerNewChatAttachedEvent = {
    event: NotificationEvents.ManagerNewChatAttached;
    payload: {
        chat: {
            id: number;
            token: string;
        };
    };
};
export type ManagerNewChatEvent = {
    event: NotificationEvents.ManagerNewChat;
    payload: {
        chat: {
            id: number;
            token: string;
        };
    };
};

export type ManagerNewMessageInChatEvent = {
    event:
        | NotificationEvents.ManagerNewMessageInChat
        | NotificationEvents.GuestNewMessageInChat;
    payload: {
        chat_id: number;
    };
};
export type ManagerShowTransferChatEvent = {
    event: NotificationEvents.ManagerShowTransferChat;
    payload: {
        chat_id: number;
        email: string;
    };
};

export type NotificationEvent =
    | ManagerIntegrationEvent
    | ManagerNewChatAttachedEvent
    | ManagerNewChatEvent
    | ManagerNewMessageInChatEvent
    | ManagerShowTransferChatEvent;
