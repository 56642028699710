import styled from 'styled-components';

export const SearchListScroll = styled.div`
    max-height: calc(100vh - 87px - 144px);

    @media screen and (max-width: 450px) {
        max-height: calc(100vh - 69px - 144px);
    }

    height: 100%;

    overflow: hidden;

    margin-bottom: -25px;

    @media screen and (max-width: 450px) {
        margin-bottom: -16px;
    }

    .site-tree-search-value {
        color: #f50;
    }

    .MuiListItemText-primary {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const SearchListContainer = styled.div`
    flex: 1;

    transition: visibility 0.3s ease-in-out;

    margin-top: 25px;

    @media screen and (max-width: 450px) {
        margin-top: 16px;
    }
`;
