import { SVGAsset } from 'components/atoms/SVGAsset';
import { useGoBack } from 'hooks/useGoBack';

import { StyledGoBackLink } from './GoBackLink.styled';

export const GoBackLink = ({
    disabled,
}: {
    disabled?: boolean;
}): JSX.Element => {
    const { goBack } = useGoBack();

    return (
        <StyledGoBackLink onClick={!disabled ? () => goBack() : undefined}>
            <SVGAsset src="icons.24px.chevron" />
            вернуться назад
        </StyledGoBackLink>
    );
};
