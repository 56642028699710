import styled from 'styled-components';

export const NodeTreeScrollContainer = styled.div`
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow: hidden;
    padding: 0 28px;
    padding-bottom: 28px;

    .ant-tree {
        margin-right: -28px;
        .ant-tree-treenode {
            .icons {
                svg {
                    width: 24px;
                    height: 24px;
                    padding: 3px;
                }
            }

            .ant-tree-iconEle {
                svg path {
                    &[stroke] {
                        stroke: currentColor;
                    }
                    &[fill] {
                        fill: currentColor;
                    }
                }
            }

            &.indexed {
                .ant-tree-iconEle {
                    color: #f50;
                }
            }
            span {
                flex-shrink: 0;
            }
            .ant-tree-draggable-icon {
                display: none;
            }
            .site-tree-search-value {
                color: #f50;
            }
        }
    }
`;
