/* eslint-disable import/no-unresolved */
import {
    ComponentPropsWithoutRef,
    memo,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { load } from '@2gis/mapgl';
import {
    ControlOptions,
    FloorControl,
    Map as MapInstance,
    MapOptions,
    ZoomControl,
} from '@2gis/mapgl/types';
import { Stack } from '@mui/material';

import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useMapInstance } from 'hooks/api/map/useMapInstance';
import { MapGL } from 'types/map/2gis';

import * as Style from './Map.styled';

type MapProps = {
    options?: MapOptions;
    /**
     * Добавляет заданный элемент внутрь контейнера карты
     */
    controlsPane?: ReactNode;
    fullScreen?: boolean;
};

export const MapWrapper = memo(
    (props: ComponentPropsWithoutRef<'div'>) => {
        return <Style.MapWrapper {...props} />;
    },
    () => true
);

const initZoomControl = (
    map: MapInstance,
    mapgl: MapGL,
    options: ControlOptions
): ZoomControl => {
    const zoomControl = new mapgl.ZoomControl(map, options);

    zoomControl.getContainer().classList.add('zoom-control');

    return zoomControl;
};
const initFloorControl = (
    map: MapInstance,
    mapgl: MapGL,
    options: ControlOptions
): FloorControl => {
    const floorControl = new mapgl.FloorControl(map, options);

    const floorLabel = document.createElement('div');
    floorLabel.classList.add('floor-label');
    floorLabel.innerHTML = 'Этаж';

    floorControl.getContainer().classList.add('floor-control');

    floorControl.getContainer().querySelector('div')?.prepend(floorLabel);

    return floorControl;
};

export const Map = ({
    options,
    controlsPane,
    fullScreen = true,
}: MapProps): JSX.Element => {
    const [isError, setIsError] = useState(false);

    const { setMapInstance, mapInstance, setFloorPlanId } = useMapInstance();

    const { zoomControl, floorControl, ...rest } = options || {};

    useEffect(() => {
        let map: MapInstance | undefined;
        let customZoomControl: ZoomControl | undefined;
        let customFloorControl: FloorControl | undefined;

        load()
            .then((mapgl) => {
                setIsError(false);
                map = new mapgl.Map('map-container', {
                    key: process.env.REACT_APP_MAPGL_KEY,
                    zoomControl: false,
                    floorControl: false,
                    controlsLayoutPadding: {
                        left: 25,
                        right: 25,
                        top: 25,
                        bottom: 25,
                    },
                    maxBounds: {
                        southWest: [37.46356844736752, 55.76900472242359],
                        northEast: [37.4701356821171, 55.77521224313259],
                    },
                    style: 'c2e3b675-90fc-49ac-b633-3fd6ed6d3727',
                    ...rest,
                });

                if (typeof zoomControl !== 'boolean') {
                    customZoomControl = initZoomControl(map, mapgl, {
                        position: zoomControl || 'topRight',
                    });
                }
                if (typeof floorControl !== 'boolean') {
                    customFloorControl = initFloorControl(map, mapgl, {
                        position: floorControl || 'topLeft',
                    });
                }

                setMapInstance(map, mapgl);

                map.on('floorplanshow', (e) => setFloorPlanId(e.floorPlanId));
            })
            .catch(() => {
                setIsError(true);
            });

        return () => {
            if (customZoomControl) {
                customZoomControl.destroy();
            }
            if (customFloorControl) {
                customFloorControl.destroy();
            }
            if (map) {
                map.destroy();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const invalidateMapSize = useCallback(() => {
        if (mapInstance) {
            mapInstance.invalidateSize();
        }
    }, [mapInstance]);

    useEffect(() => {
        window.addEventListener('resize', invalidateMapSize);

        return () => {
            window.removeEventListener('resize', invalidateMapSize);
        };
    }, [invalidateMapSize]);

    return (
        <Style.MapContainer data-fullscreen={fullScreen}>
            {!isError ? (
                <>
                    <MapWrapper data-fullscreen={fullScreen} />
                    {controlsPane}
                </>
            ) : (
                <Stack
                    spacing={3}
                    sx={{ flex: 1 }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <SVGAsset src="icons.56px.negative" />
                    <Typography variant="title.large" textAlign="center">
                        Что-то пошло не так
                    </Typography>
                    <Typography textAlign="center">
                        Не удалось удалить загрузить карту
                    </Typography>
                </Stack>
            )}
        </Style.MapContainer>
    );
};
