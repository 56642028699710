import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ChatMessageWrapper = styled(motion.div)`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    padding: 8px 16px;
    padding-bottom: 24px;

    &[data-type='in'] {
        justify-content: flex-end;
        flex-direction: row-reverse;
    }
`;

export const ChatMessageImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: scale-down;

    max-height: 300px;

    min-height: 100px;

    border-radius: 8px;

    margin: 8px 0;

    background-color: ${({ theme }) => theme.palette.grey.bg2};

    cursor: pointer;
`;

export const ChatTextBubble = styled.div`
    ${({ theme }) => theme.typography.body.large};
    padding: 8px 16px;
    color: ${({ theme }) => theme.palette.common.white};
    max-width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.green.betta};
    border-radius: ${({ theme }) => theme.borderRadius.large};
    border-bottom-right-radius: 0;
    min-height: 57px;
    min-width: 15px;
    margin-right: 9px;
    position: relative;
    white-space: pre-line;
    word-break: normal;

    &::after {
        position: absolute;
        content: '';
        right: -8px;
        bottom: 0;
        border-top: 15px solid transparent;
        border-left: 9px solid ${({ theme }) => theme.palette.green.betta};
    }

    & > div {
        display: inline-block;

        a {
            text-decoration: underline;
            word-break: break-word;
        }
    }

    &[data-type='in'] {
        background-color: ${({ theme }) => theme.palette.common.white};
        color: ${({ theme }) => theme.palette.common.grey};
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 0;
        margin-right: 0px;
        margin-left: 9px;

        &::after {
            left: -9px;
            right: auto;
            border-left: none;
            border-right: 9px solid ${({ theme }) => theme.palette.common.white};
        }

        & > div a {
            color: ${({ theme }) => theme.palette.green.betta};
        }
    }
`;

export const MessageStatus = styled.span`
    ${({ theme }) => theme.typography.body.small};
    color: ${({ theme }) => theme.palette.grey.inactive};
    display: flex;
    align-items: center;
    position: absolute;

    white-space: nowrap;

    bottom: 0;
    right: 0;
    left: 0;

    padding: 0 16px;

    .status {
        margin-left: 4px;
        position: relative;
        svg path {
            stroke-width: 40px;
        }
        &.sent,
        &.received {
            svg path {
                stroke: ${({ theme }) => theme.palette.grey.inactive};
            }
        }
        &.read {
            svg path {
                stroke: ${({ theme }) => theme.palette.green.betta};
            }
        }
        &.loading {
            height: 12px;
        }
    }

    .manager-label {
        margin-right: 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
    }

    justify-content: flex-end;

    &[data-type='in'] {
        justify-content: flex-start;
    }
`;
