import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import useAxios from 'hooks/api/useAxios';
import { TelegramCreate } from 'types/api/telegramCreate';

type UseTgAttachReturn = {
    getTgAttachLink: () => void;
    success: boolean;
    loading: boolean;
    data?: TelegramCreate;
};

export const useTgAttach = (): UseTgAttachReturn => {
    const navigate = useNavigate();

    const [res, { rerun }] = useAxios<TelegramCreate>(
        {
            url: '/api/integrations/telegram/',
            method: 'POST',
            data: {},
            skipRequest: () => true,
        },
        []
    );
    const success = res.type === 'success';
    const loading = res.type === 'loading';
    const data = success && !loading ? res.data : undefined;

    const getTgAttachLink = (): void => {
        rerun();
    };

    useEffect(() => {
        if (success && data) {
            window.open(data.url, '_blank');
        }
    }, [data, success, navigate]);

    return { getTgAttachLink, success, loading, data };
};
