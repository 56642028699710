import { useCallback, useEffect, useState } from 'react';

import { MapPointerEvent } from '@2gis/mapgl/types';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import { useSnackbar } from 'notistack';

import { ComponentCreatorWrapper } from 'components/organisms/PageEditor/editorControls/ComponentCreator/ComponentCreator.styled';
import { useNewLocationSelector } from 'hooks/api/builder/points/useNewLocationSelector';
import { useMapInstance } from 'hooks/api/map/useMapInstance';
import { useMeta } from 'hooks/api/useMeta';

import { MapPointCreatorModal } from '../../MapPointCreatorModal';

type NewPointCreatorProps = {
    onSuccess: (id: number) => void;
    disabled?: boolean;
};

export const NewPointCreator = ({
    onSuccess,
    disabled,
}: NewPointCreatorProps): JSX.Element => {
    const [pointModalIsOpen, setPointModalIsOpen] = useState(false);

    const { setMode, mode, setLocation, resetAll } = useNewLocationSelector();

    const { enqueueSnackbar } = useSnackbar();

    const { mapInstance } = useMapInstance();

    const { meta: floors } = useMeta('adminFloors');
    const { meta: buildings } = useMeta('adminBuildings');

    const onMapClick = useCallback(
        (e: MapPointerEvent) => {
            if (floors && buildings) {
                if (!e.targetData || e.targetData.type !== 'default') {
                    enqueueSnackbar('Нельзя поставить точку в этом месте', {
                        variant: 'error',
                        key: 'locations-point-error',
                    });
                    return;
                }

                const {
                    lngLat,
                    targetData: { id, floorId },
                } = e;

                const floor = floors.find((el) => el.floor_id === floorId);

                const building = buildings.find((el) => el.building_id === id);

                if (building && floor && mode) {
                    setLocation({
                        latitude: lngLat[0],
                        longitude: lngLat[1],
                        building,
                        floor,
                    });
                    if (mode !== 'update') {
                        setPointModalIsOpen(true);
                    }
                } else {
                    enqueueSnackbar('Нельзя поставить точку в этом месте', {
                        variant: 'error',
                        key: 'locations-point-error',
                    });
                }
            }
        },
        [buildings, enqueueSnackbar, floors, mode, setLocation]
    );

    useEffect(() => {
        if (mode !== undefined && mapInstance && floors && buildings) {
            mapInstance.on('click', onMapClick);
        }

        return () => {
            mapInstance?.off('click', onMapClick);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapInstance, mode]);

    return (
        <>
            <ComponentCreatorWrapper>
                <Fab
                    color="secondary"
                    aria-label="add"
                    size="medium"
                    onClick={() => {
                        setMode('new');
                    }}
                    disabled={disabled}
                >
                    <AddIcon />
                </Fab>
            </ComponentCreatorWrapper>
            {pointModalIsOpen && mode !== 'update' ? (
                <MapPointCreatorModal
                    open
                    onClose={() => {
                        setPointModalIsOpen(false);
                        resetAll();
                    }}
                    onSuccess={onSuccess}
                />
            ) : undefined}
        </>
    );
};
