import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import useAxios from 'hooks/api/useAxios';
import { PageRetrieve } from 'types/api/pageRetrieve';
import { PageMapConfig } from 'types/builder/pageConfig';

type UseUpdatePageReturn = {
    success: boolean;
    error: boolean;
    loading: boolean;
    updatePage: (page: Partial<PageRetrieve>) => void;
};

export const useUpdatePage = (): UseUpdatePageReturn => {
    const [body, setBody] = useState<Partial<PageRetrieve>>();

    const { enqueueSnackbar } = useSnackbar();

    const [res, { rerun }] = useAxios<PageRetrieve>(
        {
            url: `/api/builder/page/${body?.id}/`,
            method: 'PATCH',
            skipRequest: () => true,
            data: body,
        },
        [body]
    );

    const success = res.type === 'success';
    const error = res.type === 'error';
    const loading = res.type === 'loading';

    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                'Что-то пошло не так... Не удалось сохранить изменения.',
                {
                    variant: 'error',
                }
            );
        }
        if (success) {
            enqueueSnackbar('Изменения страницы сохранены.', {
                variant: 'success',
            });
        }
    }, [error, success, enqueueSnackbar]);

    const updatePage = (page: Partial<PageMapConfig>): void => {
        setBody({
            config: page.pageConfig || page.quizConfig,
            description: page.description,
            id: page.id,
            is_published: page.isPublished,
            path: page.path,
            route_uuid: page.baseRoute,
            show_bottom_navLink: page.showBottomNavLink,
            show_chat_button: page.showChatButton,
            show_nav_bar: page.showNavBar,
            show_top_app_bar: page.showTopAppBar,
            title: page.pageTitle,
            type: page.type,
            uuid: page.uuid,
        });
        rerun();
    };

    return { success, error, loading, updatePage };
};
