import { ChangeEventHandler } from 'react';

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';

import { BasicButtonProps } from 'components/atoms/BasicButton';
import { ButtonColorBadge } from 'components/atoms/BasicButton/ButtonButton.styled';
import { BraceletHeaderProps } from 'components/molecules/BraceletHeader';
import { BraceletHeaderConfig } from 'types/builder/componentConfig';

import { IconSelector } from '../../editorControls/IconSelector';

type HeaderControlProps = {
    node?: BraceletHeaderConfig;
    onChange: (config: BraceletHeaderConfig) => void;
};

type HeaderColors = {
    value: BraceletHeaderProps['variant'];
    title: string;
};

const colors: HeaderColors[] = [
    {
        value: 'Green',
        title: 'Зеленый',
    },
    {
        value: 'Yellow',
        title: 'Желтый',
    },
    {
        value: 'Red',
        title: 'Красный',
    },
];

export const HeaderControl = ({
    onChange,
    node,
}: HeaderControlProps): JSX.Element => {
    const {
        children,
        variant = 'Green',
        icon = 'icons.40px.handGreen',
    } = node?.props || {};

    const onIconChange = (val: BraceletHeaderProps['icon'] | string): void => {
        onChange({
            component: 'braceletHeader',
            props: {
                ...node?.props,
                icon: val,
            },
        });
    };

    const onTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        onChange({
            component: 'braceletHeader',
            props: {
                ...node?.props,
                children: value,
            },
        });
    };

    const onColorChange = (value: BraceletHeaderProps['variant']): void => {
        onChange({
            component: 'braceletHeader',
            props: {
                ...node?.props,
                variant: value,
            },
        });
    };

    return (
        <Stack spacing={2}>
            <FormControl variant="outlined">
                <TextField
                    label="Текст заголовка"
                    minRows={1}
                    multiline
                    size="small"
                    fullWidth
                    name=""
                    value={children || ''}
                    onChange={onTextChange}
                />
            </FormControl>
            <FormControl variant="outlined" size="small">
                <InputLabel id="base-route-label">Цвет</InputLabel>
                <Select
                    labelId="base-route-label"
                    label="Цвет"
                    value={variant || ''}
                >
                    {colors.map((el, i) => (
                        <MenuItem
                            key={`menu_item_${i}`}
                            value={el.value}
                            onClick={() => onColorChange(el.value)}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <ButtonColorBadge
                                    $bgColor={
                                        el.value?.toLocaleLowerCase() as BasicButtonProps['$bgColor']
                                    }
                                />
                                <span>{el.title}</span>
                            </Stack>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <IconSelector showPreview icon={icon} onSelect={onIconChange} />
        </Stack>
    );
};
