import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import {
    setSearch,
    setIsArchive,
    setIsFilter,
    resetFilter,
    setUsersFilter,
} from 'store/slices/usersSlice';

interface IUseStaffFilterReturn {
    search: string | undefined;
    activeFilters: number[];
    is_archive: boolean;

    setIsArchiveFilter: () => void;
    setFilter: (id: number) => void;
    setResetFilter: () => void;
    setSearchFilter: (value: string) => void;
}
export const useStaffFilter = (): IUseStaffFilterReturn => {
    const search = useAppSelector((state) => state.users.search);
    const activeFilters = useAppSelector((state) => state.users.activeFilters);
    const is_archive = useAppSelector((state) => state.users.is_archive);

    const dispatch = useAppDispatch();

    const setIsArchiveFilter = (): void => {
        dispatch(setIsArchive());
        dispatch(setUsersFilter());
    };

    const setFilter = (id: number): void => {
        dispatch(setIsFilter({ id }));
        dispatch(setUsersFilter());
    };

    const setResetFilter = (): void => {
        dispatch(resetFilter());
        dispatch(setUsersFilter());
    };

    const setSearchFilter = (value: string): void => {
        dispatch(setSearch(value));
        dispatch(setUsersFilter());
    };

    return {
        search,
        activeFilters,
        is_archive,
        setFilter,
        setResetFilter,
        setSearchFilter,
        setIsArchiveFilter,
    };
};
