import { motion } from 'framer-motion';
import styled from 'styled-components';

export const BlockerModalWrapper = styled(motion.div)`
    position: fixed;
    top: 0px;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: auto;

    z-index: 9999;

    background-color: ${({ theme }) => theme.palette.common.white};
`;

export const BlockerScreenView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-width: 320px;
    margin: 0 auto;
    padding: 60px 16px;

    text-align: center;

    min-height: 612px;

    text-align: center;
`;

export const BlockerControlsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: -12px;

    & > div {
        padding: 12px;
        width: 100%;
    }
`;

export const BlockerBackBtn = styled.button`
    cursor: pointer;
    display: flex;
    ${({ theme }) => theme.typography.title.medium};
    color: ${({ theme }) => theme.palette.green.betta};

    margin: 0 auto;

    .chevron {
        transform: rotate(90deg) scale(0.8);
        svg {
            path {
                stroke: ${({ theme }) => theme.palette.green.betta};
                stroke-width: 3;
            }
        }
    }

    background-color: transparent;
`;
