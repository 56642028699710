/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Websocket } from 'websocket-ts/lib';

type ConnectionStatus = 'closed' | 'connecting' | 'connected' | 'error';

interface NotificationsState {
    value: {
        websocket?: Websocket;
        status: ConnectionStatus;
    };
}

const initialState: NotificationsState = {
    value: {
        websocket: undefined,
        status: 'closed',
    },
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setWebsocket: (
            state,
            action: PayloadAction<NotificationsState['value']['websocket']>
        ) => {
            state.value.websocket = action.payload;
        },
        setStatus: (
            state,
            action: PayloadAction<NotificationsState['value']['status']>
        ) => {
            state.value.status = action.payload;
        },
    },
});

export const { setWebsocket, setStatus } = notificationsSlice.actions;

export const notificationsSliceReducer = notificationsSlice.reducer;
