import { ChangeEventHandler, useCallback, useMemo, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    InputAdornment,
    LinearProgress,
    TextField,
} from '@mui/material';

import { Typography } from 'components/atoms/Typography';
import { useBuilderToolbars } from 'hooks/api/builder/pages/usePageBuilder';
import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { setSelectedPage } from 'store/slices/pageBuilderSlice';
import { PageList } from 'types/api/pageList';

import { NodeTreeScrollContainer } from '../NodeTree/NodeTree.styled';
import { NewPageControl } from './NewPageControl';
import { PageListWrapper } from './PageSelectControl.styled';
import { PageSelectTree } from './PageSelectTree';

type PageSelectControlProps = {
    selectionBlocked: boolean;
    onNewPageAdded: (newPage: number) => void;
    pageList: PageList[];
    loading?: boolean;
    error?: boolean;
};

export const PageSelectControl = ({
    selectionBlocked,
    onNewPageAdded,
    pageList,
    error,
    loading,
}: PageSelectControlProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const { selectedPage, config } = useAppSelector(
        (state) => state.pageBuilder
    );

    const onPageSelect = useCallback(
        (page?: number) => {
            dispatch(setSelectedPage(page));
        },
        [dispatch]
    );

    const { setActiveToolBar, toolbars } = useBuilderToolbars();

    const currentPageList = useMemo<PageList[]>(() => {
        if (selectedPage && config) {
            return (
                pageList?.map((el) => {
                    if (el.id === selectedPage) {
                        return {
                            ...el,
                            path: config.path || '',
                            route_uuid: config.baseRoute || '',
                            is_published: config.isPublished || false,
                            title: config.pageTitle || '',
                        };
                    }
                    return el;
                }) || []
            );
        }
        return pageList || [];
    }, [config, pageList, selectedPage]);

    const [searchValue, setSearchValue] = useState('');

    const onSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        setSearchValue(value);
    };

    const getParentPath = useCallback(
        (key?: string) => {
            return currentPageList.find((p) => p.uuid === key)?.href;
        },
        [currentPageList]
    );

    const pages = useMemo<PageList[]>(() => {
        return currentPageList.map((el) => ({
            ...el,
            href:
                el.id === selectedPage && el.route_uuid
                    ? `${getParentPath(el.route_uuid)}${el.path}`
                    : el.href,
        }));
    }, [getParentPath, currentPageList, selectedPage]);

    return (
        <Accordion
            sx={{
                borderBottom: 1,
                borderColor: 'divider',
                marginBottom: '0 !important',
            }}
            expanded={toolbars.left === 'pageSelect'}
            onChange={(e, v) => setActiveToolBar('pageSelect', v)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    px: '28px',
                    fontSize: toolbars.left === 'pageSelect' ? 22 : 16,
                    fontWeight: 500,
                }}
            >
                {loading ? (
                    <LinearProgress
                        variant="indeterminate"
                        sx={{ position: 'absolute', top: 0, right: 0, left: 0 }}
                    />
                ) : undefined}
                Страницы
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
                <Box sx={{ px: '28px', pb: '20px' }}>
                    <TextField
                        size="small"
                        variant="standard"
                        placeholder="Поиск"
                        value={searchValue}
                        onChange={onSearch}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ position: 'relative', flex: 1 }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            overflowY: 'auto',
                            p: '28px',
                            pt: 0,
                        }}
                    >
                        <PageListWrapper>
                            <NodeTreeScrollContainer>
                                {!loading && !error ? (
                                    <PageSelectTree
                                        pages={pages.sort((a, b) => {
                                            const nameA = a.title.toLowerCase();
                                            const nameB = b.title.toLowerCase();
                                            if (nameA < nameB) return -1;
                                            if (nameA > nameB) return 1;
                                            return 0;
                                        })}
                                        selectedPage={selectedPage}
                                        onPageSelect={onPageSelect}
                                        selectDisabled={selectionBlocked}
                                        searchValue={searchValue}
                                    />
                                ) : undefined}
                                {!loading &&
                                !error &&
                                !currentPageList.length ? (
                                    <Typography>
                                        На данный момент страницы отсутствуют
                                    </Typography>
                                ) : undefined}
                                {!loading &&
                                error &&
                                !currentPageList.length ? (
                                    <Typography>
                                        При загрузке страниц что-то пошло не
                                        так...
                                    </Typography>
                                ) : undefined}
                            </NodeTreeScrollContainer>
                        </PageListWrapper>
                    </Box>
                </Box>
            </AccordionDetails>
            {!loading && !error && toolbars.left === 'pageSelect' ? (
                <NewPageControl
                    disabled={selectionBlocked}
                    onSuccess={(d) => onNewPageAdded(d.id)}
                />
            ) : undefined}
        </Accordion>
    );
};
