import { ContentWrapper } from 'components/atoms/Grid';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useAppTitle } from 'hooks/store/useAppTitle';
import { useGoBack } from 'hooks/useGoBack';

import {
    TopAppBarContentWrapper,
    TopAppBarLogoWrapper,
    TopAppBarNavButton,
    TopAppBarWrapper,
} from './TopAppBar.styled';

export const TopAppBar = (): JSX.Element => {
    const { appTitle } = useAppTitle();

    const { goBack } = useGoBack();

    return (
        <TopAppBarWrapper>
            <ContentWrapper>
                <TopAppBarContentWrapper>
                    <TopAppBarNavButton onClick={() => goBack()}>
                        <SVGAsset src="icons.24px.chevron" />
                        <Typography variant="title.medium">
                            {appTitle}
                        </Typography>
                    </TopAppBarNavButton>
                    <TopAppBarLogoWrapper>
                        <SVGAsset src="logos.logo67" />
                    </TopAppBarLogoWrapper>
                </TopAppBarContentWrapper>
            </ContentWrapper>
        </TopAppBarWrapper>
    );
};
