import alertTriangle from './alert-triangle.svg';
import checkCircle from './check-circle.svg';
import faq from './faq.svg';
import injection from './injection.svg';
import laser from './laser.svg';
import negative from './negative.svg';
import oms from './oms.svg';
import passport from './passport.svg';
import patient from './patient.svg';
import positive from './positive.svg';
import visitor from './visitor.svg';

export const ICONS_56PX_MAP = {
    alertTriangle,
    injection,
    laser,
    faq,
    negative,
    oms,
    passport,
    patient,
    positive,
    visitor,
    checkCircle,
};
