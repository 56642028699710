import { AvatarControl } from 'components/organisms/PageEditor/componentControls/AvatarControl';
import { BasicButtonControl } from 'components/organisms/PageEditor/componentControls/BasicButtonControl';
import { BoxControl } from 'components/organisms/PageEditor/componentControls/BoxControl';
import { CheckboxControl } from 'components/organisms/PageEditor/componentControls/CheckboxControl';
import { CopyListControl } from 'components/organisms/PageEditor/componentControls/CopyListControl';
import { DropdownControl } from 'components/organisms/PageEditor/componentControls/DropdownControl';
import { HeaderControl } from 'components/organisms/PageEditor/componentControls/HeaderControl';
import { IconButtonControl } from 'components/organisms/PageEditor/componentControls/IconButtonControl';
import { ImageControl } from 'components/organisms/PageEditor/componentControls/ImageControl';
import { LinkControl } from 'components/organisms/PageEditor/componentControls/LinkControl';
import { ListControl } from 'components/organisms/PageEditor/componentControls/ListControl';
import { RowControl } from 'components/organisms/PageEditor/componentControls/RowControl';
import { SectionControl } from 'components/organisms/PageEditor/componentControls/SectionControl';
import { SpacerControl } from 'components/organisms/PageEditor/componentControls/SpacerControl';
import { SVGAssetControl } from 'components/organisms/PageEditor/componentControls/SVGAssetControl';
import { TypographyControl } from 'components/organisms/PageEditor/componentControls/TypographyControl';
import {
    OneOfComponentsConfigs,
    PageComponentConfig,
} from 'types/builder/componentConfig';

type ControlConfig = {
    [K in PageComponentConfig['component']]: unknown;
};
type ControlChildConfig = {
    [K in PageComponentConfig['component']]: boolean;
};
type NewComponentDefaultProps = {
    [K in PageComponentConfig['component']]: OneOfComponentsConfigs;
};

export const controlConfig: Partial<ControlConfig> = {
    typography: TypographyControl as unknown,
    avatar: AvatarControl as unknown,
    spacer: SpacerControl as unknown,
    box: BoxControl as unknown,
    row: RowControl as unknown,
    section: SectionControl as unknown,
    list: ListControl as unknown,
    simpleLink: LinkControl as unknown,
    dropdown: DropdownControl as unknown,
    copyList: CopyListControl as unknown,
    checkbox: CheckboxControl as unknown,
    basicButton: BasicButtonControl as unknown,
    image: ImageControl as unknown,
    svgAsset: SVGAssetControl as unknown,
    braceletHeader: HeaderControl as unknown,
    buttonWithIcon: IconButtonControl as unknown,
};

export const controlChildConfig: ControlChildConfig = {
    avatar: false,
    basicButton: false,
    box: true,
    braceletHeader: false,
    buttonWithIcon: false,
    checkbox: false,
    col: true,
    copyList: true,
    doctorCard: false,
    dropdown: true,
    image: false,
    list: false,
    placeholder: false,
    row: true,
    section: true,
    simpleLink: false,
    spacer: false,
    svgAsset: false,
    typography: false,
};

export const newComponentDefaultProps: Partial<NewComponentDefaultProps> = {
    typography: {
        component: 'typography',
        props: {
            children: 'Текст',
            variant: 'body.medium',
        },
    },
    simpleLink: {
        component: 'simpleLink',
        props: {
            children: 'Ссылка',
        },
    },
    list: {
        component: 'list',
        props: {
            items: ['Элемент', 'Элемент', 'Элемент'],
        },
    },
    buttonWithIcon: {
        component: 'buttonWithIcon',
        props: {
            children: 'Кнопка с иконкой',
            linkType: 'internal',
            $bgColor: 'default',
        },
    },
    basicButton: {
        component: 'basicButton',
        props: {
            linkType: 'internal',
            children: 'Кнопка',
        },
    },
    checkbox: {
        component: 'checkbox',
        props: {
            children: 'Галочка',
        },
    },
    svgAsset: {
        component: 'svgAsset',
        props: {
            src: 'logos.logo67',
        },
    },
    braceletHeader: {
        component: 'braceletHeader',
        props: {
            variant: 'Green',
            children: 'Хэдер',
        },
    },
};
