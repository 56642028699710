import { useEffect, useMemo } from 'react';

import { CircularProgress } from '@mui/material';
import { Variants, AnimatePresence } from 'framer-motion';

import { ContentWrapper } from 'components/atoms/Grid';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useChat } from 'hooks/api/chat/useChat';
import { useGuestChatModal } from 'hooks/useGuestChatModal';

import { ChatControl } from '../ChatControl';
import {
    ChatModalWrapper,
    ChatViewWrapper,
    ChatHeader,
    ChatCloseButton,
    LoaderWrapper,
} from './GuestChatModal.styled';

const ModalVariants: Variants = {
    out: {
        y: '100%',
        opacity: 0,
    },
    in: {
        y: 0,
        opacity: 1,
    },
};

const GuestChatControl = (): JSX.Element => {
    const { messages, status, sendMessage, chat_id } = useChat({
        owner: 'guest',
    });

    const chatMessages = useMemo(() => {
        return messages.sort(
            (a, b) =>
                Date.parse(a.created_date || '') -
                Date.parse(b.created_date || '')
        );
    }, [messages]);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('ws/chat - status:', status);
        }
    }, [status]);

    if (status === 'error') {
        return (
            <LoaderWrapper>
                <Typography variant="title.large">
                    Что-то пошло не так...
                </Typography>
                <Typography variant="body.medium">
                    Проверьте ваше подключение к сети
                </Typography>
            </LoaderWrapper>
        );
    }

    return status === 'connected' && chat_id !== null ? (
        <ChatControl
            chatOwner="guest"
            messages={chatMessages}
            onRequestSendMessage={sendMessage}
            chatID={chat_id}
        />
    ) : (
        <LoaderWrapper>
            <CircularProgress color="success" variant="indeterminate" />
        </LoaderWrapper>
    );
};

export const GuestChatModal = (): JSX.Element => {
    const { isOpen, onClose } = useGuestChatModal();

    return (
        <AnimatePresence>
            {isOpen ? (
                <ChatModalWrapper
                    variants={ModalVariants}
                    transition={{
                        duration: 0.3,
                    }}
                    initial="out"
                    animate="in"
                    exit="out"
                >
                    <ContentWrapper>
                        <ChatViewWrapper>
                            <ChatHeader>
                                <Typography variant="title.medium">
                                    Чат
                                </Typography>
                                <ChatCloseButton onClick={() => onClose()}>
                                    <SVGAsset src="icons.24px.closeX" />
                                </ChatCloseButton>
                            </ChatHeader>
                            <GuestChatControl />
                        </ChatViewWrapper>
                    </ContentWrapper>
                </ChatModalWrapper>
            ) : undefined}
        </AnimatePresence>
    );
};
