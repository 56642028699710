import styled, { css } from 'styled-components';

import { ItemListProps } from './index.d';

const dashedListStyles = css`
    list-style-type: '— ';
`;

const diskListStyled = css`
    list-style-type: '•   ';
`;

export const StyledListWrapper = styled.ul<ItemListProps>`
    padding-left: ${({ items, listStyle }) =>
        items && items.length >= 10 && listStyle === undefined
            ? '32px'
            : '16px'};
    margin: 8px 0;
    li {
        ${({ theme }) => theme.typography.body.medium};
        color: ${({ theme }) => theme.palette.common.grey};
        ${({ listStyle }) =>
            listStyle === 'disc' ? diskListStyled : undefined};
        list-style: ${({ listStyle }) =>
            listStyle === undefined ? 'decimal' : undefined};
        ${({ listStyle }) =>
            listStyle === 'dash' ? dashedListStyles : undefined};

        white-space: pre-line;
    }
`;
