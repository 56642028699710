import styled, { css } from 'styled-components';

import { BasicButtonProps } from './types';

const commonButtonStyles = css<BasicButtonProps>`
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.common.white};
    width: 100%;
    justify-content: center;
    text-transform: uppercase;
    padding: 10px 24px;
    border-radius: ${({ theme }) => theme.borderRadius.buttons};
    ${({ theme }) => theme.typography.label.large}
    text-align: center;
    white-space: pre-line;
`;

const greenButtonStyles = css`
    background: ${({ theme }) => theme.palette.gradients.green};
`;

const yellowButtonStyles = css`
    background: ${({ theme }) => theme.palette.gradients.yellow};
`;

const redButtonStyles = css`
    background: ${({ theme }) => theme.palette.gradients.red};
`;

const greenOutlinedButtonStyles = css`
    border-color: ${({ theme }) => theme.palette.green.betta};
    color: ${({ theme }) => theme.palette.green.betta};
`;

const redOutlinedButtonStyles = css`
    border-color: ${({ theme }) => theme.palette.red};
    color: ${({ theme }) => theme.palette.red};
`;

const yellowOutlinedButtonStyles = css`
    border-color: ${({ theme }) => theme.palette.yellow};
    color: ${({ theme }) => theme.palette.yellow};
`;

const secondaryMainButtonStyles = css<BasicButtonProps>`
    background: none;
    border: 2px solid;
    ${(props) =>
        props.$bgColor === 'green' ? greenOutlinedButtonStyles : undefined}
    ${(props) =>
        props.$bgColor === 'yellow' ? yellowOutlinedButtonStyles : undefined}
    ${(props) =>
        props.$bgColor === 'red' ? redOutlinedButtonStyles : undefined}
`;

export const StyledButton = styled.button<BasicButtonProps>`
    ${commonButtonStyles}

    ${(props) => (props.$bgColor === 'green' ? greenButtonStyles : undefined)}
    ${(props) => (props.$bgColor === 'yellow' ? yellowButtonStyles : undefined)}
    ${(props) => (props.$bgColor === 'red' ? redButtonStyles : undefined)}

    ${(props) =>
        props.variant === 'secondary' ? secondaryMainButtonStyles : undefined};

    &:disabled {
        opacity: 0.5;
    }
`;

StyledButton.defaultProps = {
    $bgColor: 'green',
    variant: 'primary',
};

type ButtonColorBadgeProps = Pick<BasicButtonProps, '$bgColor'>;

export const ButtonColorBadge = styled.div<ButtonColorBadgeProps>`
    ${(props) => (props.$bgColor === 'green' ? greenButtonStyles : undefined)}
    ${(props) => (props.$bgColor === 'yellow' ? yellowButtonStyles : undefined)}
    ${(props) => (props.$bgColor === 'red' ? redButtonStyles : undefined)}

    width: 20px;
    height: 20px;
    border-radius: 50%;
`;
