import { useCallback, useEffect, useMemo } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { PageList } from 'types/api/pageList';

export const usePageShareUrl = (
    pages?: PageList[],
    loading?: boolean
): void => {
    const [params, setParams] = useSearchParams();

    const pageUuid = useMemo(() => params.get('page'), [params]);

    const navigate = useNavigate();

    const page = useMemo(() => {
        if (pages && pageUuid) {
            return pages.find((el) => el.uuid === pageUuid);
        }
        return undefined;
    }, [pages, pageUuid]);

    const resetParams = useCallback(() => {
        params.delete('page');
        setParams(params);
    }, [params, setParams]);

    useEffect(() => {
        if (page && !loading) {
            resetParams();
            navigate(page.href);
        }
    }, [loading, navigate, page, resetParams]);
};
