import styled, { css } from 'styled-components';

import { HumanAvatarProps } from './index';

interface IHumanAvatarWrapperProps {
    size: HumanAvatarProps['size'];
}

const thumbnailAvatarStyles = css`
    width: 34px;
    height: 34px;
`;

const smallAvatarStyles = css`
    width: 86px;
    height: 86px;
`;

const mediumAvatarStyles = css`
    width: 102px;
    height: 102px;
`;

const largeAvatarStyles = css`
    width: 128px;
    height: 128px;
`;

const hiddenAvatarStyle = css`
    visibility: hidden;
    display: inherit !important;
`;

export const HumanAvatarWrapper = styled.div<IHumanAvatarWrapperProps>`
    background: ${({ theme }) => theme.palette.glass.green};
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;

    ${(props) =>
        props.size === 'thumbnail' ? thumbnailAvatarStyles : undefined}
    ${(props) => (props.size === 'small' ? smallAvatarStyles : undefined)}
    ${(props) => (props.size === 'medium' ? mediumAvatarStyles : undefined)}
    ${(props) => (props.size === 'large' ? largeAvatarStyles : undefined)}
    ${(props) => (props.hidden === true ? hiddenAvatarStyle : undefined)}

    img {
        width: 100%;
        height: 100%;
    }
`;
