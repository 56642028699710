import { ChangeEventHandler } from 'react';

import { FormControl, InputAdornment, Stack, TextField } from '@mui/material';

import { CopyListConfig } from 'types/builder/componentConfig';

type CopyListControlProps = {
    node?: CopyListConfig;
    onChange: (node?: CopyListConfig) => void;
};

export const CopyListControl = ({
    onChange,
    node,
}: CopyListControlProps): JSX.Element => {
    const { buttonText } = node?.props || {};

    const onButtonTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        onChange({
            component: 'copyList',
            props: {
                ...node?.props,
                buttonText: value,
            },
        });
    };

    return (
        <Stack spacing={2}>
            <FormControl variant="outlined">
                <TextField
                    label="Текст кнопки"
                    size="small"
                    fullWidth
                    value={buttonText || ''}
                    onChange={onButtonTextChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                sx={{ pointerEvents: 'none' }}
                            >
                                Скопировать
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
        </Stack>
    );
};
