import { ReactElement, useEffect, useState } from 'react';

import { AnimatePresence, motion, Variants } from 'framer-motion';

import { ContentWrapper } from 'components/atoms/Grid';

import * as Style from './ControlBase.styled';

const ControlVariants: Variants = {
    visible: {
        opacity: 1,
    },
    hidden: {
        opacity: 0,
    },
};

type ControlBaseProps = {
    children?: ReactElement;
    background?: boolean;
    inlineWithMapControls?: boolean;
};

export const ControlBase = ({
    children,
    background = false,
    inlineWithMapControls = false,
}: ControlBaseProps): JSX.Element => {
    const [inline, setInline] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setInline(inlineWithMapControls);
        }, 200);

        return () => {
            clearTimeout(timeout);
        };
    }, [inlineWithMapControls]);

    return (
        <Style.ControlContainer
            data-background={background}
            data-inline={inline}
        >
            <ContentWrapper style={{ padding: 0 }} data-inline={inline}>
                <Style.ControlPaneWrapper>
                    <AnimatePresence exitBeforeEnter>
                        <motion.div
                            key={children?.key}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                            }}
                            variants={ControlVariants}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            transition={{
                                duration: 0.2,
                            }}
                        >
                            {children}
                        </motion.div>
                    </AnimatePresence>
                </Style.ControlPaneWrapper>
            </ContentWrapper>
        </Style.ControlContainer>
    );
};
