import { useEffect } from 'react';

import { Variants } from 'framer-motion';

import { BasicButton } from 'components/atoms/BasicButton';
import { SplashScreenShape } from 'components/atoms/SplashScreenShape';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { useAppTitle } from 'hooks/store/useAppTitle';

import {
    SplashScreenBottomElements,
    SplashScreenInfo,
    SplashScreenListElements,
    SplashScreenTitle,
    SplashScreenTitleElements,
    SplashScreenView,
    SplashScreenWrapper,
} from './Splash.styled';

const splashVariants: Variants = {
    STAR_RESET: {
        rotate: 0,
        transition: {
            bounce: 0.3,
        },
    },
    BOTTOM_INITIAL: {
        y: '120%',
    },
    TOP_INITIAL: {
        y: '-120%',
    },
    RESET_TEXT: {
        y: 0,
        transition: {
            type: 'spring',
            bounce: 0.3,
            duration: 1,
        },
    },
};

const SPLASH_SCREEN_ELEMENTS = [
    {
        title: 'узнайте все возможности больницы',
        icon: <SVGAsset src="icons.24px.signUp" />,
    },
    {
        title: 'ознакомьтесь с правилами распорядка',
        icon: <SVGAsset src="icons.24px.search" />,
    },
    {
        title: 'получите ответы на свои вопросы',
        icon: <SVGAsset src="icons.24px.answer" />,
    },
];

export const SplashScreen = ({
    isLoading = false,
    fullScreen = true,
}: {
    isLoading?: boolean;
    fullScreen?: boolean;
}): JSX.Element => {
    const { setDefaultAppTitle } = useAppTitle();

    useEffect(() => {
        setDefaultAppTitle();
    }, [setDefaultAppTitle]);

    return (
        <SplashScreenWrapper data-full-screen={fullScreen}>
            <SplashScreenView data-full-screen={fullScreen}>
                <SplashScreenTitleElements
                    variants={splashVariants}
                    animate={!isLoading ? 'RESET_TEXT' : 'TOP_INITIAL'}
                    initial="TOP_INITIAL"
                >
                    <SplashScreenTitle>Добро пожаловать!</SplashScreenTitle>
                    <SplashScreenInfo>
                        {'Информационный помощник \nклинической больницы №67'}
                    </SplashScreenInfo>
                </SplashScreenTitleElements>
                <SplashScreenShape isLoading={isLoading} />
                <SplashScreenBottomElements
                    variants={splashVariants}
                    animate={!isLoading ? 'RESET_TEXT' : 'BOTTOM_INITIAL'}
                    initial="BOTTOM_INITIAL"
                >
                    <SplashScreenListElements>
                        {SPLASH_SCREEN_ELEMENTS.map((el, i) => (
                            <li key={`splash_list_element_${i}`}>
                                {el.icon}
                                {el.title}
                            </li>
                        ))}
                    </SplashScreenListElements>
                    <BasicButton href="/home" linkType="internal">
                        Все понятно
                    </BasicButton>
                </SplashScreenBottomElements>
            </SplashScreenView>
        </SplashScreenWrapper>
    );
};
