import {
    FormControl,
    InputLabel,
    MenuItem,
    Stack,
    Select,
} from '@mui/material';

import { SpacerProps } from 'components/atoms/Grid/types';
import { SpacerConfig } from 'types/builder/componentConfig';

type SpacerControlProps = {
    node?: SpacerConfig;
    onChange: (node?: SpacerConfig) => void;
};

type Size = SpacerProps['size'];

const sizes: Size[] = ['1x', '2x', '3x', '4x', '5x'];

export const SpacerControl = ({
    onChange,
    node,
}: SpacerControlProps): JSX.Element => {
    const { size = '1x' } = node?.props || {};

    const onSizeChange = (value: Size): void => {
        onChange({
            component: 'spacer',
            props: {
                ...node?.props,
                size: value,
            },
        });
    };

    return (
        <Stack spacing={2}>
            <FormControl variant="outlined" size="small">
                <InputLabel id="base-route-label">Размер</InputLabel>
                <Select
                    labelId="base-route-label"
                    label="Размер"
                    value={size || ''}
                >
                    {sizes.map((el, i) => (
                        <MenuItem
                            key={`menu_item_${i}`}
                            value={el}
                            onClick={() => onSizeChange(el)}
                        >
                            {el}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );
};
