import styled from 'styled-components';

export const NavBarWrapper = styled.nav`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.palette.grey.bg2};
    color: ${({ theme }) => theme.palette.grey.inactive};
    z-index: 999;
`;

export const NavBarSegmentsWrapper = styled.ul`
    display: flex;
    flex-direction: row;
    li {
        flex: 1;
    }
`;

export const NavBarSegment = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 16px 0;
    cursor: pointer;
    background-color: transparent;

    padding-bottom: calc(16px + env(safe-area-inset-bottom));

    min-height: 80px;

    .svg-asset svg {
        margin-bottom: 8px;
    }

    &[data-active='true'] {
        color: ${({ theme }) => theme.palette.green.betta};
    }
`;
