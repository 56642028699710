// import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { CharacterSelector } from 'components/templates/Quiz/CharacterSelector';
import useLocalStorage from 'hooks/useLocalStorage';
import { QuizProgress } from 'types/builder/quizConfig';

export const HomePage = (): JSX.Element => {
    const [progress, setProgress] = useLocalStorage<QuizProgress>(
        'info67quizProgress',
        {}
    );

    const navigate = useNavigate();

    // useEffect(() => {
    //     if (progress.character) {
    //         navigate('/home/quiz/location');
    //     }
    // }, [navigate, progress.character]);

    const onCharacterSelect = (character: QuizProgress['character']): void => {
        setProgress({
            ...progress,
            character,
        });

        navigate('/home/location');
    };

    return <CharacterSelector onSelect={onCharacterSelect} />;
};
