import { useEffect } from 'react';

import { load } from '@2gis/mapgl';
import styled from 'styled-components';

import { ContentWrapper, Spacer } from 'components/atoms/Grid';
import { Typography } from 'components/atoms/Typography';
import { Dropdown } from 'components/molecules/DropDown';
import { ItemList } from 'components/molecules/ItemList';
import { Map } from 'components/molecules/Map';
import { useMapInstance } from 'hooks/api/map/useMapInstance';

const AddressContainer = styled.div`
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 99;
    bottom: 80px;
    padding: 16px 0;
    padding-top: 60px;
    & > div {
        * {
            color: ${({ theme }) => theme.palette.common.white};
        }
    }
    background: linear-gradient(180deg, rgba(76, 162, 100, 0) 0%, #4ca264 40%);
`;

const SELECTED_OBJECTS = ['4504235282809544'];

const MARKERS = [
    {
        coords: [37.46702, 55.772136],
        text: '',
    },
];

export const PharmacyPage = (): JSX.Element => {
    const { mapInstance, mapgl } = useMapInstance();

    useEffect(() => {
        if (mapInstance !== undefined) {
            mapInstance.setCenter([37.46702, 55.772136]);
            mapInstance.setMinZoom(16.5);
            mapInstance.setMaxBounds({
                southWest: [37.46356844736752, 55.77033572242359],
                northEast: [37.4701356821171, 55.77421224313259],
            });
            mapInstance.setSelectedObjects(SELECTED_OBJECTS);

            if (mapgl !== undefined) {
                const markers = MARKERS.map(
                    (m) =>
                        new mapgl.Marker(mapInstance, {
                            coordinates: m.coords,
                            interactive: true,
                            label: {
                                text: m.text,
                                relativeAnchor: [0.5, 0],
                                fontSize: 16,
                                color: '#0C6D36',
                                haloColor: '#ffffff',
                                haloRadius: 2,
                            },
                            size: [25, 37],
                        })
                );

                return () => {
                    markers.forEach((marker) => {
                        if (marker) {
                            marker.destroy();
                        }
                    });
                };
            }
        }
        return undefined;
    }, [mapInstance, mapgl]);

    useEffect(() => {
        load().then(() => {
            if (mapgl && mapInstance) {
                setTimeout(() => {
                    mapInstance.setCenter([37.46702, 55.772136]).setZoom(19.5, {
                        duration: 2000,
                        easing: 'easeInCubic',
                    });
                }, 2000);
            }
        });
    }, [mapInstance, mapgl]);

    return (
        <>
            <AddressContainer>
                <ContentWrapper
                    style={{
                        padding: '0 0',
                    }}
                >
                    <Dropdown
                        defaultOpen
                        style={{
                            background: 'transparent',
                            padding: '0 20px',
                        }}
                        dropdownTitle="Адрес и режим работы"
                        chevronColor="white"
                        chevronReverse
                    >
                        <Typography>
                            улица Саляма Адиля, 2/44с1, Корпус А, 1 этаж, Лабис
                            Фарма.
                        </Typography>
                        <Spacer />
                        <Typography>
                            Тел: <a href="tel:84957734246">8 (495) 773-42-46</a>
                        </Typography>
                        <Spacer />
                        <Typography variant="title.small">
                            Режим работы:
                        </Typography>
                        <ItemList
                            listStyle="disc"
                            items={[
                                '08:00 - 19:00 (по будням)',
                                '10:00 - 19:00 (по выходным)',
                            ]}
                        />
                    </Dropdown>
                </ContentWrapper>
            </AddressContainer>

            <div style={{ flex: 1, pointerEvents: 'none' }}>
                <Map
                    options={{
                        disableDragging: true,
                        disableZoomOnScroll: true,
                        disablePitchByUserInteraction: true,
                        disableRotationByUserInteraction: true,
                        zoomControl: false,
                        floorControl: false,
                        minZoom: 19.5,
                        maxZoom: 19.5,
                    }}
                />
            </div>
        </>
    );
};
