import { ReactNode, useMemo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {
    Accordion,
    Box,
    AccordionSummary,
    Stack,
    AccordionDetails,
} from '@mui/material';
import { motion } from 'framer-motion';

import { useAppSelector } from 'hooks/store/useAppStore';

type QuizOptionsDropDownProps = {
    expanded: boolean;
    onExpand: (state: boolean) => void;
    children?: ReactNode;
};

export const QuizOptionsDropDown = ({
    expanded,
    onExpand,
    children,
}: QuizOptionsDropDownProps): JSX.Element => {
    const { errors } = useAppSelector((state) => state.pageBuilder);

    const error = useMemo(() => errors.includes('quizSettings'), [errors]);

    return (
        <Accordion
            sx={{
                borderBottom: 1,
                borderColor: 'divider',
                margin: '0 !important',
            }}
            expanded={expanded}
            onChange={(e, v) => onExpand(v)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    px: '28px',
                    fontSize: expanded ? 22 : 16,
                    fontWeight: 500,
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ flex: 1 }}
                >
                    {error ? (
                        <ReportProblemOutlinedIcon color="error" />
                    ) : undefined}
                    <span>Настройки вопроса</span>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, position: 'relative' }}>
                <Box
                    component={motion.div}
                    layoutScroll
                    sx={{
                        p: '28px',
                        pt: 1,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        overflowY: 'auto',
                    }}
                >
                    <Stack spacing={2}>{children}</Stack>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};
