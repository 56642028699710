import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ChatModalWrapper = styled(motion.div)`
    position: fixed;
    top: 0px;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: auto;

    z-index: 1700;

    background-color: ${({ theme }) => theme.palette.common.white};

    & > div {
        height: 100%;
    }
`;

export const ChatViewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 -16px;

    background-color: ${({ theme }) => theme.palette.grey.bg2};
    border-left: 1px solid #ededed;
    border-right: 1px solid #ededed;
`;

export const ChatHeader = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-bottom: 1px solid #ededed;

    position: relative;
    background-color: ${({ theme }) => theme.palette.common.white};
`;

export const ChatCloseButton = styled.button`
    position: absolute;
    background-color: transparent;
    cursor: pointer;
    right: 16px;
`;

export const LoaderWrapper = styled.div`
    &:not([data-transparent]) {
        background-color: ${({ theme }) => theme.palette.grey.bg2};
    }

    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
`;
