import { useCallback } from 'react';

import { AppLocationsState, set } from 'store/slices/appLocationsSlice';

import { useAppDispatch, useAppSelector } from './useAppStore';

type AppLocations = AppLocationsState['value'];

type UseAppLocationsReturn = [AppLocations, (payload: AppLocations) => void];

export const useAppLocations = (): UseAppLocationsReturn => {
    const locations = useAppSelector((state) => state.appLocations.value);

    const dispatch = useAppDispatch();

    const setLocations = useCallback(
        (payload: AppLocations) => {
            dispatch(set(payload));
        },
        [dispatch]
    );

    return [locations, setLocations];
};
