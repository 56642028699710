import { useMemo } from 'react';

import {
    Stack,
    InputAdornment,
    TextField,
    CircularProgress,
} from '@mui/material';

import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useStaffData } from 'hooks/api/staff/useStaffData';
import { useStaffFilter } from 'hooks/api/staff/useUsersFilter';

import { Staff } from '../Staff';
import { Annotation } from '../StaffAnnotation/index';
import * as Style from './StaffList.styled';

export const StaffList = (): JSX.Element => {
    const { usersFilter, roles } = useStaffData();

    const { search, setSearchFilter, is_archive, activeFilters } =
        useStaffFilter();

    let is_filter = false;
    if (activeFilters.length !== 0 || is_archive === true || search !== '') {
        is_filter = true;
    }

    const onChangedSearchFilter = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setSearchFilter(event.target.value);
    };

    const annotation = useMemo(() => {
        if (usersFilter && usersFilter.length === 0) {
            return (
                <Annotation
                    icon={<SVGAsset src="shapes.admin.pointUpFace" />}
                    title="У вас пока нет пользователей"
                    text="Добавьте нового пользователя при помощи поля в верхнем левом углу интерфейса"
                />
            );
        }
        if (usersFilter && usersFilter.length === 0 && is_filter === true) {
            return (
                <Annotation
                    icon={<SVGAsset src="shapes.admin.pointRightFace" />}
                    title="Совпадений не найдено"
                    text="Попробуйте изменить перамеры фильтра"
                />
            );
        }
        if (usersFilter && usersFilter.length === 0 && is_archive === true) {
            return (
                <Annotation
                    icon={<SVGAsset src="icons.24px.archive" />}
                    title="Ваш архив пока пуст"
                    text="Добавьте пользователя в архив при помощи такой кнопки"
                />
            );
        }

        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ width: '100%', flex: 1 }}
            >
                <CircularProgress
                    size={56}
                    color="primary"
                    variant="indeterminate"
                />
            </Stack>
        );
    }, [is_archive, is_filter, usersFilter]);

    return (
        <Style.EmployeesList>
            <Style.EmployeesListDataWrapper>
                <Style.EmployeesTitle>
                    <Typography variant="title.large">Персонал</Typography>

                    <TextField
                        onChange={onChangedSearchFilter}
                        placeholder="Поиск"
                        variant="standard"
                        value={search}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SVGAsset src="icons.24px.searchNoBg" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Style.EmployeesTitle>

                <Style.TypesWrapper>
                    {roles?.map((role) => {
                        return (
                            <Stack key={role.id} direction="row" spacing={0}>
                                <Typography variant="body.large">
                                    {role.verbose_name}
                                </Typography>
                            </Stack>
                        );
                    })}
                </Style.TypesWrapper>
            </Style.EmployeesListDataWrapper>

            <Style.EmployeesListScrollWrapper is_filter={is_filter}>
                {usersFilter && usersFilter.length
                    ? usersFilter.map((el, index) => {
                          return (
                              <Staff
                                  is_filter={is_filter}
                                  index={index}
                                  key={el.id}
                                  id={el.id}
                                  email={el.email}
                                  is_archive={el.is_archived}
                                  userRoles={el.roles}
                              />
                          );
                      })
                    : annotation}
            </Style.EmployeesListScrollWrapper>
        </Style.EmployeesList>
    );
};
