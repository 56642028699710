import { useMemo } from 'react';

import * as mui from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import { ReactSVG, Props as ReactSVGProps } from 'react-svg';

import { ASSETS_MAP } from 'assets';
import { IThemePalette } from 'theme/custom/theme';
import { themePalette } from 'theme/custom/themePalette';
import { NestedKeyOf } from 'types/utils';
import { getValueFromObjectPath } from 'utils/getValueFromObjectPath';

import { StyledImageAsset } from './SVGAsset.styled';

type IconKeys = {
    [key: string]: mui.SvgIconComponent;
};

const muiIcons: IconKeys = mui;

type PartialReactSVGProps = Omit<ReactSVGProps, 'ref' | 'src' | 'wrapper'>;

export type SVGAssetProps = PartialReactSVGProps & {
    src: NestedKeyOf<typeof ASSETS_MAP> | string;
    color?: NestedKeyOf<Omit<IThemePalette, 'gradients' | 'glass' | 'grey'>>;
};

export const SVGAsset = (props: SVGAssetProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { src, className, color, children, ...rest } = props;

    const classNames = [className, 'svg-asset', ...src.split('.')]
        .filter((c) => !!c)
        .join(' ');

    const source = useMemo(() => {
        return getValueFromObjectPath(ASSETS_MAP, src);
    }, [src]);

    if (!src.includes('.')) {
        return (
            <div
                style={{
                    color: color
                        ? getValueFromObjectPath(themePalette, color)
                        : undefined,
                }}
            >
                <SvgIcon
                    component={muiIcons[src]}
                    fontSize="large"
                    color="inherit"
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        );
    }

    return !src.includes('shapes.human') && !src.includes('40px.hand') ? (
        <ReactSVG
            {...rest}
            wrapper="span"
            className={classNames}
            src={source}
        />
    ) : (
        <StyledImageAsset
            {...rest}
            className={classNames}
            src={source}
            alt=""
        />
    );
};
