/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import {
    ChangeEventHandler,
    Key,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    InputAdornment,
    Stack,
    TextField,
} from '@mui/material';
import Tree, { DataNode } from 'antd/lib/tree';

import { Typography } from 'components/atoms/Typography';
import { useBuilderToolbars } from 'hooks/api/builder/pages/usePageBuilder';
import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { setSelectedNode } from 'store/slices/pageBuilderSlice';
import {
    MutablePageComponentConfig,
    PageComponentConfig,
} from 'types/builder/componentConfig';
import { highlightInText } from 'utils/highlightInText';

import { ComponentCreator } from '../ComponentCreator';
import { NodeHighlighter } from '../NodeHighlighter';
import {
    NodeTreeHeaderWrapper,
    NodeTreeScrollContainer,
    NodeTreeWrapper,
} from './NodeTree.styled';
import {
    NodeTreeProps,
    NodeSearchList,
    loopFunc,
    onDropEventHandler,
    onSelectFunc,
} from './types';
import { allowDrop, getParentKey, transformConfig } from './utils';

export const NodeTree = ({
    onNodesChange,
    onNewComponentCreated,
}: NodeTreeProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const { selectedNode, config } = useAppSelector(
        (state) => state.pageBuilder
    );

    const nodes = useMemo(() => {
        return config?.pageConfig || [];
    }, [config]);

    const onSelectedChange = useCallback(
        (node?: Key) => {
            dispatch(setSelectedNode(node));
        },
        [dispatch]
    );

    const { setActiveToolBar, toolbars } = useBuilderToolbars();

    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [hoveredNode, setHoveredNode] = useState<Key>();

    const nodeTreeList = useMemo(() => transformConfig(nodes), [nodes]);

    const nodeSearchList = useMemo<NodeSearchList[]>(() => {
        const nodeList: NodeSearchList[] = [];

        const generateSearchList = (data: DataNode[]): void => {
            data.forEach((node) => {
                const { key, title } = node;
                nodeList.push({
                    key,
                    title: title as string,
                });

                if (node.children) {
                    generateSearchList(node.children);
                }
            });
        };

        generateSearchList(nodeTreeList);

        return nodeList;
    }, [nodeTreeList]);

    const toggleExpandNode = useCallback((key: Key): void => {
        setExpandedKeys((keys) => {
            if (!keys.includes(key)) {
                return Array.from(new Set([...keys, key]));
            }
            return keys;
        });
    }, []);

    useEffect(() => {
        if (selectedNode?._key) {
            toggleExpandNode(selectedNode._key);
        }
    }, [nodeTreeList, selectedNode, toggleExpandNode]);

    const onExpand = (newExpandedKeys: Key[]): void => {
        setExpandedKeys(Array.from(new Set([...newExpandedKeys])));
        setAutoExpandParent(false);
    };

    const onSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        if (value) {
            const newExpandedKeys = nodeSearchList
                .map((item) => {
                    if (
                        item.title.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                    ) {
                        return getParentKey(item.key, nodeTreeList);
                    }
                    return null;
                })
                .filter(
                    (item, i, self) => item && self.indexOf(item) === i
                ) as Key[];

            setExpandedKeys(Array.from(new Set([...newExpandedKeys])));
        } else {
            setExpandedKeys([]);
        }

        setSearchValue(value);
        setAutoExpandParent(true);
    };

    const treeData = useMemo(() => {
        const loop = (data: DataNode[]): DataNode[] =>
            data.map((item) => {
                const title = highlightInText(
                    item.title as string,
                    searchValue
                );

                if (item.children) {
                    return {
                        ...item,
                        title: title.element,
                        className: title.match ? 'indexed' : undefined,
                        children: loop(item.children),
                    };
                }

                return {
                    ...item,
                    className: title.match ? 'indexed' : undefined,

                    title: title.element,
                };
            });

        return loop(nodeTreeList);
    }, [nodeTreeList, searchValue]);

    const onDrop: onDropEventHandler = (info) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition =
            info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const loop: loopFunc = (data, key, callback) => {
            data.forEach((el, i) => {
                if (el._key === key) {
                    return callback(el, i, data);
                }
                if (el.children) {
                    loop(el.children, key, callback);
                }
                return undefined;
            });
        };

        const data = JSON.parse(JSON.stringify(nodes)); // Find dragObject

        let dragObj: MutablePageComponentConfig = { component: 'box' };

        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                item.children.unshift(dragObj);
            });
        } else if (
            (info.node.children || []).length > 0 &&
            info.node.expanded &&
            dropPosition === 1
        ) {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                item.children.unshift(dragObj);
            });
        } else {
            let ar: PageComponentConfig[] = [];
            let i = 0;

            loop(data, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
            });

            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }

        if (typeof onNodesChange === 'function') {
            onNodesChange(data);
        }
    };

    const onNodeSelect: onSelectFunc = (keys): void => {
        if (keys.length === 1) {
            onSelectedChange(keys[0]);
        } else {
            onSelectedChange(undefined);
        }
    };

    const onNodeHover = (node?: Key): void => {
        setHoveredNode(node);
    };

    return (
        <Accordion
            sx={{
                borderBottom: 1,
                borderColor: 'divider',
                margin: '0 !important',
            }}
            expanded={toolbars.left === 'nodeTree'}
            onChange={(e, v) => setActiveToolBar('nodeTree', v)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    px: '28px',
                    fontSize: toolbars.left === 'nodeTree' ? 22 : 16,
                    fontWeight: 500,
                }}
            >
                Структура
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
                {nodes.length ? (
                    <NodeTreeWrapper>
                        <NodeTreeHeaderWrapper>
                            <TextField
                                size="small"
                                variant="standard"
                                placeholder="Поиск"
                                value={searchValue}
                                onChange={onSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </NodeTreeHeaderWrapper>
                        <Box sx={{ position: 'relative', flex: 1 }}>
                            <NodeTreeScrollContainer>
                                <Tree
                                    onSelect={onNodeSelect}
                                    onExpand={onExpand}
                                    onDrop={onDrop}
                                    allowDrop={allowDrop}
                                    onMouseEnter={({ node }) =>
                                        onNodeHover(node.key)
                                    }
                                    onMouseLeave={() => onNodeHover()}
                                    expandedKeys={expandedKeys}
                                    autoExpandParent={autoExpandParent}
                                    selectedKeys={
                                        selectedNode?._key
                                            ? [selectedNode._key]
                                            : undefined
                                    }
                                    treeData={treeData}
                                    blockNode
                                    showIcon
                                    draggable
                                />
                            </NodeTreeScrollContainer>
                        </Box>
                        <NodeHighlighter
                            hoveredNode={hoveredNode}
                            key={JSON.stringify(nodes)}
                        />
                    </NodeTreeWrapper>
                ) : undefined}
                {!nodes.length ? (
                    <Stack
                        sx={{ flex: 1 }}
                        direction="column"
                        justifyContent="center"
                    >
                        <Typography variant="body.medium" textAlign="center">
                            Нажмите кнопку «+» ниже, <br />
                            чтобы добавить новый компонент
                        </Typography>
                    </Stack>
                ) : undefined}
            </AccordionDetails>
            {toolbars.left === 'nodeTree' ? (
                <ComponentCreator onComponentAdd={onNewComponentCreated} />
            ) : undefined}
        </Accordion>
    );
};
