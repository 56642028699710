export const getTimeByPeriod = (date: string, withTime?: boolean): string => {
    const todaysDate = new Date();
    const inputDate = new Date(date);

    const timeDiff = Math.abs(todaysDate.getTime() - inputDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
        return new Date(date).toLocaleTimeString('ru-RU', {
            hour: '2-digit',
            minute: '2-digit',
        });
    }

    const withTimeProps: Intl.DateTimeFormatOptions = withTime
        ? {
              hour: '2-digit',
              minute: '2-digit',
          }
        : {};

    if (diffDays < 8) {
        return new Date(date).toLocaleDateString('ru-RU', {
            weekday: 'short',
            ...withTimeProps,
        });
    }

    if (diffDays > 7 && todaysDate.getFullYear() === inputDate.getFullYear()) {
        return new Date(date).toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: 'short',
            ...withTimeProps,
        });
    }

    return new Date(date).toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        ...withTimeProps,
    });
};
