import styled from 'styled-components';

export const StaffFilterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    width: 100%;
    height: 56px;
    margin: 0px 0px 40px 0px;
    justify-content: space-between;
`;

export const TextFieldWrapper = styled.div`
    display: inline-block;
    width: 496px;
`;
export const TextFieldWrapperFlex = styled.div`
    display: flex;
    width: 496px;
`;

export const FiltersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    margin: 0px 0px 0px 104px;

    & button:not(:last-child) {
        margin: 0px 16px 0px 0px;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex: 0 0 auto;
    margin: 8px 0px 0px 0px;
`;

export const Spacer = styled.div`
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0px 16px 0px 0px;
`;

export const ResetFilter = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    letter-spacing: 0.4px;
    text-decoration-line: underline;
    color: ${(props) => props.theme.palette.green.betta};
    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
`;

export const Email = styled.div`
    color: ${(props) => props.theme.palette.green.betta};
`;
