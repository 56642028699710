import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { useMeta } from 'hooks/api/useMeta';
import { PageList } from 'types/api/pageList';

type UsePagesListReturn = {
    pageList?: PageList[];
    loading: boolean;
    error: boolean;
    getPageList: () => void;
};

export const usePagesList = (): UsePagesListReturn => {
    const { error, meta, loading, refresh } = useMeta('pageList');

    const { enqueueSnackbar } = useSnackbar();

    const [pageList, setPageList] = useState<PageList[]>();

    const getPageList = (): void => {
        setPageList(undefined);
        refresh();
    };

    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                'Что-то пошло не так... Не удалось получить список всех страниц.',
                { variant: 'error', key: 'page-list-error' }
            );
        }
    }, [error, enqueueSnackbar]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (error) {
                getPageList();
            }
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (meta) {
            setPageList(meta);
        }
    }, [meta]);

    return { pageList, loading, error, getPageList };
};
