import { useEffect, useState } from 'react';

import {
    Autocomplete,
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    FormControl,
    Stack,
    TextField,
} from '@mui/material';

import { BasicButton } from 'components/atoms/BasicButton';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useMeta } from 'hooks/api/useMeta';

type ChatTransferProps = {
    open: boolean;
    chatID?: number;
    onClose?: () => void;
    onTransfer?: (userID: number, chatID: number) => void;
};

export const ChatTransfer = ({
    open,
    chatID,
    onClose = () => undefined,
    onTransfer = () => undefined,
}: ChatTransferProps): JSX.Element => {
    const [transferred, setTransferred] = useState(false);

    const [activeUser, setActiveUser] = useState<number>();

    const { meta } = useMeta('staff');

    useEffect(() => {
        setTransferred(false);
        setActiveUser(undefined);
    }, [open]);

    const data = meta ? meta?.filter((el) => el.roles?.includes(3)) : undefined;

    const activeOption = data?.find((el) => el.id === activeUser) || {
        id: 0,
        email: 'undefined',
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent sx={{ width: 420, p: '32px 40px' }}>
                <Stack spacing={3} alignItems="center">
                    {!transferred ? (
                        <>
                            <Typography
                                variant="title.large"
                                textAlign="center"
                            >
                                Выберите саппорт-менеджера, которому передадите
                                чат #{chatID}
                            </Typography>
                            <FormControl
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled={!data}
                            >
                                {!data ? (
                                    <TextField
                                        size="small"
                                        InputProps={{
                                            endAdornment: (
                                                <CircularProgress
                                                    variant="indeterminate"
                                                    color="inherit"
                                                    size={16}
                                                />
                                            ),
                                        }}
                                        label="Выберите саппорт-менеджера"
                                        disabled
                                    />
                                ) : (
                                    <Autocomplete
                                        noOptionsText="Ничего не найдено"
                                        options={data}
                                        autoHighlight
                                        fullWidth
                                        getOptionLabel={(option) =>
                                            option.email === 'undefined'
                                                ? ''
                                                : option.email
                                        }
                                        onChange={(e, v) =>
                                            setActiveUser(v?.id)
                                        }
                                        isOptionEqualToValue={(o, v) =>
                                            v.email === 'undefined' ||
                                            o.id === v.id
                                        }
                                        value={activeOption}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Выберите саппорт-менеджера"
                                                placeholder="Поиск..."
                                                size="small"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete:
                                                        'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            </FormControl>
                            <BasicButton
                                onClick={() => {
                                    if (activeUser && chatID) {
                                        setTransferred(true);
                                        onTransfer(activeUser, chatID);
                                    }
                                }}
                            >
                                ПЕРЕДАТЬ ЧАТ
                            </BasicButton>
                        </>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    svg: { width: '100px', height: '100px' },
                                }}
                            >
                                <SVGAsset src="icons.56px.checkCircle" />
                            </Box>
                            <Typography
                                variant="title.large"
                                textAlign="center"
                            >
                                Вы успешно передали чат саппорт-менеджеру
                            </Typography>
                            <Typography
                                variant="body.medium"
                                textAlign="center"
                            >
                                test@example.com
                            </Typography>
                        </>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
