import { useEffect } from 'react';

import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useEditUser } from 'hooks/api/staff/useEditUser';
import useAxios from 'hooks/api/useAxios';

type IconButtonArchiveProps = {
    title: string;
    icon: React.ReactNode;
    id: number;
    is_archive: boolean;
};
export const IconButtonArchive = (
    props: IconButtonArchiveProps
): JSX.Element => {
    const { setArchiveUser } = useEditUser();
    const { id, is_archive, title, icon } = props;

    const [res, { rerun }] = useAxios(
        {
            url: `/api/admin/user/${id}/`,
            method: 'PATCH',
            data: { is_archived: !is_archive },
            skipRequest: () => true,
        },
        []
    );

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (res.type === 'success') {
            setArchiveUser(id);
        }
        if (res.type === 'error') {
            enqueueSnackbar(`Не удалось ${title.toLowerCase()} пользователя`, {
                variant: 'error',
                key: 'user-archive-error',
            });
        }
    }, [res.type, id, title, setArchiveUser, enqueueSnackbar]);

    return (
        <Tooltip title={title} arrow placement="top">
            <IconButton
                onClick={() => {
                    rerun();
                }}
            >
                {res.type === 'loading' ? (
                    <CircularProgress size={24} color="inherit" />
                ) : (
                    icon
                )}
            </IconButton>
        </Tooltip>
    );
};

type IconButtonDeleteProps = {
    title: string;
    icon: React.ReactNode;
    setIsDelete: (value: boolean) => void;
};
export const IconButtonDelete = (props: IconButtonDeleteProps): JSX.Element => {
    const { title, icon, setIsDelete } = props;

    return (
        <Tooltip title={title} arrow placement="top">
            <IconButton
                onClick={() => {
                    setIsDelete(true);
                }}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
};
