/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppLocationsState {
    value: {
        [key: string]: string;
    };
}

const initialState: AppLocationsState = {
    value: {},
};

export const appLocationsSlice = createSlice({
    name: 'appLocations',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<AppLocationsState['value']>) => {
            state.value = action.payload;
        },
    },
});

export const { set } = appLocationsSlice.actions;

export const appLocationsSliceReducer = appLocationsSlice.reducer;
