import {
    ChangeEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Button,
    FormControl,
    Link,
    Stack,
    Switch,
    TextField,
} from '@mui/material';
import QRCodeStyling from 'qr-code-styling';
import { useForm } from 'react-hook-form';

import logo from 'assets/logos/logo-67.svg';
import { Spacer } from 'components/atoms/Grid';
import { Typography } from 'components/atoms/Typography';
import { useBuilderToolbars } from 'hooks/api/builder/pages/usePageBuilder';
import { useNewLocationSelector } from 'hooks/api/builder/points/useNewLocationSelector';
import { useAppDispatch } from 'hooks/store/useAppStore';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { setError } from 'store/slices/pageBuilderSlice';
import { SelectedLocation } from 'types/map/location';

import { ControlDropDown } from '../DropDown';
import { PointDeleteModal } from '../PointDeleteModal';

type PoiSettingsControlProps = {
    onDeleteSuccess: () => void;
    onChange: (conf?: SelectedLocation) => void;
    config: SelectedLocation;
};

export const PoiSettingsControl = ({
    onDeleteSuccess = () => undefined,
    onChange = () => undefined,
    config,
}: PoiSettingsControlProps): JSX.Element => {
    const { setActiveToolBar, toolbars } = useBuilderToolbars();

    const {
        name = '',
        is_published = false,
        is_hidden = false,
        id,
        uuid,
    } = config || {};

    useEffect(() => {
        if (id) {
            setActiveToolBar('mapPointSettings', true);
        }
    }, [id, setActiveToolBar]);

    const onPointNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (config) {
            const { value } = e.target;

            onChange({
                ...config,
                name: value,
            });
        }
    };

    const onSwitch: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (config) {
            const { checked, name: inputName } = e.target;
            onChange({
                ...config,
                [inputName]: checked,
            });
        }
    };

    const {
        register,
        formState: { errors },
        trigger,
    } = useForm<Pick<SelectedLocation, 'name'>>({
        mode: 'all',
        defaultValues: {
            name,
        },
    });

    const hasErrors = !!Object.keys(errors).length;

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setError({ name: 'mapPoints', error: hasErrors }));
    }, [hasErrors, dispatch]);

    useEffect(() => {
        if (name !== undefined) {
            trigger();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const locationUrl = useMemo(
        () => `${window.location.origin}/map?location=${uuid}`,
        [uuid]
    );

    const onQrGenerate = useCallback(() => {
        const qrCode = new QRCodeStyling({
            width: 300,
            height: 300,
            type: 'svg',
            data: locationUrl,
            image: logo,
            dotsOptions: {
                type: 'rounded',
            },
            imageOptions: {
                crossOrigin: 'anonymous',
                margin: 10,
                imageSize: 0.6,
            },
        });

        qrCode.download({
            name: `QR-код для «${name}»`,
            extension: 'svg',
        });
    }, [name, locationUrl]);

    const { setMode, resetAll, location, mode } = useNewLocationSelector();

    useEffect(() => {
        if (location && mode === 'update') {
            onChange({
                ...config,
                ...location,
            });
            resetAll();
        }
    }, [config, location, mode, onChange, resetAll]);

    const [copied, copy] = useCopyToClipboard();

    return (
        <ControlDropDown
            expanded={toolbars.right === 'mapPointSettings'}
            onExpand={(v) => setActiveToolBar('mapPointSettings', v)}
            title="Настройки точки"
            scrollContainer
            errorName="mapPoints"
        >
            <Stack sx={{ flex: 1 }} spacing={2}>
                <Typography variant="title.small">Основные</Typography>
                <FormControl variant="outlined">
                    <TextField
                        label="Название точки"
                        size="small"
                        fullWidth
                        {...register('name', {
                            required: {
                                value: true,
                                message: 'Введите название точки',
                            },
                            onChange: onPointNameChange,
                        })}
                        error={!!errors.name}
                        value={name || ''}
                    />
                    {errors.name?.message ? (
                        <>
                            <Spacer size="1x" />
                            <Typography $color="red" variant="body.small">
                                {errors.name?.message}
                            </Typography>
                        </>
                    ) : undefined}
                </FormControl>

                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>Изменить расположение</Typography>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => setMode('update')}
                    >
                        Изменить
                    </Button>
                </Stack>
                <Typography variant="title.small">
                    Публикация и удаление
                </Typography>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>Опубликована</Typography>
                    <Switch
                        name="is_published"
                        checked={is_published}
                        onChange={onSwitch}
                    />
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>Скрыта из поиска</Typography>
                    <Switch
                        name="is_hidden"
                        checked={is_hidden}
                        onChange={onSwitch}
                    />
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>Удалить точку</Typography>
                    <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => setDeleteModalIsOpen(true)}
                        disabled={is_published}
                    >
                        Удалить
                    </Button>
                </Stack>
                <Typography variant="title.small">Поделиться точкой</Typography>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={locationUrl}
                        sx={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                    >
                        {locationUrl}
                    </Link>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{ flexShrink: 0 }}
                        onClick={() => copy(locationUrl)}
                        disabled={!!copied}
                    >
                        {!copied ? 'Копировать' : 'Скопировано'}
                    </Button>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>Получить QR-код</Typography>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => onQrGenerate()}
                    >
                        Скачать SVG
                    </Button>
                </Stack>
            </Stack>
            {id ? (
                <PointDeleteModal
                    open={deleteModalIsOpen}
                    onClose={() => setDeleteModalIsOpen(false)}
                    onSuccess={onDeleteSuccess}
                    pointTitle={name}
                    id={id}
                    key={`delete_modal_${deleteModalIsOpen}`}
                />
            ) : undefined}
        </ControlDropDown>
    );
};
