/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GalleryState {
    value?: string;
}

const initialState: GalleryState = {
    value: undefined,
};

export const gallerySlice = createSlice({
    name: 'gallery',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<GalleryState['value']>) => {
            state.value = action.payload;
        },
    },
});

export const { set } = gallerySlice.actions;

export const gallerySliceReducer = gallerySlice.reducer;
