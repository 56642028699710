/* eslint-disable no-underscore-dangle */
import { Key } from 'react';

import { DataNode } from 'antd/lib/tree';
import { ReactSVG } from 'react-svg';

import { controlChildConfig } from 'constants/components/controlConfig';
import { componentDisplayConfig } from 'constants/components/displayConfig';
import { PageComponentConfig } from 'types/builder/componentConfig';

import { allowDropFunc } from './types';

export const getParentKey = (key: Key, tree: DataNode[]): Key | undefined => {
    let parentKey: Key | undefined;

    tree.forEach((node) => {
        if (node.children) {
            if (node.children.some((item) => item.key === key)) {
                parentKey = node.key;
            } else if (getParentKey(key, node.children)) {
                parentKey = getParentKey(key, node.children);
            }
        }
    });

    return parentKey;
};

export const findParentKeys = (key: Key, tree: DataNode[]): Key[] => {
    let keys: Key[] = [];

    const current = getParentKey(key, tree);

    if (current) {
        keys.push(current);
        const childKeys = findParentKeys(current, tree);

        if (childKeys.length) {
            keys = [...keys, ...childKeys];
        }
    }

    return keys;
};

export const transformConfig = (
    config?: readonly PageComponentConfig[]
): DataNode[] =>
    config?.map((el) => ({
        title:
            typeof el.props?.children === 'string'
                ? el.props?.children
                : componentDisplayConfig[el.component]?.title,
        icon: (
            <ReactSVG src={componentDisplayConfig[el.component]?.icon || ''} />
        ),
        children: transformConfig(el.children),
        checkable: controlChildConfig[el.component],
        key: el._key || '',
    })) || [];

export const allowDrop: allowDropFunc = ({ dropNode, dropPosition }) => {
    if (!dropNode.checkable) {
        if (dropPosition === 0) return false;
    }
    return true;
};
