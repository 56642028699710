import { useEffect, useMemo, useRef, useState } from 'react';

import {
    Badge,
    CircularProgress,
    Grid,
    List,
    ListItemButton,
    Stack,
    Tooltip,
} from '@mui/material';

import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useNotifications } from 'hooks/api/integrations/useNotifications';
import { useFindStaff } from 'hooks/api/staff/useFindStaff';
import { ChatList } from 'types/api/chatList';
import { getShortEmail } from 'utils/getShortEmail';
import { getTimeByPeriod } from 'utils/getTimeByPeriod';

import {
    ChatListMessageWrapper,
    ChatListScrollWrapper,
} from './ChatList.styled';

type ManagerListProps = {
    onUnreadCount?: (count: number) => void;
    onChatSelect: (id: ChatList) => void;
    selectedChat?: number;
    showCompletedOnly?: boolean;
    chatList?: ChatList[];
    error?: boolean;
    onRequestReload?: () => void;
};

export const ManagerChatList = ({
    onUnreadCount = () => undefined,
    onChatSelect,
    selectedChat,
    showCompletedOnly,
    chatList,
    error = false,
    onRequestReload = () => undefined,
}: ManagerListProps): JSX.Element => {
    const mounted = useRef(true);

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    const finalChatList = useMemo(() => {
        return chatList
            ?.filter((el) =>
                showCompletedOnly
                    ? el.status === 'finished'
                    : el.status !== 'finished'
            )
            .map((item) => {
                if (item.id === selectedChat) {
                    return {
                        ...item,
                        messages_unread_count: 0,
                    };
                }
                return item;
            });
    }, [chatList, selectedChat, showCompletedOnly]);

    useEffect(() => {
        if (finalChatList && mounted.current) {
            onUnreadCount(
                finalChatList.filter((el) => el.messages_unread_count > 0)
                    .length
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalChatList, chatList]);

    const { status } = useNotifications({}, []);

    useEffect(() => {
        if (status === 'connected') {
            onRequestReload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChat, status]);

    const { findManager } = useFindStaff();

    const renderListItems = (): JSX.Element[] => {
        if (finalChatList) {
            const sortedUnread = finalChatList
                .filter((el) => el.messages_unread_count > 0)
                .sort(
                    (a, b) =>
                        Date.parse(b.updated_date) - Date.parse(a.updated_date)
                );
            const sortedRead = finalChatList
                .filter((el) => el.messages_unread_count === 0)
                .sort(
                    (a, b) =>
                        Date.parse(b.updated_date) - Date.parse(a.updated_date)
                );

            return [...sortedUnread, ...sortedRead].map((item) => {
                const email = item.manager
                    ? findManager(item.manager)?.email
                    : undefined;

                return (
                    <ListItemButton
                        key={`chat_list_item_${item.id}`}
                        selected={selectedChat === item.id}
                        onClick={() => onChatSelect(item)}
                    >
                        <Grid container>
                            <Grid item xs={3}>
                                <Typography variant="body.large">
                                    #{item.id}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body.large">
                                    {getTimeByPeriod(item.updated_date)}
                                </Typography>
                            </Grid>
                            <Tooltip
                                enterDelay={1000}
                                title={
                                    email
                                        ? `Закреплен за ${getShortEmail(email)}`
                                        : ''
                                }
                                placement="bottom"
                                arrow
                            >
                                <Grid item xs={5}>
                                    {email ? (
                                        <Typography
                                            variant="body.small"
                                            $color="green.betta"
                                        >
                                            {getShortEmail(email)}
                                        </Typography>
                                    ) : undefined}
                                </Grid>
                            </Tooltip>
                            <Grid item xs={1}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    height="100%"
                                >
                                    <Badge
                                        badgeContent={
                                            item.messages_unread_count
                                        }
                                        color="secondary"
                                        sx={{ ml: 'auto' }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </ListItemButton>
                );
            });
        }
        return [];
    };

    const [showScrollbar, setShowScrollbar] = useState(false);

    return (
        <ChatListScrollWrapper
            data-scroll={showScrollbar}
            onTouchStart={() => setShowScrollbar(true)}
            onTouchEnd={() => setShowScrollbar(false)}
        >
            {finalChatList && finalChatList.length ? (
                <List disablePadding>{renderListItems()}</List>
            ) : undefined}
            {finalChatList && !finalChatList.length ? (
                <ChatListMessageWrapper>
                    <Typography variant="title.large">
                        {showCompletedOnly ? 'Обработанных' : 'Новых'} обращений
                        нет
                    </Typography>
                    <Typography>
                        Обращения пользователей будут появляться тут
                    </Typography>
                </ChatListMessageWrapper>
            ) : undefined}
            {error && !finalChatList ? (
                <ChatListMessageWrapper>
                    <Stack spacing={2} alignItems="center">
                        <SVGAsset src="icons.56px.negative" />
                        <Typography variant="title.large">
                            При загрузке обращений что-то пошло не так...
                        </Typography>
                    </Stack>
                </ChatListMessageWrapper>
            ) : undefined}
            {!finalChatList && !error ? (
                <ChatListMessageWrapper>
                    <CircularProgress variant="indeterminate" size={30} />
                </ChatListMessageWrapper>
            ) : undefined}
        </ChatListScrollWrapper>
    );
};
