import styled from 'styled-components';

export const ControlMapWrapper = styled.div`
    flex: 1;
    #map-container {
        & > div {
            canvas {
                display: block;
            }
        }
    }
    &[data-inline='true'] {
        #map-container {
            & > div {
                & > div[class*='mapgl_'] {
                    @media screen and (max-width: 576px) {
                        margin-top: 87px !important;
                    }
                    @media screen and (max-width: 450px) {
                        margin-top: 69px !important;
                    }
                }
            }
        }
    }
`;

export const ControlContainer = styled.div`
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 80px;

    &[data-inline='true'] {
        max-width: calc(100vw - 146px);

        @media screen and (max-width: 576px) {
            max-width: none;
        }
    }

    pointer-events: none;

    overflow: hidden;
    margin: 0 auto;

    & > div {
        height: 100%;
    }

    .MuiInputBase-root {
        background-color: ${({ theme }) => theme.palette.common.white};
        pointer-events: initial;

        &.Mui-disabled {
            background-color: #f2f2f2;
        }
    }

    &:before {
        content: '';
        position: fixed;
        top: 64px;
        bottom: 80px;
        left: 0;
        right: 0;
        background-color: ${({ theme }) => theme.palette.common.white};

        pointer-events: initial;

        transition: 0.3s ease-in-out;
        transition-property: opacity, visibility;

        opacity: 0;
        visibility: hidden;
    }

    &[data-background='true'] {
        &:before {
            opacity: 1;
            visibility: visible;
        }
    }
`;

export const ControlPaneWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    margin: 25px;

    @media screen and (max-width: 450px) {
        margin: 16px;
    }

    // margin-bottom: 0 !important;

    position: relative;
`;
