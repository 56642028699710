import { ItemListProps } from './index.d';
import { StyledListWrapper } from './ItemList.styled';

export const ItemList = (props: ItemListProps): JSX.Element => {
    const { items, ...rest } = props;

    return (
        <StyledListWrapper {...rest} items={items}>
            {items?.map((el, i) => (
                <li key={`list_item_${i}`}>{el}</li>
            ))}
        </StyledListWrapper>
    );
};

export type { ItemListProps } from './index.d';
