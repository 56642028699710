import { Typography } from 'components/atoms/Typography';

import * as Style from './Annotation.styled';

type AnnotationPropsType = {
    title: string;
    text: string;
    icon: React.ReactNode;
};

export const Annotation = (props: AnnotationPropsType): JSX.Element => {
    const { title, text, icon } = props;

    return (
        <Style.AnnotationWrapper>
            <Style.AnnotationContent>
                <Style.Image>{icon}</Style.Image>
                <Style.Data>
                    <Typography variant="title.large">{title}</Typography>
                    <Typography variant="body.large">{text}</Typography>
                </Style.Data>
            </Style.AnnotationContent>
        </Style.AnnotationWrapper>
    );
};
