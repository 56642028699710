import { motion } from 'framer-motion';
import styled from 'styled-components';

export const LocationInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding: 12px 16px;

    border-radius: 16px;
    background-color: ${({ theme }) => theme.palette.common.white};
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.23);

    .location-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        margin-bottom: 8px;

        h2 {
            margin-left: 8px;
            margin-right: auto;
        }

        button {
            margin-left: 16px;
            white-space: nowrap;

            min-width: 130px;
        }
    }

    p {
        display: flex;
        align-items: center;

        svg {
            margin-right: 4px;
        }
    }

    #tooltip {
        padding: 20px 40px;
        background: #fff;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        display: none;
        position: fixed;
        pointer-events: none;
        z-index: 1800;
    }
`;

export const LocationInfoMotion = styled(motion.div)`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: initial;
`;
