/* eslint-disable no-nested-ternary */

import { ReactNode } from 'react';

import { ContentWrapper } from 'components/atoms/Grid';
import { SupportButton } from 'components/molecules/Buttons/SupportButton';
import { GoBackLink } from 'components/molecules/Links/GoBackLink';
import { BlockerModal } from 'components/organisms/BlockerModal';
import { GuestChatModal } from 'components/organisms/Chat/GuestChatModal';
import { LightboxGallery } from 'components/organisms/LightboxGallery';
import { FadeAnimation } from 'components/templates/FadeWrapper';
import { NavigationBar } from 'components/templates/layouts/PageLayout/NavigationBar';
import { TopAppBar } from 'components/templates/layouts/PageLayout/TopAppBar';
import { useIsAuthenticated } from 'hooks/api/auth/useIsAuthenticated';
import { PageMapConfig } from 'types/builder/pageConfig';

import { PageLayoutWrapper } from './PageLayout.styled';

type PageLayoutProps = {
    children?: ReactNode;
    currentPage?: PageMapConfig;
    path?: string;
};

export const PageLayout = ({
    children,
    currentPage,
    path,
}: PageLayoutProps): JSX.Element => {
    const {
        showBottomNavLink = true,
        showNavBar = true,
        showTopAppBar = true,
        showChatButton = true,
    } = currentPage || {};

    const { authenticated } = useIsAuthenticated();

    return (
        <FadeAnimation>
            <PageLayoutWrapper
                footerIsVisible={showNavBar}
                headerIsVisible={showTopAppBar}
            >
                {showTopAppBar ? <TopAppBar /> : undefined}
                <ContentWrapper as="main">{children}</ContentWrapper>
                {showBottomNavLink ? (
                    <GoBackLink />
                ) : showChatButton ? (
                    <div style={{ paddingTop: '80px' }} />
                ) : undefined}
                {showChatButton && !authenticated ? (
                    <SupportButton data-footer={showNavBar} />
                ) : undefined}
                {showNavBar ? (
                    <NavigationBar currentPath={path || ''} />
                ) : undefined}
                <BlockerModal />
                <GuestChatModal />
                <LightboxGallery />
            </PageLayoutWrapper>
        </FadeAnimation>
    );
};
