import styled, { keyframes } from 'styled-components';

type StyleStaffType = {
    index: number;
    is_filter: boolean;
    is_delete: boolean;
};
export const StaffWrapper = styled.div<StyleStaffType>`
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 40px;
    height: 48px;
    background: ${(props) =>
        (!props.is_filter && props.index % 2 !== 0) || props.is_delete
            ? 'white'
            : 'transparent'};
    transition: 0.3s;

    &:hover {
        background: rgba(183, 211, 12, 0.2);
        transition: 0.5s;
    }
    cursor: pointer;
`;

type TypographyWrapperType = {
    is_delete?: boolean;
};
export const TypographyWrapper = styled.span<TypographyWrapperType>`
    display: flex;
    flex: 0 0 auto;
    width: 456px;
    height: 100%;
    color: ${(props) =>
        props.is_delete
            ? props.theme.palette.grey.inactive
            : props.theme.palette.common.black};
    & p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const TogglesWrapper = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0px 104px 0px 104px;

    & div {
        display: flex;
        flex: 0 0 auto;
        margin: 0px 136px 0px 0px;
        &:last-child {
            margin: 0px 0px 0px 0px;
        }
    }
`;

export const DeleteWrapper = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 0px 0px 104px;
`;

export const DeleteText = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 366px 0px 0px;
    color: ${(props) => props.theme.palette.red};
`;
export const RestoreButton = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 0px 0px 0px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    color: ${(props) => props.theme.palette.green.betta};
    cursor: pointer;
`;
export const Timer = styled.div`
    display: flex;
    flex: 0 0 auto;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    margin: 0px 16px 0px 0px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;

    & div {
        display: flex;
        flex: 0 0 auto;
        margin: 0px 22px 0px 0px;
        &:last-child {
            margin: 0px 0px 0px 0px;
        }
    }
`;

export const IconButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
`;
export const Annotation = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px;
    height: 32px;
    top: -40px;
    background: #9ef6b0;
    border-radius: 4px;
    z-index: 0;
`;

export const AnnotationBlock = styled.div`
    position: absolute;
    display: flex;
    z-index: 0;
    top: 25px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background: #9ef6b0;
`;

const fill = keyframes`  
    0% {
        transform: rotate(0deg);
    } 
    100% {
        transform: rotate(180deg);
    }
`;

export const ProgressBarWrapper = styled.div`
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background: #d8e0d8;
    border-radius: 50%;
    position: relative;
    margin: 0px 16px 0px 0px;
`;
export const ProgressBarSpinner = styled.div`
    width: 25px;
    height: 25px;
    position: absolute;
    border-radius: 50%;
    clip: rect(0px, 25px, 25px, 12.5px);
    animation: ${fill} linear 10s;
    transform: rotate(180deg);
    & div {
        width: 25px;
        height: 25px;
        position: absolute;
        border-radius: 50%;
        animation: ${fill} linear 10s;
        transform: rotate(180deg);
        clip: rect(0px, 12.5px, 25px, 0px);
        background-color: ${(props) => props.theme.palette.common.white};
    }
`;
export const ProgressBarFiller = styled.div`
    width: 25px;
    height: 25px;
    position: absolute;
    border-radius: 50%;
    clip: rect(0px, 25px, 25px, 12.5px);
    & div {
        width: 25px;
        height: 25px;
        position: absolute;
        border-radius: 50%;
        animation: ${fill} linear 10s;
        transform: rotate(180deg);
        clip: rect(0px, 12.5px, 25px, 0px);
        background-color: ${(props) => props.theme.palette.common.white};
    }
`;
export const ProgressBarText = styled.div`
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background: white;
    line-height: 25px;
    text-align: center;
    color: ${(props) => props.theme.palette.common.black};
    z-index: 100;
    font-weight: 400;
    font-size: 12px;
`;
