import * as mui from '@mui/icons-material';

import { ASSETS_MAP } from 'assets';

import { synonyms } from './synonyms';

type IconKeys = {
    [key: string]: mui.SvgIconComponent;
};

type NestedObject = {
    [key: string]: string | NestedObject;
};

const muiIcons: IconKeys = mui;

export const muiIconsList = Object.keys(muiIcons)
    .sort()
    .filter((importName) => importName.indexOf('Outlined') !== -1)
    .map((importName) => {
        const name = importName.replace(
            /(Outlined|TwoTone|Rounded|Sharp)$/,
            ''
        );

        return {
            importName,
            searchable: `${name} ${synonyms[name] ? synonyms[name] : ''}`,
            Component: muiIcons[importName],
        };
    });

const getPaths = (
    o: NestedObject,
    root = '',
    result: string[] = []
): string[] => {
    const ok = Object.keys(o);
    return ok.reduce((a, k) => {
        const p = `${root + k}.`;
        const obj = o[k];

        if (typeof obj === 'object') {
            getPaths(obj, p, a);
        } else {
            a.push(p);
        }
        return a;
    }, result);
};

export const info67icons = getPaths(ASSETS_MAP)
    .filter((el) => el.indexOf('stars') === -1)
    .map((el) => el.slice(0, -1));
