/* eslint-disable react/no-unstable-nested-components */
import { CircularProgress, Tooltip } from '@mui/material';
import { Variants } from 'framer-motion';
import Linkify from 'react-linkify';

import { SVGAsset } from 'components/atoms/SVGAsset';
import {
    HumanAvatar,
    HumanAvatarProps,
} from 'components/molecules/HumanAvatar';
import { MessageStatusEnum, MessageUserTypeEnum } from 'types/api/message';
import { getShortEmail } from 'utils/getShortEmail';
import { getTimeByPeriod } from 'utils/getTimeByPeriod';

import {
    ChatMessageImage,
    ChatMessageWrapper,
    ChatTextBubble,
    MessageStatus,
} from './ChatMessage.styled';

export type ChatMessageProps = {
    from?: MessageUserTypeEnum;
    chatOwner: MessageUserTypeEnum;
    status?: MessageStatusEnum;
    text?: string;
    time?: Date;
    avatar?: HumanAvatarProps['human'];
    managerEmail?: string;
    image?: string;
    onImageClick?: (img: { img: string; caption?: string }) => void;
};

const MessageVariants: Variants = {
    out: {
        y: '116px',
        opacity: 0,
    },
    in: {
        y: 0,
        opacity: 1,
    },
};

export const ChatMessage = ({
    from,
    chatOwner,
    status,
    text,
    time = new Date(),
    avatar,
    managerEmail,
    image,
    onImageClick = () => undefined,
}: ChatMessageProps): JSX.Element => {
    const getStatusIcon = (): JSX.Element => {
        switch (status) {
            case 'sent':
                return (
                    <div className="status sent">
                        <SVGAsset src="icons.24px.check" />
                    </div>
                );
            case 'received':
                return (
                    <div className="status received">
                        <SVGAsset src="icons.24px.checkDouble" />
                    </div>
                );
            case 'read':
                return (
                    <div className="status read">
                        <SVGAsset src="icons.24px.checkDouble" />
                    </div>
                );
            default:
                return (
                    <div className="status loading">
                        <CircularProgress
                            size={12}
                            variant="indeterminate"
                            color="inherit"
                        />
                    </div>
                );
        }
    };

    const type = from === chatOwner ? 'out' : 'in';

    return (
        <ChatMessageWrapper
            data-type={type}
            variants={MessageVariants}
            animate="in"
            initial="out"
        >
            <ChatTextBubble data-type={type}>
                {image ? (
                    <ChatMessageImage
                        src={image}
                        onClick={() =>
                            onImageClick({
                                img: image,
                                caption: text,
                            })
                        }
                    />
                ) : undefined}
                <div>
                    <Linkify
                        componentDecorator={(
                            decoratedHref,
                            decoratedText,
                            key
                        ) => (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={decoratedHref}
                                key={key}
                            >
                                {decoratedText}
                            </a>
                        )}
                    >
                        {text}
                    </Linkify>
                </div>
            </ChatTextBubble>
            <MessageStatus data-type={type}>
                {from === 'manager' && chatOwner !== 'guest' ? (
                    <Tooltip
                        enterDelay={1000}
                        title={
                            managerEmail
                                ? `Отправлено от ${getShortEmail(managerEmail)}`
                                : ''
                        }
                        placement="bottom"
                        arrow
                    >
                        <span className="manager-label">
                            {managerEmail
                                ? getShortEmail(managerEmail)
                                : undefined}
                        </span>
                    </Tooltip>
                ) : undefined}
                {getTimeByPeriod(time.toISOString(), true)}
                {type === 'out' ? getStatusIcon() : undefined}
            </MessageStatus>
            {from === 'guest' ? (
                <HumanAvatar size="thumbnail" human={avatar} />
            ) : undefined}
        </ChatMessageWrapper>
    );
};
