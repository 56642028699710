import styled from 'styled-components';

export const PageListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: -28px;
    & > li {
        margin-bottom: 4px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .Mui-selected {
        background-color: ${({ theme }) =>
            theme.palette.glass.green} !important;
    }

    .site-tree-search-value {
        color: #f50;
    }
`;
