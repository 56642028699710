import { MouseEventHandler, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { hashLink } from 'components/atoms/HashLink';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { useMeta } from 'hooks/api/useMeta';
import { useExternalHref } from 'hooks/store/useExternalHref';
import { useGallery } from 'hooks/store/useGallery';

import { StyledSimpleLink } from './Link.styled';
import { SimpleLinkProps } from './types';

export const SimpleLink = (props: SimpleLinkProps): JSX.Element => {
    const {
        children,
        showArrow,
        fullWidth,
        type = 'internal',
        href: linkHref = '',
        hrefUuid,
        ...rest
    } = props;

    const { setExternalHref } = useExternalHref();
    const { setGallery } = useGallery();

    const { meta: urls = [] } = useMeta('guestPageList');

    const href = useMemo(() => {
        const page = urls.find((el) => el.uuid === hrefUuid);

        if (page) {
            return `${page.href}`;
        }

        return linkHref;
    }, [hrefUuid, linkHref, urls]);

    const linkChildren = (
        <>
            {children}
            {showArrow ? <SVGAsset src="icons.24px.chevron" /> : undefined}
        </>
    );

    const preview = document.body.hasAttribute('data-preview');

    const onExternalLinkClick: MouseEventHandler = (e) => {
        e.preventDefault();
        if (!preview) {
            setExternalHref(href);
        }
    };
    const onImageLinkClick: MouseEventHandler = (e) => {
        e.preventDefault();
        if (!preview) {
            setGallery(href);
        }
    };

    if (preview) {
        return (
            <StyledSimpleLink
                href={href}
                data-full-width={fullWidth}
                onClick={(e) => e.preventDefault()}
                {...rest}
            >
                {linkChildren}
            </StyledSimpleLink>
        );
    }

    switch (type) {
        case 'internal':
            return (
                <StyledSimpleLink
                    as={href.includes('#') ? hashLink : Link}
                    to={href}
                    data-full-width={fullWidth}
                    {...rest}
                >
                    {linkChildren}
                </StyledSimpleLink>
            );
        case 'chat':
            return (
                <StyledSimpleLink
                    as={Link}
                    to="?chat=open"
                    data-full-width={fullWidth}
                    {...rest}
                >
                    {linkChildren}
                </StyledSimpleLink>
            );
        case 'external':
            return (
                <StyledSimpleLink
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onExternalLinkClick}
                    data-full-width={fullWidth}
                    {...rest}
                >
                    {linkChildren}
                </StyledSimpleLink>
            );
        case 'image':
            return (
                <StyledSimpleLink
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onImageLinkClick}
                    data-full-width={fullWidth}
                    {...rest}
                >
                    {linkChildren}
                </StyledSimpleLink>
            );
        default:
            return (
                <StyledSimpleLink
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-full-width={fullWidth}
                    {...rest}
                >
                    {linkChildren}
                </StyledSimpleLink>
            );
    }
};
