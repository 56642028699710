import { css } from 'styled-components';

export const AntDStyles = css`
    [class^='ant-']::-ms-clear,
    [class*='ant-']::-ms-clear,
    [class^='ant-'] input::-ms-clear,
    [class*='ant-'] input::-ms-clear,
    [class^='ant-'] input::-ms-reveal,
    [class*='ant-'] input::-ms-reveal {
        display: none;
    }
    input::-ms-clear,
    input::-ms-reveal {
        display: none;
    }
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
    html {
        -ms-overflow-style: scrollbar;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    @-ms-viewport {
        width: device-width;
    }
    [tabindex='-1']:focus {
        outline: none !important;
    }
    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }
    abbr[title],
    abbr[data-original-title] {
        text-decoration: underline;
        -webkit-text-decoration: underline dotted;
        text-decoration: underline dotted;
        border-bottom: 0;
        cursor: help;
    }
    address {
        margin-bottom: 1em;
        font-style: normal;
        line-height: inherit;
    }
    input[type='text'],
    input[type='password'],
    input[type='number'],
    textarea {
        -webkit-appearance: none;
    }
    sub,
    sup {
        position: relative;
        font-size: 75%;
        line-height: 0;
        vertical-align: baseline;
    }
    sub {
        bottom: -0.25em;
    }
    sup {
        top: -0.5em;
    }
    a,
    area,
    button,
    [role='button'],
    input:not([type='range']),
    label,
    select,
    summary,
    textarea {
        touch-action: manipulation;
    }
    table {
        border-collapse: collapse;
    }
    input,
    button,
    select,
    optgroup,
    textarea {
        margin: 0;
        color: inherit;
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
    }
    button,
    input {
        overflow: visible;
    }
    button,
    select {
        text-transform: none;
    }
    button,
    html [type='button'],
    [type='reset'],
    [type='submit'] {
        -webkit-appearance: button;
    }
    button::-moz-focus-inner,
    [type='button']::-moz-focus-inner,
    [type='reset']::-moz-focus-inner,
    [type='submit']::-moz-focus-inner {
        padding: 0;
        border-style: none;
    }
    input[type='radio'],
    input[type='checkbox'] {
        box-sizing: border-box;
        padding: 0;
    }
    input[type='date'],
    input[type='time'],
    input[type='datetime-local'],
    input[type='month'] {
        -webkit-appearance: listbox;
    }
    textarea {
        overflow: auto;
        resize: vertical;
    }
    progress {
        vertical-align: baseline;
    }
    [type='number']::-webkit-inner-spin-button,
    [type='number']::-webkit-outer-spin-button {
        height: auto;
    }
    [type='search'] {
        outline-offset: -2px;
        -webkit-appearance: none;
    }
    [type='search']::-webkit-search-cancel-button,
    [type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }
    ::-webkit-file-upload-button {
        font: inherit;
        -webkit-appearance: button;
    }
    output {
        display: inline-block;
    }
    summary {
        display: list-item;
    }
    template {
        display: none;
    }
    [hidden] {
        display: none !important;
    }
    mark {
        padding: 0.2em;
        background-color: #feffe6;
    }
    .clearfix::before {
        display: table;
        content: '';
    }
    .clearfix::after {
        display: table;
        clear: both;
        content: '';
    }
    .anticon {
        display: inline-block;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizelegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .anticon > * {
        line-height: 1;
    }
    .anticon svg {
        display: inline-block;
    }
    .anticon::before {
        display: none;
    }
    .anticon .anticon-icon {
        display: block;
    }
    .anticon > .anticon {
        line-height: 0;
        vertical-align: 0;
    }
    .anticon[tabindex] {
        cursor: pointer;
    }
    .anticon-spin,
    .anticon-spin::before {
        display: inline-block;
        -webkit-animation: loadingCircle 1s infinite linear;
        animation: loadingCircle 1s infinite linear;
    }
    .ant-fade-enter,
    .ant-fade-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-fade-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-fade-enter.ant-fade-enter-active,
    .ant-fade-appear.ant-fade-appear-active {
        -webkit-animation-name: antFadeIn;
        animation-name: antFadeIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-fade-leave.ant-fade-leave-active {
        -webkit-animation-name: antFadeOut;
        animation-name: antFadeOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-fade-enter,
    .ant-fade-appear {
        opacity: 0;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
    .ant-fade-leave {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
    @-webkit-keyframes antFadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes antFadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @-webkit-keyframes antFadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    @keyframes antFadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    .ant-move-up-enter,
    .ant-move-up-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-move-up-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-move-up-enter.ant-move-up-enter-active,
    .ant-move-up-appear.ant-move-up-appear-active {
        -webkit-animation-name: antMoveUpIn;
        animation-name: antMoveUpIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-move-up-leave.ant-move-up-leave-active {
        -webkit-animation-name: antMoveUpOut;
        animation-name: antMoveUpOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-move-up-enter,
    .ant-move-up-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }
    .ant-move-up-leave {
        -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
        animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    }
    .ant-move-down-enter,
    .ant-move-down-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-move-down-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-move-down-enter.ant-move-down-enter-active,
    .ant-move-down-appear.ant-move-down-appear-active {
        -webkit-animation-name: antMoveDownIn;
        animation-name: antMoveDownIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-move-down-leave.ant-move-down-leave-active {
        -webkit-animation-name: antMoveDownOut;
        animation-name: antMoveDownOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-move-down-enter,
    .ant-move-down-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }
    .ant-move-down-leave {
        -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
        animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    }
    .ant-move-left-enter,
    .ant-move-left-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-move-left-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-move-left-enter.ant-move-left-enter-active,
    .ant-move-left-appear.ant-move-left-appear-active {
        -webkit-animation-name: antMoveLeftIn;
        animation-name: antMoveLeftIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-move-left-leave.ant-move-left-leave-active {
        -webkit-animation-name: antMoveLeftOut;
        animation-name: antMoveLeftOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-move-left-enter,
    .ant-move-left-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }
    .ant-move-left-leave {
        -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
        animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    }
    .ant-move-right-enter,
    .ant-move-right-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-move-right-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-move-right-enter.ant-move-right-enter-active,
    .ant-move-right-appear.ant-move-right-appear-active {
        -webkit-animation-name: antMoveRightIn;
        animation-name: antMoveRightIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-move-right-leave.ant-move-right-leave-active {
        -webkit-animation-name: antMoveRightOut;
        animation-name: antMoveRightOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-move-right-enter,
    .ant-move-right-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }
    .ant-move-right-leave {
        -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
        animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    }
    @-webkit-keyframes antMoveDownIn {
        0% {
            transform: translateY(100%);
            transform-origin: 0 0;
            opacity: 0;
        }
        100% {
            transform: translateY(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
    }
    @keyframes antMoveDownIn {
        0% {
            transform: translateY(100%);
            transform-origin: 0 0;
            opacity: 0;
        }
        100% {
            transform: translateY(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
    }
    @-webkit-keyframes antMoveDownOut {
        0% {
            transform: translateY(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
        100% {
            transform: translateY(100%);
            transform-origin: 0 0;
            opacity: 0;
        }
    }
    @keyframes antMoveDownOut {
        0% {
            transform: translateY(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
        100% {
            transform: translateY(100%);
            transform-origin: 0 0;
            opacity: 0;
        }
    }
    @-webkit-keyframes antMoveLeftIn {
        0% {
            transform: translateX(-100%);
            transform-origin: 0 0;
            opacity: 0;
        }
        100% {
            transform: translateX(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
    }
    @keyframes antMoveLeftIn {
        0% {
            transform: translateX(-100%);
            transform-origin: 0 0;
            opacity: 0;
        }
        100% {
            transform: translateX(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
    }
    @-webkit-keyframes antMoveLeftOut {
        0% {
            transform: translateX(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
        100% {
            transform: translateX(-100%);
            transform-origin: 0 0;
            opacity: 0;
        }
    }
    @keyframes antMoveLeftOut {
        0% {
            transform: translateX(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
        100% {
            transform: translateX(-100%);
            transform-origin: 0 0;
            opacity: 0;
        }
    }
    @-webkit-keyframes antMoveRightIn {
        0% {
            transform: translateX(100%);
            transform-origin: 0 0;
            opacity: 0;
        }
        100% {
            transform: translateX(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
    }
    @keyframes antMoveRightIn {
        0% {
            transform: translateX(100%);
            transform-origin: 0 0;
            opacity: 0;
        }
        100% {
            transform: translateX(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
    }
    @-webkit-keyframes antMoveRightOut {
        0% {
            transform: translateX(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
        100% {
            transform: translateX(100%);
            transform-origin: 0 0;
            opacity: 0;
        }
    }
    @keyframes antMoveRightOut {
        0% {
            transform: translateX(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
        100% {
            transform: translateX(100%);
            transform-origin: 0 0;
            opacity: 0;
        }
    }
    @-webkit-keyframes antMoveUpIn {
        0% {
            transform: translateY(-100%);
            transform-origin: 0 0;
            opacity: 0;
        }
        100% {
            transform: translateY(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
    }
    @keyframes antMoveUpIn {
        0% {
            transform: translateY(-100%);
            transform-origin: 0 0;
            opacity: 0;
        }
        100% {
            transform: translateY(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
    }
    @-webkit-keyframes antMoveUpOut {
        0% {
            transform: translateY(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
        100% {
            transform: translateY(-100%);
            transform-origin: 0 0;
            opacity: 0;
        }
    }
    @keyframes antMoveUpOut {
        0% {
            transform: translateY(0%);
            transform-origin: 0 0;
            opacity: 1;
        }
        100% {
            transform: translateY(-100%);
            transform-origin: 0 0;
            opacity: 0;
        }
    }
    @-webkit-keyframes loadingCircle {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes loadingCircle {
        100% {
            transform: rotate(360deg);
        }
    }
    [ant-click-animating='true'],
    [ant-click-animating-without-extra-node='true'] {
        position: relative;
    }
    html {
        --antd-wave-shadow-color: ${({ theme }) => theme.palette.green.betta};
        --scroll-bar: 0;
    }
    [ant-click-animating-without-extra-node='true']::after,
    .ant-click-animating-node {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        border-radius: inherit;
        box-shadow: 0 0 0 0 ${({ theme }) => theme.palette.green.betta};
        box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
        opacity: 0.2;
        -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1),
            waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
        animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1),
            waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        content: '';
        pointer-events: none;
    }
    @-webkit-keyframes waveEffect {
        100% {
            box-shadow: 0 0 0 ${({ theme }) => theme.palette.green.betta};
            box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
        }
    }
    @keyframes waveEffect {
        100% {
            box-shadow: 0 0 0 ${({ theme }) => theme.palette.green.betta};
            box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
        }
    }
    @-webkit-keyframes fadeEffect {
        100% {
            opacity: 0;
        }
    }
    @keyframes fadeEffect {
        100% {
            opacity: 0;
        }
    }
    .ant-slide-up-enter,
    .ant-slide-up-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-slide-up-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-slide-up-enter.ant-slide-up-enter-active,
    .ant-slide-up-appear.ant-slide-up-appear-active {
        -webkit-animation-name: antSlideUpIn;
        animation-name: antSlideUpIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-slide-up-leave.ant-slide-up-leave-active {
        -webkit-animation-name: antSlideUpOut;
        animation-name: antSlideUpOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-slide-up-enter,
    .ant-slide-up-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    .ant-slide-up-leave {
        -webkit-animation-timing-function: cubic-bezier(
            0.755,
            0.05,
            0.855,
            0.06
        );
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    .ant-slide-down-enter,
    .ant-slide-down-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-slide-down-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-slide-down-enter.ant-slide-down-enter-active,
    .ant-slide-down-appear.ant-slide-down-appear-active {
        -webkit-animation-name: antSlideDownIn;
        animation-name: antSlideDownIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-slide-down-leave.ant-slide-down-leave-active {
        -webkit-animation-name: antSlideDownOut;
        animation-name: antSlideDownOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-slide-down-enter,
    .ant-slide-down-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    .ant-slide-down-leave {
        -webkit-animation-timing-function: cubic-bezier(
            0.755,
            0.05,
            0.855,
            0.06
        );
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    .ant-slide-left-enter,
    .ant-slide-left-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-slide-left-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-slide-left-enter.ant-slide-left-enter-active,
    .ant-slide-left-appear.ant-slide-left-appear-active {
        -webkit-animation-name: antSlideLeftIn;
        animation-name: antSlideLeftIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-slide-left-leave.ant-slide-left-leave-active {
        -webkit-animation-name: antSlideLeftOut;
        animation-name: antSlideLeftOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-slide-left-enter,
    .ant-slide-left-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    .ant-slide-left-leave {
        -webkit-animation-timing-function: cubic-bezier(
            0.755,
            0.05,
            0.855,
            0.06
        );
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    .ant-slide-right-enter,
    .ant-slide-right-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-slide-right-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-slide-right-enter.ant-slide-right-enter-active,
    .ant-slide-right-appear.ant-slide-right-appear-active {
        -webkit-animation-name: antSlideRightIn;
        animation-name: antSlideRightIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-slide-right-leave.ant-slide-right-leave-active {
        -webkit-animation-name: antSlideRightOut;
        animation-name: antSlideRightOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-slide-right-enter,
    .ant-slide-right-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    .ant-slide-right-leave {
        -webkit-animation-timing-function: cubic-bezier(
            0.755,
            0.05,
            0.855,
            0.06
        );
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    @-webkit-keyframes antSlideUpIn {
        0% {
            transform: scaleY(0.8);
            transform-origin: 0% 0%;
            opacity: 0;
        }
        100% {
            transform: scaleY(1);
            transform-origin: 0% 0%;
            opacity: 1;
        }
    }
    @keyframes antSlideUpIn {
        0% {
            transform: scaleY(0.8);
            transform-origin: 0% 0%;
            opacity: 0;
        }
        100% {
            transform: scaleY(1);
            transform-origin: 0% 0%;
            opacity: 1;
        }
    }
    @-webkit-keyframes antSlideUpOut {
        0% {
            transform: scaleY(1);
            transform-origin: 0% 0%;
            opacity: 1;
        }
        100% {
            transform: scaleY(0.8);
            transform-origin: 0% 0%;
            opacity: 0;
        }
    }
    @keyframes antSlideUpOut {
        0% {
            transform: scaleY(1);
            transform-origin: 0% 0%;
            opacity: 1;
        }
        100% {
            transform: scaleY(0.8);
            transform-origin: 0% 0%;
            opacity: 0;
        }
    }
    @-webkit-keyframes antSlideDownIn {
        0% {
            transform: scaleY(0.8);
            transform-origin: 100% 100%;
            opacity: 0;
        }
        100% {
            transform: scaleY(1);
            transform-origin: 100% 100%;
            opacity: 1;
        }
    }
    @keyframes antSlideDownIn {
        0% {
            transform: scaleY(0.8);
            transform-origin: 100% 100%;
            opacity: 0;
        }
        100% {
            transform: scaleY(1);
            transform-origin: 100% 100%;
            opacity: 1;
        }
    }
    @-webkit-keyframes antSlideDownOut {
        0% {
            transform: scaleY(1);
            transform-origin: 100% 100%;
            opacity: 1;
        }
        100% {
            transform: scaleY(0.8);
            transform-origin: 100% 100%;
            opacity: 0;
        }
    }
    @keyframes antSlideDownOut {
        0% {
            transform: scaleY(1);
            transform-origin: 100% 100%;
            opacity: 1;
        }
        100% {
            transform: scaleY(0.8);
            transform-origin: 100% 100%;
            opacity: 0;
        }
    }
    @-webkit-keyframes antSlideLeftIn {
        0% {
            transform: scaleX(0.8);
            transform-origin: 0% 0%;
            opacity: 0;
        }
        100% {
            transform: scaleX(1);
            transform-origin: 0% 0%;
            opacity: 1;
        }
    }
    @keyframes antSlideLeftIn {
        0% {
            transform: scaleX(0.8);
            transform-origin: 0% 0%;
            opacity: 0;
        }
        100% {
            transform: scaleX(1);
            transform-origin: 0% 0%;
            opacity: 1;
        }
    }
    @-webkit-keyframes antSlideLeftOut {
        0% {
            transform: scaleX(1);
            transform-origin: 0% 0%;
            opacity: 1;
        }
        100% {
            transform: scaleX(0.8);
            transform-origin: 0% 0%;
            opacity: 0;
        }
    }
    @keyframes antSlideLeftOut {
        0% {
            transform: scaleX(1);
            transform-origin: 0% 0%;
            opacity: 1;
        }
        100% {
            transform: scaleX(0.8);
            transform-origin: 0% 0%;
            opacity: 0;
        }
    }
    @-webkit-keyframes antSlideRightIn {
        0% {
            transform: scaleX(0.8);
            transform-origin: 100% 0%;
            opacity: 0;
        }
        100% {
            transform: scaleX(1);
            transform-origin: 100% 0%;
            opacity: 1;
        }
    }
    @keyframes antSlideRightIn {
        0% {
            transform: scaleX(0.8);
            transform-origin: 100% 0%;
            opacity: 0;
        }
        100% {
            transform: scaleX(1);
            transform-origin: 100% 0%;
            opacity: 1;
        }
    }
    @-webkit-keyframes antSlideRightOut {
        0% {
            transform: scaleX(1);
            transform-origin: 100% 0%;
            opacity: 1;
        }
        100% {
            transform: scaleX(0.8);
            transform-origin: 100% 0%;
            opacity: 0;
        }
    }
    @keyframes antSlideRightOut {
        0% {
            transform: scaleX(1);
            transform-origin: 100% 0%;
            opacity: 1;
        }
        100% {
            transform: scaleX(0.8);
            transform-origin: 100% 0%;
            opacity: 0;
        }
    }
    .ant-zoom-enter,
    .ant-zoom-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-enter.ant-zoom-enter-active,
    .ant-zoom-appear.ant-zoom-appear-active {
        -webkit-animation-name: antZoomIn;
        animation-name: antZoomIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-zoom-leave.ant-zoom-leave-active {
        -webkit-animation-name: antZoomOut;
        animation-name: antZoomOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-zoom-enter,
    .ant-zoom-appear {
        transform: scale(0);
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }
    .ant-zoom-enter-prepare,
    .ant-zoom-appear-prepare {
        transform: none;
    }
    .ant-zoom-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
    .ant-zoom-big-enter,
    .ant-zoom-big-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-big-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-big-enter.ant-zoom-big-enter-active,
    .ant-zoom-big-appear.ant-zoom-big-appear-active {
        -webkit-animation-name: antZoomBigIn;
        animation-name: antZoomBigIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-zoom-big-leave.ant-zoom-big-leave-active {
        -webkit-animation-name: antZoomBigOut;
        animation-name: antZoomBigOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-zoom-big-enter,
    .ant-zoom-big-appear {
        transform: scale(0);
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }
    .ant-zoom-big-enter-prepare,
    .ant-zoom-big-appear-prepare {
        transform: none;
    }
    .ant-zoom-big-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
    .ant-zoom-big-fast-enter,
    .ant-zoom-big-fast-appear {
        -webkit-animation-duration: 0.1s;
        animation-duration: 0.1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-big-fast-leave {
        -webkit-animation-duration: 0.1s;
        animation-duration: 0.1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
    .ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
        -webkit-animation-name: antZoomBigIn;
        animation-name: antZoomBigIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
        -webkit-animation-name: antZoomBigOut;
        animation-name: antZoomBigOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-zoom-big-fast-enter,
    .ant-zoom-big-fast-appear {
        transform: scale(0);
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }
    .ant-zoom-big-fast-enter-prepare,
    .ant-zoom-big-fast-appear-prepare {
        transform: none;
    }
    .ant-zoom-big-fast-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
    .ant-zoom-up-enter,
    .ant-zoom-up-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-up-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-up-enter.ant-zoom-up-enter-active,
    .ant-zoom-up-appear.ant-zoom-up-appear-active {
        -webkit-animation-name: antZoomUpIn;
        animation-name: antZoomUpIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-zoom-up-leave.ant-zoom-up-leave-active {
        -webkit-animation-name: antZoomUpOut;
        animation-name: antZoomUpOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-zoom-up-enter,
    .ant-zoom-up-appear {
        transform: scale(0);
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }
    .ant-zoom-up-enter-prepare,
    .ant-zoom-up-appear-prepare {
        transform: none;
    }
    .ant-zoom-up-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
    .ant-zoom-down-enter,
    .ant-zoom-down-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-down-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-down-enter.ant-zoom-down-enter-active,
    .ant-zoom-down-appear.ant-zoom-down-appear-active {
        -webkit-animation-name: antZoomDownIn;
        animation-name: antZoomDownIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-zoom-down-leave.ant-zoom-down-leave-active {
        -webkit-animation-name: antZoomDownOut;
        animation-name: antZoomDownOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-zoom-down-enter,
    .ant-zoom-down-appear {
        transform: scale(0);
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }
    .ant-zoom-down-enter-prepare,
    .ant-zoom-down-appear-prepare {
        transform: none;
    }
    .ant-zoom-down-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
    .ant-zoom-left-enter,
    .ant-zoom-left-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-left-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-left-enter.ant-zoom-left-enter-active,
    .ant-zoom-left-appear.ant-zoom-left-appear-active {
        -webkit-animation-name: antZoomLeftIn;
        animation-name: antZoomLeftIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-zoom-left-leave.ant-zoom-left-leave-active {
        -webkit-animation-name: antZoomLeftOut;
        animation-name: antZoomLeftOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-zoom-left-enter,
    .ant-zoom-left-appear {
        transform: scale(0);
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }
    .ant-zoom-left-enter-prepare,
    .ant-zoom-left-appear-prepare {
        transform: none;
    }
    .ant-zoom-left-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
    .ant-zoom-right-enter,
    .ant-zoom-right-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-right-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .ant-zoom-right-enter.ant-zoom-right-enter-active,
    .ant-zoom-right-appear.ant-zoom-right-appear-active {
        -webkit-animation-name: antZoomRightIn;
        animation-name: antZoomRightIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .ant-zoom-right-leave.ant-zoom-right-leave-active {
        -webkit-animation-name: antZoomRightOut;
        animation-name: antZoomRightOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .ant-zoom-right-enter,
    .ant-zoom-right-appear {
        transform: scale(0);
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }
    .ant-zoom-right-enter-prepare,
    .ant-zoom-right-appear-prepare {
        transform: none;
    }
    .ant-zoom-right-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
    @-webkit-keyframes antZoomIn {
        0% {
            transform: scale(0.2);
            opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    @keyframes antZoomIn {
        0% {
            transform: scale(0.2);
            opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    @-webkit-keyframes antZoomOut {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.2);
            opacity: 0;
        }
    }
    @keyframes antZoomOut {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.2);
            opacity: 0;
        }
    }
    @-webkit-keyframes antZoomBigIn {
        0% {
            transform: scale(0.8);
            opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    @keyframes antZoomBigIn {
        0% {
            transform: scale(0.8);
            opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    @-webkit-keyframes antZoomBigOut {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.8);
            opacity: 0;
        }
    }
    @keyframes antZoomBigOut {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.8);
            opacity: 0;
        }
    }
    @-webkit-keyframes antZoomUpIn {
        0% {
            transform: scale(0.8);
            transform-origin: 50% 0%;
            opacity: 0;
        }
        100% {
            transform: scale(1);
            transform-origin: 50% 0%;
        }
    }
    @keyframes antZoomUpIn {
        0% {
            transform: scale(0.8);
            transform-origin: 50% 0%;
            opacity: 0;
        }
        100% {
            transform: scale(1);
            transform-origin: 50% 0%;
        }
    }
    @-webkit-keyframes antZoomUpOut {
        0% {
            transform: scale(1);
            transform-origin: 50% 0%;
        }
        100% {
            transform: scale(0.8);
            transform-origin: 50% 0%;
            opacity: 0;
        }
    }
    @keyframes antZoomUpOut {
        0% {
            transform: scale(1);
            transform-origin: 50% 0%;
        }
        100% {
            transform: scale(0.8);
            transform-origin: 50% 0%;
            opacity: 0;
        }
    }
    @-webkit-keyframes antZoomLeftIn {
        0% {
            transform: scale(0.8);
            transform-origin: 0% 50%;
            opacity: 0;
        }
        100% {
            transform: scale(1);
            transform-origin: 0% 50%;
        }
    }
    @keyframes antZoomLeftIn {
        0% {
            transform: scale(0.8);
            transform-origin: 0% 50%;
            opacity: 0;
        }
        100% {
            transform: scale(1);
            transform-origin: 0% 50%;
        }
    }
    @-webkit-keyframes antZoomLeftOut {
        0% {
            transform: scale(1);
            transform-origin: 0% 50%;
        }
        100% {
            transform: scale(0.8);
            transform-origin: 0% 50%;
            opacity: 0;
        }
    }
    @keyframes antZoomLeftOut {
        0% {
            transform: scale(1);
            transform-origin: 0% 50%;
        }
        100% {
            transform: scale(0.8);
            transform-origin: 0% 50%;
            opacity: 0;
        }
    }
    @-webkit-keyframes antZoomRightIn {
        0% {
            transform: scale(0.8);
            transform-origin: 100% 50%;
            opacity: 0;
        }
        100% {
            transform: scale(1);
            transform-origin: 100% 50%;
        }
    }
    @keyframes antZoomRightIn {
        0% {
            transform: scale(0.8);
            transform-origin: 100% 50%;
            opacity: 0;
        }
        100% {
            transform: scale(1);
            transform-origin: 100% 50%;
        }
    }
    @-webkit-keyframes antZoomRightOut {
        0% {
            transform: scale(1);
            transform-origin: 100% 50%;
        }
        100% {
            transform: scale(0.8);
            transform-origin: 100% 50%;
            opacity: 0;
        }
    }
    @keyframes antZoomRightOut {
        0% {
            transform: scale(1);
            transform-origin: 100% 50%;
        }
        100% {
            transform: scale(0.8);
            transform-origin: 100% 50%;
            opacity: 0;
        }
    }
    @-webkit-keyframes antZoomDownIn {
        0% {
            transform: scale(0.8);
            transform-origin: 50% 100%;
            opacity: 0;
        }
        100% {
            transform: scale(1);
            transform-origin: 50% 100%;
        }
    }
    @keyframes antZoomDownIn {
        0% {
            transform: scale(0.8);
            transform-origin: 50% 100%;
            opacity: 0;
        }
        100% {
            transform: scale(1);
            transform-origin: 50% 100%;
        }
    }
    @-webkit-keyframes antZoomDownOut {
        0% {
            transform: scale(1);
            transform-origin: 50% 100%;
        }
        100% {
            transform: scale(0.8);
            transform-origin: 50% 100%;
            opacity: 0;
        }
    }
    @keyframes antZoomDownOut {
        0% {
            transform: scale(1);
            transform-origin: 50% 100%;
        }
        100% {
            transform: scale(0.8);
            transform-origin: 50% 100%;
            opacity: 0;
        }
    }
    .ant-motion-collapse-legacy {
        overflow: hidden;
    }
    .ant-motion-collapse-legacy-active {
        transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
            opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    }
    .ant-motion-collapse {
        overflow: hidden;
        transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
            opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    }
`;

export const AntDTreeStyles = css`
    /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
    /* stylelint-disable no-duplicate-selectors */
    /* stylelint-disable */
    /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
    @-webkit-keyframes antCheckboxEffect {
        0% {
            transform: scale(1);
            opacity: 0.5;
        }
        100% {
            transform: scale(1.6);
            opacity: 0;
        }
    }
    @keyframes antCheckboxEffect {
        0% {
            transform: scale(1);
            opacity: 0.5;
        }
        100% {
            transform: scale(1.6);
            opacity: 0;
        }
    }
    @-webkit-keyframes ant-tree-node-fx-do-not-use {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes ant-tree-node-fx-do-not-use {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode {
        position: relative;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 4px;
        left: 0;
        transition: background-color 0.3s;
        content: '';
        pointer-events: none;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
        background: #f5f5f5;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode > * {
        z-index: 1;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
        transition: color 0.3s;
    }
    .ant-tree.ant-tree-directory
        .ant-tree-treenode
        .ant-tree-node-content-wrapper {
        border-radius: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .ant-tree.ant-tree-directory
        .ant-tree-treenode
        .ant-tree-node-content-wrapper:hover {
        background: transparent;
    }
    .ant-tree.ant-tree-directory
        .ant-tree-treenode
        .ant-tree-node-content-wrapper.ant-tree-node-selected {
        color: #fff;
        background: transparent;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
        background: ${({ theme }) => theme.palette.green.betta};
    }
    .ant-tree.ant-tree-directory
        .ant-tree-treenode-selected
        .ant-tree-switcher {
        color: #fff;
    }
    .ant-tree.ant-tree-directory
        .ant-tree-treenode-selected
        .ant-tree-node-content-wrapper {
        color: #fff;
        background: transparent;
    }
    .ant-tree-checkbox {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum';
        position: relative;
        top: 0.2em;
        line-height: 1;
        white-space: nowrap;
        outline: none;
        cursor: pointer;
    }
    .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
    .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
    .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
        border-color: ${({ theme }) => theme.palette.green.betta};
    }
    .ant-tree-checkbox-checked::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid ${({ theme }) => theme.palette.green.betta};
        border-radius: 2px;
        visibility: hidden;
        -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
        animation: antCheckboxEffect 0.36s ease-in-out;
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        content: '';
    }
    .ant-tree-checkbox:hover::after,
    .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
        visibility: visible;
    }
    .ant-tree-checkbox-inner {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        width: 16px;
        height: 16px;
        direction: ltr;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        border-collapse: separate;
        transition: all 0.3s;
    }
    .ant-tree-checkbox-inner::after {
        position: absolute;
        top: 50%;
        left: 21.5%;
        display: table;
        width: 5.71428571px;
        height: 9.14285714px;
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(0) translate(-50%, -50%);
        opacity: 0;
        transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
        content: ' ';
    }
    .ant-tree-checkbox-input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
        position: absolute;
        display: table;
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        content: ' ';
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: ${({ theme }) => theme.palette.green.betta};
        border-color: ${({ theme }) => theme.palette.green.betta};
    }
    .ant-tree-checkbox-disabled {
        cursor: not-allowed;
    }
    .ant-tree-checkbox-disabled.ant-tree-checkbox-checked
        .ant-tree-checkbox-inner::after {
        border-color: rgba(0, 0, 0, 0.25);
        -webkit-animation-name: none;
        animation-name: none;
    }
    .ant-tree-checkbox-disabled .ant-tree-checkbox-input {
        cursor: not-allowed;
        pointer-events: none;
    }
    .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
        background-color: #f5f5f5;
        border-color: #d9d9d9 !important;
    }
    .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
        border-color: #f5f5f5;
        border-collapse: separate;
        -webkit-animation-name: none;
        animation-name: none;
    }
    .ant-tree-checkbox-disabled + span {
        color: rgba(0, 0, 0, 0.25);
        cursor: not-allowed;
    }
    .ant-tree-checkbox-disabled:hover::after,
    .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
        visibility: hidden;
    }
    .ant-tree-checkbox-wrapper {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum';
        display: inline-flex;
        align-items: baseline;
        line-height: unset;
        cursor: pointer;
    }
    .ant-tree-checkbox-wrapper::after {
        display: inline-block;
        width: 0;
        overflow: hidden;
        content: '\a0';
    }
    .ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
        cursor: not-allowed;
    }
    .ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
        margin-left: 8px;
    }
    .ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-in-form-item
        input[type='checkbox'] {
        width: 14px;
        height: 14px;
    }
    .ant-tree-checkbox + span {
        padding-right: 8px;
        padding-left: 8px;
    }
    .ant-tree-checkbox-group {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum';
        display: inline-block;
    }
    .ant-tree-checkbox-group-item {
        margin-right: 8px;
    }
    .ant-tree-checkbox-group-item:last-child {
        margin-right: 0;
    }
    .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
        margin-left: 0;
    }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
        background-color: #fff;
        border-color: #d9d9d9;
    }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        background-color: ${({ theme }) => theme.palette.green.betta};
        border: 0;
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        content: ' ';
    }
    .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled
        .ant-tree-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(0, 0, 0, 0.25);
    }
    .ant-tree {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum';
        background: transparent;
        border-radius: 2px;
        transition: background-color 0.3s;
    }
    .ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
        background: #e6f7ff;
    }
    .ant-tree-list-holder-inner {
        align-items: flex-start;
    }
    .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
        align-items: stretch;
    }
    .ant-tree.ant-tree-block-node
        .ant-tree-list-holder-inner
        .ant-tree-node-content-wrapper {
        flex: auto;
    }
    .ant-tree.ant-tree-block-node
        .ant-tree-list-holder-inner
        .ant-tree-treenode.dragging {
        position: relative;
    }
    .ant-tree.ant-tree-block-node
        .ant-tree-list-holder-inner
        .ant-tree-treenode.dragging::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 4px;
        left: 0;
        border: 1px solid ${({ theme }) => theme.palette.green.betta};
        opacity: 0;
        -webkit-animation: ant-tree-node-fx-do-not-use 0.3s;
        animation: ant-tree-node-fx-do-not-use 0.3s;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        content: '';
        pointer-events: none;
    }
    .ant-tree .ant-tree-treenode {
        display: flex;
        align-items: flex-start;
        padding: 0 0 4px 0;
        outline: none;
    }
    .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
        color: rgba(0, 0, 0, 0.25);
        cursor: not-allowed;
    }
    .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
        background: transparent;
    }
    .ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
        background: #f5f5f5;
    }
    .ant-tree
        .ant-tree-treenode:not(.ant-tree
            .ant-tree-treenode-disabled).filter-node
        .ant-tree-title {
        color: inherit;
        font-weight: 500;
    }
    .ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
        width: 24px;
        line-height: 24px;
        text-align: center;
        visibility: visible;
        opacity: 0.2;
        transition: opacity 0.3s;
    }
    .ant-tree-treenode:hover
        .ant-tree
        .ant-tree-treenode-draggable
        .ant-tree-draggable-icon {
        opacity: 0.45;
    }
    .ant-tree-indent {
        align-self: stretch;
        white-space: nowrap;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .ant-tree-indent-unit {
        display: inline-block;
        width: 24px;
    }
    .ant-tree-draggable-icon {
        visibility: hidden;
    }
    .ant-tree-switcher {
        position: relative;
        flex: none;
        align-self: stretch;
        width: 24px;
        margin: 0;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .ant-tree-switcher .ant-tree-switcher-icon,
    .ant-tree-switcher .ant-select-tree-switcher-icon {
        display: inline-block;
        font-size: 10px;
        vertical-align: baseline;
    }
    .ant-tree-switcher .ant-tree-switcher-icon svg,
    .ant-tree-switcher .ant-select-tree-switcher-icon svg {
        transition: transform 0.3s;
    }
    .ant-tree-switcher-noop {
        cursor: default;
    }
    .ant-tree-switcher_close .ant-tree-switcher-icon svg {
        transform: rotate(-90deg);
    }
    .ant-tree-switcher-loading-icon {
        color: ${({ theme }) => theme.palette.green.betta};
    }
    .ant-tree-switcher-leaf-line {
        position: relative;
        z-index: 1;
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    .ant-tree-switcher-leaf-line::before {
        position: absolute;
        top: 0;
        right: 12px;
        bottom: -4px;
        margin-left: -1px;
        border-right: 1px solid #d9d9d9;
        content: ' ';
    }
    .ant-tree-switcher-leaf-line::after {
        position: absolute;
        width: 10px;
        height: 14px;
        border-bottom: 1px solid #d9d9d9;
        content: ' ';
    }
    .ant-tree-checkbox {
        top: initial;
        margin: 4px 8px 0 0;
    }
    .ant-tree .ant-tree-node-content-wrapper {
        position: relative;
        z-index: auto;
        min-height: 24px;
        margin: 0;
        padding: 0 4px;
        color: inherit;
        line-height: 24px;
        background: transparent;
        border-radius: 2px;
        cursor: pointer;
        transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
    }
    .ant-tree .ant-tree-node-content-wrapper:hover {
        background-color: ${({ theme }) => theme.palette.grey.bg1};
    }
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: ${({ theme }) => theme.palette.glass.green};
    }
    .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        vertical-align: top;
    }
    .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
        display: none;
    }
    .ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
        background-color: transparent;
    }
    .ant-tree-node-content-wrapper {
        line-height: 24px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .ant-tree-node-content-wrapper .ant-tree-drop-indicator {
        position: absolute;
        z-index: 1;
        height: 2px;
        background-color: ${({ theme }) => theme.palette.green.betta};
        border-radius: 1px;
        pointer-events: none;
    }
    .ant-tree-node-content-wrapper .ant-tree-drop-indicator::after {
        position: absolute;
        top: -3px;
        left: -6px;
        width: 8px;
        height: 8px;
        background-color: transparent;
        border: 2px solid ${({ theme }) => theme.palette.green.betta};
        border-radius: 50%;
        content: '';
    }
    .ant-tree .ant-tree-treenode.drop-container > [draggable] {
        box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.green.betta};
    }
    .ant-tree-show-line .ant-tree-indent-unit {
        position: relative;
        height: 100%;
    }
    .ant-tree-show-line .ant-tree-indent-unit::before {
        position: absolute;
        top: 0;
        right: 12px;
        bottom: -4px;
        border-right: 1px solid #d9d9d9;
        content: '';
    }
    .ant-tree-show-line .ant-tree-indent-unit-end::before {
        display: none;
    }
    .ant-tree-show-line .ant-tree-switcher {
        background: #fff;
    }
    .ant-tree-show-line .ant-tree-switcher-line-icon {
        vertical-align: -0.15em;
    }
    .ant-tree
        .ant-tree-treenode-leaf-last
        .ant-tree-switcher-leaf-line::before {
        top: auto !important;
        bottom: auto !important;
        height: 14px !important;
    }
    .ant-tree-rtl {
        direction: rtl;
    }
    .ant-tree-rtl
        .ant-tree-node-content-wrapper[draggable='true']
        .ant-tree-drop-indicator::after {
        right: -6px;
        left: unset;
    }
    .ant-tree .ant-tree-treenode-rtl {
        direction: rtl;
    }
    .ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
        transform: rotate(90deg);
    }
    .ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
        right: auto;
        left: -13px;
        border-right: none;
        border-left: 1px solid #d9d9d9;
    }
    .ant-tree-rtl .ant-tree-checkbox {
        margin: 4px 0 0 8px;
    }
    .ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
        margin: 4px 0 0 8px;
    }
`;
