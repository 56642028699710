import styled from 'styled-components';

export const QuizQuestionWrapper = styled.div`
    padding-top: 32px;
`;

export const QuizTitleAndAvatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    h1 {
        margin-right: 8px;
    }
`;

export const QuestionBlockWrapper = styled.div`
    background: ${({ theme }) => theme.palette.glass.green};
    border-radius: ${({ theme }) => theme.borderRadius.large};
    padding: 32px 16px;
    margin: 0 -16px;
    position: relative;

    &::before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        top: -24px;
        border-left: 24px solid transparent;
        border-bottom: 24px solid ${({ theme }) => theme.palette.glass.green};
    }

    &[data-avatar-size='large'] {
        &::before {
            right: 80px;
        }
    }
    &[data-avatar-size='medium'] {
        &::before {
            right: 67px;
        }
    }
    &[data-avatar-size='small'] {
        &::before {
            right: 59px;
        }
    }
`;

export const QuestionBlockGrid = styled.div`
    display: flex;
    flex-direction: column;
    margin: -12px;
    & > div {
        padding: 12px;
    }
`;
