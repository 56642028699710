/* eslint-disable no-underscore-dangle */
import { ChangeEventHandler } from 'react';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
} from '@mui/material';
import { ReactSVG } from 'react-svg';

import alignCenter from 'assets/icons/24px/editor/text-align-center.svg';
import alignJustify from 'assets/icons/24px/editor/text-align-justified.svg';
import alignLeft from 'assets/icons/24px/editor/text-align-left.svg';
import alignRight from 'assets/icons/24px/editor/text-align-right.svg';
import { Typography, TypographyProps } from 'components/atoms/Typography';
import { TypographyBadge } from 'components/atoms/Typography/Typography.styled';
import { TypographyConfig } from 'types/builder/componentConfig';

type TypographyControlProps = {
    node?: TypographyConfig;
    onChange: (node?: TypographyConfig) => void;
};

type Variant = TypographyProps['variant'];
type TextAlign = TypographyProps['textAlign'];
type Color = TypographyProps['$color'];

const variants: {
    value: Variant;
    title: string;
}[] = [
    {
        value: 'display.medium',
        title: 'Заголовок 1',
    },
    {
        value: 'title.large',
        title: 'Заголовок 2',
    },
    {
        value: 'title.medium',
        title: 'Заголовок 3',
    },
    {
        value: 'title.small',
        title: 'Параграф (жирный)',
    },
    {
        value: 'body.medium',
        title: 'Параграф',
    },
];

const colors: {
    value: Color;
    title: string;
}[] = [
    {
        value: 'common.black',
        title: 'Чёрный',
    },
    {
        value: 'common.grey',
        title: 'Серый',
    },
    {
        value: 'common.white',
        title: 'Белый',
    },
    {
        value: 'green.alpha',
        title: 'Салатовый',
    },
    {
        value: 'green.betta',
        title: 'Зелёный',
    },
    {
        value: 'green.gamma',
        title: 'Аквамариновый',
    },
    {
        value: 'red',
        title: 'Красный',
    },
    {
        value: 'yellow',
        title: 'Жёлтый',
    },
];

const textAlignment = [
    {
        value: 'justify',
        icon: alignJustify,
        title: 'По ширине',
    },
    {
        value: 'left',
        icon: alignLeft,
        title: 'По левому краю',
    },
    {
        value: 'center',
        icon: alignCenter,
        title: 'По центру',
    },
    {
        value: 'right',
        icon: alignRight,
        title: 'По правому краю',
    },
];

export const TypographyControl = ({
    node,
    onChange,
}: TypographyControlProps): JSX.Element => {
    const onTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        onChange({
            component: 'typography',
            props: {
                ...node?.props,
                children: value,
            },
        });
    };

    const onVariantChange = (value: TypographyProps['variant']): void => {
        onChange({
            component: 'typography',
            props: {
                ...node?.props,
                variant: value,
            },
        });
    };

    const onAlignmentChange = (value: TypographyProps['textAlign']): void => {
        onChange({
            component: 'typography',
            props: {
                ...node?.props,
                textAlign: value,
            },
        });
    };
    const onColorChange = (value: TypographyProps['$color']): void => {
        onChange({
            component: 'typography',
            props: {
                ...node?.props,
                $color: value,
            },
        });
    };

    const getConditionalColor = (): string => {
        if (node?.props?.variant === 'body.medium') {
            return 'common.grey';
        }
        return 'common.black';
    };

    const {
        textAlign = 'left',
        children,
        variant = 'body.medium',
        $color = getConditionalColor(),
    } = node?.props || {};

    return (
        <Stack spacing={2}>
            <FormControl variant="outlined">
                <TextField
                    label="Текст"
                    minRows={1}
                    multiline
                    size="small"
                    fullWidth
                    name=""
                    value={children || ''}
                    onChange={onTextChange}
                />
            </FormControl>
            <FormControl variant="outlined" size="small">
                <InputLabel id="base-route-label">Вариант</InputLabel>
                <Select
                    labelId="base-route-label"
                    label="Вариант"
                    value={variant || ''}
                    renderValue={(v) => (
                        <span>
                            {variants.find((el) => el.value === v)?.title}
                        </span>
                    )}
                >
                    {variants.map((el, i) => (
                        <MenuItem
                            key={`menu_item_${i}`}
                            value={el.value}
                            onClick={() => onVariantChange(el.value)}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                <Typography variant={el.value}>
                                    {el.title}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
            >
                <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel id="base-route-label">Цвет</InputLabel>
                    <Select
                        labelId="base-route-label"
                        label="Цвет"
                        value={$color || ''}
                    >
                        {colors.map((el, i) => (
                            <MenuItem
                                key={`menu_item_${i}`}
                                value={el.value}
                                onClick={() => onColorChange(el.value)}
                            >
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <TypographyBadge $color={el.value} />
                                    <Typography>{el.title}</Typography>
                                </Stack>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Stack direction="row" alignItems="center" color="gray">
                    {textAlignment.map((el, i) => (
                        <Tooltip
                            arrow
                            placement="bottom"
                            title={el.title}
                            enterDelay={1000}
                            key={`align_btn_${i}`}
                        >
                            <Button
                                onClick={() =>
                                    onAlignmentChange(el.value as TextAlign)
                                }
                                variant={
                                    textAlign === el.value
                                        ? 'contained'
                                        : 'text'
                                }
                                className="svg-color-inherit"
                                color="inherit"
                                size="small"
                                sx={{
                                    minWidth: 0,
                                    padding: '1px 2px',
                                }}
                            >
                                <ReactSVG src={el.icon} />
                            </Button>
                        </Tooltip>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
};
