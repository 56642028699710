import { ReactNode } from 'react';

import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { customTheme, GlobalStyles, muiTheme } from 'theme';
import {} from 'theme/styles/CSSBaseline';

interface IThemeProviderProps {
    children: ReactNode;
}

export const ThemeProvider = ({
    children,
}: IThemeProviderProps): JSX.Element => {
    return (
        <StyledThemeProvider theme={customTheme}>
            <MuiThemeProvider theme={muiTheme}>
                <GlobalStyles />
                {children}
            </MuiThemeProvider>
        </StyledThemeProvider>
    );
};
