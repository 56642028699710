import { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { setSelectedPage } from 'store/slices/pageBuilderSlice';
import { setStateLocation } from 'store/slices/pointBuilderSlice';
import { SelectedLocation } from 'types/map/location';

type UseSelectedPointReturn = {
    selectedPointId: number | undefined;
    selectedPointData: SelectedLocation | undefined;
    setSelectedPoint: (id?: number) => void;
};

export const useSelectedPoint = (): UseSelectedPointReturn => {
    const dispatch = useAppDispatch();

    const { location, points } = useAppSelector((state) => state.pointBuilder);

    const { list: pointsList } = points;

    const { selected, data } = location;

    useEffect(() => {
        dispatch(
            setStateLocation({
                data: pointsList?.find((el) => el.id === selected),
            })
        );
    }, [dispatch, pointsList, selected]);

    const setSelectedPoint = useCallback(
        (id?: number) => {
            dispatch(
                setStateLocation({
                    selected: id,
                })
            );
            if (id) {
                dispatch(setSelectedPage(undefined));
            }
        },
        [dispatch]
    );

    return {
        selectedPointId: selected,
        selectedPointData: data,
        setSelectedPoint,
    };
};
