import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { QuizOptions } from 'types/builder/quizConfig';

type ValueType = QuizOptions['avatarSize'];

type QuizAvatarSize = {
    value: ValueType;
    title: string;
};
const avatarSizes: QuizAvatarSize[] = [
    {
        value: 'small',
        title: 'Маленький',
    },
    {
        value: 'medium',
        title: 'Средний',
    },
    {
        value: 'large',
        title: 'Большой',
    },
];

type AvatarSizeControlProps = {
    value: ValueType;
    onChange: (val: ValueType) => void;
};

export const AvatarSizeControl = ({
    onChange,
    value,
}: AvatarSizeControlProps): JSX.Element => {
    return (
        <FormControl variant="outlined" size="small">
            <InputLabel id="base-route-label">Размер аватара</InputLabel>
            <Select
                labelId="base-route-label"
                label="Размер аватара"
                value={value || ''}
            >
                {avatarSizes.map((el, i) => (
                    <MenuItem
                        key={`menu_item_${i}`}
                        value={el.value}
                        onClick={() => onChange(el.value)}
                    >
                        {el.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
