/* eslint-disable import/no-unresolved */
import { useEffect } from 'react';

import Lightbox from 'react-image-lightbox';

import { useGallery } from 'hooks/store/useGallery';

import 'react-image-lightbox/style.css';

export const LightboxGallery = (): JSX.Element | null => {
    const { gallery, setGallery } = useGallery();

    useEffect(() => {
        if (gallery !== undefined) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [gallery]);

    return gallery !== undefined ? (
        <Lightbox
            mainSrc={gallery}
            key={gallery}
            onImageLoad={() => {
                window.dispatchEvent(new Event('resize'));
            }}
            onCloseRequest={() => setGallery(undefined)}
            imagePadding={80}
        />
    ) : null;
};
