import styled from 'styled-components';

export const NodeTreeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
`;

export const NodeTreeHeaderWrapper = styled.div`
    padding: 20px 28px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
`;

export const NodeTreeScrollContainer = styled.div`
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow-y: auto;
    padding: 0 28px;
    padding-bottom: 28px;

    .ant-tree {
        margin-right: -28px;
        .ant-tree-treenode {
            .icons {
                svg {
                    width: 24px;
                    height: 24px;
                    padding: 3px;
                }
            }

            .ant-tree-iconEle {
                svg path {
                    &[stroke] {
                        stroke: currentColor;
                    }
                    &[fill] {
                        fill: currentColor;
                    }
                }
            }

            &.indexed {
                .ant-tree-iconEle {
                    color: #f50;
                }
            }
            span {
                flex-shrink: 0;
            }
            .ant-tree-draggable-icon {
                display: none;
            }
            .ant-tree-node-content-wrapper {
                .ant-tree-title {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    position: absolute;
                    overflow: hidden;

                    top: 0;
                    left: 32px;
                    right: 0;
                    bottom: 0;
                }
            }
            .site-tree-search-value {
                color: #f50;
            }
        }
    }
`;
