import {
    useState,
    ChangeEvent,
    useCallback,
    useRef,
    useEffect,
    KeyboardEventHandler,
} from 'react';

import { IconButton } from '@mui/material';
import Lightbox from 'react-image-lightbox';

import { SVGAsset } from 'components/atoms/SVGAsset';
import { HumanAvatarProps } from 'components/molecules/HumanAvatar';
import { useFindStaff } from 'hooks/api/staff/useFindStaff';
import { Message, MessageUserTypeEnum } from 'types/api/message';

import { ChatFileUploadControl } from '../ChatFileControl';
import { ChatMessage } from '../ChatMessage';
import {
    ChatFooter,
    ChatInput,
    ChatMessagesContainer,
    ChatWindowWrapper,
} from './ChatControl.styled';

type ChatControlProps = {
    onRequestSendMessage?: (message: string, image?: string) => void;
    messages?: Message[];
    chatOwner: MessageUserTypeEnum;
    chatDisabled?: boolean;
    avatar?: HumanAvatarProps['human'];
    chatID: number;
};

export const ChatControl = ({
    onRequestSendMessage,
    messages = [],
    chatOwner,
    chatDisabled,
    avatar,
    chatID,
}: ChatControlProps): JSX.Element => {
    const [message, setMessage] = useState<string>('');

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const onChatInput = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        setMessage(e.target.value);
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.height = '36px';
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
            inputRef.current.scrollTop = inputRef.current.scrollHeight;
        }
    }, [message, inputRef]);

    const sendMessage = useCallback(
        (image?: string) => {
            if (
                (message.trim().length > 0 || image) &&
                typeof onRequestSendMessage === 'function'
            ) {
                const trimmed = message.replace(/(\r\n|\r|\n){2,}/g, '$1\n');

                onRequestSendMessage(trimmed, image);
                setMessage('');
            }
        },
        [message, onRequestSendMessage]
    );

    const onChatKeyDown: KeyboardEventHandler = (e) => {
        const onlyEnter =
            !e.ctrlKey &&
            !e.altKey &&
            !e.shiftKey &&
            !e.metaKey &&
            e.key === 'Enter';

        if (onlyEnter && !('ontouchstart' in document.documentElement)) {
            e.preventDefault();
            sendMessage();
        }
    };

    const scrollRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }, [messages]);

    const { findManager } = useFindStaff();

    const [zoomedImage, setZoomedImage] = useState<{
        img: string;
        caption?: string;
    }>();

    return (
        <>
            <ChatWindowWrapper>
                <ChatMessagesContainer>
                    {messages.map((item, i) => (
                        <ChatMessage
                            key={`chat_msg_${i}`}
                            text={item.content}
                            time={new Date(item.created_date || '')}
                            chatOwner={chatOwner}
                            from={item.type}
                            status={item.status}
                            avatar={avatar || 'manAdultV2'}
                            managerEmail={
                                item.manager !== null
                                    ? findManager(item.manager)?.email
                                    : undefined
                            }
                            image={item.image_url}
                            onImageClick={setZoomedImage}
                        />
                    ))}
                    <div ref={scrollRef} />
                </ChatMessagesContainer>
            </ChatWindowWrapper>
            {!chatDisabled ? (
                <ChatFooter>
                    <ChatFileUploadControl
                        shouldOpenDropZone={chatOwner !== 'guest'}
                        message={message}
                        onMessageChange={onChatInput}
                        chatID={chatID}
                        onSuccess={(file) => sendMessage(file)}
                    />
                    <ChatInput
                        value={message}
                        onChange={onChatInput}
                        placeholder="Сообщение"
                        onKeyDown={onChatKeyDown}
                        ref={inputRef}
                    />
                    <IconButton
                        onClick={() => sendMessage()}
                        disabled={message.trim().length === 0}
                        className="svg-color-inherit"
                        color="success"
                        sx={{ mx: -1, alignSelf: 'flex-start' }}
                    >
                        <SVGAsset src="icons.24px.send" />
                    </IconButton>
                </ChatFooter>
            ) : undefined}
            {zoomedImage?.img ? (
                <Lightbox
                    mainSrc={zoomedImage.img}
                    key={zoomedImage.img}
                    onImageLoad={() => {
                        window.dispatchEvent(new Event('resize'));
                    }}
                    onCloseRequest={() => setZoomedImage(undefined)}
                    imageCaption={zoomedImage.caption}
                    imagePadding={80}
                />
            ) : undefined}
        </>
    );
};
