import styled from 'styled-components';

import phoneLg from 'assets/images/mockups/phone-lg.png';
import phoneSm from 'assets/images/mockups/phone-sm.png';
import phone from 'assets/images/mockups/phone.png';
import tablet from 'assets/images/mockups/tablet.png';

export const HeaderWrapper = styled.div`
    position: absolute;
    z-index: 98;
    top: -1px;
    left: 0;
    right: 0;

    background-color: ${({ theme }) => theme.palette.grey.bg2};
`;

export const FooterWrapper = styled.div`
    position: absolute;
    z-index: 98;
    bottom: -1px;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.palette.grey.bg2};

    color: ${({ theme }) => theme.palette.grey.inactive};
`;

export const HeaderContent = styled.div`
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
        display: flex;
        align-items: center;
    }

    .logo67 {
        svg {
            width: 38px;
            height: auto;
        }
    }

    .chevron {
        transform: rotate(90deg);
        margin-right: 16px;
    }
`;

export const SupportBtnPlaceholder = styled.div`
    width: 56px;
    height: 56px;
    border-radius: ${({ theme }) => theme.borderRadius.large};
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.palette.green.alpha};

    position: absolute;

    right: 16px;

    bottom: 96px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PreviewWindowPhone = styled.div`
    &[data-size='phone-sm'] {
        width: 360px;
        height: 639px;
    }
    &[data-size='tablet'] {
        width: 672px;
        height: 1024px;
    }
    &[data-size='phone'] {
        width: 390px;
        height: 844px;
    }
    &[data-size='phone-lg'] {
        width: 428px;
        height: 926px;
    }

    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: white;

    scrollbar-width: none;
    ::-webkit-scrollbar {
        width: 0px;
    }

    &[data-header='true'] {
        padding-top: 64px;
    }

    &[data-footer='true'] {
        padding-bottom: 80px;
    }
    &[data-footer='false'] {
        ${SupportBtnPlaceholder} {
            bottom: 16px;
        }
    }

    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);

    &[data-hide-border='false'] {
        box-shadow: none;
        &::before {
            pointer-events: none;
            content: '';
            position: absolute;
            z-index: 99;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-size: 100% 100%;
        }

        &[data-size='phone-sm'] {
            &::before {
                margin: -100px -28px;
                background-image: url(${phoneSm});
            }
        }
        &[data-size='tablet'] {
            &::before {
                margin: -54px;
                background-image: url(${tablet});
            }
        }
        &[data-size='phone'] {
            padding-top: 40px;
            &::before {
                margin: -28px -38px;
                background-image: url(${phone});
            }
            ${HeaderWrapper} {
                min-height: 40px;
            }
            &[data-header='true'] {
                ${HeaderWrapper} {
                    padding-top: 32px;
                }
                padding-top: 96px;
            }
            &[data-footer='false'] {
                ${SupportBtnPlaceholder} {
                    bottom: 32px;
                }
            }
            &[data-footer='true'] {
                padding-bottom: 96px;
                ${FooterWrapper} {
                    padding-bottom: 16px;
                }
                ${SupportBtnPlaceholder} {
                    bottom: 112px;
                }
            }
        }
        &[data-size='phone-lg'] {
            padding-top: 40px;
            &::before {
                margin: -37px -36px;
                background-image: url(${phoneLg});
            }
            ${HeaderWrapper} {
                min-height: 40px;
            }
            &[data-header='true'] {
                ${HeaderWrapper} {
                    padding-top: 32px;
                }
                padding-top: 96px;
            }
            &[data-footer='false'] {
                ${SupportBtnPlaceholder} {
                    bottom: 32px;
                }
            }
            &[data-footer='true'] {
                padding-bottom: 96px;
                ${FooterWrapper} {
                    padding-bottom: 16px;
                }
                ${SupportBtnPlaceholder} {
                    bottom: 112px;
                }
            }
        }
    }
`;
