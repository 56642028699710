import styled from 'styled-components';

export const TopAppBarWrapper = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.palette.grey.bg2};
    color: ${({ theme }) => theme.palette.grey.inactive};
    z-index: 999;
`;

export const TopAppBarContentWrapper = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
`;

export const TopAppBarLogoWrapper = styled.div`
    width: 38px;
    height: 32px;

    svg {
        width: 100%;
        height: 100%;
    }
`;

export const TopAppBarNavButton = styled.button`
    cursor: pointer;
    text-decoration: none;
    background: none;

    color: ${({ theme }) => theme.palette.common.black};

    display: flex;
    align-items: center;

    .chevron {
        transform: rotate(90deg);
        margin-right: 16px;
    }
`;
