import styled from 'styled-components';

export const SupportPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    padding-top: 40px;
    padding-bottom: 64px;

    max-height: calc(100vh - 100px);
`;

export const SupportPageContent = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: ${({ theme }) => theme.borderRadius.large};
    overflow: hidden;
`;

export const TabPanel = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    &[hidden] {
        display: none;
    }

    margin: 0 -40px;
    padding: 0 40px;

    overflow: hidden;
`;

export const InboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px 40px;
    padding-bottom: 0;

    background-color: ${({ theme }) => theme.palette.common.white};
    border-right: 1px solid ${({ theme }) => theme.palette.grey.bg2};

    & > * {
        &:not(${TabPanel}) {
            margin-bottom: 32px;
        }
    }

    .MuiTabs-root {
        min-height: 28px;
        .MuiTab-root {
            min-height: 28px;
            padding-top: 0;
            padding-bottom: 8px;
        }
    }
    flex: 0.8;
    min-width: 380px;
    max-width: 500px;
`;

export const ChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    min-width: 320px;
`;

export const ChatWindowMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;

    padding: 32px 40px;

    h1 {
        max-width: 320px;
    }
`;
