import {
    FormControl,
    InputLabel,
    MenuItem,
    Stack,
    Select,
} from '@mui/material';

import { HumanAvatarProps } from 'components/molecules/HumanAvatar';
import { AvatarConfig } from 'types/builder/componentConfig';

type AvatarControlProps = {
    node?: AvatarConfig;
    onChange: (node?: AvatarConfig) => void;
};

type Size = {
    value: HumanAvatarProps['size'];
    title: string;
};

const sizes: Size[] = [
    {
        value: 'large',
        title: '3x',
    },
    {
        value: 'medium',
        title: '2x',
    },
    {
        value: 'small',
        title: '1x',
    },
    {
        value: 'thumbnail',
        title: 'Иконка',
    },
];

export const AvatarControl = ({
    onChange,
    node,
}: AvatarControlProps): JSX.Element => {
    const { size = 'small' } = node?.props || {};

    const onSizeChange = (value: HumanAvatarProps['size']): void => {
        onChange({
            component: 'avatar',
            props: {
                ...node?.props,
                size: value,
            },
        });
    };

    return (
        <Stack spacing={2}>
            <FormControl variant="outlined" size="small">
                <InputLabel id="base-route-label">Размер</InputLabel>
                <Select
                    labelId="base-route-label"
                    label="Размер"
                    value={size || ''}
                >
                    {sizes.map((el, i) => (
                        <MenuItem
                            key={`menu_item_${i}`}
                            value={el.value}
                            onClick={() => onSizeChange(el.value)}
                        >
                            {el.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );
};
