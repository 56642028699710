/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SharedMeta } from 'types/sharedMeta';

export interface SharedMetaState {
    value: SharedMeta;
}

const initialState: SharedMetaState = {
    value: {},
};

export const sharedMetaSlice = createSlice({
    name: 'sharedMeta',
    initialState,
    reducers: {
        setSharedMeta: (
            state,
            action: PayloadAction<SharedMetaState['value']>
        ) => {
            state.value = action.payload;
        },
    },
});

export const { setSharedMeta } = sharedMetaSlice.actions;

export const sharedMetaSliceReducer = sharedMetaSlice.reducer;
