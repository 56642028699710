import styled from 'styled-components';

export const EmployeesPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 64px;
    max-height: calc(100vh - 100px);
`;

export const EmployeesPageContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;
