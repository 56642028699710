import styled, { css } from 'styled-components';

export const MapContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    margin: 0 -16px;

    &[data-fullscreen='false'] {
        margin: 0;
    }

    user-select: none;

    position: relative;
    overflow: hidden;
`;

const controlWrapperStyles = css`
    & > div {
        border-radius: 8px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
        border: 1px solid rgba(0, 0, 0, 0.23);
        width: 48px;
        & > div {
            -webkit-padding-end: 0;
            -webkit-margin-end: 0;
        }
        button {
            width: 100%;
            height: 40px;
            align-items: center;
            justify-content: center;
            & > div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            & > svg {
                margin: auto;
            }
            &:active:not(:disabled) {
                opacity: 0.7;
            }
            &:not(:disabled) {
                &:hover,
                &:active {
                    color: ${({ theme }) => theme.palette.green.betta};
                }
            }
            &:hover {
                opacity: 1;
            }
        }
    }
`;

export const MapWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &[data-fullscreen='true'] {
        position: fixed;
        top: 64px;
        bottom: 80px;
        left: 0;
        width: 100%;
        height: calc(100% - 80px - 64px);
    }
    &[data-fullscreen='false'] {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    & > div {
        canvas {
            display: block;
        }
        & > div[class*='mapgl_'] {
            @media screen and (max-width: 450px) {
                margin: 16px !important;
            }
        }
    }

    .zoom-control {
        ${controlWrapperStyles}
    }

    .floor-control {
        &.hidden {
            display: none;
        }

        & > div {
            border-radius: 8px;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
            border: 1px solid rgba(0, 0, 0, 0.23);
            overflow: hidden;
            width: 48px;
            & > div {
                border-radius: 0 !important;
                box-shadow: none !important;
                border: none !important;
                width: 100% !important;

                max-height: 40vh;

                & > div {
                    -moz-padding-end: 0 !important;
                }
            }
            .floor-label {
                background-color: ${({ theme }) => theme.palette.common.white};
                box-shadow: none;

                color: ${({ theme }) => theme.palette.common.grey};
                ${({ theme }) => theme.typography.body.small};
                padding: 8px 0;

                text-align: center;

                border-bottom: 1px solid #e6e6e6 !important;
            }
        }

        & > div {
            ${controlWrapperStyles}

            button {
                &:disabled {
                    background: ${({ theme }) => theme.palette.glass.green};
                    color: ${({ theme }) => theme.palette.green.betta};
                }
            }
        }
    }
`;

MapWrapper.defaultProps = {
    id: 'map-container',
};
