import { MouseEventHandler, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from '@mui/material';
import Fab from '@mui/material/Fab';
import { ReactSVG } from 'react-svg';

import { componentDisplayConfig } from 'constants/components/displayConfig';
import { PageComponentConfig } from 'types/builder/componentConfig';

import { ComponentCreatorWrapper } from './ComponentCreator.styled';

type ComponentCreatorProps = {
    onComponentAdd: (component: PageComponentConfig['component']) => void;
    disabled?: boolean;
};

export const ComponentCreator = ({
    onComponentAdd,
    disabled,
}: ComponentCreatorProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleFabClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const onComponentMenuItemClick = (
        el: PageComponentConfig['component']
    ): void => {
        onComponentAdd(el);
        handleClose();
    };

    return (
        <>
            <ComponentCreatorWrapper>
                <Fab
                    color="secondary"
                    aria-label="add"
                    size="medium"
                    onClick={handleFabClick}
                    disabled={disabled}
                >
                    {open ? <CloseIcon /> : <AddIcon />}
                </Fab>
            </ComponentCreatorWrapper>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'right', vertical: -16 }}
                PaperProps={{
                    sx: {
                        maxHeight: 320,
                        minWidth: 220,
                    },
                }}
            >
                {Object.keys(componentDisplayConfig).map((key, i) => {
                    const component = key as PageComponentConfig['component'];

                    const element = componentDisplayConfig[component];

                    return (
                        <MenuItem
                            key={`component_menu_item_${i}`}
                            onClick={() => onComponentMenuItemClick(component)}
                            sx={{
                                '.MuiIconButton-root': {
                                    opacity: 0,
                                },
                                '&:hover': {
                                    '.MuiIconButton-root': {
                                        opacity: 1,
                                    },
                                },
                                height: 43,
                            }}
                        >
                            <ListItemIcon>
                                <ReactSVG src={element?.icon || ''} />
                            </ListItemIcon>
                            <ListItemText>{element?.title}</ListItemText>
                            {element?.description ? (
                                <Tooltip
                                    title={<span>{element.description}</span>}
                                    placement="right"
                                    arrow
                                >
                                    <IconButton
                                        size="small"
                                        sx={{ cursor: 'help', mr: -0.5, ml: 2 }}
                                    >
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : undefined}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};
