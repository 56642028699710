import styled from 'styled-components';

export const StyledGoBackLink = styled.button`
    ${({ theme }) => theme.typography.title.medium};
    color: ${({ theme }) => theme.palette.green.betta};

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;

    width: 100%;

    margin-top: 60px;
    margin-bottom: 32px;

    cursor: pointer;

    .chevron {
        transform: rotate(90deg) scale(0.8);
        svg {
            path {
                stroke: ${({ theme }) => theme.palette.green.betta};
                stroke-width: 3;
            }
        }
    }
`;
