import { useCallback, useEffect, useState } from 'react';

import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { BasicButton } from 'components/atoms/BasicButton';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { usePasswordChange } from 'hooks/api/auth/usePasswordChange';
import { ChangeMyUser } from 'types/api/changeMyUser';

type NewPasswordModalProps = {
    open: boolean;
    onClose?: () => void;
};

type PasswordChangeData = ChangeMyUser & { passwordRepeat: string };

export const NewPasswordModal = ({
    open,
    onClose = () => undefined,
}: NewPasswordModalProps): JSX.Element => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        watch,
    } = useForm<PasswordChangeData>({
        mode: 'onChange',
    });

    const password = watch('password');

    const [showPassword, setShowPassword] = useState(false);
    const [showRptPassword, setShowRptPassword] = useState(false);

    const [state, setState] = useState<'idle' | 'success' | 'error'>('idle');

    const resetForm = useCallback(() => {
        reset();
        setState('idle');
        setShowPassword(false);
        setShowRptPassword(false);
    }, [reset]);

    const handleClose = useCallback((): void => {
        onClose();
        resetForm();
    }, [onClose, resetForm]);

    const { changePassword, error, loading, success } = usePasswordChange();

    useEffect(() => {
        if (success) {
            setState('success');
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            setState('error');
        }
    }, [error]);

    const onSubmit = handleSubmit((data) => {
        changePassword(data.password);
    });

    const formIsDisabled = loading;
    const formHasErrors = !!Object.keys(errors).length;

    return (
        <Dialog open={open} disableEscapeKeyDown>
            <DialogContent sx={{ width: '420px', p: '32px 40px' }}>
                <Stack spacing={2} alignItems="center">
                    {state === 'idle' ? (
                        <>
                            <Typography
                                variant="title.large"
                                textAlign="center"
                            >
                                Сменить пароль
                            </Typography>
                            <Stack
                                component="form"
                                onSubmit={onSubmit}
                                sx={{ width: '100%' }}
                            >
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    label="Введите новый пароль"
                                    variant="standard"
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete="new-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                >
                                                    {showPassword ? (
                                                        <SVGAsset src="icons.24px.eyeClose" />
                                                    ) : (
                                                        <SVGAsset src="icons.24px.eyeOpen" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled={formIsDisabled}
                                    {...register('password', {
                                        required: true,
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/,
                                            message:
                                                'Должен содержать как минимум 8 символов,  одну заглавную букву, цифру и специальный символ',
                                        },
                                    })}
                                    error={!!errors.password}
                                />
                                {errors.password?.message ? (
                                    <Typography $color="red" textAlign="center">
                                        {errors.password.message}
                                    </Typography>
                                ) : undefined}
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    label="Подтвердите новый пароль"
                                    variant="standard"
                                    type={showRptPassword ? 'text' : 'password'}
                                    autoComplete="new-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() =>
                                                        setShowRptPassword(
                                                            !showRptPassword
                                                        )
                                                    }
                                                >
                                                    {showRptPassword ? (
                                                        <SVGAsset src="icons.24px.eyeClose" />
                                                    ) : (
                                                        <SVGAsset src="icons.24px.eyeOpen" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...register('passwordRepeat', {
                                        required: true,
                                        validate: (value) =>
                                            value === password ||
                                            'Пароли не совпадают',
                                    })}
                                    disabled={formIsDisabled}
                                    error={!!errors.passwordRepeat}
                                />
                                {errors.passwordRepeat?.message ? (
                                    <Typography $color="red" textAlign="center">
                                        {errors.passwordRepeat.message}
                                    </Typography>
                                ) : undefined}
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ mt: 3 }}
                                >
                                    <BasicButton
                                        variant="secondary"
                                        onClick={handleClose}
                                        type="reset"
                                        disabled={formIsDisabled}
                                    >
                                        ОТМЕНА
                                    </BasicButton>
                                    <BasicButton
                                        type="submit"
                                        disabled={formHasErrors}
                                    >
                                        {loading ? (
                                            <CircularProgress
                                                variant="indeterminate"
                                                size={16}
                                                color="inherit"
                                            />
                                        ) : (
                                            'СМЕНИТЬ'
                                        )}
                                    </BasicButton>
                                </Stack>
                            </Stack>
                        </>
                    ) : undefined}
                    {state === 'success' ? (
                        <>
                            <Box
                                sx={{
                                    svg: { width: '100px', height: '100px' },
                                }}
                            >
                                <SVGAsset src="icons.56px.checkCircle" />
                            </Box>
                            <Typography
                                variant="title.large"
                                textAlign="center"
                            >
                                Новый пароль успешно установлен
                            </Typography>
                            <BasicButton onClick={handleClose}>
                                Все понятно
                            </BasicButton>
                        </>
                    ) : undefined}
                    {state === 'error' ? (
                        <>
                            <Box
                                sx={{
                                    svg: { width: '100px', height: '100px' },
                                }}
                            >
                                <SVGAsset src="icons.56px.negative" />
                            </Box>
                            <Typography
                                variant="title.large"
                                textAlign="center"
                            >
                                Что-то пошло не так... <br />
                                Не удалось установить
                                <br />
                                новый пароль
                            </Typography>
                            <BasicButton onClick={handleClose}>
                                Все понятно
                            </BasicButton>
                        </>
                    ) : undefined}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
