import { useAppDispatch } from 'hooks/store/useAppStore';
import {
    setUsersFilter,
    archiveUser,
    deleteUser,
    addRole,
    deleteRole,
} from 'store/slices/usersSlice';

interface IUseEditUserReturn {
    setArchiveUser: (id: number) => void;
    setDeleteUser: (id: number) => void;
    addUserRole: (id: number, role: number) => void;
    deleteUserRole: (id: number, role: number) => void;
}
export const useEditUser = (): IUseEditUserReturn => {
    const dispatch = useAppDispatch();

    const setArchiveUser = (id: number): void => {
        dispatch(archiveUser({ id }));
        dispatch(setUsersFilter());
    };
    const setDeleteUser = (id: number): void => {
        dispatch(deleteUser({ id }));
        dispatch(setUsersFilter());
    };

    const addUserRole = (id: number, role: number): void => {
        dispatch(addRole({ id, role }));
        dispatch(setUsersFilter());
    };
    const deleteUserRole = (id: number, role: number): void => {
        dispatch(deleteRole({ id, role }));
        dispatch(setUsersFilter());
    };

    return {
        setArchiveUser,
        setDeleteUser,
        addUserRole,
        deleteUserRole,
    };
};
