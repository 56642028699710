import styled, { css } from 'styled-components';

const previewStyles = css`
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
`;

export const BraceletHeaderContainer = styled.div`
    padding-top: 72px;
    &[data-preview='true'] {
        padding-top: 0;
        margin: 0 -16px;
    }
`;

export const BraceletHeaderWrapper = styled.div`
    :not([data-preview='true']) {
        ${previewStyles}
    }
    &[data-color='Green'] {
        background: ${({ theme }) => theme.palette.gradients.green};
    }
    &[data-color='Yellow'] {
        background: ${({ theme }) => theme.palette.gradients.yellow};
    }
    &[data-color='Red'] {
        background: ${({ theme }) => theme.palette.gradients.red};
    }
`;

export const BraceletHeaderContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.palette.common.white};

    ${({ theme }) => theme.typography.headline.medium};

    min-height: 72px;

    .svg-asset {
        svg {
            height: 60px;
            width: auto;
        }
        &.handRed,
        &.handGreen,
        &.handYellow {
            svg {
                height: 100%;
                width: auto;
            }
        }
    }
`;
