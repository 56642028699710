import { useCallback, useEffect, useMemo, useState } from 'react';

import {
    ConstantBackoff,
    LRUBuffer,
    Websocket,
    WebsocketBuilder,
} from 'websocket-ts/lib';

import useAxios from 'hooks/api/useAxios';
import { Chat } from 'types/api/chat';
import {
    Message,
    MessageStatusEnum,
    MessageUserTypeEnum,
} from 'types/api/message';
import {
    ChatEventData,
    ChatEvents,
    ChatMessageEventData,
} from 'types/chat/eventMap';
import { socketBaseUrl } from 'utils/socketBaseUrl';

import { useIsAuthenticated } from '../auth/useIsAuthenticated';
import { useUser } from '../auth/useUser';
import { useNotifications } from '../integrations/useNotifications';

type ChatOwner = {
    owner: MessageUserTypeEnum;
};

type ManagerChatOptions = ChatOwner & {
    owner: 'manager';
    chat?: {
        id?: number;
        token?: string;
        readOnly?: boolean;
    };
};
type GuestChatOptions = ChatOwner & {
    owner: 'guest';
    chat?: never;
};

type UseChatOptions = GuestChatOptions | ManagerChatOptions;

type ConnectionStatus = 'closed' | 'connecting' | 'connected' | 'error';

type UseChatReturn = {
    status: ConnectionStatus;
    messages: Message[];
    sendMessage: (message: string, image?: string) => void;
    finishChat: () => void;
    transferChat: (chatID: number, userID: number) => void;
    manager: number | null;
    chat_id: number | null;
    guestAvatar?: string;
};

export const useChat = (options: UseChatOptions): UseChatReturn => {
    const [status, setStatus] = useState<ConnectionStatus>('closed');
    const [websocket, setWebsocket] = useState<Websocket>();

    const [messages, setMessages] = useState<Message[]>([]);

    const { owner, chat } = options;

    const socketURL = useMemo(() => {
        if (owner === 'manager') {
            return `${socketBaseUrl()}/ws/chat/${chat?.token}/`;
        }
        return `${socketBaseUrl()}/ws/chat/`;
    }, [chat?.token, owner]);

    const { authChecked } = useIsAuthenticated();

    const { status: notificationsStatus } = useNotifications({}, []);

    const [manager, setManager] = useState<number | null>(null);
    const [chat_id, setChat_id] = useState<number | null>(null);
    const [guestAvatar, setGuestAvatar] = useState<string>();

    const { user } = useUser();

    useEffect(() => {
        setStatus('closed');
    }, [chat?.id]);

    const [res, { rerun }] = useAxios<Message[] | Chat>(
        {
            url:
                owner === 'manager'
                    ? `/api/chat/${chat?.id}/history/`
                    : '/api/chat/history/',
            method: 'GET',
            skipRequest: () =>
                chat?.readOnly
                    ? false
                    : !websocket ||
                      websocket.underlyingWebsocket?.readyState !== 1,
        },
        [chat?.id, owner, websocket]
    );

    useEffect(() => {
        const error = res.type === 'error' ? res.data : undefined;

        const timeout = setTimeout(() => {
            if (error?.response && error.response?.status >= 500) {
                rerun();
            }
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
    }, [res, rerun]);

    const loadChatHistory = (
        msg: Chat | Message[],
        keepStatus?: boolean,
        newStatus?: MessageStatusEnum
    ): void => {
        const assignMessageStatus = (history: Message[]): void => {
            setMessages(
                history.map((item) =>
                    keepStatus
                        ? item
                        : {
                              ...item,
                              status: newStatus,
                          }
                )
            );
        };

        if (Array.isArray(msg)) {
            assignMessageStatus(msg);
        } else {
            setManager(msg.manager !== undefined ? msg.manager : null);
            assignMessageStatus(msg.messages);
        }
    };

    useEffect(() => {
        if (res.type === 'success') {
            setStatus('connected');
            if (owner === 'manager') {
                loadChatHistory(res.data, true);
            } else {
                loadChatHistory(res.data, false, 'sent');
            }
        }
        if (res.type === 'error') {
            setStatus('error');
        }
    }, [res, owner]);

    const sendMessage = useCallback(
        (message: string, image?: string) => {
            if (websocket) {
                const payload = {
                    event: 'send.message',
                    payload: { content: message, image_url: image },
                };

                websocket.send(JSON.stringify(payload));

                setMessages((chatHistory) => [
                    ...chatHistory,
                    {
                        content: message,
                        created_date: new Date().toISOString(),
                        status: undefined,
                        type: owner,
                        manager: owner === 'manager' && user ? user.id : null,
                        image_url: image,
                    },
                ]);
            }
        },
        [owner, user, websocket]
    );

    const finishChat = useCallback(() => {
        if (websocket) {
            const payload = {
                event: 'chat.finish',
                payload: {},
            };

            websocket.send(JSON.stringify(payload));
        }
    }, [websocket]);

    const transferChat = useCallback(
        (chatID: number, userID: number) => {
            if (websocket) {
                const payload = {
                    event: 'manager.transfer.chat',
                    payload: {
                        to_id: userID,
                        chat_id: chatID,
                    },
                };

                websocket.send(JSON.stringify(payload));
            }
        },
        [websocket]
    );

    const setAllStatus = (stat: MessageStatusEnum): void => {
        const statusRank = (s?: MessageStatusEnum): number => {
            switch (s) {
                case 'sent':
                    return 1;
                case 'received':
                    return 2;
                case 'read':
                    return 3;
                default:
                    return 0;
            }
        };

        setMessages((msgs) =>
            msgs.map((el) => ({
                ...el,
                status:
                    statusRank(stat) > statusRank(el.status) ? stat : el.status,
            }))
        );
    };

    const eventSerializer = useCallback(
        (e: MessageEvent): void => {
            const showNewMessage = (
                payload: ChatMessageEventData['payload']
            ): void => {
                setMessages((history) => [
                    ...history,
                    {
                        content: payload.content,
                        created_date: new Date().toISOString(),
                        type:
                            (payload.manager !== undefined
                                ? 'manager'
                                : undefined) ||
                            (owner === 'manager' ? 'guest' : 'manager'),
                        manager:
                            payload.manager !== undefined
                                ? payload.manager
                                : null,
                        image_url:
                            payload.image_url !== null
                                ? payload.image_url
                                : undefined,
                    },
                ]);
            };

            if (e.data && /^\s*(\{|\[)/.test(e.data)) {
                let data: ChatEventData | undefined;
                try {
                    data = JSON.parse(e.data);
                } catch (err) {
                    return;
                }

                if (data) {
                    switch (data.event) {
                        case ChatEvents.GuestInfo:
                            setGuestAvatar(
                                data?.payload?.data?.avatar as string
                            );
                            break;
                        case ChatEvents.ChatInfo:
                            setChat_id(data?.payload?.id);
                            break;
                        case ChatEvents.ShowMessage:
                            showNewMessage(data.payload);
                            break;

                        case ChatEvents.ChatHistory:
                            if (Array.isArray(data.payload)) {
                                if (owner === 'manager') {
                                    loadChatHistory(data.payload, true);
                                } else {
                                    loadChatHistory(
                                        data.payload,
                                        false,
                                        'sent'
                                    );
                                }
                            }
                            break;
                        case ChatEvents.MessageReceived:
                            setAllStatus('received');
                            break;
                        case ChatEvents.MessageRead:
                            setAllStatus('read');
                            break;
                        case ChatEvents.MessageSent:
                            setAllStatus('sent');
                            break;
                        default:
                            break;
                    }
                }
            }
        },
        [owner]
    );

    useEffect(() => {
        if (
            authChecked &&
            notificationsStatus === 'connected' &&
            !chat?.readOnly
        ) {
            setStatus('connecting');

            const socket = new WebsocketBuilder(socketURL)
                .onMessage((i, e) => {
                    eventSerializer(e);
                })
                .onOpen((i) => {
                    setWebsocket(i);
                })
                .onClose(() => {
                    setStatus('closed');
                    setWebsocket(undefined);
                })
                .onError(() => {
                    setStatus('error');
                    setWebsocket(undefined);
                })
                .withBuffer(new LRUBuffer(1000))
                .withBackoff(new ConstantBackoff(3000))
                .build();

            return () => {
                socket.close();
            };
        }

        return undefined;
    }, [
        authChecked,
        socketURL,
        notificationsStatus,
        chat?.readOnly,
        eventSerializer,
    ]);

    return {
        status,
        messages,
        sendMessage,
        finishChat,
        transferChat,
        chat_id,
        manager,
        guestAvatar,
    };
};
