import { MouseEventHandler, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import QuizIcon from '@mui/icons-material/Quiz';
import {
    CircularProgress,
    Fab,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@mui/material';
import { v4 as uuid } from 'uuid';

import { useAddNewPage } from 'hooks/api/builder/pages/useAddNewPage';
import { PageRetrieve } from 'types/api/pageRetrieve';

import { ComponentCreatorWrapper } from '../../ComponentCreator/ComponentCreator.styled';

type NewPageControlProps = {
    disabled?: boolean;
    onSuccess: (data: PageRetrieve) => void;
};

const basePageTemplate: Partial<PageRetrieve> = {
    description: 'Описание',
    route_uuid: '1d57dd67-54d1-4da1-a648-6635d40d2ba8',
    path: '',
    title: 'Новая страница',
    show_top_app_bar: true,
    show_nav_bar: true,
    show_bottom_navLink: true,
    show_chat_button: true,
    is_published: false,
};

export const NewPageControl = ({
    disabled,
    onSuccess,
}: NewPageControlProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const { addNewPage, loading } = useAddNewPage({
        onSuccess,
    });

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const onPageAdd = (): void => {
        handleClose();
        addNewPage({
            ...basePageTemplate,
            uuid: uuid(),
            type: 'PAGE',
            config: [],
        });
    };

    const onQuizAdd = (): void => {
        handleClose();
        addNewPage({
            ...basePageTemplate,
            type: 'QUIZ',
            uuid: uuid(),
            config: {
                questionTitle: 'Вопрос',
                questions: [
                    {
                        title: 'Ответ',
                    },
                ],
            },
        });
    };

    const handleBtnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        setAnchorEl(e.currentTarget);
    };

    return (
        <>
            <ComponentCreatorWrapper>
                <Fab
                    color="secondary"
                    aria-label="add"
                    size="medium"
                    onClick={handleBtnClick}
                    disabled={disabled || loading}
                >
                    {open && !loading ? <CloseIcon /> : undefined}
                    {!open && !loading ? <AddIcon /> : undefined}
                    {loading ? (
                        <CircularProgress
                            variant="indeterminate"
                            color="inherit"
                            size={20}
                        />
                    ) : undefined}
                </Fab>
            </ComponentCreatorWrapper>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'right', vertical: -16 }}
            >
                <MenuItem onClick={() => onPageAdd()}>
                    <ListItemIcon>
                        <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText>Добавить страницу</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => onQuizAdd()}>
                    <ListItemIcon>
                        <QuizIcon />
                    </ListItemIcon>
                    <ListItemText>Добавить страницу-опросник</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};
