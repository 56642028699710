import { useCallback, useEffect } from 'react';

import { UseUndoRedoReturn } from 'hooks/useUndoRedo';
import { SelectedLocation } from 'types/map/location';

import { usePointsList } from './usePointsList';
import { usePointsUndoRedo } from './usePointsUndoRedo';
import { useSelectedPoint } from './useSelectedPoint';
import { useUpdatePoint } from './useUpdatePoint';

type UsePointControlReturn = {
    saveChanges: (() => void) & {
        loading: boolean;
        success: boolean;
        error: boolean;
    };
    undoRedoState: UseUndoRedoReturn['2'];
    controls: {
        resetChanges: () => void;
        onNewPointCreated: (id: number) => void;
        onPointDeleted: () => void;
        onPointSettingsChanged: (conf?: SelectedLocation) => void;
    };
    config?: SelectedLocation;
};

export const usePointControl = (): UsePointControlReturn => {
    const { selectedPointData, setSelectedPoint } = useSelectedPoint();

    const { reloadLocations } = usePointsList();

    const [config, setConfig, actions] = usePointsUndoRedo();

    const { error, loading, success, updatePoint } = useUpdatePoint();

    const onPointSettingsChanged = useCallback(
        (settings?: SelectedLocation): void => {
            if (config) {
                setConfig({
                    ...config,
                    ...settings,
                });
            }
        },
        [config, setConfig]
    );

    const onNewPointCreated = (id: number): void => {
        setSelectedPoint(id);
        reloadLocations();
    };

    const onPointDeleted = (): void => {
        setSelectedPoint(undefined);
        reloadLocations();
    };

    useEffect(() => {
        if (success) {
            reloadLocations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success]);

    return {
        config,
        saveChanges: Object.assign(
            () => {
                if (config && config.floor?.id && config.building?.id) {
                    updatePoint({
                        ...config,
                        floor: config.floor?.id,
                        building: config.building?.id,
                    });
                }
            },
            {
                loading,
                success,
                error,
            }
        ),
        undoRedoState: actions,
        controls: {
            resetChanges: () => actions.resetState(selectedPointData),
            onNewPointCreated,
            onPointDeleted,
            onPointSettingsChanged,
        },
    };
};
