import {
    ChangeEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {
    Box,
    FormControl,
    IconButton,
    InputAdornment,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Link as MuiLink,
} from '@mui/material';
import QRCodeStyling from 'qr-code-styling';
import { useForm } from 'react-hook-form';

import logo from 'assets/logos/logo-67.svg';
import { Spacer } from 'components/atoms/Grid';
import { Typography } from 'components/atoms/Typography';
import { useBuilderToolbars } from 'hooks/api/builder/pages/usePageBuilder';
import { useMeta } from 'hooks/api/useMeta';
import { useAppDispatch } from 'hooks/store/useAppStore';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { setError } from 'store/slices/pageBuilderSlice';
import { PageMapConfig } from 'types/builder/pageConfig';
import { getSlugName } from 'utils/getSlugName';

import { InternalPageSelector } from '../InternalPageSelector';
import { PageDeleteModal } from '../PageDeleteModal';

export type PageSettings = Omit<
    PageMapConfig,
    'type' | 'pageConfig' | 'quizConfig' | 'definedElement'
>;

export type PageSettingsControlProps = {
    onSettingsChange: (config: PageSettings) => void;
    onDeleteSuccess: () => void;
    config: PageMapConfig;
};

export const PageSettingsControl = ({
    config,
    onDeleteSuccess,
    onSettingsChange,
}: PageSettingsControlProps): JSX.Element => {
    const settings: PageSettings = config;

    const {
        register,
        formState: { errors },
        trigger,
        resetField,
    } = useForm<Pick<PageMapConfig, 'pageTitle' | 'description' | 'path'>>({
        mode: 'all',
        defaultValues: {
            pageTitle: config.pageTitle,
            description: config.description,
            path: config.path,
        },
    });

    const [pageSelectError, setPageSelectError] = useState(false);

    const hasErrors = !!Object.keys(errors).length || pageSelectError;

    const generatePagePath = (): void => {
        const generated = `${getSlugName(settings.pageTitle || '')}`;
        if (settings.path !== generated) {
            onSettingsChange({
                ...settings,
                path: generated,
            });
            resetField('path', { defaultValue: generated, keepDirty: true });
        }
    };

    const onBasePathChange = (v: string): void => {
        onSettingsChange({
            ...settings,
            baseRoute: v,
        });
    };

    const onSwitch: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { checked, name } = e.target;
        onSettingsChange({
            ...settings,
            [name]: checked,
        });
    };

    const onTextInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.target;
        onSettingsChange({
            ...settings,
            [name]: value,
        });
    };

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setError({ name: 'pageSettings', error: hasErrors }));
    }, [hasErrors, dispatch]);

    const {
        uuid,
        baseRoute,
        description,
        pageTitle,
        path,
        showBottomNavLink = true,
        showChatButton = true,
        showNavBar = true,
        showTopAppBar = true,
        isPublished = false,
    } = settings || {};

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { meta } = useMeta('pageList');

    const getParentPath = useCallback(
        (key?: string) => {
            if (meta && key) {
                const parent = meta.find((el) => el.uuid === key);

                return parent?.href;
            }
            return undefined;
        },
        [meta]
    );

    const filterPath = (val: string): string => {
        return val
            .split('/')
            .filter((e) => !!e)
            .join('/');
    };

    const validatePath = (value?: string): boolean | string => {
        const href = filterPath(`${getParentPath(baseRoute)}${value}/`);
        const routes = meta?.filter((el) => el.id !== config.id);
        const matches = routes
            ?.filter((el) => filterPath(el.href) === href)
            .filter((el) => !el.is_fundamental || el.is_static);

        if (matches?.length) {
            return 'Страница с таким путём уже существует';
        }
        return true;
    };

    useEffect(() => {
        trigger();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageTitle, description, path, baseRoute]);

    const { setActiveToolBar, toolbars } = useBuilderToolbars();

    const locationUrl = useMemo(
        () => `${window.location.origin}/?page=${uuid}`,
        [uuid]
    );

    const onQrGenerate = useCallback(() => {
        const qrCode = new QRCodeStyling({
            width: 300,
            height: 300,
            type: 'svg',
            data: locationUrl,
            image: logo,
            dotsOptions: {
                type: 'rounded',
            },
            imageOptions: {
                crossOrigin: 'anonymous',
                margin: 10,
                imageSize: 0.6,
            },
        });

        qrCode.download({
            name: `QR-код для «${pageTitle}»`,
            extension: 'svg',
        });
    }, [pageTitle, locationUrl]);

    const [copied, copy] = useCopyToClipboard();

    return (
        <Accordion
            sx={{
                borderBottom: 1,
                borderColor: 'divider',
                margin: '0 !important',
            }}
            expanded={toolbars.right === 'pageSettings'}
            onChange={(e, v) => setActiveToolBar('pageSettings', v)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    px: '28px',
                    fontSize: toolbars.right === 'pageSettings' ? 22 : 16,
                    fontWeight: 500,
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ flex: 1 }}
                >
                    {hasErrors ? (
                        <ReportProblemOutlinedIcon color="error" />
                    ) : undefined}
                    <span>Настройки страницы</span>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, position: 'relative' }}>
                {settings ? (
                    <>
                        <Box
                            sx={{
                                p: '28px',
                                pt: 1,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                overflowY: 'auto',
                            }}
                        >
                            <Stack spacing={2}>
                                <Typography variant="title.small">
                                    Основные
                                </Typography>
                                <FormControl variant="outlined">
                                    <TextField
                                        label="Заголовок страницы"
                                        size="small"
                                        fullWidth
                                        {...register('pageTitle', {
                                            required: {
                                                value: true,
                                                message:
                                                    'Введите заголовок страницы',
                                            },
                                            onChange: onTextInputChange,
                                        })}
                                        error={!!errors.pageTitle}
                                        value={pageTitle || ''}
                                    />
                                    {errors.pageTitle?.message ? (
                                        <>
                                            <Spacer size="1x" />
                                            <Typography
                                                $color="red"
                                                variant="body.small"
                                            >
                                                {errors.pageTitle?.message}
                                            </Typography>
                                        </>
                                    ) : undefined}
                                </FormControl>
                                <FormControl variant="outlined">
                                    <TextField
                                        label="Описание страницы"
                                        minRows={3}
                                        multiline
                                        size="small"
                                        fullWidth
                                        {...register('description', {
                                            required: {
                                                value: true,
                                                message:
                                                    'Введите описание страницы',
                                            },
                                            onChange: onTextInputChange,
                                        })}
                                        error={!!errors.description}
                                        value={description || ''}
                                    />
                                    {errors.description?.message ? (
                                        <>
                                            <Spacer size="1x" />
                                            <Typography
                                                $color="red"
                                                variant="body.small"
                                            >
                                                {errors.description?.message}
                                            </Typography>
                                        </>
                                    ) : undefined}
                                </FormControl>
                                <InternalPageSelector
                                    value={baseRoute || ''}
                                    onChange={onBasePathChange}
                                    title="Выберите базовую страницу"
                                    filterID={config.id}
                                    onError={setPageSelectError}
                                />
                                <FormControl variant="outlined">
                                    <TextField
                                        label="Путь к странице"
                                        size="small"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title="Сгенерировать путь"
                                                        placement="bottom-end"
                                                        arrow
                                                    >
                                                        <IconButton
                                                            edge="end"
                                                            onClick={() =>
                                                                generatePagePath()
                                                            }
                                                            disabled={
                                                                !pageTitle
                                                            }
                                                        >
                                                            <AutoFixHighIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        {...register('path', {
                                            onChange: onTextInputChange,
                                            pattern: {
                                                value: /^[a-zA-Z0-9\-_]+$/,
                                                message:
                                                    'Неверный формат пути страницы',
                                            },
                                            validate: validatePath,
                                        })}
                                        error={!!errors.path}
                                        value={path || ''}
                                    />
                                    {errors.path?.message ? (
                                        <>
                                            <Spacer size="1x" />
                                            <Typography
                                                $color="red"
                                                variant="body.small"
                                            >
                                                {errors.path?.message}
                                            </Typography>
                                        </>
                                    ) : undefined}
                                </FormControl>
                                <Typography variant="title.small">
                                    Отображение
                                </Typography>
                                <Stack spacing={0.5}>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography>Кнопка чата</Typography>
                                        <Switch
                                            name="showChatButton"
                                            checked={showChatButton}
                                            onChange={onSwitch}
                                        />
                                    </Stack>
                                    <Divider />
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography>
                                            Кнопка «вернуться назад»
                                        </Typography>
                                        <Switch
                                            name="showBottomNavLink"
                                            checked={showBottomNavLink}
                                            onChange={onSwitch}
                                        />
                                    </Stack>
                                    <Divider />
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography>
                                            Панель навигации
                                        </Typography>
                                        <Switch
                                            name="showNavBar"
                                            checked={showNavBar}
                                            onChange={onSwitch}
                                        />
                                    </Stack>
                                    <Divider />
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography>
                                            Панель заголовка
                                        </Typography>
                                        <Switch
                                            name="showTopAppBar"
                                            checked={showTopAppBar}
                                            onChange={onSwitch}
                                        />
                                    </Stack>
                                </Stack>
                                <Typography variant="title.small">
                                    Публикация и удаление
                                </Typography>
                                <Stack spacing={0.5}>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography>Опубликована</Typography>
                                        <Switch
                                            name="isPublished"
                                            checked={isPublished}
                                            onChange={onSwitch}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography>Удалить страницу</Typography>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        onClick={() =>
                                            setDeleteModalIsOpen(true)
                                        }
                                        disabled={isPublished}
                                    >
                                        Удалить
                                    </Button>
                                </Stack>
                                <Typography variant="title.small">
                                    Поделиться страницей
                                </Typography>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <MuiLink
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={locationUrl}
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {locationUrl}
                                    </MuiLink>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{ flexShrink: 0 }}
                                        onClick={() => copy(locationUrl)}
                                        disabled={!!copied}
                                    >
                                        {!copied ? 'Копировать' : 'Скопировано'}
                                    </Button>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography>Получить QR-код</Typography>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => onQrGenerate()}
                                    >
                                        Скачать SVG
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                        <PageDeleteModal
                            open={deleteModalIsOpen}
                            onClose={() => setDeleteModalIsOpen(false)}
                            onSuccess={onDeleteSuccess}
                            pageTitle={pageTitle}
                            id={settings.id}
                            key={`delete_modal_${deleteModalIsOpen}`}
                        />
                    </>
                ) : undefined}
            </AccordionDetails>
        </Accordion>
    );
};
