import { useState, useEffect } from 'react';

import { Dialog, DialogContent, Stack } from '@mui/material';
import { Transition } from 'history';

import { BasicButton } from 'components/atoms/BasicButton';
import { Typography } from 'components/atoms/Typography';
import { useBlocker } from 'hooks/utils/useBlocker';

type EditorBlockerModalProps = {
    when?: boolean;
};

export const EditorBlockerModal = ({
    when,
}: EditorBlockerModalProps): JSX.Element => {
    const [block, setBlock] = useState(when);
    const [visible, setVisible] = useState(false);
    const [transition, setTransition] = useState<Transition | undefined>();

    const show = (): void => setVisible(true);
    const hide = (): void => setVisible(false);

    const skip = (): void => {
        setBlock(false);
        setTimeout(() => {
            transition?.retry();
            hide();
        }, 0);
    };

    useEffect(() => {
        setBlock(when);
    }, [when]);

    useBlocker((tx) => {
        setTransition(tx);
        show();
    }, block);

    return (
        <Dialog
            open={visible}
            onClose={(e, reason) =>
                reason !== 'backdropClick' ? hide() : undefined
            }
            disableEscapeKeyDown
        >
            <DialogContent sx={{ maxWidth: '500px', padding: '32px 40px' }}>
                <Stack spacing={3}>
                    <Typography variant="title.large" textAlign="center">
                        Подтвердите действие
                    </Typography>
                    <Typography variant="body.medium" textAlign="center">
                        Вы покидаете страницу контент-менеджера. При переходе
                        все несохраненные данные будут потеряны.
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <BasicButton variant="secondary" onClick={() => hide()}>
                            Не переходить
                        </BasicButton>
                        <BasicButton onClick={() => skip()} $bgColor="red">
                            Перейти
                        </BasicButton>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
