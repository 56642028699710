import { Tabs } from '@mui/material';
import styled from 'styled-components';

export const HeaderWrapper = styled.header`
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 28px;

    background-color: ${({ theme }) => theme.palette.common.white};
`;

export const RoleTabs = styled(Tabs)`
    .MuiTab-root {
        max-width: 300px;
        min-width: 300px;
        width: 100%;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            height: 4px;
            bottom: 0;
            left: 0;
            right: 0;

            background-color: ${({ theme }) => theme.palette.grey.bg1};
        }
        &:first-of-type {
            &::before {
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
            }
        }
        &:last-of-type {
            &::before {
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
            }
        }
    }
    .MuiTabs-indicator {
        height: 4px;
        border-radius: 2px;
    }
`;

export const HeaderTabsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    .logo67 {
        svg {
            width: 56px;
            height: 48px;
            margin-right: 24px;
        }
    }
`;

export const TabSegment = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
        margin-right: 8px;
    }
`;
export const LogoutBtnSegment = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-wrapper {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
    }
`;
