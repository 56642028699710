import { Stack, CircularProgress } from '@mui/material';

import { BasicButton } from 'components/atoms/BasicButton';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useNotifications } from 'hooks/api/integrations/useNotifications';
import { useTgAttach } from 'hooks/api/integrations/useTgAttach';

export const TgAuthScreen = (): JSX.Element => {
    const { getTgAttachLink, loading: tgLoading } = useTgAttach();

    useNotifications(
        {
            onTgAttach() {
                window.location.reload();
            },
        },
        []
    );

    return (
        <Stack
            spacing={3}
            alignItems="center"
            justifyContent="center"
            sx={{ flex: 1, maxWidth: 280, mx: 'auto' }}
        >
            <Stack direction="row" spacing={1} alignItems="center">
                <SVGAsset src="logos.logo67" />
                <SVGAsset src="logos.tg" />
            </Stack>
            <Typography variant="title.large" textAlign="center">
                Авторизоваться через Telegram
            </Typography>
            <Typography variant="body.medium" textAlign="center">
                Для доступа к саппорт чату необходимо привязать Telegram аккаунт
            </Typography>
            <BasicButton
                variant="primary"
                type="button"
                onClick={() => getTgAttachLink()}
                disabled={tgLoading}
            >
                {tgLoading ? (
                    <CircularProgress
                        color="inherit"
                        variant="indeterminate"
                        size={20}
                    />
                ) : (
                    'Перейти в Telegram'
                )}
            </BasicButton>
        </Stack>
    );
};
