import styled from 'styled-components';

export const AnnotationWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;
export const AnnotationContent = styled.div`
    display: flex;
    width: 496px;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 250px 0px;
`;

export const Image = styled.div`
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin: 0px 24px 0px 0px;

    .archive svg {
        width: 80px;
        height: 80px;
    }
`;
export const Data = styled.div`
    display: flex;
    flex-direction: column;
    width: 392px;
`;
