/* eslint-disable react/no-unstable-nested-components */
import {
    ChangeEventHandler,
    memo,
    useCallback,
    useEffect,
    useState,
} from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Grid,
    InputAdornment,
    Stack,
    SvgIcon,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import { FixedSizeGrid } from 'react-window';

import { SVGAsset, SVGAssetProps } from 'components/atoms/SVGAsset';

import { info67icons, muiIconsList } from './iconList';

type IconSelectorProps = {
    icon?: string;
    showPreview?: boolean;
    onSelect?: (icon: string | SVGAssetProps['src']) => void;
};

const MuiIcons = memo(
    ({
        icons,
        onIconClick,
    }: {
        icons: typeof muiIconsList;
        onIconClick: (icon: string) => void;
    }): JSX.Element => {
        return (
            <FixedSizeGrid
                columnCount={10}
                columnWidth={50}
                rowCount={Math.ceil(icons.length / 7)}
                rowHeight={50}
                height={438}
                width={520}
                overscanRowCount={5}
            >
                {({ columnIndex, rowIndex, style }) => {
                    const item = icons[rowIndex * 7 + columnIndex];

                    return (
                        <Stack
                            style={style}
                            alignItems="center"
                            justifyContent="center"
                            key={`${rowIndex}_${columnIndex}`}
                        >
                            {item ? (
                                <Button
                                    sx={{ minWidth: 0 }}
                                    color="inherit"
                                    onClick={() => onIconClick(item.importName)}
                                >
                                    <SvgIcon
                                        component={item.Component}
                                        fontSize="large"
                                    />
                                </Button>
                            ) : undefined}
                        </Stack>
                    );
                }}
            </FixedSizeGrid>
        );
    }
);
const Info67Icons = memo(
    ({
        icons,
        onIconClick,
    }: {
        icons: typeof info67icons;
        onIconClick: (icon: SVGAssetProps['src']) => void;
    }): JSX.Element => {
        return (
            <Stack spacing={2}>
                <Grid
                    container
                    columns={7}
                    rowSpacing={2}
                    justifyContent="center"
                >
                    {icons.map((icon) => (
                        <Grid item key={icon}>
                            <Button
                                sx={{ minWidth: 0 }}
                                color="inherit"
                                onClick={() =>
                                    onIconClick(icon as SVGAssetProps['src'])
                                }
                            >
                                <SVGAsset
                                    src={icon as SVGAssetProps['src']}
                                    fontSize="large"
                                />
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        );
    }
);

export const IconSelector = ({
    icon: selectedIcon,
    showPreview,
    onSelect = () => undefined,
}: IconSelectorProps): JSX.Element => {
    const [searchValue, setSearchValue] = useState('');

    const [open, setOpen] = useState(false);

    const onSearchValueChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        setSearchValue(value);
    };

    const [filteredElements, setFilteredElements] = useState(muiIconsList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncededSetFilteredElements = useCallback(
        debounce(setFilteredElements, 500, {
            leading: true,
        }),
        []
    );

    useEffect(() => {
        debouncededSetFilteredElements(
            muiIconsList.filter(({ searchable }) =>
                searchable.includes(searchValue.toLowerCase())
            )
        );
    }, [debouncededSetFilteredElements, searchValue]);

    const onIconSelect = (icon: string | SVGAssetProps['src']): void => {
        onSelect(icon);
        setOpen(false);
    };

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        setSearchValue('');
        setActiveTab(0);
    }, [open]);

    return (
        <>
            {showPreview ? (
                <Stack
                    sx={{
                        border: 1,
                        borderColor: 'divider',
                        width: '100%',
                        height: '200px',
                        borderRadius: '16px',
                        backgroundImage:
                            'radial-gradient(rgba(0, 0, 0, 0.12) 1px, transparent 0)',
                        backgroundSize: '20px 20px',
                        backgroundPosition: '-19px -19px',
                    }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <SVGAsset src={selectedIcon || ''} />
                </Stack>
            ) : undefined}
            <Button
                variant="outlined"
                onClick={() => setOpen(true)}
                endIcon={
                    selectedIcon && !showPreview ? (
                        <SVGAsset src={selectedIcon} />
                    ) : undefined
                }
                sx={{
                    'svg, img': {
                        width: '22px',
                        height: '22px',
                    },
                    justifyContent:
                        selectedIcon && !showPreview
                            ? 'space-between'
                            : undefined,
                    alignItems: 'center',
                }}
            >
                <span>
                    {selectedIcon ? 'Поменять иконку' : 'Выбрать иконку'}
                </span>
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent
                    sx={{ minWidth: 600, minHeight: 600, p: '32px 40px' }}
                >
                    <Box sx={{ pb: '20px' }}>
                        <Tabs
                            variant="fullWidth"
                            value={activeTab}
                            onChange={(e, tab) => setActiveTab(tab)}
                        >
                            <Tab label="Иконки инфопомощника" />
                            <Tab label="Другие иконки" />
                        </Tabs>
                    </Box>
                    <Box hidden={activeTab !== 1}>
                        <TextField
                            value={searchValue}
                            onChange={onSearchValueChange}
                            label="Поиск иконок"
                            placeholder="Введите запрос..."
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box sx={{ mt: 1 }}>
                            <Typography>
                                {filteredElements.length} результатов
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <MuiIcons
                                icons={filteredElements}
                                onIconClick={onIconSelect}
                            />
                        </Box>
                    </Box>
                    <Box hidden={activeTab !== 0}>
                        <Box
                            sx={{
                                maxHeight: 520,
                                overflowY: 'auto',
                            }}
                        >
                            <Info67Icons
                                icons={info67icons}
                                onIconClick={onIconSelect}
                            />
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
