import { useEffect, useMemo } from 'react';

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import {
    Autocomplete,
    CircularProgress,
    FormControl,
    Stack,
    TextField,
} from '@mui/material';
import { v4 as uuid } from 'uuid';

import { Typography } from 'components/atoms/Typography';
import { NAVBAR_SEGMENTS } from 'components/templates/layouts/PageLayout/NavigationBar';
import { useMeta } from 'hooks/api/useMeta';
import { PageList } from 'types/api/pageList';

type InternalPageSelectorProps = {
    value: string;
    onChange: (value: string) => void;
    title?: string;
    filterID?: number;
    onError?: (error: boolean) => void;
};

export const InternalPageSelector = ({
    onChange,
    value,
    title,
    filterID,
    onError = () => undefined,
}: InternalPageSelectorProps): JSX.Element => {
    const { meta } = useMeta('pageList');

    const data = useMemo(
        () =>
            meta?.filter((el) =>
                filterID !== undefined ? el.id !== filterID : true
            ),
        [filterID, meta]
    );

    const activeOption = useMemo<Partial<PageList>>(() => {
        return (
            meta?.find((el) => el.uuid === value) || {
                title: '',
                uuid: 'empty-option',
            }
        );
    }, [meta, value]);

    useEffect(() => {
        onError(activeOption.uuid === 'empty-option' && !!value);
    }, [activeOption.uuid, onError, value]);

    return (
        <FormControl variant="outlined" size="small" disabled={!data}>
            {!data ? (
                <TextField
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <CircularProgress
                                variant="indeterminate"
                                color="inherit"
                                size={16}
                            />
                        ),
                    }}
                    label={title || 'Выберите страницу'}
                    disabled
                />
            ) : (
                <Autocomplete
                    options={data.sort((a, b) => {
                        const nameA = a.title.toLowerCase();
                        const nameB = b.title.toLowerCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                    })}
                    autoHighlight
                    getOptionLabel={(option) => option.title || ''}
                    onChange={(e, v) => onChange(v?.uuid || '')}
                    isOptionEqualToValue={(o, v) =>
                        v.uuid === 'empty-option' || o.uuid === v.uuid
                    }
                    noOptionsText="Страницы отсутствуют"
                    value={activeOption}
                    disableClearable
                    renderOption={(props, option) => {
                        const item = NAVBAR_SEGMENTS.find((nav) =>
                            nav.href.includes(option?.href?.split('/')[1] || '')
                        );

                        const parentLabel = data?.find(
                            (el) => el.uuid === option.route_uuid
                        )?.title;

                        return (
                            <Stack
                                component="li"
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                {...props}
                                key={uuid()}
                            >
                                <div
                                    style={{
                                        transform: 'scale(0.8)',
                                    }}
                                >
                                    {item?.icon}
                                </div>
                                <Stack>
                                    <Typography variant="title.small">
                                        {option?.title}
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        spacing={0.5}
                                        sx={{ fontSize: '14px' }}
                                    >
                                        {parentLabel ? (
                                            <SubdirectoryArrowLeftIcon
                                                sx={{ rotate: '90deg' }}
                                                fontSize="inherit"
                                            />
                                        ) : (
                                            <SettingsApplicationsIcon fontSize="inherit" />
                                        )}
                                        <Typography
                                            variant="body.small"
                                            style={{ wordBreak: 'break-all' }}
                                        >
                                            {parentLabel ||
                                                'Системная страница'}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={title || 'Выберите страницу'}
                            size="small"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            )}
            {activeOption.uuid === 'empty-option' && !!value ? (
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ color: 'error.main', mt: 0.5 }}
                >
                    <ReportProblemOutlinedIcon fontSize="small" />
                    <Typography
                        variant="body.small"
                        style={{ color: 'inherit' }}
                    >
                        Выбранная страница была удалена
                    </Typography>
                </Stack>
            ) : undefined}
            {activeOption.uuid !== 'empty-option' &&
            !activeOption.is_published ? (
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ color: 'warning.main', mt: 0.5 }}
                >
                    <ReportProblemOutlinedIcon fontSize="small" />
                    <Typography
                        variant="body.small"
                        style={{ color: 'inherit' }}
                    >
                        Страница не опубликована
                    </Typography>
                </Stack>
            ) : undefined}
        </FormControl>
    );
};
