import { ChangeEventHandler } from 'react';

import { FormControl, Stack, Switch, TextField } from '@mui/material';

import { Typography } from 'components/atoms/Typography';
import { CheckboxConfig } from 'types/builder/componentConfig';

type CheckboxControlProps = {
    node?: CheckboxConfig;
    onChange: (node?: CheckboxConfig) => void;
};

export const CheckboxControl = ({
    onChange,
    node,
}: CheckboxControlProps): JSX.Element => {
    const { children, defaultChecked = false } = node?.props || {};

    const onTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        onChange({
            component: 'checkbox',
            props: {
                ...node?.props,
                children: value,
            },
        });
    };
    const onDefaultCheckedChange = (checked: boolean): void => {
        onChange({
            component: 'checkbox',
            props: {
                ...node?.props,
                defaultChecked: checked,
            },
        });
    };

    return (
        <Stack spacing={2}>
            <FormControl variant="outlined">
                <TextField
                    label="Текст"
                    minRows={1}
                    multiline
                    size="small"
                    fullWidth
                    name=""
                    value={children || ''}
                    onChange={onTextChange}
                />
            </FormControl>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>Отмечена по умолчанию</Typography>
                <Switch
                    name="showBottomNavLink"
                    checked={defaultChecked}
                    onChange={(e, v) => onDefaultCheckedChange(v)}
                />
            </Stack>
        </Stack>
    );
};
