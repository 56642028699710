import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import useAxios from 'hooks/api/useAxios';
import { PageRetrieve } from 'types/api/pageRetrieve';

type UseAddNewPageProps = {
    onSuccess?: (page: PageRetrieve) => void;
};

type UseAddNewPageReturn = {
    success: boolean;
    error: boolean;
    loading: boolean;
    addNewPage: (page: Partial<PageRetrieve>) => void;
};

export const useAddNewPage = (
    options?: UseAddNewPageProps
): UseAddNewPageReturn => {
    const { onSuccess } = options || {};

    const [body, setBody] = useState<Partial<PageRetrieve>>();

    const { enqueueSnackbar } = useSnackbar();

    const [res, { rerun }] = useAxios<PageRetrieve>(
        {
            url: '/api/builder/page/add/',
            method: 'POST',
            skipRequest: () => true,
            data: body,
        },
        [body]
    );

    const success = res.type === 'success';
    const error = res.type === 'error';
    const loading = res.type === 'loading';

    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                'Что-то пошло не так... Не удалось добавить новую страницу.',
                { variant: 'error' }
            );
        }
        if (success) {
            enqueueSnackbar('Новая страница добавлена', { variant: 'success' });
        }
    }, [error, success, enqueueSnackbar]);

    useEffect(() => {
        if (typeof onSuccess === 'function' && success) {
            onSuccess(res.data);
        }
    }, [onSuccess, res.data, success]);

    const addNewPage = (page: Partial<PageRetrieve>): void => {
        setBody(page);
        rerun();
    };

    return { success, error, loading, addNewPage };
};
