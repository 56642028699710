import styled from 'styled-components';

export const ZoomPanControlWrapper = styled.div`
    position: absolute;
    right: 28px;
    bottom: 28px;
    background-color: ${({ theme }) => theme.palette.grey.bg2};
    box-shadow: 0 0 20px 20px ${({ theme }) => theme.palette.grey.bg2};
    border-radius: 8px;

    .MuiButtonBase-root {
        min-width: 29px !important;
        padding: 9px !important;
    }
`;
