import { useEffect } from 'react';

import { PageContentEditor } from 'components/organisms/PageEditor';

export const PageEditor = (): JSX.Element => {
    useEffect(() => {
        document.body.setAttribute('data-preview', 'true');

        return () => {
            document.body.removeAttribute('data-preview');
        };
    }, []);

    return <PageContentEditor />;
};
