import { Message } from 'types/api/message';

export enum ChatEvents {
    SendMessage = 'send.message',
    ShowMessage = 'show.message',
    MessageRead = 'message.read',
    MessageReceived = 'message.received',
    MessageSent = 'message.sent',
    ChatHistory = 'chat.history',
    ChatFinish = 'chat.finish',
    LastMessage = 'last.message',
    GuestInfo = 'guest.info',
    ChatInfo = 'chat.info',
}

export type ChatEventWithNoPayload = {
    event: Exclude<
        ChatEvents,
        ChatEvents.ShowMessage | ChatEvents.SendMessage | ChatEvents.ChatHistory
    >;
    payload: Record<string, never>;
};

export type ChatMessageEventData = {
    event: ChatEvents.ShowMessage | ChatEvents.SendMessage;
    payload: {
        content: string;
        image_url: string | null;
        manager?: number;
    };
};

export type LastMessageEventData = {
    event: ChatEvents.LastMessage;
    payload: Record<string, never> | Message;
};
export type GuestInfoEventData = {
    event: ChatEvents.GuestInfo;
    payload: {
        data: Record<string, never>;
    };
};

export type ChatHistoryEventData = {
    event: ChatEvents.ChatHistory;
    payload: Record<string, never> | Message[];
};
export type ChatInfoEventData = {
    event: ChatEvents.ChatInfo;
    payload: {
        id: number;
    };
};

export type ChatEventData =
    | ChatEventWithNoPayload
    | ChatMessageEventData
    | ChatHistoryEventData
    | GuestInfoEventData
    | ChatInfoEventData;
