import { useMemo, useState } from 'react';

import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Stack,
    Tooltip,
} from '@mui/material';

import { ChangesResetModal } from 'components/organisms/PageEditor/editorControls/ChangesResetModal';
import { useAppSelector } from 'hooks/store/useAppStore';
import { UseUndoRedoReturn } from 'hooks/useUndoRedo';

type PointSaverControlProps = {
    undoRedoActions: UseUndoRedoReturn['2'];
    loading: boolean;
    onSave: () => void;
    onReset: () => void;
};

export const PointSaverControl = ({
    undoRedoActions,
    loading,
    onReset,
    onSave,
}: PointSaverControlProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);

    const { errors } = useAppSelector((state) => state.pageBuilder);

    const error = useMemo(() => errors.includes('mapPoints'), [errors]);

    const { canRedo, canUndo, redo, undo, hasChanges } = undoRedoActions;

    return (
        <Stack
            spacing={2}
            sx={{
                p: '20px 28px',
                borderBottom: 1,
                borderColor: 'divider',
            }}
        >
            <Stack direction="row" spacing={2}>
                <ButtonGroup variant="outlined" size="small">
                    <Button disabled={!canUndo} onClick={() => undo()}>
                        <UndoIcon />
                    </Button>
                    <Button disabled={!canRedo} onClick={() => redo()}>
                        <RedoIcon />
                    </Button>
                </ButtonGroup>
                <Button
                    size="small"
                    variant="outlined"
                    fullWidth
                    color="error"
                    disabled={!hasChanges || loading}
                    onClick={() => setIsOpen(true)}
                >
                    Отменить изменения
                </Button>

                <Tooltip
                    title={error ? 'Исправьте ошибки перед сохранением' : ''}
                    arrow
                    placement="bottom"
                >
                    <Box sx={{ width: '100%' }}>
                        <Button
                            size="small"
                            variant="contained"
                            fullWidth
                            disabled={!hasChanges || loading || error}
                            onClick={() => onSave()}
                            sx={{ height: '100%' }}
                        >
                            {loading ? (
                                <CircularProgress
                                    size={20}
                                    variant="indeterminate"
                                    color="inherit"
                                />
                            ) : (
                                'Сохранить изменения'
                            )}
                        </Button>
                    </Box>
                </Tooltip>
            </Stack>
            <ChangesResetModal
                onClose={() => setIsOpen(false)}
                open={isOpen}
                onConfirm={() => onReset()}
            />
        </Stack>
    );
};
