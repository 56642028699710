import styled from 'styled-components';

export const ChatListScrollWrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding-bottom: 32px;
    flex: 1;

    margin-right: -20px;
    padding-right: 20px;

    .MuiListItemButton-root {
        &:not(:last-child) {
            border-bottom: 1px solid ${({ theme }) => theme.palette.grey.bg1};
        }
        .MuiGrid-item {
            display: flex;
            flex-direction: column;
            p {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin: auto 0;
            }
        }
    }

    overflow-y: auto;

    @supports (overflow-y: overlay) {
        overflow-y: overlay;
    }

    &::-webkit-scrollbar {
        display: none;
        width: 8px;
    }

    &:hover,
    &[data-scroll='true'] {
        &::-webkit-scrollbar {
            display: block;
        }
    }
`;

export const ChatListMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 32px 40px;

    h1 {
        max-width: 320px;
    }
`;
