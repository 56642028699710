/* eslint-disable import/no-unresolved */

import {
    GeoJsonSource,
    GeoJsonSourceOptions,
    Map,
    Marker,
    MarkerOptions,
} from '@2gis/mapgl/types';

import myLocation from 'assets/icons/24px/my-location.svg';
import poi from 'assets/icons/24px/poi.svg';
import tooltipBg from 'assets/images/tooltip.svg';
import { MapGL, Point, RoutingResult } from 'types/map/2gis';

const whiteLineStyle = {
    width: 9,
    color: '#fff',
};
const lineStyle = {
    width: 5,
    color: [
        'match',
        ['get', 'index'],
        [-1],
        '#ccc',
        [0],
        '#00bbff',
        [1],
        '#00bbff',
        '#00bbff',
    ],
};

const lineFilter = [
    'all',
    ['==', ['sourceAttr', 'foo'], 'bar'],
    ['==', ['get', 'type'], 'path'],
    [
        'any',
        ['==', ['get', 'floorId'], null],
        ['in', ['get', 'floorId'], ['global', '_activeFloorIds']],
    ],
];

export const onMapStyleLoad = (map: Map): void => {
    map.addLayer(
        {
            id: 'my-line-white',
            type: 'line',
            filter: lineFilter,
            style: whiteLineStyle,
        },
        '344517'
    );
    map.addLayer(
        {
            id: 'my-line',
            type: 'line',
            filter: lineFilter,
            style: lineStyle,
        },
        '344517'
    );
    map.addLayer({
        id: 'my-line-white-above',
        type: 'line',
        filter: lineFilter,
        style: whiteLineStyle,
    });
    map.addLayer({
        id: 'my-line-above',
        type: 'line',
        filter: lineFilter,
        style: lineStyle,
    });

    map.addLayer({
        id: 'my-point',
        type: 'point',
        filter: [
            'all',
            ['==', ['sourceAttr', 'foo'], 'bar'],
            ['==', ['get', 'type'], 'point'],
            [
                'any',
                ['==', ['get', 'floorId'], null],
                ['in', ['get', 'floorId'], ['global', '_activeFloorIds']],
            ],
        ],
        style: {
            iconImage: 'ent',
            iconWidth: 15,
            textFont: 'Noto_Sans',
            textFontSize: 14,
            textField: ['get', 'comment'],
            iconPriority: 500,
            textPriority: 0,
            textHaloWidth: 1,
            textHaloColor: '#fff',
            allowOverlap: true,
        },
    });
    map.addLayer({
        id: 'my-point-end',
        type: 'point',
        filter: [
            'all',
            ['==', ['sourceAttr', 'foo'], 'bar'],
            ['==', ['get', 'type'], 'end'],
        ],
        style: {
            iconImage: [
                'match',
                [
                    'any',
                    ['==', ['get', 'floorId'], null],
                    ['in', ['get', 'floorId'], ['global', '_activeFloorIds']],
                ],
                [true],
                'ent_i',
                'ent',
            ],
            iconWidth: 30,
            textFont: 'Noto_Sans_Semibold',
            textFontSize: 14,
            textField: ['get', 'comment'],
            iconPriority: 1000,
            textPriority: 0,
            textColor: '#fff',
            textPlacement: 'centerCenter',
        },
    });
};

export const parserLineStringWKT = (wkt: string): number[][] => {
    return wkt
        .slice('LINESTRING('.length, -1)
        .split(',')
        .map((c) => c.trim().split(' ').map(Number));
};

export const drawRoute = (
    source: GeoJsonSource,
    points: Point[],
    result?: RoutingResult
): void => {
    if (!points.length) {
        return;
    }

    const data: GeoJsonSourceOptions['data'] = {
        type: 'FeatureCollection',
        features: points.map((p, i) => ({
            type: 'Feature',
            properties: {
                type: 'end',
                comment: i === 0 ? 'A' : 'B',
                floorId: p.floor_id,
            },
            geometry: {
                type: 'Point',
                coordinates: [p.x, p.y],
            },
        })),
    };

    let colorIndex = 0;
    let isFirstSegmentAdded = false;
    let lastPoint = points[1]
        ? {
              coordinates: [points[0].x, points[0].y],
              floorId: points[0].floor_id,
          }
        : undefined;

    if (result) {
        result.maneuvers.forEach((maneuver) => {
            if (
                maneuver.outcoming_path &&
                maneuver.outcoming_path.geometry.length
            ) {
                const firstCoord = parserLineStringWKT(
                    maneuver.outcoming_path.geometry[0].selection
                )[0];

                if (maneuver.comment) {
                    data.features.push({
                        type: 'Feature',
                        properties: {
                            type: 'point',
                            comment: maneuver.comment,
                            floorId: lastPoint?.floorId,
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: firstCoord,
                        },
                    });
                }

                if (!isFirstSegmentAdded) {
                    isFirstSegmentAdded = true;
                    data.features.push({
                        type: 'Feature',
                        properties: {
                            type: 'path',
                            index: -1,
                            floorId: maneuver.outcoming_path.floor_from,
                        },
                        geometry: {
                            type: 'LineString',
                            coordinates: [
                                [points[0].x, points[0].y],
                                firstCoord,
                            ],
                        },
                    });
                }

                maneuver.outcoming_path.geometry.forEach((geometry) => {
                    const coordinates = parserLineStringWKT(geometry.selection);
                    data.features.push({
                        type: 'Feature',
                        properties: {
                            type: 'path',
                            index: (colorIndex += 1) % 3,
                            floorId:
                                maneuver.outcoming_path?.floor_to ||
                                maneuver.outcoming_path?.floor_from ||
                                lastPoint?.floorId,
                        },
                        geometry: {
                            type: 'LineString',
                            coordinates,
                        },
                    });
                    lastPoint = {
                        coordinates: coordinates[coordinates.length - 1],
                        floorId:
                            maneuver.outcoming_path?.floor_to ||
                            maneuver.outcoming_path?.floor_from ||
                            lastPoint?.floorId,
                    };
                });
            } else if (maneuver.comment && lastPoint) {
                data.features.push({
                    type: 'Feature',
                    properties: {
                        type: 'point',
                        comment: maneuver.comment,
                        floorId: lastPoint.floorId,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: lastPoint.coordinates,
                    },
                });
            }
        });
    }

    if (lastPoint && result) {
        data.features.push({
            type: 'Feature',
            properties: {
                type: 'path',
                floorId: lastPoint.floorId,
                index: -1,
            },
            geometry: {
                type: 'LineString',
                coordinates: [
                    lastPoint.coordinates,
                    [points[1].x, points[1].y],
                ],
            },
        });
    }

    source.setData(data);
};

const blockMarkerOptions: Partial<MarkerOptions> = {
    label: {
        text: '',
        fontSize: 16,
        color: '#0C6D36',
        haloColor: '#ffffff',
        haloRadius: 2,
        relativeAnchor: [0.5, 0.2],
    },
    icon: poi,
    size: [30, 30],
    maxZoom: 17,
    minZoom: 16,
};

const locationMarkerOptions: Partial<MarkerOptions> = {
    label: {
        text: '',
        fontSize: 12,
        color: '#262626',
        haloColor: '#ffffff',
        haloRadius: 1,
        relativeAnchor: [0.5, 0.2],
    },
    icon: poi,
    size: [20, 20],
    maxZoom: 22,
    minZoom: 17,
};
const myLocationMarkerOptions: Partial<MarkerOptions> = {
    label: {
        text: '',
        fontSize: 12,
        color: '#ffffff',
        image: {
            url: tooltipBg,
            size: [80, 40],
            stretchX: [
                [8, 32],
                [48, 72],
            ],
            stretchY: [[8, 24]],
            padding: [8, 8, 16, 8],
        },
        relativeAnchor: [0.5, 1],
        offset: [0, -30],
    },
    maxZoom: 22,
    minZoom: 17,
    icon: myLocation,
};

export const createMarker = (
    mapInstance: Map,
    mapgl: MapGL,
    coords: number[],
    text?: string,
    style: 'block' | 'location' | 'my-location' | undefined = 'block'
): Marker => {
    const getMarkerOptions = (s: typeof style): Partial<MarkerOptions> => {
        if (s === 'my-location') {
            return myLocationMarkerOptions;
        }
        if (s === 'location') {
            return locationMarkerOptions;
        }
        return blockMarkerOptions;
    };

    const options = getMarkerOptions(style);

    return new mapgl.Marker(mapInstance, {
        coordinates: coords,
        interactive: true,
        ...options,
        label: text
            ? {
                  ...options.label,
                  text,
              }
            : undefined,
        userData: {
            text,
        },
    });
};
