import { ComponentPropsWithoutRef } from 'react';

import { ContentWrapper } from 'components/atoms/Grid';
import { SVGAsset, SVGAssetProps } from 'components/atoms/SVGAsset';

import {
    BraceletHeaderContainer,
    BraceletHeaderContent,
    BraceletHeaderWrapper,
} from './BraceletHeader.styled';

export type BraceletHeaderProps = ComponentPropsWithoutRef<'div'> & {
    variant?: 'Green' | 'Yellow' | 'Red';
    icon?: SVGAssetProps['src'];
};

export const BraceletHeader = (props: BraceletHeaderProps): JSX.Element => {
    const {
        variant = 'Green',
        children,
        icon = 'icons.40px.handGreen',
        ...rest
    } = props;

    const preview = document.body.hasAttribute('data-preview');

    return (
        <BraceletHeaderContainer data-preview={preview}>
            <BraceletHeaderWrapper
                data-preview={preview}
                data-color={variant}
                {...rest}
            >
                <ContentWrapper>
                    <BraceletHeaderContent
                        className={
                            !icon.includes('40px.hand') &&
                            !icon.includes('logo')
                                ? 'svg-color-inherit'
                                : undefined
                        }
                    >
                        {children}
                        <SVGAsset src={icon} />
                    </BraceletHeaderContent>
                </ContentWrapper>
            </BraceletHeaderWrapper>
        </BraceletHeaderContainer>
    );
};
