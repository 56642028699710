import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { QuizOptions } from 'types/builder/quizConfig';

type ValueType = QuizOptions['questionButtonSize'];

type SizeType = {
    value: ValueType;
    title: string;
};

const buttonSizes: SizeType[] = [
    {
        value: 'xl',
        title: 'Огромные',
    },
    {
        value: 'lg',
        title: 'Большие',
    },
    {
        value: 'md',
        title: 'Средние',
    },
    {
        value: 'sm',
        title: 'Маленькие',
    },
    {
        value: 'xs',
        title: 'Компактные',
    },
];

type ButtonSizeControlProps = {
    value: ValueType;
    onChange: (val: ValueType) => void;
};

export const ButtonSizeControl = ({
    onChange,
    value,
}: ButtonSizeControlProps): JSX.Element => {
    return (
        <FormControl variant="outlined" size="small">
            <InputLabel id="base-route-label">Размер кнопкок</InputLabel>
            <Select
                labelId="base-route-label"
                label="Размер кнопкок"
                value={value || ''}
            >
                {buttonSizes.map((el, i) => (
                    <MenuItem
                        key={`menu_item_${i}`}
                        value={el.value}
                        onClick={() => onChange(el.value)}
                    >
                        {el.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
