import { useEffect, useRef, useState } from 'react';

import { AxiosError } from 'axios';

import useAxios from 'hooks/api/useAxios';
import { ChatList } from 'types/api/chatList';

import { useNotifications } from '../integrations/useNotifications';

type UseManagerChatsReturn = {
    chatList: ChatList[] | undefined;
    error: AxiosError<unknown, unknown> | undefined;
    getChatList: () => void;
};

export const useChatList = (): UseManagerChatsReturn => {
    const [res, { rerun }] = useAxios<ChatList[]>(
        {
            url: '/api/chat/own/',
            method: 'GET',
        },
        []
    );

    const mounted = useRef(true);

    const error = res.type === 'error' ? res.data : undefined;

    const [chatList, setChatList] = useState<ChatList[]>();

    useEffect(() => {
        if (res.type === 'success' && mounted) {
            setChatList(res.data);
        }
    }, [res]);

    useNotifications(
        {
            onNewChatAttach: () => rerun(),
            onNewChat: () => {
                rerun();
                setChatList(undefined);
            },
            onNewMessage: () => rerun(),
        },
        [rerun]
    );

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (error) {
                rerun();
            }
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
    }, [error, rerun]);

    const getChatList = (): void => {
        if (mounted) {
            rerun();
        }
    };

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    return { chatList, error, getChatList };
};
