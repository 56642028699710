import styled from 'styled-components';

type TypographyWrapperType = {
    color: string;
    margin: string;
};
export const TypographyWrapper = styled.div<TypographyWrapperType>`
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    color: ${(props) => props.color};
    margin: ${(props) => props.margin};

    max-width: 320px;
`;
export const LogoWrapper = styled.div`
    margin: 48px 0px 0px 0px;
`;
export const BasicButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 264px;
    height: 40px;
    margin: 16px 0px 0px 0px;
`;
export const ErrorCode = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    box-sizing: border-box;
`;
export const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 128px;
    line-height: 150px;
    text-align: center;
    letter-spacing: 0.1em;
    box-sizing: border-box;
    z-index: 1;
    margin: 0px 0px 0px 12px;
`;

type StarsType = {
    width: string;
};
export const Stars = styled.div<StarsType>`
    position: absolute;
    display: flex;

    & span span svg {
        width: ${(props) => props.width};
    }
`;
export const Opacity = styled.div`
    position: absolute;
    display: flex;
    width: 80px;
    height: 90px;
    opacity: 0.7;
    background-color: white;
    filter: blur(1px);
    border-radius: 50px;
`;
