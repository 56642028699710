import styled from 'styled-components';

import { getValueFromObjectPath } from 'utils/getValueFromObjectPath';

import { SimpleLinkProps } from './types';

export const StyledSimpleLink = styled.a<SimpleLinkProps>`
    color: ${({ theme }) => theme.palette.green.betta};
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    white-space: pre-line;

    ${({ theme, variant = 'body.medium' }) =>
        getValueFromObjectPath(theme.typography, variant)};

    &[data-full-width='true'] {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .chevron {
        transform: rotate(-90deg) scale(0.8);
        svg {
            path {
                stroke: ${({ theme }) => theme.palette.green.betta};
                stroke-width: 3;
            }
        }
    }
`;
