/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect } from 'react';

import { PageSettings } from 'components/organisms/PageEditor/editorControls/PageSettingsControl';
import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { useUndoRedo, UseUndoRedoReturn } from 'hooks/useUndoRedo';
import {
    setActiveExpand,
    setConfig as setReduxConfig,
    setSelectedNode,
    setSelectedPage,
} from 'store/slices/pageBuilderSlice';
import { PageList } from 'types/api/pageList';
import { PageComponentConfig } from 'types/builder/componentConfig';
import { PageBuilderState } from 'types/builder/pageBuilder';
import { PageMapConfig } from 'types/builder/pageConfig';
import { QuizQuestion } from 'types/builder/quizConfig';
import {
    addNewComponent,
    deleteNodeByKey,
    duplicateNode,
    updateConfigByNode,
} from 'utils/builder';

import { useSelectedPoint } from '../points/useSelectedPoint';
import { useGetPageConfig } from './useGetPageConfig';
import { usePagesList } from './usePagesList';
import { useUpdatePage } from './useUpdatePage';

type onOfToolBars =
    | PageBuilderState['toolbars']['left']
    | PageBuilderState['toolbars']['right'];

export const useBuilderToolbars = (): {
    toolbars: PageBuilderState['toolbars'];
    setActiveToolBar: (name: onOfToolBars, value: boolean) => void;
} => {
    const dispatch = useAppDispatch();
    const { toolbars } = useAppSelector((state) => state.pageBuilder);

    const setActiveToolBar = useCallback(
        (name: onOfToolBars, value: boolean) => {
            dispatch(
                setActiveExpand({
                    name,
                    value,
                })
            );
        },
        [dispatch]
    );

    return { toolbars, setActiveToolBar };
};

type UsePageBuilderReturn = {
    pageList: { pages?: PageList[]; loading: boolean; error: boolean };
    page: { config?: PageMapConfig; loading: boolean; error: boolean };
    undoRedoState: UseUndoRedoReturn['2'] & { hasChanges: boolean };
    controls: {
        saveChanges: (() => void) & {
            loading: boolean;
            success: boolean;
            error: boolean;
        };
        resetChanges: () => void;
        onNewPageCreated: (id: number) => void;
        onPageDeleted: () => void;
        onNewComponentCreated: (
            component: PageComponentConfig['component']
        ) => void;
        page: {
            onPageConfigChange: (conf: PageMapConfig['pageConfig']) => void;
            onPageSettingsChange: (settings: PageSettings) => void;
            onQuizSettingsChange: (conf: QuizQuestion) => void;
        };
        node: {
            onNodeDelete: () => void;
            onNodeDuplicate: () => void;
            onNodeSettingsChange: (node: PageComponentConfig) => void;
        };
    };
};

export const usePageBuilder = (): UsePageBuilderReturn => {
    const dispatch = useAppDispatch();

    const { selectedPage, selectedNode } = useAppSelector(
        (state) => state.pageBuilder
    );

    const { page, error, loading, getPage } = useGetPageConfig(selectedPage);

    const { setActiveToolBar } = useBuilderToolbars();

    const {
        pageList,
        loading: pageListLoading,
        error: pageListError,
        getPageList,
    } = usePagesList();

    const [config, setConfig, undoRedoActions] = useUndoRedo<
        PageMapConfig | undefined
    >(page);

    useEffect(() => {
        dispatch(setReduxConfig(config));
    }, [config, dispatch]);

    useEffect(() => {
        dispatch(setSelectedNode(undefined));
    }, [page, dispatch]);

    useEffect(() => {
        if (pageListError) {
            dispatch(setSelectedPage(undefined));
        }
    }, [dispatch, pageListError]);

    const { pageConfig = [] } = config || {};

    const onPageConfigChange = useCallback(
        (conf: PageMapConfig['pageConfig']): void => {
            if (config?.type === 'PAGE') {
                setConfig({
                    ...config,
                    pageConfig: conf ? [...conf] : undefined,
                });
            }
        },
        [config, setConfig]
    );

    const onNodeSettingsChange = useCallback(
        (node: PageComponentConfig): void => {
            if (selectedNode?._key) {
                onPageConfigChange(
                    updateConfigByNode([...pageConfig], {
                        _key: selectedNode._key,
                        props: node.props,
                    })
                );
            }
        },
        [selectedNode?._key, onPageConfigChange, pageConfig]
    );

    const onNodeDuplicate = useCallback((): void => {
        if (selectedNode?._key) {
            onPageConfigChange(
                duplicateNode([...pageConfig], selectedNode._key)
            );
        }
    }, [selectedNode?._key, pageConfig, onPageConfigChange]);

    const onNodeDelete = useCallback((): void => {
        if (selectedNode?._key) {
            onPageConfigChange(
                deleteNodeByKey([...pageConfig], selectedNode._key)
            );
            dispatch(setSelectedNode(undefined));
        }
    }, [selectedNode?._key, onPageConfigChange, pageConfig, dispatch]);

    const onNewComponentCreated = useCallback(
        (component: PageComponentConfig['component']) => {
            onPageConfigChange(
                addNewComponent(
                    [...pageConfig],
                    component,
                    (n) => dispatch(setSelectedNode(n)),
                    selectedNode?._key
                )
            );
        },
        [onPageConfigChange, pageConfig, selectedNode?._key, dispatch]
    );

    const onPageSettingsChange = useCallback(
        (settings: PageSettings): void => {
            if (config) {
                setConfig({
                    ...config,
                    ...settings,
                });
            }
        },
        [config, setConfig]
    );

    const onQuizSettingsChange = useCallback(
        (conf: QuizQuestion): void => {
            if (config && config.type === 'QUIZ') {
                setConfig({
                    ...config,
                    quizConfig: conf,
                });
            }
        },
        [config, setConfig]
    );

    const getFreshPageData = useCallback(
        (resetSelectedPage?: boolean): void => {
            if (resetSelectedPage) {
                dispatch(setSelectedPage(undefined));
            }
            getPageList();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onNewPageCreated = (id: number): void => {
        dispatch(setSelectedPage(id));
        getFreshPageData();
    };

    const onPageDeleted = (): void => {
        getFreshPageData(true);
    };

    const {
        loading: pageSaveLoading,
        success: pageSaved,
        error: pageSaveError,
        updatePage,
    } = useUpdatePage();

    useEffect(() => {
        if (pageSaved) {
            getFreshPageData();
            getPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSaved]);

    useEffect(() => {
        if (selectedNode?._key) {
            setActiveToolBar('nodeSettings', true);
        }
    }, [selectedNode?._key, setActiveToolBar]);

    const { setSelectedPoint } = useSelectedPoint();

    useEffect(() => {
        if (selectedPage) {
            setActiveToolBar('pageSettings', true);
            setSelectedPoint(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPage, page, setActiveToolBar]);

    // const pageAnchors = useMemo<string[]>(() => {
    //     if (config?.pageConfig) {
    //         const sections = findAllAnchors(config.pageConfig);

    //         return sections.map((el) => el.props?.id as string);
    //     }
    //     return [];
    // }, [config?.pageConfig]);

    // useEffect(() => {
    //     console.log(pageAnchors);
    // }, [pageAnchors]);

    const usePageBuilderReturn = {
        pageList: {
            pages: pageList,
            loading: pageListLoading,
            error: pageListError,
        },
        page: {
            config,
            loading,
            error: !!error,
        },
        undoRedoState: {
            ...undoRedoActions,
            hasChanges: undoRedoActions.canUndo || undoRedoActions.canRedo,
        },
        controls: {
            saveChanges: Object.assign(
                config ? () => updatePage(config) : () => undefined,
                {
                    loading: pageSaveLoading,
                    success: pageSaved,
                    error: pageSaveError,
                }
            ),
            resetChanges: () => undoRedoActions.resetState(page),
            onNewPageCreated,
            onPageDeleted,
            onNewComponentCreated,
            page: {
                onPageConfigChange,
                onPageSettingsChange,
                onQuizSettingsChange,
            },
            node: {
                onNodeDelete,
                onNodeDuplicate,
                onNodeSettingsChange,
            },
        },
    };

    return usePageBuilderReturn;
};
