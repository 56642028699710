import { Dialog, DialogContent, Stack } from '@mui/material';

import { BasicButton } from 'components/atoms/BasicButton';
import { Typography } from 'components/atoms/Typography';

type ChangesResetModalProps = {
    onClose: () => void;
    onConfirm: () => void;
    open: boolean;
};

export const ChangesResetModal = ({
    onClose,
    onConfirm,
    open,
}: ChangesResetModalProps): JSX.Element => {
    return (
        <Dialog
            open={open}
            onClose={(e, reason) =>
                reason !== 'backdropClick' ? onClose() : undefined
            }
            disableEscapeKeyDown
        >
            <DialogContent sx={{ maxWidth: '420px', padding: '32px 40px' }}>
                <Stack spacing={3}>
                    <Typography variant="title.large" textAlign="center">
                        Вы уверены, что хотите отменить все текущие изменения?
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <BasicButton
                            variant="secondary"
                            onClick={() => onClose()}
                        >
                            Не отменять
                        </BasicButton>
                        <BasicButton
                            onClick={() => {
                                onConfirm();
                                onClose();
                            }}
                            $bgColor="red"
                        >
                            Отменить
                        </BasicButton>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
