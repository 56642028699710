import { Grid } from '@mui/material';
import styled from 'styled-components';

export const StyledRowControlGrid = styled(Grid)`
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    .MuiButtonBase-root {
        min-width: 0;
        width: 100%;
        height: 100%;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            width: 4px;
            height: 4px;
            background-color: currentColor;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.5;
            border-radius: 50%;
        }

        svg {
            transform: rotate(90deg);
            opacity: 0;
        }

        &:hover,
        &[data-active='true'] {
            svg {
                opacity: 0.5;
            }
            &:before {
                display: none;
            }
            &[data-active='true'] {
                svg {
                    opacity: 1;
                }
            }
        }
    }
`;
