import { useEffect } from 'react';

import { Marker } from '@2gis/mapgl/types';
import { Box } from '@mui/material';

import { Map } from 'components/molecules/Map';
import { MARKERS, SELECTED_OBJECTS } from 'components/pages/MapPage';
import { useNewLocationSelector } from 'hooks/api/builder/points/useNewLocationSelector';
import { useMapInstance } from 'hooks/api/map/useMapInstance';
import { useMapPosition } from 'hooks/api/map/useMapPosition';
import { useAppSelector } from 'hooks/store/useAppStore';
import { createMarker, onMapStyleLoad } from 'utils/map';

export const PoiPreviewControl = (): JSX.Element | null => {
    const { mapInstance, mapgl } = useMapInstance();

    const config = useAppSelector((s) => s.pointBuilder.config);

    useEffect(() => {
        if (mapInstance !== undefined && mapgl) {
            mapInstance.setCenter([37.467061292944116, 55.772047401155625]);
            mapInstance.setMinZoom(16.5);
            mapInstance.setMaxZoom(22);

            mapInstance.setStyleState({
                showPoints: true,
                showAlways: false,
            });

            mapInstance.once('styleload', () => onMapStyleLoad(mapInstance));

            mapInstance.setSelectedObjects(SELECTED_OBJECTS);

            const markers = MARKERS.map((m) =>
                createMarker(mapInstance, mapgl, m.coords, m.text, 'block')
            );

            return () => {
                markers.forEach((marker) => {
                    if (marker) {
                        marker.destroy();
                    }
                });
            };
        }
        return undefined;
    }, [mapInstance, mapgl]);

    const { mode } = useNewLocationSelector();

    const { setMapToLocation, setMapFloor } = useMapPosition();

    useEffect(() => {
        let marker: Marker | undefined;

        if (config && mapInstance && mapgl && mode === undefined) {
            const floor = config.floor?.floor_number;

            setMapToLocation({
                x: config.latitude,
                y: config.longitude,
                zoom: 21,
            });

            setMapFloor({
                floorLevelIndex:
                    floor !== undefined && floor < 1 ? floor + 1 : floor,
                floorPlanId: config.building?.floor_plan_id,
            });

            marker = createMarker(
                mapInstance,
                mapgl,
                [config.latitude, config.longitude],
                config.name,
                'my-location'
            );

            if (!mode) {
                document
                    .querySelector('.floor-control')
                    ?.classList.add('hidden');
            }
        }
        return () => {
            marker?.destroy();

            document
                .querySelector('.floor-control')
                ?.classList.remove('hidden');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, mapInstance, mapgl, mode, setMapFloor]);

    return config?.id || mode ? (
        <Box sx={{ flex: 1, position: 'relative' }}>
            <Map fullScreen={false} />
        </Box>
    ) : null;
};
