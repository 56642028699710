import styled from 'styled-components';

export const ChatWindowWrapper = styled.div`
    flex: 1;

    overflow-y: auto;
    overflow-anchor: none;
    @supports (overflow-y: overlay) {
        overflow-y: overlay;
    }
`;

export const ChatMessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
    padding-bottom: 58px;
    overflow: hidden;
`;

export const ChatInput = styled.textarea`
    padding: 6px 8px;
    background: #f7f7fc;
    border-radius: 8px;
    resize: none;
    width: 100%;
    min-height: 36px;
    max-height: 106px;
    height: 36px;
    margin: 0px 12px;
    ${({ theme }) => theme.typography.body.large}
    &:focus {
        outline-color: ${({ theme }) => theme.palette.green.betta};
    }
`;

export const ChatFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-top: 1px solid #ededed;
    padding: 10px 16px;
    padding-bottom: calc(10px + env(safe-area-inset-bottom));
`;
