import {
    ChangeEvent,
    KeyboardEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { Box, CircularProgress, Fab, IconButton, Stack } from '@mui/material';
import { useDropzone } from 'react-dropzone';

import { BasicButton } from 'components/atoms/BasicButton';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { DropZoneWrapper } from 'components/organisms/PageEditor/editorControls/FileUploadControl/FileUploadControl.styled';
import { useImageUpload } from 'hooks/api/chat/useImageUpload';

import { ChatInput } from '../ChatControl/ChatControl.styled';
import * as Style from './ChatFileControl.styled';

type ChatFileUploadControlProps = {
    shouldOpenDropZone?: boolean;
    message?: string;
    onMessageChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    chatID: number;
    onSuccess?: (imageUrl: string) => void;
};

export const ChatFileUploadControl = ({
    shouldOpenDropZone = true,
    message = '',
    onMessageChange = () => undefined,
    chatID,
    onSuccess = () => undefined,
}: ChatFileUploadControlProps): JSX.Element => {
    const [dropZoneOpen, setDropZoneOpen] = useState(false);

    const [uploadedFile, setUploadedFile] = useState<string | Blob>();

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const onDrop = useCallback(<T extends File>(acceptedFiles: T[]) => {
        if (acceptedFiles?.length === 1) {
            setUploadedFile(acceptedFiles[0]);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    });

    const previewImage = useMemo(() => {
        return uploadedFile
            ? URL.createObjectURL(uploadedFile as Blob)
            : undefined;
    }, [uploadedFile]);

    const onChatKeyDown: KeyboardEventHandler = (e) => {
        const onlyEnter =
            !e.ctrlKey &&
            !e.altKey &&
            !e.shiftKey &&
            !e.metaKey &&
            e.key === 'Enter';

        if (onlyEnter && !('ontouchstart' in document.documentElement)) {
            e.preventDefault();
            // sendMessage();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.style.height = '36px';
                inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
                inputRef.current.scrollTop = inputRef.current.scrollHeight;
            }
        }, 100);
    }, [message, inputRef, previewImage]);

    useEffect(() => {
        if (!dropZoneOpen) {
            setUploadedFile(undefined);
        }
    }, [dropZoneOpen]);

    const onCloseAll = (): void => {
        setDropZoneOpen(false);
        setUploadedFile(undefined);
    };

    const { uploadImage, loading, error, fileLink } = useImageUpload();

    const fileTooLarge = error && error.response?.status === 429;

    useEffect(() => {
        if (!loading && !error && fileLink) {
            onSuccess(fileLink);
            onCloseAll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileLink, loading, error]);

    return (
        <>
            <IconButton
                sx={{ mx: -1, alignSelf: 'flex-start' }}
                onClick={
                    shouldOpenDropZone ? () => setDropZoneOpen(true) : undefined
                }
                component="label"
                htmlFor="contained-button-file"
            >
                <SVGAsset src="icons.24px.paperclip" />
                {!shouldOpenDropZone ? (
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        {...getInputProps()}
                    />
                ) : undefined}
            </IconButton>
            {!uploadedFile ? (
                <Style.Dialog
                    open={dropZoneOpen}
                    onClose={() => setDropZoneOpen(false)}
                    maxWidth={false}
                    sx={{ zIndex: 1800 }}
                >
                    <Style.DialogContent>
                        <DropZoneWrapper
                            {...getRootProps()}
                            className="dropzone"
                        >
                            <input {...getInputProps()} />
                            <Stack spacing={4} pt="72px">
                                <Typography
                                    variant="title.large"
                                    $color="common.grey"
                                    textAlign="center"
                                >
                                    Перетащите файл сюда
                                </Typography>
                                <BasicButton $bgColor="green">
                                    Выбрать файл
                                </BasicButton>
                            </Stack>
                        </DropZoneWrapper>
                    </Style.DialogContent>
                </Style.Dialog>
            ) : undefined}
            {uploadedFile ? (
                <Style.Dialog open maxWidth="xl" sx={{ zIndex: 1800 }}>
                    <Fab
                        className="close-button"
                        onClick={() => onCloseAll()}
                        size="small"
                        disabled={loading}
                    >
                        <SVGAsset src="icons.24px.closeX" />
                    </Fab>
                    <Style.DialogContent
                        sx={{ flexDirection: 'column', minHeight: 500 }}
                    >
                        <Box sx={{ mb: 2 }}>
                            <Typography
                                variant="title.medium"
                                textAlign="center"
                            >
                                Загрузка изображения
                            </Typography>
                        </Box>
                        <div
                            className="img-preview-wrapper"
                            data-loading={loading}
                        >
                            <div
                                className="img-preview"
                                style={{
                                    backgroundImage: `url(${previewImage})`,
                                }}
                            />
                        </div>
                        {error ? (
                            <Box sx={{ mt: 2 }}>
                                <Typography
                                    variant="body.small"
                                    textAlign="center"
                                    $color="red"
                                >
                                    {fileTooLarge
                                        ? 'Размер изображения превышает допустимый лимит'
                                        : 'При загрузке изображения что-то пошло не так'}
                                </Typography>
                            </Box>
                        ) : undefined}
                        <Stack
                            spacing={0.5}
                            sx={{ mt: 2, width: '100%' }}
                            direction="row"
                        >
                            <ChatInput
                                value={message}
                                onChange={onMessageChange}
                                placeholder="Описание"
                                onKeyDown={onChatKeyDown}
                                ref={inputRef}
                                style={{ margin: 0 }}
                                disabled={loading}
                            />
                            <IconButton
                                onClick={() =>
                                    uploadImage(uploadedFile, chatID)
                                }
                                className="send-button"
                                color="success"
                                sx={{ alignSelf: 'flex-start' }}
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress
                                        variant="indeterminate"
                                        size={24}
                                    />
                                ) : (
                                    <SVGAsset
                                        src="icons.24px.send"
                                        className="svg-color-inherit"
                                    />
                                )}
                            </IconButton>
                        </Stack>
                    </Style.DialogContent>
                </Style.Dialog>
            ) : undefined}
        </>
    );
};
