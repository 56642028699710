import { useState } from 'react';

import { AxiosError } from 'axios';

import useAxios from 'hooks/api/useAxios';
import { ChatFileStorage } from 'types/api/chatFileStorage';

type UseImageUploadReturn = {
    uploadImage: (file: string | Blob, chat: number) => void;
    loading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: AxiosError<unknown, any> | undefined;
    fileLink: string | undefined;
};

export const useImageUpload = (): UseImageUploadReturn => {
    const [body, setBody] = useState<FormData>();

    const [res, { rerun }] = useAxios<ChatFileStorage>(
        {
            url: '/api/chat/storage/',
            method: 'POST',
            data: body,
            skipRequest: () => true,
        },
        [body]
    );

    const uploadImage = (file: string | Blob, chat: number): void => {
        const fData = new FormData();

        fData.append('chat', chat?.toString() || '');
        fData.append('file', file || '');

        setBody(fData);
        rerun();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const error = res.type === 'error' ? res.data : undefined;
    const fileLink = res.type === 'success' ? res.data.file : undefined;
    const loading = res.type === 'loading';

    return { uploadImage, loading, error, fileLink };
};
