import { useEffect } from 'react';

import { Switch } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useEditUser } from 'hooks/api/staff/useEditUser';
import useAxios from 'hooks/api/useAxios';

type SwitchProps = {
    id: number;
    role: number;
    status: boolean;
    action?: string;
};
export const SwitchUserRole = (props: SwitchProps): JSX.Element => {
    const { addUserRole, deleteUserRole } = useEditUser();
    const { id, role, status, action } = props;

    const [res, { rerun }] = useAxios(
        {
            url: `/api/admin/user/${id}/role/${action}/`,
            method: 'POST',
            data: { role_id: role },
            skipRequest: () => true,
        },
        []
    );

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (res.type === 'loading') {
            if (action === 'add') {
                addUserRole(id, role);
            }
            if (action === 'remove') {
                deleteUserRole(id, role);
            }
        }
        if (res.type === 'error') {
            if (action === 'add') {
                addUserRole(id, role);
            }
            if (action === 'remove') {
                deleteUserRole(id, role);
            }
            enqueueSnackbar(`Не удалось сменить роль пользователя`, {
                variant: 'error',
                key: 'user-role-error',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [res.type, enqueueSnackbar]);

    return <Switch checked={status} onClick={() => rerun()} />;
};
