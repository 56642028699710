import { ChangeEventHandler, useCallback, useMemo } from 'react';

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    Divider,
    TextField,
    InputAdornment,
    Tooltip,
    IconButton,
    Link,
} from '@mui/material';

import { Typography } from 'components/atoms/Typography';
import { SimpleLinkProps } from 'components/molecules/Links/Link/types';
import { useAppSelector } from 'hooks/store/useAppStore';
import { SimpleLinkConfig } from 'types/builder/componentConfig';

import { FileUploadControl } from '../../editorControls/FileUploadControl';
import { InternalPageSelector } from '../../editorControls/InternalPageSelector';

type LinkControlProps = {
    node?: SimpleLinkConfig;
    onChange: (node?: SimpleLinkConfig) => void;
};

type LinkType = {
    value: SimpleLinkProps['type'];
    title: string;
};

const types: LinkType[] = [
    {
        value: 'internal',
        title: 'Ссылка на внутреннюю страницу',
    },
    {
        value: 'external',
        title: 'Ссылка на внешний ресурс',
    },
    {
        value: 'image',
        title: 'Ссылка на картинку',
    },
    {
        value: 'document',
        title: 'Ссылка на документ',
    },
    {
        value: 'phone',
        title: 'Контактная ссылка',
    },
    {
        value: 'chat',
        title: 'Ссылка на саппорт-чат',
    },
];

type Variant = SimpleLinkProps['variant'];

const variants: {
    value: Variant;
    title: string;
}[] = [
    {
        value: 'display.medium',
        title: 'Заголовок 1',
    },
    {
        value: 'title.large',
        title: 'Заголовок 2',
    },
    {
        value: 'title.medium',
        title: 'Заголовок 3',
    },
    {
        value: 'title.small',
        title: 'Параграф (жирный)',
    },
    {
        value: 'body.medium',
        title: 'Параграф',
    },
];

export const LinkControl = ({
    onChange,
    node,
}: LinkControlProps): JSX.Element => {
    const {
        href = '',
        hrefUuid = '',
        type = 'internal',
        variant = 'body.medium',
        fullWidth = false,
        showArrow = false,
        children,
    } = node?.props || {};

    const onLinkTypeChange = (value: SimpleLinkProps['type']): void => {
        onChange({
            component: 'simpleLink',
            props: {
                ...node?.props,
                type: value,
                href: '',
                hrefUuid: undefined,
            },
        });
    };

    const onArrowChange = (value: boolean): void => {
        onChange({
            component: 'simpleLink',
            props: {
                ...node?.props,
                showArrow: value,
            },
        });
    };
    const onFullWidthChange = (value: boolean): void => {
        onChange({
            component: 'simpleLink',
            props: {
                ...node?.props,
                fullWidth: value,
            },
        });
    };

    const onPhoneHrefChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        const numeric = value.replaceAll(/[^\d]/g, '');

        const match = value.match(/[^\d]/g);

        if (value.length < 11 && !match) {
            onChange({
                component: 'simpleLink',
                props: {
                    ...node?.props,
                    href: `tel:7${numeric}`,
                },
            });
        }
    };

    const onLinkTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        onChange({
            component: 'simpleLink',
            props: {
                ...node?.props,
                children: value,
            },
        });
    };

    const generatePhoneLink = useCallback((): void => {
        let idx = 0;

        const formated = '### ###-##-##'.split('').map((el) => {
            if (el === '#') {
                const char = href.slice(5)[idx];
                idx += 1;
                return char;
            }
            return el;
        });

        onChange({
            component: 'simpleLink',
            props: {
                ...node?.props,
                children: `+7 ${formated.join('')}`,
            },
        });
    }, [href, node?.props, onChange]);

    const onVariantChange = (value: SimpleLinkProps['variant']): void => {
        onChange({
            component: 'simpleLink',
            props: {
                ...node?.props,
                variant: value,
            },
        });
    };

    const onLinkHrefChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        onChange({
            component: 'simpleLink',
            props: {
                ...node?.props,
                hrefUuid: undefined,
                href: value,
            },
        });
    };

    const { id: pageID } =
        useAppSelector((state) => state.pageBuilder.config) || {};

    const endAdorment = useMemo(
        () =>
            type === 'phone' ? (
                <InputAdornment position="end">
                    <Tooltip
                        title="Сгенерировать текст из телефона"
                        placement="bottom-end"
                        arrow
                    >
                        <IconButton
                            edge="end"
                            onClick={() => generatePhoneLink()}
                            disabled={!href}
                        >
                            <AutoFixHighIcon />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            ) : undefined,
        [generatePhoneLink, href, type]
    );

    return (
        <Stack spacing={2}>
            <FormControl variant="outlined" size="small">
                <InputLabel id="base-route-label">Вариант</InputLabel>
                <Select
                    labelId="base-route-label"
                    label="Вариант"
                    value={variant || ''}
                    renderValue={(v) => (
                        <span>
                            {variants.find((el) => el.value === v)?.title}
                        </span>
                    )}
                >
                    {variants.map((el, i) => (
                        <MenuItem
                            key={`menu_item_${i}`}
                            value={el.value}
                            onClick={() => onVariantChange(el.value)}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                <Typography variant={el.value}>
                                    {el.title}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small">
                <InputLabel id="base-route-label">Тип ссылки</InputLabel>
                <Select
                    labelId="base-route-label"
                    label="Тип ссылки"
                    value={type || ''}
                >
                    {types.map((el, i) => (
                        <MenuItem
                            key={`menu_item_${i}`}
                            value={el.value}
                            onClick={() => onLinkTypeChange(el.value)}
                        >
                            {el.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {type === 'internal' ? (
                <InternalPageSelector
                    value={hrefUuid}
                    onChange={(v) =>
                        onChange({
                            component: 'simpleLink',
                            props: {
                                ...node?.props,
                                href: undefined,
                                hrefUuid: v,
                            },
                        })
                    }
                />
            ) : undefined}
            {type === 'phone' ? (
                <FormControl variant="outlined">
                    <TextField
                        label="Введите телефон"
                        size="small"
                        fullWidth
                        value={href.slice(5) || ''}
                        onChange={onPhoneHrefChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    +7
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            ) : undefined}
            {type === 'external' ? (
                <FormControl variant="outlined">
                    <TextField
                        label="Внешний ресурс"
                        size="small"
                        fullWidth
                        value={href || ''}
                        onChange={onLinkHrefChange}
                    />
                </FormControl>
            ) : undefined}
            {type === 'image' || type === 'document' ? (
                <Stack spacing={1}>
                    <FileUploadControl
                        accept={type === 'image' ? 'images' : 'files'}
                        onSuccess={(file) =>
                            onChange({
                                component: 'simpleLink',
                                props: {
                                    ...node?.props,
                                    href: file,
                                },
                            })
                        }
                        id={pageID?.toString()}
                    />
                    <Stack direction="row" justifyContent="space-between">
                        <Typography
                            variant="caption.small"
                            $color="common.grey"
                        >
                            {href ? 'Файл загружен' : 'Файл не загружен'}
                        </Typography>
                        {href ? (
                            <Link
                                href={href}
                                target="_blank"
                                rel="noopener noreferrer"
                                fontSize={12}
                            >
                                Предпросмотр
                            </Link>
                        ) : undefined}
                    </Stack>
                </Stack>
            ) : undefined}
            <TextField
                label="Текст ссылки"
                size="small"
                variant="outlined"
                fullWidth
                multiline
                value={children || ''}
                onChange={onLinkTextChange}
                autoFocus
                InputProps={{
                    endAdornment: endAdorment,
                }}
            />
            <Stack spacing={1} sx={{ mt: 1 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>Показывать стрелку</Typography>
                    <Switch
                        name="showTopAppBar"
                        checked={showArrow}
                        onChange={(e, checked) => onArrowChange(checked)}
                    />
                </Stack>
                <Divider />
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>По всей ширине</Typography>
                    <Switch
                        name="showTopAppBar"
                        checked={fullWidth}
                        onChange={(e, checked) => onFullWidthChange(checked)}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};
