import { ComponentPropsWithoutRef } from 'react';

import aa from 'assets/images/doctors/aa.png';
import aa2 from 'assets/images/doctors/aa2.png';
import an from 'assets/images/doctors/an.png';
import em from 'assets/images/doctors/em.png';
import ev from 'assets/images/doctors/ev.png';
import ia from 'assets/images/doctors/ia.png';
import lo from 'assets/images/doctors/lo.png';
import lp from 'assets/images/doctors/lp.png';
import lv from 'assets/images/doctors/lv.png';
import os from 'assets/images/doctors/os.png';
import si from 'assets/images/doctors/si.png';
import sv from 'assets/images/doctors/sv.png';
import ts from 'assets/images/doctors/ts.png';

import {
    DoctorCardImage,
    DoctorCardInfo,
    DoctorCardWrapper,
    DoctorCardSpec,
    DoctorCardName,
    DoctorCardAction,
} from './DoctorCard.styled';

export type DoctorCardProps = ComponentPropsWithoutRef<'div'> & {
    name?: string;
    spec?: string;
    photo?: string;
};

export const DoctorCard = (props: DoctorCardProps): JSX.Element => {
    const { name, spec, photo } = props;

    interface Photos {
        [key: string]: string;
    }
    const photos: Photos = {
        // пока что картинки из статики, но также может отобразить картинку просто по url: 'https://via.placeholder.com/300.png/09f/fff'
        em,
        ia,
        ts,
        aa,
        lv,
        os,
        lp,
        lo,
        aa2,
        ev,
        an,
        si,
        sv,
    };

    return (
        <DoctorCardWrapper>
            <DoctorCardImage src={photos[photo as keyof Photos] || photo} />
            <DoctorCardInfo>
                <DoctorCardSpec>{spec}</DoctorCardSpec>
                <DoctorCardName>{name}</DoctorCardName>
                <DoctorCardAction>Подробнее →</DoctorCardAction>
            </DoctorCardInfo>
        </DoctorCardWrapper>
    );
};
