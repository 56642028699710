import { ComponentPropsWithoutRef, MouseEventHandler } from 'react';

import { useGallery } from 'hooks/store/useGallery';

import { StyledImage, StyledImageLink } from './Image.styled';

export type ImageProps = ComponentPropsWithoutRef<'img'> & { modal?: boolean };

export const Image = (props: ImageProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { alt, src, modal, children, ...rest } = props;

    const { setGallery } = useGallery();

    const preview = document.body.hasAttribute('data-preview');

    const onImageLinkClick: MouseEventHandler = (e) => {
        e.preventDefault();
        if (!preview) {
            setGallery(src);
        }
    };

    if (!modal) {
        return <StyledImage {...rest} src={src} alt={alt || ''} />;
    }

    return (
        <StyledImageLink
            href={src}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onImageLinkClick}
        >
            <StyledImage {...rest} src={src} alt={alt || ''} />
        </StyledImageLink>
    );
};
