import { useEffect } from 'react';

import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { BasicButton } from 'components/atoms/BasicButton';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useAppTitle } from 'hooks/store/useAppTitle';

import * as Style from './NotFoundPage.styled';

type NotFoundPageProps = {
    backPath?: string;
};

export const NotFoundPage = ({ backPath }: NotFoundPageProps): JSX.Element => {
    const { setAppTitle } = useAppTitle();
    const navigate = useNavigate();

    useEffect(() => {
        setAppTitle('Страница не найдена');
    }, [setAppTitle]);

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ flex: 1, height: '100%' }}
        >
            <Style.LogoWrapper>
                <SVGAsset src="logos.logo67" />
            </Style.LogoWrapper>

            <Style.TypographyWrapper color="#000000" margin="66px 0px 0px 0px">
                <Typography variant="title.small" textAlign="center">
                    Ошибка
                </Typography>
            </Style.TypographyWrapper>

            <Style.TypographyWrapper color="#B7D30C" margin="0px 0px 0px 0px">
                <Style.ErrorCode>
                    <Style.Stars width="120px">
                        <SVGAsset src="shapes.stars.large" />
                    </Style.Stars>
                    <Style.Stars width="80px">
                        <SVGAsset src="shapes.stars.small" />
                    </Style.Stars>
                    <Style.Opacity />
                    <Style.Text>404</Style.Text>
                </Style.ErrorCode>
            </Style.TypographyWrapper>

            <Style.TypographyWrapper color="#B7D30C" margin="68px 0px 0px 0px">
                <Typography variant="headline.large" textAlign="center">
                    Ой!
                </Typography>
            </Style.TypographyWrapper>

            <Style.TypographyWrapper color="#000000" margin="8px 0px 0px 0px">
                <Typography variant="title.small" textAlign="center">
                    Страница не найдена. Не расстраивайтесь, вы можете вернуться
                    на главную.
                </Typography>
            </Style.TypographyWrapper>

            <Style.BasicButtonWrapper>
                <BasicButton
                    onClick={() =>
                        navigate(backPath ?? '/home', { replace: true })
                    }
                >
                    На главную
                </BasicButton>
            </Style.BasicButtonWrapper>
        </Stack>
    );
};
