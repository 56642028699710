import styled, { css } from 'styled-components';

import {
    BoxProps,
    ColProps,
    ContentWrapperProps,
    RowProps,
    SectionProps,
    SpacerProps,
} from './types';

export const StyledSection = styled.section<SectionProps>`
    margin: ${({ noMargin }) => (noMargin ? undefined : '24px 0')};
`;

export const StyledRow = styled.div<RowProps>`
    display: flex;
    flex-direction: row;

    align-items: ${({ vAlign = 'flex-start' }) => vAlign};
    justify-content: ${({ hAlign = 'flex-start' }) => hAlign};

    margin: ${(props) => (props.noPadding ? undefined : '-8px')};
    & > div {
        width: ${(props) =>
            props.cols ? `calc(100%/${props.cols})` : undefined};
        padding: ${(props) => (props.noPadding ? undefined : '8px')};
    }
`;

const paddingStyles = css`
    padding-left: calc(16px + env(safe-area-inset-left));
    padding-right: calc(16px + env(safe-area-inset-right));
`;

const paddingStylesWithEnv = css`
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
`;

export const StyledContentWrapper = styled.div<ContentWrapperProps>`
    margin: 0 auto;
    max-width: 576px;
    ${(props) => (props.noPadding ? paddingStylesWithEnv : paddingStyles)};
    width: 100%;
    flex: 1 0 auto;

    display: flex;
    flex-direction: column;
`;

export const StyledCol = styled.div<ColProps>`
    width: ${({ col }) => (col ? `calc(99% / ${col})` : undefined)};

    > div {
        width: ${({ col }) => (col ? '100%' : undefined)};
    }
`;

export const StyledBox = styled.div<BoxProps>`
    &[data-variant='message'] {
        background: ${({ theme }) => theme.palette.glass.green};
        border-radius: ${({ theme }) => theme.borderRadius.large};
        padding: 32px 16px;
        margin: 0 -16px;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            top: -24px;
            border-left: 24px solid transparent;
            border-bottom: 24px solid
                ${({ theme }) => theme.palette.glass.green};
            right: 80px;
        }
    }
    &[data-variant='paper'] {
        background: ${({ theme }) => theme.palette.glass.green};
        border-radius: ${({ theme }) => theme.borderRadius.medium};
        padding: 8px 16px;
        margin: 16px -16px;
    }
`;

export const StyledSpacer = styled.div<SpacerProps>`
    padding-top: ${({ size }) => (size === '1x' ? '8px' : undefined)};
    padding-top: ${({ size }) => (size === '2x' ? '16px' : undefined)};
    padding-top: ${({ size }) => (size === '3x' ? '24px' : undefined)};
    padding-top: ${({ size }) => (size === '4x' ? '32px' : undefined)};
    padding-top: ${({ size }) => (size === '5x' ? '40px' : undefined)};

    width: 100%;
`;

StyledSpacer.defaultProps = {
    size: '1x',
};
