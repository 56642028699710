import { useState } from 'react';

import { AxiosError } from 'axios';

import useAxios from 'hooks/api/useAxios';
import { Location } from 'types/api/location';

type UseGetPointConfigReturn = {
    loading: boolean;
    success: boolean;
    error: false | AxiosError<unknown, unknown>;
    deletePoint: (id: number) => void;
};

export const usePointDelete = (): UseGetPointConfigReturn => {
    const [id, setID] = useState<number>();

    const [res, { rerun }] = useAxios<Location>(
        {
            url: `/api/builder/map/location/${id}/`,
            method: 'DELETE',
            skipRequest: () => true,
        },
        [id]
    );

    const loading = res.type === 'loading';
    const success = res.type === 'success';
    const error = res.type === 'error' && res.data;

    const sendDeleteRequest = (pointID: number): void => {
        setID(pointID);
        rerun();
    };

    return { success, loading, error, deletePoint: sendDeleteRequest };
};
