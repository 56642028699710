import { Link, Stack, Switch } from '@mui/material';

import { Typography } from 'components/atoms/Typography';
import { useAppSelector } from 'hooks/store/useAppStore';
import { ImageConfig } from 'types/builder/componentConfig';

import { FileUploadControl } from '../../editorControls/FileUploadControl';

type ImageControlProps = {
    node?: ImageConfig;
    onChange: (config: ImageConfig) => void;
};

export const ImageControl = ({
    node,
    onChange,
}: ImageControlProps): JSX.Element => {
    const { src = '', modal = false } = node?.props || {};

    const { id: pageID } =
        useAppSelector((state) => state.pageBuilder.config) || {};

    const onSrcChange = (value: string): void => {
        onChange({
            component: 'image',
            props: {
                ...node?.props,
                src: value,
            },
        });
    };
    const onModalChange = (value: boolean): void => {
        onChange({
            component: 'image',
            props: {
                ...node?.props,
                modal: value,
            },
        });
    };

    return (
        <Stack spacing={2}>
            <Stack spacing={1}>
                <FileUploadControl
                    accept="images"
                    id={pageID?.toString()}
                    onSuccess={onSrcChange}
                />
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="caption.small" $color="common.grey">
                        {src ? 'Файл загружен' : 'Файл не загружен'}
                    </Typography>
                    {src ? (
                        <Link
                            href={src}
                            target="_blank"
                            rel="noopener noreferrer"
                            fontSize={12}
                        >
                            Предпросмотр
                        </Link>
                    ) : undefined}
                </Stack>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>Полноэкранный режим</Typography>
                <Switch
                    name="showBottomNavLink"
                    checked={modal}
                    onChange={(e, v) => onModalChange(v)}
                />
            </Stack>
        </Stack>
    );
};
