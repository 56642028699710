import { ChangeEventHandler } from 'react';

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
    FormControl,
    InputLabel,
    Select,
    Stack,
    MenuItem,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Tooltip,
} from '@mui/material';

import { BasicButtonProps } from 'components/atoms/BasicButton';
import { ButtonColorBadge } from 'components/atoms/BasicButton/ButtonButton.styled';
import { Typography } from 'components/atoms/Typography';
import { useAppSelector } from 'hooks/store/useAppStore';
import { BasicButtonConfig } from 'types/builder/componentConfig';

import { FileUploadControl } from '../../editorControls/FileUploadControl';
import { InternalPageSelector } from '../../editorControls/InternalPageSelector';

type BasicButtonControlProps = {
    node: BasicButtonConfig;
    onChange: (config: BasicButtonConfig) => void;
};

type LinkType = {
    value: BasicButtonProps['linkType'];
    title: string;
};

const types: LinkType[] = [
    {
        value: 'internal',
        title: 'Ссылка на внутреннюю страницу',
    },
    {
        value: 'external',
        title: 'Ссылка на внешний ресурс',
    },
    {
        value: 'image',
        title: 'Ссылка на картинку',
    },
    {
        value: 'document',
        title: 'Ссылка на документ',
    },
    {
        value: 'phone',
        title: 'Контактная ссылка',
    },
    {
        value: 'chat',
        title: 'Ссылка на саппорт-чат',
    },
];

type ButtonVariant = {
    value: BasicButtonProps['variant'];
    title: string;
};

const variants: ButtonVariant[] = [
    {
        value: 'primary',
        title: 'Основная кнопка',
    },
    {
        value: 'secondary',
        title: 'Второстепенная кнопка',
    },
];
type ButtonColors = {
    value: BasicButtonProps['$bgColor'];
    title: string;
};

const colors: ButtonColors[] = [
    {
        value: 'green',
        title: 'Зеленая',
    },
    {
        value: 'yellow',
        title: 'Желтая',
    },
    {
        value: 'red',
        title: 'Красная',
    },
];

export const BasicButtonControl = ({
    node,
    onChange,
}: BasicButtonControlProps): JSX.Element => {
    const {
        href = '',
        hrefUuid = '',
        linkType = 'internal',
        children,
        $bgColor = 'green',
        variant = 'primary',
    } = node.props || {};

    const onLinkTypeChange = (value: BasicButtonProps['linkType']): void => {
        onChange({
            component: 'basicButton',
            props: {
                ...node?.props,
                linkType: value,
                href: '',
                hrefUuid: undefined,
            },
        });
    };

    const onPhoneHrefChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        const numeric = value.replaceAll(/[^\d]/g, '');

        const match = value.match(/[^\d]/g);

        if (value.length < 11 && !match) {
            onChange({
                component: 'basicButton',
                props: {
                    ...node?.props,
                    href: `tel:7${numeric}`,
                },
            });
        }
    };

    const onLinkTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        onChange({
            component: 'basicButton',
            props: {
                ...node?.props,
                children: value,
            },
        });
    };

    const generatePhoneLink = (): void => {
        let idx = 0;

        const formated = '### ###-##-##'.split('').map((el) => {
            if (el === '#') {
                const char = href.slice(5)[idx];
                idx += 1;
                return char;
            }
            return el;
        });

        onChange({
            component: 'basicButton',
            props: {
                ...node?.props,
                children: `+7 ${formated.join('')}`,
            },
        });
    };

    const onVariantChange = (value: BasicButtonProps['variant']): void => {
        onChange({
            component: 'basicButton',
            props: {
                ...node?.props,
                variant: value,
            },
        });
    };
    const onColorChange = (value: BasicButtonProps['$bgColor']): void => {
        onChange({
            component: 'basicButton',
            props: {
                ...node?.props,
                $bgColor: value,
            },
        });
    };

    const onLinkHrefChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        onChange({
            component: 'basicButton',
            props: {
                ...node?.props,
                href: value,
            },
        });
    };

    const { id: pageID } =
        useAppSelector((state) => state.pageBuilder.config) || {};

    return (
        <Stack spacing={2}>
            <FormControl variant="outlined">
                <TextField
                    label="Текст кнопки"
                    size="small"
                    multiline
                    fullWidth
                    value={children || ''}
                    onChange={onLinkTextChange}
                    InputProps={
                        linkType === 'phone'
                            ? {
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <Tooltip
                                              title="Сгенерировать текст из телефона"
                                              placement="bottom-end"
                                              arrow
                                          >
                                              <IconButton
                                                  edge="end"
                                                  onClick={() =>
                                                      generatePhoneLink()
                                                  }
                                                  disabled={!href}
                                              >
                                                  <AutoFixHighIcon />
                                              </IconButton>
                                          </Tooltip>
                                      </InputAdornment>
                                  ),
                              }
                            : undefined
                    }
                />
            </FormControl>
            <FormControl variant="outlined" size="small">
                <InputLabel id="base-route-label">Вариант кнопки</InputLabel>
                <Select
                    labelId="base-route-label"
                    label="Вариант кнопки"
                    value={variant || ''}
                >
                    {variants.map((el, i) => (
                        <MenuItem
                            key={`menu_item_${i}`}
                            value={el.value}
                            onClick={() => onVariantChange(el.value)}
                        >
                            {el.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small">
                <InputLabel id="base-route-label">Цвет кнопки</InputLabel>
                <Select
                    labelId="base-route-label"
                    label="Цвет кнопки"
                    value={$bgColor || ''}
                >
                    {colors.map((el, i) => (
                        <MenuItem
                            key={`menu_item_${i}`}
                            value={el.value}
                            onClick={() => onColorChange(el.value)}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <ButtonColorBadge $bgColor={el.value} />
                                <span>{el.title}</span>
                            </Stack>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small">
                <InputLabel id="base-route-label">Тип кнопки</InputLabel>
                <Select
                    labelId="base-route-label"
                    label="Тип ссылки"
                    value={linkType || ''}
                >
                    {types.map((el, i) => (
                        <MenuItem
                            key={`menu_item_${i}`}
                            value={el.value}
                            onClick={() => onLinkTypeChange(el.value)}
                        >
                            {el.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {linkType === 'internal' ? (
                <InternalPageSelector
                    value={hrefUuid}
                    onChange={(v) =>
                        onChange({
                            component: 'basicButton',
                            props: {
                                ...node?.props,
                                href: undefined,
                                hrefUuid: v,
                            },
                        })
                    }
                />
            ) : undefined}
            {linkType === 'phone' ? (
                <FormControl variant="outlined">
                    <TextField
                        label="Введите телефон"
                        size="small"
                        fullWidth
                        value={href.slice(5) || ''}
                        onChange={onPhoneHrefChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    +7
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            ) : undefined}
            {linkType === 'external' ? (
                <FormControl variant="outlined">
                    <TextField
                        label="Внешний ресурс"
                        size="small"
                        fullWidth
                        value={href || ''}
                        onChange={onLinkHrefChange}
                    />
                </FormControl>
            ) : undefined}
            {linkType === 'image' || linkType === 'document' ? (
                <Stack spacing={1}>
                    <FileUploadControl
                        accept={linkType === 'image' ? 'images' : 'files'}
                        onSuccess={(file) =>
                            onChange({
                                component: 'basicButton',
                                props: {
                                    ...node?.props,
                                    href: file,
                                },
                            })
                        }
                        id={pageID?.toString()}
                    />
                    <Stack direction="row" justifyContent="space-between">
                        <Typography
                            variant="caption.small"
                            $color="common.grey"
                        >
                            {href ? 'Файл загружен' : 'Файл не загружен'}
                        </Typography>
                        {href ? (
                            <Link
                                href={href}
                                target="_blank"
                                rel="noopener noreferrer"
                                fontSize={12}
                            >
                                Предпросмотр
                            </Link>
                        ) : undefined}
                    </Stack>
                </Stack>
            ) : undefined}
        </Stack>
    );
};
