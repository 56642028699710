import { useEffect, useState } from 'react';

import useAxios from 'hooks/api/useAxios';
import useLocalStorage from 'hooks/useLocalStorage';
import { QuizProgress } from 'types/builder/quizConfig';

export const useSessionStorage = (): void => {
    const [progress] = useLocalStorage<QuizProgress>('info67quizProgress', {});

    const { character } = progress;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [body, setBody] = useState<any>({});

    const [, { rerun }] = useAxios(
        {
            url: '/api/account/session/storage/',
            method: 'POST',
            data: {
                data: body,
            },
            skipRequest: () => true,
        },
        [body]
    );

    useEffect(() => {
        if (character) {
            setBody({
                avatar: character,
            });
            rerun();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [character]);
};
