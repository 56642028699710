import manAdultV2 from './man-adult-v2.png';
import manGrandV2 from './man-grand-v2.png';
import manTeenV2 from './man-teen-v2.png';
import manAndWoman from './man-woman-together.png';
import womanAdultV2 from './woman-adult-v2.png';
import womanGrandV2 from './woman-grand-v2.png';
import womanTeenV2 from './woman-teen-v2.png';

export const SHAPES_HUMANS_MAP = {
    manAdultV2,
    manGrandV2,
    manTeenV2,
    manAndWoman,
    womanAdultV2,
    womanGrandV2,
    womanTeenV2,
};
