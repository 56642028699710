import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useUndoRedo, UseUndoRedoReturn } from 'hooks/useUndoRedo';
import { setStateConfig } from 'store/slices/pointBuilderSlice';
import { SelectedLocation } from 'types/map/location';

import { useSelectedPoint } from './useSelectedPoint';

export const usePointsUndoRedo = (): UseUndoRedoReturn<
    SelectedLocation | undefined
> => {
    const dispatch = useDispatch();

    const { selectedPointData, selectedPointId } = useSelectedPoint();

    const state = useUndoRedo(selectedPointData);

    useEffect(() => {
        dispatch(setStateConfig(state[0]));
    }, [dispatch, selectedPointId, state]);

    return state;
};
