import { useMemo } from 'react';

import ApartmentIcon from '@mui/icons-material/Apartment';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
} from '@mui/material';
import { AnimatePresence, Variants } from 'framer-motion';

import { Typography } from 'components/atoms/Typography';
import { useLocationUrl } from 'hooks/api/map/useLocationUrl';
import { useMeta } from 'hooks/api/useMeta';
import { SelectedLocation } from 'types/map/location';

import {
    LocationInfoMotion,
    LocationInfoWrapper,
} from './LocationViewControl.styled';

const LocationInfoVariants: Variants = {
    open: {
        y: 0,
        transition: {
            delay: 0.3,
        },
    },
    closed: {
        y: '200%',
    },
};

type LocationViewControlProps = {
    selectedLocation?: SelectedLocation;
    onClearSelectedLocation?: () => void;
    onSearchPaneOpen?: () => void;
    onRequestRoute?: () => void;
};

export const LocationViewControl = ({
    selectedLocation,
    onClearSelectedLocation = () => undefined,
    onSearchPaneOpen = () => undefined,
    onRequestRoute = () => undefined,
}: LocationViewControlProps): JSX.Element => {
    const { meta: locations } = useMeta('guestLocations');
    const { meta: buildings } = useMeta('guestBuildings');
    const { meta: floors } = useMeta('guestFloors');

    const loading = useMemo(() => {
        return !locations || !floors || !buildings;
    }, [locations, floors, buildings]);

    const { location } = useLocationUrl();

    return (
        <Stack sx={{ flex: 1 }}>
            <TextField
                placeholder="Поиск"
                size="small"
                fullWidth
                value={selectedLocation?.name || ''}
                disabled={loading}
                onFocus={onSearchPaneOpen}
                InputProps={{
                    style: {
                        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
                    },
                    startAdornment: (
                        <InputAdornment position="start">
                            {loading ? (
                                <IconButton
                                    size="small"
                                    sx={{
                                        m: -1,
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <CircularProgress
                                        size={21}
                                        sx={{ p: 0.2 }}
                                        variant="indeterminate"
                                        color="inherit"
                                    />
                                </IconButton>
                            ) : (
                                <IconButton
                                    size="small"
                                    sx={{
                                        m: -1,
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end" id="close-btn">
                            {selectedLocation ? (
                                <IconButton
                                    size="small"
                                    sx={{ m: -1 }}
                                    onClick={onClearSelectedLocation}
                                    id="close-btn"
                                >
                                    <CancelOutlinedIcon
                                        sx={{ pointerEvents: 'none' }}
                                    />
                                </IconButton>
                            ) : undefined}
                        </InputAdornment>
                    ),
                }}
            />
            <AnimatePresence exitBeforeEnter>
                {selectedLocation ? (
                    <LocationInfoMotion
                        key={selectedLocation.id}
                        variants={LocationInfoVariants}
                        initial="closed"
                        animate="open"
                        exit="closed"
                    >
                        <LocationInfoWrapper>
                            <div id="tooltip" />
                            <div className="location-header">
                                <LocationOnIcon />
                                <Typography variant="title.medium">
                                    {selectedLocation.name}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    endIcon={<DirectionsWalkIcon />}
                                    onClick={() => onRequestRoute()}
                                >
                                    {location
                                        ? 'Начать маршрут'
                                        : 'Пройти сюда'}
                                </Button>
                            </div>
                            <Typography>
                                <ApartmentIcon />
                                {`${selectedLocation.building?.name} > ${selectedLocation.floor?.floor_number} этаж`}
                            </Typography>
                        </LocationInfoWrapper>
                    </LocationInfoMotion>
                ) : undefined}
            </AnimatePresence>
        </Stack>
    );
};
