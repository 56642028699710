import { ChangeEventHandler, useEffect } from 'react';

import { FormControl, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

import { Spacer } from 'components/atoms/Grid';
import { Typography } from 'components/atoms/Typography';
import { useAppDispatch } from 'hooks/store/useAppStore';
import { setError } from 'store/slices/pageBuilderSlice';
import { QuizQuestion } from 'types/builder/quizConfig';

type QuizTitleControlProps = {
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
};

export const QuizTitleControl = ({
    onChange = () => undefined,
    value,
}: QuizTitleControlProps): JSX.Element => {
    const {
        register,
        formState: { errors },
        resetField,
        trigger,
    } = useForm<Pick<QuizQuestion, 'questionTitle'>>({
        mode: 'all',
    });

    useEffect(() => {
        trigger();
    }, [value, trigger]);

    useEffect(() => {
        resetField('questionTitle', { defaultValue: value });
    }, [value, resetField, trigger]);

    const hasErrors = !!Object.keys(errors).length;

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setError({ name: 'quizSettings', error: hasErrors }));
    }, [hasErrors, dispatch]);

    return (
        <FormControl variant="outlined">
            <TextField
                label="Заголовок вопроса"
                size="small"
                fullWidth
                {...register('questionTitle', {
                    required: {
                        value: true,
                        message: 'Введите заголовок вопроса',
                    },
                    onChange,
                })}
                autoFocus
                error={!!errors.questionTitle}
                value={value || ''}
            />
            {errors.questionTitle?.message ? (
                <>
                    <Spacer size="1x" />
                    <Typography $color="red" variant="body.small">
                        {errors.questionTitle?.message}
                    </Typography>
                </>
            ) : undefined}
        </FormControl>
    );
};
