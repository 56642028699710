import { Key, ReactNode, useEffect, useMemo, useState } from 'react';

import PublishIcon from '@mui/icons-material/Publish';
import { Box, Tooltip } from '@mui/material';
import { Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';

import { onSelectFunc } from 'components/organisms/PageEditor/editorControls/NodeTree/types';
import {
    findParentKeys,
    getParentKey,
} from 'components/organisms/PageEditor/editorControls/NodeTree/utils';
import { Building } from 'types/api/building';
import { Floor } from 'types/api/floor';
import { SelectedLocation } from 'types/map/location';
import { highlightInText } from 'utils/highlightInText';

type NodeWithProps<T> = DataNode & {
    published?: boolean;
    children?: NodeWithProps<T>[];
    title?: T;
};

type BuildingWithLocations = {
    building: Building;
    locations: SelectedLocation[];
};
type FloorWithLocations = { floor: Floor; locations: SelectedLocation[] };

const getTreeData = (
    items: SelectedLocation[],
    selected?: number,
    disabled?: boolean
): NodeWithProps<string>[] => {
    if (items && items.length > 0) {
        const locationsByBuildings = Object.entries(
            items.reduce((result, item) => {
                const value = item.building?.id;

                const res = result as {
                    [key: number]: BuildingWithLocations;
                };

                if (value) {
                    const existing = res[value] || {
                        building: item.building,
                        locations: [],
                    };
                    return {
                        ...res,
                        [value]: {
                            ...existing,
                            locations: [...existing.locations, item],
                        },
                    };
                }
                return {};
            }, {})
        ) as [string, BuildingWithLocations][];

        const sortedLocations = locationsByBuildings.map(
            ([, { building, locations }]) => {
                const locationsByFloor = Object.entries(
                    locations.reduce((result, item) => {
                        const value = item.floor?.id;

                        const res = result as {
                            [key: number]: FloorWithLocations;
                        };

                        if (value) {
                            const existing = res[value] || {
                                floor: item.floor,
                                locations: [],
                            };
                            return {
                                ...res,
                                [value]: {
                                    ...existing,
                                    locations: [...existing.locations, item],
                                },
                            };
                        }
                        return {};
                    }, {})
                ) as [string, FloorWithLocations][];

                return {
                    ...building,
                    floors: locationsByFloor.map(
                        ([, { floor, locations: floor_locations }]) => ({
                            ...floor,
                            locations: floor_locations,
                        })
                    ),
                };
            }
        );

        return sortedLocations.map((building) => ({
            key: `building_${building.id}`,
            title: building.name,
            selectable: false,
            children: building.floors.map((floor) => {
                return {
                    key: `floor_${floor.id}`,
                    title: `${floor.floor_number} этаж`,
                    selectable: false,
                    children: floor.locations.map((location) => {
                        const { id, name, is_published } = location;

                        return {
                            key: id,
                            title: name,
                            published: is_published,
                            disabled: disabled && selected !== id,
                        };
                    }),
                };
            }),
        }));

        return [];
    }
    return [];
};

type PoiSelectTreeProps = {
    locations: SelectedLocation[];
    selectedLocation?: number;
    onSelect: (location?: SelectedLocation) => void;
    selectDisabled: boolean;
    searchValue?: string;
    height?: number;
};

type LocationTitleWrapperProps = {
    published?: boolean;
    children?: ReactNode;
    disabled?: boolean;
};

const LocationTitleWrapper = ({
    disabled,
    published,
    children,
}: LocationTitleWrapperProps): JSX.Element => {
    return (
        <Box>
            <Box sx={{ mr: 3, py: 0.2, px: 0.5 }}>{children}</Box>
            {published ? (
                <Tooltip
                    arrow
                    placement="right"
                    title={disabled ? '' : 'Точка опубликована'}
                    enterDelay={2000}
                >
                    <PublishIcon
                        fontSize="small"
                        color="action"
                        sx={{
                            position: 'absolute',
                            right: '4px',
                            zIndex: 99,
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    />
                </Tooltip>
            ) : undefined}
        </Box>
    );
};

export const PoiSelectTree = ({
    locations,
    onSelect,
    selectDisabled,
    searchValue = '',
    selectedLocation,
    height,
}: PoiSelectTreeProps): JSX.Element => {
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    const onExpand = (newExpandedKeys: Key[]): void => {
        setExpandedKeys(Array.from(new Set([...newExpandedKeys])));
        setAutoExpandParent(false);
    };

    const onNodeSelect: onSelectFunc = (keys): void => {
        if (keys.length === 1) {
            onSelect(locations.find((el) => el.id === Number(keys[0])));
        }
    };

    const locationsTree = useMemo(() => {
        return getTreeData(locations, selectedLocation, selectDisabled);
    }, [locations, selectDisabled, selectedLocation]);

    useEffect(() => {
        if (searchValue !== '') {
            const newExpandedKeys = locations
                .map((item) => {
                    if (
                        item.name
                            .toLowerCase()
                            .indexOf(searchValue.toLowerCase()) > -1
                    ) {
                        return getParentKey(item.id, locationsTree);
                    }
                    return null;
                })
                .filter(
                    (item, i, self) => item && self.indexOf(item) === i
                ) as Key[];

            setExpandedKeys(Array.from(new Set([...newExpandedKeys])));
        } else {
            setExpandedKeys([]);
        }
        if (selectedLocation) {
            const newExpandedKeys = findParentKeys(
                selectedLocation,
                locationsTree
            );

            setExpandedKeys((keys) =>
                Array.from(new Set([...keys, ...newExpandedKeys]))
            );
        }
        setAutoExpandParent(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, selectedLocation, locationsTree]);

    const treeData = useMemo(() => {
        const loop = (
            data: NodeWithProps<string>[]
        ): NodeWithProps<ReactNode>[] =>
            data.map((item) => {
                const title = highlightInText(
                    item.title as string,
                    searchValue
                );

                if (item.children) {
                    return {
                        ...item,
                        title: (
                            <LocationTitleWrapper
                                disabled={item.disabled}
                                published={item.published}
                            >
                                {title.element}
                            </LocationTitleWrapper>
                        ),
                        className: title.match ? 'indexed' : undefined,
                        children: loop(item.children),
                    };
                }

                return {
                    ...item,
                    className: title.match ? 'indexed' : undefined,
                    title: (
                        <LocationTitleWrapper
                            disabled={item.disabled}
                            published={item.published}
                        >
                            {title.element}
                        </LocationTitleWrapper>
                    ),
                };
            });

        return loop(locationsTree);
    }, [locationsTree, searchValue]);

    const onNodeClick = (key: Key): void => {
        if (typeof key !== 'number') {
            if (expandedKeys.includes(key)) {
                setExpandedKeys((keys) => keys.filter((el) => el !== key));
            } else {
                setExpandedKeys((keys) => Array.from(new Set([...keys, key])));
            }
        }
    };

    return (
        <Tree
            blockNode
            treeData={treeData}
            selectedKeys={selectedLocation ? [selectedLocation] : undefined}
            onSelect={onNodeSelect}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onExpand={onExpand}
            height={height ? height - 28 : undefined}
            onClick={(e, node) => onNodeClick(node.key)}
        />
    );
};
