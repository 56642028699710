import { useMemo } from 'react';

import { Navigate } from 'react-router-dom';

import { SplashScreen } from 'components/pages/Splash';
import { useUser } from 'hooks/api/auth/useUser';
import { useStaffData } from 'hooks/api/staff/useStaffData';

export const UserRoleNavigator = (): JSX.Element => {
    const { user } = useUser();
    const { roles } = useStaffData();

    const path = useMemo(() => {
        if (user && roles) {
            const firstRole = roles.find((el) => el.id === user.roles[0]);

            return firstRole?.name;
        }
        return undefined;
    }, [roles, user]);

    return path ? <Navigate to={path} replace /> : <SplashScreen isLoading />;
};
