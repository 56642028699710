/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { memo, useCallback, useEffect, useState } from 'react';

import {
    ReactZoomPanPinchRef,
    TransformWrapper,
} from '@kokarn/react-zoom-pan-pinch';
import { CircularProgress, Stack } from '@mui/material';

import { useAppSelector } from 'hooks/store/useAppStore';
import { UseUndoRedoReturn } from 'hooks/useUndoRedo';

import { NodeHighlighter } from '../NodeHighlighter';
import {
    PagePreviewContainer,
    PreviewToolbar,
} from './PagePreviewControl.styled';
import { PreviewControls } from './PreviewControls';
import { PreviewWindow } from './PreviewWindow';
import { PreviewZoomPanControl } from './PreviewZoomPanControl';
import { PreviewSizes } from './types';

type PagePreviewControlProps = {
    undoRedoActions: UseUndoRedoReturn['2'];
    loading?: boolean;
    error?: boolean;
};

const PagePreviewControl = ({
    undoRedoActions,
    loading,
    error,
}: PagePreviewControlProps): JSX.Element | null => {
    const [previewSize, setPreviewSize] = useState<PreviewSizes>('phone');
    const [showBorder, setShowBorder] = useState(true);

    const { selectedNode } = useAppSelector((state) => state.pageBuilder);

    const [vwScale, setVwScale] = useState(0);

    const [zoomInstance, setZoomInstance] = useState<ReactZoomPanPinchRef>();

    const resizeHandler = useCallback((): void => {
        const range = 1 / 1260;
        const vw = range * Math.min(window.innerWidth, window.innerHeight);
        setVwScale(vw);
    }, []);

    useEffect(() => {
        resizeHandler();

        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [resizeHandler]);

    useEffect(() => {
        if (zoomInstance) {
            zoomInstance.centerView(undefined, 0);
        }
    }, [vwScale, zoomInstance, previewSize]);

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [previewSize]);

    const preview = useAppSelector((state) => state.pageBuilder.config);

    if (loading) {
        return (
            <Stack alignItems="center" justifyContent="center" sx={{ flex: 1 }}>
                <CircularProgress variant="indeterminate" />
            </Stack>
        );
    }

    if (!loading && error) {
        return (
            <Stack alignItems="center" justifyContent="center" sx={{ flex: 1 }}>
                При загрузке страницы что-то пошло не так...
            </Stack>
        );
    }

    return preview ? (
        <PagePreviewContainer>
            <PreviewToolbar>
                <PreviewControls
                    onPreviewSizeChange={setPreviewSize}
                    onShowBorderChange={setShowBorder}
                    previewSize={previewSize}
                    showBorder={showBorder}
                    undoRedoActions={undoRedoActions}
                />
            </PreviewToolbar>
            {vwScale ? (
                <TransformWrapper
                    minScale={0.5}
                    maxScale={2}
                    limitToBounds={false}
                    initialScale={vwScale}
                    onInit={setZoomInstance}
                    doubleClick={{
                        disabled: true,
                    }}
                    wheel={{
                        disabled: true,
                    }}
                    pinch={{
                        disabled: true,
                    }}
                >
                    <PreviewWindow
                        preview={preview}
                        previewSize={previewSize}
                        showBorder={showBorder}
                    />
                </TransformWrapper>
            ) : undefined}
            {zoomInstance ? (
                <PreviewZoomPanControl zoomInstance={zoomInstance} />
            ) : undefined}
            <NodeHighlighter
                selectedNode={selectedNode?._key}
                key={JSON.stringify(preview)}
            />
        </PagePreviewContainer>
    ) : null;
};

export const EditorPagePreviewControl = memo(PagePreviewControl);
