import { Typography } from 'components/atoms/Typography';
import { ButtonWithIcon } from 'components/molecules/Buttons/ButtonWithIcon';
import { HumanAvatar } from 'components/molecules/HumanAvatar';
import { QuizQuestion as QuizQuestionType } from 'types/builder/quizConfig';

import {
    QuestionBlockGrid,
    QuestionBlockWrapper,
    QuizQuestionWrapper,
    QuizTitleAndAvatar,
} from './QuizQuestion.styled';

export interface QuizQuestionProps {
    config: QuizQuestionType;
}

export const QuizQuestion = ({ config }: QuizQuestionProps): JSX.Element => {
    const { questionTitle, questions, options } = config || {};

    const {
        avatarSize = 'large',
        titleSize = 'large',
        questionButtonSize = 'lg',
    } = options || {};

    return (
        <QuizQuestionWrapper>
            <QuizTitleAndAvatar>
                <Typography variant={`headline.${titleSize}`}>
                    {questionTitle}
                </Typography>
                <HumanAvatar size={avatarSize} />
            </QuizTitleAndAvatar>
            <QuestionBlockWrapper data-avatar-size={avatarSize}>
                <QuestionBlockGrid>
                    {questions.map((el, i) => (
                        <div key={`question_option_${i}`}>
                            <ButtonWithIcon
                                size={questionButtonSize}
                                icon={el.icon}
                                href={el.href}
                                hrefUuid={el.hrefUuid}
                                linkType={
                                    el.isExternal ? 'external' : 'internal'
                                }
                            >
                                {el.title}
                            </ButtonWithIcon>
                        </div>
                    ))}
                </QuestionBlockGrid>
            </QuestionBlockWrapper>
        </QuizQuestionWrapper>
    );
};
