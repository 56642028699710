import styled from 'styled-components';

export const AdminLayoutWrapper = styled.main`
    display: flex;
    flex-direction: column;

    flex: 1;

    background-color: ${({ theme }) => theme.palette.grey.bg2};

    overflow-y: auto;
`;

export const AdminLayoutContainer = styled.div`
    flex: 1;
    max-width: 1256px;
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0 16px;
`;
