import { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export type UseGuestChatModalReturn = {
    isOpen: boolean;
    onClose: () => void;
};

export const useGuestChatModal = (): UseGuestChatModalReturn => {
    const [isOpen, setIsOpen] = useState(false);

    const [params] = useSearchParams();

    const [prevTitle, setPrevTitle] = useState(document.title);

    const chatIsOpen = params.get('chat');

    useEffect(() => {
        const open = chatIsOpen === 'open';

        if (open) {
            setPrevTitle(document.title);
            document.title = 'Чат | Инфопомощник 67';
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
            document.title = prevTitle;
        }
        setIsOpen(open);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatIsOpen]);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const onClose = useCallback(() => {
        navigate(pathname, {
            replace: true,
        });
    }, [navigate, pathname]);

    return { isOpen, onClose };
};
