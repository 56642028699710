import { ChangeEventHandler } from 'react';

import { Typography } from 'components/atoms/Typography';
import { useBuilderToolbars } from 'hooks/api/builder/pages/usePageBuilder';
import { QuizOptions, QuizQuestion } from 'types/builder/quizConfig';

import { AvatarSizeControl } from './controls/AvatarSizeControl';
import { ButtonSizeControl } from './controls/ButtonSizeControl';
import { QuizOptionsDropDown } from './controls/DropDown';
import { OptionsControl } from './controls/OptionsControl';
import { QuizTitleControl } from './controls/QuizTitleControl';
import { TitleSizeControl } from './controls/TitleSizeControl';

export type PageSettingsControlProps = {
    config?: QuizQuestion;
    onChange: (config: QuizQuestion) => void;
};

export const QuizSettingsControl = ({
    config,
    onChange,
}: PageSettingsControlProps): JSX.Element => {
    const { questions = [], options, questionTitle = '' } = config || {};

    const { setActiveToolBar, toolbars } = useBuilderToolbars();

    const {
        questionButtonSize = 'lg',
        avatarSize = 'large',
        titleSize = 'large',
    } = options || {};

    const onButtonSizeChange = (
        size: QuizOptions['questionButtonSize']
    ): void => {
        onChange({
            ...config,
            questions: config?.questions || [],
            options: {
                ...config?.options,
                questionButtonSize: size,
            },
        });
    };
    const onAvatarSizeChange = (size: QuizOptions['avatarSize']): void => {
        onChange({
            ...config,
            questions: config?.questions || [],
            options: {
                ...config?.options,
                avatarSize: size,
            },
        });
    };
    const onTitleSizeChange = (size: QuizOptions['titleSize']): void => {
        onChange({
            ...config,
            questions: config?.questions || [],
            options: {
                ...config?.options,
                titleSize: size,
            },
        });
    };

    const onQuizTitleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;
        onChange({
            ...config,
            questions: config?.questions || [],
            questionTitle: value,
        });
    };
    const onQuizOptionsChange = (opt: QuizQuestion['questions']): void => {
        onChange({
            ...config,
            questions: opt,
        });
    };

    return (
        <QuizOptionsDropDown
            expanded={toolbars.right === 'quizSettings'}
            onExpand={(v) => setActiveToolBar('quizSettings', v)}
        >
            <Typography variant="title.small">Основные</Typography>
            <QuizTitleControl
                value={questionTitle}
                onChange={onQuizTitleChange}
            />
            <TitleSizeControl value={titleSize} onChange={onTitleSizeChange} />
            <AvatarSizeControl
                value={avatarSize}
                onChange={onAvatarSizeChange}
            />
            <ButtonSizeControl
                value={questionButtonSize}
                onChange={onButtonSizeChange}
            />
            <Typography variant="title.small">Опции</Typography>
            <OptionsControl
                options={questions}
                onChange={onQuizOptionsChange}
            />
        </QuizOptionsDropDown>
    );
};
