export const highlightInText = (
    value: string,
    searchValue: string
): {
    element: JSX.Element;
    match: boolean;
} => {
    const strTitle = value;
    const index = searchValue
        ? strTitle.toLowerCase().indexOf(searchValue.toLowerCase())
        : -1;
    const beforeStr = strTitle.substring(0, index);
    const betweenStr = strTitle.substring(index, index + searchValue.length);
    const afterStr = strTitle.slice(index + searchValue.length);
    const title =
        index > -1 ? (
            <span>
                {beforeStr}
                <span className="site-tree-search-value">{betweenStr}</span>
                {afterStr}
            </span>
        ) : (
            <span>{strTitle}</span>
        );

    return {
        match: index > -1,
        element: title,
    };
};
