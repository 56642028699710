import { useEffect, useState } from 'react';

import useAxios from 'hooks/api/useAxios';
import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { setUsers, setRoles, setUsersFilter } from 'store/slices/usersSlice';
import { RoleList } from 'types/api/roleList';
import { UserList } from 'types/api/userList';

interface IUseStaffDataReturn {
    users: UserList[] | undefined;
    roles: RoleList[] | undefined;
    usersFilter: UserList[] | undefined;

    setAllUsers: (users: UserList[]) => void;
    setAllRoles: (roles: RoleList[]) => void;
}

export const useStaffData = (): IUseStaffDataReturn => {
    const users = useAppSelector((state) => state.users.users);
    const roles = useAppSelector((state) => state.users.roles);
    const usersFilter = useAppSelector((state) => state.users.usersFilter);

    const dispatch = useAppDispatch();

    const setAllUsers = (allUsers: UserList[]): void => {
        dispatch(setUsers(allUsers));
        dispatch(setUsersFilter());
    };
    const setAllRoles = (allRoles: RoleList[]): void => {
        dispatch(setRoles(allRoles));
        dispatch(setUsersFilter());
    };

    return {
        setAllUsers,
        setAllRoles,
        users,
        roles,
        usersFilter,
    };
};

interface IUseStaffReturn {
    users: UserList[] | undefined;
    getUsers: () => void;
}
export const useStaff = (): IUseStaffReturn => {
    const [res, { rerun }] = useAxios<UserList[]>(
        {
            url: `/api/admin/staff/`,
            method: 'GET',
            skipRequest: () => true,
        },
        []
    );
    const [users, setNewUsers] = useState<UserList[]>();

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (res.type === 'success') {
            setNewUsers(res.data);
            dispatch(setUsers(users));
            dispatch(setUsersFilter());
        } else {
            setNewUsers(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [res.type, users]);

    const getUsers = (): void => {
        rerun();
    };

    return { users, getUsers };
};

interface IUseRolesReturn {
    roles: RoleList[] | undefined;
    getRoles: () => void;
}
export const useRoles = (): IUseRolesReturn => {
    const [res, { rerun }] = useAxios<RoleList[]>(
        {
            url: `/api/admin/role/`,
            method: 'GET',
            skipRequest: () => true,
        },
        []
    );
    const [roles, setNewRoles] = useState<RoleList[]>();

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (res.type === 'success') {
            setNewRoles(res.data);
            dispatch(setRoles(roles));
            dispatch(setUsersFilter());
        } else {
            setNewRoles(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [res.type, roles]);

    const getRoles = (): void => {
        rerun();
    };
    return { roles, getRoles };
};
