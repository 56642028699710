import { useCallback, useEffect } from 'react';

import { AxiosError } from 'axios';

import useAxios from 'hooks/api/useAxios';
import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import {
    setAuthChecked as setAuthCheckedAction,
    setAuthenticated as setAuthenticatedAction,
    setAuthLoading as setAuthLoadingAction,
} from 'store/slices/authSlice';
import { User } from 'types/api/user';

import { useUser } from './useUser';

type UseIsAuthenticatedReturn = {
    checkAuthStatus: () => void;
    setAuthenticated: (val: boolean) => void;
    loading: boolean;
    authChecked: boolean;
    error?: AxiosError<unknown, unknown>;
    authenticated: boolean;
};

export const useIsAuthenticated = (): UseIsAuthenticatedReturn => {
    const dispatch = useAppDispatch();

    const { authChecked, authLoading, authenticated } = useAppSelector(
        (state) => state.auth.value
    );

    const { setUser } = useUser();

    const setAuthChecked = useCallback(
        (val: boolean) => {
            dispatch(setAuthCheckedAction(val));
        },
        [dispatch]
    );
    const setAuthenticated = useCallback(
        (val: boolean) => {
            dispatch(setAuthenticatedAction(val));
        },
        [dispatch]
    );
    const setAuthLoading = useCallback(
        (val: boolean) => {
            dispatch(setAuthLoadingAction(val));
        },
        [dispatch]
    );

    const [res, { rerun }] = useAxios<User>(
        {
            url: '/api/account/sign/',
            method: 'GET',
            skipRequest: () => true,
        },
        []
    );

    const checkAuthStatus = useCallback(() => {
        if (!authChecked && !authLoading && !authenticated) {
            rerun();
        }
    }, [authChecked, authLoading, authenticated, rerun]);

    const error = res.type === 'error' ? res.data : undefined;
    const loading = authenticated ? false : res.type === 'loading';
    const success = res.type === 'success' || authenticated;
    const response = res.type === 'success' && !loading ? res.data : undefined;

    useEffect(() => {
        setAuthLoading(loading);
    }, [loading, setAuthLoading]);

    useEffect(() => {
        if (success || error) {
            setAuthChecked(true);
        }
    }, [error, success, setAuthChecked]);

    useEffect(() => {
        if (success) {
            setAuthenticated(true);
            if (response) {
                setUser({
                    id: response.id,
                    username: response.username,
                    integrations: {
                        telegram: response.integrations?.telegram,
                    },
                    email: response.email,
                    roles: response.roles,
                });
            }
        } else {
            setAuthenticated(false);
        }
    }, [setAuthenticated, setUser, success, response]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (
                error?.response &&
                error.response?.status >= 500 &&
                !authLoading &&
                !authenticated
            ) {
                rerun();
            }
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
    }, [authLoading, authenticated, error, rerun]);

    return {
        checkAuthStatus,
        setAuthenticated,
        loading: authLoading,
        authChecked,
        error,
        authenticated,
    };
};
