import { memo } from 'react';

import { TransformComponent } from '@kokarn/react-zoom-pan-pinch';

import { ContentWrapper } from 'components/atoms/Grid';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { GoBackLink } from 'components/molecules/Links/GoBackLink';
import { NAVBAR_SEGMENTS } from 'components/templates/layouts/PageLayout/NavigationBar';
import {
    NavBarSegmentsWrapper,
    NavBarSegment,
} from 'components/templates/layouts/PageLayout/NavigationBar/NavigationBar.styled';
import { PageTemplate } from 'components/templates/PageTemplate';
import { useMeta } from 'hooks/api/useMeta';
import { PageMapConfig } from 'types/builder/pageConfig';

import { PreviewSizes } from '../types';
import {
    HeaderWrapper,
    HeaderContent,
    SupportBtnPlaceholder,
    FooterWrapper,
    PreviewWindowPhone,
} from './PreviewWindow.styled';

type PreviewWindowProps = {
    preview: PageMapConfig;
    showBorder: boolean;
    previewSize: PreviewSizes;
};

const MemoPreview = memo(PageTemplate);

export const PreviewWindow = ({
    preview,
    previewSize,
    showBorder,
}: PreviewWindowProps): JSX.Element => {
    const {
        showBottomNavLink = true,
        showNavBar = true,
        showTopAppBar = true,
        showChatButton = true,
        pageTitle,
        baseRoute,
    } = preview || {};

    const { meta } = useMeta('pageList');

    const route = meta?.find((el) => el.uuid === baseRoute);

    const firstSegment = route?.href?.split('/').filter((e) => !!e)[0];

    return (
        <TransformComponent>
            <PreviewWindowPhone
                data-size={previewSize}
                data-hide-border={!showBorder}
                data-header={showTopAppBar}
                data-footer={showNavBar}
            >
                <HeaderWrapper>
                    {showTopAppBar ? (
                        <ContentWrapper>
                            <HeaderContent>
                                <div>
                                    <SVGAsset src="icons.24px.chevron" />
                                    <Typography variant="title.medium">
                                        {pageTitle}
                                    </Typography>
                                </div>
                                <SVGAsset src="logos.logo67" />
                            </HeaderContent>
                        </ContentWrapper>
                    ) : undefined}
                </HeaderWrapper>
                <ContentWrapper>
                    <MemoPreview page={preview} previewMode />
                </ContentWrapper>
                {showBottomNavLink ? (
                    <GoBackLink disabled />
                ) : (
                    <div style={{ paddingTop: '80px' }} />
                )}
                {showChatButton ? (
                    <SupportBtnPlaceholder>
                        <SVGAsset src="icons.24px.messageCircle" />
                    </SupportBtnPlaceholder>
                ) : undefined}
                {showNavBar ? (
                    <FooterWrapper>
                        <ContentWrapper>
                            <NavBarSegmentsWrapper>
                                {NAVBAR_SEGMENTS.map((el, i) => (
                                    <li key={`navbar_segment_${i}`}>
                                        <NavBarSegment
                                            as="div"
                                            data-active={
                                                `/${firstSegment}` === el.href
                                            }
                                        >
                                            <span className="svg-color-inherit">
                                                {el.icon}
                                            </span>
                                            <Typography variant="label.medium">
                                                {el.title}
                                            </Typography>
                                        </NavBarSegment>
                                    </li>
                                ))}
                            </NavBarSegmentsWrapper>
                        </ContentWrapper>
                    </FooterWrapper>
                ) : undefined}
            </PreviewWindowPhone>
        </TransformComponent>
    );
};
