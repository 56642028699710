/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SelectedLocation } from 'types/map/location';

interface PointBuilderState {
    points: {
        list?: SelectedLocation[];
        loading: boolean;
        error: boolean;
    };
    location: {
        selected?: number;
        data?: SelectedLocation;
    };
    config?: SelectedLocation;
    newLocation: {
        mode?: 'new' | 'update';
        location?: Partial<SelectedLocation>;
    };
}

const initialState: PointBuilderState = {
    points: {
        list: undefined,
        error: false,
        loading: false,
    },
    location: {
        data: undefined,
        selected: undefined,
    },
    config: undefined,
    newLocation: {
        mode: undefined,
        location: undefined,
    },
};

export const pointBuilderSlice = createSlice({
    name: 'pointBuilder',
    initialState,
    reducers: {
        setStatePoints: (
            state,
            action: PayloadAction<Partial<PointBuilderState['points']>>
        ) => {
            state.points = {
                ...state.points,
                ...action.payload,
            };
        },
        setStateLocation: (
            state,
            action: PayloadAction<Partial<PointBuilderState['location']>>
        ) => {
            state.location = {
                ...state.location,
                ...action.payload,
            };
        },
        setStateConfig: (
            state,
            action: PayloadAction<PointBuilderState['config']>
        ) => {
            state.config = action.payload;
        },
        setStateNewLocation: (
            state,
            action: PayloadAction<PointBuilderState['newLocation']>
        ) => {
            state.newLocation = {
                ...state.newLocation,
                ...action.payload,
            };
        },
    },
});

export const {
    setStatePoints,
    setStateLocation,
    setStateConfig,
    setStateNewLocation,
} = pointBuilderSlice.actions;

export const pointBuilderSliceReducer = pointBuilderSlice.reducer;
