import { useState, useCallback } from 'react';

import useAxios from 'hooks/api/useAxios';
import {
    DirectionsQuery,
    DirectionsQueryResponse,
    Point,
} from 'types/map/2gis';

const directionsApiKey = process.env.REACT_APP_MAPGL_DIRECTIONS_KEY;

export type UseFetchDirectionsReturn = {
    fetchDirections: (points: Point[]) => void;
    loading: boolean;
    error: boolean;
    data?: DirectionsQueryResponse;
};

export const useFetchDirections = (): UseFetchDirectionsReturn => {
    const [query, setQuery] = useState<DirectionsQuery>();

    const [res, { rerun }] = useAxios<DirectionsQueryResponse>(
        {
            url: `https://catalog.api.2gis.ru/carrouting/6.0.0/global?key=${directionsApiKey}`,
            method: 'POST',
            data: query,
            skipRequest: () => true,
        },
        [query]
    );

    const fetchDirections = useCallback(
        (points: Point[]) => {
            setQuery({
                locale: 'ru',
                type: 'pedestrian',
                points,
                use_indoor: true,
                options: ['pedestrian_instructions'],
            });
            rerun();
        },
        [rerun]
    );

    const loading = res.type === 'loading';
    const error = res.type === 'error';
    const data = res.type === 'success' ? res.data : undefined;

    return {
        fetchDirections,
        loading,
        error,
        data,
    };
};
