import { ChangeEventHandler, useMemo, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, Stack, TextField } from '@mui/material';

import { Typography } from 'components/atoms/Typography';
import { PageListWrapper } from 'components/organisms/PageEditor/editorControls/PageSelectControl/PageSelectControl.styled';
import { useBuilderToolbars } from 'hooks/api/builder/pages/usePageBuilder';
import { usePointsList } from 'hooks/api/builder/points/usePointsList';
import { useSelectedPoint } from 'hooks/api/builder/points/useSelectedPoint';
import { useAppSelector } from 'hooks/store/useAppStore';
import useElementSize from 'hooks/utils/useElementSize';

import { ControlDropDown } from '../DropDown';
import { NewPointCreator } from './NewPointCreator';
import { PoiSelectTree } from './PoiSelectTree';
import { NodeTreeScrollContainer } from './PoiSelectTree.styled';

type PointSelectControlProps = {
    selectionBlocked?: boolean;
    onNewPointCreated: (id: number) => void;
};

export const PointSelectControl = ({
    selectionBlocked = false,
    onNewPointCreated,
}: PointSelectControlProps): JSX.Element => {
    const { setActiveToolBar, toolbars } = useBuilderToolbars();

    const [searchValue, setSearchValue] = useState('');

    const config = useAppSelector((s) => s.pointBuilder.config);

    const onSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        setSearchValue(value);
    };

    const [containerRef, { height }] = useElementSize();

    const { selectedPointId, setSelectedPoint } = useSelectedPoint();

    const { error, list: locationsList, loading } = usePointsList();

    const locations = useMemo(() => {
        return locationsList?.map((el) => {
            if (el.id === config?.id) {
                return {
                    ...config,
                };
            }
            return el;
        });
    }, [locationsList, config]);

    return (
        <ControlDropDown
            expanded={toolbars.left === 'mapPoints'}
            onExpand={(v) => setActiveToolBar('mapPoints', v)}
            title="Точки интереса"
            scrollContainer={false}
            loading={loading}
        >
            <Stack sx={{ flex: 1 }}>
                <Box sx={{ px: '28px', pb: '20px' }}>
                    <TextField
                        size="small"
                        variant="standard"
                        placeholder="Поиск"
                        value={searchValue}
                        onChange={onSearch}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ position: 'relative', flex: 1 }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            overflowY: 'auto',
                            p: '28px',
                            pt: 0,
                        }}
                    >
                        <PageListWrapper>
                            <NodeTreeScrollContainer ref={containerRef}>
                                {!loading && !error && locations ? (
                                    <PoiSelectTree
                                        locations={locations}
                                        searchValue={searchValue}
                                        onSelect={(loc) =>
                                            setSelectedPoint(loc?.id)
                                        }
                                        selectDisabled={selectionBlocked}
                                        height={height}
                                        selectedLocation={selectedPointId}
                                    />
                                ) : undefined}
                                {!loading && !error && !locations?.length ? (
                                    <Typography>
                                        На данный момент точки интереса
                                        отсутствуют
                                    </Typography>
                                ) : undefined}
                                {!loading && error && !locations?.length ? (
                                    <Typography>
                                        При загрузке точек интереса что-то пошло
                                        не так...
                                    </Typography>
                                ) : undefined}
                            </NodeTreeScrollContainer>
                        </PageListWrapper>
                    </Box>
                </Box>
                {!loading && !error && toolbars.left === 'mapPoints' ? (
                    <NewPointCreator
                        onSuccess={onNewPointCreated}
                        disabled={selectionBlocked}
                    />
                ) : undefined}
            </Stack>
        </ControlDropDown>
    );
};
