import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import useAxios from 'hooks/api/useAxios';
import { Location } from 'types/api/location';

type UseUpdatePointReturn = {
    success: boolean;
    error: boolean;
    loading: boolean;
    updatePoint: (point: Location) => void;
};

export const useUpdatePoint = (): UseUpdatePointReturn => {
    const [body, setBody] = useState<Location>();

    const { enqueueSnackbar } = useSnackbar();

    const [res, { rerun }] = useAxios<Location>(
        {
            url: `/api/builder/map/location/${body?.id}/`,
            method: 'PATCH',
            skipRequest: () => true,
            data: body,
        },
        [body]
    );

    const success = res.type === 'success';
    const error = res.type === 'error';
    const loading = res.type === 'loading';

    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                'Что-то пошло не так... Не удалось сохранить изменения.',
                {
                    variant: 'error',
                }
            );
        }
        if (success) {
            enqueueSnackbar('Изменения точки сохранены.', {
                variant: 'success',
            });
        }
    }, [error, success, enqueueSnackbar]);

    const updatePoint = (point: Location): void => {
        setBody(point);
        rerun();
    };

    return { success, error, loading, updatePoint };
};
