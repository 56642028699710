/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ExternalHrefState {
    value?: string;
}

const initialState: ExternalHrefState = {
    value: undefined,
};

export const externalHrefSlice = createSlice({
    name: 'externalHref',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<ExternalHrefState['value']>) => {
            state.value = action.payload;
        },
    },
});

export const { set } = externalHrefSlice.actions;

export const externalHrefSliceReducer = externalHrefSlice.reducer;
