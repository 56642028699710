import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { setSharedMeta } from 'store/slices/sharedMeta';
import { MetaNames, MetaURLs, SharedMeta } from 'types/sharedMeta';

import { useAxios } from './useAxios';

const META_URL: MetaURLs = {
    pageList: '/api/builder/page/',
    guestPageList: '/api/page/',
    staff: '/api/admin/staff/',
    guestFloors: '/api/map/floor/',
    guestLocations: '/api/map/location/',
    guestBuildings: '/api/map/building/',
    adminFloors: '/api/builder/map/floor/',
    adminLocations: '/api/builder/map/location/',
    adminBuildings: '/api/builder/map/building/',
};

type UseMetaReturn<T extends MetaNames> = {
    meta: SharedMeta[T];
    error: boolean;
    loading: boolean;
    refresh: () => void;
};

export const useMeta = <T extends MetaNames>(metaName: T): UseMetaReturn<T> => {
    const dispatch = useAppDispatch();

    const meta = useAppSelector((state) => state.sharedMeta.value);

    const [res, { rerun }] = useAxios<T>(
        {
            url: META_URL[metaName],
            method: 'GET',
            skipRequest: () => !!meta[metaName],
        },
        [meta, metaName]
    );

    useEffect(() => {
        if (res.type === 'success') {
            dispatch(setSharedMeta({ ...meta, [metaName]: res.data }));
        }
    }, [res, meta, metaName, dispatch]);

    return {
        meta: meta[metaName],
        error: res.type === 'error',
        loading: res.type === 'loading',
        refresh: rerun,
    };
};
