/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppTitleState {
    value: string;
}

const initialState: AppTitleState = {
    value: '',
};

export const appTitleSlice = createSlice({
    name: 'appTitle',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<AppTitleState['value']>) => {
            state.value = action.payload;
        },
    },
});

export const { set } = appTitleSlice.actions;

export const appTitleSliceReducer = appTitleSlice.reducer;
