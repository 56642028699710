import { useCallback, useEffect, useState } from 'react';

import { Map } from 'components/molecules/Map';
import { useLocationUrl } from 'hooks/api/map/useLocationUrl';
import useLocalStorage from 'hooks/useLocalStorage';
import { RoutingResult } from 'types/map/2gis';
import { NavigationLocalSave, SelectedLocation } from 'types/map/location';

import { ControlBase } from './ControlBase';
import { ControlMapWrapper } from './ControlBase/ControlBase.styled';
import { LocationSelectControl } from './LocationSelectControl';
import { LocationViewControl } from './LocationViewControl';
import { RouteNavigation } from './RouteNavigation';
import { RoutingControl } from './RoutingControl';
import { SelectedLocationZoom } from './SelectedLocationZoom';

export const NavigationControl = (): JSX.Element => {
    const [controlMode, setControlMode] = useState<
        'view' | 'search' | 'routing' | 'navigation'
    >('view');

    const [selectedLocation, setSelectedLocation] =
        useState<SelectedLocation>();

    const [routeData, setRouteData] = useState<{
        route: RoutingResult;
        points: {
            start: SelectedLocation;
            end: SelectedLocation;
        };
    }>();

    const { location, resetParams } = useLocationUrl();

    useEffect(() => {
        if (location) {
            setSelectedLocation(location);
            setControlMode('view');
        }
    }, [location]);

    const [navigation, setNavigation] = useLocalStorage<NavigationLocalSave>(
        'info67mapNavigation',
        {}
    );

    useEffect(() => {
        if (navigation.points && navigation.route) {
            setControlMode('navigation');
        }
    }, [navigation]);

    useEffect(() => {
        if (routeData) {
            setNavigation(routeData);
        }
    }, [routeData, setNavigation]);

    useEffect(() => {
        if (!routeData && navigation.points && navigation.route) {
            setRouteData({
                points: navigation.points,
                route: navigation.route,
            });
        }
    }, [routeData, navigation]);

    const getCurrentControl = useCallback(() => {
        switch (controlMode) {
            case 'view':
                return (
                    <LocationViewControl
                        key="location-view"
                        selectedLocation={selectedLocation}
                        onClearSelectedLocation={() => {
                            setSelectedLocation(undefined);
                            resetParams();
                        }}
                        onSearchPaneOpen={() => setControlMode('search')}
                        onRequestRoute={() => setControlMode('routing')}
                    />
                );
            case 'search':
                return (
                    <LocationSelectControl
                        key="location-select"
                        selectedLocation={selectedLocation}
                        onLocationSelect={(loc) => {
                            setSelectedLocation(loc);
                            resetParams();
                        }}
                        onClose={() => setControlMode('view')}
                    />
                );
            case 'routing':
                return (
                    <RoutingControl
                        key="location-routing"
                        initialLocation={selectedLocation}
                        onCancel={() => setControlMode('view')}
                        onSuccessRouteBuild={(r, p) => {
                            setRouteData({
                                points: p,
                                route: r,
                            });
                            setSelectedLocation(undefined);
                            resetParams();
                            setControlMode('navigation');
                        }}
                    />
                );
            case 'navigation':
                return (
                    <RouteNavigation
                        key="route-navigation"
                        route={routeData?.route}
                        startLocation={routeData?.points.start}
                        endLocation={routeData?.points.end}
                        onNavCancel={() => {
                            setRouteData(undefined);
                            setControlMode('view');
                        }}
                    />
                );
            default:
                return undefined;
        }
    }, [controlMode, routeData, selectedLocation, resetParams]);

    const [inline, setInline] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setInline(controlMode === 'view');
        }, 200);

        return () => {
            clearTimeout(timeout);
        };
    }, [controlMode]);

    return (
        <ControlMapWrapper data-inline={inline}>
            <Map
                controlsPane={
                    <>
                        <ControlBase
                            background={
                                controlMode !== 'view' &&
                                controlMode !== 'navigation'
                            }
                            inlineWithMapControls={controlMode === 'view'}
                        >
                            {getCurrentControl()}
                        </ControlBase>
                        <SelectedLocationZoom
                            selectedLocation={selectedLocation}
                        />
                    </>
                }
            />
        </ControlMapWrapper>
    );
};
