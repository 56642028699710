/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RoleList } from 'types/api/roleList';
import { UserList } from 'types/api/userList';

interface UsersState {
    users: UserList[] | undefined;
    usersFilter: UserList[] | undefined;
    roles: RoleList[] | undefined;

    activeFilters: number[];
    search: string;
    is_archive: boolean;
}

const initialState: UsersState = {
    users: undefined,
    usersFilter: undefined,
    roles: undefined,

    search: '',
    activeFilters: [],
    is_archive: false,
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        // staffData
        setUsers: (state, action: PayloadAction<UsersState['users']>) => {
            state.users = action.payload;
        },
        setRoles: (state, action: PayloadAction<UsersState['roles']>) => {
            state.roles = action.payload;
        },

        // usersFilter
        setSearch: (state, action: PayloadAction<UsersState['search']>) => {
            state.search = action.payload;
        },
        setIsFilter: (state, action) => {
            if (state.activeFilters.length !== 0) {
                if (state.activeFilters.includes(action.payload.id)) {
                    state.activeFilters = state.activeFilters.filter(
                        (id) => id !== action.payload.id
                    );
                } else {
                    state.activeFilters.push(action.payload.id);
                }
            } else {
                state.activeFilters.push(action.payload.id);
            }
        },
        resetFilter: (state) => {
            state.activeFilters = [];
            state.is_archive = false;
        },
        setIsArchive: (state) => {
            if (state.is_archive) {
                state.is_archive = false;
            } else {
                state.is_archive = true;
            }
        },

        // editUser
        archiveUser: (state, action) => {
            if (state.users !== undefined) {
                for (let i = 0; i < state.users.length; i += 1) {
                    if (state.users[i].id === action.payload.id) {
                        state.users[i].is_archived =
                            !state.users[i].is_archived;
                    }
                }
            }
        },
        deleteUser: (state, action) => {
            if (state.users !== undefined) {
                state.users = state.users.filter(
                    (user) => user.id !== action.payload.id
                );
            }
        },
        addRole: (state, action) => {
            if (state.users !== undefined) {
                for (let i = 0; i < state.users.length; i += 1) {
                    if (state.users[i].id === action.payload.id) {
                        if (
                            !state.users[i].roles.some(
                                (role) => role === action.payload.role
                            )
                        ) {
                            state.users[i].roles.push(action.payload.role);
                        }
                    }
                }
            }
        },
        deleteRole: (state, action) => {
            if (state.users !== undefined) {
                for (let i = 0; i < state.users.length; i += 1) {
                    if (state.users[i].id === action.payload.id) {
                        if (
                            state.users[i].roles.some(
                                (role) => role === action.payload.role
                            )
                        ) {
                            state.users[i].roles = state.users[i].roles.filter(
                                (role) => role !== action.payload.role
                            );
                        }
                    }
                }
            }
        },

        //
        setUsersFilter: (state) => {
            if (state.users !== undefined && state.roles !== undefined) {
                state.usersFilter = state.users;

                if (state.is_archive) {
                    state.usersFilter = state.users.filter(
                        (user) => user.is_archived === true
                    );
                } else {
                    state.usersFilter = state.users.filter(
                        (user) => user.is_archived === false
                    );
                }

                if (state.activeFilters.length !== 0) {
                    for (let i = 0; i < state.activeFilters.length; i += 1) {
                        state.usersFilter = state.usersFilter.filter((user) =>
                            user.roles.includes(state.activeFilters[i])
                        );
                    }
                }

                state.usersFilter = state.usersFilter.filter((user) =>
                    user.email
                        .toLowerCase()
                        .includes(state.search.toLowerCase())
                );
            }
        },
    },
});

export const {
    setUsers,
    setRoles,

    setSearch,
    setIsArchive,
    setIsFilter,
    resetFilter,
    setUsersFilter,

    archiveUser,
    deleteUser,
    addRole,
    deleteRole,
} = usersSlice.actions;

export const usersSliceReducer = usersSlice.reducer;
