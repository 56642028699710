import { useEffect, useState } from 'react';

import { AxiosError } from 'axios';

import useAxios from 'hooks/api/useAxios';
import { User } from 'types/api/user';
import { UserAuth } from 'types/api/userAuth';

import { useIsAuthenticated } from './useIsAuthenticated';
import { useUser } from './useUser';

type UseSignInReturn = {
    signIn: (credentials: UserAuth) => void;
    error?: AxiosError<unknown, unknown>;
    loading: boolean;
    success: boolean;
};

export const useSignIn = (): UseSignInReturn => {
    const [data, setData] = useState<UserAuth>({
        email: '',
        password: '',
    });

    const { authenticated, setAuthenticated } = useIsAuthenticated();

    const { setUser } = useUser();

    const [res, { rerun }] = useAxios<User>(
        {
            url: '/api/account/sign/in/',
            method: 'POST',
            data,
            skipRequest: () => true,
        },
        [data]
    );

    const signIn = (credentials: UserAuth): void => {
        setData(credentials);
        rerun();
    };

    const error = res.type === 'error' ? res.data : undefined;
    const loading = res.type === 'loading';
    const success = res.type === 'success' || authenticated;
    const response = res.type === 'success' && !loading ? res.data : undefined;

    useEffect(() => {
        if (success) {
            setAuthenticated(true);
            if (response) {
                setUser({
                    id: response.id,
                    username: response.username,
                    integrations: {
                        telegram: response.integrations?.telegram,
                    },
                    email: response.email,
                    roles: response.roles,
                });
            }
        }
    }, [setAuthenticated, success, response, setUser]);

    return { signIn, error, loading, success };
};
