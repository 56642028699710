import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { set } from 'store/slices/gallerySlice';

interface IUseGalleryReturn {
    gallery?: string;
    setGallery: (value?: string) => void;
}

export const useGallery = (): IUseGalleryReturn => {
    const gallery = useAppSelector((state) => state.gallery.value);

    const dispatch = useAppDispatch();

    const setGallery = useCallback(
        (value?: string): void => {
            dispatch(set(value));
        },
        [dispatch]
    );

    return { gallery, setGallery };
};
