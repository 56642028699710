import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Stack, Button } from '@mui/material';

type CopyRemoveControlProps = {
    onRequestDeuplicate: () => void;
    onRequestDelete: () => void;
};

export const CopyRemoveControl = ({
    onRequestDeuplicate,
    onRequestDelete,
}: CopyRemoveControlProps): JSX.Element => {
    return (
        <Stack spacing={1} direction="row">
            <Button
                fullWidth
                endIcon={<DeleteOutlineIcon />}
                color="error"
                variant="outlined"
                size="small"
                onClick={() => onRequestDelete()}
            >
                Удалить
            </Button>
            <Button
                fullWidth
                variant="outlined"
                endIcon={<CopyAllIcon />}
                size="small"
                onClick={() => onRequestDeuplicate()}
            >
                Дублировать
            </Button>
        </Stack>
    );
};
