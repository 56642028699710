import { useEffect } from 'react';

import { AnimatePresence, Variants } from 'framer-motion';

import { BasicButton } from 'components/atoms/BasicButton';
import { SplashScreenShape } from 'components/atoms/SplashScreenShape';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useExternalHref } from 'hooks/store/useExternalHref';

import {
    BlockerBackBtn,
    BlockerControlsWrapper,
    BlockerModalWrapper,
    BlockerScreenView,
} from './BlockerModal.styled';

const ModalVariants: Variants = {
    out: {
        y: '100%',
        opacity: 0,
    },
    in: {
        y: 0,
        opacity: 1,
    },
};

export const BlockerModal = (): JSX.Element => {
    const { externalHref, setExternalHref } = useExternalHref();

    const goToPage = (): void => {
        window.open(externalHref, '_blank');
        setExternalHref(undefined);
    };

    useEffect(() => {
        if (externalHref !== undefined) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [externalHref]);

    return (
        <AnimatePresence>
            {externalHref ? (
                <BlockerModalWrapper
                    variants={ModalVariants}
                    transition={{
                        duration: 0.3,
                    }}
                    initial="out"
                    animate="in"
                    exit="out"
                >
                    <BlockerScreenView>
                        <Typography variant="title.large">
                            Вы покидаете инфопомощник
                        </Typography>
                        <SplashScreenShape />
                        <BlockerControlsWrapper>
                            <div>
                                <Typography variant="title.medium">
                                    Желаете перейти на наш сайт с информацией?
                                </Typography>
                            </div>
                            <div>
                                <BasicButton onClick={() => goToPage()}>
                                    ПЕРЕЙТИ
                                </BasicButton>
                            </div>
                            <div>
                                <BlockerBackBtn
                                    onClick={() => setExternalHref(undefined)}
                                >
                                    <SVGAsset src="icons.24px.chevron" />
                                    вернуться назад
                                </BlockerBackBtn>
                            </div>
                        </BlockerControlsWrapper>
                    </BlockerScreenView>
                </BlockerModalWrapper>
            ) : undefined}
        </AnimatePresence>
    );
};
