import { Children, cloneElement, isValidElement } from 'react';

import { StyledBox, StyledRow, StyledCol } from './Grid.styled';
import { BoxProps, ColProps, RowProps } from './types';

export {
    StyledSection as Section,
    StyledContentWrapper as ContentWrapper,
    StyledSpacer as Spacer,
} from './Grid.styled';

export const Box = (props: BoxProps): JSX.Element => {
    const { children, bgVariant, ...rest } = props;

    return (
        <StyledBox {...rest} data-variant={bgVariant}>
            {children}
        </StyledBox>
    );
};

export const Col = (props: ColProps): JSX.Element => {
    const { children, ...rest } = props;

    return <StyledCol {...rest}>{children}</StyledCol>;
};

export const Row = (props: RowProps): JSX.Element => {
    const { children, ...rest } = props;

    return (
        <StyledRow {...rest} className="row">
            {Children.map(children, (child, i) => {
                if (isValidElement(child)) {
                    return (
                        <Col
                            key={`row_element_${i}`}
                            className="col"
                            {...child.props}
                        >
                            {cloneElement(child)}
                        </Col>
                    );
                }
                return null;
            })}
        </StyledRow>
    );
};

export type { BoxProps, ColProps, SectionProps, RowProps } from './types';
