import styled from 'styled-components';

export const WelcomeMsgWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.palette.grey.bg2};
    color: ${({ theme }) => theme.palette.grey.inactive};
    z-index: 1100;
`;

export const WelcomeMsgContent = styled.div`
    padding: 14px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.palette.common.black};
`;

export const WelcomeMsgTextWrapper = styled.div`
    max-width: 242px;
    p {
        opacity: 0.7;
    }
`;

export const SelectorContentWrapper = styled.div`
    padding-top: 128px;
`;

export const CharachtersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -16px -8px;
    margin-top: 16px;
    & > div {
        padding: 16px 8px;
        width: 50%;
    }
`;
