import styled from 'styled-components';

export const EmployeesList = styled.div`
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: ${({ theme }) => theme.borderRadius.large};
    overflow: hidden;
    position: relative;
    height: calc(100vh - 100px);
`;

export const EmployeesListDataWrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    padding: 32px 40px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.palette.common.white};
    width: 100%;
`;

export const EmployeesTitle = styled.div`
    display: flex;
    width: 456px;
    flex: 0 0 auto;

    & h1 {
        display: flex;
        width: 148px;
        margin: 0px 27px 0px 0px;
    }
    & input {
        display: flex;
        flex: 0 0 auto;
        width: 250px;
    }
`;

export const TextFieldWrapper = styled.div`
    display: inline-block;
    width: 450px;
`;

export const TextFieldWrapper1 = styled.div`
    display: flex;
    flex: 0 0 auto;
    width: 450px;
`;

export const TypesWrapper = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0px 104px 0px 104px;
    overflow: hidden;
    text-overflow: ellipsis;

    & div {
        width: 184px;
        display: flex;
        flex: 0 0 auto;
        padding: 0px 20px 0px 0px;
        box-sizing: border-box;
    }
`;

type EmployeesListProps = {
    is_filter: boolean;
};
export const EmployeesListScrollWrapper = styled.div<EmployeesListProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    background: 'transparent';
    position: relative;

    @supports (overflow-y: overlay) {
        overflow-y: overlay;
    }

    &::-webkit-scrollbar {
        display: none;
        width: 8px;
    }

    &:hover,
    &[data-scroll='true'] {
        &::-webkit-scrollbar {
            display: block;
        }
    }
`;
