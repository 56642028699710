import styled from 'styled-components';

export const StyledImageLink = styled.a`
    display: block;
`;

export const StyledImage = styled.img`
    height: auto;
    width: 100%;
    object-fit: cover;

    border-radius: 16px;
`;
