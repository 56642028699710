import { Map } from '@2gis/mapgl/types';

import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { set, setStateFloorPlanId } from 'store/slices/mapInstanceSlice';
import { MapGL } from 'types/map/2gis';

interface IUseMapInstanceReturn {
    mapInstance?: Map;
    mapgl?: MapGL;
    currentFloorPlanId?: string;
    setMapInstance: (map: Map, MapGL: MapGL) => void;
    setFloorPlanId: (floorPlanId?: string) => void;
}

export const useMapInstance = (): IUseMapInstanceReturn => {
    const {
        map: mapInstance,
        mapgl: mapglInstance,
        currentFloorPlanId,
    } = useAppSelector((state) => state.mapInstance.value);

    const dispatch = useAppDispatch();

    const setMapInstance = (map: Map, MapGl: MapGL): void => {
        dispatch(
            set({
                map,
                mapgl: MapGl,
            })
        );
    };
    const setFloorPlanId = (floorPlanId?: string): void => {
        dispatch(setStateFloorPlanId(floorPlanId));
    };

    return {
        mapInstance,
        mapgl: mapglInstance,
        currentFloorPlanId,
        setMapInstance,
        setFloorPlanId,
    };
};
