/* eslint-disable no-underscore-dangle */
import { usePageBuilder } from 'hooks/api/builder/pages/usePageBuilder';
import { useNewLocationSelector } from 'hooks/api/builder/points/useNewLocationSelector';
import { usePointControl } from 'hooks/api/builder/points/usePointControl';

import { ComponentSettingsControl } from './editorControls/ComponentSettingsControl';
import { EditorBlockerModal } from './editorControls/EditorBlockerModal';
import { NodeTree } from './editorControls/NodeTree';
import { EditorPagePreviewControl } from './editorControls/PagePreviewControl';
import { PageSaverControl } from './editorControls/PageSaverControl';
import { PageSelectControl } from './editorControls/PageSelectControl';
import { PageSettingsControl } from './editorControls/PageSettingsControl';
import { QuizSettingsControl } from './editorControls/QuizSettingsControl';
import {
    PageEditorWrapper,
    EditorToolbarWrapper,
    EditorPreviewWrapper,
} from './PageEditor.styled';
import { PointSaverControl } from './pointOfInterestControls/controls/PointSaverControl';
import { PointSelectControl } from './pointOfInterestControls/controls/PointSelectControl';
import { PointSelectToolBar } from './pointOfInterestControls/controls/PointSelectToolbar';
import { PoiPreviewControl } from './pointOfInterestControls/controls/PoiPreviewControl';
import { PoiSettingsControl } from './pointOfInterestControls/controls/PoiSettingsControl';

export const PageContentEditor = (): JSX.Element => {
    const { pageList, page, undoRedoState, controls } = usePageBuilder();

    const {
        saveChanges,
        resetChanges,
        onNewComponentCreated,
        onNewPageCreated,
        onPageDeleted,
        page: {
            onPageConfigChange,
            onPageSettingsChange,
            onQuizSettingsChange,
        },
        node: { onNodeDelete, onNodeDuplicate, onNodeSettingsChange },
    } = controls;

    const {
        config,
        undoRedoState: actions,
        saveChanges: savePointChanges,
        controls: pointControls,
    } = usePointControl();

    const {
        onNewPointCreated,
        onPointDeleted,
        onPointSettingsChanged,
        resetChanges: resetPointChanges,
    } = pointControls;

    const { mode, location } = useNewLocationSelector();

    return (
        <PageEditorWrapper>
            <EditorBlockerModal
                when={
                    undoRedoState.hasChanges ||
                    actions?.hasChanges ||
                    mode !== undefined
                }
            />
            <EditorToolbarWrapper>
                <PageSelectControl
                    onNewPageAdded={onNewPageCreated}
                    selectionBlocked={
                        actions?.hasChanges ||
                        undoRedoState.hasChanges ||
                        mode !== undefined
                    }
                    pageList={pageList.pages || []}
                    error={pageList.error}
                    loading={pageList.loading}
                />
                {page.config &&
                page.config.type !== 'QUIZ' &&
                mode === undefined ? (
                    <NodeTree
                        onNewComponentCreated={onNewComponentCreated}
                        onNodesChange={onPageConfigChange}
                    />
                ) : undefined}
                <PointSelectControl
                    selectionBlocked={
                        actions?.hasChanges ||
                        undoRedoState.hasChanges ||
                        mode !== undefined
                    }
                    onNewPointCreated={onNewPointCreated}
                />
            </EditorToolbarWrapper>
            <EditorPreviewWrapper>
                {mode === undefined ? (
                    <EditorPagePreviewControl
                        undoRedoActions={undoRedoState}
                        loading={page.loading}
                        error={page.error}
                    />
                ) : undefined}
                <PoiPreviewControl />
            </EditorPreviewWrapper>
            <EditorToolbarWrapper>
                {(config && mode === undefined) ||
                (config && location && mode === 'update') ? (
                    <>
                        <PointSaverControl
                            undoRedoActions={actions}
                            onReset={() => resetPointChanges()}
                            onSave={() => savePointChanges()}
                            loading={savePointChanges.loading}
                        />
                        <PoiSettingsControl
                            key={actions?.key}
                            onDeleteSuccess={onPointDeleted}
                            onChange={onPointSettingsChanged}
                            config={config}
                        />
                    </>
                ) : undefined}
                {mode !== undefined && !location ? (
                    <PointSelectToolBar />
                ) : undefined}
                {page.config && mode === undefined ? (
                    <>
                        <PageSaverControl
                            hasChanges={undoRedoState.hasChanges}
                            onReset={() => resetChanges()}
                            onSave={() => saveChanges()}
                            loading={saveChanges.loading}
                        />
                        <PageSettingsControl
                            onSettingsChange={onPageSettingsChange}
                            onDeleteSuccess={onPageDeleted}
                            config={page.config}
                            key={undoRedoState.key}
                        />
                        {page.config.type === 'PAGE' ? (
                            <ComponentSettingsControl
                                onNodeChange={onNodeSettingsChange}
                                onNodeDelete={onNodeDelete}
                                onNodeDuplicate={onNodeDuplicate}
                            />
                        ) : (
                            <QuizSettingsControl
                                config={page.config.quizConfig}
                                onChange={onQuizSettingsChange}
                            />
                        )}
                    </>
                ) : undefined}
            </EditorToolbarWrapper>
        </PageEditorWrapper>
    );
};
