import { ReactZoomPanPinchRef } from '@kokarn/react-zoom-pan-pinch';
import AddIcon from '@mui/icons-material/Add';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, ButtonGroup, Stack } from '@mui/material';

import { ZoomPanControlWrapper } from './PreviewZoomPanControl.styled';

type PreviewZoomPanControlProps = {
    zoomInstance: ReactZoomPanPinchRef;
};

export const PreviewZoomPanControl = ({
    zoomInstance,
}: PreviewZoomPanControlProps): JSX.Element => {
    const {
        zoomIn,
        zoomOut,
        resetTransform,
        instance: { setCenter },
    } = zoomInstance;

    const onReset = (): void => {
        resetTransform(0, 'linear');
        setCenter();
    };

    return (
        <ZoomPanControlWrapper>
            <Stack spacing={2}>
                <ButtonGroup
                    variant="outlined"
                    size="medium"
                    orientation="vertical"
                >
                    <Button onClick={() => zoomIn()}>
                        <AddIcon />
                    </Button>
                    <Button onClick={() => zoomOut()}>
                        <RemoveIcon />
                    </Button>
                </ButtonGroup>
                <Button
                    onClick={() => {
                        onReset();
                    }}
                    variant="outlined"
                    size="medium"
                >
                    <FilterCenterFocusIcon />
                </Button>
            </Stack>
        </ZoomPanControlWrapper>
    );
};
