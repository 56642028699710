import { useCallback, useEffect, useState } from 'react';

import { useMapInstance } from './useMapInstance';

type SetMapPosition = {
    x?: number;
    y?: number;
    zoom?: number;
};

type SetMapFloor = {
    floorLevelIndex?: number;
    floorPlanId?: string;
};

type UseMapPositionReturn = {
    setMapToLocation: (location: SetMapPosition) => void;
    setMapFloor: (floor: SetMapFloor) => void;
};

export const useMapPosition = (): UseMapPositionReturn => {
    const { mapInstance: map, currentFloorPlanId } = useMapInstance();

    const [currentLocation, setCurrentLocation] = useState<SetMapPosition>();

    const setMapToLocation = useCallback((location: SetMapPosition) => {
        setCurrentLocation(location);
    }, []);

    useEffect(() => {
        const { x, y, zoom } = currentLocation || {};

        if (map) {
            if (x && y) {
                map.setCenter([x, y], {
                    easing: 'easeInOutQuart',
                    duration: 1000,
                });
            }

            if (zoom) {
                map.setZoom(zoom, {
                    easing: 'easeOutQuart',
                    duration: 1000,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation?.x, map]);

    const setMapFloor = useCallback(
        (floor: SetMapFloor) => {
            const { floorLevelIndex, floorPlanId } = floor;

            const floorIndex = (): number => {
                if (floorPlanId && floorLevelIndex) {
                    if (
                        floorPlanId === '4504235282539247' ||
                        floorPlanId === '4504235282539069'
                    ) {
                        return 0;
                    }
                    return floorLevelIndex < 1
                        ? floorLevelIndex + 1
                        : floorLevelIndex;
                }
                return 0;
            };

            if (map) {
                if (
                    floorPlanId !== undefined &&
                    floorLevelIndex !== undefined &&
                    floorPlanId === currentFloorPlanId
                ) {
                    map.setFloorPlanLevel(floorPlanId, floorIndex());
                }
            }
        },
        [currentFloorPlanId, map]
    );

    return { setMapToLocation, setMapFloor };
};
