import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from '@mui/material';

import { SVGAssetProps } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { TypographyBadge } from 'components/atoms/Typography/Typography.styled';
import { SVGAssetConfig } from 'types/builder/componentConfig';

import { IconSelector } from '../../editorControls/IconSelector';

type SVGAssetControlProps = {
    node?: SVGAssetConfig;
    onChange: (config: SVGAssetConfig) => void;
};

type Color = SVGAssetProps['color'];

const colors: {
    value: Color;
    title: string;
}[] = [
    {
        value: 'common.black',
        title: 'Чёрный',
    },
    {
        value: 'common.grey',
        title: 'Серый',
    },
    {
        value: 'common.white',
        title: 'Белый',
    },
    {
        value: 'green.alpha',
        title: 'Салатовый',
    },
    {
        value: 'green.betta',
        title: 'Зелёный',
    },
    {
        value: 'green.gamma',
        title: 'Аквамариновый',
    },
    {
        value: 'red',
        title: 'Красный',
    },
    {
        value: 'yellow',
        title: 'Жёлтый',
    },
];

export const SVGAssetControl = ({
    onChange,
    node,
}: SVGAssetControlProps): JSX.Element => {
    const { src = 'logos.logo67', color } = node?.props || {};

    const onIconChange = (icon: SVGAssetProps['src'] | string): void => {
        onChange({
            component: 'svgAsset',
            props: {
                ...node?.props,
                src: icon,
            },
        });
    };
    const onColorChange = (value: SVGAssetProps['color']): void => {
        onChange({
            component: 'svgAsset',
            props: {
                src,
                color: value,
            },
        });
    };

    return (
        <Stack spacing={2}>
            <IconSelector icon={src} showPreview onSelect={onIconChange} />
            {!src.includes('.') ? (
                <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel id="base-route-label">Цвет</InputLabel>
                    <Select
                        labelId="base-route-label"
                        label="Цвет"
                        value={color || ''}
                    >
                        {colors.map((el, i) => (
                            <MenuItem
                                key={`menu_item_${i}`}
                                value={el.value}
                                onClick={() => onColorChange(el.value)}
                            >
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <TypographyBadge $color={el.value} />
                                    <Typography>{el.title}</Typography>
                                </Stack>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : undefined}
        </Stack>
    );
};
