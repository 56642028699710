import {
    DialogContent as MuiDialogContent,
    Dialog as MuiDialog,
} from '@mui/material';
import styled from 'styled-components';

export const Dialog = styled(MuiDialog)`
    .close-button {
        position: absolute;
        top: 70px;
        right: 34px;
        background-color: white;
        border-radius: 8px;
    }

    @media screen and (max-width: 465px) {
        .MuiPaper-root {
            margin: 0;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            border-radius: 0 !important;
        }
        .MuiDialog-container {
            width: 100%;
        }
        .close-button {
            right: 30px;
        }
    }
`;

export const DialogContent = styled(MuiDialogContent)`
    min-width: 600px;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    .img-preview-wrapper {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: ${({ theme }) => theme.palette.grey.bg2};
        border-radius: 12px;
        overflow: hidden;
        position: relative;

        &[data-loading='true'] {
            filter: brightness(0.5);
        }

        .img-preview {
            position: absolute;
            margin: 16px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }
    }

    @media screen and (max-width: 665px) {
        min-width: 400px;
    }
    @media screen and (max-width: 465px) {
        min-width: 100%;
        padding: 20px !important;

        .dropzone {
            height: 100%;
            padding: 30px;
        }
    }
`;
