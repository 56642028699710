import { useEffect, useMemo } from 'react';

import { AnimatePresence } from 'framer-motion';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { AuthPage } from 'components/pages/Admin/Auth';
import { PageEditor } from 'components/pages/Admin/Editor';
import { StaffPage } from 'components/pages/Admin/Staff';
import { SupportPage } from 'components/pages/Admin/Support';
import { NotFoundPage } from 'components/pages/NotFoundPage';
import { SplashScreen } from 'components/pages/Splash';
import { DynamicPageRender } from 'components/templates/DynamicPageRender';
import { FadeAnimation } from 'components/templates/FadeWrapper';
import { AdminLayout } from 'components/templates/layouts/AdminLayout';
import { UserRoleNavigator } from 'components/templates/layouts/AdminLayout/UserRoleNavigator';
import { PageLayout } from 'components/templates/layouts/PageLayout';
import { PageTemplate } from 'components/templates/PageTemplate';
import { CONFIG_PAGES_MAP } from 'constants/routes';
import { useIsAuthenticated } from 'hooks/api/auth/useIsAuthenticated';
import { usePageShareUrl } from 'hooks/api/builder/pages/usePageShareUrl';
import { useNotifications } from 'hooks/api/integrations/useNotifications';
import { useSessionStorage } from 'hooks/api/integrations/useSessionStorage';
import { useMeta } from 'hooks/api/useMeta';

export const App = (): JSX.Element => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const { checkAuthStatus, authChecked, authenticated } =
        useIsAuthenticated();
    const { connectNotifications, status } = useNotifications({}, []);

    useEffect(() => {
        checkAuthStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (authChecked) {
            const socket = connectNotifications();

            return () => {
                socket.close();
            };
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authChecked, authenticated]);

    const { meta, error, refresh } = useMeta('guestPageList');

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (error) {
                refresh();
            }
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
    }, [error, refresh]);

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            document.getElementById('yandex-metrika')?.remove();
        }
    }, []);

    const loading = useMemo(
        () => !meta || !authChecked || status !== 'connected',
        [authChecked, meta, status]
    );

    const dynamicPages = useMemo(() => {
        return (
            meta?.filter((el) => !el.is_fundamental && el.is_published) || []
        );
    }, [meta]);

    useSessionStorage();

    usePageShareUrl(dynamicPages, loading);

    return (
        <AnimatePresence>
            <Routes>
                <Route
                    path="/"
                    element={<SplashScreen isLoading={loading} />}
                />
                {!loading ? (
                    <Route path="/">
                        {CONFIG_PAGES_MAP.map((el) => (
                            <Route
                                key={`page_idx_${el.id}`}
                                path={`${el.baseRoute}${el.path ?? ''}`}
                                element={
                                    <PageLayout
                                        currentPage={el}
                                        path={`${el.baseRoute}${el.path ?? ''}`}
                                    >
                                        <PageTemplate
                                            page={el}
                                            path={`${el.baseRoute}${
                                                el.path ?? ''
                                            }`}
                                        />
                                    </PageLayout>
                                }
                            />
                        ))}
                        {dynamicPages.map((el) => (
                            <Route
                                key={`page_idx_${el.id}`}
                                path={el.href}
                                element={
                                    <DynamicPageRender
                                        pageID={el.id}
                                        path={el.href}
                                    />
                                }
                            />
                        ))}
                        <Route
                            path="*"
                            element={<Navigate to="/404" replace />}
                        />
                    </Route>
                ) : (
                    <Route path="*" element={<SplashScreen isLoading />} />
                )}
                <Route path="/admin" element={<AdminLayout />}>
                    <Route index element={<UserRoleNavigator />} />
                    <Route path="auth" element={<AuthPage />} />
                    <Route path="administrator" element={<StaffPage />} />
                    <Route path="support_manager" element={<SupportPage />} />
                    <Route path="content_manager" element={<PageEditor />} />
                    <Route
                        path="*"
                        element={<NotFoundPage backPath="/admin" />}
                    />
                </Route>
                <Route
                    path="/404"
                    element={
                        <FadeAnimation>
                            <NotFoundPage />
                        </FadeAnimation>
                    }
                />
            </Routes>
        </AnimatePresence>
    );
};
