import { useEffect, useState } from 'react';

import {
    Stack,
    Button,
    TextField,
    Modal,
    Box,
    CircularProgress,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { BasicButton } from 'components/atoms/BasicButton';
import { Spacer } from 'components/atoms/Grid';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useStaffData, useStaff } from 'hooks/api/staff/useStaffData';
import { useStaffFilter } from 'hooks/api/staff/useUsersFilter';
import useAxios from 'hooks/api/useAxios';

import * as Style from './Filters.styled';

export const Filters = (): JSX.Element => {
    const { roles, setAllUsers } = useStaffData();

    const {
        activeFilters,
        is_archive,
        setIsArchiveFilter,
        setResetFilter,
        setFilter,
    } = useStaffFilter();

    const { users, getUsers } = useStaff();

    const [email, setEmail] = useState<string>();

    const [res, { rerun }] = useAxios<unknown, { email: string[] }>(
        {
            url: `/api/admin/user/add/`,
            method: 'POST',
            data: { email },
            skipRequest: () => true,
        },
        []
    );

    const [status, setStatus] = useState<
        'idle' | 'success' | 'error' | 'loading'
    >('idle');

    const {
        register,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm<{ email: string }>();

    const resetAll = (): void => {
        setStatus('idle');
        reset();
        setEmail('');
    };

    const [open, setOpen] = useState(false);

    const handleOpen = (): void => setOpen(true);
    const handleClose = (): void => {
        setOpen(false);
        resetAll();
    };

    const onSubmit = handleSubmit((data) => {
        setEmail(data.email);
        handleOpen();
    });

    useEffect(() => {
        if (res.type === 'success') {
            setStatus('success');
            getUsers();
            if (users !== undefined) {
                setAllUsers(users);
            }
        }
        if (res.type === 'loading') {
            setStatus('loading');
        }
        if (res.type === 'error') {
            setStatus('error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [res.type]);

    let resetFilterButton = null;
    if (activeFilters.length !== 0 || is_archive === true) {
        resetFilterButton = (
            <Style.ResetFilter onClick={() => setResetFilter()}>
                Сбросить
            </Style.ResetFilter>
        );
    }

    const filters = roles?.map((role) => {
        return (
            <Button
                key={role.id}
                variant={
                    activeFilters.some((activeRole) => activeRole === role.id)
                        ? 'contained'
                        : 'outlined'
                }
                onClick={() => setFilter(role.id)}
            >
                {role.verbose_name}
            </Button>
        );
    });

    const style = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '32px 40px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 420,
        bgcolor: '#FFFFFF',
        borderRadius: '16px',
    };

    return (
        <Style.StaffFilterWrapper>
            <Modal
                open={open}
                onClose={() =>
                    status === 'loading' ? undefined : handleClose()
                }
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {status === 'idle' || status === 'loading' ? (
                        <>
                            <Typography variant="title.large">
                                На почту
                            </Typography>
                            <Style.Email>
                                <Typography
                                    variant="title.large"
                                    textAlign="center"
                                >
                                    {email}
                                </Typography>
                            </Style.Email>
                            <Typography
                                variant="title.large"
                                textAlign="center"
                            >
                                будет отправлен новый пароль для входа в учётную
                                запись
                            </Typography>
                            <Spacer size="2x" />
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{ width: '100%' }}
                            >
                                <BasicButton
                                    variant="secondary"
                                    onClick={() => handleClose()}
                                    disabled={status === 'loading'}
                                >
                                    Отмена
                                </BasicButton>
                                <BasicButton
                                    onClick={() => rerun()}
                                    disabled={status === 'loading'}
                                >
                                    {status === 'loading' ? (
                                        <CircularProgress
                                            size={16}
                                            color="inherit"
                                            variant="indeterminate"
                                        />
                                    ) : (
                                        'ОТПРАВИТЬ'
                                    )}
                                </BasicButton>
                            </Stack>
                        </>
                    ) : undefined}
                    {status === 'error' && res.type === 'error' ? (
                        <Stack
                            spacing={2}
                            sx={{ svg: { width: '100px', height: '100px' } }}
                            alignItems="center"
                        >
                            <SVGAsset src="icons.56px.negative" />
                            <Typography
                                variant="title.large"
                                textAlign="center"
                            >
                                Не удалось добавить пользователя
                            </Typography>
                            <Typography>
                                {res.data.response?.data.email
                                    ? 'Пользователь с таким email уже существует.'
                                    : undefined}
                            </Typography>
                            <BasicButton onClick={handleClose}>
                                Все понятно
                            </BasicButton>
                        </Stack>
                    ) : undefined}
                    {status === 'success' && res.type === 'success' ? (
                        <Stack
                            spacing={2}
                            sx={{ svg: { width: '100px', height: '100px' } }}
                            alignItems="center"
                        >
                            <SVGAsset src="icons.56px.checkCircle" />
                            <Typography
                                variant="title.large"
                                textAlign="center"
                            >
                                Новый пароль успешно отправлен на почту
                            </Typography>
                            <Typography>{email}</Typography>
                            <BasicButton onClick={handleClose}>
                                Все понятно
                            </BasicButton>
                        </Stack>
                    ) : undefined}
                </Box>
            </Modal>
            <Style.TextFieldWrapper>
                <Stack
                    component="form"
                    onSubmit={onSubmit}
                    sx={{ p: '1px' }}
                    spacing={0.5}
                >
                    <TextField
                        placeholder=""
                        variant="filled"
                        label={
                            errors.email?.message ||
                            'Введите email нового пользователя'
                        }
                        sx={{
                            '.MuiInputBase-root': {
                                paddingRight: 0,
                                maxWidth: '100%',
                            },
                        }}
                        {...register('email', {
                            required: true,
                            pattern: {
                                value: /^\S+@\S+\.\S+$/,
                                message: 'Введите корректную почту',
                            },
                        })}
                        error={!!errors.email}
                        InputProps={{
                            endAdornment: (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    endIcon={
                                        <SVGAsset src="icons.24px.plusCircle" />
                                    }
                                    type="submit"
                                    sx={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                    }}
                                >
                                    Добавить
                                </Button>
                            ),
                        }}
                    />
                </Stack>
            </Style.TextFieldWrapper>

            <Style.FiltersWrapper>
                <Stack direction="row" spacing={1}>
                    <Typography variant="body.small">Фильтры:</Typography>
                    {resetFilterButton}
                </Stack>
                <Style.ButtonsWrapper>
                    {filters}
                    <Style.Spacer>
                        <Typography variant="body.large">/</Typography>
                    </Style.Spacer>
                    <Button
                        variant={is_archive ? 'contained' : 'outlined'}
                        onClick={() => setIsArchiveFilter()}
                    >
                        Архив
                    </Button>
                </Style.ButtonsWrapper>
            </Style.FiltersWrapper>
        </Style.StaffFilterWrapper>
    );
};
