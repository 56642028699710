import { ComponentPropsWithoutRef, useEffect, useState } from 'react';

import { Badge } from '@mui/material';
import { AnimatePresence, Variants } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';

import { SVGAsset } from 'components/atoms/SVGAsset';
import { useNotifications } from 'hooks/api/integrations/useNotifications';
import useAxios from 'hooks/api/useAxios';
import { Message } from 'types/api/message';

import {
    MainButton,
    MessageButton,
    PhoneButton,
    SupportButtonAnchor,
    SupportButtonWrapper,
} from './Support.styled';

const ButtonVariants: Variants = {
    visible: {
        y: 0,
        opacity: 1,
    },
    msgButtonHidden: {
        y: 64,
        opacity: 0,
    },
    phoneButtonHidden: {
        y: 120,
        opacity: 0,
    },
};

export const SupportButton = (
    props: ComponentPropsWithoutRef<'div'>
): JSX.Element => {
    const [pressed, setPressed] = useState(false);
    const [, setParams] = useSearchParams();

    const [unreadCount, setUnreadCount] = useState(0);

    const [res, { rerun }] = useAxios<Message[]>(
        {
            url: '/api/chat/history/',
            method: 'GET',
        },
        []
    );

    const data = res.type === 'success' ? res.data : undefined;

    useNotifications(
        {
            onNewMessageGuest: () => rerun(),
        },
        [rerun]
    );

    useEffect(() => {
        if (data) {
            setUnreadCount(
                data.filter(
                    (el) => el.status !== 'read' && el.type === 'manager'
                ).length
            );
        }
    }, [data]);

    return (
        <SupportButtonAnchor {...props}>
            <SupportButtonWrapper>
                <AnimatePresence initial>
                    {pressed ? (
                        <>
                            <MessageButton
                                key="msg_button"
                                variants={ButtonVariants}
                                animate="visible"
                                exit="msgButtonHidden"
                                initial="msgButtonHidden"
                                onClick={() => {
                                    setPressed(false);
                                    setParams({ chat: 'open' });
                                    setUnreadCount(0);
                                }}
                                className="svg-color-inherit"
                            >
                                <Badge badgeContent={unreadCount} color="error">
                                    <SVGAsset src="icons.24px.messageSquare" />
                                </Badge>
                            </MessageButton>
                            <PhoneButton
                                key="phone_button"
                                variants={ButtonVariants}
                                animate="visible"
                                exit="phoneButtonHidden"
                                initial="phoneButtonHidden"
                                href="tel:84955392478"
                                onClick={() => setPressed(false)}
                                className="svg-color-inherit"
                            >
                                <SVGAsset src="icons.24px.phoneCall" />
                            </PhoneButton>
                        </>
                    ) : undefined}
                </AnimatePresence>
                <MainButton
                    onClick={() => setPressed(!pressed)}
                    className="svg-color-inherit"
                >
                    {pressed ? (
                        <SVGAsset src="icons.24px.closeX" />
                    ) : (
                        <Badge badgeContent={unreadCount} color="error">
                            <SVGAsset src="icons.24px.messageCircle" />
                        </Badge>
                    )}
                </MainButton>
            </SupportButtonWrapper>
        </SupportButtonAnchor>
    );
};
