import { useEffect } from 'react';

import useAxios from 'hooks/api/useAxios';

type UseTgDetachReturn = {
    detachTelegram: () => void;
    success: boolean;
    loading: boolean;
};

export const useTgDetach = (): UseTgDetachReturn => {
    const [res, { rerun }] = useAxios(
        {
            url: '/api/integrations/telegram/',
            method: 'DELETE',
            skipRequest: () => true,
        },
        []
    );
    const success = res.type === 'success';
    const loading = res.type === 'loading';

    const detachTelegram = (): void => {
        rerun();
    };

    useEffect(() => {
        if (success) {
            window.location.reload();
        }
    }, [success]);

    return { detachTelegram, success, loading };
};
