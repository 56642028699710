import { useEffect, useMemo } from 'react';

import { Box, Stack } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { SplashScreen } from 'components/pages/Splash';
import { FadeAnimation } from 'components/templates/FadeWrapper';
import { useIsAuthenticated } from 'hooks/api/auth/useIsAuthenticated';
import { useUser } from 'hooks/api/auth/useUser';
import { useRoles, useStaffData } from 'hooks/api/staff/useStaffData';

import { AdminLayoutContainer, AdminLayoutWrapper } from './AdminLayout.styled';
import { ChatTransferProvider } from './ChatTransferProvider';
import { Header } from './Header';

export const AdminLayout = (): JSX.Element => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { authChecked, authenticated, loading } = useIsAuthenticated();

    const { getRoles } = useRoles();

    const { roles } = useStaffData();
    const { user } = useUser();

    useEffect(() => {
        if (authenticated) {
            getRoles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated]);

    const backPath = useMemo(
        () => (pathname !== '/admin/auth' ? pathname : undefined),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (authChecked && !authenticated) {
            navigate(`/admin/auth${backPath ? `?back=${backPath}` : ''}`, {
                replace: true,
            });
        }
    }, [authChecked, authenticated, navigate, pathname, backPath]);

    const roleState = useMemo(() => {
        const rolePath = pathname.split('/')[2];
        const role = roles?.find((el) => el.name === rolePath);
        const userRole = role ? !!user?.roles.includes(role?.id) : false;

        return { userRole: user?.roles.includes(1) || userRole, role };
    }, [pathname, roles, user?.roles]);

    const outlet = useMemo(() => {
        return pathname !== '/admin/content_manager' ? (
            <AdminLayoutContainer>
                <Outlet />
            </AdminLayoutContainer>
        ) : (
            <Outlet />
        );
    }, [pathname]);

    if (!authChecked || loading || (authenticated && !roles)) {
        return (
            <FadeAnimation>
                <AdminLayoutWrapper>
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: '100%' }}
                    >
                        <SplashScreen isLoading fullScreen={false} />
                    </Stack>
                </AdminLayoutWrapper>
            </FadeAnimation>
        );
    }

    return (
        <>
            <FadeAnimation key={`anim_${authenticated}`}>
                <AdminLayoutWrapper>
                    {pathname !== '/admin/auth' ? <Header /> : undefined}
                    {roleState.role && !roleState.userRole ? (
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ height: '100%' }}
                        >
                            <Box
                                sx={{
                                    height: 'min-content',
                                    div: { margin: 0 },
                                    p: '32px 40px',
                                    bgcolor: 'white',
                                    borderRadius: '16px',
                                }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <SVGAsset src="shapes.admin.pointUpFace" />
                                    <Stack spacing={1}>
                                        <Typography variant="title.large">
                                            У вас нет прав на доступ к данному
                                            разделу
                                        </Typography>
                                        <Typography>
                                            Запросите доступ у администратора
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                    ) : (
                        <FadeAnimation key={pathname}>{outlet}</FadeAnimation>
                    )}
                </AdminLayoutWrapper>
            </FadeAnimation>
            <ChatTransferProvider />
        </>
    );
};
