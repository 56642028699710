/* eslint-disable no-underscore-dangle */
import { Box, Stack } from '@mui/material';
import { ReactSVG } from 'react-svg';

import { Typography } from 'components/atoms/Typography';
import { componentDisplayConfig } from 'constants/components/displayConfig';
import { PageComponentConfig } from 'types/builder/componentConfig';

type ComponentHeaderProps = {
    activeNode: PageComponentConfig;
    expanded: boolean;
};

export const ComponentHeader = ({
    activeNode,
    expanded,
}: ComponentHeaderProps): JSX.Element => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ width: '100%', mr: 1 }}
            className="svg-color-inherit"
        >
            <ReactSVG
                src={componentDisplayConfig[activeNode.component]?.icon || ''}
            />
            <Box
                sx={{
                    fontSize: expanded ? 22 : 16,
                    fontWeight: 500,
                }}
            >
                {componentDisplayConfig[activeNode.component]?.title}
            </Box>
            {activeNode?._key ? (
                <div
                    style={{
                        marginLeft: 'auto',
                        color: 'gray',
                    }}
                >
                    <Typography variant="title.small">
                        #{activeNode._key.slice(0, 4)}
                    </Typography>
                </div>
            ) : undefined}
        </Stack>
    );
};
