import { useMemo, useEffect, useState, useCallback } from 'react';

import { CircularProgress, IconButton, Stack, Tooltip } from '@mui/material';

import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { HumanAvatarProps } from 'components/molecules/HumanAvatar';
import { ChatControl } from 'components/organisms/Chat/ChatControl';
import { LoaderWrapper } from 'components/organisms/Chat/GuestChatModal/GuestChatModal.styled';
import { useChat } from 'hooks/api/chat/useChat';
import { useFindStaff } from 'hooks/api/staff/useFindStaff';
import { getShortEmail } from 'utils/getShortEmail';

import { ChatTransfer } from '../ChatTransfer';
import { SupportChatWrapper } from './SupportChat.styled';

type SupportChatControlProps = {
    chatID?: number;
    chatToken?: string;
    onClose?: () => void;
    onFinish?: (chatID: number) => void;
    chatDisabled?: boolean;
    // onArchive?: () => void;
};

export const SupportChatControl = ({
    chatID,
    chatToken,
    chatDisabled,
    onClose = () => undefined,
    onFinish = () => undefined,
}: // onArchive = () => undefined,
SupportChatControlProps): JSX.Element => {
    const {
        messages,
        status,
        sendMessage,
        finishChat,
        transferChat,
        manager,
        guestAvatar,
    } = useChat({
        owner: 'manager',
        chat: {
            id: chatID,
            token: chatToken,
            readOnly: chatDisabled,
        },
    });

    const chatMessages = useMemo(() => {
        return messages.sort(
            (a, b) =>
                Date.parse(a.created_date || '') -
                Date.parse(b.created_date || '')
        );
    }, [messages]);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development' && !chatDisabled) {
            // eslint-disable-next-line no-console
            console.log('ws/chat - status:', status);
        }
    }, [status, chatDisabled]);

    const [chatTransferOpen, setChatTransferOpen] = useState(false);

    const onChatTransfer = useCallback(
        (chat: number, userID: number) => {
            transferChat(chat, userID);

            setTimeout(() => {
                if (chatID) {
                    onFinish(chatID);
                }
                onClose();
            }, 2000);
        },
        [chatID, onClose, onFinish, transferChat]
    );

    const onChatFinish = useCallback(() => {
        if (chatID) {
            onFinish(chatID);
            finishChat();
            onClose();
        }
    }, [chatID, finishChat, onClose, onFinish]);

    const { findManager } = useFindStaff();

    const { email } =
        (manager !== null && manager !== undefined
            ? findManager(manager)
            : undefined) || {};

    if (status === 'error') {
        return (
            <LoaderWrapper data-transparent>
                <Typography variant="title.large">
                    Что-то пошло не так...
                </Typography>
                <Typography variant="body.medium">
                    Проверьте ваше подключение к сети
                </Typography>
            </LoaderWrapper>
        );
    }

    return status === 'connected' ? (
        <SupportChatWrapper>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    height: '56px',
                    px: 1,
                    bgcolor: 'white',
                    borderBottom: '1px solid #EFFBEF',
                }}
            >
                <Stack direction="row" spacing={1}>
                    {!chatDisabled ? (
                        <Tooltip
                            title="Передать чат"
                            arrow
                            placement="bottom"
                            enterDelay={1000}
                        >
                            <IconButton
                                onClick={() => setChatTransferOpen(true)}
                                color="primary"
                            >
                                <SVGAsset src="icons.24px.userTransfer" />
                            </IconButton>
                        </Tooltip>
                    ) : undefined}
                    {/* <Tooltip
                        title="Архивировать чат"
                        arrow
                        placement="bottom"
                        enterDelay={1000}
                    >
                        <IconButton onClick={onArchive} color="primary">
                            <SVGAsset src="icons.24px.archive" />
                        </IconButton>
                    </Tooltip> */}
                    {!chatDisabled ? (
                        <Tooltip
                            title="Завершить чат"
                            arrow
                            placement="bottom"
                            enterDelay={1000}
                        >
                            <IconButton onClick={onChatFinish} color="primary">
                                <SVGAsset src="icons.24px.checkSquare" />
                            </IconButton>
                        </Tooltip>
                    ) : undefined}
                    <Tooltip
                        title={
                            email ? `Закреплен за ${getShortEmail(email)}` : ''
                        }
                        arrow
                        placement="bottom"
                        enterDelay={1000}
                    >
                        <Stack
                            justifyContent="center"
                            sx={{ cursor: 'pointer' }}
                        >
                            {email ? (
                                <Typography $color="green.betta">
                                    {getShortEmail(email)}
                                </Typography>
                            ) : undefined}
                        </Stack>
                    </Tooltip>
                </Stack>
                <Tooltip
                    title="Свернуть чат"
                    arrow
                    placement="bottom"
                    enterDelay={1000}
                >
                    <IconButton onClick={onClose}>
                        <SVGAsset src="icons.24px.closeX" />
                    </IconButton>
                </Tooltip>
            </Stack>
            {chatID ? (
                <ChatControl
                    chatOwner="manager"
                    messages={chatMessages}
                    onRequestSendMessage={sendMessage}
                    chatDisabled={chatDisabled}
                    avatar={guestAvatar as HumanAvatarProps['human']}
                    chatID={chatID}
                />
            ) : undefined}
            <ChatTransfer
                chatID={chatID}
                open={chatTransferOpen}
                onClose={() => setChatTransferOpen(false)}
                onTransfer={onChatTransfer}
            />
        </SupportChatWrapper>
    ) : (
        <LoaderWrapper data-transparent>
            <CircularProgress color="success" variant="indeterminate" />
        </LoaderWrapper>
    );
};
