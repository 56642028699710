import { HashLink } from 'react-router-hash-link';

export const hashLink = HashLink;

const scrollWithOffset = (el: HTMLElement): void => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset });
};

hashLink.defaultProps = {
    scroll: scrollWithOffset,
};
