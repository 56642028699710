import { ComponentPropsWithoutRef, useEffect, useState } from 'react';

import { Badge, Stack, Tab, Tabs } from '@mui/material';

import { Typography } from 'components/atoms/Typography';
import { useUser } from 'hooks/api/auth/useUser';
import { useChatList } from 'hooks/api/chat/useChatList';
import { useNotifications } from 'hooks/api/integrations/useNotifications';
import { ChatList } from 'types/api/chatList';

import { ManagerChatList } from './ChatList';
import * as Style from './Support.styled';
import { SupportChatControl } from './SupportChat';
import { TgAuthScreen } from './TgAuth';

interface TabPanelProps extends ComponentPropsWithoutRef<'div'> {
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
    const { children, value, index, ...other } = props;

    return (
        <Style.TabPanel
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            {...other}
        >
            {children}
        </Style.TabPanel>
    );
};

export const SupportPage = (): JSX.Element => {
    const [currentTab, setCurrentTab] = useState(0);
    const [unreadCount, setUnreadCount] = useState(0);
    const [selectedChat, setSelectedChat] = useState<ChatList>();

    const { user } = useUser();

    const tgIntegrationRequired = !user?.integrations?.telegram;

    const { chatList: chats, error, getChatList } = useChatList();

    useNotifications(
        {
            onNewChatAttach: (chat) => {
                if (chat.id === selectedChat?.id) {
                    setSelectedChat(undefined);
                }
            },
            onNewChat: (chat) => {
                if (chat.id === selectedChat?.id) {
                    setSelectedChat(undefined);
                }
            },
        },
        [selectedChat]
    );

    const [chatList, setChatList] = useState<ChatList[]>();

    useEffect(() => {
        setChatList(chats);
    }, [chats]);

    return (
        <Style.SupportPageWrapper>
            <Style.SupportPageContent>
                {!tgIntegrationRequired ? (
                    <>
                        <Style.InboxWrapper>
                            <Typography variant="title.large">
                                Обращения пользователей
                            </Typography>
                            <div>
                                <Tabs
                                    variant="fullWidth"
                                    value={currentTab}
                                    onChange={(e, value) =>
                                        setCurrentTab(value)
                                    }
                                >
                                    <Tab
                                        label={
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{ width: '100%' }}
                                            >
                                                <Typography variant="title.medium">
                                                    Входящие
                                                </Typography>
                                                <Badge
                                                    badgeContent={unreadCount}
                                                    color="secondary"
                                                    sx={
                                                        unreadCount > 0
                                                            ? { ml: '16px' }
                                                            : undefined
                                                    }
                                                />
                                            </Stack>
                                        }
                                    />
                                    <Tab
                                        label={
                                            <Typography variant="title.medium">
                                                Обработанные
                                            </Typography>
                                        }
                                    />
                                </Tabs>
                            </div>

                            <TabPanel value={currentTab} index={0}>
                                <ManagerChatList
                                    onUnreadCount={setUnreadCount}
                                    onChatSelect={setSelectedChat}
                                    selectedChat={selectedChat?.id}
                                    chatList={chatList}
                                    error={!!error}
                                    onRequestReload={() => getChatList()}
                                />
                            </TabPanel>
                            <TabPanel value={currentTab} index={1}>
                                <ManagerChatList
                                    onChatSelect={setSelectedChat}
                                    showCompletedOnly
                                    selectedChat={selectedChat?.id}
                                    chatList={chatList}
                                    error={!!error}
                                    onRequestReload={() => getChatList()}
                                />
                            </TabPanel>
                        </Style.InboxWrapper>
                        <Style.ChatWrapper>
                            {!selectedChat ? (
                                <Style.ChatWindowMessageWrapper>
                                    <Typography variant="title.large">
                                        Выберите обращение слева, чтобы открыть
                                        чат
                                    </Typography>
                                </Style.ChatWindowMessageWrapper>
                            ) : (
                                <SupportChatControl
                                    chatID={selectedChat.id}
                                    chatToken={selectedChat.token}
                                    onClose={() => setSelectedChat(undefined)}
                                    onFinish={(id) =>
                                        setChatList(
                                            chatList?.filter(
                                                (el) => el.id !== id
                                            )
                                        )
                                    }
                                    chatDisabled={
                                        selectedChat.status === 'finished'
                                    }
                                />
                            )}
                        </Style.ChatWrapper>
                    </>
                ) : (
                    <TgAuthScreen />
                )}
            </Style.SupportPageContent>
        </Style.SupportPageWrapper>
    );
};
