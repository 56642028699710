import { useCallback } from 'react';

import { useMeta } from 'hooks/api/useMeta';
import { User } from 'types/api/user';
import { UserList } from 'types/api/userList';

import { useUser } from '../auth/useUser';

export const useFindStaff = (): {
    findManager: (id: number) => UserList | User | undefined;
} => {
    const { meta } = useMeta('staff');
    const { user } = useUser();

    const findManager = useCallback(
        (id: number) => {
            return (
                meta?.find((el) => el.id === id) ||
                (user?.id === id ? user : undefined)
            );
        },
        [meta, user]
    );

    return { findManager };
};
