import styled from 'styled-components';

export const CheckboxItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0;
    color: ${({ theme }) => theme.palette.common.grey};
`;

// NEW CHECKBOX SNIPPET

export const CheckboxContainer = styled.div`
    display: block;
    margin-right: 8px;
    margin-top: 4px;
`;

export const Icon = styled.svg`
    fill: none;
    stroke: ${({ theme }) => theme.palette.common.grey};
    stroke-width: 3px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    visibility: hidden;
    display: none;
    width: 1px;
    height: 1px;
`;

interface CheckboxProps {
    readonly checked: boolean;
}

export const StyledCheckbox = styled.div<CheckboxProps>`
    display: inline-block;
    width: 18px;
    height: 18px;
    background: ${({ theme }) =>
        (props) =>
            props.checked ? theme.palette.green.gamma : 'transparent'};
    border: 2px solid ${({ theme }) => theme.palette.common.grey};
    border-radius: 3px;
    transition: all 150ms;

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px #28282822;
    }

    ${Icon} {
        visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    }
`;

export const CheckboxItemLabel = styled.label`
    display: flex;
    align-items: center;
    color: ${(props) =>
        props.defaultChecked
            ? ({ theme }) => theme.palette.grey.inactive
            : undefined};
`;
