import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Typography } from 'components/atoms/Typography';

import { DropdownProps } from './types';

type ToggleProps = {
    chevronColor?: 'white' | 'black';
    chevronReverse?: boolean;
};

export const DropdownWrapper = styled.div<DropdownProps>`
    background: ${({ theme, transparent }) =>
        transparent ? undefined : theme.palette.glass.green};
    border-radius: ${({ theme }) => theme.borderRadius.large};
    overflow: hidden;
    margin: 0 -16px;
`;

export const DropDownToggleContent = styled.div``;

export const DropdownToggle = styled.button<ToggleProps>`
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: inherit;
    width: 100%;

    svg {
        transform: rotate(
            ${(props) => (props.chevronReverse ? '180deg' : '0')}
        );
        path {
            stroke: ${(props) => props.chevronColor};
        }
    }
`;

export const DropdownTitle = styled(Typography)`
    text-align: left;
`;

export const DropdownContent = styled(motion.div)`
    padding: 16px;
    padding-top: 0;
    transition: visibility 0.3s ease-in-out;
`;
