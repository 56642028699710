import answer from './answer.svg';
import archive from './archive.svg';
import checkSquare from './check-square.svg';
import check from './checkmark.svg';
import checkDouble from './checkmarkDone.svg';
import chevron from './chevron.svg';
import covid from './covid.svg';
import edit from './edit.svg';
import mapPin from './editor/map-pin.svg';
import eyeClose from './eye-close.svg';
import eyeOpen from './eye-open.svg';
import home from './home.svg';
import logout from './log-out.svg';
import map from './map.svg';
import messageCircle from './message-circle.svg';
import messageSquare from './message-square.svg';
import paperclip from './paperclip.svg';
import pharmacy from './pharmacy.svg';
import phoneCall from './phone-call.svg';
import plusCircle from './plus-circle.svg';
import questions from './questions.svg';
import searchNoBg from './search-no-bg.svg';
import search from './search.svg';
import send from './send.svg';
import services from './services.svg';
import signUp from './sign-up.svg';
import trash from './trash.svg';
import unarchive from './un-archive.svg';
import users from './users.svg';
import userTransfer from './userTransfer.svg';
import closeX from './x.svg';

export const ICONS_24PX_MAP = {
    answer,
    archive,
    chevron,
    covid,
    home,
    map,
    mapPin,
    checkDouble,
    pharmacy,
    questions,
    search,
    searchNoBg,
    services,
    signUp,
    messageCircle,
    messageSquare,
    phoneCall,
    closeX,
    send,
    paperclip,
    userTransfer,
    checkSquare,
    check,
    edit,
    users,
    unarchive,
    logout,
    eyeClose,
    eyeOpen,
    trash,
    plusCircle,
};
