import { configureStore } from '@reduxjs/toolkit';

import { appLocationsSliceReducer } from './slices/appLocationsSlice';
import { appTitleSliceReducer } from './slices/appTitleSlice';
import { authSliceReducer } from './slices/authSlice';
import { externalHrefSliceReducer } from './slices/externalHrefSlice';
import { gallerySliceReducer } from './slices/gallerySlice';
import { mapInstanceSliceReducer } from './slices/mapInstanceSlice';
import { notificationsSliceReducer } from './slices/notificationsSlice';
import { pageBuilderSliceReducer } from './slices/pageBuilderSlice';
import { pointBuilderSliceReducer } from './slices/pointBuilderSlice';
import { sharedMetaSliceReducer } from './slices/sharedMeta';
import { usersSliceReducer } from './slices/usersSlice';

export const store = configureStore({
    reducer: {
        appTitle: appTitleSliceReducer,
        mapInstance: mapInstanceSliceReducer,
        externalHref: externalHrefSliceReducer,
        gallery: gallerySliceReducer,
        auth: authSliceReducer,
        notifications: notificationsSliceReducer,
        appLocations: appLocationsSliceReducer,
        sharedMeta: sharedMetaSliceReducer,
        users: usersSliceReducer,
        pageBuilder: pageBuilderSliceReducer,
        pointBuilder: pointBuilderSliceReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
