import styled from 'styled-components';

export const PagePreviewContainer = styled.div`
    flex: 1;
    position: relative;

    .react-transform-wrapper {
        position: absolute;
        top: 29px;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
    }
`;

export const PreviewToolbar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 28px;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none !important;
    }

    background: linear-gradient(
        to bottom,
        ${({ theme }) => theme.palette.grey.bg2} 0%,
        ${({ theme }) => theme.palette.grey.bg2} 70%,
        transparent 100%
    );
`;
