import styled, { css } from 'styled-components';

import { ActionButtonProps } from './types';

const commonButtonStyles = css`
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.common.white};
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.large};
    justify-content: space-between;
    white-space: pre-line;
    text-align: left;
`;

const greenButtonStyles = css`
    background: ${({ theme }) => theme.palette.gradients.green};
`;

const yellowButtonStyles = css`
    background: ${({ theme }) => theme.palette.gradients.yellow};
`;

const redButtonStyles = css`
    background: ${({ theme }) => theme.palette.gradients.red};
`;

const defaultButtonStyles = css`
    background: ${({ theme }) => theme.palette.gradients.grey};
    color: ${({ theme }) => theme.palette.common.black};
`;

const extraLargeButtonStyle = css`
    padding: 24px;
    padding-right: 16px;
    ${({ theme }) => theme.typography.headline.large}

    svg {
        height: 56px;
        width: auto;
    }
`;

const largeButtonStyles = css`
    padding: 16px 24px;
    padding-right: 16px;
    ${({ theme }) => theme.typography.headline.large}

    svg {
        height: 56px;
        width: auto;
    }
`;

const mediumButtonStyles = css`
    padding: 20px 24px;
    padding-right: 16px;
    ${({ theme }) => theme.typography.headline.small}

    svg {
        height: 40px;
        width: auto;
    }
`;

const smallButtonStyles = css`
    padding: 14px 16px;
    ${({ theme }) => theme.typography.title.large}

    svg {
        height: 40px;
        width: auto;
    }
`;

const extraSmallButtonStyles = css`
    padding: 18px 16px;
    padding-right: 7px;
    ${({ theme }) => theme.typography.title.small}

    svg {
        height: 24px;
        width: auto;
    }
`;

export const StyledActionButton = styled.button<ActionButtonProps>`
    ${commonButtonStyles}

    ${(props) => (props.size === 'xl' ? extraLargeButtonStyle : undefined)}
    ${(props) => (props.size === 'lg' ? largeButtonStyles : undefined)}
    ${(props) => (props.size === 'md' ? mediumButtonStyles : undefined)}
    ${(props) => (props.size === 'sm' ? smallButtonStyles : undefined)}
    ${(props) => (props.size === 'xs' ? extraSmallButtonStyles : undefined)}

    ${(props) => (props.$bgColor === 'green' ? greenButtonStyles : undefined)}
    ${(props) => (props.$bgColor === 'yellow' ? yellowButtonStyles : undefined)}
    ${(props) => (props.$bgColor === 'red' ? redButtonStyles : undefined)}
    ${(props) =>
        props.$bgColor === 'default' ? defaultButtonStyles : undefined}

    &:disabled {
        opacity: 0.5;
    }

    .handGreen,
    .handRed,
    .handYellow {
        margin-top: -30px;
        margin-bottom: -25px;
        margin-right: -12px;
    }
    .humans {
        margin-bottom: -18px;
        margin-top: -43px;
        width: 81px;
        height: 81px;
    }
`;

StyledActionButton.defaultProps = {
    size: 'sm',
    $bgColor: 'default',
};

export const ActionButtonColorBadge = styled.div<
    Pick<ActionButtonProps, '$bgColor'>
>`
    ${(props) => (props.$bgColor === 'green' ? greenButtonStyles : undefined)}
    ${(props) => (props.$bgColor === 'yellow' ? yellowButtonStyles : undefined)}
    ${(props) => (props.$bgColor === 'red' ? redButtonStyles : undefined)}
    ${(props) =>
        props.$bgColor === 'default' ? defaultButtonStyles : undefined}

    width: 20px;
    height: 20px;
    border-radius: 50%;
`;
