import { useEffect, useRef, useState } from 'react';

import { motion, Variants } from 'framer-motion';

import { SVGAsset } from 'components/atoms/SVGAsset';

import {
    DropdownContent,
    DropdownTitle,
    DropdownToggle,
    DropDownToggleContent,
    DropdownWrapper,
} from './DropDown.styled';
import type { DropdownProps } from './types';

const dropdownChevronVariants: Variants = {
    open: {
        rotateX: 180,
    },
    closed: {
        rotateX: 0,
    },
};

export const Dropdown = (props: DropdownProps): JSX.Element => {
    const {
        dropdownTitle,
        subTitle,
        children,
        titleSize = 'large',
        defaultOpen,
        chevronColor = 'black',
        chevronReverse = false,
        ...rest
    } = props;

    const preview = document.body.hasAttribute('data-preview');

    const [active, setActive] = useState(!!defaultOpen || preview);

    const contentRef = useRef<HTMLDivElement>(null);

    const [contentHeight, setContentHeight] = useState(0);

    const handleHeightChange = (): void => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.offsetHeight + 16);
        }
    };

    useEffect(() => {
        handleHeightChange();

        window.addEventListener('resize', handleHeightChange);

        return () => {
            window.removeEventListener('resize', handleHeightChange);
        };
    }, []);

    useEffect(() => {
        handleHeightChange();
    }, [active, children]);

    const dropdownVariants: Variants = {
        open: (custom) => ({
            opacity: 1,
            visibility: 'visible',
            height: custom,
            overflow: 'visible',
            paddingBottom: 16,
        }),
        closed: {
            opacity: 0,
            height: 0,
            visibility: 'hidden',
            overflow: 'hidden',
            paddingBottom: 0,
        },
    };

    return (
        <DropdownWrapper {...rest}>
            <DropdownToggle
                onClick={() => setActive(!active)}
                data-active={active}
                chevronColor={chevronColor}
                chevronReverse={chevronReverse}
            >
                <DropDownToggleContent>
                    <DropdownTitle variant={`title.${titleSize}`}>
                        {dropdownTitle}
                    </DropdownTitle>
                    {subTitle ? (
                        <DropdownTitle variant="title.small">
                            {subTitle}
                        </DropdownTitle>
                    ) : undefined}
                </DropDownToggleContent>
                <motion.span
                    variants={dropdownChevronVariants}
                    animate={active ? 'open' : 'closed'}
                    transition={preview ? { duration: 0 } : undefined}
                >
                    <SVGAsset src="icons.24px.chevron" />
                </motion.span>
            </DropdownToggle>
            <DropdownContent
                variants={dropdownVariants}
                initial={defaultOpen ? 'open' : 'closed'}
                animate={active ? 'open' : 'closed'}
                custom={contentHeight}
                transition={preview ? { duration: 0 } : undefined}
            >
                <div ref={contentRef}>{children}</div>
            </DropdownContent>
        </DropdownWrapper>
    );
};

export type { DropdownProps } from './types';
