import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { setAuthUser } from 'store/slices/authSlice';
import { User } from 'types/api/user';

type UseUserReturn = {
    user?: User;
    setUser: (u: User) => void;
    resetUser: () => void;
};

export const useUser = (): UseUserReturn => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.value.user);

    const setUser = useCallback(
        (u: User) => {
            dispatch(setAuthUser(u));
        },
        [dispatch]
    );

    const resetUser = useCallback(() => {
        dispatch(setAuthUser(undefined));
    }, [dispatch]);

    return { user, setUser, resetUser };
};
