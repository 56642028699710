import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { set } from 'store/slices/externalHrefSlice';

interface IUseExternalHrefReturn {
    externalHref?: string;
    setExternalHref: (value?: string) => void;
}

export const useExternalHref = (): IUseExternalHrefReturn => {
    const externalHref = useAppSelector((state) => state.externalHref.value);

    const dispatch = useAppDispatch();

    const setExternalHref = (value?: string): void => {
        dispatch(set(value));
    };

    return { externalHref, setExternalHref };
};
