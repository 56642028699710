import {
    FormControl,
    InputLabel,
    MenuItem,
    Stack,
    Select,
} from '@mui/material';

import { BoxProps } from 'components/atoms/Grid';
import { BoxConfig } from 'types/builder/componentConfig';

type BoxControlProps = {
    node?: BoxConfig;
    onChange: (node?: BoxConfig) => void;
};

type BgVariant = {
    value: BoxProps['bgVariant'];
    title: string;
};

const bgVariants: BgVariant[] = [
    {
        value: 'message',
        title: 'Сообщение',
    },
    {
        value: 'paper',
        title: 'Панель',
    },
];

export const BoxControl = ({
    onChange,
    node,
}: BoxControlProps): JSX.Element => {
    const { bgVariant } = node?.props || {};

    const onSizeChange = (value: BoxProps['bgVariant']): void => {
        onChange({
            component: 'box',
            props: {
                ...node?.props,
                bgVariant: value,
            },
        });
    };

    return (
        <Stack spacing={2}>
            <FormControl variant="outlined" size="small">
                <InputLabel id="base-route-label">Вариант</InputLabel>
                <Select
                    labelId="base-route-label"
                    label="Вариант"
                    value={bgVariant || 'empty'}
                >
                    {bgVariants.map((el, i) => (
                        <MenuItem
                            key={`menu_item_${i}`}
                            value={el.value}
                            onClick={() => onSizeChange(el.value)}
                        >
                            {el.title}
                        </MenuItem>
                    ))}
                    <MenuItem
                        value="empty"
                        onClick={() => onSizeChange(undefined)}
                    >
                        Пустой
                    </MenuItem>
                </Select>
            </FormControl>
        </Stack>
    );
};
