import { SHAPES_HUMANS_MAP } from 'assets/shapes/humans';
import { ContentWrapper } from 'components/atoms/Grid';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { ButtonWithHuman } from 'components/molecules/Buttons/ButtonWithHuman';
import { TopAppBarLogoWrapper } from 'components/templates/layouts/PageLayout/TopAppBar/TopAppBar.styled';
import { NestedKeyOf } from 'types/utils';

import {
    CharachtersWrapper,
    SelectorContentWrapper,
    WelcomeMsgContent,
    WelcomeMsgTextWrapper,
    WelcomeMsgWrapper,
} from './CharachetSelector.styled';

interface CharachterSelectorOptions {
    name: string;
    character: NestedKeyOf<typeof SHAPES_HUMANS_MAP>;
}

const CHARACTER_OPTIONS: CharachterSelectorOptions[] = [
    {
        name: 'Петя',
        character: 'manTeenV2',
    },
    {
        name: 'Маша',
        character: 'womanTeenV2',
    },
    {
        name: 'Пётр',
        character: 'manAdultV2',
    },
    {
        name: 'Мария',
        character: 'womanAdultV2',
    },
    {
        name: 'Петрович',
        character: 'manGrandV2',
    },
    {
        name: 'Маруся',
        character: 'womanGrandV2',
    },
];

interface ICharacterSelectorProps {
    onSelect?: (character: CharachterSelectorOptions['character']) => void;
}

export const CharacterSelector = ({
    onSelect,
}: ICharacterSelectorProps): JSX.Element => {
    const onCharacterSelect = (
        character: CharachterSelectorOptions['character']
    ): void => {
        if (typeof onSelect === 'function') {
            onSelect(character);
        }
    };

    return (
        <div>
            <WelcomeMsgWrapper>
                <ContentWrapper>
                    <WelcomeMsgContent>
                        <WelcomeMsgTextWrapper>
                            <Typography variant="title.large">
                                Добро пожаловать!
                            </Typography>
                            <Typography variant="title.small">
                                Информационный помощник клинической больницы №67
                            </Typography>
                        </WelcomeMsgTextWrapper>
                        <div
                            style={{
                                width: '75px',
                                height: '50px',
                                position: 'relative',
                            }}
                        >
                            <TopAppBarLogoWrapper
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                }}
                            >
                                <SVGAsset src="logos.blueLogo" />
                            </TopAppBarLogoWrapper>
                            <TopAppBarLogoWrapper
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                }}
                            >
                                <SVGAsset
                                    src="logos.logo67"
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                    }}
                                />
                            </TopAppBarLogoWrapper>
                        </div>
                    </WelcomeMsgContent>
                </ContentWrapper>
            </WelcomeMsgWrapper>
            <SelectorContentWrapper>
                <Typography variant="title.large">
                    Выберите персонажа, который будет помогать&nbsp;вам
                </Typography>
                <CharachtersWrapper>
                    {CHARACTER_OPTIONS.map((el, i) => (
                        <div key={`character_option_${i}`}>
                            <ButtonWithHuman
                                human={el.character}
                                onClick={() => onCharacterSelect(el.character)}
                            >
                                {el.name}
                            </ButtonWithHuman>
                        </div>
                    ))}
                </CharachtersWrapper>
            </SelectorContentWrapper>
        </div>
    );
};
