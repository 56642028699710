import styled from 'styled-components';

export const AuthPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .shapes {
        position: absolute;
        z-index: 0;
        transform: scale(4);
    }

    overflow: hidden;
`;

export const AuthFormWrapper = styled.form`
    max-width: 392px;
    width: 100%;
    margin: 64px 0;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: ${({ theme }) => theme.borderRadius.large};

    position: relative;
`;

export const AuthFormContent = styled.div`
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
        &:not(.logos) {
            width: 100%;
        }
        .logos {
            svg {
                margin: 0 auto;
            }
            &.tg svg {
                width: 90px;
                height: 90px;
            }
        }
        &:not(:first-child) {
            margin-top: 40px;
        }
    }
`;
