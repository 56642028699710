import { ComponentPropsWithoutRef } from 'react';

import { SHAPES_HUMANS_MAP } from 'assets/shapes/humans';
import { SVGAsset } from 'components/atoms/SVGAsset';
import useLocalStorage from 'hooks/useLocalStorage';
import { QuizProgress } from 'types/builder/quizConfig';
import { NestedKeyOf } from 'types/utils';

import { HumanAvatarWrapper } from './HumanAvatar.styled';

export type HumanAvatarProps = ComponentPropsWithoutRef<'div'> & {
    human?: NestedKeyOf<typeof SHAPES_HUMANS_MAP>;
    size?: 'thumbnail' | 'small' | 'medium' | 'large';
    hidden?: false | true;
};

export const HumanAvatar = (props: HumanAvatarProps): JSX.Element => {
    const { human, size = 'small', hidden = false, ...rest } = props;

    const [progress] = useLocalStorage<QuizProgress>('info67quizProgress', {});

    const { character = 'manAdultV2' } = progress;

    return (
        <HumanAvatarWrapper {...rest} size={size} hidden={hidden}>
            <SVGAsset src={`shapes.humans.${human || character}`} />
        </HumanAvatarWrapper>
    );
};
