import { useEffect } from 'react';

import useAxios from 'hooks/api/useAxios';

import { useIsAuthenticated } from './useIsAuthenticated';
import { useUser } from './useUser';

type UseSignOutReturn = {
    signOut: () => void;
    success: boolean;
    loading: boolean;
};

export const useSignOut = (): UseSignOutReturn => {
    const { setAuthenticated } = useIsAuthenticated();
    const { resetUser } = useUser();

    const [res, { rerun }] = useAxios(
        {
            url: '/api/account/sign/out/',
            method: 'GET',
            skipRequest: () => true,
        },
        []
    );
    const success = res.type === 'success';
    const loading = res.type === 'loading';

    const signOut = (): void => {
        rerun();
    };

    useEffect(() => {
        if (success) {
            setAuthenticated(false);
            resetUser();
        }
    }, [resetUser, setAuthenticated, success]);

    return { signOut, success, loading };
};
