import {
    ChangeEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import {
    CircularProgress,
    Divider,
    IconButton,
    InputAdornment,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    TextField,
} from '@mui/material';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { FixedSizeList as List } from 'react-window';

import { useMeta } from 'hooks/api/useMeta';
import { SelectedLocation } from 'types/map/location';
import { highlightInText } from 'utils/highlightInText';

import {
    SearchListContainer,
    SearchListScroll,
} from './LocationSelectControl.styled';

type LocationSelectControlProps = {
    onClose?: () => void;
    selectedLocation?: SelectedLocation;
    onLocationSelect?: (location: SelectedLocation) => void;
    filter?: SelectedLocation['id'];
};

const SearchPaneVariants: Variants = {
    open: {
        opacity: 1,
        visibility: 'visible',
    },
    closed: {
        opacity: 0,
        visibility: 'hidden',
    },
};

export const LocationSelectControl = ({
    onClose = () => undefined,
    onLocationSelect = () => undefined,
    selectedLocation,
    filter,
}: LocationSelectControlProps): JSX.Element => {
    const [searchValue, setSearchValue] = useState(
        selectedLocation?.name || ''
    );

    const onSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        setSearchValue(value);
    };

    const onClearButtonClick = useCallback(() => {
        setSearchValue('');
    }, []);

    const { meta: locations } = useMeta('guestLocations');
    const { meta: buildings } = useMeta('guestBuildings');
    const { meta: floors } = useMeta('guestFloors');

    const loading = useMemo(() => {
        return !locations || !floors || !buildings;
    }, [locations, floors, buildings]);

    const locationsList = useMemo(() => {
        if (locations && floors && buildings) {
            return locations
                .map((el) => ({
                    ...el,
                    floor: floors.find((floor) => floor.id === el.floor),
                    building: buildings.find(
                        (building) => building.id === el.building
                    ),
                }))
                .filter((el) =>
                    filter !== undefined ? el.id !== filter : true
                )
                .filter((el) => !el.is_hidden);
        }
        return [];
    }, [locations, floors, buildings, filter]);

    const listWrapperRef = useRef<HTMLDivElement>(null);

    const [listHeight, setListHeight] = useState(0);

    const handleResize = (): void => {
        const listWrapper = listWrapperRef.current;

        if (listWrapper) {
            setListHeight(listWrapper.clientHeight);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            handleResize();
        }, 100);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [listWrapperRef]);

    const indexedLocationList = useMemo(() => {
        return locationsList
            .map((item) => {
                const title = highlightInText(item.name, searchValue);
                const location = `${item.building?.name} > ${item.floor?.floor_number} этаж`;
                const locationInfo = highlightInText(location, searchValue);

                return {
                    ...item,
                    indexed: title.match || locationInfo.match,
                    name: title.element,
                    locationInfo: locationInfo.element,
                };
            })
            .filter((el) => (searchValue ? el.indexed : true));
    }, [locationsList, searchValue]);

    const onSelect = useCallback(
        (id: number): void => {
            const location = locationsList.find((el) => el.id === id);

            if (location) {
                setSearchValue(location.name);
                onLocationSelect(location);
                onClose();
            }
        },
        [locationsList, onLocationSelect, onClose]
    );

    const locationsVirtualizedList = useMemo(
        () =>
            listHeight ? (
                <motion.div
                    key="search-pane"
                    className="search-pane-container"
                    variants={SearchPaneVariants}
                    initial="closed"
                    animate="open"
                    exit="closed"
                    transition={{
                        duration: 0.2,
                    }}
                >
                    <List
                        itemCount={indexedLocationList.length}
                        width="100%"
                        height={listHeight}
                        itemSize={55}
                    >
                        {({ index, style }) => {
                            const el = indexedLocationList[index];

                            return (
                                <div style={style}>
                                    <ListItem
                                        disablePadding
                                        secondaryAction={<NorthEastIcon />}
                                    >
                                        <ListItemButton
                                            onClick={() => onSelect(el.id)}
                                            selected={
                                                el.id === selectedLocation?.id
                                            }
                                        >
                                            <ListItemText
                                                primary={el.name}
                                                secondary={el.locationInfo}
                                                sx={{ my: 0 }}
                                                className={
                                                    el.indexed
                                                        ? 'indexed'
                                                        : 'undefined'
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    {index !==
                                    indexedLocationList.length - 1 ? (
                                        <Divider />
                                    ) : undefined}
                                </div>
                            );
                        }}
                    </List>
                </motion.div>
            ) : undefined,
        [indexedLocationList, listHeight, onSelect, selectedLocation?.id]
    );

    return (
        <Stack sx={{ flex: 1, pointerEvents: 'initial' }}>
            <TextField
                placeholder="Поиск"
                size="small"
                fullWidth
                value={searchValue}
                onChange={onSearch}
                disabled={loading}
                autoFocus
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {loading ? (
                                <IconButton
                                    size="small"
                                    sx={{
                                        m: -1,
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <CircularProgress
                                        size={21}
                                        sx={{ p: 0.2 }}
                                        variant="indeterminate"
                                        color="inherit"
                                    />
                                </IconButton>
                            ) : (
                                <IconButton
                                    size="small"
                                    sx={{ m: -1, p: 0 }}
                                    onClick={onClose}
                                >
                                    <ChevronLeftIcon
                                        color="inherit"
                                        fontSize="large"
                                    />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchValue.length ? (
                                <IconButton
                                    size="small"
                                    sx={{ m: -1 }}
                                    onClick={onClearButtonClick}
                                >
                                    <CancelOutlinedIcon />
                                </IconButton>
                            ) : undefined}
                        </InputAdornment>
                    ),
                }}
            />
            <SearchListContainer>
                <SearchListScroll ref={listWrapperRef}>
                    <AnimatePresence exitBeforeEnter>
                        {locationsVirtualizedList}
                    </AnimatePresence>
                </SearchListScroll>
            </SearchListContainer>
        </Stack>
    );
};
