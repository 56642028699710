import { useState } from 'react';

import {
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    CircularProgress,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { Navigate, useSearchParams } from 'react-router-dom';

import { BasicButton } from 'components/atoms/BasicButton';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useSignIn } from 'hooks/api/auth/useSignIn';
import { UserAuth } from 'types/api/userAuth';

import {
    AuthFormContent,
    AuthFormWrapper,
    AuthPageWrapper,
} from './Auth.styled';

export const AuthPage = (): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false);

    const { signIn, success, loading, error } = useSignIn();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<UserAuth>();

    const onSubmit = handleSubmit((data) => {
        signIn(data);
    });

    const formHasErrors = !!Object.keys(errors).length;
    const unauthorized = error?.response?.status === 401;

    const [params] = useSearchParams('');

    const backPath = params.get('back');

    if (success) {
        return (
            <Navigate
                to={
                    backPath && backPath !== '/admin/auth' ? backPath : '/admin'
                }
                replace
            />
        );
    }

    return (
        <AuthPageWrapper>
            <SVGAsset src="shapes.stars.large" />
            <SVGAsset src="shapes.stars.small" />
            <AuthFormWrapper onSubmit={onSubmit}>
                <AuthFormContent>
                    <SVGAsset src="logos.logo67" />
                    <Stack spacing={2}>
                        <TextField
                            label="Электронная почта"
                            variant="standard"
                            fullWidth
                            {...register('email', { required: true })}
                            error={!!errors.email}
                        />
                        <TextField
                            fullWidth
                            label="Пароль"
                            variant="standard"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            color="inherit"
                                            size="small"
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        >
                                            {showPassword ? (
                                                <SVGAsset src="icons.24px.eyeClose" />
                                            ) : (
                                                <SVGAsset src="icons.24px.eyeOpen" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            {...register('password', { required: true })}
                            error={!!errors.password}
                        />
                        {error ? (
                            <Typography
                                variant="body.medium"
                                $color="red"
                                textAlign="center"
                            >
                                {unauthorized
                                    ? 'Неверное имя пользователя или пароль'
                                    : 'Что-то пошло не так...'}
                            </Typography>
                        ) : undefined}
                    </Stack>
                    <BasicButton
                        variant="primary"
                        type="submit"
                        disabled={formHasErrors || loading}
                    >
                        {loading ? (
                            <CircularProgress
                                color="inherit"
                                variant="indeterminate"
                                size={20}
                            />
                        ) : (
                            'Войти'
                        )}
                    </BasicButton>
                </AuthFormContent>
            </AuthFormWrapper>
        </AuthPageWrapper>
    );
};
