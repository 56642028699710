import styled from 'styled-components';

import { ChatFooter } from 'components/organisms/Chat/ChatControl/ChatControl.styled';
import { ChatTextBubble } from 'components/organisms/Chat/ChatMessage/ChatMessage.styled';

export const SupportChatWrapper = styled.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ${ChatFooter} {
        border-top: 1px solid ${({ theme }) => theme.palette.grey.bg2};
    }
    ${ChatTextBubble} {
        &[data-type='in'] {
            background-color: ${({ theme }) => theme.palette.glass.green};

            &::after {
                border-right: 9px solid
                    ${({ theme }) => theme.palette.glass.green};
            }
        }
    }
`;
