import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from '@mui/material';

import { Typography, TypographyProps } from 'components/atoms/Typography';
import { QuizOptions } from 'types/builder/quizConfig';

type ValueType = QuizOptions['titleSize'];

type QuizAvatarSize = {
    value: ValueType;
    title: string;
};
const titleSizes: QuizAvatarSize[] = [
    {
        value: 'large',
        title: 'Большой',
    },
    {
        value: 'medium',
        title: 'Средний',
    },
    {
        value: 'small',
        title: 'Маленький',
    },
];

type TitleSizeControlProps = {
    value: ValueType;
    onChange: (val: ValueType) => void;
};

export const TitleSizeControl = ({
    onChange,
    value,
}: TitleSizeControlProps): JSX.Element => {
    return (
        <FormControl variant="outlined" size="small">
            <InputLabel id="base-route-label">Размер заголовка</InputLabel>
            <Select
                labelId="base-route-label"
                label="Размер заголовка"
                value={value || ''}
                renderValue={(v) => (
                    <span>
                        {titleSizes.find((el) => el.value === v)?.title}
                    </span>
                )}
            >
                {titleSizes.map((el, i) => {
                    const variant =
                        `headline.${el.value}` as TypographyProps['variant'];

                    return (
                        <MenuItem
                            key={`menu_item_${i}`}
                            value={el.value}
                            onClick={() => onChange(el.value)}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                <Typography variant={variant}>
                                    {el.title}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
