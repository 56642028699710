import { useEffect, useState } from 'react';

import { Stack, Switch } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useEditUser } from 'hooks/api/staff/useEditUser';
import { useStaffData } from 'hooks/api/staff/useStaffData';
import useAxios from 'hooks/api/useAxios';

import { IconButtonArchive, IconButtonDelete } from '../Buttons/index';
import { SwitchUserRole } from '../Switches';
import * as Style from './Staff.styled';

type ProgressBarType = {
    time: number;
};
export const ProgressBar = (props: ProgressBarType): JSX.Element => {
    const { time } = props;
    return (
        <Style.ProgressBarWrapper>
            <Style.ProgressBarSpinner>
                <div />
            </Style.ProgressBarSpinner>
            <Style.ProgressBarFiller>
                <div />
            </Style.ProgressBarFiller>
            <Style.ProgressBarText>{time}</Style.ProgressBarText>
        </Style.ProgressBarWrapper>
    );
};

type UserModel = {
    email: string;
    is_archive: boolean;
    id: number;
    userRoles: number[];
    index: number;
    is_filter: boolean;
};
export const Staff = (props: UserModel): JSX.Element => {
    const { roles } = useStaffData();
    const { setDeleteUser } = useEditUser();
    const { is_archive, id, email, userRoles, index, is_filter } = props;

    const switches = roles?.map((role) => {
        let roleStatus = false;

        if (userRoles.some((userRole) => userRole === role.id)) {
            roleStatus = true;
        }

        return (
            <Stack key={role.id}>
                {!is_archive ? (
                    <SwitchUserRole
                        status={roleStatus}
                        id={id}
                        role={role.id}
                        action={roleStatus ? 'remove' : 'add'}
                    />
                ) : (
                    <Switch defaultChecked checked={false} />
                )}
            </Stack>
        );
    });

    const [is_delete, setIsDelete] = useState(false);
    const [timer, setTimer] = useState(10);

    const [res, { rerun }] = useAxios(
        {
            url: `/api/admin/user/${id}/`,
            method: 'DELETE',
            skipRequest: () => true,
        },
        []
    );

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (res.type === 'success') {
            setDeleteUser(id);
        }
        if (res.type === 'error') {
            setIsDelete(false);
            enqueueSnackbar(`Не удалось удалить пользователя`, {
                variant: 'error',
                key: 'user-delete-error',
            });
        }
    }, [res.type, id, setIsDelete, setDeleteUser, enqueueSnackbar]);

    useEffect(() => {
        let time = 10;
        setTimer(time);
        const interval = setInterval(() => {
            if (is_delete) {
                time -= 1;
                setTimer(time);
            }
            if (time === 0) {
                clearInterval(interval);
                rerun();
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_delete]);

    return (
        <Style.StaffWrapper
            index={index}
            is_filter={is_filter}
            is_delete={is_delete}
        >
            <Style.TypographyWrapper is_delete={is_delete || is_archive}>
                <Typography variant="body.large">{email}</Typography>
            </Style.TypographyWrapper>

            {!is_delete ? (
                <>
                    <Style.TogglesWrapper>{switches}</Style.TogglesWrapper>

                    <Style.ButtonsWrapper>
                        <IconButtonArchive
                            id={id}
                            is_archive={is_archive}
                            title={
                                is_archive ? 'Разархивировать' : 'Архивировать'
                            }
                            icon={
                                is_archive ? (
                                    <SVGAsset src="icons.24px.unarchive" />
                                ) : (
                                    <SVGAsset src="icons.24px.archive" />
                                )
                            }
                        />
                        <IconButtonDelete
                            setIsDelete={setIsDelete}
                            title="Удалить"
                            icon={<SVGAsset src="icons.24px.trash" />}
                        />
                    </Style.ButtonsWrapper>
                </>
            ) : (
                <Style.DeleteWrapper>
                    <Style.DeleteText>
                        <Typography variant="body.large">Удалено</Typography>
                    </Style.DeleteText>
                    <ProgressBar time={timer} />
                    <Style.RestoreButton onClick={() => setIsDelete(false)}>
                        Восстановить
                    </Style.RestoreButton>
                </Style.DeleteWrapper>
            )}
        </Style.StaffWrapper>
    );
};
