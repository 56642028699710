import { useCallback } from 'react';

import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { ContentWrapper } from 'components/atoms/Grid';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useAppLocations } from 'hooks/store/useAppLocations';

import {
    NavBarSegment,
    NavBarSegmentsWrapper,
    NavBarWrapper,
} from './NavigationBar.styled';

export const NAVBAR_SEGMENTS = [
    {
        title: 'Главная',
        icon: <SVGAsset src="icons.24px.home" />,
        href: '/home',
    },
    {
        title: 'Услуги',
        icon: <SVGAsset src="icons.24px.services" />,
        href: '/services',
    },
    {
        title: 'Карта',
        icon: <SVGAsset src="icons.24px.map" />,
        href: '/map',
    },
    {
        title: 'Аптека',
        icon: <SVGAsset src="icons.24px.pharmacy" />,
        href: '/pharmacy',
    },
    {
        title: 'Вопросы',
        icon: <SVGAsset src="icons.24px.questions" />,
        href: '/faq',
    },
];

type NavBarProps = {
    currentPath: string;
};

export const NavigationBar = ({ currentPath }: NavBarProps): JSX.Element => {
    const [prevLocations, setPrevLocations] = useAppLocations();

    const navigate = useNavigate();

    const tabClickHandler = useCallback(
        (href: string) => {
            setPrevLocations({
                ...prevLocations,
                [`/${currentPath.split('/')[1]}`]: currentPath,
            });
            navigate(href);
        },
        [currentPath, prevLocations, setPrevLocations, navigate]
    );

    const { pathname } = useLocation();

    const pageMatches = useCallback(
        (path: string) => {
            return !!matchPath(
                {
                    path,
                    end: false,
                },
                `/${pathname.split('/').filter((e) => !!e)[0]}`
            );
        },
        [pathname]
    );

    return (
        <NavBarWrapper>
            <ContentWrapper noPadding>
                <NavBarSegmentsWrapper>
                    {NAVBAR_SEGMENTS.map((el, i) => {
                        const active = pageMatches(el.href);
                        const href =
                            el.href in prevLocations
                                ? prevLocations[el.href]
                                : el.href;

                        return (
                            <li key={`navbar_segment_${i}`}>
                                <NavBarSegment
                                    className="svg-color-inherit"
                                    onClick={() =>
                                        !active
                                            ? tabClickHandler(href)
                                            : undefined
                                    }
                                    data-active={active}
                                >
                                    {el.icon}
                                    <Typography variant="label.medium">
                                        {el.title}
                                    </Typography>
                                </NavBarSegment>
                            </li>
                        );
                    })}
                </NavBarSegmentsWrapper>
            </ContentWrapper>
        </NavBarWrapper>
    );
};
