import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const CommonStyles = css`
    position: absolute;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 16px;
    border-radius: ${({ theme }) => theme.borderRadius.large};

    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
`;

const ContactButtonStyles = css`
    ${CommonStyles};
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${({ theme }) => theme.palette.green.betta};
    height: 48px;
    width: 48px;
`;

export const SupportButtonAnchor = styled.div`
    position: fixed;
    bottom: calc(96px + env(safe-area-inset-bottom));

    &[data-footer='false'] {
        bottom: calc(16px + env(safe-area-inset-bottom));
    }

    right: 16px;
`;

export const SupportButtonWrapper = styled.div`
    position: relative;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const MainButton = styled.button`
    ${CommonStyles}
    bottom: 0;
    background-color: ${({ theme }) => theme.palette.green.alpha};
    height: 56px;
    width: 56px;
    color: ${({ theme }) => theme.palette.common.black};
`;

export const MessageButton = styled(motion.button)`
    ${ContactButtonStyles}
    bottom: 72px;
`;

export const PhoneButton = styled(motion.a)`
    ${ContactButtonStyles}
    bottom: 136px;
`;
