import { useEffect } from 'react';

import {
    Dialog,
    DialogContent,
    Stack,
    FormControl,
    TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { BasicButton } from 'components/atoms/BasicButton';
import { Spacer } from 'components/atoms/Grid';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { useAddNewPoint } from 'hooks/api/builder/points/useAddNewPoint';
import { useNewLocationSelector } from 'hooks/api/builder/points/useNewLocationSelector';

type MapPointCreatorModalProps = {
    open: boolean;
    onClose: () => void;
    onSuccess: (id: number) => void;
};

export const MapPointCreatorModal = ({
    onClose,
    onSuccess,
    open,
}: MapPointCreatorModalProps): JSX.Element => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<{ name: string }>();

    const { addNewPoint, error, loading, success, id } = useAddNewPoint();

    const { location } = useNewLocationSelector();

    const onSubmit = handleSubmit((data) => {
        addNewPoint({
            uuid: uuid(),
            name: data.name,
            is_published: false,
            is_hidden: false,
            ...location,
            building: location?.building?.id,
            floor: location?.floor?.id,
        });
    });

    useEffect(() => {
        if (success && id && !error) {
            onSuccess(id);
        }
    }, [success, id, error, onSuccess]);

    useEffect(() => {
        if (success || error) {
            onClose();
        }
    }, [success, error, onClose]);

    useEffect(() => {
        reset();
    }, [open, reset]);

    return (
        <Dialog open={open} onClose={!loading ? () => onClose() : undefined}>
            <DialogContent sx={{ minWidth: '420px', padding: '32px 40px' }}>
                <Stack
                    spacing={3}
                    alignItems="center"
                    sx={{ svg: { width: '100px', height: '100px' } }}
                    component="form"
                    onSubmit={onSubmit}
                >
                    <SVGAsset src="icons.24px.mapPin" />
                    <Typography variant="title.large" textAlign="center">
                        Введите имя новой точки
                    </Typography>
                    <FormControl variant="outlined" sx={{ width: '100%' }}>
                        <TextField
                            label="Название точки"
                            size="small"
                            fullWidth
                            {...register('name', {
                                required: {
                                    value: true,
                                    message: 'Введите название точки',
                                },
                            })}
                            error={!!errors.name}
                        />
                        {errors.name?.message ? (
                            <>
                                <Spacer size="1x" />
                                <Typography $color="red" variant="body.small">
                                    {errors.name?.message}
                                </Typography>
                            </>
                        ) : undefined}
                    </FormControl>
                    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                        <BasicButton
                            variant="secondary"
                            type="submit"
                            disabled={loading || !!errors.name}
                        >
                            Сохранить
                        </BasicButton>
                        <BasicButton variant="secondary" onClick={onClose}>
                            Отменить
                        </BasicButton>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
