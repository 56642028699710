import styled from 'styled-components';

export const PreviewSizeWrapper = styled.div`
    .size-buttons {
        @media screen and (max-width: 1400px) {
            display: none;
        }
    }
    .size-select {
        min-width: 160px;
        @media screen and (min-width: 1399px) {
            display: none;
        }
    }
`;
