import { useSnackbar } from 'notistack';

import { useNotifications } from 'hooks/api/integrations/useNotifications';

export const ChatTransferProvider = (): null => {
    const { enqueueSnackbar } = useSnackbar();

    useNotifications(
        {
            onChatTransfer: (chatID, email) =>
                enqueueSnackbar(
                    `Cаппорт-менеджер ${email} передал вам чат #${chatID}`,
                    { variant: 'success' }
                ),
        },
        []
    );

    return null;
};
