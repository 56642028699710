import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import NumbersIcon from '@mui/icons-material/Numbers';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    FormControl,
    IconButton,
    InputLabel,
    List,
    MenuItem,
    Select,
    Stack,
    Typography,
    TextField,
    InputAdornment,
} from '@mui/material';
import {
    Reorder,
    animate,
    MotionValue,
    useMotionValue,
    useDragControls,
} from 'framer-motion';

import { ItemListProps } from 'components/molecules/ItemList';
import { ListConfig } from 'types/builder/componentConfig';

const inactiveShadow = '0px 0px 0px rgba(0,0,0,0.1)';

export function useRaisedShadow(
    value: MotionValue<number>
): MotionValue<string> {
    const boxShadow = useMotionValue(inactiveShadow);

    useEffect(() => {
        let isActive = false;
        value.onChange((latest) => {
            const wasActive = isActive;
            if (latest !== 0) {
                isActive = true;
                if (isActive !== wasActive) {
                    animate(boxShadow, '0 0 20px rgba(0,0,0,0.2)');
                }
            } else {
                isActive = false;
                if (isActive !== wasActive) {
                    animate(boxShadow, inactiveShadow);
                }
            }
        });
    }, [value, boxShadow]);

    return boxShadow;
}

type ListControlProps = {
    node?: ListConfig;
    onChange: (node?: ListConfig) => void;
};

type DragItemProps = {
    item: string;
    onEditMode: () => void;
    onDelete: () => void;
    editMode: boolean;
};

const DragItem = ({
    item,
    onEditMode,
    editMode,
    onDelete,
}: DragItemProps): JSX.Element => {
    const y = useMotionValue(0);
    const boxShadow = useRaisedShadow(y);
    const controls = useDragControls();

    return (
        <Reorder.Item
            value={item}
            id={item}
            style={{ boxShadow, y }}
            dragListener={false}
            dragControls={!editMode ? controls : undefined}
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                sx={{ minHeight: '32px' }}
            >
                <DragIndicatorIcon
                    onPointerDown={(e) => controls.start(e)}
                    sx={{
                        cursor: !editMode ? 'grab' : 'default',
                        opacity: !editMode ? 0.5 : 0.2,
                    }}
                />
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <Typography>{item}</Typography>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                        <IconButton
                            size="small"
                            onClick={() => onEditMode()}
                            disabled={editMode}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            color="error"
                            disabled={editMode}
                            onClick={() => onDelete()}
                        >
                            <DeleteOutlineIcon />
                        </IconButton>
                    </Stack>
                </Stack>
            </Stack>
        </Reorder.Item>
    );
};

export const ListControl = ({
    onChange,
    node,
}: ListControlProps): JSX.Element => {
    const { listStyle, items = [] } = node?.props || {};

    const onListTypeChange = (val: ItemListProps['listStyle']): void => {
        onChange({
            component: 'list',
            props: {
                ...node?.props,
                listStyle: val,
            },
        });
    };

    const [editModeId, setEditModeId] = useState<number>();
    const [value, setValue] = useState('');

    const onItemsReorder = (i: string[]): void => {
        onChange({
            component: 'list',
            props: {
                ...node?.props,
                items: i,
            },
        });
    };

    const onItemChange = (item: string, index: number): void => {
        onChange({
            component: 'list',
            props: {
                ...node?.props,
                items: items.map((el, i) => (i === index ? item : el)),
            },
        });
    };

    const onNewItemAdd = (item: string): void => {
        onChange({
            component: 'list',
            props: {
                ...node?.props,
                items: [...items, item],
            },
        });
        setValue('');
    };
    const onItemDelete = (index: number): void => {
        onChange({
            component: 'list',
            props: {
                ...node?.props,
                items: items.filter((v, i) => i !== index),
            },
        });
    };

    return (
        <Stack spacing={2}>
            <FormControl variant="outlined" size="small">
                <InputLabel id="base-route-label">Маркер списка</InputLabel>
                <Select
                    labelId="base-route-label"
                    label="Маркер списка"
                    value={listStyle || 'numbered'}
                >
                    <MenuItem
                        value="numbered"
                        onClick={() => onListTypeChange(undefined)}
                    >
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <NumbersIcon fontSize="inherit" />
                            <div>Нумерованный</div>
                        </Stack>
                    </MenuItem>
                    <MenuItem
                        value="disc"
                        onClick={() => onListTypeChange('disc')}
                    >
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <CircleIcon fontSize="inherit" />
                            <div>Точка</div>
                        </Stack>
                    </MenuItem>
                    <MenuItem
                        value="dash"
                        onClick={() => onListTypeChange('dash')}
                    >
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <RemoveIcon fontSize="inherit" />
                            <div>Дефис</div>
                        </Stack>
                    </MenuItem>
                </Select>
            </FormControl>
            <List>
                <Reorder.Group
                    axis="y"
                    values={items}
                    onReorder={onItemsReorder}
                >
                    {items.map((el, i) => (
                        <DragItem
                            key={`element_${el}_${i}`}
                            item={el}
                            onEditMode={() => {
                                setEditModeId(i);
                                setValue(items[i]);
                            }}
                            onDelete={() => onItemDelete(i)}
                            editMode={editModeId !== undefined}
                        />
                    ))}
                </Reorder.Group>
            </List>
            <FormControl variant="outlined">
                <TextField
                    label={
                        editModeId !== undefined
                            ? 'Текст элемента'
                            : 'Текст нового элемента'
                    }
                    minRows={1}
                    multiline
                    size="small"
                    fullWidth
                    value={value || ''}
                    onChange={(e) => setValue(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ mr: -1 }}>
                                {editModeId !== undefined ? (
                                    <IconButton
                                        onClick={() => {
                                            onItemChange(value, editModeId);
                                            setEditModeId(undefined);
                                            setValue('');
                                        }}
                                        disabled={!value}
                                        size="small"
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        size="small"
                                        disabled={!value}
                                        onClick={() => onNewItemAdd(value)}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
        </Stack>
    );
};
