import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { setStateNewLocation } from 'store/slices/pointBuilderSlice';
import { SelectedLocation } from 'types/map/location';

type UseNewLocationSelectorReturn = {
    mode: 'new' | 'update' | undefined;
    location: Partial<SelectedLocation> | undefined;
    setLocation: (loc?: Partial<SelectedLocation>) => void;
    setMode: (m?: 'new' | 'update') => void;
    resetAll: () => void;
};

export const useNewLocationSelector = (): UseNewLocationSelectorReturn => {
    const dispatch = useAppDispatch();

    const { location, mode } = useAppSelector(
        (s) => s.pointBuilder.newLocation
    );

    const setLocation = useCallback(
        (loc?: Partial<SelectedLocation>): void => {
            dispatch(
                setStateNewLocation({
                    location: loc,
                })
            );
        },
        [dispatch]
    );

    const setMode = useCallback(
        (m?: 'new' | 'update'): void => {
            dispatch(
                setStateNewLocation({
                    mode: m,
                })
            );
        },
        [dispatch]
    );

    const resetAll = useCallback(() => {
        dispatch(
            setStateNewLocation({
                location: undefined,
                mode: undefined,
            })
        );
    }, [dispatch]);

    return {
        mode,
        location,
        setLocation,
        setMode,
        resetAll,
    };
};
