/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from 'types/api/user';

interface AuthState {
    value: {
        authenticated: boolean;
        authChecked: boolean;
        authLoading: boolean;
        user?: User;
    };
}

const initialState: AuthState = {
    value: {
        authChecked: false,
        authenticated: false,
        authLoading: false,
        user: undefined,
    },
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.value.authenticated = action.payload;
        },
        setAuthLoading: (state, action: PayloadAction<boolean>) => {
            state.value.authLoading = action.payload;
        },
        setAuthChecked: (state, action: PayloadAction<boolean>) => {
            state.value.authChecked = action.payload;
        },
        setAuthUser: (
            state,
            action: PayloadAction<AuthState['value']['user']>
        ) => {
            state.value.user = action.payload;
        },
    },
});

export const { setAuthChecked, setAuthLoading, setAuthenticated, setAuthUser } =
    authSlice.actions;

export const authSliceReducer = authSlice.reducer;
