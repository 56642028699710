import { DependencyList, useCallback, useEffect, useState } from 'react';

import {
    ConstantBackoff,
    LRUBuffer,
    Websocket,
    WebsocketBuilder,
    WebsocketEvents,
} from 'websocket-ts/lib';

import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import {
    setWebsocket as setWebsocketAction,
    setStatus as setStatusAction,
} from 'store/slices/notificationsSlice';
import {
    NotificationEvent,
    NotificationEvents,
} from 'types/chat/notifications';
import { socketBaseUrl } from 'utils/socketBaseUrl';

type ConnectionStatus = 'closed' | 'connecting' | 'connected' | 'error';

type NewChatFragment = {
    id: number;
    token: string;
};

type UseNotificationsConfig = {
    onNewChatAttach?: (chat: NewChatFragment) => void;
    onChatTransfer?: (chatID: number, email: string) => void;
    onNewChat?: (chat: NewChatFragment) => void;
    onNewMessage?: (chatID: number) => void;
    onNewMessageGuest?: (chatID: number) => void;
    onTgAttach?: () => void;
};

type UseNotificationsReturn = {
    status: ConnectionStatus;
    connectNotifications: () => Websocket;
};

export const useNotifications = (
    config: UseNotificationsConfig,
    deps: DependencyList
): UseNotificationsReturn => {
    const [websocket, setWebsocket] = useState<Websocket>();

    const reduxSocket = useAppSelector(
        (state) => state.notifications.value.websocket
    );
    const status = useAppSelector((state) => state.notifications.value.status);
    const dispatch = useAppDispatch();

    const setStatus = useCallback(
        (s: ConnectionStatus): void => {
            dispatch(setStatusAction(s));
        },
        [dispatch]
    );

    useEffect(() => {
        if (websocket) {
            dispatch(setWebsocketAction(websocket));
        }
    }, [websocket, dispatch]);

    const connectNotifications = (): Websocket => {
        setStatus('connecting');
        const socket = new WebsocketBuilder(
            `${socketBaseUrl()}/ws/notifications/`
        )
            .onOpen((i) => {
                setWebsocket(i);
                setStatus('connected');
            })
            .onClose(() => {
                setStatus('closed');
                setWebsocket(undefined);
            })
            .onError(() => {
                setStatus('error');
                setWebsocket(undefined);
            })
            .withBuffer(new LRUBuffer(1000))
            .withBackoff(new ConstantBackoff(3000))
            .build();

        return socket;
    };

    const eventSerializer = useCallback(
        (e: MessageEvent): void => {
            const {
                onNewChatAttach = () => undefined,
                onNewChat = () => undefined,
                onNewMessage = () => undefined,
                onNewMessageGuest = () => undefined,
                onTgAttach = () => undefined,
                onChatTransfer = () => undefined,
            } = config || {};

            if (e.data && /^\s*(\{|\[)/.test(e.data)) {
                let data: NotificationEvent | undefined;
                try {
                    data = JSON.parse(e.data);
                } catch (err) {
                    return;
                }

                if (data) {
                    switch (data.event) {
                        case NotificationEvents.ManagerNewMessageInChat:
                            onNewMessage(data.payload.chat_id);
                            break;
                        case NotificationEvents.GuestNewMessageInChat:
                            onNewMessageGuest(data.payload.chat_id);
                            break;
                        case NotificationEvents.ManagerNewChatAttached:
                            onNewChatAttach(data.payload.chat);
                            break;
                        case NotificationEvents.ManagerIntegrationSuccess:
                            onTgAttach();
                            break;
                        case NotificationEvents.ManagerNewChat:
                            onNewChat(data.payload.chat);
                            break;
                        case NotificationEvents.ManagerShowTransferChat:
                            onChatTransfer(
                                data.payload.chat_id,
                                data.payload.email
                            );
                            break;
                        default:
                            break;
                    }
                }
            }
        },
        [config]
    );

    useEffect(() => {
        if (reduxSocket) {
            reduxSocket?.addEventListener(WebsocketEvents.message, (i, e) => {
                eventSerializer(e);
            });
        }
        return () => {
            if (reduxSocket) {
                reduxSocket.removeEventListener(
                    WebsocketEvents.message,
                    (i, e) => {
                        eventSerializer(e);
                    }
                );
            }
        };
    }, [reduxSocket, eventSerializer, deps]);

    return { status, connectNotifications };
};
