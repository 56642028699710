import React from 'react';

import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { App } from 'App';
import { RootProvider } from 'components/providers/RootProvider';

const root = document.getElementById('root') as HTMLElement;

render(
    <React.StrictMode>
        <RootProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </RootProvider>
    </React.StrictMode>,
    root
);
