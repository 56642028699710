import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import useAxios from 'hooks/api/useAxios';
import { Location } from 'types/api/location';

type UseAddNewPointProps = {
    onSuccess?: (point: Location) => void;
};

type UseAddNewPointReturn = {
    success: boolean;
    error: boolean;
    loading: boolean;
    addNewPoint: (point: Partial<Location>) => void;
    id: number;
};

export const useAddNewPoint = (
    options?: UseAddNewPointProps
): UseAddNewPointReturn => {
    const { onSuccess } = options || {};

    const [body, setBody] = useState<Partial<Location>>();

    const { enqueueSnackbar } = useSnackbar();

    const [res, { rerun }] = useAxios<Location>(
        {
            url: '/api/builder/map/location/',
            method: 'POST',
            skipRequest: () => true,
            data: body,
        },
        [body]
    );

    const success = res.type === 'success';
    const error = res.type === 'error';
    const loading = res.type === 'loading';
    const id = res.type === 'success' && !loading ? res.data.id : 0;

    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                'Что-то пошло не так... Не удалось добавить новую точку.',
                { variant: 'error' }
            );
        }
        if (success) {
            enqueueSnackbar('Новая точка добавлена', { variant: 'success' });
        }
    }, [error, success, enqueueSnackbar]);

    useEffect(() => {
        if (typeof onSuccess === 'function' && success) {
            onSuccess(res.data);
        }
    }, [onSuccess, res.data, success]);

    const addNewPoint = (point: Partial<Location>): void => {
        setBody(point);
        rerun();
    };

    return { success, error, loading, addNewPoint, id };
};
