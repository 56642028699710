import { useAppDispatch, useAppSelector } from 'hooks/store/useAppStore';
import { set } from 'store/slices/appTitleSlice';

interface IUseAppTitleReturn {
    appTitle: string;
    setAppTitle: (value: string) => void;
    setDefaultAppTitle: () => void;
}

export const useAppTitle = (): IUseAppTitleReturn => {
    const appTitle = useAppSelector((state) => state.appTitle.value);

    const dispatch = useAppDispatch();

    const setAppTitle = (value: string): void => {
        document.title = `${value} | Инфопомощник 67`;
        dispatch(set(value));
    };

    const setDefaultAppTitle = (): void => {
        document.title = `Инфопомощник 67`;
        dispatch(set('Инфопомощник 67'));
    };

    return { appTitle, setAppTitle, setDefaultAppTitle };
};
