import {
    Children,
    ComponentPropsWithoutRef,
    isValidElement,
    useState,
} from 'react';

import copyToClipboard from 'copy-to-clipboard';

import { BasicButton } from 'components/atoms/BasicButton';
import { CheckboxItem } from 'components/atoms/CheckboxItem';

import { ItemList } from '../ItemList';
import { CopyListWrapper } from './CopyList.styled';

export type CopyListProps = ComponentPropsWithoutRef<'div'> & {
    buttonText?: string;
};

function textContent(elem: React.ReactElement | string): string {
    if (!elem) {
        return '';
    }
    if (typeof elem === 'string') {
        return elem;
    }

    const children = elem.props?.children;

    if (elem.type === ItemList) {
        return elem.props?.items
            .map((el: string, i: number) => {
                if (elem.props?.listStyle === 'disc') {
                    return `• ${textContent(el)}`;
                }
                if (elem.props?.listStyle === 'dash') {
                    return `- ${textContent(el)}`;
                }
                return `${i + 1}. ${textContent(el)}`;
            })
            .join('\n');
    }

    if (children instanceof Array) {
        return children.map(textContent).join('\n');
    }
    if (elem.type === CheckboxItem) {
        return `- ${textContent(children)}`;
    }
    return textContent(children);
}

export const CopyList = (props: CopyListProps): JSX.Element => {
    const { children, buttonText = '', ...rest } = props;

    const [copied, setCopied] = useState(false);

    const getCopyOutput = (): string => {
        return (
            Children.map(children, (child) => {
                if (isValidElement(child)) {
                    return textContent(child);
                }
                return '';
            })?.join('\n') || ''
        );
    };

    const performCopy = (): void => {
        setCopied(true);

        const copyOutput = getCopyOutput();

        copyToClipboard(copyOutput);

        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };

    return (
        <CopyListWrapper {...rest}>
            {children}
            <BasicButton
                variant="secondary"
                onClick={() => performCopy()}
                disabled={copied}
            >
                {!copied ? `Скопировать ${buttonText}` : 'Скопировано'}
            </BasicButton>
        </CopyListWrapper>
    );
};
