import { SHAPES_HUMANS_MAP } from 'assets/shapes/humans';
import { ActionButton, ActionButtonProps } from 'components/atoms/ActionButton';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { NestedKeyOf } from 'types/utils';

type ButtonWithHumanProps = Omit<ActionButtonProps, 'size'> & {
    human?: NestedKeyOf<typeof SHAPES_HUMANS_MAP>;
};

export const ButtonWithHuman = (props: ButtonWithHumanProps): JSX.Element => {
    const { human, children, ...rest } = props;

    return (
        <ActionButton size="xs" {...rest}>
            {children}
            {human ? <SVGAsset src={`shapes.humans.${human}`} /> : undefined}
        </ActionButton>
    );
};
