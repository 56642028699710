import { useCallback, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useMeta } from 'hooks/api/useMeta';
import { SelectedLocation } from 'types/map/location';

type UseLocationUrlReturn = {
    location?: SelectedLocation;
    resetParams: () => void;
};

export const useLocationUrl = (): UseLocationUrlReturn => {
    const { meta: locations } = useMeta('guestLocations');
    const { meta: buildings } = useMeta('guestBuildings');
    const { meta: floors } = useMeta('guestFloors');

    const [params, setParams] = useSearchParams();

    const locationUuid = useMemo(() => params.get('location'), [params]);

    const locationsList = useMemo(() => {
        if (locations && floors && buildings) {
            return locations.map((el) => ({
                ...el,
                floor: floors.find((floor) => floor.id === el.floor),
                building: buildings.find(
                    (building) => building.id === el.building
                ),
            }));
        }
        return [];
    }, [locations, floors, buildings]);

    const location = useMemo(() => {
        if (locationsList && locationUuid) {
            return locationsList.find((el) => el.uuid === locationUuid);
        }
        return undefined;
    }, [locationUuid, locationsList]);

    const resetParams = useCallback(() => {
        params.delete('location');
        setParams(params);
    }, [params, setParams]);

    return { location, resetParams };
};
