import { useCallback, useEffect, useState } from 'react';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    Stack,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';

import { BasicButton } from 'components/atoms/BasicButton';
import { Typography } from 'components/atoms/Typography';
import useAxios from 'hooks/api/useAxios';

import { DropZoneWrapper } from './FileUploadControl.styled';

type FileUploadControlProps = {
    accept?: 'files' | 'images';
    onSuccess: (file: string) => void;
    id?: number | string;
};

type FileRes = {
    page: number;
    file: string;
};

export const FileUploadControl = ({
    accept,
    onSuccess,
    id,
}: FileUploadControlProps): JSX.Element => {
    const [dropZoneOpen, setDropZoneOpen] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<string | Blob>();
    const [body, setBody] = useState<FormData>();

    useEffect(() => {
        if (!dropZoneOpen || id) {
            setBody(undefined);
            setUploadedFile(undefined);
        }
    }, [id, dropZoneOpen]);

    const [res, { rerun }] = useAxios<FileRes>(
        {
            url: '/api/builder/page/storage/',
            method: 'POST',
            data: body,
            skipRequest: () => true,
        },
        [body]
    );

    const onDrop = useCallback(<T extends File>(acceptedFiles: T[]) => {
        if (acceptedFiles?.length === 1) {
            setUploadedFile(acceptedFiles[0]);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept:
            accept === 'images'
                ? {
                      'image/jpeg': [],
                      'image/png': [],
                  }
                : {
                      'application/msword': [],
                      'application/vnd.ms-excel': [],
                      'application/vnd.ms-powerpoint': [],
                      'text/plain': [],
                      'application/pdf': [],
                      'image/*': [],
                  },
    });

    const uploadFile = useCallback(() => {
        const fData = new FormData();

        fData.append('page', id?.toString() || '');
        fData.append('file', uploadedFile || '');

        setBody(fData);
        rerun();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, uploadedFile]);

    useEffect(() => {
        if (uploadedFile) {
            uploadFile();
        }
    }, [uploadFile, uploadedFile]);

    useEffect(() => {
        if (res.type === 'success') {
            onSuccess(res.data.file);
            setDropZoneOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [res.type, res.data]);

    const fileTooLarge =
        res.type === 'error' && res.data.response?.status === 429;

    return (
        <>
            <Button variant="outlined" onClick={() => setDropZoneOpen(true)}>
                Выбрать файл
            </Button>
            <Dialog
                open={dropZoneOpen}
                onClose={() => setDropZoneOpen(false)}
                maxWidth={false}
            >
                <DialogContent
                    sx={{
                        minWidth: 600,
                        minHeight: 300,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {res.type === 'idle' ? (
                        <DropZoneWrapper {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Stack spacing={4} pt="72px">
                                <Typography
                                    variant="title.large"
                                    $color="common.grey"
                                >
                                    Перетащите файл сюда
                                </Typography>
                                <BasicButton $bgColor="green">
                                    Выбрать файл
                                </BasicButton>
                            </Stack>
                        </DropZoneWrapper>
                    ) : undefined}
                    {res.type === 'loading' ? (
                        <CircularProgress variant="indeterminate" />
                    ) : undefined}
                    {res.type === 'error' ? (
                        <Stack spacing={4} pt="72px" alignItems="center">
                            <Typography
                                variant="title.large"
                                $color="common.grey"
                            >
                                {fileTooLarge
                                    ? 'Слишком большой размер файла'
                                    : 'Не удалось загрузить файл'}
                            </Typography>
                            <BasicButton
                                $bgColor="green"
                                onClick={() =>
                                    fileTooLarge
                                        ? setDropZoneOpen(false)
                                        : rerun()
                                }
                            >
                                {fileTooLarge
                                    ? 'Все понятно'
                                    : 'Попробовать еще раз'}
                            </BasicButton>
                        </Stack>
                    ) : undefined}
                </DialogContent>
            </Dialog>
        </>
    );
};
