import { Dialog, DialogContent, Stack } from '@mui/material';

import { BasicButton } from 'components/atoms/BasicButton';
import { SVGAsset } from 'components/atoms/SVGAsset';
import { Typography } from 'components/atoms/Typography';
import { usePageDelete } from 'hooks/api/builder/pages/usePageDelete';

type PageDeleteModalProps = {
    onClose: () => void;
    onSuccess: () => void;
    open: boolean;
    id: number;
    pageTitle?: string;
};

export const PageDeleteModal = ({
    onClose,
    onSuccess,
    id,
    open,
    pageTitle,
}: PageDeleteModalProps): JSX.Element => {
    const { deletePage, loading, error, success } = usePageDelete();

    const onModalCloseAction = (): void => {
        onClose();
        if (success) {
            onSuccess();
        }
    };

    const getModalContent = (): JSX.Element => {
        if (success) {
            return (
                <>
                    <SVGAsset src="icons.56px.checkCircle" />
                    <Typography variant="title.large" textAlign="center">
                        Страница {pageTitle ? ` «${pageTitle}»` : ''} удалена
                        успешно.
                    </Typography>
                </>
            );
        }
        if (error) {
            return (
                <>
                    <SVGAsset src="icons.56px.negative" />
                    <Typography variant="title.large" textAlign="center">
                        Что-то пошло не так.
                        <br />
                        Не удалось удалить страницу
                        {pageTitle ? ` «${pageTitle}»` : ''}.
                    </Typography>
                </>
            );
        }
        return (
            <>
                <SVGAsset src="icons.56px.alertTriangle" />
                <Typography variant="title.large" textAlign="center">
                    Вы уверены, что хотите удалить
                    {pageTitle ? ` страницу «${pageTitle}»` : ' эту страницу'}?
                </Typography>
            </>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={(e, reason) =>
                reason !== 'backdropClick' ? onClose() : undefined
            }
            disableEscapeKeyDown
        >
            <DialogContent sx={{ maxWidth: '420px', padding: '32px 40px' }}>
                <Stack
                    spacing={3}
                    alignItems="center"
                    sx={{ svg: { width: '100px', height: '100px' } }}
                >
                    {getModalContent()}
                    {!success && !error ? (
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ width: '100%' }}
                        >
                            <BasicButton
                                variant="secondary"
                                onClick={() => onClose()}
                                disabled={loading}
                            >
                                НЕ УДАЛЯТЬ
                            </BasicButton>
                            <BasicButton
                                onClick={() => deletePage(id)}
                                $bgColor="red"
                                disabled={loading}
                            >
                                УДАЛИТЬ
                            </BasicButton>
                        </Stack>
                    ) : (
                        <BasicButton
                            variant="secondary"
                            onClick={() => onModalCloseAction()}
                            disabled={loading}
                        >
                            все понятно
                        </BasicButton>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
