/* eslint-disable no-param-reassign */
import * as mapgl from '@2gis/mapgl/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MapInstanceState {
    value: {
        map?: mapgl.Map;
        mapgl?: typeof mapgl;
        currentFloorPlanId?: string;
    };
}

const initialState: MapInstanceState = {
    value: {
        map: undefined,
        mapgl: undefined,
        currentFloorPlanId: undefined,
    },
};

export const mapInstanceSlice = createSlice({
    name: 'mapInstance',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<MapInstanceState['value']>) => {
            state.value = action.payload;
        },
        setStateFloorPlanId: (
            state,
            action: PayloadAction<
                MapInstanceState['value']['currentFloorPlanId']
            >
        ) => {
            state.value.currentFloorPlanId = action.payload;
        },
    },
});

export const { set, setStateFloorPlanId } = mapInstanceSlice.actions;

export const mapInstanceSliceReducer = mapInstanceSlice.reducer;
