import { ChangeEventHandler, Fragment, useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
    Button,
    Divider,
    FormControl,
    IconButton,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { Reorder, useDragControls, useMotionValue } from 'framer-motion';
import { v4 as uuid } from 'uuid';

import { useRaisedShadow } from 'components/organisms/PageEditor/componentControls/ListControl';
import { QuestionOption, QuizQuestion } from 'types/builder/quizConfig';

import { IconSelector } from '../../../IconSelector';
import { InternalPageSelector } from '../../../InternalPageSelector';

type ValueType = QuizQuestion['questions'];

type OptionsControlProps = {
    options: ValueType;
    onChange: (options: ValueType) => void;
};

type DragItemProps = {
    item: QuestionOption;
    onDelete: () => void;
    disableDelete: boolean;
    onChange: (item: QuestionOption) => void;
};

const DragItem = ({
    item,
    onDelete,
    disableDelete,
    onChange,
}: DragItemProps): JSX.Element => {
    const y = useMotionValue(0);
    const boxShadow = useRaisedShadow(y);
    const controls = useDragControls();

    const onItemTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        onChange({
            ...item,
            title: value,
        });
    };
    const onItemHrefChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        onChange({
            ...item,
            href: value,
            hrefUuid: undefined,
        });
    };

    const onItemExternalChange = (val: boolean): void => {
        onChange({
            ...item,
            isExternal: val,
            href: undefined,
            hrefUuid: undefined,
        });
    };
    const onExternalHrefChange = (val: string): void => {
        onChange({
            ...item,
            href: undefined,
            hrefUuid: val,
        });
    };

    const onItemIconChange = (val: string): void => {
        onChange({
            ...item,
            icon: val,
        });
    };

    return (
        <Reorder.Item
            value={item.uuid}
            style={{ boxShadow, y }}
            dragListener={false}
            dragControls={disableDelete ? undefined : controls}
        >
            <Stack
                spacing={2}
                sx={{ py: 2 }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <DragIndicatorIcon
                    onPointerDown={(e) => controls.start(e)}
                    sx={{
                        cursor: !disableDelete ? 'grab' : 'default',
                        opacity: !disableDelete ? 0.5 : 0.2,
                    }}
                />
                <Stack sx={{ flex: 1 }} spacing={2}>
                    <FormControl variant="outlined">
                        <TextField
                            label="Текст опции"
                            size="small"
                            fullWidth
                            value={item.title}
                            onChange={onItemTextChange}
                        />
                    </FormControl>
                    <IconSelector
                        icon={item.icon}
                        onSelect={onItemIconChange}
                    />
                    {item.isExternal ? (
                        <FormControl variant="outlined">
                            <TextField
                                label="Внешний ресурс"
                                size="small"
                                fullWidth
                                value={item.href || ''}
                                onChange={onItemHrefChange}
                            />
                        </FormControl>
                    ) : (
                        <InternalPageSelector
                            value={item.hrefUuid || ''}
                            onChange={(v) => onExternalHrefChange(v)}
                        />
                    )}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography>Внешняя ссылка</Typography>
                        <Switch
                            checked={item.isExternal || false}
                            onChange={(e, checked) =>
                                onItemExternalChange(checked)
                            }
                        />
                    </Stack>
                </Stack>
                <IconButton
                    size="small"
                    color="error"
                    onClick={() => onDelete()}
                    disabled={disableDelete}
                >
                    <DeleteOutlineIcon />
                </IconButton>
            </Stack>
        </Reorder.Item>
    );
};

const addUuidToOptions = (options: ValueType): ValueType => {
    return options.map((el) => ({ ...el, uuid: el.uuid || uuid() }));
};

export const OptionsControl = ({
    options = [],
    onChange,
}: OptionsControlProps): JSX.Element => {
    const keyedOptions = useMemo(() => {
        return addUuidToOptions(options);
    }, [options]);

    const onItemAdd = (): void => {
        onChange([
            ...keyedOptions,
            {
                title: 'Ответ',
                uuid: uuid(),
            },
        ]);
    };

    const onItemsReorder = (newOrder: string[]): void => {
        onChange(
            keyedOptions.sort((a, b) => {
                return (
                    newOrder.indexOf(a.uuid || '') -
                    newOrder.indexOf(b.uuid || '')
                );
            })
        );
    };

    const onItemDelete = (key?: string): void => {
        if (key) {
            onChange(keyedOptions.filter((el) => el.uuid !== key));
        }
    };

    const onItemChange = (item: QuestionOption): void => {
        if (item && item.uuid) {
            onChange(
                keyedOptions.map((el) => {
                    if (el.uuid === item.uuid) {
                        return item;
                    }
                    return el;
                })
            );
        }
    };

    return (
        <Stack spacing={2}>
            <Reorder.Group
                axis="y"
                values={keyedOptions.map((el) => el.uuid)}
                onReorder={onItemsReorder}
            >
                {keyedOptions.map((el, i) => {
                    return (
                        <Fragment key={el.uuid}>
                            <DragItem
                                item={el}
                                onDelete={() => onItemDelete(el.uuid)}
                                disableDelete={keyedOptions.length < 2}
                                onChange={onItemChange}
                            />
                            {i !== keyedOptions.length - 1 ? (
                                <Divider />
                            ) : undefined}
                        </Fragment>
                    );
                })}
            </Reorder.Group>
            <Button
                size="small"
                fullWidth
                sx={{ mt: 2 }}
                onClick={onItemAdd}
                variant="outlined"
                endIcon={<AddIcon />}
            >
                Добавить опцию
            </Button>
        </Stack>
    );
};
