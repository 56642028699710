import styled from 'styled-components';

export const DoctorCardWrapper = styled.div`
    display: flex;
    margin: 4px 0;
`;

export const DoctorCardImage = styled.img`
    width: 68px;
    height: 68px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 16px;
`;
export const DoctorCardInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    ${({ theme }) => theme.typography.body.medium}
`;
export const DoctorCardSpec = styled.span`
    color: ${({ theme }) => theme.palette.common.grey};
`;
export const DoctorCardName = styled.span`
    color: ${({ theme }) => theme.palette.common.black};
`;
export const DoctorCardAction = styled.span`
    color: ${({ theme }) => theme.palette.green.betta};
`;
