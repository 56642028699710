import { Variants } from 'framer-motion';

import { SVGAsset } from '../SVGAsset';
import {
    SplashShapeWrapper,
    SplashShapeElement,
    SplashBlurryEllipse,
    HumansWrapper,
} from './Shape.styled';

const splashVariants: Variants = {
    STAR_L: {
        rotate: 90,
        transition: {
            duration: 1,
            ease: 'linear',
            repeat: Infinity,
        },
    },
    STAR_S: {
        rotate: -90,
        transition: {
            duration: 1,
            ease: 'linear',
            repeat: Infinity,
        },
    },
    STAR_RESET: {
        rotate: 0,
        transition: {
            bounce: 0.3,
        },
    },
    LOGO_IN: {
        x: '110%',
        y: '-110%',
    },
    BLUE_LOGO_IN: {
        x: '-240%',
        y: '-160%',
    },
    RESET_LOGO: {
        x: 0,
        y: 0,
    },
    BLUE_RESET_LOGO: {
        x: '-30%',
        y: 0,
    },
    PEOPLE_IN: {
        opacity: 1,
    },
    RESET_PEOPLE: {
        opacity: 0,
    },
};

export const SplashScreenShape = ({
    isLoading,
    isTg = false,
}: {
    isLoading?: boolean;
    isTg?: boolean;
}): JSX.Element => {
    return (
        <SplashShapeWrapper>
            <SplashShapeElement
                variants={splashVariants}
                animate={isLoading ? 'STAR_L' : 'STAR_RESET'}
            >
                <SVGAsset src="shapes.stars.large" />
            </SplashShapeElement>
            <SplashShapeElement
                variants={splashVariants}
                animate={isLoading ? 'STAR_S' : 'STAR_RESET'}
            >
                <SVGAsset src="shapes.stars.small" />
            </SplashShapeElement>
            <SplashShapeElement>
                <SplashBlurryEllipse />
            </SplashShapeElement>
            <SplashShapeElement
                variants={splashVariants}
                animate={!isLoading ? 'PEOPLE_IN' : 'RESET_PEOPLE'}
                initial="RESET_PEOPLE"
                transition={{
                    type: 'spring',
                    bounce: 0.3,
                    duration: 1,
                }}
            >
                <HumansWrapper>
                    <SVGAsset
                        src={isTg ? 'logos.tg' : 'shapes.humans.manAndWoman'}
                    />
                </HumansWrapper>
            </SplashShapeElement>
            <SplashShapeElement
                variants={splashVariants}
                animate={!isLoading ? 'BLUE_LOGO_IN' : 'BLUE_RESET_LOGO'}
                initial="BLUE_RESET_LOGO"
                transition={{
                    type: 'spring',
                    bounce: 0.3,
                    duration: 1,
                }}
            >
                {!isTg && <SVGAsset src="logos.blueLogo" />}
            </SplashShapeElement>
            <SplashShapeElement
                variants={splashVariants}
                animate={!isLoading ? 'LOGO_IN' : 'RESET_LOGO'}
                initial="RESET_LOGO"
                transition={{
                    type: 'spring',
                    bounce: 0.3,
                    duration: 1,
                }}
            >
                {!isTg && <SVGAsset src="logos.logo67" />}
            </SplashShapeElement>
        </SplashShapeWrapper>
    );
};
