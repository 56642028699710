/* eslint-disable import/no-unresolved */
import { useEffect } from 'react';

import { NavigationControl } from 'components/organisms/NavigationControl';
import { useMapInstance } from 'hooks/api/map/useMapInstance';
import { createMarker, onMapStyleLoad } from 'utils/map';

export const SELECTED_OBJECTS = [
    '4504235282809544',
    '4504235282539206',
    '4504235308607210',
    '4504235282809805',
    '4504235282539138',
    '4504235282539139',
    '4504235310551400',
    '4504235282809543',
    '4504235282539039',
];

export const MARKERS = [
    {
        coords: [37.467061292944116, 55.772047401155625],
        text: 'Корпус А',
    },
    {
        coords: [37.469032, 55.769593],
        text: 'Перинатальный центр',
    },
    {
        coords: [37.46818454396161, 55.77077538173749],
        text: 'Корпус Б',
    },
    {
        coords: [37.46729454577556, 55.77004544789239],
        text: 'Корпус Г',
    },
    {
        coords: [37.46510687719565, 55.77406184594783],
        text: 'Травматологический корпус',
    },
    {
        coords: [37.469602, 55.771806],
        text: 'Морг',
    },
];

export const MapPage = (): JSX.Element => {
    const { mapInstance, mapgl } = useMapInstance();

    useEffect(() => {
        if (mapInstance !== undefined && mapgl) {
            mapInstance.setCenter([37.467061292944116, 55.772047401155625]);
            mapInstance.setMinZoom(16.5);
            mapInstance.setMaxZoom(22);

            mapInstance.setStyleState({
                showPoints: true,
                showAlways: false,
            });

            mapInstance.once('styleload', () => onMapStyleLoad(mapInstance));

            mapInstance.setSelectedObjects(SELECTED_OBJECTS);

            const markers = MARKERS.map((m) =>
                createMarker(mapInstance, mapgl, m.coords, m.text, 'block')
            );

            return () => {
                markers.forEach((marker) => {
                    if (marker) {
                        marker.destroy();
                    }
                });
            };
        }
        return undefined;
    }, [mapInstance, mapgl]);

    return <NavigationControl />;
};
