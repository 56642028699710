import avatarIcon from 'assets/icons/24px/components/avatar.svg';
import boxIcon from 'assets/icons/24px/components/box.svg';
import buttonIcon from 'assets/icons/24px/components/button.svg';
import buttonWithIcon from 'assets/icons/24px/components/buttonWithIcon.svg';
import checkboxIcon from 'assets/icons/24px/components/checkbox.svg';
import copyListIcon from 'assets/icons/24px/components/copyList.svg';
import dropdownIcon from 'assets/icons/24px/components/dropdown.svg';
import headerIcon from 'assets/icons/24px/components/header.svg';
import icon from 'assets/icons/24px/components/icon.svg';
import imageIcon from 'assets/icons/24px/components/image.svg';
import linkIcon from 'assets/icons/24px/components/link.svg';
import listIcon from 'assets/icons/24px/components/list.svg';
import rowIcon from 'assets/icons/24px/components/row.svg';
import sectionIcon from 'assets/icons/24px/components/section.svg';
import spacerIcon from 'assets/icons/24px/components/spacer.svg';
import typographyIcon from 'assets/icons/24px/components/typography.svg';
import { PageComponentConfig } from 'types/builder/componentConfig';

type ComponentDisplay = {
    [k in PageComponentConfig['component']]?: {
        title: string;
        description?: string;
        icon: string;
    };
};

export const componentDisplayConfig: ComponentDisplay = {
    section: {
        title: 'Раздел',
        icon: sectionIcon,
        description:
            'Определяет раздел страницы и позволяет создать ссылку на раздел.',
    },
    box: {
        title: 'Блок',
        icon: boxIcon,
        description:
            'Базовый компонент, используемый для группировки нескольких компонентов. Дает возможность задать стиль фона в блоке.',
    },
    row: {
        title: 'Ряд',
        icon: rowIcon,
        description:
            'Располагает элементы внутри данного компонента в ряд, с возможностью поменять выравнивание и позиционирование элементов.',
    },
    typography: {
        title: 'Текст',
        icon: typographyIcon,
        description: 'Текстовый компонент (параграфы, заголовки)',
    },
    image: {
        title: 'Изображение',
        icon: imageIcon,
        description: 'Компонент, отображающий на странице изображения.',
    },
    list: {
        title: 'Список',
        icon: listIcon,
        description:
            'Компонент, создающий маркированный или нумерованный список.',
    },
    spacer: {
        icon: spacerIcon,
        title: 'Отступ',
        description:
            'Компонент, позволяющий задать отступ между другими компонентами.',
    },
    simpleLink: {
        title: 'Ссылка',
        icon: linkIcon,
        description:
            'Текстовый элемент, создающий гиперссылку на страницу, раздел, картинку, телефон, или внешний ресурс.',
    },
    buttonWithIcon: {
        title: 'Кнопка с иконкой',
        icon: buttonWithIcon,
        description:
            'Имитирует поведение компонента «Ссылка», с возможностью задать размер, цвет, и иконку кнопки.',
    },
    basicButton: {
        title: 'Кнопка',
        icon: buttonIcon,
        description:
            'Базовая кнопка, имитирует поведение компонента «Ссылка», с возможностью задать вариант и цвет.',
    },
    checkbox: {
        icon: checkboxIcon,
        title: 'Галочка',
        description:
            'Компонент-флажок - автоматически сохраняет отметку на устройстве пользователя. Удобен для создания списка предметов или документов. Рекомендуется использовать внутри компонента «Копировщик».',
    },
    svgAsset: {
        icon,
        title: 'Иконка',
        description: 'Иконки инфопомощника.',
    },
    avatar: {
        title: 'Аватар',
        icon: avatarIcon,
        description: 'Аватар помощника, выбранный пользователем.',
    },
    dropdown: {
        title: 'Выпадающий блок',
        icon: dropdownIcon,
        description:
            'Компонент, скрывающий элементы внутри, до момента его раскрытия.',
    },
    copyList: {
        icon: copyListIcon,
        title: 'Копировщик',
        description:
            'Копировщик создает кнопку для копирования текста всех элементов, находящихся внутри копировщика.',
    },
    braceletHeader: {
        icon: headerIcon,
        title: 'Хэдер',
        description:
            'Задает «шапку» страницы или раздела, в которой обычно располагается заголовок.',
    },
};
