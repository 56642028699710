/* eslint-disable react/no-children-prop */
import { createElement } from 'react';

import { keysToComponentMap } from 'constants/components';
import { PageComponentConfig } from 'types/builder/componentConfig';

export const renderComponent = (
    config: PageComponentConfig
): JSX.Element | null => {
    const { children, component, props, _key } = config;

    const keyedComponent = keysToComponentMap[component];

    if (typeof keyedComponent !== 'undefined') {
        return createElement(
            keyedComponent as string,
            {
                key: _key,
                'data-key': _key,
                ...props,
            },
            props?.children
                ? (props.children as string)
                : children?.map((c) => renderComponent(c))
        );
    }
    return null;
};
