import { useEffect } from 'react';

import { SplashScreen } from 'components/pages/Splash';
import { useGetPageConfig } from 'hooks/api/builder/pages/useGetPageConfig';

import { FadeAnimation } from '../FadeWrapper';
import { PageLayout } from '../layouts/PageLayout';
import { PageTemplate } from '../PageTemplate';

type DynamicPageRenderProps = {
    pageID: number;
    path?: string;
};

export const DynamicPageRender = ({
    pageID,
    path,
}: DynamicPageRenderProps): JSX.Element => {
    const { page, loading, error, getPage } = useGetPageConfig(pageID, false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (error) {
                getPage();
            }
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
    }, [error, getPage]);

    return (
        <PageLayout currentPage={page} path={path}>
            {loading || !page || error ? (
                <FadeAnimation>
                    <SplashScreen isLoading fullScreen={false} />
                </FadeAnimation>
            ) : (
                <PageTemplate page={page} />
            )}
        </PageLayout>
    );
};
