import { ActionButton, ActionButtonProps } from 'components/atoms/ActionButton';
import { SVGAsset, SVGAssetProps } from 'components/atoms/SVGAsset';

export type ButtonWithIconProps = ActionButtonProps & {
    icon?: SVGAssetProps['src'];
};

export const ButtonWithIcon = (props: ButtonWithIconProps): JSX.Element => {
    const { icon = '', children, $bgColor = 'default', ...rest } = props;

    return (
        <ActionButton {...rest} $bgColor={$bgColor}>
            {children}
            <div
                className={
                    $bgColor !== 'default' &&
                    !icon.includes('40px.hand') &&
                    !icon.includes('logo')
                        ? 'svg-color-inherit'
                        : undefined
                }
            >
                {icon ? <SVGAsset src={icon} /> : undefined}
            </div>
        </ActionButton>
    );
};
