import aboutHospital from './about-hospital.svg';
import ambulance from './ambulance.svg';
import appointment from './appointment.svg';
import cosmetology from './cosmetology.svg';
import docs from './docs.svg';
import donor from './donor.svg';
import faq from './faq.svg';
import handGreen from './hand-green.svg';
import handRed from './hand-red.svg';
import handYellow from './hand-yellow.svg';
import hospitalized from './hospitalized.svg';
import memos from './memos.svg';
import oms from './oms.svg';
import pass from './pass.svg';
import payServices from './pay-services.svg';
import personal from './personal.svg';
import podiatrist from './podiatrist.svg';
import prizyvnik from './prizyvnik.svg';
import reception from './reception.svg';
import schedule from './schedule.svg';
import snils from './snils.svg';
import stomatology from './stomatology.svg';
import surveys from './surveys.svg';
import traumatology from './traumatology.svg';

export const ICONS_40PX_MAP = {
    aboutHospital,
    prizyvnik,
    donor,
    ambulance,
    cosmetology,
    appointment,
    docs,
    oms,
    pass,
    faq,
    hospitalized,
    memos,
    payServices,
    personal,
    podiatrist,
    reception,
    schedule,
    snils,
    stomatology,
    surveys,
    traumatology,
    handGreen,
    handYellow,
    handRed,
};
