import { ChangeEventHandler } from 'react';

import {
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
} from '@mui/material';

import { Typography } from 'components/atoms/Typography';
import { DropdownProps } from 'components/molecules/DropDown';
import { DropdownConfig } from 'types/builder/componentConfig';

type DropdownControlProps = {
    node?: DropdownConfig;
    onChange: (node?: DropdownConfig) => void;
};

type TitleSize = {
    value: DropdownProps['titleSize'];
    title: string;
};

const titleSizes: TitleSize[] = [
    {
        value: 'large',
        title: 'Заголовок 1',
    },
    {
        value: 'medium',
        title: 'Заголовок 2',
    },
    {
        value: 'small',
        title: 'Заголовок 3',
    },
];

export const DropdownControl = ({
    onChange,
    node,
}: DropdownControlProps): JSX.Element => {
    const {
        dropdownTitle,
        subTitle,
        titleSize = 'large',
        transparent,
        defaultOpen,
    } = node?.props || {};

    const onTitleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        onChange({
            component: 'dropdown',
            props: {
                ...node?.props,
                dropdownTitle: value,
            },
        });
    };
    const onSubTitleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { value } = e.target;

        onChange({
            component: 'dropdown',
            props: {
                ...node?.props,
                subTitle: value,
            },
        });
    };

    const onTransparentChange = (value: boolean): void => {
        onChange({
            component: 'dropdown',
            props: {
                ...node?.props,
                transparent: value,
            },
        });
    };
    const onDefaultOpenChange = (value: boolean): void => {
        onChange({
            component: 'dropdown',
            props: {
                ...node?.props,
                defaultOpen: value,
            },
        });
    };

    const onTitleSizeChange = (value: DropdownProps['titleSize']): void => {
        onChange({
            component: 'dropdown',
            props: {
                ...node?.props,
                titleSize: value,
            },
        });
    };

    return (
        <Stack spacing={2}>
            <FormControl variant="outlined">
                <TextField
                    label="Заголовок"
                    size="small"
                    fullWidth
                    value={dropdownTitle || ''}
                    onChange={onTitleChange}
                />
            </FormControl>
            <FormControl variant="outlined" size="small">
                <InputLabel id="base-route-label">Размер заголовка</InputLabel>
                <Select
                    labelId="base-route-label"
                    label="Размер заголовка"
                    value={titleSize || ''}
                    renderValue={(v) => (
                        <span>
                            {titleSizes.find((el) => el.value === v)?.title}
                        </span>
                    )}
                >
                    {titleSizes.map((el, i) => (
                        <MenuItem
                            key={`menu_item_${i}`}
                            value={el.value}
                            onClick={() => onTitleSizeChange(el.value)}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                <Typography
                                    variant={`title.${el.value || 'large'}`}
                                >
                                    {el.title}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="outlined">
                <TextField
                    label="Подзаголовок"
                    size="small"
                    fullWidth
                    value={subTitle || ''}
                    onChange={onSubTitleChange}
                />
            </FormControl>
            <Stack spacing={1} sx={{ mt: 1 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>Прозрачный</Typography>
                    <Switch
                        name="showTopAppBar"
                        checked={transparent}
                        onChange={(e, checked) => onTransparentChange(checked)}
                    />
                </Stack>
                <Divider />
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>Открыт по умолчанию</Typography>
                    <Switch
                        name="showTopAppBar"
                        checked={defaultOpen}
                        onChange={(e, checked) => onDefaultOpenChange(checked)}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};
