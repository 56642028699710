import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

export type UseGoBackReturn = {
    goBack: () => void;
};

export const useGoBack = (): UseGoBackReturn => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [prevPath, setPrevPath] = useState('');

    const pathSegments = pathname.split('/').filter((e) => !!e);
    const prevPathSegmnets = prevPath.split('/').filter((e) => !!e);

    const fromSamePath = prevPathSegmnets[0] === pathSegments[0];
    const hasPrevPath = !!prevPath;

    useEffect(() => {
        return () => {
            setPrevPath(pathname);
        };
    }, [pathname]);

    const goBack = (): void => {
        if (
            (window.history.state &&
                window.history.state.idx > 1 &&
                pathSegments.length > 1) ||
            (hasPrevPath && !fromSamePath)
        ) {
            navigate(-1);
        } else {
            navigate('/home', { replace: true });
        }
    };

    return { goBack };
};
