import { MouseEventHandler, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { useMeta } from 'hooks/api/useMeta';
import { useExternalHref } from 'hooks/store/useExternalHref';
import { useGallery } from 'hooks/store/useGallery';

import { hashLink } from '../HashLink';
import { StyledActionButton } from './ActionButton.styled';
import type { ActionButtonProps } from './types';

export const ActionButton = (props: ActionButtonProps): JSX.Element => {
    const {
        children,
        linkType = undefined,
        href: linkHref = '',
        hrefUuid,
        ...rest
    } = props;

    const { setExternalHref } = useExternalHref();
    const { setGallery } = useGallery();

    const { meta: urls = [] } = useMeta('guestPageList');

    const href = useMemo(() => {
        const page = urls.find((el) => el.uuid === hrefUuid);

        if (page) {
            return `${page.href}`;
        }

        return linkHref;
    }, [hrefUuid, linkHref, urls]);

    const preview = document.body.hasAttribute('data-preview');

    const onExternalLinkClick: MouseEventHandler = (e) => {
        e.preventDefault();
        if (!preview) {
            setExternalHref(href);
        }
    };
    const onImageLinkClick: MouseEventHandler = (e) => {
        e.preventDefault();
        if (!preview) {
            setGallery(href);
        }
    };

    if (preview && linkType) {
        return (
            <StyledActionButton
                href={href}
                data-preview={preview}
                onClick={(e) => e.preventDefault()}
                {...rest}
            >
                {children}
            </StyledActionButton>
        );
    }

    switch (linkType) {
        case 'internal':
            return (
                <StyledActionButton
                    as={href.includes('#') ? hashLink : Link}
                    to={href}
                    {...rest}
                >
                    {children}
                </StyledActionButton>
            );
        case 'chat':
            return (
                <StyledActionButton as={Link} to="?chat=open" {...rest}>
                    {children}
                </StyledActionButton>
            );
        case 'external':
        case 'document':
        case 'phone':
            return (
                <StyledActionButton
                    as="a"
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onExternalLinkClick}
                    {...rest}
                >
                    {children}
                </StyledActionButton>
            );
        case 'image':
            return (
                <StyledActionButton
                    as="a"
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onImageLinkClick}
                    {...rest}
                >
                    {children}
                </StyledActionButton>
            );
        default:
            return (
                <StyledActionButton {...rest}>{children}</StyledActionButton>
            );
    }
};

export type { ActionButtonProps } from './types';
