type Synonyms = {
    [key: string]: string;
};

export const synonyms: Synonyms = {
    Abc: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character font letter symbol text type',
    AccessAlarm:
        '\u0427\u0430\u0441\u044b \u0432\u0440\u0435\u043c\u044f clock time',
    AccessAlarms:
        '\u0427\u0430\u0441\u044b \u0432\u0440\u0435\u043c\u044f clock time',
    Accessibility:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b\u0439 \u0442\u0435\u043b\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u043c\u043e\u0449\u044c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c accessible body handicap help human people person user',
    AccessibilityNew:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b\u0439 \u0440\u0443\u043a\u0438 \u0442\u0435\u043b\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u043c\u043e\u0449\u044c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c accessible arms body handicap help human people person user',
    Accessible:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u043e\u0441\u0442\u044c \u0442\u0435\u043b\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u043c\u043e\u0449\u044c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434\u043d\u043e\u0435 \u043a\u0440\u0435\u0441\u043b\u043e accessibility body handicap help human people person user wheelchair',
    AccessibleForward:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u043e\u0441\u0442\u044c \u0442\u0435\u043b\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u043c\u043e\u0449\u044c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0438\u043d\u0432\u0430\u043b\u0438\u0434\u043d\u043e\u0435 \u043a\u0440\u0435\u0441\u043b\u043e accessibility body handicap help human people person wheelchair',
    AccessTime:
        '\u0427\u0430\u0441\u044b \u0432\u0440\u0435\u043c\u044f clock time',
    AccountBalance:
        '\u0431\u0430\u043d\u043a \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u043e\u0440\u0442 \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0441\u0442\u0440\u0443\u043a\u0442\u0443\u0440\u0430 \u0445\u0440\u0430\u043c \u0441\u0434\u0435\u043b\u043a\u0430 bank bill building card cash coin commerce court credit currency dollars finance money online payment structure temple transaction',
    AccountBalanceWallet:
        '\u0431\u0430\u043d\u043a \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0441\u0434\u0435\u043b\u043a\u0430 bank bill card cash coin commerce credit currency dollars finance money online payment transaction',
    AccountBox:
        '\u0430\u0432\u0430\u0442\u0430\u0440 \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u043c\u0438\u043d\u0438\u0430\u0442\u044e\u0440\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c avatar face human people person profile square thumbnail user',
    AccountCircle:
        '\u0430\u0432\u0430\u0442\u0430\u0440 \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043c\u0438\u043d\u0438\u0430\u0442\u044e\u0440\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c avatar face human people person profile thumbnail user',
    AccountTree:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u043f\u043e\u0442\u043e\u043a \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u043f\u0440\u043e\u0446\u0435\u0441\u0441 \u043f\u0440\u043e\u0435\u043a\u0442 \u043a\u0430\u0440\u0442\u0430 \u0441\u0430\u0439\u0442\u0430 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u0441\u0442\u0440\u0443\u043a\u0442\u0443\u0440\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics chart connect data diagram flow infographic measure metrics process project sitemap square statistics structure tracking',
    AcUnit: '\u0432\u043e\u0437\u0434\u0443\u0445\u0430 \u0445\u043e\u043b\u043e\u0434\u043d\u043e \u043a\u043e\u043d\u0434\u0438\u0446\u0438\u043e\u043d\u0435\u0440 \u0437\u0430\u043c\u043e\u0440\u043e\u0437\u0438\u0442\u044c \u0441\u043d\u0435\u0436\u0438\u043d\u043a\u0430 \u0442\u0435\u043c\u043f\u0435\u0440\u0430\u0442\u0443\u0440\u0430 \u043f\u043e\u0433\u043e\u0434\u0430 \u0437\u0438\u043c\u0430 air cold conditioner freeze snowflake temperature weather winter',
    Adb: '\u0430\u043d\u0434\u0440\u043e\u0438\u0434 \u043c\u043e\u0441\u0442 \u043e\u0442\u043b\u0430\u0436\u0438\u0432\u0430\u0442\u044c android bridge debug',
    Add: '+ \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0432\u0435\u0449\u044c \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0441\u0438\u043c\u0432\u043e\u043b + create item new plus symbol',
    AddAlarm:
        '\u0427\u0430\u0441\u044b \u043f\u043b\u044e\u0441 \u0432\u0440\u0435\u043c\u044f clock plus time',
    AddAlert:
        '+ \u0430\u043a\u0442\u0438\u0432\u043d\u044b\u0439 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u0432\u044b\u0437\u044b\u0432\u0430\u0442\u044c \u043f\u0435\u0440\u0435\u0437\u0432\u043e\u043d \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043d\u043e\u0432\u044b\u0439 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u044f \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u044f\u0442\u044c \u043f\u043b\u044e\u0441 \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0437\u0432\u0435\u043d\u0435\u0442\u044c \u0437\u0432\u0443\u043a \u0441\u0438\u043c\u0432\u043e\u043b + active alarm announcement bell callout chime information new notifications notify plus reminder ring sound symbol',
    AddAPhoto:
        '+ \u043a\u0430\u043c\u0435\u0440\u0430 \u043e\u0431\u044a\u0435\u043a\u0442\u0438\u0432 \u043d\u043e\u0432\u044b\u0439 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u043b\u044e\u0441 \u0441\u0438\u043c\u0432\u043e\u043b + camera lens new photography picture plus symbol',
    AddBox: '\u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0441\u0438\u043c\u0432\u043e\u043b create new plus square symbol',
    AddBusiness:
        '+ \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u041a\u043e\u043c\u043f\u0430\u043d\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0440\u044b\u043d\u043e\u043a \u0414\u0435\u043d\u044c\u0433\u0438 \u043d\u043e\u0432\u044b\u0439 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043b\u044e\u0441 \u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0432\u0438\u0442\u0440\u0438\u043d\u0430 \u0441\u0438\u043c\u0432\u043e\u043b + bill building card cash coin commerce company credit currency dollars market money new online payment plus retail shopping storefront symbol',
    AddCard:
        '+ \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043d\u043e\u0432\u044b\u0439 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043b\u044e\u0441 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b + bill cash coin commerce cost credit currency dollars finance money new online payment plus price shopping symbol',
    Addchart:
        '+ \u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 + analytics bars data diagram infographic measure metrics new plus statistics symbol tracking',
    AddCircle:
        '+ \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 + create new plus',
    AddCircleOutline:
        '+ \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 + create new plus',
    AddComment:
        '+ \u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0440\u0435\u0447\u044c \u0441\u0438\u043c\u0432\u043e\u043b + bubble chat communicate feedback message new plus speech symbol',
    AddIcCall:
        '+ \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043b\u0435\u0444\u043e\u043d + cell contact device hardware mobile new plus symbol telephone',
    AddLink:
        '\u043f\u0440\u0438\u043a\u0440\u0435\u043f\u043b\u044f\u0442\u044c \u043a\u043b\u0438\u043f \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0441\u0438\u043c\u0432\u043e\u043b attach clip new plus symbol',
    AddLocation:
        '+ \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 GPS \u043a\u0430\u0440\u0442\u044b \u043d\u043e\u0432\u044b\u0439 \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043f\u043b\u044e\u0441 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0441\u0438\u043c\u0432\u043e\u043b + destination direction gps maps new pin place plus stop symbol',
    AddLocationAlt:
        '+ \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043d\u043e\u0432\u044b\u0439 \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043f\u043b\u044e\u0441 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0441\u0438\u043c\u0432\u043e\u043b + destination direction maps new pin place plus stop symbol',
    AddModerator:
        '+ \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0449\u0438\u0442 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e + certified new plus privacy private protection security shield symbol verified',
    AddPhotoAlternate:
        '+ \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u043d\u043e\u0432\u044b\u0439 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u043b\u044e\u0441 \u0441\u0438\u043c\u0432\u043e\u043b + image landscape mountains new photography picture plus symbol',
    AddReaction:
        '+ \u044d\u043c\u043e\u0434\u0437\u0438 \u044d\u043c\u043e\u0446\u0438\u0438 \u0432\u044b\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u043b\u0438\u0446\u043e \u0447\u0443\u0432\u0441\u0442\u0432\u0430 \u0440\u0430\u0434\u043e\u0441\u0442\u043d\u044b\u0439 \u0441\u0447\u0430\u0441\u0442\u044c\u0435 \u0441\u0447\u0430\u0441\u0442\u043b\u0438\u0432\u044b\u0439 \u0432\u0441\u0442\u0430\u0432\u043b\u044f\u0442\u044c \u043a\u0430\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435 \u043d\u043e\u0432\u044b\u0439 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0434\u043e\u0432\u043e\u043b\u044c\u043d\u044b\u0439 \u043f\u043b\u044e\u0441 \u0443\u043b\u044b\u0431\u043a\u0430 \u0443\u043b\u044b\u0431\u0430\u0435\u0442\u0441\u044f \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043e\u043f\u0440\u043e\u0441 \u0441\u0438\u043c\u0432\u043e\u043b + emoji emotions expressions face feelings glad happiness happy insert like mood new person pleased plus smile smiling social survey symbol',
    AddRoad:
        '+ \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0448\u043e\u0441\u0441\u0435 \u043a\u0430\u0440\u0442\u044b \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0443\u043b\u0438\u0446\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 + destination direction highway maps new plus stop street symbol traffic',
    AddShoppingCart:
        '\u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043f\u0440\u043e\u0432\u0435\u0440\u0438\u0442\u044c \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043b\u044e\u0441 card cash checkout coin commerce credit currency dollars money online payment plus',
    AddTask:
        '+ \u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043a\u0440\u0443\u0433 \u0437\u0430\u0432\u0435\u0440\u0448\u0435\u043d\u043d\u044b\u0439 \u0443\u0432\u0435\u043b\u0438\u0447\u0438\u0432\u0430\u0442\u044c \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u043f\u043b\u044e\u0441 \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u0434\u0430 + approve check circle completed increase mark ok plus select tick yes',
    AddToDrive:
        '+ \u043f\u0440\u0438\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u0440\u0435\u0437\u0435\u0440\u0432\u043d\u043e\u0435 \u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0431\u043b\u0430\u043a\u043e \u0434\u0430\u043d\u043d\u044b\u0435 \u0444\u0430\u0439\u043b\u044b \u043f\u0430\u043f\u043a\u0438 gdrive Google \u043f\u043b\u044e\u0441 \u0432\u043e\u0441\u0441\u0442\u0430\u043d\u043e\u0432\u043b\u0435\u043d\u0438\u0435 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 + app backup cloud data files folders gdrive google plus recovery storage',
    AddToHomeScreen:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u043a \u0432\u0432\u0435\u0440\u0445 Android add arrow cell device hardware iOS mobile phone tablet to up',
    AddToPhotos:
        '\u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u043b\u044e\u0441 collection image landscape mountains photography picture plus',
    AddToQueue:
        '+ \u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043e\u0442\u0441\u0442\u0430\u0432\u0430\u043d\u0438\u0435 \u0445\u0440\u043e\u043c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0440\u0430\u0441\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u044d\u043a\u0440\u0430\u043d \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e + Android backlog chrome desktop device display hardware iOS lineup mac monitor new plus screen symbol television watch web window',
    AdfScanner:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u043a\u043e\u0440\u043c\u0443\u0448\u043a\u0430 \u043c\u0430\u0448\u0438\u043d\u0430 \u043e\u0444\u0438\u0441 document feeder machine office',
    Adjust: '\u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0446\u0435\u043d\u0442\u0440 \u043a\u0440\u0443\u0433\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u0442\u043e\u0447\u043a\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0444\u0438\u043b\u044c\u0442\u0440 \u0438\u0441\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0441\u043c\u0435\u0448\u0438\u0432\u0430\u043d\u0438\u0435 \u0448\u0430\u0433 \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440 \u043f\u043e\u043b\u0437\u0443\u043d\u043e\u043a \u0421\u043e\u0440\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0432\u044b\u043a\u043b\u044e\u0447\u0430\u0442\u0435\u043b\u044c \u0446\u0435\u043b\u044c \u043c\u0435\u043b\u043e\u0434\u0438\u044f alter center circles control dot edit filter fix image mix move setting slider sort switch target tune',
    AdminPanelSettings:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0449\u0438\u0442 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e account avatar certified face human people person privacy private profile protection security shield user verified',
    AdUnits:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0431\u0430\u043d\u043d\u0435\u0440 \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u044f \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0422\u043e\u043f Android banner cell device hardware iOS mobile notifications phone tablet top',
    Agriculture:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u0432\u044b\u0440\u0430\u0449\u0438\u0432\u0430\u043d\u0438\u0435 \u0444\u0435\u0440\u043c\u0430 \u0443\u0440\u043e\u0436\u0430\u0439 \u043a\u0430\u0440\u0442\u044b \u0442\u0440\u0430\u043a\u0442\u043e\u0440 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0433\u0440\u0443\u0437\u043e\u0432\u0430\u044f \u043c\u0430\u0448\u0438\u043d\u0430 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars cultivation farm harvest maps tractor transport travel truck vehicle',
    Air: '\u0434\u0443\u0435\u0442 \u0432\u0435\u0442\u0435\u0440 \u043f\u043e\u0442\u043e\u043a \u0432\u043e\u043b\u043d\u0430 \u043f\u043e\u0433\u043e\u0434\u0430 \u0432\u0435\u0442\u0435\u0440 blowing breeze flow wave weather wind',
    Airlines:
        '\u0441\u0430\u043c\u043e\u043b\u0435\u0442 \u0430\u044d\u0440\u043e\u043f\u043e\u0440\u0442 \u043f\u043e\u043b\u0435\u0442 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 airplane airport flight transportation travel trip',
    AirlineSeatFlat:
        '\u043a\u0440\u043e\u0432\u0430\u0442\u044c \u0442\u0435\u043b\u043e \u0431\u0438\u0437\u043d\u0435\u0441 \u0443\u0447\u0435\u0431\u043d\u044b\u0439 \u043a\u043b\u0430\u0441\u0441 \u043f\u0435\u0440\u0432\u044b\u0439 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043e\u0442\u0434\u044b\u0445\u0430\u0442\u044c \u0441\u043f\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c bed body business class first human people person rest sleep travel',
    AirlineSeatFlatAngled:
        '\u043a\u0440\u043e\u0432\u0430\u0442\u044c \u0442\u0435\u043b\u043e \u0431\u0438\u0437\u043d\u0435\u0441 \u0443\u0447\u0435\u0431\u043d\u044b\u0439 \u043a\u043b\u0430\u0441\u0441 \u043f\u0435\u0440\u0432\u044b\u0439 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043e\u0442\u0434\u044b\u0445\u0430\u0442\u044c \u0441\u043f\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c bed body business class first human people person rest sleep travel',
    AirlineSeatIndividualSuite:
        '\u043a\u0440\u043e\u0432\u0430\u0442\u044c \u0442\u0435\u043b\u043e \u0431\u0438\u0437\u043d\u0435\u0441 \u0443\u0447\u0435\u0431\u043d\u044b\u0439 \u043a\u043b\u0430\u0441\u0441 \u043f\u0435\u0440\u0432\u044b\u0439 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043e\u0442\u0434\u044b\u0445\u0430\u0442\u044c \u0441\u043f\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c bed body business class first human people person rest sleep travel',
    AirlineSeatLegroomExtra:
        '\u0442\u0435\u043b\u043e \u043d\u043e\u0433\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u0438\u0434\u0438\u0442 \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c body feet human people person sitting space travel',
    AirlineSeatLegroomNormal:
        '\u0442\u0435\u043b\u043e \u043d\u043e\u0433\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u0438\u0434\u0438\u0442 \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c body feet human people person sitting space travel',
    AirlineSeatLegroomReduced:
        '\u0442\u0435\u043b\u043e \u043d\u043e\u0433\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u0438\u0434\u0438\u0442 \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c body feet human people person sitting space travel',
    AirlineSeatReclineExtra:
        '\u0442\u0435\u043b\u043e \u043d\u043e\u0433\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043c\u0435\u0441\u0442\u043e \u0434\u043b\u044f \u043d\u043e\u0433 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u0438\u0434\u0438\u0442 \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c body feet human legroom people person sitting space travel',
    AirlineSeatReclineNormal:
        '\u0442\u0435\u043b\u043e \u0434\u043e\u043f\u043e\u043b\u043d\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u043d\u043e\u0433\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043c\u0435\u0441\u0442\u043e \u0434\u043b\u044f \u043d\u043e\u0433 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u0438\u0434\u0438\u0442 \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c body extra feet human legroom people person sitting space travel',
    AirlineStops:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0441\u0442\u043e \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 arrow destination direction layover location maps place transportation travel trip',
    AirplanemodeActive:
        '\u043f\u043e\u043b\u0435\u0442 \u043b\u0435\u0442\u0430\u044e\u0449\u0438\u0439 \u043d\u0430 \u0441\u0438\u0433\u043d\u0430\u043b flight flying on signal',
    AirplanemodeInactive:
        '\u0430\u044d\u0440\u043e\u043f\u043e\u0440\u0442 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043f\u043e\u043b\u0435\u0442 \u043b\u0435\u0442\u0430\u044e\u0449\u0438\u0439 \u043a\u0430\u0440\u0442\u044b \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c airport disabled enabled flight flying maps offline slash transportation travel',
    AirplaneTicket:
        '\u0430\u044d\u0440\u043e\u043f\u043e\u0440\u0442 \u0438\u043d\u0442\u0435\u0440\u043d\u0430\u0442 \u043f\u043e\u043b\u0435\u0442 \u043b\u0435\u0442\u0430\u0442\u044c \u043a\u0430\u0440\u0442\u044b \u043f\u0440\u043e\u0445\u043e\u0434\u0438\u0442\u044c \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c airport boarding flight fly maps pass transportation travel',
    Airplay:
        '\u044f\u0431\u043b\u043e\u043a\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0412 \u0440\u043e\u043b\u044f\u0445 \u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u044d\u043a\u0440\u0430\u043d \u0441\u0438\u0433\u043d\u0430\u043b \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 apple arrow cast connect control desktop device display monitor screen signal television tv',
    AirportShuttle:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0430\u0432\u0442\u043e\u0431\u0443\u0441 \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u043e\u043c\u043c\u0435\u0440\u0447\u0435\u0441\u043a\u0438\u0439 \u0414\u043e\u0441\u0442\u0430\u0432\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043c\u0438\u043d\u0438 \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0433\u0440\u0443\u0437\u043e\u0432\u0430\u044f \u043c\u0430\u0448\u0438\u043d\u0430 \u0438\u0437 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile bus cars commercial delivery direction maps mini public transportation travel truck van vehicle',
    Alarm: '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u0427\u0430\u0441\u044b \u043e\u0431\u0440\u0430\u0442\u043d\u044b\u0439 \u043e\u0442\u0441\u0447\u0435\u0442 \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f alart bell clock countdown date notification schedule time',
    AlarmAdd:
        '+ \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u0427\u0430\u0441\u044b \u043e\u0431\u0440\u0430\u0442\u043d\u044b\u0439 \u043e\u0442\u0441\u0447\u0435\u0442 \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043d\u043e\u0432\u044b\u0439 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043f\u043b\u044e\u0441 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0440\u0435\u043c\u044f + alart bell clock countdown date new notification plus schedule symbol time',
    AlarmOff:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u0427\u0430\u0441\u044b \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0442\u0430\u0439\u043c\u0435\u0440 \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c alart bell clock disabled duration enabled notification slash stop timer watch',
    AlarmOn:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u0427\u0430\u0441\u044b \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0433\u043e\u0442\u043e\u0432\u044b \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u041d\u0430\u0447\u0430\u043b\u043e \u0442\u0430\u0439\u043c\u0435\u0440 \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c alart bell checkmark clock disabled duration enabled notification off ready slash start timer watch',
    Album: '\u0445\u0443\u0434\u043e\u0436\u043d\u0438\u043a \u0430\u0443\u0434\u0438\u043e \u0431\u0432\u0431 CD \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0441\u043a \u0444\u0430\u0439\u043b \u041c\u0443\u0437\u044b\u043a\u0430 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u0437\u0432\u0443\u043a \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u0442\u044c \u0432\u0438\u043d\u0438\u043b artist audio bvb cd computer data disk file music play record sound storage track vinyl',
    AlignHorizontalCenter:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u043f\u0440\u0430\u0432\u0438\u043b\u0430 \u0441\u0442\u0438\u043b\u044c \u0442\u0435\u043a\u0441\u0442 alignment format layout lines paragraph rules style text',
    AlignHorizontalLeft:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u043f\u0440\u0430\u0432\u0438\u043b\u0430 \u0441\u0442\u0438\u043b\u044c \u0442\u0435\u043a\u0441\u0442 alignment format layout lines paragraph rules style text',
    AlignHorizontalRight:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u043f\u0440\u0430\u0432\u0438\u043b\u0430 \u0441\u0442\u0438\u043b\u044c \u0442\u0435\u043a\u0441\u0442 alignment format layout lines paragraph rules style text',
    AlignVerticalBottom:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u043f\u0440\u0430\u0432\u0438\u043b\u0430 \u0441\u0442\u0438\u043b\u044c \u0442\u0435\u043a\u0441\u0442 alignment format layout lines paragraph rules style text',
    AlignVerticalCenter:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u043f\u0440\u0430\u0432\u0438\u043b\u0430 \u0441\u0442\u0438\u043b\u044c \u0442\u0435\u043a\u0441\u0442 alignment format layout lines paragraph rules style text',
    AlignVerticalTop:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u043f\u0440\u0430\u0432\u0438\u043b\u0430 \u0441\u0442\u0438\u043b\u044c \u0442\u0435\u043a\u0441\u0442 alignment format layout lines paragraph rules style text',
    AllInbox:
        '\u0412\u0445\u043e\u0434\u044f\u0449\u0438\u0435 \u0434\u043e\u0441\u0442\u0430\u0432\u043b\u0435\u043d \u0414\u043e\u0441\u0442\u0430\u0432\u043a\u0430 \u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043f\u043e\u0447\u0442\u0430 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c Inbox delivered delivery email letter message post send',
    AllInclusive:
        '\u0431\u0435\u0441\u043a\u043e\u043d\u0435\u0447\u043d\u044b\u0439 \u043d\u0430\u0432\u0441\u0435\u0433\u0434\u0430 \u0431\u0435\u0441\u043a\u043e\u043d\u0435\u0447\u043d\u044b\u0439 \u0431\u0435\u0441\u043a\u043e\u043d\u0435\u0447\u043d\u043e\u0441\u0442\u044c \u043f\u0435\u0442\u043b\u044f \u041c\u0435\u0431\u0438\u0443\u0441 \u0431\u0435\u0441\u043a\u043e\u043d\u0435\u0447\u043d\u044b\u0439 \u043f\u043e\u043b\u043e\u0441\u043a\u0430 \u0443\u0441\u0442\u043e\u0439\u0447\u0438\u0432\u043e\u0441\u0442\u044c \u0441\u0442\u0430\u0431\u0438\u043b\u044c\u043d\u044b\u0439 endless forever infinite infinity loop mobius neverending strip sustainability sustainable',
    AllOut: '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u0440\u0443\u0433 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0441\u0448\u0438\u0440\u044f\u0442\u044c \u0444\u043e\u0440\u043c\u0430 arrows circle directional expand shape',
    AlternateEmail:
        '@ \u0430\u0434\u0440\u0435\u0441 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u044f\u0440\u043b\u044b\u043a @ address contact tag',
    AltRoute:
        '\u0447\u0435\u0440\u0435\u0434\u043e\u0432\u0430\u0442\u044c \u0430\u043b\u044c\u0442\u0435\u0440\u043d\u0430\u0442\u0438\u0432\u0430 \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043e\u043f\u0446\u0438\u0438 \u0414\u0440\u0443\u0433\u043e\u0439 \u043c\u0430\u0440\u0448\u0440\u0443\u0442\u044b \u0440\u0430\u0441\u043a\u043e\u043b\u043e\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b alternate alternative arrows direction maps navigation options other routes split symbol',
    Analytics:
        '\u043e\u0446\u0435\u043d\u043a\u0430 \u0431\u0430\u0440 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 assessment bar chart data diagram infographic measure metrics statistics tracking',
    Anchor: 'Google \u043b\u043e\u0433\u043e\u0442\u0438\u043f google logo',
    Android:
        '\u043c\u0430\u0440\u043a\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u0442\u0430\u043b\u0438\u0441\u043c\u0430\u043d \u0434\u0435\u0439\u0441\u0442\u0432\u0443\u044e\u0449\u0438\u0439 \u0441\u0438\u0441\u0442\u0435\u043c\u0430 \u0438\u0433\u0440\u0443\u0448\u043a\u0430 brand character logo mascot operating system toy',
    Animation:
        '\u043a\u0440\u0443\u0433\u0438 \u0444\u0438\u043b\u044c\u043c \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u043a\u0438\u043d\u043e \u0434\u0432\u0438\u0436\u0443\u0449\u0438\u0439\u0441\u044f \u043f\u043e\u0441\u043b\u0435\u0434\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0432\u0438\u0434\u0435\u043e circles film motion movement movie moving sequence video',
    Announcement:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u0432\u043e\u0437\u0434\u0443\u0448\u043d\u044b\u0439 \u0448\u0430\u0440 \u043f\u0443\u0437\u044b\u0440\u044c \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u041d\u043e\u0432\u043e\u0441\u0442\u0438 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0440\u0435\u0447\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert attention balloon bubble caution chat comment communicate danger error exclamation feedback important mark message news notification speech symbol warning',
    Aod: '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0432\u0441\u0435\u0433\u0434\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0434\u043e\u043c\u0430\u0448\u043d\u0438\u0439 \u044d\u043a\u0440\u0430\u043d iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android always device display hardware homescreen iOS mobile phone tablet',
    Apartment:
        '\u043f\u0440\u043e\u0436\u0438\u0432\u0430\u043d\u0438\u0435 \u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0433\u043e\u0440\u043e\u0434 \u041a\u043e\u043c\u043f\u0430\u043d\u0438\u044f \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u043f\u043b\u043e\u0441\u043a\u0438\u0439 \u0434\u043e\u043c \u0434\u043e\u043c \u043e\u0444\u0438\u0441 \u043c\u0435\u0441\u0442\u0430 \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 \u0435\u0434\u0438\u043d\u0438\u0446\u044b \u0440\u0430\u0431\u043e\u0447\u0435\u0435 \u043c\u0435\u0441\u0442\u043e accommodation architecture building city company estate flat home house office places real residence residential shelter units workplace',
    Api: '\u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u043a\u0430 \u043f\u0440\u0435\u0434\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u043d\u043e\u0433\u043e \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u044f developer development enterprise software',
    AppBlocking:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u041f\u0440\u0438\u043b\u043e\u0436\u0435\u043d\u0438\u044f \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u0438\u043b\u0441\u044f \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android applications cancel cell device hardware iOS mobile phone stopped tablet',
    Apple: '\u043c\u0430\u0440\u043a\u0430 \u043b\u043e\u0433\u043e\u0442\u0438\u043f brand logo',
    AppRegistration:
        '\u041f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u044b \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u0440\u0435\u0433\u0438\u0441\u0442\u0440 apps edit pencil register',
    Approval:
        '\u043f\u043e\u0434\u0430\u0442\u044c \u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0443\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043d\u0438\u044f \u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043a\u0430\u0442 \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043a\u0430\u0446\u0438\u044f \u043d\u0435\u043e\u0434\u043e\u0431\u0440\u0435\u043d\u0438\u0435 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u0432\u043f\u0435\u0447\u0430\u0442\u043b\u0435\u043d\u0438\u0435 \u0447\u0435\u0440\u043d\u0438\u043b\u0430 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043f\u043e\u0447\u0442\u043e\u0432\u044b\u0435 \u0440\u0430\u0441\u0445\u043e\u0434\u044b \u043f\u0435\u0447\u0430\u0442\u044c apply approvals approve certificate certification disapproval drive file impression ink mark postage stamp',
    Apps: '\u0432\u0441\u0435 \u041f\u0440\u0438\u043b\u043e\u0436\u0435\u043d\u0438\u044f \u043a\u0440\u0443\u0433\u0438 \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0442\u043e\u0447\u043a\u0438 \u0441\u0435\u0442\u043a\u0430 \u0434\u043e\u043c\u0430\u0448\u043d\u0438\u0439 \u044d\u043a\u0440\u0430\u043d \u0438\u043a\u043e\u043d\u043a\u0438 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043a\u0432\u0430\u0434\u0440\u0430\u0442\u044b \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 all applications circles collection components dots grid homescreen icons interface squares ui ux',
    AppSettingsAlt:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u041f\u0440\u0438\u043b\u043e\u0436\u0435\u043d\u0438\u044f \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043c\u0435\u0445\u0430\u043d\u0438\u0437\u043c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android applications cell device gear hardware iOS mobile phone tablet',
    AppShortcut:
        '\u0434\u043e\u0431\u0430\u0432\u043b\u0435\u043d \u0432 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0438 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0432\u044b\u0434\u0435\u043b\u044f\u0442\u044c \u0432\u0430\u0436\u043d\u044b\u0439 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d \u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u043d\u043e\u0433\u043e \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u044f \u0441\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 \u0437\u0432\u0435\u0437\u0434\u0430 bookmarked favorite highlight important mobile saved software special star',
    AppsOutage:
        '\u0432\u0441\u0435 \u041f\u0440\u0438\u043b\u043e\u0436\u0435\u043d\u0438\u044f \u043a\u0440\u0443\u0433\u0438 \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0442\u043e\u0447\u043a\u0438 \u0441\u0435\u0442\u043a\u0430 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043a\u0432\u0430\u0434\u0440\u0430\u0442\u044b \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 all applications circles collection components dots grid interface squares ui ux',
    Architecture:
        '\u0438\u0441\u043a\u0443\u0441\u0441\u0442\u0432\u043e \u043a\u043e\u043c\u043f\u0430\u0441 \u0434\u0438\u0437\u0430\u0439\u043d \u0420\u0438\u0441\u043e\u0432\u0430\u043d\u0438\u0435 \u0438\u043d\u0436\u0435\u043d\u0435\u0440\u0438\u044f \u0433\u0435\u043e\u043c\u0435\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 art compass design drawing engineering geometric tool',
    Archive:
        '\u0432\u0445\u043e\u0434\u044f\u0449\u0438\u0435 \u043f\u043e\u0447\u0442\u0430 \u0445\u0440\u0430\u043d\u0438\u0442\u044c inbox mail store',
    ArrowBack:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043f\u0440\u0435\u0434\u044b\u0434\u0443\u0449\u0438\u0439 \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application components direction interface left navigation previous screen ui ux website',
    ArrowBackIos:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0448\u0435\u0432\u0440\u043e\u043d \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043f\u0440\u0435\u0434\u044b\u0434\u0443\u0449\u0438\u0439 \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application chevron components direction interface left navigation previous screen ui ux website',
    ArrowBackIosNew:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0448\u0435\u0432\u0440\u043e\u043d \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043f\u0440\u0435\u0434\u044b\u0434\u0443\u0449\u0438\u0439 \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application chevron components direction interface left navigation previous screen ui ux website',
    ArrowCircleDown:
        '\u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f direction navigation',
    ArrowCircleLeft:
        '\u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f direction navigation',
    ArrowCircleRight:
        '\u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f direction navigation',
    ArrowCircleUp:
        '\u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f direction navigation',
    ArrowDownward:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application components direction interface navigation screen ui ux website',
    ArrowDropDown:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application components direction interface navigation screen ui ux website',
    ArrowDropDownCircle:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application components direction interface navigation screen ui ux website',
    ArrowDropUp:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application components direction interface navigation screen ui ux website',
    ArrowForward:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application arrows components direction interface navigation right screen ui ux website',
    ArrowForwardIos:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0448\u0435\u0432\u0440\u043e\u043d \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0441\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0439 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application chevron components direction interface navigation next right screen ui ux website',
    ArrowLeft:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043a\u043b\u0430\u0434\u044b\u0432\u0430\u0442\u044c \u043d\u0430\u0437\u0430\u0434 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043f\u0440\u0435\u0434\u044b\u0434\u0443\u0449\u0438\u0439 \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application backstack backward components direction interface navigation previous screen ui ux website',
    ArrowRight:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u041f\u0440\u043e\u0434\u043e\u043b\u0436\u0430\u0442\u044c \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043f\u0435\u0440\u0435\u0434 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application components continue direction forward interface navigation screen ui ux website',
    ArrowRightAlt:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043e\u0441\u0442\u043e\u043a \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0443\u043a\u0430\u0437\u044b\u0432\u0430\u044f \u0444\u043e\u0440\u043c\u0430 arrows direction east navigation pointing shape',
    ArrowUpward:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u044d\u043a\u0440\u0430\u043d \u0420\u0430\u0437\u043c\u0435\u0441\u0442\u0438\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application components direction interface navigation screen submit ui ux website',
    Article:
        '\u043e\u0431\u044a\u044f\u0441\u043d\u0438\u0442\u044c \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u041d\u043e\u0432\u043e\u0441\u0442\u0438 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0431\u0443\u043c\u0430\u0433\u0430 \u0442\u0435\u043a\u0441\u0442 \u043f\u0438\u0448\u0443 clarify document file news page paper text writing',
    ArtTrack:
        '\u0430\u043b\u044c\u0431\u043e\u043c \u0445\u0443\u0434\u043e\u0436\u043d\u0438\u043a \u0430\u0443\u0434\u0438\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0444\u043e\u0440\u043c\u0430\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0432\u0441\u0442\u0430\u0432\u043b\u044f\u0442\u044c \u041c\u0443\u0437\u044b\u043a\u0430 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0437\u0432\u0443\u043a \u0442\u0440\u0435\u043a\u0438 album artist audio display format image insert music photography picture sound tracks',
    AspectRatio:
        '\u0440\u0430\u0441\u0448\u0438\u0440\u044f\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0440\u0430\u0437\u043c\u0435\u0440 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0448\u043a\u0430\u043b\u0430 \u044d\u043a\u0440\u0430\u043d \u043f\u043b\u043e\u0449\u0430\u0434\u044c expand image monitor resize resolution scale screen square',
    Assessment:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u043e\u0442\u0447\u0435\u0442 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bars chart data diagram infographic measure metrics report statistics tracking',
    Assignment:
        '\u0441\u0442\u0430\u0442\u044c\u044f \u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0437\u0430\u0434\u0430\u0447\u0430 \u0442\u0435\u043a\u0441\u0442 \u043f\u0438\u0448\u0443 article clipboard document task text writing',
    AssignmentInd:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u043b\u0438\u0446\u043e \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0437\u0430\u0434\u0430\u0447\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account clipboard document face people person profile task user',
    AssignmentLate:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0437\u0430\u0434\u0430\u0447\u0430 \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert announcement attention caution clipboard danger document error exclamation important mark notification symbol task warning',
    AssignmentReturn:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u0442\u043e\u0447\u043a\u0430 \u0432\u043e\u0437\u0432\u0440\u0430\u0449\u0430\u0442\u044c\u0441\u044f \u0437\u0430\u0434\u0430\u0447\u0430 arrow back clipboard document left point retun task',
    AssignmentReturned:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043d\u0438\u0437 \u0442\u043e\u0447\u043a\u0430 \u0437\u0430\u0434\u0430\u0447\u0430 arrow clipboard document down point task',
    AssignmentTurnedIn:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u043f\u043e\u043b\u043d\u044b\u0439 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0437\u0430\u043a\u043e\u043d\u0447\u0435\u043d\u043d\u044b\u0439 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0437\u0430\u0434\u0430\u0447\u0430 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve checkmark clipboard complete document done finished ok select task tick validate verified yes',
    Assistant:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0440\u0435\u043a\u043e\u043c\u0435\u043d\u0434\u0430\u0446\u0438\u044f \u0440\u0435\u0447\u044c \u0437\u0432\u0435\u0437\u0434\u0430 \u043f\u0440\u0435\u0434\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043c\u0435\u0440\u0446\u0430\u043d\u0438\u0435 bubble chat comment communicate feedback message recommendation speech star suggestion twinkle',
    AssistantDirection:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043e\u0440\u0438\u0435\u043d\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 destination location maps navigate navigation pin place right stop',
    AssistantPhoto:
        '\u0444\u043b\u0430\u0433 \u0440\u0435\u043a\u043e\u043c\u0435\u043d\u0434\u0430\u0446\u0438\u044f \u0443\u043c\u043d\u0430\u044f \u0437\u0432\u0435\u0437\u0434\u0430 \u043f\u0440\u0435\u0434\u043b\u043e\u0436\u0435\u043d\u0438\u0435 flag recommendation smart star suggestion',
    AssuredWorkload:
        '\u0441\u043e\u0433\u043b\u0430\u0441\u0438\u0435 \u043a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 \u0444\u0435\u0434\u0435\u0440\u0430\u043b\u044c\u043d\u044b\u0439 \u043f\u0440\u0430\u0432\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0440\u0435\u0433\u0443\u043b\u0438\u0440\u0443\u044e\u0449\u0438\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0447\u0443\u0432\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0439 compliance confidential federal government regulatory secure sensitive',
    Atm: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0430\u0432\u0442\u043e\u043c\u0430\u0442\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0439 \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0442\u0435\u043b\u0435\u0436\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043c\u0430\u0448\u0438\u043d\u0430 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b \u043a\u0430\u0441\u0441\u0438\u0440 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet automated bill card cart cash character coin commerce credit currency dollars font letter machine money online payment shopping symbol teller text type',
    AttachEmail:
        '\u0432\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u043b\u0438\u043f \u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u043e\u043a\u0443\u0442\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u0441\u0430\u0439\u0442 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c attachment clip compose envelop letter link message send',
    AttachFile:
        '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0432\u0435\u0449\u044c \u0441\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u0441\u0430\u0439\u0442 \u043f\u043e\u0447\u0442\u0430 \u0421\u041c\u0418 \u0441\u043a\u0440\u0435\u043f\u043a\u0430 add item link mail media paperclip',
    Attachment:
        '\u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u0444\u0430\u0439\u043b \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0432\u0435\u0449\u044c \u0441\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u0441\u0430\u0439\u0442 \u0441\u043a\u0440\u0435\u043f\u043a\u0430 compose file image item link paperclip',
    AttachMoney:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u0432\u044b\u0433\u043e\u0434\u0430 \u0440\u0430\u0441\u043f\u0440\u043e\u0434\u0430\u0436\u0430 \u0441\u0438\u043c\u0432\u043e\u043b bill card cash coin commerce cost credit currency dollars finance online payment price profit sale symbol',
    Attractions:
        '\u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u0435 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u043e\u0431\u043e\u0437\u0440\u0435\u043d\u0438\u0435 \u0432\u0435\u0441\u0435\u043b\u043e \u043a\u0430\u0440\u0442\u044b \u043f\u0430\u0440\u043a \u043c\u0435\u0441\u0442\u0430 \u0440\u0443\u043b\u0435\u0432\u043e\u0435 \u043a\u043e\u043b\u0435\u0441\u043e amusement entertainment ferris fun maps park places wheel',
    Attribution:
        '\u0430\u0442\u0440\u0438\u0431\u0443\u0442 \u0442\u0435\u043b\u043e \u0410\u0432\u0442\u043e\u0440\u0441\u043a\u0438\u0435 \u043f\u0440\u0430\u0432\u0430 \u043a\u043e\u043f\u0438\u0440\u0430\u0439\u0442\u0435\u0440 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a attribute body copyright copywriter human people person',
    AudioFile:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u043a\u043b\u044e\u0447 \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u0437\u0432\u0443\u043a \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u0442\u044c document key music note sound track',
    Audiotrack:
        '\u043a\u043b\u044e\u0447 \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u0437\u0432\u0443\u043a key music note sound',
    AutoAwesome:
        '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0444\u0438\u043b\u044c\u0442\u0440 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0444\u043e\u0442\u043e \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440 \u0437\u0432\u0435\u0437\u0434\u044b adjust editing enhance filter image photography photos setting stars',
    AutoAwesomeMosaic:
        '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043a\u043e\u043b\u043b\u0430\u0436 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0444\u0438\u043b\u044c\u0442\u0440 \u0441\u0435\u0442\u043a\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043c\u0430\u043a\u0435\u0442 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0444\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440 adjust collage editing enhance filter grid image layout photographs photography photos pictures setting',
    AutoAwesomeMotion:
        '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0430\u043d\u0438\u043c\u0430\u0446\u0438\u044f \u043a\u043e\u043b\u043b\u0430\u0436 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0444\u0438\u043b\u044c\u0442\u0440 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0436\u0438\u0442\u044c \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0444\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440 \u0432\u0438\u0434\u0435\u043e adjust animation collage editing enhance filter image live photographs photography photos pictures setting video',
    AutoDelete:
        '\u043c\u0443\u0441\u043e\u0440\u043d\u043e\u0435 \u0432\u0435\u0434\u0440\u043e \u041c\u043e\u0436\u043d\u043e \u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043c\u0443\u0441\u043e\u0440 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f \u043c\u0443\u0441\u043e\u0440 bin can clock date garbage remove schedule time trash',
    AutoFixHigh:
        '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0441\u0442\u0435\u0440\u0435\u0442\u044c \u043c\u0430\u0433\u0438\u044f \u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0440\u0443\u0447\u043a\u0430 \u0437\u0432\u0435\u0437\u0434\u044b \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 \u043f\u0430\u043b\u043e\u0447\u043a\u0430 adjust editing enhance erase magic modify pen stars tool wand',
    AutoFixNormal:
        '\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0441\u0442\u0435\u0440\u0435\u0442\u044c \u043c\u0430\u0433\u0438\u044f \u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0437\u0432\u0435\u0437\u0434\u044b \u043f\u0430\u043b\u043e\u0447\u043a\u0430 edit erase magic modify stars wand',
    AutoFixOff:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0441\u0442\u0435\u0440\u0435\u0442\u044c \u043c\u0430\u0433\u0438\u044f \u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0432\u0435\u0437\u0434\u044b \u043f\u0430\u043b\u043e\u0447\u043a\u0430 disabled edit enabled erase magic modify on slash stars wand',
    AutofpsSelect:
        '\u0410 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u0420\u0430\u043c\u043a\u0430 \u0447\u0430\u0441\u0442\u043e\u0442\u0430 \u043f\u0438\u0441\u044c\u043c\u043e \u0437\u0430 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0441\u0435\u043a\u0443\u043d\u0434\u044b \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f A alphabet character font frame frequency letter per rate seconds symbol text type',
    AutoGraph:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043b\u0438\u043d\u0438\u044f \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0437\u0432\u0435\u0437\u0434\u044b \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics chart data diagram infographic line measure metrics stars statistics tracking',
    AutoMode:
        '\u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0433\u043d\u0435\u0437\u0434\u043e \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c around arrows direction inprogress loading navigation nest refresh renew rotate turn',
    Autorenew:
        '\u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u044d\u0448\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0439 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u043f\u043e\u0433\u0440\u0443\u0437\u0447\u0438\u043a \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0432 \u043e\u0436\u0438\u0434\u0430\u043d\u0438\u0438 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0441\u0442\u0430\u0442\u0443\u0441 \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c around arrows cached direction inprogress loader loading navigation pending refresh rotate status turn',
    AutoStories:
        '\u0430\u0443\u0434\u0438\u043e\u043a\u043d\u0438\u0433\u0430 \u043b\u0438\u0441\u0442\u0430\u043d\u0438\u0435 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u044b \u0447\u0442\u0435\u043d\u0438\u0435 \u0438\u0441\u0442\u043e\u0440\u0438\u044f audiobook flipping pages reading story',
    AvTimer:
        '\u0427\u0430\u0441\u044b \u043e\u0431\u0440\u0430\u0442\u043d\u044b\u0439 \u043e\u0442\u0441\u0447\u0435\u0442 \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u043c\u0438\u043d\u0443\u0442\u044b \u0441\u0435\u043a\u0443\u043d\u0434\u044b \u0441\u0435\u043a\u0443\u043d\u0434\u043e\u043c\u0435\u0440 clock countdown duration minutes seconds stopwatch',
    BabyChangingStation:
        '\u0434\u0435\u0442\u0438 \u0432\u0430\u043d\u043d\u0430\u044f \u043a\u043e\u043c\u043d\u0430\u0442\u0430 \u0442\u0435\u043b\u043e \u0434\u0435\u0442\u0438 \u043e\u0442\u0435\u0446 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043c\u043b\u0430\u0434\u0435\u043d\u0435\u0446 \u0414\u0435\u0442\u0438 \u043c\u0430\u0442\u044c \u043d\u043e\u0432\u043e\u0440\u043e\u0436\u0434\u0435\u043d\u043d\u044b\u0439 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0440\u0435\u0431\u0435\u043d\u043e\u043a, \u043d\u0430\u0447\u0438\u043d\u0430\u044e\u0449\u0438\u0439 \u0445\u043e\u0434\u0438\u0442\u044c \u0422\u0443\u0430\u043b\u0435\u0442 \u043c\u043e\u043b\u043e\u0434\u043e\u0439 babies bathroom body children father human infant kids mother newborn people person toddler wc young',
    Backpack:
        '\u043f\u043e\u0440\u0442\u0444\u0435\u043b\u044c \u0440\u044e\u043a\u0437\u0430\u043a \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c bookbag knapsack storage travel',
    Backspace:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0427\u0438\u0441\u0442\u043e \u043f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u044b\u0439 \u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0441\u0442\u0435\u0440\u0435\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c arrow cancel clear correct delete erase remove',
    Backup: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043e\u0431\u043b\u0430\u043a\u043e \u0434\u0430\u043d\u043d\u044b\u0435 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b\u044b \u043f\u0430\u043f\u043a\u0438 \u0442\u043e\u0447\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u0420\u0430\u0437\u043c\u0435\u0441\u0442\u0438\u0442\u044c \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c arrow cloud data drive files folders point storage submit upload',
    BackupTable:
        '\u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b\u044b \u043f\u0430\u043f\u043a\u0438 \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u043a\u0443\u0447\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 drive files folders format layout stack storage',
    Badge: '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0431\u043e\u0442\u043d\u0438\u043a \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0443\u0434\u043e\u0441\u0442\u043e\u0432\u0435\u0440\u0435\u043d\u0438\u0435 \u043b\u0438\u0447\u043d\u043e\u0441\u0442\u0438 \u0438\u043c\u044f \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0420\u0430\u0431\u043e\u0442\u0430 account avatar card certified employee face human identification name people person profile security user work',
    BakeryDining:
        '\u0445\u043b\u0435\u0431 \u0437\u0430\u0432\u0442\u0440\u0430\u043a \u043f\u043e\u0437\u0434\u043d\u0438\u0439 \u0437\u0430\u0432\u0442\u0440\u0430\u043a \u043a\u0440\u0443\u0430\u0441\u0441\u0430\u043d \u0435\u0434\u0430 bread breakfast brunch croissant food',
    Balance:
        '\u0440\u0430\u0432\u043d\u044b\u0439 \u0440\u0430\u0432\u043d\u043e\u0432\u0435\u0441\u0438\u0435 \u0431\u0435\u0441\u043f\u0440\u0438\u0441\u0442\u0440\u0430\u0441\u0442\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0441\u043f\u0440\u0438\u0441\u0442\u0440\u0430\u0441\u0442\u043d\u043e\u0441\u0442\u044c \u0441\u043f\u0440\u0430\u0432\u0435\u0434\u043b\u0438\u0432\u043e\u0441\u0442\u044c \u043f\u0430\u0440\u0438\u0442\u0435\u0442 \u0441\u0442\u0430\u0431\u0438\u043b\u044c\u043d\u043e\u0441\u0442\u044c. \u0443\u0441\u0442\u043e\u0439\u0447\u0438\u0432\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u043c\u0435\u0442\u0440\u0438\u044f equal equilibrium equity impartiality justice parity stability. steadiness symmetry',
    Balcony:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0434\u0432\u0435\u0440\u0438 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u0434\u043e\u043c \u043a\u0430\u0440\u0442\u044b \u0432\u043d\u0435 \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043e\u0441\u0442\u0430\u0442\u044c\u0441\u044f \u0442\u0435\u0440\u0440\u0430\u0441\u0430 \u043e\u043a\u043d\u043e architecture doors estate home house maps outside place real residence residential stay terrace window',
    Ballot: '\u043f\u0443\u043b\u044f \u043f\u0443\u043b\u044f \u0432\u044b\u0431\u043e\u0440\u044b \u0441\u043f\u0438\u0441\u043e\u043a \u0442\u043e\u0447\u043a\u0430 \u043e\u043f\u0440\u043e\u0441 \u0433\u043e\u043b\u043e\u0441\u043e\u0432\u0430\u043d\u0438\u0435 bullet bulllet election list point poll vote',
    BarChart:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics anlytics data diagram infographic measure metrics statistics tracking',
    BatchPrediction:
        '\u043b\u0430\u043c\u043f\u043e\u0447\u043a\u0430 \u0438\u0434\u0435\u044f \u043b\u0435\u0433\u043a\u0438\u0439 bulb idea light',
    Bathroom:
        '\u0448\u043a\u0430\u0444 \u0434\u043e\u043c \u0434\u043e\u043c \u043c\u0435\u0441\u0442\u043e \u0441\u0430\u043d\u0442\u0435\u0445\u043d\u0438\u043a\u0430 \u0434\u0443\u0448 \u0440\u0430\u0437\u0431\u0440\u044b\u0437\u0433\u0438\u0432\u0430\u0442\u0435\u043b\u044c \u043c\u044b\u0442\u044c \u0432\u043e\u0434\u0430 \u0422\u0443\u0430\u043b\u0435\u0442 closet home house place plumbing shower sprinkler wash water wc',
    Bathtub:
        '\u043a\u0443\u043f\u0430\u043d\u0438\u0435 \u0432\u0430\u043d\u043d\u0430\u044f \u043a\u043e\u043c\u043d\u0430\u0442\u0430 \u0447\u0438\u0441\u0442\u044b\u0439 \u0434\u043e\u043c \u043e\u0442\u0435\u043b\u044c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0434\u0443\u0448 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c bathing bathroom clean home hotel human person shower travel',
    Battery0Bar:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u043b\u0430 cell charge mobile power',
    Battery1Bar:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u043b\u0430 cell charge mobile power',
    Battery2Bar:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u043b\u0430 cell charge mobile power',
    Battery3Bar:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u043b\u0430 cell charge mobile power',
    Battery4Bar:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u043b\u0430 cell charge mobile power',
    Battery5Bar:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u043b\u0430 cell charge mobile power',
    Battery6Bar:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u043b\u0430 cell charge mobile power',
    BatteryAlert:
        '! \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0441\u0438\u043b\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! attention caution cell charge danger error exclamation important mark mobile notification power symbol warning',
    BatteryChargingFull:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043c\u043e\u043b\u043d\u0438\u044f \u043c\u043e\u043b\u043d\u0438\u044f \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u043b\u0430 \u0443\u0434\u0430\u0440 \u043c\u043e\u043b\u043d\u0438\u0438 cell charge lightening lightning mobile power thunderbolt',
    BatteryFull:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u043b\u0430 cell charge mobile power',
    BatterySaver:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u0437\u0430\u0440\u044f\u0434\u043a\u0430 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0441\u0438\u043b\u0430 \u0441\u0438\u043c\u0432\u043e\u043b + add charge charging new plus power symbol',
    BatteryStd:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043f\u043b\u044e\u0441 \u0441\u0438\u043b\u0430 \u0441\u0442\u0430\u043d\u0434\u0430\u0440\u0442\u043d\u044b\u0439 cell charge mobile plus power standard',
    BatteryUnknown:
        '? \u043f\u043e\u043c\u043e\u0449\u044c \u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043f\u043e\u043c\u043e\u0449\u044c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u043b\u0430 \u043f\u0443\u043d\u043a\u0442\u0443\u0430\u0446\u0438\u044f \u0432\u043e\u043f\u0440\u043e\u0441 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b ? assistance cell charge help information mark mobile power punctuation question support symbol',
    BeachAccess:
        '\u0437\u043e\u043d\u0442\u0438\u043a \u043c\u0435\u0441\u0442\u0430 \u043b\u0435\u0442\u043e \u0441\u043e\u043b\u043d\u0435\u0447\u043d\u043e \u0437\u043e\u043d\u0442\u0438\u043a parasol places summer sunny umbrella',
    Bed: '\u0421\u043f\u0430\u043b\u044c\u043d\u044f \u0434\u0432\u043e\u0439\u043d\u043e\u0439 \u043f\u043e\u043b\u043d\u044b\u0439 \u043c\u0435\u0431\u0435\u043b\u044c \u0434\u043e\u043c \u043e\u0442\u0435\u043b\u044c \u0434\u043e\u043c \u043a\u043e\u0440\u043e\u043b\u044c \u043d\u043e\u0447\u044c \u043f\u043e\u0434\u0443\u0448\u043a\u0438 \u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0430 \u043e\u0442\u0434\u044b\u0445\u0430\u0442\u044c \u0440\u0430\u0437\u043c\u0435\u0440 \u0441\u043f\u0430\u0442\u044c bedroom double full furniture home hotel house king night pillows queen rest size sleep',
    BedroomBaby:
        '\u0434\u0435\u0442\u0438 \u0434\u0435\u0442\u0438 \u0434\u043e\u043c \u043b\u043e\u0448\u0430\u0434\u044c \u0434\u043e\u043c \u043c\u043b\u0430\u0434\u0435\u043d\u0435\u0446 \u0440\u0435\u0431\u0435\u043d\u043e\u043a \u043d\u043e\u0432\u043e\u0440\u043e\u0436\u0434\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0441\u043a\u0430\u0447\u0438\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0431\u0435\u043d\u043e\u043a, \u043d\u0430\u0447\u0438\u043d\u0430\u044e\u0449\u0438\u0439 \u0445\u043e\u0434\u0438\u0442\u044c \u043c\u043e\u043b\u043e\u0434\u043e\u0439 babies children home horse house infant kid newborn rocking toddler young',
    BedroomChild:
        '\u0434\u0435\u0442\u0438 \u043c\u0435\u0431\u0435\u043b\u044c \u0434\u043e\u043c \u043e\u0442\u0435\u043b\u044c \u0434\u043e\u043c \u0440\u0435\u0431\u0435\u043d\u043e\u043a \u043d\u043e\u0447\u044c \u043f\u043e\u0434\u0443\u0448\u043a\u0438 \u043e\u0442\u0434\u044b\u0445\u0430\u0442\u044c \u0440\u0430\u0437\u043c\u0435\u0440 \u0441\u043f\u0430\u0442\u044c \u0431\u043b\u0438\u0437\u043d\u0435\u0446 \u043c\u043e\u043b\u043e\u0434\u043e\u0439 children furniture home hotel house kid night pillows rest size sleep twin young',
    BedroomParent:
        '\u0434\u0432\u043e\u0439\u043d\u043e\u0439 \u043f\u043e\u043b\u043d\u044b\u0439 \u043c\u0435\u0431\u0435\u043b\u044c \u0434\u043e\u043c \u043e\u0442\u0435\u043b\u044c \u0434\u043e\u043c \u043a\u043e\u0440\u043e\u043b\u044c \u043c\u0430\u0441\u0442\u0435\u0440 \u043d\u043e\u0447\u044c \u043f\u043e\u0434\u0443\u0448\u043a\u0438 \u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0430 \u043e\u0442\u0434\u044b\u0445\u0430\u0442\u044c \u0442\u044b \u0442\u043e\u0436\u0435 \u0441\u043f\u0430\u0442\u044c double full furniture home hotel house king master night pillows queen rest sizem sleep',
    Bedtime:
        '\u043d\u043e\u0447\u044c \u0441\u043f\u0430\u0442\u044c nightime sleep',
    BedtimeOff:
        '\u043d\u043e\u0447\u044c \u0441\u043f\u0430\u0442\u044c nightime sleep',
    Beenhere:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0430\u0440\u0445\u0438\u0432 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u044d\u0442\u0438\u043a\u0435\u0442\u043a\u0430 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u0447\u0442\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u044f\u0440\u043b\u044b\u043a \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve archive bookmark checkmark complete done favorite label library reading remember ribbon save select tag tick validate verified yes',
    Bento: '\u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u043e\u0431\u0435\u0434 \u0435\u0434\u0430 \u043e\u0431\u0435\u0434 \u0435\u0434\u0430 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d \u0432\u044b\u0438\u0433\u0440\u0430\u0442\u044c box dinner food lunch meal restaurant takeout',
    BikeScooter:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0432\u0435\u0441\u043f\u0430 automobile cars maps transportation vehicle vespa',
    Biotech:
        '\u0445\u0438\u043c\u0438\u044f \u043b\u0430\u0431\u043e\u0440\u0430\u0442\u043e\u0440\u0438\u044f \u043c\u0438\u043a\u0440\u043e\u0441\u043a\u043e\u043f \u0438\u0441\u0441\u043b\u0435\u0434\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0430\u044f \u0440\u0430\u0431\u043e\u0442\u0430 \u043d\u0430\u0443\u043a\u0430 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438 \u0442\u0435\u0441\u0442 chemistry laboratory microscope research science technology test',
    Blender:
        '\u043f\u0440\u0438\u0431\u043e\u0440 \u043f\u0440\u0438\u0433\u043e\u0442\u043e\u0432\u043b\u0435\u043d\u0438\u0435 \u0435\u0434\u044b \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0441\u043e\u043a\u043e\u0432\u044b\u0436\u0438\u043c\u0430\u043b\u043a\u0430 \u043a\u0443\u0445\u043d\u044f \u043c\u0430\u0448\u0438\u043d\u0430 \u0432\u0438\u0442\u0430\u043c\u0438\u043d\u0438\u043a\u0441 appliance cooking electric juicer kitchen machine vitamix',
    BlindsClosed:
        '\u043f\u043e\u043a\u0440\u044b\u0442\u0438\u0435 \u0448\u0442\u043e\u0440\u044b \u0433\u043d\u0435\u0437\u0434\u043e \u0437\u0430\u0442\u0432\u043e\u0440 \u0437\u043e\u043d\u0442\u0438\u043a cover curtains nest shutter sunshade',
    Block: '\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u044b\u0439 \u0438\u0437\u0431\u0435\u0433\u0430\u0442\u044c \u0437\u0430\u043f\u0440\u0435\u0449\u0435\u043d \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u0432\u0445\u043e\u0434 \u0432\u044b\u0445\u043e\u0434 \u043d\u0435\u0442 \u0437\u0430\u043f\u0440\u0435\u0449\u0435\u043d\u043d\u044b\u0439 \u043f\u043e\u043a\u0438\u0434\u0430\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 allowed avoid banned cancel close disable entry exit not prohibited quit remove stop',
    Bloodtype:
        '\u043f\u043e\u0436\u0435\u0440\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043a\u0430\u043f\u0435\u043b\u044c\u043a\u0430 \u0447\u0440\u0435\u0437\u0432\u044b\u0447\u0430\u0439\u043d\u0430\u044f \u0441\u0438\u0442\u0443\u0430\u0446\u0438\u044f \u0431\u043e\u043b\u044c\u043d\u0438\u0446\u0430 \u043b\u0435\u043a\u0430\u0440\u0441\u0442\u0432\u0435\u043d\u043d\u043e\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u043f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u0432\u043e\u0434\u0430 donate droplet emergency hospital medicine negative positive water',
    Bluetooth:
        '\u0412 \u0440\u043e\u043b\u044f\u0445 \u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0441\u0435\u0442\u044c \u0441\u0442\u0440\u0438\u0436\u043a\u0430 \u043f\u043e\u0442\u043e\u043a\u043e\u0432\u043e\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cast connection device network paring streaming symbol wireless',
    BluetoothAudio:
        '\u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u041c\u0443\u0437\u044b\u043a\u0430 \u0441\u0438\u0433\u043d\u0430\u043b \u0437\u0432\u0443\u043a \u0441\u0438\u043c\u0432\u043e\u043b connection device music signal sound symbol',
    BluetoothConnected:
        '\u0412 \u0440\u043e\u043b\u044f\u0445 \u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0441\u0435\u0442\u044c \u0441\u0442\u0440\u0438\u0436\u043a\u0430 \u043f\u043e\u0442\u043e\u043a\u043e\u0432\u043e\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cast connection device network paring streaming symbol wireless',
    BluetoothDisabled:
        '\u0412 \u0440\u043e\u043b\u044f\u0445 \u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0441\u0435\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u0441\u0442\u0440\u0438\u0436\u043a\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u043e\u0442\u043e\u043a\u043e\u0432\u043e\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cast connection device enabled network offline paring slash streaming symbol wireless',
    BluetoothDrive:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u0412 \u0440\u043e\u043b\u044f\u0445 \u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043a\u0430\u0440\u0442\u044b \u0441\u0442\u0440\u0438\u0436\u043a\u0430 \u043f\u043e\u0442\u043e\u043a\u043e\u0432\u043e\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 automobile cars cast connection device maps paring streaming symbol transportation travel vehicle wireless',
    BluetoothSearching:
        '\u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0441\u0435\u0442\u044c \u0441\u0442\u0440\u0438\u0436\u043a\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection device network paring symbol wireless',
    BlurCircular:
        '\u043a\u0440\u0443\u0433 \u0442\u043e\u0447\u043a\u0438 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0444\u0438\u043b\u044c\u0442\u0440 circle dots editing effect enhance filter',
    BlurLinear:
        '\u0442\u043e\u0447\u043a\u0438 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0444\u0438\u043b\u044c\u0442\u0440 dots editing effect enhance filter',
    BlurOff:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0442\u043e\u0447\u043a\u0438 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled dots editing effect enabled enhance on slash',
    BlurOn: '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0442\u043e\u0447\u043a\u0438 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0444\u0438\u043b\u044c\u0442\u0440 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled dots editing effect enabled enhance filter off slash',
    Bolt: '\u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u044d\u043d\u0435\u0440\u0433\u0438\u044f \u0431\u044b\u0441\u0442\u0440\u043e \u0432\u0441\u043f\u044b\u0448\u043a\u0430 \u043c\u043e\u043b\u043d\u0438\u044f \u0441\u0438\u043b\u0430 \u0443\u0434\u0430\u0440 \u043c\u043e\u043b\u043d\u0438\u0438 electric energy fast flash lightning power thunderbolt',
    Book: '\u0431\u043b\u043e\u0433 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u044d\u0442\u0438\u043a\u0435\u0442\u043a\u0430 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u0447\u0442\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u044f\u0440\u043b\u044b\u043a blog bookmark favorite label library reading remember ribbon save tag',
    Bookmark:
        '\u0430\u0440\u0445\u0438\u0432 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0441\u043b\u0435\u0434\u0438\u0442\u044c \u044d\u0442\u0438\u043a\u0435\u0442\u043a\u0430 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u0447\u0442\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u044f\u0440\u043b\u044b\u043a archive favorite follow label library reading remember ribbon save tag',
    BookmarkAdd:
        '+ \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u043f\u043b\u044e\u0441 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u0441\u0438\u043c\u0432\u043e\u043b + favorite plus remember ribbon save symbol',
    BookmarkAdded:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u0441\u043f\u0430\u0441\u0442\u0438 \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve check complete done favorite remember save select tick validate verified yes',
    BookmarkBorder:
        '\u0430\u0440\u0445\u0438\u0432 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u044d\u0442\u0438\u043a\u0435\u0442\u043a\u0430 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u043a\u043e\u043d\u0442\u0443\u0440 \u0447\u0442\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u044f\u0440\u043b\u044b\u043a archive favorite label library outline reading remember ribbon save tag',
    BookmarkRemove:
        '\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u043c\u0438\u043d\u0443\u0441 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u0432\u044b\u0447\u0435\u0441\u0442\u044c delete favorite minus remember ribbon save subtract',
    Bookmarks:
        '\u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u044d\u0442\u0438\u043a\u0435\u0442\u043a\u0430 \u0441\u043b\u043e\u0438 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u0447\u0442\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u043a\u0443\u0447\u0430 \u044f\u0440\u043b\u044b\u043a favorite label layers library multiple reading remember ribbon save stack tag',
    BookOnline:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0434\u043e\u043f\u0443\u0441\u043a \u0434\u0435\u043b\u043e\u0432\u043e\u0435 \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435, \u0432\u0441\u0442\u0440\u0435\u0447\u0430 \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043f\u0440\u043e\u0445\u043e\u0434\u0438\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0431\u0440\u043e\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u043f\u0440\u043e\u0435\u0437\u0434\u043d\u043e\u0439 \u0431\u0438\u043b\u0435\u0442 Android admission appointment cell device event hardware iOS mobile pass phone reservation tablet ticket',
    BorderAll:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 doc editing editor spreadsheet stroke text type writing',
    BorderBottom:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 doc editing editor spreadsheet stroke text type writing',
    BorderClear:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 doc editing editor spreadsheet stroke text type writing',
    BorderColor:
        '\u0432\u0441\u0435 \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043c\u0430\u0440\u043a\u0435\u0440 \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 all create doc editing editor marker pencil spreadsheet stroke text type writing',
    BorderHorizontal:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 doc editing editor spreadsheet stroke text type writing',
    BorderInner:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 doc editing editor spreadsheet stroke text type writing',
    BorderLeft:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 doc editing editor spreadsheet stroke text type writing',
    BorderOuter:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 doc editing editor spreadsheet stroke text type writing',
    BorderRight:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 doc editing editor spreadsheet stroke text type writing',
    BorderStyle:
        '\u0446\u0432\u0435\u0442 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 color doc editing editor spreadsheet stroke text type writing',
    BorderTop:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 doc editing editor spreadsheet stroke text type writing',
    BorderVertical:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 doc editing editor spreadsheet stroke text type writing',
    Boy: '\u0442\u0435\u043b\u043e \u041f\u043e\u043b \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043c\u0443\u0436\u0447\u0438\u043d\u0430 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u0438\u043c\u0432\u043e\u043b body gender human male people person social symbol',
    BrandingWatermark:
        '\u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0410\u0432\u0442\u043e\u0440\u0441\u043a\u0438\u0435 \u043f\u0440\u0430\u0432\u0430 \u0434\u0438\u0437\u0430\u0439\u043d \u044d\u043c\u0431\u043b\u0435\u043c\u0430 \u0444\u043e\u0440\u043c\u0430\u0442 \u043b\u0438\u0447\u043d\u043e\u0441\u0442\u044c \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043c\u0430\u043a\u0435\u0442 \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u044d\u043a\u0440\u0430\u043d \u043f\u0435\u0447\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e components copyright design emblem format identity interface layout logo screen stamp ui ux website window',
    BreakfastDining:
        '\u043f\u0435\u043a\u0430\u0440\u043d\u044f \u0445\u043b\u0435\u0431 \u043c\u0430\u0441\u043b\u043e \u0435\u0434\u0430 \u0442\u043e\u0441\u0442 bakery bread butter food toast',
    Brightness1:
        '\u043a\u0440\u0443\u0433 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043f\u043e\u043b\u0443\u043c\u0435\u0441\u044f\u0446 \u0441\u0435\u0440\u0435\u043d\u0442 \u0443\u0440\u043e\u0432\u0435\u043d\u044c \u043b\u0443\u043d\u0430 \u044d\u043a\u0440\u0430\u043d circle control crescent cresent level moon screen',
    Brightness2:
        '\u043a\u0440\u0443\u0433 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043f\u043e\u043b\u0443\u043c\u0435\u0441\u044f\u0446 \u0441\u0435\u0440\u0435\u043d\u0442 \u0443\u0440\u043e\u0432\u0435\u043d\u044c \u043b\u0443\u043d\u0430 \u043d\u043e\u0447\u044c \u044d\u043a\u0440\u0430\u043d circle control crescent cresent level moon night screen',
    Brightness3:
        '\u043a\u0440\u0443\u0433 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043f\u043e\u043b\u0443\u043c\u0435\u0441\u044f\u0446 \u0441\u0435\u0440\u0435\u043d\u0442 \u0443\u0440\u043e\u0432\u0435\u043d\u044c \u043b\u0443\u043d\u0430 \u043d\u043e\u0447\u044c \u044d\u043a\u0440\u0430\u043d circle control crescent cresent level moon night screen',
    Brightness4:
        '\u043a\u0440\u0443\u0433 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043f\u043e\u043b\u0443\u043c\u0435\u0441\u044f\u0446 \u0441\u0435\u0440\u0435\u043d\u0442 \u0442\u0435\u043c\u043d\u044b\u0439 \u0443\u0440\u043e\u0432\u0435\u043d\u044c \u043b\u0443\u043d\u0430 \u043d\u043e\u0447\u044c \u044d\u043a\u0440\u0430\u043d \u0441\u043e\u043b\u043d\u0446\u0435 circle control crescent cresent dark level moon night screen sun',
    Brightness5:
        '\u043a\u0440\u0443\u0433 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043f\u043e\u043b\u0443\u043c\u0435\u0441\u044f\u0446 \u0441\u0435\u0440\u0435\u043d\u0442 \u0443\u0440\u043e\u0432\u0435\u043d\u044c \u043b\u0443\u043d\u0430 \u044d\u043a\u0440\u0430\u043d \u0441\u043e\u043b\u043d\u0446\u0435 circle control crescent cresent level moon screen sun',
    Brightness6:
        '\u043a\u0440\u0443\u0433 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043f\u043e\u043b\u0443\u043c\u0435\u0441\u044f\u0446 \u0441\u0435\u0440\u0435\u043d\u0442 \u0443\u0440\u043e\u0432\u0435\u043d\u044c \u043b\u0443\u043d\u0430 \u044d\u043a\u0440\u0430\u043d \u0441\u043e\u043b\u043d\u0446\u0435 circle control crescent cresent level moon screen sun',
    Brightness7:
        '\u043a\u0440\u0443\u0433 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043f\u043e\u043b\u0443\u043c\u0435\u0441\u044f\u0446 \u0441\u0435\u0440\u0435\u043d\u0442 \u0443\u0440\u043e\u0432\u0435\u043d\u044c \u043b\u0435\u0433\u043a\u0438\u0439 \u043b\u0443\u043d\u0430 \u044d\u043a\u0440\u0430\u043d \u0441\u043e\u043b\u043d\u0446\u0435 circle control crescent cresent level light moon screen sun',
    BrightnessAuto:
        '\u0410 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0443\u0440\u043e\u0432\u0435\u043d\u044c \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u044d\u043a\u0440\u0430\u043d A control display level mobile monitor phone screen',
    BrightnessHigh:
        '\u0430\u0432\u0442\u043e \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0444\u043e\u043d auto control mobile monitor phone',
    BrightnessLow:
        '\u0430\u0432\u0442\u043e \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0444\u043e\u043d auto control mobile monitor phone',
    BrightnessMedium:
        '\u0430\u0432\u0442\u043e \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0444\u043e\u043d auto control mobile monitor phone',
    BrokenImage:
        '\u043a\u043e\u0440\u0440\u0443\u043c\u043f\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0439 \u043e\u0448\u0438\u0431\u043a\u0430 \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0440\u0430\u0437\u043e\u0440\u0432\u0430\u043d\u043d\u044b\u0439 corrupt error landscape mountains photography picture torn',
    BrowseGallery:
        '\u0427\u0430\u0441\u044b \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u043a\u0443\u0447\u0430 \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c clock collection library stack watch',
    BrowserNotSupported:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u044d\u043a\u0440\u0430\u043d \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 www disabled enabled internet off on page screen slash website www',
    BrowserUpdated:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0445\u0440\u043e\u043c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u044d\u043a\u0440\u0430\u043d \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android arrow chrome desktop device display download hardware iOS mac monitor screen web window',
    BrunchDining:
        '\u0437\u0430\u0432\u0442\u0440\u0430\u043a \u0448\u0430\u043c\u043f\u0430\u043d\u0441\u043a\u043e\u0435 \u0448\u0430\u043c\u043f\u0430\u043d\u0441\u043a\u043e\u0435 \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0435\u0434\u0430 \u043e\u0431\u0435\u0434 \u0435\u0434\u0430 breakfast champagne champaign drink food lunch meal',
    Brush: '\u0438\u0441\u043a\u0443\u0441\u0441\u0442\u0432\u043e \u0434\u0438\u0437\u0430\u0439\u043d \u0440\u0438\u0441\u043e\u0432\u0430\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 art design draw editing painting tool',
    BubbleChart:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bars data diagram infographic measure metrics statistics tracking',
    BugReport:
        '\u0436\u0438\u0432\u043e\u0442\u043d\u043e\u0435 \u0444\u0430\u0439\u043b \u0438\u0441\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043d\u0430\u0441\u0435\u043a\u043e\u043c\u043e\u0435 \u043f\u0440\u043e\u0431\u043b\u0435\u043c\u0430 \u043f\u0440\u043e\u0431\u043b\u0435\u043c\u0430 \u0442\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043f\u0440\u043e\u0435\u0437\u0434\u043d\u043e\u0439 \u0431\u0438\u043b\u0435\u0442 \u0432\u0438\u0440\u0443\u0441 \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 animal file fix insect issue problem testing ticket virus warning',
    Build: '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0438\u0441\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u0440\u0435\u043c\u043e\u043d\u0442 \u0433\u0430\u0435\u0447\u043d\u044b\u0439 \u043a\u043b\u044e\u0447 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 \u0433\u0430\u0435\u0447\u043d\u044b\u0439 \u043a\u043b\u044e\u0447 adjust fix repair spanner tool wrench',
    BuildCircle:
        '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0438\u0441\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u0440\u0435\u043c\u043e\u043d\u0442 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 \u0433\u0430\u0435\u0447\u043d\u044b\u0439 \u043a\u043b\u044e\u0447 adjust fix repair tool wrench',
    Bungalow:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u043a\u043e\u0442\u0442\u0435\u0434\u0436 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u0434\u043e\u043c \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043e\u0441\u0442\u0430\u0442\u044c\u0441\u044f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 architecture cottage estate home house maps place real residence residential stay traveling',
    BurstMode:
        '\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 image landscape mountains multiple photography picture',
    BusAlert:
        '! \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043a\u0430\u0440\u0442\u044b \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! attention automobile cars caution danger error exclamation important maps mark notification symbol transportation vehicle warning',
    Business:
        '\u0430\u0434\u0440\u0435\u0441 \u043a\u0432\u0430\u0440\u0442\u0438\u0440\u0430 \u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u041a\u043e\u043c\u043f\u0430\u043d\u0438\u044f \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u043f\u043b\u043e\u0441\u043a\u0438\u0439 \u0434\u043e\u043c \u043e\u0444\u0438\u0441 \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 \u0441\u0442\u0440\u0443\u043a\u0442\u0443\u0440\u0430 address apartment architecture building company estate flat home office place real residence residential shelter structure',
    BusinessCenter:
        '\u0411\u0430\u0433\u0430\u0436 \u043f\u043e\u0440\u0442\u0444\u0435\u043b\u044c \u043c\u0435\u0441\u0442\u0430 \u043a\u043e\u0448\u0435\u043b\u0435\u043a \u0447\u0435\u043c\u043e\u0434\u0430\u043d \u0420\u0430\u0431\u043e\u0442\u0430 baggage briefcase places purse suitcase work',
    Cabin: '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u043f\u043e\u0445\u043e\u0434 \u043a\u043e\u0442\u0442\u0435\u0434\u0436 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u0434\u043e\u043c \u0436\u0443\u0440\u043d\u0430\u043b \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043e\u0441\u0442\u0430\u0442\u044c\u0441\u044f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 \u0434\u0440\u0435\u0432\u0435\u0441\u0438\u043d\u0430 architecture camping cottage estate home house log maps place real residence residential stay traveling wood',
    Cable: '\u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u0438\u043a\u0430 USB \u043f\u0440\u043e\u0432\u043e\u0434 connection device electronics usb wire',
    Cached: '\u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u043f\u043e\u0433\u0440\u0443\u0437\u0447\u0438\u043a \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u0435\u0440\u0435\u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c around arrows inprogress loader loading refresh reload renew rotate',
    Cake: '\u0437\u0430\u043f\u0435\u0447\u0435\u043d\u043d\u044b\u0439 \u0434\u0435\u043d\u044c \u0440\u043e\u0436\u0434\u0435\u043d\u0438\u044f \u0441\u0432\u0435\u0447\u0438 \u043f\u0440\u0430\u0437\u0434\u043d\u0438\u043a \u0414\u0435\u0441\u0435\u0440\u0442 \u0435\u0434\u0430 \u0438\u043d\u0435\u0439 \u043f\u0430\u0440\u0442\u0438\u044f \u0432\u044b\u043f\u0435\u0447\u043a\u0430 \u0432\u044b\u043f\u0435\u0447\u043a\u0430 \u0432 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043b\u0430\u0434\u043a\u0438\u0439 baked birthday candles celebration dessert food frosting party pastries pastry pie social sweet',
    Calculate:
        '+ - \u0437\u043d\u0430\u043a \u0440\u0430\u0432\u043d\u043e \u043a\u0430\u043b\u044c\u043a\u0443\u043b\u044f\u0442\u043e\u0440 \u0441\u0447\u0438\u0442\u0430\u0442\u044c \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u043c\u0430\u0442\u0435\u043c\u0430\u0442\u0438\u043a\u0430 + - = calculator count finance math',
    CalendarMonth:
        '\u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 C\u0435\u0433\u043e\u0434\u043d\u044f date event schedule today',
    CalendarToday:
        '\u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u043c\u0435\u0441\u044f\u0446 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u043d\u0435\u0434\u0435\u043b\u044f date event month remember reminder schedule week',
    CalendarViewDay:
        '\u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u043c\u0435\u0441\u044f\u0446 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 C\u0435\u0433\u043e\u0434\u043d\u044f \u043d\u0435\u0434\u0435\u043b\u044f date event format grid layout month remember reminder schedule today week',
    CalendarViewMonth:
        '\u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 C\u0435\u0433\u043e\u0434\u043d\u044f date event format grid layout schedule today',
    CalendarViewWeek:
        '\u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u043c\u0435\u0441\u044f\u0446 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 C\u0435\u0433\u043e\u0434\u043d\u044f date event format grid layout month schedule today',
    Call: '\u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0440\u0430\u0437\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c \u0442\u0435\u043b\u0435\u0444\u043e\u043d cell contact device hardware mobile talk telephone',
    CallEnd:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0440\u0430\u0437\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c \u0442\u0435\u043b\u0435\u0444\u043e\u043d cell contact device hardware mobile talk telephone',
    CallMade:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 arrow device mobile',
    CallMerge:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 arrow device mobile',
    CallMissed:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 arrow device mobile',
    CallMissedOutgoing:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 arrow device mobile',
    CallReceived:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 arrow device mobile',
    CallSplit:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 arrow device mobile',
    CallToAction:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0431\u0430\u0440 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 cta \u0434\u0438\u0437\u0430\u0439\u043d \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043c\u0430\u043a\u0435\u0442 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e alert bar components cta design information interface layout message notification screen ui ux website window',
    Camera: '\u0430\u043b\u044c\u0431\u043e\u043c \u043e\u0442\u0432\u0435\u0440\u0441\u0442\u0438\u0435 \u043e\u0431\u044a\u0435\u043a\u0442\u0438\u0432 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u0421\u043a\u0440\u0438\u043d\u0448\u043e\u0442 \u0437\u0430\u0442\u0432\u043e\u0440 album aperture lens photography picture record screenshot shutter',
    CameraAlt:
        '\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 image photography picture',
    CameraEnhance:
        '\u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0431\u044a\u0435\u043a\u0442\u0438\u0432 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0441\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 \u0437\u0432\u0435\u0437\u0434\u0430 important lens photography picture quality special star',
    CameraFront:
        '\u0442\u0435\u043b\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043e\u0431\u044a\u0435\u043a\u0442\u0438\u0432 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043f\u043e\u0440\u0442\u0440\u0435\u0442 \u0441\u0435\u043b\u0444\u0438 body human lens mobile person phone photography portrait selfie',
    CameraIndoor:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0441\u044a\u0435\u043c\u043a\u0438 \u0444\u0438\u043b\u044c\u043c\u0430 \u0434\u043e\u043c \u0434\u043e\u043c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0432\u043d\u0443\u0442\u0440\u0438 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u0433\u043d\u0435\u0437\u0434\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 \u0432\u0438\u0434\u0435\u043e\u0441\u044a\u0435\u043c\u043a\u0430 architecture building estate filming home house image inside motion nest picture place real residence residential shelter videography',
    CameraOutdoor:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0441\u044a\u0435\u043c\u043a\u0438 \u0444\u0438\u043b\u044c\u043c\u0430 \u0434\u043e\u043c \u0434\u043e\u043c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u0433\u043d\u0435\u0437\u0434\u043e \u0432\u043d\u0435 \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 \u0432\u0438\u0434\u0435\u043e\u0441\u044a\u0435\u043c\u043a\u0430 architecture building estate filming home house image motion nest outside picture place real residence residential shelter videography',
    CameraRear:
        '\u0444\u0440\u043e\u043d\u0442 \u043e\u0431\u044a\u0435\u043a\u0442\u0438\u0432 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u043e\u0440\u0442\u0440\u0435\u0442 \u0441\u0435\u043b\u0444\u0438 front lens mobile phone photography picture portrait selfie',
    CameraRoll:
        '\u0444\u0438\u043b\u044c\u043c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f film image library photography',
    Cameraswitch:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u0443\u0432\u044b\u0440\u043e\u043a \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043c\u0435\u043d\u044f\u0442\u044c \u041f\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c arrows flip rotate swap view',
    Campaign:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0433\u0440\u043e\u043c\u043a\u0438\u0439 \u043c\u0435\u0433\u0430\u0444\u043e\u043d \u043c\u0438\u043a\u0440\u043e\u0444\u043e\u043d \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043e\u0440\u0430\u0442\u043e\u0440 alert announcement loud megaphone microphone notification speaker',
    Cancel: '\u043a\u0440\u0443\u0433 \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u041f\u0435\u0440\u0435\u0441\u0435\u043a\u0430\u0442\u044c \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u0432\u044b\u0445\u043e\u0434 \u0441\u0442\u0430\u0442\u0443\u0441 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 circle close cross disable exit status stop',
    CancelPresentation:
        '\u0417\u0430\u043a\u0440\u044b\u0442\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0432\u044b\u0445\u043e\u0434 \u043d\u0435\u0442 \u043f\u043e\u043a\u0438\u0434\u0430\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u044d\u043a\u0440\u0430\u043d \u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f \u0433\u043e\u0440\u043a\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e close device exit no quit remove screen share slide stop website window',
    CancelScheduleSend:
        '\u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043d\u0435\u0442 \u043f\u043e\u043a\u0438\u0434\u0430\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0418\u043a\u0441 email no quit remove share stop x',
    CandlestickChart:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics data diagram finance infographic measure metrics statistics tracking',
    CarCrash:
        '\u043d\u0435\u0441\u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0441\u043b\u0443\u0447\u0430\u0439 \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u0441\u0442\u043e\u043b\u043a\u043d\u043e\u0432\u0435\u043d\u0438\u0435 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f accident automobile cars collision direction maps public transportation vehicle',
    CardGiftcard:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u043e\u0441\u0442\u0430\u0442\u043e\u043a \u0441\u0440\u0435\u0434\u0441\u0442\u0432 \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0442\u0435\u043b\u0435\u0436\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043a\u0430\u0442 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442\u043d\u0430\u044f \u043a\u0430\u0440\u0442\u0430 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u0434\u0430\u0440\u043e\u043a \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c account balance bill cart cash certificate coin commerce creditcard currency dollars money online payment present shopping',
    CardMembership:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043a\u0430\u0442 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442\u043d\u0430\u044f \u043a\u0430\u0440\u0442\u0430 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0432\u0435\u0440\u043d\u043e\u0441\u0442\u044c \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u043f\u043e\u0434\u043f\u0438\u0441\u043a\u0430 bill bookmark cash certificate coin commerce cost creditcard currency dollars finance loyalty money online payment shopping subscription',
    CardTravel:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442\u043d\u0430\u044f \u043a\u0430\u0440\u0442\u0430 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0447\u043b\u0435\u043d\u0441\u0442\u0432\u043e \u043c\u0438\u043b\u0438 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 bill cash coin commerce cost creditcard currency dollars finance membership miles money online payment trip',
    Carpenter:
        '\u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0440\u0435\u0437\u043a\u0430 \u0440\u0430\u0437\u043d\u043e\u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0440\u0435\u043c\u043e\u043d\u0442 \u043f\u0438\u043b\u0430 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 building construction cutting handyman repair saw tool',
    CarRental:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u043b\u044e\u0447 \u043a\u0430\u0440\u0442\u044b \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars key maps transportation vehicle',
    CarRepair:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars maps transportation vehicle',
    Cases: '\u0411\u0430\u0433\u0430\u0436 \u043f\u043e\u0440\u0442\u0444\u0435\u043b\u044c \u0431\u0438\u0437\u043d\u0435\u0441 \u043a\u043e\u0448\u0435\u043b\u0435\u043a \u0447\u0435\u043c\u043e\u0434\u0430\u043d baggage briefcase business purse suitcase',
    Casino: '\u0438\u0433\u0440\u0430\u043b\u044c\u043d\u0430\u044f \u043a\u043e\u0441\u0442\u044c \u0442\u043e\u0447\u043a\u0438 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0430\u0437\u0430\u0440\u0442\u043d\u0430\u044f \u0438\u0433\u0440\u0430 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0430\u0437\u0430\u0440\u0442\u043d\u044b\u0435 \u0438\u0433\u0440\u044b \u0438\u0433\u0440\u044b \u0443\u0434\u0430\u0447\u0430 \u043c\u0435\u0441\u0442\u0430 dice dots entertainment gamble gambling games luck places',
    Cast: '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0442\u0440\u0430\u043d\u0441\u043b\u044f\u0446\u0438\u044f \u0445\u0440\u043e\u043c\u043a\u0430\u0441\u0442 \u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0441\u043a\u0440\u0438\u043d\u043a\u0430\u0441\u0442 \u043f\u043e\u0442\u043e\u043a\u043e\u0432\u043e\u0435 \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 Android airplay chromecast connect desktop device display hardware iOS mac monitor screencast streaming television tv web window wireless',
    CastConnected:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0442\u0440\u0430\u043d\u0441\u043b\u044f\u0446\u0438\u044f \u0445\u0440\u043e\u043c\u043a\u0430\u0441\u0442 \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0441\u043a\u0440\u0438\u043d\u043a\u0430\u0441\u0442 \u043f\u043e\u0442\u043e\u043a\u043e\u0432\u043e\u0435 \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 Android airplay chromecast desktop device display hardware iOS mac monitor screencast streaming television tv web window wireless',
    CastForEducation:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0442\u0440\u0430\u043d\u0441\u043b\u044f\u0446\u0438\u044f \u0445\u0440\u043e\u043c \u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043e\u0431\u0443\u0447\u0435\u043d\u0438\u0435 \u0443\u0440\u043e\u043a\u0438 \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0441\u043a\u0440\u0438\u043d\u043a\u0430\u0441\u0442 \u043f\u043e\u0442\u043e\u043a\u043e\u0432\u043e\u0435 \u043e\u0431\u0443\u0447\u0435\u043d\u0438\u0435 \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 Android airplay chrome connect desktop device display hardware iOS learning lessons mac monitor screencast streaming teaching television tv web window wireless',
    Castle: '\u043a\u0440\u0435\u043f\u043e\u0441\u0442\u044c \u043e\u0441\u043e\u0431\u043d\u044f\u043a \u0434\u0432\u043e\u0440\u0435\u0446 fortress mansion palace',
    CatchingPokemon:
        '\u0438\u0434\u0442\u0438 \u043f\u043e\u043a\u0435\u0441\u0442\u043e\u043f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c go pokestop travel',
    Category:
        '\u043a\u0430\u0442\u0435\u0433\u043e\u0440\u0438\u0438 \u043a\u0440\u0443\u0433 \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u041f\u0440\u0435\u0434\u043c\u0435\u0442\u044b \u0442\u043e\u0432\u0430\u0440 \u0421\u043e\u0440\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0442\u0440\u0435\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a categories circle collection items product sort square triangle',
    Celebration:
        '\u041c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u044f \u0434\u0435\u043d\u044c \u0440\u043e\u0436\u0434\u0435\u043d\u0438\u044f \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u0432\u0435\u0441\u0435\u043b\u043e \u043f\u0430\u0440\u0442\u0438\u044f activity birthday event fun party',
    CellTower:
        '\u0442\u0440\u0430\u043d\u0441\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u041a\u0430\u0441\u0442\u0438\u043d\u0433 \u0441\u0435\u0442\u044c \u0441\u0438\u0433\u043d\u0430\u043b \u043f\u0435\u0440\u0435\u0434\u0430\u0447\u0430 \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 broadcast casting network signal transmitting wireless',
    CellWifi:
        '\u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection data internet mobile network phone service signal wireless',
    CenterFocusStrong:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043e\u0431\u044a\u0435\u043a\u0442\u0438\u0432 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0437\u0443\u043c camera image lens photography zoom',
    CenterFocusWeak:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043e\u0431\u044a\u0435\u043a\u0442\u0438\u0432 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0437\u0443\u043c camera image lens photography zoom',
    Chair: '\u043a\u043e\u043c\u0444\u043e\u0440\u0442 \u0434\u0438\u0432\u0430\u043d \u0443\u043a\u0440\u0430\u0448\u0435\u043d\u0438\u0435 \u043c\u0435\u0431\u0435\u043b\u044c \u0434\u043e\u043c \u0434\u043e\u043c \u0436\u0438\u0437\u043d\u044c \u0431\u0435\u0437\u0434\u0435\u043b\u044c\u043d\u0438\u0447\u0430\u044e\u0449\u0438\u0439 \u0434\u0432\u0443\u0445\u043c\u0435\u0441\u0442\u043d\u043e\u0435 \u0441\u0438\u0434\u0435\u043d\u044c\u0435 \u043d\u043e\u043c\u0435\u0440 \u0441\u0438\u0434\u0435\u043d\u0438\u0435 \u0434\u0438\u0432\u0430\u043d comfort couch decoration furniture home house living lounging loveseat room seating sofa',
    ChairAlt:
        '\u041a\u0430\u0438\u0440 \u043c\u0435\u0431\u0435\u043b\u044c \u0434\u043e\u043c \u0434\u043e\u043c \u043a\u0443\u0445\u043d\u044f \u0431\u0435\u0437\u0434\u0435\u043b\u044c\u043d\u0438\u0447\u0430\u044e\u0449\u0438\u0439 \u0441\u0438\u0434\u0435\u043d\u0438\u0435 \u0441\u0442\u043e\u043b cahir furniture home house kitchen lounging seating table',
    Chalet: '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u043a\u043e\u0442\u0442\u0435\u0434\u0436 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u0434\u043e\u043c \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043e\u0441\u0442\u0430\u0442\u044c\u0441\u044f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 architecture cottage estate home house maps place real residence residential stay traveling',
    ChangeCircle:
        '\u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0432\u0440\u0430\u0449\u0430\u0442\u044c around arrows direction navigation rotate',
    ChangeHistory:
        '\u0444\u043e\u0440\u043c\u0430 \u0442\u0440\u0435\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a shape triangle',
    ChargingStation:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0431\u0430\u0442\u0430\u0440\u0435\u044f \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u043b\u043d\u0438\u044f \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0443\u0434\u0430\u0440 \u043c\u043e\u043b\u043d\u0438\u0438 Android battery cell device electric hardware iOS lightning mobile phone tablet thunderbolt',
    Chat: '\u043f\u0443\u0437\u044b\u0440\u044c \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0440\u0435\u0447\u044c \u0440\u0430\u0437\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c \u0442\u0435\u043a\u0441\u0442 bubble comment communicate feedback message speech talk text',
    ChatBubble:
        '\u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0440\u0435\u0447\u044c \u0440\u0430\u0437\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c \u0442\u0435\u043a\u0441\u0442 comment communicate feedback message speech talk text',
    ChatBubbleOutline:
        '\u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0440\u0435\u0447\u044c \u0440\u0430\u0437\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c \u0442\u0435\u043a\u0441\u0442 comment communicate feedback message speech talk text',
    Check: '\u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u043f\u043e\u043b\u043d\u044b\u0439 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0432\u043e\u0439\u0442\u0438 \u0445\u043e\u0440\u043e\u0448\u043e \u043a\u0443\u043f\u0438\u043b \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0443\u0441\u043f\u0435\u0445 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u0434\u0430 checkmark complete confirm correct done enter okay purchased select success tick yes',
    CheckBox:
        '\u043e\u0434\u043e\u0431\u0440\u0435\u043d\u043d\u044b\u0439 \u043a\u043d\u043e\u043f\u043a\u0430 \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u0430\u044f \u0447\u0430\u0441\u0442\u044c \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u0444\u043e\u0440\u043c\u0430 Ok \u0432\u044b\u0431\u0440\u0430\u043d \u0432\u044b\u0431\u043e\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0443\u0441\u043f\u0435\u0445 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0434\u0430 approved button checkmark component control form ok selected selection square success tick toggle ui yes',
    CheckBoxOutlineBlank:
        '\u043a\u043d\u043e\u043f\u043a\u0430 \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u0430\u044f \u0447\u0430\u0441\u0442\u044c \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u0441\u043d\u044f\u0442 \u0441 \u0432\u044b\u0431\u043e\u0440\u0430 \u043f\u0443\u0441\u0442\u043e\u0439 \u0444\u043e\u0440\u043c\u0430 \u0432\u044b\u0431\u043e\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 button checkmark component control deselected empty form selection square tick toggle ui',
    CheckCircle:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u0437\u0430\u043a\u043e\u043d\u0447\u0435\u043d\u043d\u044b\u0439 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0443\u0441\u043f\u0435\u0445 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve checkmark complete done download finished ok select success tick upload validate verified yes',
    CheckCircleOutline:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0437\u0430\u043a\u043e\u043d\u0447\u0435\u043d\u043d\u044b\u0439 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0443\u0441\u043f\u0435\u0445 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve checkmark complete done finished ok select success tick validate verified yes',
    Checkroom:
        '\u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u0448\u043a\u0430\u0444 \u043e\u0434\u0435\u0436\u0434\u0430 \u041f\u0430\u043b\u044c\u0442\u043e \u0432\u0435\u0448\u0430\u043b\u043a\u0430 check closet clothes coat hanger',
    ChevronLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u0437\u0430\u0434 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0442\u0440\u0435\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a arrows back direction triangle',
    ChevronRight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043f\u0435\u0440\u0435\u0434 \u0442\u0440\u0435\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a arrows direction forward triangle',
    ChildCare:
        '\u0434\u0435\u0442\u0438 \u043c\u0430\u043b\u044b\u0448 \u0434\u0435\u0442\u0438 \u043b\u0438\u0446\u043e \u043c\u043b\u0430\u0434\u0435\u043d\u0435\u0446 \u0414\u0435\u0442\u0438 \u043d\u043e\u0432\u043e\u0440\u043e\u0436\u0434\u0435\u043d\u043d\u044b\u0439 \u0440\u0435\u0431\u0435\u043d\u043e\u043a, \u043d\u0430\u0447\u0438\u043d\u0430\u044e\u0449\u0438\u0439 \u0445\u043e\u0434\u0438\u0442\u044c \u043c\u043e\u043b\u043e\u0434\u043e\u0439 babies baby children face infant kids newborn toddler young',
    ChildFriendly:
        '\u043c\u0430\u043b\u044b\u0448 \u0437\u0430\u0431\u043e\u0442\u0430 \u043f\u0435\u0440\u0435\u0432\u043e\u0437\u043a\u0430 \u0434\u0435\u0442\u0438 \u043c\u043b\u0430\u0434\u0435\u043d\u0435\u0446 \u0440\u0435\u0431\u0435\u043d\u043e\u043a \u043d\u043e\u0432\u043e\u0440\u043e\u0436\u0434\u0435\u043d\u043d\u044b\u0439 \u0411\u0440\u043e\u0434\u044f\u0433\u0430 \u0440\u0435\u0431\u0435\u043d\u043e\u043a, \u043d\u0430\u0447\u0438\u043d\u0430\u044e\u0449\u0438\u0439 \u0445\u043e\u0434\u0438\u0442\u044c \u043c\u043e\u043b\u043e\u0434\u043e\u0439 baby care carriage children infant kid newborn stroller toddler young',
    ChromeReaderMode: '\u0442\u0435\u043a\u0441\u0442 text',
    Church: '\u0445\u0440\u0438\u0441\u0442\u0438\u0430\u043d\u0441\u0442\u0432\u043e \u0440\u0435\u043b\u0438\u0433\u0438\u044f \u0434\u0443\u0445\u043e\u0432\u043d\u044b\u0439 \u043f\u043e\u043a\u043b\u043e\u043d\u0435\u043d\u0438\u0435 christianity religion spiritual worship',
    Circle: '\u043f\u0443\u043b\u044f \u043a\u043d\u043e\u043f\u043a\u0430 \u0442\u043e\u0447\u043a\u0430 \u043f\u043e\u043b\u043d\u044b\u0439 \u0433\u0435\u043e\u043c\u0435\u0442\u0440\u0438\u044f \u043b\u0443\u043d\u0430 \u043f\u0435\u0440\u0438\u043e\u0434 \u0440\u0430\u0434\u0438\u043e bullet button dot full geometry moon period radio',
    CircleNotifications:
        '\u0430\u043a\u0442\u0438\u0432\u043d\u044b\u0439 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u043f\u0435\u0440\u0435\u0437\u0432\u043e\u043d \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u044f\u0442\u044c \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0437\u0432\u0435\u043d\u0435\u0442\u044c \u0437\u0432\u0443\u043a active alarm alert bell chime notify reminder ring sound',
    Class: '\u0430\u0440\u0445\u0438\u0432 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043a\u0430\u0442\u0435\u0433\u043e\u0440\u0438\u044f \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0432\u0435\u0449\u044c \u044d\u0442\u0438\u043a\u0435\u0442\u043a\u0430 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u0447\u0442\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u044f\u0440\u043b\u044b\u043a archive bookmark category favorite item label library reading remember ribbon save tag',
    CleanHands:
        '\u0431\u0430\u043a\u0442\u0435\u0440\u0438\u0438 \u0434\u0435\u0437\u0438\u043d\u0444\u0438\u0446\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043c\u0438\u043a\u0440\u043e\u0431\u044b \u0436\u0435\u0441\u0442 \u0434\u0435\u0437\u0438\u043d\u0444\u0438\u0446\u0438\u0440\u0443\u044e\u0449\u0435\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e bacteria disinfect germs gesture sanitizer',
    CleaningServices:
        '\u043f\u044b\u043b\u044c \u043f\u043e\u0434\u043c\u0435\u0442\u0430\u0442\u044c dust sweep',
    Clear: '\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u044b\u0439 \u043d\u0430\u0437\u0430\u0434 \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u043f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u044b\u0439 \u041f\u0435\u0440\u0435\u0441\u0435\u043a\u0430\u0442\u044c \u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u0441\u0442\u0435\u0440\u0435\u0442\u044c \u0432\u044b\u0445\u043e\u0434 \u043d\u0435\u0442 \u0440\u0430\u0437 allowed back cancel correct cross delete disable erase exit not times',
    ClearAll:
        '\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0441\u0442\u0435\u0440\u0435\u0442\u044c \u0444\u043e\u0440\u043c\u0430\u0442 \u043b\u0438\u043d\u0438\u0438 \u0441\u043f\u0438\u0441\u043e\u043a \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u044f \u0432\u044b\u0442\u0438\u0440\u0430\u0442\u044c delete document erase format lines list notifications wipe',
    Close: '\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u044b\u0439 \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u041f\u0435\u0440\u0435\u0441\u0435\u043a\u0430\u0442\u044c \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u0432\u044b\u0445\u043e\u0434 \u043d\u0435\u0442 \u0441\u0442\u0430\u0442\u0443\u0441 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0440\u0430\u0437 allowed cancel cross disable exit not status stop times',
    ClosedCaption:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b\u0439 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u0435\u043a\u043e\u0434\u0435\u0440 \u0448\u0440\u0438\u0444\u0442 \u044f\u0437\u044b\u043a \u043f\u0438\u0441\u044c\u043c\u043e \u0421\u041c\u0418 \u0444\u0438\u043b\u044c\u043c\u044b \u0441\u0443\u0431\u0442\u0438\u0442\u0440\u044b \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0442\u0438\u043f accessible alphabet character decoder font language letter media movies subtitles symbol text tv type',
    ClosedCaptionDisabled:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b\u0439 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u0435\u043a\u043e\u0434\u0435\u0440 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0448\u0440\u0438\u0444\u0442 \u044f\u0437\u044b\u043a \u043f\u0438\u0441\u044c\u043c\u043e \u0421\u041c\u0418 \u0444\u0438\u043b\u044c\u043c\u044b \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0443\u0431\u0442\u0438\u0442\u0440\u044b \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0442\u0438\u043f accessible alphabet character decoder enabled font language letter media movies off slash subtitles symbol text tv type',
    ClosedCaptionOff:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b\u0439 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u0435\u043a\u043e\u0434\u0435\u0440 \u0448\u0440\u0438\u0444\u0442 \u044f\u0437\u044b\u043a \u043f\u0438\u0441\u044c\u043c\u043e \u0421\u041c\u0418 \u0444\u0438\u043b\u044c\u043c\u044b \u043a\u043e\u043d\u0442\u0443\u0440 \u0441\u0443\u0431\u0442\u0438\u0442\u0440\u044b \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0442\u0438\u043f accessible alphabet character decoder font language letter media movies outline subtitles symbol text tv type',
    CloseFullscreen:
        '\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u0440\u0430\u0445 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0432\u0435\u0441\u0442\u0438 \u043a \u043c\u0438\u043d\u0438\u043c\u0443\u043c\u0443 action arrows collapse direction minimize',
    Cloud: '\u0441\u0432\u044f\u0437\u044c \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u043d\u0435\u0431\u043e \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c \u043f\u043e\u0433\u043e\u0434\u0430 connection internet network sky upload weather',
    CloudCircle:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0435\u0437\u0435\u0440\u0432\u043d\u043e\u0435 \u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b\u044b \u043f\u0430\u043f\u043a\u0438 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u043d\u0435\u0431\u043e \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c application backup connection drive files folders internet network sky storage upload',
    CloudDone:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0440\u0435\u0437\u0435\u0440\u0432\u043d\u043e\u0435 \u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u043f\u043e\u043b\u043d\u044b\u0439 \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b\u044b \u043f\u0430\u043f\u043a\u0438 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043d\u0435\u0431\u043e \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 application approve backup checkmark complete connection drive files folders internet network ok select sky storage tick upload validate verified yes',
    CloudDownload:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0440\u0435\u0437\u0435\u0440\u0432\u043d\u043e\u0435 \u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b\u044b \u043f\u0430\u043f\u043a\u0438 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u043d\u0435\u0431\u043e \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c application arrow backup connection drive files folders internet network sky storage upload',
    CloudOff:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0435\u0437\u0435\u0440\u0432\u043d\u043e\u0435 \u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0441\u0432\u044f\u0437\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0444\u0430\u0439\u043b\u044b \u043f\u0430\u043f\u043a\u0438 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043d\u0435\u0431\u043e \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c application backup connection disabled drive enabled files folders internet network offline sky slash storage upload',
    CloudQueue:
        '\u0441\u0432\u044f\u0437\u044c \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u043d\u0435\u0431\u043e \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c connection internet network sky upload',
    CloudSync:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u043a\u043e\u043b\u043e \u0440\u0435\u0437\u0435\u0440\u0432\u043d\u043e\u0435 \u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b\u044b \u043f\u0430\u043f\u043a\u0438 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u0441\u0435\u0442\u044c \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043d\u0435\u0431\u043e \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c application around backup connection drive files folders inprogress internet loading network refresh renew rotate sky storage turn upload',
    CloudUpload:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0440\u0435\u0437\u0435\u0440\u0432\u043d\u043e\u0435 \u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0441\u0432\u044f\u0437\u044c \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b\u044b \u043f\u0430\u043f\u043a\u0438 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u043d\u0435\u0431\u043e \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 application arrow backup connection download drive files folders internet network sky storage',
    Co2: '\u0443\u0433\u043b\u0435\u0440\u043e\u0434 \u0434\u0432\u0443\u043e\u043a\u0438\u0441\u044c \u0433\u0430\u0437 carbon dioxide gas',
    Code: '\u0441\u043a\u043e\u0431\u043a\u0438 css \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a \u0438\u043d\u0436\u0435\u043d\u0435\u0440\u0438\u044f HTML \u0441\u043a\u043e\u0431\u043a\u0430 \u041f\u043b\u0430\u0442\u0444\u043e\u0440\u043c\u0430 brackets css developer engineering html parenthesis platform',
    CodeOff:
        '\u0441\u043a\u043e\u0431\u043a\u0438 css \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u043d\u0436\u0435\u043d\u0435\u0440\u0438\u044f HTML \u043d\u0430 \u041f\u043b\u0430\u0442\u0444\u043e\u0440\u043c\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 brackets css developer disabled enabled engineering html on platform slash',
    Coffee: '\u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0447\u0430\u0448\u043a\u0430 \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u043a\u0440\u0443\u0436\u043a\u0430 \u043f\u043b\u0430\u0441\u0442\u0438\u043d\u0430 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u043b\u0435\u043d \u0447\u0430\u0439 beverage cup drink mug plate set tea',
    CoffeeMaker:
        '\u0422\u0435\u0445\u043d\u0438\u043a\u0430 \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0447\u0430\u0448\u043a\u0430 \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u043c\u0430\u0448\u0438\u043d\u0430 \u043a\u0440\u0443\u0436\u043a\u0430 appliances beverage cup drink machine mug',
    Collections:
        '\u0430\u043b\u044c\u0431\u043e\u043c \u0433\u0430\u043b\u0435\u0440\u0435\u044f \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0435\u0439\u0437\u0430\u0436 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043a\u0443\u0447\u0430 album gallery image landscape library mountains photography picture stack',
    CollectionsBookmark:
        '\u0430\u043b\u044c\u0431\u043e\u043c \u0430\u0440\u0445\u0438\u0432 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0433\u0430\u043b\u0435\u0440\u0435\u044f \u044d\u0442\u0438\u043a\u0435\u0442\u043a\u0430 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u0447\u0442\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u043a\u0443\u0447\u0430 \u044f\u0440\u043b\u044b\u043a album archive favorite gallery label library reading remember ribbon save stack tag',
    Colorize:
        '\u0446\u0432\u0435\u0442 \u043a\u0430\u043f\u0435\u043b\u044c\u043d\u0438\u0446\u0430 \u0438\u0437\u0432\u043b\u0435\u043a\u0430\u0442\u044c \u0433\u043b\u0430\u0437 \u0441\u0431\u043e\u0440\u0449\u0438\u043a \u043f\u0438\u043f\u0435\u0442\u043a\u0430 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 color dropper extract eye picker pipette tool',
    ColorLens:
        '\u0438\u0441\u043a\u0443\u0441\u0441\u0442\u0432\u043e \u043a\u0440\u0430\u0441\u043a\u0430 \u043f\u043e\u0434\u0434\u043e\u043d art paint pallet',
    Comment:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u043a\u043e\u043d\u0442\u0443\u0440 \u0440\u0435\u0447\u044c bubble chat communicate document feedback message note outline speech',
    CommentBank:
        '\u0430\u0440\u0445\u0438\u0432 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u044d\u0442\u0438\u043a\u0435\u0442\u043a\u0430 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u0440\u0435\u0447\u044c \u044f\u0440\u043b\u044b\u043a archive bookmark bubble cchat communicate favorite label library message remember ribbon save speech tag',
    CommentsDisabled:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0440\u0435\u0447\u044c bubble chat communicate enabled feedback message offline on slash speech',
    Commit: '\u0432\u044b\u043f\u043e\u043b\u043d\u0438\u0442\u044c \u0441\u0432\u044f\u0437\u044b\u0432\u0430\u0442\u044c \u043a\u0440\u0443\u0433 \u043f\u043e\u0441\u0432\u044f\u0449\u0430\u0442\u044c \u0432\u044b\u043f\u043e\u043b\u043d\u044f\u0442\u044c \u043b\u0438\u043d\u0438\u044f \u0432\u044b\u043f\u043e\u043b\u043d\u044f\u0442\u044c \u0437\u0430\u043b\u043e\u0433 accomplish bind circle dedicate execute line perform pledge',
    Commute:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0435\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile car direction maps public train transportation trip vehicle',
    Compare:
        '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u0443\u0435\u0442 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0438\u0441\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0444\u043e\u0442\u043e \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 adjustment editing edits enhance fix images photography photos scan settings',
    CompareArrows:
        '\u0441\u0442\u043e\u043b\u043a\u043d\u0443\u0442\u044c\u0441\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u043f\u0435\u0440\u0435\u0434 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u0443\u043a\u0430\u0437\u044b\u0432\u0430\u044f \u0434\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0442\u043e\u043b\u043a\u0430\u0442\u044c \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u0432\u043c\u0435\u0441\u0442\u0435 collide directional facing left pointing pressure push right together',
    CompassCalibration:
        '\u0441\u0432\u044f\u0437\u044c \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0441\u0435\u0442\u044c \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection internet location maps network refresh service signal wifi wireless',
    Compress:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0441\u0442\u043e\u043b\u043a\u043d\u0443\u0442\u044c\u0441\u044f \u0434\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0442\u043e\u043b\u043a\u0430\u0442\u044c \u0432\u043c\u0435\u0441\u0442\u0435 arrows collide pressure push together',
    Computer:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0445\u0440\u043e\u043c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043d\u043e\u0443\u0442\u0431\u0443\u043a \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u041f\u041a \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android chrome desktop device hardware iOS laptop mac monitor pc web window',
    ConfirmationNumber:
        '\u0434\u043e\u043f\u0443\u0441\u043a \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u043f\u0440\u043e\u0435\u0437\u0434\u043d\u043e\u0439 \u0431\u0438\u043b\u0435\u0442 admission entertainment event ticket',
    ConnectedTv:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0442\u0440\u0430\u043d\u0441\u043b\u044f\u0446\u0438\u044f \u0445\u0440\u043e\u043c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0441\u043a\u0440\u0438\u043d\u043a\u0430\u0441\u0442 \u043f\u043e\u0442\u043e\u043a\u043e\u0432\u043e\u0435 \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 Android airplay chrome desktop device display hardware iOS mac monitor screencast streaming television web window wireless',
    ConnectingAirports:
        '\u0441\u0430\u043c\u043e\u043b\u0435\u0442\u044b \u043f\u043e\u043b\u0435\u0442 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 airplanes flight transportation travel trip',
    ConnectWithoutContact:
        '\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u0441\u0442\u043e\u044f\u043d\u0438\u0435 \u043b\u044e\u0434\u0438 \u0441\u0438\u0433\u043d\u0430\u043b \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f communicating distance people signal socialize',
    Construction:
        '\u0441\u0442\u0440\u043e\u0438\u0442\u044c \u043f\u043b\u043e\u0442\u043d\u0438\u043a \u043e\u0431\u043e\u0440\u0443\u0434\u043e\u0432\u0430\u043d\u0438\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043c\u043e\u043b\u043e\u0442\u043e\u043a \u0443\u043b\u0443\u0447\u0448\u0435\u043d\u0438\u0435 \u043f\u0440\u043e\u043c\u044b\u0448\u043b\u0435\u043d\u043d\u044b\u0439 \u043f\u0440\u043e\u043c\u044b\u0448\u043b\u0435\u043d\u043d\u043e\u0441\u0442\u044c \u0440\u0435\u043c\u043e\u043d\u0442 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u044b \u0433\u0430\u0435\u0447\u043d\u044b\u0439 \u043a\u043b\u044e\u0447 build carpenter equipment fix hammer improvement industrial industry repair tools wrench',
    Contactless:
        'ApplePay Bluetooth \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u043e\u043f\u043b\u0430\u0442\u0430 \u0441\u0438\u0433\u043d\u0430\u043b \u043d\u0430\u0436\u043c\u0438\u0442\u0435 \u0441\u0434\u0435\u043b\u043a\u0430 Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 applepay bluetooth cash connection connectivity credit device finance payment signal tap transaction wifi wireless',
    ContactMail:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0434\u0440\u0435\u0441 \u0430\u0432\u0430\u0442\u0430\u0440 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account address avatar communicate email face human information message people person profile user',
    ContactPage:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u043b\u0438\u0446\u043e \u043f\u0430\u043f\u043a\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u043f\u0438\u0448\u0443 account avatar data document drive face folders human people person profile sheet slide storage user writing',
    ContactPhone:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u0432\u044b\u0437\u043e\u0432 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account avatar call communicate face human information message mobile number people person profile user',
    Contacts:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0434\u0440\u0435\u0441 \u0430\u0432\u0430\u0442\u0430\u0440 \u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account address avatar call cell face human information mobile number people person phone profile user',
    ContactSupport:
        '? \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u043f\u043e\u043c\u043e\u0449\u044c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043f\u0443\u043d\u043a\u0442\u0443\u0430\u0446\u0438\u044f \u0440\u0435\u0447\u044c \u0441\u0438\u043c\u0432\u043e\u043b v\u0432\u043e\u043f\u0440\u043e\u0441 ? alert announcement bubble chat comment communicate help information mark message punctuation speech symbol vquestion',
    ContentCopy:
        '\u0440\u0435\u0437\u0430\u0442\u044c \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0434\u0443\u0431\u043b\u0438\u043a\u0430\u0442 \u0444\u0430\u0439\u043b \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043c\u0438\u043c\u043e cut document duplicate file multiple past',
    ContentCut:
        '\u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u043c\u0438\u043c\u043e \u043d\u043e\u0436\u043d\u0438\u0446\u044b \u043f\u043e\u0434\u0440\u0435\u0437\u0430\u0442\u044c copy document file past scissors trim',
    ContentPaste:
        '\u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0440\u0435\u0437\u0430\u0442\u044c \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e clipboard copy cut document file multiple',
    ContentPasteGo:
        '\u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0444\u0430\u0439\u043b \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 clipboard disabled document enabled file slash',
    ContentPasteOff:
        '\u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0444\u0430\u0439\u043b \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 clipboard disabled document enabled file slash',
    ContentPasteSearch:
        '\u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u043d\u0430\u0439\u0442\u0438 \u0441\u043b\u0435\u0434 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u0442\u044c clipboard document file find trace track',
    Contrast:
        '\u0447\u0435\u0440\u043d\u044b\u0439 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0444\u0438\u043b\u044c\u0442\u0440 \u043e\u0442\u0442\u0435\u043d\u043a\u0438 \u0441\u0435\u0440\u043e\u0433\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0431\u0435\u043b\u044b\u0439 black editing effect filter grayscale images photography pictures settings white',
    ControlCamera:
        '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0446\u0435\u043d\u0442\u0440 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u0448\u0430\u0433 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e adjust arrows center direction left move right',
    ControlPoint:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u043a\u0440\u0443\u0433 \u043f\u043b\u044e\u0441 + add circle plus',
    ControlPointDuplicate:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u043a\u0440\u0443\u0433 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0441\u0438\u043c\u0432\u043e\u043b + add circle multiple new plus symbol',
    Cookie: '\u043f\u0435\u0447\u0435\u043d\u044c\u0435 \u043f\u0435\u0447\u0435\u043d\u044c\u0435 \u0414\u0435\u0441\u0435\u0440\u0442 \u043e\u0431\u043b\u0430\u0442\u043a\u0430 biscuit cookies dessert wafer',
    CoPresent:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0441\u043e\u043f\u0440\u0435\u0434\u0441\u0442\u0430\u0432\u0438\u0442\u0435\u043b\u044c \u043f\u0440\u0435\u0437\u0435\u043d\u0442\u0430\u0446\u0438\u044f \u044d\u043a\u0440\u0430\u043d \u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f \u0441\u043b\u0430\u0439\u0434\u044b \u0441\u043e\u0431\u0438\u0440\u0430\u0442\u044c \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 arrow co-present presentation screen share slides togather website',
    CopyAll:
        '\u0441\u043e\u0434\u0435\u0440\u0436\u0430\u043d\u0438\u0435 \u0440\u0435\u0437\u0430\u0442\u044c \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0431\u0443\u043c\u0430\u0433\u0430 \u043c\u0438\u043c\u043e content cut document file multiple page paper past',
    Copyright:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u0440\u0443\u0433 \u044d\u043c\u0431\u043b\u0435\u043c\u0430 \u0448\u0440\u0438\u0444\u0442 \u0437\u0430\u043a\u043e\u043d\u043d\u044b\u0439 \u043f\u0438\u0441\u044c\u043c\u043e \u0432\u043b\u0430\u0434\u0435\u043b\u0435\u0446 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 alphabet character circle emblem font legal letter owner symbol text',
    Coronavirus:
        '19 \u0431\u0430\u043a\u0442\u0435\u0440\u0438\u0438 COVID-19 \u0431\u043e\u043b\u0435\u0437\u043d\u044c \u043c\u0438\u043a\u0440\u043e\u0431\u044b \u0431\u043e\u043b\u0435\u0437\u043d\u044c \u0431\u043e\u043b\u044c\u043d\u043e\u0439 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 19 bacteria covid disease germs illness sick social',
    CorporateFare:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0431\u0438\u0437\u043d\u0435\u0441 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u044f \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 architecture building business estate organization place real residence residential shelter',
    Cottage:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u043f\u043b\u044f\u0436 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u0434\u043e\u043c \u043e\u0437\u0435\u0440\u043e \u043b\u043e\u0436\u0430 \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043e\u0441\u0442\u0430\u0442\u044c\u0441\u044f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 architecture beach estate home house lake lodge maps place real residence residential stay traveling',
    Countertops:
        '\u0434\u043e\u043c \u0434\u043e\u043c \u043a\u0443\u0445\u043d\u044f \u0440\u0430\u043a\u043e\u0432\u0438\u043d\u0430 \u0441\u0442\u043e\u043b home house kitchen sink table',
    Create: '\u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0432\u0445\u043e\u0434 \u0432\u0435\u0449\u044c \u043d\u043e\u0432\u044b\u0439 \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0448\u0443 compose editing input item new pencil write writing',
    CreateNewFolder:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u043a\u0430\u0442\u0430\u043b\u043e\u0433 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u043b\u044e\u0441 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u0441\u0438\u043c\u0432\u043e\u043b + add data directory document drive file plus sheet slide storage symbol',
    CreditCard:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442\u043d\u0430\u044f \u043a\u0430\u0440\u0442\u0430 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill cash charge coin commerce cost creditcard currency dollars finance information money online payment price shopping symbol',
    CreditCardOff:
        '\u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 charge commerce cost disabled enabled finance money online payment slash',
    CreditScore:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043f\u043e\u043b\u043d\u044b\u0439 \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0437\u0430\u0435\u043c \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0414\u0435\u043d\u044c\u0433\u0438 Ok \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve bill card cash check coin commerce complete cost currency dollars done finance loan mark money ok online payment select symbol tick validate verified yes',
    Crib: '\u0434\u0435\u0442\u0438 \u043c\u0430\u043b\u044b\u0448 \u043b\u044e\u043b\u044c\u043a\u0430 \u043a\u0440\u043e\u0432\u0430\u0442\u044c \u0434\u0435\u0442\u0438 \u043a\u043e\u043b\u044b\u0431\u0435\u043b\u044c \u043c\u043b\u0430\u0434\u0435\u043d\u0435\u0446 \u0440\u0435\u0431\u0435\u043d\u043e\u043a \u043d\u043e\u0432\u043e\u0440\u043e\u0436\u0434\u0435\u043d\u043d\u044b\u0439 \u0441\u043f\u0430\u0442\u044c \u0440\u0435\u0431\u0435\u043d\u043e\u043a, \u043d\u0430\u0447\u0438\u043d\u0430\u044e\u0449\u0438\u0439 \u0445\u043e\u0434\u0438\u0442\u044c babies baby bassinet bed children cradle infant kid newborn sleeping toddler',
    CrisisAlert:
        '! \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043f\u0440\u044f\u043c\u043e \u0432 \u0442\u043e\u0447\u043a\u0443 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0446\u0435\u043b\u044c \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! attention bullseye caution danger error exclamation important mark notification symbol target warning',
    Crop: '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u043a\u0438 \u043e\u0431\u043b\u0430\u0441\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0420\u0430\u043c\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e \u043f\u0440\u044f\u043c\u043e\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c adjustments area editing frame images photos rectangle settings size square',
    Crop169:
        '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u043a\u0438 \u043e\u0431\u043b\u0430\u0441\u0442\u044c \u043f\u043e \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0420\u0430\u043c\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u0440\u044f\u043c\u043e\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c adjustments area by editing frame images photos picture rectangle settings size square',
    Crop32: '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u043a\u0438 \u043e\u0431\u043b\u0430\u0441\u0442\u044c \u043f\u043e \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0420\u0430\u043c\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u0440\u044f\u043c\u043e\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c adjustments area by editing frame images photos picture rectangle settings size square',
    Crop54: '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u043a\u0438 \u043e\u0431\u043b\u0430\u0441\u0442\u044c \u043f\u043e \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0420\u0430\u043c\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u0440\u044f\u043c\u043e\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c adjustments area by editing frame images photos picture rectangle settings size square',
    Crop75: '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u043a\u0438 \u043e\u0431\u043b\u0430\u0441\u0442\u044c \u043f\u043e \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0420\u0430\u043c\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u0440\u044f\u043c\u043e\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c adjustments area by editing frame images photos picture rectangle settings size square',
    CropDin:
        '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u043a\u0438 \u043e\u0431\u043b\u0430\u0441\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0420\u0430\u043c\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u0440\u044f\u043c\u043e\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c adjustments area editing frame images photos picture rectangle settings size square',
    CropFree:
        '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u043a\u0438 \u0448\u0442\u0440\u0438\u0445-\u043a\u043e\u0434 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0444\u043e\u043a\u0443\u0441 \u0420\u0430\u043c\u043a\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e QR \u043a\u043e\u0434 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0437\u0443\u043c adjustments barcode editing focus frame image photos qrcode settings size square zoom',
    CropLandscape:
        '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u043a\u0438 \u043e\u0431\u043b\u0430\u0441\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0420\u0430\u043c\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c adjustments area editing frame images photos picture settings size square',
    CropOriginal:
        '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u043a\u0438 \u043e\u0431\u043b\u0430\u0441\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0420\u0430\u043c\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c adjustments area editing frame images photos picture settings size square',
    CropPortrait:
        '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u043a\u0438 \u043e\u0431\u043b\u0430\u0441\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0420\u0430\u043c\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u0440\u044f\u043c\u043e\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c adjustments area editing frame images photos picture rectangle settings size square',
    CropRotate:
        '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u043a\u0438 \u043e\u0431\u043b\u0430\u0441\u0442\u044c \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0420\u0430\u043c\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c adjustments area arrows editing frame images photos settings size turn',
    CropSquare:
        '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u043a\u0438 \u043e\u0431\u043b\u0430\u0441\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0420\u0430\u043c\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e \u043f\u0440\u044f\u043c\u043e\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0440\u0430\u0437\u043c\u0435\u0440 adjustments area editing frame images photos rectangle settings size',
    Css: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u043a\u043e\u0431\u043a\u0438 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u043e\u0434 \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a \u0438\u043d\u0436\u0435\u043d\u0435\u0440\u0438\u044f \u0448\u0440\u0438\u0444\u0442 HTML \u043f\u0438\u0441\u044c\u043c\u043e \u041f\u043b\u0430\u0442\u0444\u043e\u0440\u043c\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet brackets character code developer engineering font html letter platform symbol text type',
    CurrencyBitcoin:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0431\u043b\u043e\u043a\u0447\u0435\u0439\u043d \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0446\u0438\u0444\u0440\u043e\u0432\u043e\u0439 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0444\u0440\u0430\u043d\u043a \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill blockchain card cash commerce cost credit digital dollars finance franc money online payment price shopping symbol',
    CurrencyExchange:
        '360 \u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0414\u0435\u043d\u044c\u0433\u0438 \u043f\u043b\u0430\u0442\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0441\u0438\u043d\u0445\u0440\u043e\u043d\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c \u0443\u043d\u0438\u0432\u0435\u0440\u0441\u0430\u043b\u044c\u043d\u044b\u0439 360 around arrows cash coin commerce direction dollars inprogress money pay renew rotate sync turn universal',
    CurrencyFranc:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    CurrencyLira:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    CurrencyPound:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    CurrencyRuble:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    CurrencyRupee:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    CurrencyYen:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    CurrencyYuan:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill card cash coin commerce cost credit dollars finance money online payment price shopping symbol',
    Curtains:
        '\u0436\u0430\u043b\u044e\u0437\u0438 \u043f\u043e\u043a\u0440\u044b\u0442\u0438\u0435 \u0433\u043d\u0435\u0437\u0434\u043e \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u043c \u0437\u0430\u0442\u0432\u043e\u0440 \u0437\u043e\u043d\u0442\u0438\u043a blinds cover nest open shutter sunshade',
    CurtainsClosed:
        '\u0436\u0430\u043b\u044e\u0437\u0438 \u043f\u043e\u043a\u0440\u044b\u0442\u0438\u0435 \u0433\u043d\u0435\u0437\u0434\u043e \u0437\u0430\u0442\u0432\u043e\u0440 \u0437\u043e\u043d\u0442\u0438\u043a blinds cover nest shutter sunshade',
    Cyclone:
        '\u043a\u0440\u0438\u0437\u0438\u0441 \u0441\u0442\u0438\u0445\u0438\u0439\u043d\u043e\u0435 \u0431\u0435\u0434\u0441\u0442\u0432\u0438\u0435 \u0435\u0441\u0442\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0434\u043e\u0436\u0434\u044c \u0431\u0443\u0440\u044f \u043f\u043e\u0433\u043e\u0434\u0430 \u0432\u0435\u0442\u0440\u044b crisis disaster natural rain storm weather winds',
    Dangerous:
        '\u0441\u043b\u043e\u043c\u0430\u043d\u043d\u044b\u0439 \u0438\u0441\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043d\u0435\u0442 \u0437\u043d\u0430\u043a \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u041e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 \u043d\u0435\u043f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u044b\u0439 broken fix no sign stop update warning wrong',
    DarkMode:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043b\u0443\u043d\u0430 \u043d\u043e\u0447\u044c \u0442\u0438\u0445\u0438\u0439 \u0442\u0435\u043c\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application device interface moon night silent theme ui ux website',
    Dashboard:
        '\u043a\u0430\u0440\u0442\u044b \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u043f\u0440\u044f\u043c\u043e\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u0444\u043e\u0440\u043c\u044b \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 cards format layout rectangle shapes square website',
    DashboardCustomize:
        '\u043a\u0430\u0440\u0442\u044b \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u043f\u0440\u044f\u043c\u043e\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u0444\u043e\u0440\u043c\u044b \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 cards format layout rectangle shapes square website',
    DataArray:
        '\u0441\u043a\u043e\u0431\u043a\u0438 \u043a\u043e\u0434\u0435\u0440 \u0441\u043a\u043e\u0431\u043a\u0438 brackets coder parentheses',
    DataObject:
        '\u0441\u043a\u043e\u0431\u043a\u0438 \u043a\u043e\u0434\u0435\u0440 \u0441\u043a\u043e\u0431\u043a\u0438 brackets coder parentheses',
    DataSaverOff:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u043f\u043e\u043d\u0447\u0438\u043a \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0437\u0432\u0435\u043d\u0435\u0442\u044c \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bars chart diagram donut infographic measure metrics ring statistics tracking',
    DataSaverOn:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0437\u0432\u0435\u043d\u0435\u0442\u044c \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 + add analytics chart diagram infographic measure metrics new plus ring statistics symbol tracking',
    DataThresholding:
        '\u0441\u043a\u0440\u044b\u0442\u044b\u0439 \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0440\u043e\u0433 hidden privacy thresold',
    DataUsage:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u043a\u0440\u0443\u0433 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics chart circle diagram infographic measure metrics statistics tracking',
    DateRange:
        '\u043f\u043e\u0432\u0435\u0441\u0442\u043a\u0430 \u0434\u043d\u044f \u043a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u043c\u0435\u0441\u044f\u0446 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f C\u0435\u0433\u043e\u0434\u043d\u044f \u043d\u0435\u0434\u0435\u043b\u044f agenda calendar event month remember reminder schedule time today week',
    Deblur: '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u043b\u0438\u0446\u043e \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043b\u0438\u043d\u0438\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0442\u043e\u0447\u0438\u0442\u044c adjust editing enhance face image lines photography sharpen',
    Deck: '\u0441\u0442\u0443\u043b\u044c\u044f \u043c\u0435\u0431\u0435\u043b\u044c \u0441\u0430\u0434 \u0434\u043e\u043c \u0434\u043e\u043c \u043d\u0430 \u0443\u043b\u0438\u0446\u0435 \u0432\u043d\u0435 \u043f\u0430\u0442\u0438\u043e \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0442\u0435\u0440\u0440\u0430\u0441\u0430 \u0437\u043e\u043d\u0442\u0438\u043a \u043f\u043b\u043e\u0449\u0430\u0434\u043a\u0430 chairs furniture garden home house outdoors outside patio social terrace umbrella yard',
    Dehaze: '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043b\u0438\u043d\u0438\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0443\u0434\u0430\u043b\u044f\u0442\u044c adjust editing enhance image lines photography remove',
    Delete: '\u043c\u0443\u0441\u043e\u0440\u043d\u043e\u0435 \u0432\u0435\u0434\u0440\u043e \u043c\u0443\u0441\u043e\u0440 \u0445\u043b\u0430\u043c \u043f\u0435\u0440\u0435\u0440\u0430\u0431\u0430\u0442\u044b\u0432\u0430\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0423\u0440\u043d\u0430 \u0434\u043b\u044f \u043c\u0443\u0441\u043e\u0440\u0430 bin garbage junk recycle remove trashcan',
    DeleteForever:
        '\u043c\u0443\u0441\u043e\u0440\u043d\u043e\u0435 \u0432\u0435\u0434\u0440\u043e \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0432\u044b\u0445\u043e\u0434 \u043c\u0443\u0441\u043e\u0440 \u0445\u043b\u0430\u043c \u043f\u0435\u0440\u0435\u0440\u0430\u0431\u0430\u0442\u044b\u0432\u0430\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0423\u0440\u043d\u0430 \u0434\u043b\u044f \u043c\u0443\u0441\u043e\u0440\u0430 bin cancel exit garbage junk recycle remove trashcan',
    DeleteOutline:
        '\u043c\u0443\u0441\u043e\u0440\u043d\u043e\u0435 \u0432\u0435\u0434\u0440\u043e \u041c\u043e\u0436\u043d\u043e \u043c\u0443\u0441\u043e\u0440 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u043c\u0443\u0441\u043e\u0440 bin can garbage remove trash',
    DeleteSweep:
        '\u043c\u0443\u0441\u043e\u0440\u043d\u043e\u0435 \u0432\u0435\u0434\u0440\u043e \u043c\u0443\u0441\u043e\u0440 \u0445\u043b\u0430\u043c \u043f\u0435\u0440\u0435\u0440\u0430\u0431\u0430\u0442\u044b\u0432\u0430\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0423\u0440\u043d\u0430 \u0434\u043b\u044f \u043c\u0443\u0441\u043e\u0440\u0430 bin garbage junk recycle remove trashcan',
    DeliveryDining:
        '\u0435\u0434\u0430 \u0435\u0434\u0430 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d \u0441\u0430\u043c\u043e\u043a\u0430\u0442 \u0432\u044b\u0438\u0433\u0440\u0430\u0442\u044c \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0432\u0435\u0441\u043f\u0430 food meal restaurant scooter takeout transportation vehicle vespa',
    DensityLarge:
        '\u0433\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u044b\u0439 \u043b\u0438\u043d\u0438\u0438 \u043f\u0440\u0430\u0432\u0438\u043b\u0430 horizontal lines rules',
    DensityMedium:
        '\u0433\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u044b\u0439 \u043b\u0438\u043d\u0438\u0438 \u043f\u0440\u0430\u0432\u0438\u043b\u0430 horizontal lines rules',
    DensitySmall:
        '\u0433\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u044b\u0439 \u043b\u0438\u043d\u0438\u0438 \u043f\u0440\u0430\u0432\u0438\u043b\u0430 horizontal lines rules',
    DepartureBoard:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0430\u0432\u0442\u043e\u0431\u0443\u0441 \u043c\u0430\u0448\u0438\u043d\u044b \u0427\u0430\u0441\u044b \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile bus cars clock maps public schedule time transportation travel vehicle',
    Description:
        '\u0441\u0442\u0430\u0442\u044c\u044f \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u0441\u0447\u0435\u0442 \u0432\u0435\u0449\u044c \u0437\u0430\u043c\u0435\u0442\u043a\u0438 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0431\u0443\u043c\u0430\u0433\u0430 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0442\u0435\u043a\u0441\u0442 \u043f\u0438\u0448\u0443 article bill data document drive file folders invoice item notes page paper sheet slide text writing',
    Deselect:
        '\u0432\u0441\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0432\u044b\u0431\u043e\u0440 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 all disabled enabled off selection slash square tool',
    DesignServices:
        '\u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0447\u0435\u0440\u043d\u043e\u0432\u0438\u043a \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0432\u0445\u043e\u0434 \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u043f\u0440\u0430\u0432\u0438\u0442\u0435\u043b\u044c \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0448\u0443 compose create draft editing input pencil ruler write writing',
    DesktopAccessDisabled:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u044f\u0431\u043b\u043e\u043a\u043e \u0445\u0440\u043e\u043c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u041f\u041a \u044d\u043a\u0440\u0430\u043d \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android apple chrome device display enabled hardware iOS mac monitor offline pc screen slash web window',
    DesktopMac:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u044f\u0431\u043b\u043e\u043a\u043e \u0445\u0440\u043e\u043c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u041f\u041a \u044d\u043a\u0440\u0430\u043d \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android apple chrome device display hardware iOS monitor pc screen web window',
    DesktopWindows:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0445\u0440\u043e\u043c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u041f\u041a \u044d\u043a\u0440\u0430\u043d \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0441\u0435\u0442\u044c Android chrome device display hardware iOS mac monitor pc screen television tv web',
    Details:
        '\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0442\u043e\u0447\u0438\u0442\u044c \u0442\u0440\u0435\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a editing enhance image photography sharpen triangle',
    DeveloperBoard:
        '\u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u043a\u0430 \u0434\u0435\u0432\u043a\u0438\u0442 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u0438\u043a\u0440\u043e\u0447\u0438\u043f \u043f\u0440\u043e\u0446\u0435\u0441\u0441\u043e\u0440 computer development devkit hardware microchip processor',
    DeveloperBoardOff:
        '\u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u043a\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u0438\u043a\u0440\u043e\u0447\u0438\u043f \u043d\u0430 \u043f\u0440\u043e\u0446\u0435\u0441\u0441\u043e\u0440 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 computer development disabled enabled hardware microchip on processor slash',
    DeveloperMode:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u043a\u043e\u0431\u043a\u0430 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u0434 \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android bracket cell code development device engineer hardware iOS mobile phone tablet',
    DeviceHub:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u0440\u0443\u0433 \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043d\u043e\u0443\u0442\u0431\u0443\u043a \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0442\u0440\u0435\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u043f\u0440\u0438\u0433\u043e\u0434\u043d\u044b\u0439 \u0434\u043b\u044f \u043d\u043e\u0441\u043a\u0438 \u0441\u0435\u0442\u044c Android circle computer desktop hardware iOS laptop mobile monitor phone square tablet triangle watch wearable web',
    Devices:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043d\u043e\u0443\u0442\u0431\u0443\u043a \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u043f\u0440\u0438\u0433\u043e\u0434\u043d\u044b\u0439 \u0434\u043b\u044f \u043d\u043e\u0441\u043a\u0438 \u0441\u0435\u0442\u044c Android computer desktop hardware iOS laptop mobile monitor phone tablet watch wearable web',
    DevicesFold:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u0441\u043a\u043b\u0430\u0434\u043d\u043e\u0439 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android cell foldable hardware iOS mobile phone tablet',
    DevicesOther:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u0445\u0440\u043e\u043c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0433\u0430\u0434\u0436\u0435\u0442 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0430\u0439\u043f\u0430\u0434 \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0443\u043c\u043d\u044b\u0435 \u0447\u0430\u0441\u044b \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0432\u0440 \u043d\u043e\u0441\u0438\u043c\u044b\u0435 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u0430 \u043e\u043a\u043d\u043e Android cell chrome desktop gadget hardware iOS ipad mac mobile monitor phone smartwatch tablet vr wearables window',
    DeviceThermostat:
        '\u0426\u0435\u043b\u044c\u0441\u0438\u044f \u0424\u0430\u0440\u0435\u043d\u0433\u0435\u0439\u0442 \u0442\u0435\u043c\u043f\u0435\u0440\u0430\u0442\u0443\u0440\u0430 \u0442\u0435\u0440\u043c\u043e\u043c\u0435\u0442\u0440 celsius fahrenheit temperature thermometer',
    DeviceUnknown:
        '? \u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043f\u043e\u043c\u043e\u0449\u044c \u043a\u043b\u0435\u0442\u043a\u0430 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043f\u043e\u043c\u043e\u0449\u044c iOS \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u0443\u043d\u043a\u0442\u0443\u0430\u0446\u0438\u044f \u0432\u043e\u043f\u0440\u043e\u0441 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u043b\u0430\u043d\u0448\u0435\u0442 ? Android assistance cell hardware help iOS information mark mobile phone punctuation question support symbol tablet',
    DialerSip:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0448\u0440\u0438\u0444\u0442 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043f\u043e\u0441\u0432\u044f\u0449\u0435\u043d\u0438\u0435 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043d\u0430\u0434 \u043f\u0440\u043e\u0442\u043e\u043a\u043e\u043b \u043c\u0430\u0440\u0448\u0440\u0443\u0442\u0438\u0437\u0430\u0446\u0438\u044f \u0441\u0435\u0430\u043d\u0441 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043b\u0435\u0444\u043e\u043d \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0433\u043e\u043b\u043e\u0441 alphabet call cell character contact device font hardware initiation internet letter mobile over protocol routing session symbol telephone text type voice',
    Dialpad:
        '\u043a\u043d\u043e\u043f\u043a\u0438 \u0432\u044b\u0437\u043e\u0432 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0442\u043e\u0447\u043a\u0438 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0447\u0438\u0441\u043b\u0430 \u0422\u0435\u043b\u0435\u0444\u043e\u043d buttons call contact device dots mobile numbers phone',
    Diamond:
        '\u043c\u043e\u0434\u0430 \u0434\u0440\u0430\u0433\u043e\u0446\u0435\u043d\u043d\u044b\u0435 \u043a\u0430\u043c\u043d\u0438 \u042e\u0432\u0435\u043b\u0438\u0440\u043d\u044b\u0435 \u0438\u0437\u0434\u0435\u043b\u0438\u044f \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u0446\u0435\u043d\u043d\u043e\u0441\u0442\u0438 fashion gems jewelry logo retail valuables',
    Difference:
        '\u0441\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u0442\u044c \u0441\u043e\u0434\u0435\u0440\u0436\u0430\u043d\u0438\u0435 \u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0440\u0435\u0437\u0430\u0442\u044c \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0434\u0443\u0431\u043b\u0438\u043a\u0430\u0442 \u0444\u0430\u0439\u043b \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043c\u0438\u043c\u043e compare content copy cut document duplicate file multiple past',
    Dining: '\u0441\u0442\u043e\u043b\u043e\u0432\u0430\u044f \u0441\u0442\u043e\u043b\u043e\u0432\u044b\u0435 \u043f\u0440\u0438\u0431\u043e\u0440\u044b \u0437\u0430\u043a\u0443\u0441\u043e\u0447\u043d\u0430\u044f \u043f\u0440\u0438\u043d\u0438\u043c\u0430\u0442\u044c \u043f\u0438\u0449\u0443 \u0432\u0438\u043b\u043a\u0430 \u043d\u043e\u043c\u0435\u0440 \u043b\u043e\u0436\u043a\u0430 cafeteria cutlery diner eating fork room spoon',
    DinnerDining:
        '\u0437\u0430\u0432\u0442\u0440\u0430\u043a \u0435\u0434\u0430 \u0432\u0438\u043b\u043a\u0430 \u043e\u0431\u0435\u0434 \u0435\u0434\u0430 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d \u0441\u043f\u0430\u0433\u0435\u0442\u0442\u0438 \u043f\u043e\u0441\u0443\u0434\u0430 breakfast food fork lunch meal restaurant spaghetti utensils',
    Directions:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0430\u0440\u0442\u044b \u0437\u0430\u0432\u043e\u0434\u0438\u0442\u044c\u0441\u044f \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrow maps naviate right route sign traffic',
    DirectionsBike:
        '\u0432\u0435\u043b\u043e\u0441\u0438\u043f\u0435\u0434 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043a\u0430\u0440\u0442\u044b \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 bicycle human maps person public route transportation',
    DirectionsBoat:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043f\u0435\u0440\u0435\u0432\u043e\u0437\u0438\u0442\u044c \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars ferry maps public transportation vehicle',
    DirectionsBoatFilled:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043f\u0435\u0440\u0435\u0432\u043e\u0437\u0438\u0442\u044c \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars ferry maps public transportation vehicle',
    DirectionsBus:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars maps public transportation vehicle',
    DirectionsBusFilled:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars maps public transportation vehicle',
    DirectionsCar:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars maps public transportation vehicle',
    DirectionsCarFilled:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars maps public transportation vehicle',
    DirectionsOff:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043a\u0430\u0440\u0442\u044b \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrow disabled enabled maps right route sign slash traffic',
    DirectionsRailway:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0435\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars maps public train transportation vehicle',
    DirectionsRailwayFilled:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0435\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars maps public train transportation vehicle',
    DirectionsRun:
        '\u0442\u0435\u043b\u043e \u0437\u0434\u043e\u0440\u043e\u0432\u044c\u0435 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0431\u0435\u0433 \u0442\u0440\u0443\u0441\u0446\u043e\u0439 \u043a\u0430\u0440\u0442\u044b \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0411\u0435\u0433 \u0445\u043e\u0434\u0438\u0442\u044c body health human jogging maps people person route running walk',
    DirectionsSubway:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0440\u0435\u043b\u044c\u0441 \u0442\u0440\u0435\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars maps public rail train transportation vehicle',
    DirectionsSubwayFilled:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0440\u0435\u043b\u044c\u0441 \u0442\u0440\u0435\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars maps public rail train transportation vehicle',
    DirectionsTransit:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0442\u0440\u043e \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0440\u0435\u043b\u044c\u0441 \u043c\u0435\u0442\u0440\u043e \u0442\u0440\u0435\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars maps metro public rail subway train transportation vehicle',
    DirectionsTransitFilled:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0440\u0435\u043b\u044c\u0441 \u043c\u0435\u0442\u0440\u043e \u0442\u0440\u0435\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars maps public rail subway train transportation vehicle',
    DirectionsWalk:
        '\u0442\u0435\u043b\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0431\u0435\u0433 \u0442\u0440\u0443\u0441\u0446\u043e\u0439 \u043a\u0430\u0440\u0442\u044b \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0431\u0435\u0436\u0430\u0442\u044c body human jogging maps people person route run',
    DirtyLens:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0448\u043b\u0435\u043f\u043e\u043a camera photography picture splat',
    DisabledByDefault:
        '\u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u0432\u044b\u0445\u043e\u0434 \u043d\u0435\u0442 \u043f\u043e\u043a\u0438\u0434\u0430\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 box cancel close exit no quit remove square stop',
    DiscFull:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c CD \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u041c\u0443\u0437\u044b\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0438\u043d\u0438\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert attention caution cd danger error exclamation important mark music notification storage symbol vinyl warning',
    DisplaySettings:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0434\u0430\u0447\u0430 \u0445\u0440\u043e\u043c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0414\u0435\u0442\u0430\u043b\u0438 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043c\u0435\u0445\u0430\u043d\u0438\u0437\u043c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u043e\u043f\u0446\u0438\u0438 \u043b\u0438\u0447\u043d\u044b\u0439 \u044d\u043a\u0440\u0430\u043d \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android application change chrome desktop details device gear hardware iOS information mac monitor options personal screen service web window',
    Dns: '\u0430\u0434\u0440\u0435\u0441 \u0431\u0430\u0440\u044b \u0434\u043e\u043c\u0435\u043d \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f IP \u0441\u043f\u0438\u0441\u043e\u043a \u0438\u0441\u043a\u0430\u0442\u044c \u0438\u043c\u044f \u0441\u0435\u0442\u044c \u0441\u0435\u0440\u0432\u0435\u0440 \u0441\u0438\u0441\u0442\u0435\u043c\u0430 address bars domain information ip list lookup name network server system',
    Dock: '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u0437\u0430\u0440\u044f\u0434\u043d\u043e\u0435 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0437\u0430\u0440\u044f\u0434\u043a\u0430 \u0440\u0430\u0437\u044a\u0435\u043c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u0438\u043b\u0430 \u0441\u0442\u0430\u043d\u0446\u0438\u044f \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android cell charger charging connector device hardware iOS mobile phone power station tablet',
    DocumentScanner:
        '\u0441\u0442\u0430\u0442\u044c\u044f \u0434\u0430\u043d\u043d\u044b\u0435 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u0437\u0430\u043c\u0435\u0442\u043a\u0438 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0431\u0443\u043c\u0430\u0433\u0430 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0442\u0435\u043a\u0441\u0442 \u043f\u0438\u0448\u0443 article data drive file folders notes page paper sheet slide text writing',
    DoDisturb:
        '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043e\u0442\u043a\u043b\u043e\u043d\u0435\u043d \u043e\u0442\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c\u0441\u044f \u043e\u0442 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0442\u0438\u0448\u0438\u043d\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 cancel close denied deny remove silence stop',
    DoDisturbAlt:
        '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043e\u0442\u043a\u043b\u043e\u043d\u0435\u043d \u043e\u0442\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c\u0441\u044f \u043e\u0442 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0442\u0438\u0448\u0438\u043d\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 cancel close denied deny remove silence stop',
    DoDisturbOff:
        '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043e\u0442\u043a\u043b\u043e\u043d\u0435\u043d \u043e\u0442\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c\u0441\u044f \u043e\u0442 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0430 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0442\u0438\u0448\u0438\u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 cancel close denied deny disabled enabled on remove silence slash stop',
    DoDisturbOn:
        '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043e\u0442\u043a\u043b\u043e\u043d\u0435\u043d \u043e\u0442\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c\u0441\u044f \u043e\u0442 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0442\u0438\u0448\u0438\u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 cancel close denied deny disabled enabled off remove silence slash stop',
    Domain: '\u043a\u0432\u0430\u0440\u0442\u0438\u0440\u0430 \u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0431\u0438\u0437\u043d\u0435\u0441 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 \u0441\u0435\u0442\u044c www apartment architecture building business estate home place real residence residential shelter web www',
    DomainAdd:
        '+ \u043a\u0432\u0430\u0440\u0442\u0438\u0440\u0430 \u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0431\u0438\u0437\u043d\u0435\u0441 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u043d\u043e\u0432\u044b\u0439 \u043c\u0435\u0441\u0442\u043e \u043f\u043b\u044e\u0441 \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 \u0441\u0438\u043c\u0432\u043e\u043b \u0441\u0435\u0442\u044c www + apartment architecture building business estate home new place plus real residence residential shelter symbol web www',
    DomainDisabled:
        '\u043a\u0432\u0430\u0440\u0442\u0438\u0440\u0430 \u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0431\u0438\u0437\u043d\u0435\u0441 \u041a\u043e\u043c\u043f\u0430\u043d\u0438\u044f \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043a\u0430\u0440\u0442\u044b \u043e\u0444\u0438\u0441 \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043d\u0430 \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 apartment architecture building business company enabled estate home internet maps office offline on place real residence residential slash website',
    DomainVerification:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0434\u0438\u0437\u0430\u0439\u043d \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u0430\u043a\u0435\u0442 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u044d\u043a\u0440\u0430\u043d \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e www \u0434\u0430 application approve check complete design desktop done interface internet layout mark ok screen select tick ui ux validate verified website window www yes',
    Done: '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u043f\u043e\u043b\u043d\u044b\u0439 \u0437\u0430\u043a\u043e\u043d\u0447\u0435\u043d\u043d\u044b\u0439 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0443\u0441\u043f\u0435\u0445 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve checkmark complete finished ok select success tick validate verified yes',
    DoneAll:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u043f\u043e\u043b\u043d\u044b\u0439 \u0437\u0430\u043a\u043e\u043d\u0447\u0435\u043d\u043d\u044b\u0439 \u0441\u043b\u043e\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043a\u0443\u0447\u0430 \u0443\u0441\u043f\u0435\u0445 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve checkmark complete finished layers multiple ok select stack success tick validate verified yes',
    DoneOutline:
        '\u0432\u0441\u0435 \u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u043f\u043e\u043b\u043d\u044b\u0439 \u0437\u0430\u043a\u043e\u043d\u0447\u0435\u043d\u043d\u044b\u0439 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0443\u0441\u043f\u0435\u0445 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 all approve checkmark complete finished ok select success tick validate verified yes',
    DoNotDisturb:
        '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043e\u0442\u043a\u043b\u043e\u043d\u0435\u043d \u043e\u0442\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c\u0441\u044f \u043e\u0442 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0442\u0438\u0448\u0438\u043d\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 cancel close denied deny remove silence stop',
    DoNotDisturbAlt:
        '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043e\u0442\u043a\u043b\u043e\u043d\u0435\u043d \u043e\u0442\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c\u0441\u044f \u043e\u0442 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0442\u0438\u0448\u0438\u043d\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 cancel close denied deny remove silence stop',
    DoNotDisturbOff:
        '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043e\u0442\u043a\u043b\u043e\u043d\u0435\u043d \u043e\u0442\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c\u0441\u044f \u043e\u0442 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0430 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0442\u0438\u0448\u0438\u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 cancel close denied deny disabled enabled on remove silence slash stop',
    DoNotDisturbOn:
        '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043e\u0442\u043a\u043b\u043e\u043d\u0435\u043d \u043e\u0442\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c\u0441\u044f \u043e\u0442 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0442\u0438\u0448\u0438\u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 cancel close denied deny disabled enabled off remove silence slash stop',
    DoNotDisturbOnTotalSilence:
        '\u0437\u0430\u043d\u044f\u0442\u044b\u0439 \u043d\u0435\u043c\u043e\u0439 \u043d\u0430 \u0442\u0438\u0445\u043e \u043e\u0431\u0449\u0438\u0439 busy mute on quiet total',
    DoNotStep:
        '\u0431\u043e\u0442\u0438\u043d\u043e\u043a \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u043e\u0433\u0438 \u0441\u0442\u0443\u043f\u043d\u044f \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u043e\u0431\u0443\u0432\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043a\u0440\u043e\u0441\u0441\u043e\u0432\u043a\u0438 boot disabled enabled feet foot off on shoe slash sneaker',
    DoNotTouch:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0440\u0443\u043a\u0430 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled enabled fingers gesture hand off on slash',
    DonutLarge:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u043a\u0440\u0443\u0433 \u043f\u043e\u043b\u043d\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f, \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0432 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics chart circle complete data diagram infographic inprogress, measure metrics pie statistics tracking',
    DonutSmall:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u043a\u0440\u0443\u0433 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0432 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics chart circle data diagram infographic inprogress measure metrics pie statistics tracking',
    DoorBack:
        '\u0437\u0430\u043a\u0440\u044b\u0442\u043e \u0434\u0432\u0435\u0440\u043d\u043e\u0439 \u043f\u0440\u043e\u0435\u043c \u0432\u0445\u043e\u0434 \u0432\u044b\u0445\u043e\u0434 \u0434\u043e\u043c \u0434\u043e\u043c closed doorway entrance exit home house',
    Doorbell:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0434\u043e\u043c \u0434\u043e\u043c \u0437\u0432\u043e\u043d alarm home house ringing',
    DoorFront:
        '\u0437\u0430\u043a\u0440\u044b\u0442\u043e \u0434\u0432\u0435\u0440\u043d\u043e\u0439 \u043f\u0440\u043e\u0435\u043c \u0432\u0445\u043e\u0434 \u0432\u044b\u0445\u043e\u0434 \u0434\u043e\u043c \u0434\u043e\u043c closed doorway entrance exit home house',
    DoorSliding:
        '\u0430\u0432\u0442\u043e\u043c\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0434\u0432\u0435\u0440\u043d\u043e\u0439 \u043f\u0440\u043e\u0435\u043c \u0434\u0432\u043e\u0439\u043d\u043e\u0439 \u0432\u0445\u043e\u0434 \u0432\u044b\u0445\u043e\u0434 \u0441\u0442\u0430\u043a\u0430\u043d \u0434\u043e\u043c \u0434\u043e\u043c \u0434\u0432\u0430 automatic doorway double entrance exit glass home house two',
    DoubleArrow:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0448\u0435\u0432\u0440\u043e\u043d \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e arrows chevron direction multiple navigation right',
    DownhillSkiing:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0442\u0435\u043b\u043e \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044b\u0436\u0438 \u0441\u043d\u0435\u0433 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0437\u0438\u043c\u0430 athlete athletic body entertainment exercise hobby human people person ski snow social sports travel winter',
    Download:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0438 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c arrow downloads drive install upload',
    DownloadDone:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0438 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u043b\u0435\u043d\u044b Ok \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c arrows check downloads drive installed ok tick upload',
    DownloadForOffline:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0440\u0443\u0433 \u0437\u0430 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u0438\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c arrow circle for install offline upload',
    Downloading:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0440\u0443\u0433 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0438 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u0438\u0442\u044c \u0432 \u043e\u0436\u0438\u0434\u0430\u043d\u0438\u0438 \u043f\u0440\u043e\u0433\u0440\u0435\u0441\u0441 \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c arrow circle downloads install pending progress upload',
    Drafts: '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043a\u043e\u043d\u0432\u0435\u0440\u0442 \u0444\u0430\u0439\u043b \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0447\u0438\u0442\u0430\u0442\u044c document email envelope file letter message read',
    DragHandle:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u043c\u0435\u043d\u044e \u0448\u0430\u0433 \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e application components design interface layout lines menu move screen ui ux website window',
    DragIndicator:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0440\u0443\u0433\u0438 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0442\u043e\u0447\u043a\u0438 \u0443\u0440\u043e\u043d\u0438\u0442\u044c \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043c\u0430\u043a\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0448\u0430\u0433 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u044d\u043a\u0440\u0430\u043d \u0444\u043e\u0440\u043c\u0430 \u0441\u0434\u0432\u0438\u0433 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e application circles components design dots drop interface layout mobile monitor move phone screen shape shift tablet ui ux website window',
    DriveEta:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars destination direction estimate maps public transportation travel trip vehicle',
    DriveFileMove:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0434\u0430\u043d\u043d\u044b\u0435 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u043f\u0430\u043f\u043a\u0438 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u043b\u0438\u0441\u0442 \u0441\u0442\u043e\u0440\u043e\u043d\u0430 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 arrows data direction document folders right sheet side slide storage',
    DriveFileRenameOutline:
        '\u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0447\u0435\u0440\u043d\u043e\u0432\u0438\u043a \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0432\u0445\u043e\u0434 \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0448\u0443 compose create draft editing input pencil write writing',
    DriveFolderUpload:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 arrow data document file sheet slide storage',
    Dry: '\u0432\u043e\u0437\u0434\u0443\u0445\u0430 \u0432\u0430\u043d\u043d\u0430\u044f \u043a\u043e\u043c\u043d\u0430\u0442\u0430 \u0441\u0443\u0448\u0438\u043b\u043a\u0430 \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0440\u0443\u043a\u0430 \u0422\u0443\u0430\u043b\u0435\u0442 air bathroom dryer fingers gesture hand wc',
    DryCleaning:
        '\u0432\u0435\u0448\u0430\u043b\u043a\u0430 \u043e\u0442\u0435\u043b\u044c \u043f\u0440\u0430\u0447\u0435\u0447\u043d\u0430\u044f \u043c\u0435\u0441\u0442\u0430 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u043f\u043e\u043b\u043e\u0442\u0435\u043d\u0446\u0435 hanger hotel laundry places service towel',
    Duo: '\u0432\u044b\u0437\u043e\u0432 \u0447\u0430\u0442 \u043a\u043e\u043d\u0444\u0435\u0440\u0435\u043d\u0446\u0438\u044f \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0432\u0438\u0434\u0435\u043e call chat conference device video',
    Dvr: '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0430\u0443\u0434\u0438\u043e \u0445\u0440\u043e\u043c \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u044b\u0439 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043d\u043e\u0443\u0442\u0431\u0443\u043a \u0441\u043f\u0438\u0441\u043e\u043a \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0440\u0435\u043a\u043e\u0440\u0434\u0435\u0440 \u044d\u043a\u0440\u0430\u043d \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0432\u0438\u0434\u0435\u043e \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android audio chrome computer desktop device display electronic hardware iOS laptop list mac monitor recorder screen tv video web window',
    DynamicFeed:
        '\u0441\u043b\u043e\u0439 \u0436\u0438\u0442\u044c \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043f\u043e\u0447\u0442\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u041e\u0431\u043d\u043e\u0432\u0438\u0442\u044c layer live multiple post refresh update',
    DynamicForm:
        '\u043a\u043e\u0434 \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0431\u044b\u0441\u0442\u0440\u043e \u043c\u043e\u043b\u043d\u0438\u044f \u0441\u043f\u0438\u0441\u043a\u0438 \u043e\u043f\u0440\u043e\u0441\u043d\u0438\u043a \u0443\u0434\u0430\u0440 \u043c\u043e\u043b\u043d\u0438\u0438 code electric fast lightning lists questionnaire thunderbolt',
    Earbuds:
        '\u0430\u043a\u0441\u0435\u0441\u0441\u0443\u0430\u0440 \u0430\u0443\u0434\u0438\u043e \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u0421\u043b\u0443\u0448\u0430\u0442\u044c \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0432\u0443\u043a accessory audio earphone headphone listen music sound',
    EarbudsBattery:
        '\u0430\u043a\u0441\u0435\u0441\u0441\u0443\u0430\u0440 \u0430\u0443\u0434\u0438\u043e \u0437\u0430\u0440\u044f\u0434\u043a\u0430 \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u0421\u043b\u0443\u0448\u0430\u0442\u044c \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0432\u0443\u043a accessory audio charging earphone headphone listen music sound',
    East: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e arrow directional maps navigation right',
    EdgesensorHigh:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0448\u0430\u0433 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0447\u0443\u0432\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0432\u0438\u0431\u0440\u0438\u0440\u043e\u0432\u0430\u0442\u044c Android cell device hardware iOS mobile move phone sensitivity tablet vibrate',
    EdgesensorLow:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0448\u0430\u0433 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0447\u0443\u0432\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0432\u0438\u0431\u0440\u0438\u0440\u043e\u0432\u0430\u0442\u044c Android cell device hardware iOS mobile move phone sensitivity tablet vibrate',
    Edit: '\u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0432\u0445\u043e\u0434 \u043d\u043e\u0432\u044b\u0439 \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0448\u0443 compose create editing input new pencil write writing',
    EditAttributes:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0430\u0442\u0440\u0438\u0431\u0443\u0446\u0438\u044f \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve attribution check complete done mark ok select tick validate verified yes',
    EditLocation:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 GPS \u043a\u0430\u0440\u0442\u044b \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c destination direction gps maps pencil pin place stop write',
    EditLocationAlt:
        '\u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u0448\u0442\u044b\u0440\u044c pencil pin',
    EditNotifications:
        '\u0430\u043a\u0442\u0438\u0432\u043d\u044b\u0439 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u043f\u0435\u0440\u0435\u0437\u0432\u043e\u043d \u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0447\u0435\u0440\u043d\u043e\u0432\u0438\u043a \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0432\u0445\u043e\u0434 \u043d\u043e\u0432\u044b\u0439 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u044f\u0442\u044c \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0437\u0432\u0435\u043d\u0435\u0442\u044c \u0437\u0432\u0443\u043a \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0448\u0443 active alarm alert bell chime compose create draft editing input new notify pencil reminder ring sound write writing',
    EditOff:
        '\u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0447\u0435\u0440\u043d\u043e\u0432\u0438\u043a \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u0445\u043e\u0434 \u043d\u043e\u0432\u044b\u0439 \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043d\u0430 \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0448\u0443 compose create disabled draft editing enabled input new offline on pencil slash write writing',
    EditRoad:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0448\u043e\u0441\u0441\u0435 \u043a\u0430\u0440\u0442\u044b \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u0443\u043b\u0438\u0446\u0430 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 destination direction highway maps pencil street traffic',
    Egg: '\u0437\u0430\u0432\u0442\u0440\u0430\u043a \u043f\u043e\u0437\u0434\u043d\u0438\u0439 \u0437\u0430\u0432\u0442\u0440\u0430\u043a \u0435\u0434\u0430 breakfast brunch food',
    EggAlt: '\u0437\u0430\u0432\u0442\u0440\u0430\u043a \u043f\u043e\u0437\u0434\u043d\u0438\u0439 \u0437\u0430\u0432\u0442\u0440\u0430\u043a \u0435\u0434\u0430 breakfast brunch food',
    EighteenMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    EightK: '8000 8\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e 8000 8K alphabet character digit display font letter number pixels resolution symbol text type video',
    EightKPlus:
        '+ 7000 8\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e + 7000 8K alphabet character digit display font letter number pixels resolution symbol text type video',
    EightMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digit font image letters megapixels number quality resolution symbol text type',
    EightteenMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    Eject: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0434\u0438\u0441\u043a \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 DVD \u0438\u0433\u0440\u043e\u043a \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0442\u0440\u0435\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u0432\u0432\u0435\u0440\u0445 USB arrow disc drive dvd player remove triangle up usb',
    Elderly:
        '\u0442\u0435\u043b\u043e \u0442\u0440\u043e\u0441\u0442\u044c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u0442\u0430\u0440\u044b\u0439 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u0442\u0430\u0440\u0448\u0438\u0439 body cane human old people person senior',
    ElderlyWoman:
        '\u0442\u0435\u043b\u043e \u0442\u0440\u043e\u0441\u0442\u044c \u0436\u0435\u043d\u0441\u043a\u0438\u0439 \u041f\u043e\u043b \u0434\u0435\u0432\u043e\u0447\u043a\u0430 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u0435\u0434\u0438 \u0441\u0442\u0430\u0440\u044b\u0439 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u0442\u0430\u0440\u0448\u0438\u0439 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0436\u0435\u043d\u0449\u0438\u043d\u044b body cane female gender girl human lady old people person senior social symbol women',
    ElectricalServices:
        '\u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u0448\u043d\u0443\u0440 \u0437\u0430\u0442\u044b\u043a\u0430\u0442\u044c \u0441\u0438\u043b\u0430 \u043f\u0440\u043e\u0432\u043e\u0434 charge cord plug power wire',
    ElectricBike:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043a\u0430\u0440\u0442\u044b \u0441\u0430\u043c\u043e\u043a\u0430\u0442 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0432\u0435\u0441\u043f\u0430 automobile cars electricity maps scooter transportation travel vehicle vespa',
    ElectricBolt:
        '\u044d\u043d\u0435\u0440\u0433\u0438\u044f \u0431\u044b\u0441\u0442\u0440\u043e \u043c\u043e\u043b\u043d\u0438\u044f \u0433\u043d\u0435\u0437\u0434\u043e \u0443\u0434\u0430\u0440 \u043c\u043e\u043b\u043d\u0438\u0438 energy fast lightning nest thunderbolt',
    ElectricCar:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043a\u0430\u0440\u0442\u044b \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars electricity maps transportation travel vehicle',
    ElectricMeter:
        '\u044d\u043d\u0435\u0440\u0433\u0438\u044f \u0431\u044b\u0441\u0442\u0440\u043e \u043c\u043e\u043b\u043d\u0438\u044f \u043c\u0435\u0440\u0430 \u0433\u043d\u0435\u0437\u0434\u043e \u0443\u0434\u0430\u0440 \u043c\u043e\u043b\u043d\u0438\u0438 \u041f\u0440\u0438\u043c\u0435\u043d\u0435\u043d\u0438\u0435 \u041d\u0430\u043f\u0440\u044f\u0436\u0435\u043d\u0438\u0435 \u0432\u043e\u043b\u044c\u0442 energy fast lightning measure nest thunderbolt usage voltage volts',
    ElectricMoped:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0432\u0435\u043b\u043e\u0441\u0438\u043f\u0435\u0434 \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u0441\u0430\u043c\u043e\u043a\u0430\u0442 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0432\u0435\u0441\u043f\u0430 automobile bike cars maps scooter transportation travel vehicle vespa',
    ElectricRickshaw:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u0418\u043d\u0434\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0433\u0440\u0443\u0437\u043e\u0432\u0430\u044f \u043c\u0430\u0448\u0438\u043d\u0430 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars india maps transportation truck vehicle',
    ElectricScooter:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0432\u0435\u043b\u043e\u0441\u0438\u043f\u0435\u0434 \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0432\u0435\u0441\u043f\u0430 automobile bike cars maps transportation vehicle vespa',
    Elevator:
        '\u0442\u0435\u043b\u043e \u0432\u043d\u0438\u0437 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0432\u0432\u0435\u0440\u0445 body down human people person up',
    ElevenMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    Email: '\u043a\u043e\u043d\u0432\u0435\u0440\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u043f\u043e\u0447\u0442\u0430 \u041f\u043e\u043b\u0443\u0447\u0430\u0442\u044c \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c envelope letter message note post receive send write',
    EmergencyRecording:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043a\u0430\u043c\u0435\u0440\u0430 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0441\u044a\u0435\u043c\u043a\u0438 \u0444\u0438\u043b\u044c\u043c\u0430 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0432\u0438\u0434\u0435\u043e\u0441\u044a\u0435\u043c\u043a\u0430 \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 alert attention camera caution danger filming hardware image important motion notification picture videography warning',
    EmergencyShare:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0432\u0430\u0436\u043d\u044b\u0439 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 alert attention caution danger important notification warning',
    EMobiledata:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet font letter text type',
    EmojiEmotions:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u0432\u044b\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u043b\u0438\u0446\u043e \u0447\u0443\u0432\u0441\u0442\u0432\u0430 \u0440\u0430\u0434\u043e\u0441\u0442\u043d\u044b\u0439 \u0441\u0447\u0430\u0441\u0442\u044c\u0435 \u0441\u0447\u0430\u0441\u0442\u043b\u0438\u0432\u044b\u0439 \u0432\u0441\u0442\u0430\u0432\u043b\u044f\u0442\u044c \u043a\u0430\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435 \u043d\u043e\u0432\u044b\u0439 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0434\u043e\u0432\u043e\u043b\u044c\u043d\u044b\u0439 \u043f\u043b\u044e\u0441 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u0443\u043b\u044b\u0431\u0430\u0435\u0442\u0441\u044f \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043e\u043f\u0440\u043e\u0441 \u0441\u0438\u043c\u0432\u043e\u043b + add emoticon expressions face feelings glad happiness happy insert like mood new person pleased plus smiley smiling social survey symbol',
    EmojiEvents:
        '\u0434\u043e\u0441\u0442\u0438\u0436\u0435\u043d\u0438\u0435 \u043d\u0430\u0433\u0440\u0430\u0434\u0430 \u0447\u0430\u0448\u0430 \u0447\u0435\u043c\u043f\u0438\u043e\u043d \u0447\u0430\u0448\u043a\u0430 \u043f\u0435\u0440\u0432\u044b\u0439 \u043f\u0440\u0438\u0437 \u043d\u0430\u0433\u0440\u0430\u0434\u0430 \u0441\u043f\u043e\u0440\u0442 \u0442\u0440\u043e\u0444\u0435\u0439 \u043f\u043e\u0431\u0435\u0434\u0438\u0442\u0435\u043b\u044c achievement award chalice champion cup first prize reward sport trophy winner',
    EmojiFoodBeverage:
        '\u043a\u043e\u0444\u0435 \u0447\u0430\u0448\u043a\u0430 \u043f\u0440\u0438\u0437\u044b\u0432\u0430\u0435\u0442 \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u043a\u0440\u0443\u0436\u043a\u0430 \u043f\u043b\u0430\u0441\u0442\u0438\u043d\u0430 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u043b\u0435\u043d \u0447\u0430\u0439 coffee cup dring drink mug plate set tea',
    EmojiNature:
        '\u0436\u0438\u0432\u043e\u0442\u043d\u043e\u0435 \u043f\u0447\u0435\u043b\u0430 \u043c\u0430\u0440\u0433\u0430\u0440\u0438\u0442\u043a\u0430 \u0446\u0432\u0435\u0442\u043e\u043a \u043c\u0435\u0434\u043e\u0432\u044b\u0439 \u043d\u0430\u0441\u0435\u043a\u043e\u043c\u043e\u0435 \u0431\u043e\u0436\u044c\u044f \u043a\u043e\u0440\u043e\u0432\u043a\u0430 \u043b\u0435\u043f\u0435\u0441\u0442\u043a\u0438 \u0432\u0435\u0441\u043d\u0430 \u043b\u0435\u0442\u043e animal bee daisy flower honey insect ladybug petals spring summer',
    EmojiObjects:
        '\u0442\u0432\u043e\u0440\u0447\u0435\u0441\u043a\u0438\u0439 \u0438\u0434\u0435\u044f \u0444\u043e\u043d\u0430\u0440\u044c \u043b\u0430\u043c\u043f\u043e\u0447\u043a\u0430 \u0440\u0435\u0448\u0435\u043d\u0438\u0435 \u043c\u044b\u0448\u043b\u0435\u043d\u0438\u0435 creative idea lamp lightbulb solution thinking',
    EmojiPeople:
        '\u0440\u0443\u043a\u0430 \u0442\u0435\u043b\u043e \u043f\u0440\u0438\u0432\u0435\u0442\u0441\u0442\u0432\u0438\u0435 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0432\u043e\u043b\u043d\u0430 \u0440\u0430\u0437\u043c\u0430\u0445\u0438\u0432\u0430\u044f arm body greeting human person social wave waving',
    EmojiSymbols:
        '\u0430\u043c\u043f\u0435\u0440\u0441\u0430\u043d\u0434 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0438\u0435\u0440\u043e\u0433\u043b\u0438\u0444\u044b \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u043f\u0440\u043e\u0446\u0435\u043d\u0442 \u0437\u043d\u0430\u043a ampersand character hieroglyph music note percent sign',
    EmojiTransportation:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u043c\u0430\u0448\u0438\u043d\u044b \u0435\u0437\u0434\u0438\u0442\u044c \u041a\u043e\u043c\u043f\u0430\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u043a\u0430\u0440\u0442\u044b \u043e\u0444\u0438\u0441 \u043c\u0435\u0441\u0442\u043e \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f architecture automobile building cars commute company direction estate maps office place public real residence residential shelter travel vehicle',
    EnergySavingsLeaf:
        '\u044d\u043a\u043e \u043b\u0438\u0441\u0442\u044c\u044f \u0433\u043d\u0435\u0437\u0434\u043e \u041f\u0440\u0438\u043c\u0435\u043d\u0435\u043d\u0438\u0435 eco leaves nest usage',
    Engineering:
        '\u0442\u0435\u043b\u043e \u0432\u0438\u043d\u0442\u0438\u043a\u0438 \u0437\u0443\u0431\u0447\u0430\u0442\u043e\u0435 \u043a\u043e\u043b\u0435\u0441\u043e \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0444\u0438\u043a\u0441\u0430\u0446\u0438\u044f \u0448\u0435\u0441\u0442\u0435\u0440\u043d\u0438 \u0418\u043c\u0435\u0435\u0442 \u0448\u043b\u0435\u043c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0435 \u043e\u0431\u0441\u043b\u0443\u0436\u0438\u0432\u0430\u043d\u0438\u0435 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440 \u0440\u0430\u0431\u043e\u0447\u0438\u0439 body cogs cogwheel construction fixing gears hat helmet human maintenance people person setting worker',
    EnhancedEncryption:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u043d\u043e\u0432\u044b\u0439 \u043f\u0430\u0440\u043e\u043b\u044c \u043f\u043b\u044e\u0441 \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b + add locked new password plus privacy private protection safety secure security symbol',
    Equalizer:
        '\u043a\u043e\u0440\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0433\u0440\u0430\u0444\u0438\u043a \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u041c\u0443\u0437\u044b\u043a\u0430 \u0448\u0443\u043c \u0437\u0432\u0443\u043a \u0441\u0442\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 \u043e\u0431\u044a\u0435\u043c adjustment analytics chart data graph measure metrics music noise sound static statistics tracking volume',
    Error: '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043a\u0440\u0443\u0433 \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043f\u0440\u043e\u0431\u043b\u0435\u043c\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert announcement attention caution circle danger exclamation feedback important mark notification problem symbol warning',
    ErrorOutline:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043a\u0440\u0443\u0433 \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043f\u0440\u043e\u0431\u043b\u0435\u043c\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert announcement attention caution circle danger exclamation feedback important mark notification problem symbol warning',
    Escalator:
        '\u0432\u043d\u0438\u0437 \u043b\u0435\u0441\u0442\u043d\u0438\u0446\u0430 \u0432\u0432\u0435\u0440\u0445 down staircase up',
    EscalatorWarning:
        '\u0442\u0435\u043b\u043e \u0440\u0435\u0431\u0435\u043d\u043e\u043a \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0440\u0435\u0431\u0435\u043d\u043e\u043a \u0440\u043e\u0434\u0438\u0442\u0435\u043b\u044c \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a body child human kid parent people person',
    Euro: '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0435\u0432\u0440\u043e \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u0432\u044b\u0433\u043e\u0434\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill card cash coin commerce cost credit currency dollars euros finance money online payment price profit shopping symbol',
    EuroSymbol:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u0432\u044b\u0433\u043e\u0434\u0430 bill card cash coin commerce cost credit currency dollars finance money online payment price profit',
    Event: '\u043f\u043e\u0432\u0435\u0441\u0442\u043a\u0430 \u0434\u043d\u044f \u043a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0432\u0435\u0449\u044c \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u0435\u0441\u044f\u0446 \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 C\u0435\u0433\u043e\u0434\u043d\u044f \u043d\u0435\u0434\u0435\u043b\u044f agenda calendar date item mark month range remember reminder today week',
    EventAvailable:
        '\u043f\u043e\u0432\u0435\u0441\u0442\u043a\u0430 \u0434\u043d\u044f \u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u043a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0432\u0435\u0449\u044c \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u0432\u0440\u0435\u043c\u044f \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 agenda approve calendar check complete done item mark ok schedule select tick time validate verified yes',
    EventBusy:
        '\u043f\u043e\u0432\u0435\u0441\u0442\u043a\u0430 \u0434\u043d\u044f \u043a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0432\u044b\u0445\u043e\u0434 \u0432\u0435\u0449\u044c \u043d\u0435\u0442 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0432\u0440\u0435\u043c\u044f \u043d\u0435\u0434\u043e\u0441\u0442\u0443\u043f\u0435\u043d agenda calendar cancel close date exit item no remove schedule stop time unavailable',
    EventNote:
        '\u043f\u043e\u0432\u0435\u0441\u0442\u043a\u0430 \u0434\u043d\u044f \u043a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0432\u0435\u0449\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0442\u0435\u043a\u0441\u0442 \u0432\u0440\u0435\u043c\u044f \u043f\u0438\u0448\u0443 agenda calendar date item schedule text time writing',
    EventRepeat:
        '\u043e\u043a\u043e\u043b\u043e \u043a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0434\u0435\u043d\u044c \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043c\u0435\u0441\u044f\u0446 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c around calendar date day inprogress loading month refresh renew rotate schedule turn',
    EventSeat:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u043d\u044b\u0439 \u0441\u043a\u0430\u043c\u044c\u044f \u0441\u0442\u0443\u043b \u043c\u0435\u0431\u0435\u043b\u044c \u0431\u0440\u043e\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0441\u0442\u0440\u043e\u043a\u0430 \u0440\u0430\u0437\u0434\u0435\u043b \u0441\u0438\u0434\u0435\u0442\u044c assigned bench chair furniture reservation row section sit',
    EvStation:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u0437\u0430\u0440\u044f\u0434\u043a\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0437\u0430\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u0435 \u0442\u043e\u043f\u043b\u0438\u0432\u043e \u0431\u0435\u043d\u0437\u0438\u043d \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0441\u0442\u0430 \u0441\u0438\u043b\u0430 \u0441\u0442\u0430\u043d\u0446\u0438\u044f \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars charge charging electricity filling fuel gasoline maps places power station transportation vehicle',
    ExitToApp:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u044d\u043a\u0441\u043f\u043e\u0440\u0442 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043c\u0430\u043a\u0435\u0442 \u043e\u0441\u0442\u0430\u0432\u043b\u044f\u0442\u044c \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0432\u044b\u0439\u0442\u0438 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0448\u0430\u0433 \u0432\u044b\u0445\u043e\u0434 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0443\u043a\u0430\u0437\u044b\u0432\u0430\u044f \u043f\u043e\u043a\u0438\u0434\u0430\u0442\u044c \u0440\u0435\u0433\u0438\u0441\u0442\u0440 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u044d\u043a\u0440\u0430\u043d \u0437\u043d\u0430\u043a \u0432\u044b\u0445\u043e\u0434 \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e application arrow back components design export interface layout leave login logout mobile monitor move output phone pointing quit register right screen signin signout signup tablet ux website window',
    Expand: '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043c\u043f\u0440\u0435\u0441\u0441 \u0443\u0432\u0435\u043b\u0438\u0447\u0438\u0442\u044c \u0440\u0430\u0441\u0442\u0438 \u0448\u0430\u0433 \u0442\u043e\u043b\u043a\u0430\u0442\u044c \u0432\u043c\u0435\u0441\u0442\u0435 arrows compress enlarge grow move push together',
    ExpandCircleDown:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0448\u0435\u0432\u0440\u043e\u043d \u043a\u0440\u0430\u0445 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u0448\u0438\u0440\u044f\u0435\u043c\u044b\u0439 \u0441\u043f\u0438\u0441\u043e\u043a \u0431\u043e\u043b\u0435\u0435 arrows chevron collapse direction expandable list more',
    ExpandLess:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0448\u0435\u0432\u0440\u043e\u043d \u043a\u0440\u0430\u0445 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u0448\u0438\u0440\u044f\u0435\u043c\u044b\u0439 \u0441\u043f\u0438\u0441\u043e\u043a \u0432\u0432\u0435\u0440\u0445 arrows chevron collapse direction expandable list up',
    ExpandMore:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0448\u0435\u0432\u0440\u043e\u043d \u043a\u0440\u0430\u0445 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u0437 \u0440\u0430\u0441\u0448\u0438\u0440\u044f\u0435\u043c\u044b\u0439 \u0441\u043f\u0438\u0441\u043e\u043a arrows chevron collapse direction down expandable list',
    Explicit:
        '\u0432\u0437\u0440\u043e\u0441\u043b\u044b\u0439 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0441\u043e\u0434\u0435\u0440\u0436\u0430\u043d\u0438\u0435 \u0448\u0440\u0438\u0444\u0442 \u044f\u0437\u044b\u043a \u043f\u0438\u0441\u044c\u043c\u043e \u0421\u041c\u0418 \u0444\u0438\u043b\u044c\u043c\u044b \u041c\u0443\u0437\u044b\u043a\u0430 \u0440\u043e\u0434\u0438\u0442\u0435\u043b\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u043d\u0430\u0434\u0437\u043e\u0440 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f adult alphabet character content font language letter media movies music parent rating supervision symbol text type',
    Explore:
        '\u043a\u043e\u043c\u043f\u0430\u0441 \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043e\u0441\u0442\u043e\u043a \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0438\u0433\u043e\u043b\u043a\u0430 \u043a \u0441\u0435\u0432\u0435\u0440\u0443 \u044e\u0433 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0437\u0430\u043f\u0430\u0434 compass destination direction east location maps needle north south travel west',
    ExploreOff:
        '\u043a\u043e\u043c\u043f\u0430\u0441 \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043e\u0441\u0442\u043e\u043a \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0438\u0433\u043e\u043b\u043a\u0430 \u043a \u0441\u0435\u0432\u0435\u0440\u0443 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u044e\u0433 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0437\u0430\u043f\u0430\u0434 compass destination direction disabled east enabled location maps needle north slash south travel west',
    Exposure:
        '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u044f\u0440\u043a\u043e\u0441\u0442\u044c \u043a\u043e\u043d\u0442\u0440\u0430\u0441\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043c\u0438\u043d\u0443\u0441 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u043b\u044e\u0441 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0432\u044b\u0447\u0435\u0441\u0442\u044c add brightness contrast editing effect image minus photography picture plus settings subtract',
    Extension:
        '\u043d\u0430\u0434\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043f\u0440\u0438\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u0448\u0438\u0440\u0435\u043d\u043d\u044b\u0439 \u0438\u0433\u0440\u0430 \u0432\u0435\u0449\u044c \u0433\u043e\u043b\u043e\u0432\u043e\u043b\u043e\u043c\u043a\u0430 \u043a\u0443\u0441\u043e\u043a \u043f\u043b\u0430\u0433\u0438\u043d \u0433\u043e\u043b\u043e\u0432\u043e\u043b\u043e\u043c\u043a\u0430 \u0444\u043e\u0440\u043c\u0430 add-ons app extended game item jigsaw piece plugin puzzle shape',
    ExtensionOff:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0440\u0430\u0441\u0448\u0438\u0440\u0435\u043d\u043d\u044b\u0439 \u0433\u043e\u043b\u043e\u0432\u043e\u043b\u043e\u043c\u043a\u0430 \u043a\u0443\u0441\u043e\u043a \u0433\u043e\u043b\u043e\u0432\u043e\u043b\u043e\u043c\u043a\u0430 \u0444\u043e\u0440\u043c\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled enabled extended jigsaw piece puzzle shape slash',
    Face: '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u044d\u043c\u043e\u0434\u0437\u0438 \u0433\u043b\u0430\u0437\u0430 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0432\u044b\u0439\u0442\u0438 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u0440\u0438\u0437\u043d\u0430\u043d\u0438\u0435 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043c\u0438\u043d\u0438\u0430\u0442\u044e\u0440\u0430 \u0440\u0430\u0437\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account avatar emoji eyes human login logout people person profile recognition security social thumbnail unlock user',
    Facebook:
        '\u043c\u0430\u0440\u043a\u0430 \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 brand logo social',
    FaceRetouchingNatural:
        '\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u044d\u043c\u043e\u0434\u0437\u0438 \u044d\u043c\u043e\u0446\u0438\u044f \u043b\u0438\u0446\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0437\u0432\u0435\u0437\u0434\u0430 \u044f\u0440\u043b\u044b\u043a editing effect emoji emotion faces image photography settings star tag',
    FaceRetouchingOff:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u044d\u043c\u043e\u0434\u0437\u0438 \u044d\u043c\u043e\u0446\u0438\u044f \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043b\u0438\u0446\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0435\u0441\u0442\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u044f\u0440\u043b\u044b\u043a disabled editing effect emoji emotion enabled faces image natural photography settings slash tag',
    FactCheck:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0441\u043f\u0438\u0441\u043e\u043a \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve complete done list mark ok select tick validate verified yes',
    Factory:
        '\u043f\u0440\u043e\u043c\u044b\u0448\u043b\u0435\u043d\u043d\u043e\u0441\u0442\u044c \u043f\u0440\u043e\u0438\u0437\u0432\u043e\u0434\u0441\u0442\u0432\u043e \u0441\u043a\u043b\u0430\u0434 industry manufacturing warehouse',
    FamilyRestroom:
        '\u0432\u0430\u043d\u043d\u0430\u044f \u043a\u043e\u043c\u043d\u0430\u0442\u0430 \u0434\u0435\u0442\u0438 \u043e\u0442\u0435\u0446 \u0414\u0435\u0442\u0438 \u043c\u0430\u0442\u044c \u0440\u043e\u0434\u0438\u0442\u0435\u043b\u0438 \u0422\u0443\u0430\u043b\u0435\u0442 bathroom children father kids mother parents wc',
    Fastfood:
        '\u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0433\u0430\u043c\u0431\u0443\u0440\u0433\u0435\u0440 \u043a\u0430\u0440\u0442\u044b \u0435\u0434\u0430 \u043c\u0435\u0441\u0442\u0430 drink hamburger maps meal places',
    FastForward:
        '\u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u0444\u0444 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0432\u0440\u0435\u043c\u044f \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0432\u0438\u0434\u0435\u043e control ff media music play speed time tv video',
    FastRewind:
        '\u043d\u0430\u0437\u0430\u0434 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0432\u0440\u0435\u043c\u044f \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0432\u0438\u0434\u0435\u043e back control media music play speed time tv video',
    Favorite:
        '\u0446\u0435\u043d\u0438\u0442\u044c \u0437\u0434\u043e\u0440\u043e\u0432\u044c\u0435 \u0441\u0435\u0440\u0434\u0446\u0435 \u043a\u0430\u043a \u043b\u044e\u0431\u043e\u0432\u044c \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u0441\u043f\u0430\u0441\u0442\u0438 \u0444\u043e\u0440\u043c\u0430 \u0443\u0441\u043f\u0435\u0445 appreciate health heart like love remember save shape success',
    FavoriteBorder:
        '\u0437\u0434\u043e\u0440\u043e\u0432\u044c\u0435 \u0441\u0435\u0440\u0434\u0446\u0435 \u043a\u0430\u043a \u043b\u044e\u0431\u043e\u0432\u044c \u043a\u043e\u043d\u0442\u0443\u0440 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u0441\u043f\u0430\u0441\u0442\u0438 \u0444\u043e\u0440\u043c\u0430 \u0443\u0441\u043f\u0435\u0445 health heart like love outline remember save shape success',
    Fax: '\u043c\u0430\u0448\u0438\u043d\u0430 \u043e\u0444\u0438\u0441 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c machine office phone send',
    FeaturedPlayList:
        '\u0430\u0443\u0434\u0438\u043e \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u0432\u044b\u0434\u0435\u043b\u0435\u043d\u043d\u044b\u0439 \u0432\u0435\u0449\u044c \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u043b\u0435\u0439\u043b\u0438\u0441\u0442 \u0440\u0435\u043a\u043e\u043c\u0435\u043d\u0434\u0443\u0435\u043c\u044b\u0435 audio collection highlighted item music playlist recommended',
    FeaturedVideo:
        '\u0440\u0435\u043a\u043b\u0430\u043c\u043d\u043e\u0435 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0435\u043a\u043b\u0430\u043c\u043d\u043e\u0435 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u044b\u0434\u0435\u043b\u0435\u043d\u043d\u044b\u0439 \u0432\u0435\u0449\u044c \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0440\u0435\u043a\u043e\u043c\u0435\u043d\u0434\u0443\u0435\u043c\u044b\u0435 \u0447\u0430\u0441\u044b, \u0440\u0435\u043a\u043b\u0430\u043c\u0430 advertisement advertisment highlighted item play recommended watch,advertised',
    Feed: '\u0441\u0442\u0430\u0442\u044c\u044f \u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0433\u0430\u0437\u0435\u0442\u0430 \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0433\u0440\u0430\u0444\u0438\u043a article headline information newspaper public social timeline',
    Feedback:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043f\u0443\u0437\u044b\u0440\u044c \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0440\u0435\u0447\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert announcement attention bubble caution chat comment communicate danger error exclamation important mark message notification speech symbol warning',
    Female: '\u041f\u043e\u043b \u0434\u0435\u0432\u043e\u0447\u043a\u0430 \u043b\u0435\u0434\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0436\u0435\u043d\u0449\u0438\u043d\u0430 \u0436\u0435\u043d\u0449\u0438\u043d\u044b gender girl lady social symbol woman women',
    Fence: '\u0437\u0430\u0434\u043d\u0438\u0439 \u0434\u0432\u043e\u0440 \u0431\u0430\u0440\u044c\u0435\u0440 \u0433\u0440\u0430\u043d\u0438\u0446\u044b \u0433\u0440\u0430\u043d\u0438\u0446\u0430 \u0434\u043e\u043c \u0434\u043e\u043c \u0437\u0430\u0449\u0438\u0442\u0430 backyard barrier boundaries boundary home house protection',
    Festival:
        '\u0446\u0438\u0440\u043a \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u043c\u0435\u0441\u0442\u043d\u044b\u0439 \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0441\u0442\u0430 \u043f\u0430\u043b\u0430\u0442\u043a\u0430 \u0442\u0443\u0440 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c circus event local maps places tent tour travel',
    FiberDvr:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u043e\u0432\u043e\u0439 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u0438\u043a\u0430 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u0440\u0435\u043a\u043e\u0440\u0434\u0435\u0440 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e alphabet character digital electronics font letter network recorder symbol text tv type video',
    FiberManualRecord:
        '\u043a\u0440\u0443\u0433 \u0442\u043e\u0447\u043a\u0430 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c circle dot play watch',
    FiberNew:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character font letter network symbol text type',
    FiberPin:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character font letter network symbol text type',
    FiberSmartRecord:
        '\u043a\u0440\u0443\u0433 \u0442\u043e\u0447\u043a\u0430 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c circle dot play watch',
    FifteenMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    FileCopy:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043a\u043b\u043e\u043d \u0441\u043e\u0434\u0435\u0440\u0436\u0430\u043d\u0438\u0435 \u0440\u0435\u0437\u0430\u0442\u044c \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0434\u0443\u0431\u043b\u0438\u043a\u0430\u0442 \u0441\u0447\u0435\u0442 \u0432\u0435\u0449\u044c \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u043c\u0438\u043c\u043e bill clone content cut document duplicate invoice item multiple page past',
    FileDownload:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0438 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u044d\u043a\u0441\u043f\u043e\u0440\u0442 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c arrows downloads drive export install upload',
    FileDownloadDone:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0438 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u043b\u0435\u043d\u044b \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c arrows check downloads drive installed tick upload',
    FileDownloadOff:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u044d\u043a\u0441\u043f\u043e\u0440\u0442 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u0438\u0442\u044c \u043d\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c arrow disabled drive enabled export install on save slash upload',
    FileOpen:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0431\u0443\u043c\u0430\u0433\u0430 arrow document drive left page paper',
    FilePresent:
        '\u043a\u043b\u0438\u043f \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u043f\u0430\u043f\u043a\u0438 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u0431\u0443\u043c\u0430\u0433\u0430 \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u043f\u0438\u0448\u0443 clip data document drive folders note paper reminder sheet slide storage writing',
    FileUpload:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u044d\u043a\u0441\u043f\u043e\u0440\u0442 arrows download drive export',
    Filter: '\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 editing effect image landscape mountains photography picture settings',
    Filter1:
        '\u0446\u0438\u0444\u0440\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043a\u0443\u0447\u0430 \u0441\u0438\u043c\u0432\u043e\u043b digit editing effect images multiple number photography pictures settings stack symbol',
    Filter2:
        '\u0446\u0438\u0444\u0440\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043a\u0443\u0447\u0430 \u0441\u0438\u043c\u0432\u043e\u043b digit editing effect images multiple number photography pictures settings stack symbol',
    Filter3:
        '\u0446\u0438\u0444\u0440\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043a\u0443\u0447\u0430 \u0441\u0438\u043c\u0432\u043e\u043b digit editing effect images multiple number photography pictures settings stack symbol',
    Filter4:
        '\u0446\u0438\u0444\u0440\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043a\u0443\u0447\u0430 \u0441\u0438\u043c\u0432\u043e\u043b digit editing effect images multiple number photography pictures settings stack symbol',
    Filter5:
        '\u0446\u0438\u0444\u0440\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043a\u0443\u0447\u0430 \u0441\u0438\u043c\u0432\u043e\u043b digit editing effect images multiple number photography pictures settings stack symbol',
    Filter6:
        '\u0446\u0438\u0444\u0440\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043a\u0443\u0447\u0430 \u0441\u0438\u043c\u0432\u043e\u043b digit editing effect images multiple number photography pictures settings stack symbol',
    Filter7:
        '\u0446\u0438\u0444\u0440\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043a\u0443\u0447\u0430 \u0441\u0438\u043c\u0432\u043e\u043b digit editing effect images multiple number photography pictures settings stack symbol',
    Filter8:
        '\u0446\u0438\u0444\u0440\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043a\u0443\u0447\u0430 \u0441\u0438\u043c\u0432\u043e\u043b digit editing effect images multiple number photography pictures settings stack symbol',
    Filter9:
        '\u0446\u0438\u0444\u0440\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043a\u0443\u0447\u0430 \u0441\u0438\u043c\u0432\u043e\u043b digit editing effect images multiple number photography pictures settings stack symbol',
    Filter9Plus:
        '+ \u0446\u0438\u0444\u0440\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043a\u0443\u0447\u0430 \u0441\u0438\u043c\u0432\u043e\u043b + digit editing effect images multiple number photography pictures settings stack symbol',
    FilterAlt:
        '\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0432\u043e\u0440\u043e\u043d\u043a\u0430 \u043e\u043f\u0446\u0438\u0438 \u0443\u0442\u043e\u0447\u043d\u0438\u0442\u044c \u043f\u0440\u043e\u0441\u0435\u044f\u0442\u044c edit funnel options refine sift',
    FilterAltOff:
        '[\u043d\u0435 \u0432 \u0441\u0435\u0442\u0438] \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0432\u043e\u0440\u043e\u043d\u043a\u0430 \u043e\u043f\u0446\u0438\u0438 \u0443\u0442\u043e\u0447\u043d\u0438\u0442\u044c \u043f\u0440\u043e\u0441\u0435\u044f\u0442\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 [offline] disabled edit funnel options refine sift slash',
    FilterBAndW:
        '\u0447\u0435\u0440\u043d\u044b\u0439 \u043a\u043e\u043d\u0442\u0440\u0430\u0441\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u043e\u0442\u0442\u0435\u043d\u043a\u0438 \u0441\u0435\u0440\u043e\u0433\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0431\u0435\u043b\u044b\u0439 black contrast editing effect grayscale images photography pictures settings white',
    FilterCenterFocus:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0442\u043e\u0447\u043a\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 camera dot edit image photography picture',
    FilterDrama:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u043e\u0431\u043b\u0430\u043a\u043e \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043d\u0435\u0431\u043e camera cloud editing effect image photography picture sky',
    FilterFrames:
        '\u043f\u0430\u043d\u0441\u0438\u043e\u043d\u0435\u0440\u044b \u0433\u0440\u0430\u043d\u0438\u0446\u0430 \u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0435\u043d\u0442\u0440 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0444\u0438\u043b\u044c\u0442\u0440\u044b \u0444\u043e\u043a\u0443\u0441 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043e\u043f\u0446\u0438\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 boarders border camera center editing effect filters focus image options photography picture',
    FilterHdr:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 camera editing effect image mountains photography picture',
    FilterList:
        '\u043b\u0438\u043d\u0438\u0438 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u043e\u0432\u0430\u0442\u044c \u0421\u043e\u0440\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c lines organize sort',
    FilterListOff:
        '[\u043d\u0435 \u0432 \u0441\u0435\u0442\u0438] \u0432\u0441\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043e\u043f\u0446\u0438\u0438 \u0443\u0442\u043e\u0447\u043d\u0438\u0442\u044c \u043f\u0440\u043e\u0441\u0435\u044f\u0442\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 [offline] alt disabled edit options refine sift slash',
    FilterNone:
        '\u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043a\u0443\u0447\u0430 multiple stack',
    FilterTiltShift:
        '\u0440\u0430\u0437\u043c\u044b\u0442\u0438\u0435 \u0446\u0435\u043d\u0442\u0440 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0444\u043e\u043a\u0443\u0441 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 blur center editing effect focus images photography pictures',
    FilterVintage:
        '\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0446\u0432\u0435\u0442\u043e\u043a \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 editing effect flower images photography pictures',
    FindInPage:
        '\u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u0431\u0443\u043c\u0430\u0433\u0430 \u043f\u043e\u0438\u0441\u043a \u0432\u0438\u0434\u0435\u0442\u044c \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u043f\u0438\u0448\u0443 data document drive file folders glass look magnifying paper search see sheet slide writing',
    FindReplace:
        '\u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043f\u043e\u0438\u0441\u043a \u0432\u0438\u0434\u0435\u0442\u044c around arrows glass inprogress loading look magnifying refresh renew rotate search see',
    Fingerprint:
        '\u0431\u0438\u043e\u043c\u0435\u0442\u0440\u0438\u044f \u0443\u0434\u043e\u0441\u0442\u043e\u0432\u0435\u0440\u0435\u043d\u0438\u0435 \u043b\u0438\u0447\u043d\u043e\u0441\u0442\u0438 \u043b\u0438\u0447\u043d\u043e\u0441\u0442\u044c \u0447\u0438\u0442\u0430\u0442\u0435\u043b\u044c \u043e\u0442\u043f\u0435\u0447\u0430\u0442\u043e\u043a \u0431\u043e\u043b\u044c\u0448\u043e\u0433\u043e \u043f\u0430\u043b\u044c\u0446\u0430 \u0441\u0435\u043d\u0441\u043e\u0440\u043d\u044b\u0439 \u043f\u0440\u043e\u0432\u0435\u0440\u043a\u0430 biometrics identification identity reader thumbprint touchid verification',
    FireExtinguisher:
        '\u0447\u0440\u0435\u0437\u0432\u044b\u0447\u0430\u0439\u043d\u0430\u044f \u0441\u0438\u0442\u0443\u0430\u0446\u0438\u044f \u0432\u043e\u0434\u0430 emergency water',
    Fireplace:
        '\u0434\u044b\u043c\u043e\u0432\u0430\u044f \u0442\u0440\u0443\u0431\u0430 \u043f\u043b\u0430\u043c\u044f \u0434\u043e\u043c \u0434\u043e\u043c \u0436\u0438\u0437\u043d\u044c \u044f\u043c\u0430 \u043d\u043e\u043c\u0435\u0440 \u0442\u0435\u043f\u043b\u043e \u0437\u0438\u043c\u0430 chimney flame home house living pit room warm winter',
    FirstPage:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u0448\u0435\u0432\u0440\u043e\u043d \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043f\u0435\u0440\u0435\u043c\u0430\u0442\u044b\u0432\u0430\u0442\u044c arrow back chevron left rewind',
    Fitbit: '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0444\u0438\u0442\u043d\u0435\u0441 \u0445\u043e\u0431\u0431\u0438 athlete athletic exercise fitness hobby',
    FitnessCenter:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0433\u0430\u043d\u0442\u0435\u043b\u044c \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0441\u043f\u043e\u0440\u0442\u0437\u0430\u043b \u0437\u0434\u043e\u0440\u043e\u0432\u044c\u0435 \u0445\u043e\u0431\u0431\u0438 \u043c\u0435\u0441\u0442\u0430 \u0441\u043f\u043e\u0440\u0442 \u0432\u0435\u0441\u0430 \u0442\u0440\u0435\u043d\u0438\u0440\u043e\u0432\u043a\u0430 athlete dumbbell exercise gym health hobby places sport weights workout',
    FitScreen:
        '\u0443\u0432\u0435\u043b\u0438\u0447\u0438\u0442\u044c \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u0443\u043c\u0435\u043d\u044c\u0448\u0430\u0442\u044c \u0448\u043a\u0430\u043b\u0430 \u0440\u0430\u0437\u043c\u0435\u0440 enlarge format layout reduce scale size',
    FiveG: '5\u0433 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u0430\u043d\u043d\u044b\u0435 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u0438\u0433\u043d\u0430\u043b \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f Wi-Fi 5g alphabet cellular character data digit font letter mobile network number phone signal speed symbol text type wifi',
    FiveK: '5000 5\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e 5000 5K alphabet character digit display font letter number pixels resolution symbol text type video',
    FiveKPlus:
        '+ 5000 5\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e + 5000 5K alphabet character digit display font letter number pixels resolution symbol text type video',
    FiveMp: '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digit font image letters megapixels number quality resolution symbol text type',
    FivteenMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    Flag: '\u0441\u0442\u0440\u0430\u043d\u0430 \u0426\u0435\u043b\u044c \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043d\u0430\u0446\u0438\u044f \u043e\u0442\u0447\u0435\u0442 \u041d\u0430\u0447\u0430\u043b\u043e country goal mark nation report start',
    FlagCircle:
        '\u0441\u0442\u0440\u0430\u043d\u0430 \u0426\u0435\u043b\u044c \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043d\u0430\u0446\u0438\u044f \u043e\u0442\u0447\u0435\u0442 \u043a\u0440\u0443\u0433\u043b\u044b\u0439 \u041d\u0430\u0447\u0430\u043b\u043e country goal mark nation report round start',
    Flaky: '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043f\u043e\u043b\u043d\u044b\u0439 \u043a\u043e\u043d\u0442\u0440\u0430\u0441\u0442 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0432\u044b\u0445\u043e\u0434 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043d\u0435\u0442 Ok \u043e\u043f\u0446\u0438\u0438 \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve check close complete contrast done exit mark no ok options select stop tick verified yes',
    Flare: '\u044f\u0440\u043a\u0438\u0439 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043e\u0442\u0431\u043b\u0435\u0441\u043a \u043e\u0442 \u043b\u0438\u043d\u0437 \u043b\u0435\u0433\u043a\u0438\u0439 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0441\u0432\u0435\u0442\u0438\u0442\u044c \u0431\u043b\u0435\u0441\u043a \u0437\u0432\u0435\u0437\u0434\u0430 \u0441\u043e\u043b\u043d\u0446\u0435 bright editing effect images lensflare light photography pictures shine sparkle star sun',
    FlashAuto:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0431\u044b\u0441\u0442\u0440\u043e \u043c\u043e\u043b\u043d\u0438\u044f \u0443\u0434\u0430\u0440 \u043c\u043e\u043b\u043d\u0438\u0438 camera electric fast lightning thunderbolt',
    FlashlightOff:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled enabled on slash',
    FlashlightOn:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled enabled off slash',
    FlashOff:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0431\u044b\u0441\u0442\u0440\u043e \u043c\u043e\u043b\u043d\u0438\u044f \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0443\u0434\u0430\u0440 \u043c\u043e\u043b\u043d\u0438\u0438 camera disabled electric enabled fast lightning on slash thunderbolt',
    FlashOn:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0431\u044b\u0441\u0442\u0440\u043e \u043c\u043e\u043b\u043d\u0438\u044f \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0443\u0434\u0430\u0440 \u043c\u043e\u043b\u043d\u0438\u0438 camera disabled electric enabled fast lightning off slash thunderbolt',
    Flatware:
        '\u0441\u0442\u043e\u043b\u043e\u0432\u0430\u044f \u0441\u0442\u043e\u043b\u043e\u0432\u044b\u0435 \u043f\u0440\u0438\u0431\u043e\u0440\u044b \u0437\u0430\u043a\u0443\u0441\u043e\u0447\u043d\u0430\u044f \u0441\u0442\u043e\u043b\u043e\u0432\u0430\u044f \u043f\u0440\u0438\u043d\u0438\u043c\u0430\u0442\u044c \u043f\u0438\u0449\u0443 \u0432\u0438\u043b\u043a\u0430 \u043d\u043e\u043c\u0435\u0440 \u043b\u043e\u0436\u043a\u0430 cafeteria cutlery diner dining eating fork room spoon',
    Flight: '\u0441\u0430\u043c\u043e\u043b\u0435\u0442 \u0430\u044d\u0440\u043e\u043f\u043e\u0440\u0442 \u043b\u0435\u0442\u0430\u044e\u0449\u0438\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 airplane airport flying transportation travel trip',
    FlightClass:
        '\u0441\u0430\u043c\u043e\u043b\u0435\u0442 \u0431\u0438\u0437\u043d\u0435\u0441 \u043f\u0435\u0440\u0432\u044b\u0439 \u0441\u0438\u0434\u0435\u043d\u044c\u0435 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 \u043e\u043a\u043d\u043e airplane business first seat transportation travel trip window',
    FlightLand:
        '\u0441\u0430\u043c\u043e\u043b\u0435\u0442 \u0430\u044d\u0440\u043e\u043f\u043e\u0440\u0442 \u043f\u0440\u0438\u0431\u044b\u0442\u0438\u0435 \u043f\u0440\u0438\u0431\u044b\u0442\u0438\u0435 \u043b\u0435\u0442\u0430\u044e\u0449\u0438\u0439 \u043f\u043e\u0441\u0430\u0434\u043a\u0430 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c airplane airport arrival arriving flying landing transportation travel',
    FlightTakeoff:
        '\u0441\u0430\u043c\u043e\u043b\u0435\u0442 \u0430\u044d\u0440\u043e\u043f\u043e\u0440\u0442 \u0443\u0448\u0435\u043b \u043e\u0442\u044a\u0435\u0437\u0434 \u043b\u0435\u0442\u0430\u044e\u0449\u0438\u0439 \u043f\u043e\u0441\u0430\u0434\u043a\u0430 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c airplane airport departed departing flying landing transportation travel',
    Flip: '\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043e\u0440\u0438\u0435\u043d\u0442\u0430\u0446\u0438\u044f \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 editing image orientation scanning',
    FlipCameraAndroid:
        '\u0446\u0435\u043d\u0442\u0440 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0444\u0440\u043e\u043d\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043e\u0440\u0438\u0435\u043d\u0442\u0430\u0446\u0438\u044f \u0437\u0430\u0434\u043d\u0438\u0439 \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c center editing front image mobile orientation rear reverse rotate turn',
    FlipCameraIos:
        '\u0430\u043d\u0434\u0440\u043e\u0438\u0434 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0444\u0440\u043e\u043d\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043e\u0440\u0438\u0435\u043d\u0442\u0430\u0446\u0438\u044f \u0437\u0430\u0434\u043d\u0438\u0439 \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c android editing front image mobile orientation rear reverse rotate turn',
    FlipToBack:
        '\u0434\u043e\u0433\u043e\u0432\u043e\u0440\u0435\u043d\u043d\u043e\u0441\u0442\u044c \u0444\u043e\u0440\u043c\u0430\u0442 \u0444\u0440\u043e\u043d\u0442 \u043c\u0430\u043a\u0435\u0442 \u0448\u0430\u0433 \u0437\u0430\u043a\u0430\u0437 \u0421\u043e\u0440\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c arrangement format front layout move order sort',
    FlipToFront:
        '\u0434\u043e\u0433\u043e\u0432\u043e\u0440\u0435\u043d\u043d\u043e\u0441\u0442\u044c \u043d\u0430\u0437\u0430\u0434 \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u0448\u0430\u0433 \u0437\u0430\u043a\u0430\u0437 \u0421\u043e\u0440\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c arrangement back format layout move order sort',
    Flood: '\u043a\u0440\u0438\u0437\u0438\u0441 \u0441\u0442\u0438\u0445\u0438\u0439\u043d\u043e\u0435 \u0431\u0435\u0434\u0441\u0442\u0432\u0438\u0435 \u0435\u0441\u0442\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0434\u043e\u0436\u0434\u044c \u0431\u0443\u0440\u044f \u043f\u043e\u0433\u043e\u0434\u0430 crisis disaster natural rain storm weather',
    Flourescent:
        '\u044f\u0440\u043a\u0438\u0439 \u0444\u043e\u043d\u0430\u0440\u044c \u043b\u0430\u043c\u043f\u043e\u0447\u043a\u0430 bright lamp lightbulb',
    FlutterDash:
        '\u043f\u0442\u0438\u0446\u0430 \u0442\u0430\u043b\u0438\u0441\u043c\u0430\u043d bird mascot',
    FmdBad: '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert attention caution danger destination direction error exclamation important location maps mark notification pin place symbol warning',
    FmdGood:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 destination direction location maps pin place stop',
    Folder: '\u0434\u0430\u043d\u043d\u044b\u0435 \u043a\u0430\u0442\u0430\u043b\u043e\u0433 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 data directory document drive file folders sheet slide storage',
    FolderCopy:
        '\u0441\u043e\u0434\u0435\u0440\u0436\u0430\u043d\u0438\u0435 \u0440\u0435\u0437\u0430\u0442\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0434\u0443\u0431\u043b\u0438\u043a\u0430\u0442 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u0432\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 content cut data document drive duplicate file folders multiple paste sheet slide storage',
    FolderDelete:
        '\u043c\u0443\u0441\u043e\u0440\u043d\u043e\u0435 \u0432\u0435\u0434\u0440\u043e \u041c\u043e\u0436\u043d\u043e \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u043c\u0443\u0441\u043e\u0440 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u043c\u0443\u0441\u043e\u0440 bin can data document drive file folders garbage remove sheet slide storage trash',
    FolderOff:
        '[\u043e\u043d\u043b\u0430\u0439\u043d] \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u043b\u0438\u0441\u0442 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 [online] data disabled document drive enabled file folders sheet slash slide storage',
    FolderOpen:
        '\u0434\u0430\u043d\u043d\u044b\u0435 \u043a\u0430\u0442\u0430\u043b\u043e\u0433 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 data directory document drive file folders sheet slide storage',
    FolderShared:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0441\u043e\u0442\u0440\u0443\u0434\u043d\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0434\u0430\u043d\u043d\u044b\u0435 \u043a\u0430\u0442\u0430\u043b\u043e\u0433 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account collaboration data directory document drive face human people person profile sheet slide storage team user',
    FolderSpecial:
        '\u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u043a\u0430\u0442\u0430\u043b\u043e\u0433 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0444\u0430\u0439\u043b \u0432\u044b\u0434\u0435\u043b\u044f\u0442\u044c \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0447\u0435\u043d \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d \u0444\u043e\u0440\u043c\u0430 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0437\u0432\u0435\u0437\u0434\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 bookmark data directory document drive favorite file highlight important marked saved shape sheet slide star storage',
    FolderZip:
        '\u043a\u043e\u043c\u043f\u0440\u0435\u0441\u0441 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u043c \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 compress data document drive file folders open sheet slide storage',
    FollowTheSigns:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0442\u0435\u043b\u043e \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 arrow body directional human people person right social',
    FontDownload:
        '\u0410 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043f\u0438\u0441\u044c\u043c\u043e \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f A alphabet character letter square symbol text type',
    FontDownloadOff:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character disabled enabled letter slash square symbol text type',
    FoodBank:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0431\u043b\u0430\u0433\u043e\u0442\u0432\u043e\u0440\u0438\u0442\u0435\u043b\u044c\u043d\u0430\u044f \u0434\u0435\u044f\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0435\u0441\u0442\u044c \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0432\u0438\u043b\u043a\u0430 \u0434\u043e\u043c \u043d\u043e\u0436 \u0435\u0434\u0430 \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 \u043f\u043e\u0441\u0443\u0434\u0430 architecture building charity eat estate fork house knife meal place real residence residential shelter utensils',
    Forest: '\u0434\u0436\u0443\u043d\u0433\u043b\u0438 \u043f\u0440\u0438\u0440\u043e\u0434\u0430 \u043f\u043b\u0430\u043d\u0442\u0430\u0446\u0438\u044f \u0440\u0430\u0441\u0442\u0435\u043d\u0438\u044f \u0434\u0435\u0440\u0435\u0432\u044c\u044f \u043b\u0435\u0441\u0438\u0441\u0442\u0430\u044f \u043c\u0435\u0441\u0442\u043d\u043e\u0441\u0442\u044c jungle nature plantation plants trees woodland',
    ForkLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path route sign traffic',
    ForkRight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path route sign traffic',
    FormatAlignCenter:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043b\u0438\u043d\u0438\u0438 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment doc editing editor lines spreadsheet text type writing',
    FormatAlignJustify:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u043f\u043b\u043e\u0442\u043d\u043e\u0441\u0442\u044c \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0434\u043e\u043f\u043e\u043b\u043d\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u043b\u0438\u043d\u0438\u0438 \u043c\u0430\u043b\u0435\u043d\u044c\u043a\u0438\u0439 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment density doc editing editor extra lines small spreadsheet text type writing',
    FormatAlignLeft:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043b\u0438\u043d\u0438\u0438 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment doc editing editor lines spreadsheet text type writing',
    FormatAlignRight:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043b\u0438\u043d\u0438\u0438 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment doc editing editor lines spreadsheet text type writing',
    FormatBold:
        '\u0411 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u0438 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 B alphabet character doc editing editor font letter spreadsheet styles symbol text type writing',
    FormatClear:
        '\u0422 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 T alphabet character disabled doc editing editor enabled font letter off slash spreadsheet style symbol text type writing',
    FormatColorFill:
        '\u0432\u0435\u0434\u0440\u043e \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043a\u0440\u0430\u0441\u043a\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 bucket doc editing editor paint spreadsheet style text type writing',
    FormatColorReset:
        '\u0427\u0438\u0441\u0442\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u043a\u0430\u043f\u0435\u043b\u044c\u043a\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0430\u043f\u043e\u043b\u043d\u044f\u0442\u044c \u0436\u0438\u0434\u043a\u043e\u0441\u0442\u044c \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u043a\u0440\u0430\u0441\u043a\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u043e\u0434\u0430 \u043f\u0438\u0448\u0443 clear disabled doc droplet editing editor enabled fill liquid off on paint slash spreadsheet style text type water writing',
    FormatColorText:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043d\u0430\u043f\u043e\u043b\u043d\u044f\u0442\u044c \u043a\u0440\u0430\u0441\u043a\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0442\u0438\u043f \u043f\u0438\u0448\u0443 doc editing editor fill paint spreadsheet style type writing',
    FormatIndentDecrease:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043e\u0442\u0441\u0442\u0443\u043f \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment doc editing editor indentation paragraph spreadsheet text type writing',
    FormatIndentIncrease:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043e\u0442\u0441\u0442\u0443\u043f \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment doc editing editor indentation paragraph spreadsheet text type writing',
    FormatItalic:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alphabet character doc editing editor font letter spreadsheet style symbol text type writing',
    FormatLineSpacing:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment doc editing editor spreadsheet text type writing',
    FormatListBulleted:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0437\u0430\u043c\u0435\u0442\u043a\u0438 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0437\u0430\u0434\u0430\u0447\u0430 \u0442\u0435\u043a\u0441\u0442 \u0432\u0441\u0435 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment doc editing editor notes spreadsheet task text todo type writing',
    FormatListNumbered:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0446\u0438\u0444\u0440\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0437\u0430\u043c\u0435\u0442\u043a\u0438 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0437\u0430\u0434\u0430\u0447\u0430 \u0442\u0435\u043a\u0441\u0442 \u0432\u0441\u0435 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment digit doc editing editor notes spreadsheet symbol task text todo type writing',
    FormatListNumberedRtl:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0446\u0438\u0444\u0440\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0437\u0430\u043c\u0435\u0442\u043a\u0438 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0437\u0430\u0434\u0430\u0447\u0430 \u0442\u0435\u043a\u0441\u0442 \u0432\u0441\u0435 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment digit doc editing editor notes spreadsheet symbol task text todo type writing',
    FormatOverline:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u043e\u0434 \u043f\u0438\u0448\u0443 alphabet character doc editing editor font letter spreadsheet style symbol text type under writing',
    FormatPaint:
        '\u0449\u0435\u0442\u043a\u0430 \u0446\u0432\u0435\u0442 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043d\u0430\u043f\u043e\u043b\u043d\u044f\u0442\u044c \u043c\u0430\u043b\u044f\u0440\u043d\u044b\u0439 \u0432\u0430\u043b\u0438\u043a \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 brush color doc editing editor fill paintroller spreadsheet style text type writing',
    FormatQuote:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0446\u0438\u0442\u0430\u0442\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 doc editing editor quotation spreadsheet text type writing',
    FormatShapes:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0432\u0435\u0442 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043d\u0430\u043f\u043e\u043b\u043d\u044f\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u0440\u0430\u0441\u043a\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alphabet character color doc editing editor fill font letter paint spreadsheet style symbol text type writing',
    FormatSize:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0432\u0435\u0442 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043d\u0430\u043f\u043e\u043b\u043d\u044f\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u0440\u0430\u0441\u043a\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alphabet character color doc editing editor fill font letter paint spreadsheet style symbol text type writing',
    FormatStrikethrough:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alphabet character doc editing editor font letter spreadsheet style symbol text type writing',
    FormatTextdirectionLToR:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043b\u0442\u0440 \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment doc editing editor ltr paragraph spreadsheet type writing',
    FormatTextdirectionRToL:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u0440\u0442\u043b \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment doc editing editor paragraph rtl spreadsheet type writing',
    FormatUnderlined:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alphabet character doc editing editor font letter spreadsheet style symbol text type writing',
    Fort: '\u0437\u0430\u043c\u043e\u043a \u043a\u0440\u0435\u043f\u043e\u0441\u0442\u044c \u043e\u0441\u043e\u0431\u043d\u044f\u043a \u0434\u0432\u043e\u0440\u0435\u0446 castle fortress mansion palace',
    Forum: '\u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u0441\u043e\u043e\u0431\u0449\u0435\u0441\u0442\u0432\u043e \u0431\u0435\u0441\u0435\u0434\u0430 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0446\u0435\u043d\u0442\u0440 \u0421\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u044f \u0440\u0435\u0447\u044c \u0440\u0430\u0437\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c bubble chat comment communicate community conversation feedback hub messages speech talk',
    Forward:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043f\u043e\u0447\u0442\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0432\u043e\u0441\u043f\u0440\u043e\u0438\u0437\u0432\u0435\u0434\u0435\u043d\u0438\u0435 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u043f\u043e\u0441\u043b\u0430\u043b arrow mail message playback right sent',
    Forward10:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0440\u0443\u0433 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0446\u0438\u0444\u0440\u0430 \u0431\u044b\u0441\u0442\u0440\u043e \u041c\u0443\u0437\u044b\u043a\u0430 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0441\u0435\u043a\u0443\u043d\u0434\u044b \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0440\u0435\u043c\u044f \u0432\u0438\u0434\u0435\u043e arrow circle controls digit fast music number play rotate seconds speed symbol time video',
    Forward30:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0440\u0443\u0433 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0446\u0438\u0444\u0440\u0430 \u0431\u044b\u0441\u0442\u0440\u043e \u041c\u0443\u0437\u044b\u043a\u0430 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0441\u0435\u043a\u0443\u043d\u0434\u044b \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0440\u0435\u043c\u044f \u0432\u0438\u0434\u0435\u043e arrow circle controls digit fast music number rotate seconds speed symbol time video',
    Forward5:
        '10 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0440\u0443\u0433 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0446\u0438\u0444\u0440\u0430 \u0431\u044b\u0441\u0442\u0440\u043e \u041c\u0443\u0437\u044b\u043a\u0430 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0441\u0435\u043a\u0443\u043d\u0434\u044b \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0440\u0435\u043c\u044f \u0432\u0438\u0434\u0435\u043e 10 arrow circle controls digit fast music number rotate seconds speed symbol time video',
    ForwardToInbox:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043e\u043a\u0443\u0442\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c arrow email envelop letter message send',
    Foundation:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0431\u0430\u0437\u0430 \u043e\u0441\u043d\u043e\u0432\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u0434\u043e\u043c \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0416\u0438\u043b\u043e\u0439 architecture base basis building construction estate home house real residential',
    FourGMobiledata:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u0438\u0433\u043d\u0430\u043b \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f Wi-Fi alphabet cellular character digit font letter network number phone signal speed symbol text type wifi',
    FourGPlusMobiledata:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u0438\u0433\u043d\u0430\u043b \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f Wi-Fi alphabet cellular character digit font letter network number phone signal speed symbol text type wifi',
    FourK: '4000 4K \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e 4000 4K alphabet character digit display font letter number pixels resolution symbol text type video',
    FourKPlus:
        '+ 4000 4K \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e + 4000 4K alphabet character digit display font letter number pixels resolution symbol text type video',
    FourMp: '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digit font image letters megapixels number quality resolution symbol text type',
    FourteenMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    FreeBreakfast:
        '\u043d\u0430\u043f\u0438\u0442\u043e\u043a \u043a\u0430\u0444\u0435 \u043a\u043e\u0444\u0435 \u0447\u0430\u0448\u043a\u0430 \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u043a\u0440\u0443\u0436\u043a\u0430 \u0447\u0430\u0439 beverage cafe coffee cup drink mug tea',
    Fullscreen:
        '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u041f\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 adjust application components interface size ui ux view website',
    FullscreenExit:
        '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u041f\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 adjust application components interface size ui ux view website',
    Functions:
        '\u0441\u0440\u0435\u0434\u043d\u0438\u0439 \u0440\u0430\u0441\u0441\u0447\u0438\u0442\u0430\u0442\u044c \u0441\u0447\u0438\u0442\u0430\u0442\u044c \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043c\u0430\u0442\u0435\u043c\u0430\u0442\u0438\u043a\u0430 \u0441\u0438\u0433\u043c\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0441\u0443\u043c\u043c\u0430 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 average calculate count doc editing editor math sigma spreadsheet style sum text type writing',
    Gamepad:
        '\u043a\u043d\u043e\u043f\u043a\u0438 \u043f\u0440\u0438\u0441\u0442\u0430\u0432\u043a\u0430 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u043b\u0435\u0440 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u0433\u0440\u044b \u0438\u0433\u0440\u043e\u0432\u0430\u044f \u043f\u0440\u0438\u0441\u0442\u0430\u0432\u043a\u0430 \u0432\u0438\u0434\u0435\u043e buttons console controller device gaming playstation video',
    Games: '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u043b\u0435\u0440 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0440\u0435\u0441\u0442\u043e\u0432\u0438\u043d\u0430 \u0438\u0433\u0440\u044b \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u0448\u0430\u0433 \u043d\u0438\u043d\u0442\u0435\u043d\u0434\u043e \u0438\u0433\u0440\u043e\u0432\u0430\u044f \u043f\u0440\u0438\u0441\u0442\u0430\u0432\u043a\u0430 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e Xbox adjust arrows controller direction dpad gaming left move nintendo playstation right xbox',
    Garage: '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043c\u0430\u0448\u0438\u043d\u044b \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile automotive cars direction maps transportation travel vehicle',
    GasMeter:
        '\u043a\u0430\u043f\u0435\u043b\u044c\u043a\u0430 \u044d\u043d\u0435\u0440\u0433\u0438\u044f \u043c\u0435\u0440\u0430 \u0433\u043d\u0435\u0437\u0434\u043e \u041f\u0440\u0438\u043c\u0435\u043d\u0435\u043d\u0438\u0435 \u0432\u043e\u0434\u0430 droplet energy measure nest usage water',
    Gavel: '\u0441\u043e\u0433\u043b\u0430\u0448\u0435\u043d\u0438\u0435 \u0434\u043e\u0433\u043e\u0432\u043e\u0440 \u043a\u043e\u0440\u0442 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u043f\u0440\u0430\u0432\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u043c\u043e\u043b\u043e\u0442\u043e\u043a \u0441\u0443\u0434\u0438\u0442\u044c \u0437\u0430\u043a\u043e\u043d \u043c\u043e\u043b\u043e\u0442\u043e\u043a \u043e\u0444\u0438\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 \u043f\u043e\u043b\u0438\u0446\u0438\u044f \u043f\u0440\u0430\u0432\u0438\u043b\u0430 \u0443\u0441\u043b\u043e\u0432\u0438\u044f agreement contract court document government hammer judge law mallet official police rules terms',
    Gesture:
        '\u0420\u0438\u0441\u043e\u0432\u0430\u043d\u0438\u0435 \u041f\u0430\u043b\u0435\u0446 \u0436\u0435\u0441\u0442\u044b \u0440\u0443\u043a\u0430 \u043b\u0438\u043d\u0438\u044f \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 drawing finger gestures hand line motion',
    GetApp: '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0438 \u044d\u043a\u0441\u043f\u043e\u0440\u0442 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u0438\u0442\u044c \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0443\u043a\u0430\u0437\u044b\u0432\u0430\u044f \u0437\u0430\u0431\u0440\u0430\u0442\u044c \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c arrows downloads export install play pointing retrieve upload',
    Gif: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0430\u043d\u0438\u043c\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0439 \u0430\u043d\u0438\u043c\u0430\u0446\u0438\u044f \u0431\u0438\u0442\u043e\u0432\u0430\u044f \u043a\u0430\u0440\u0442\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u0444\u043e\u0440\u043c\u0430\u0442 \u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043e\u0431\u043c\u0435\u043d \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet animated animation bitmap character font format graphics interchange letter symbol text type',
    GifBox: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0430\u043d\u0438\u043c\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0439 \u0430\u043d\u0438\u043c\u0430\u0446\u0438\u044f \u0431\u0438\u0442\u043e\u0432\u0430\u044f \u043a\u0430\u0440\u0442\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u0444\u043e\u0440\u043c\u0430\u0442 \u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043e\u0431\u043c\u0435\u043d \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet animated animation bitmap character font format graphics interchange letter symbol text type',
    Girl: '\u0442\u0435\u043b\u043e \u0436\u0435\u043d\u0441\u043a\u0438\u0439 \u041f\u043e\u043b \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u0435\u0434\u0438 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0436\u0435\u043d\u0449\u0438\u043d\u0430 \u0436\u0435\u043d\u0449\u0438\u043d\u044b body female gender human lady people person social symbol woman women',
    Gite: '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u043e\u0431\u0449\u0435\u0436\u0438\u0442\u0438\u0435 \u0434\u043e\u043c \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043e\u0441\u0442\u0430\u0442\u044c\u0441\u044f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 architecture estate home hostel house maps place real residence residential stay traveling',
    GitHub: '\u043c\u0430\u0440\u043a\u0430 \u043a\u043e\u0434 brand code',
    GMobiledata:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character font letter network service symbol text type',
    GolfCourse:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u043c\u044f\u0447 \u043a\u043b\u0443\u0431 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0444\u043b\u0430\u0433 \u0438\u0433\u0440\u043e\u043a \u0432 \u0433\u043e\u043b\u044c\u0444 \u0438\u0433\u0440\u0430 \u0432 \u0433\u043e\u043b\u044c\u0444 \u0445\u043e\u0431\u0431\u0438 \u043e\u0442\u0432\u0435\u0440\u0441\u0442\u0438\u0435 \u043c\u0435\u0441\u0442\u0430 \u043f\u0430\u0442\u0442 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 athlete athletic ball club entertainment flag golfer golfing hobby hole places putt sports',
    Google: '\u043c\u0430\u0440\u043a\u0430 \u043b\u043e\u0433\u043e\u0442\u0438\u043f brand logo',
    GppBad: '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u044b\u0445\u043e\u0434 \u043d\u0435\u0442 \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0449\u0438\u0442 \u0441\u0438\u043c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e cancel certified close error exit no privacy private protection remove security shield sim stop verified',
    GppGood:
        '\u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 Ok \u043f\u0440\u043e\u0445\u043e\u0434\u0438\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0449\u0438\u0442 \u0441\u0438\u043c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 certified check ok pass security shield sim tick',
    GppMaybe:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0449\u0438\u0442 \u0441\u0438\u043c \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert attention caution certified danger error exclamation important mark notification privacy private protection security shield sim symbol verified warning',
    GpsFixed:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u0443\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 destination direction location maps pin place pointer stop tracking',
    GpsNotFixed:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u0441\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043e \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043d\u0435\u0442 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043e\u043d\u043b\u0430\u0439\u043d \u043c\u0435\u0441\u0442\u043e \u0443\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 destination direction disabled enabled fixed location maps not off online place pointer slash tracking',
    GpsOff: '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u0441\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043e \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043d\u0435\u0442 \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043c\u0435\u0441\u0442\u043e \u0443\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 destination direction disabled enabled fixed location maps not offline place pointer slash tracking',
    Grade: '\u0434\u043e\u0441\u0442\u0438\u0436\u0435\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043d\u0440\u0430\u0432\u0438\u0442\u0441\u044f \u043e\u0442\u043c\u0435\u0447\u0435\u043d \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u043d\u0430\u0433\u0440\u0430\u0434\u0430 \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d \u0444\u043e\u0440\u043c\u0430 \u0441\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 \u0437\u0432\u0435\u0437\u0434\u0430 achievement important likes marked rated rating reward saved shape special star',
    Gradient:
        '\u0446\u0432\u0435\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0444\u0438\u043b\u044c\u0442\u0440 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 color editing effect filter images photography pictures',
    Grading:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u043e\u0446\u0435\u043d\u043a\u0430 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u0440\u0430\u0441\u0441\u043c\u043e\u0442\u0440\u0435\u043d\u043e \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u043f\u0438\u0448\u0443 \u0434\u0430 approve check complete document done feedback grade mark ok reviewed select tick validate verified writing yes',
    Grain: '\u0442\u043e\u0447\u043a\u0438 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0444\u0438\u043b\u044c\u0442\u0440 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 dots editing effect filter images photography pictures',
    GraphicEq:
        '\u0430\u0443\u0434\u0438\u043e \u044d\u043a\u0432\u0430\u043b\u0430\u0439\u0437\u0435\u0440 \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0430\u043f\u0438\u0441\u044c \u0437\u0432\u0443\u043a \u0433\u043e\u043b\u043e\u0441 audio equalizer music recording sound voice',
    Grass: '\u0437\u0430\u0434\u043d\u0438\u0439 \u0434\u0432\u043e\u0440 \u043a\u043e\u0440\u043c \u0437\u0435\u043c\u043b\u044f \u0434\u043e\u043c \u043b\u0443\u0436\u0430\u0439\u043a\u0430 \u0437\u0430\u0432\u043e\u0434 \u0433\u0430\u0437\u043e\u043d backyard fodder ground home lawn plant turf',
    Grid3x3:
        '\u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u044f \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e layout line space',
    Grid4x4:
        '\u043f\u043e \u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e by layout lines space',
    GridGoldenratio:
        '\u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e layout lines space',
    GridOff:
        '\u043a\u043e\u043b\u043b\u0430\u0436 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043c\u0430\u043a\u0435\u0442 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u041f\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c collage disabled enabled image layout on slash view',
    GridOn: '\u043a\u043e\u043b\u043b\u0430\u0436 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043c\u0430\u043a\u0435\u0442 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043b\u0438\u0441\u0442 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u041f\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c collage disabled enabled image layout off sheet slash view',
    GridView:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0431\u043b\u043e\u043a\u0438 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043f\u0440\u0438\u0431\u043e\u0440\u043d\u0430\u044f \u0434\u043e\u0441\u043a\u0430 \u0434\u0438\u0437\u0430\u0439\u043d \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043c\u0430\u043a\u0435\u0442 \u044d\u043a\u0440\u0430\u043d \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u043f\u043b\u0438\u0442\u043a\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e application blocks components dashboard design interface layout screen square tiles ui ux website window',
    Group: '\u0443\u0447\u0435\u0442\u043d\u044b\u0435 \u0437\u0430\u043f\u0438\u0441\u0438 \u043a\u043e\u043c\u0438\u0442\u0435\u0442 \u043b\u0438\u0446\u043e \u0441\u0435\u043c\u044c\u044f \u0434\u0440\u0443\u0437\u044c\u044f \u043b\u044e\u0434\u0438 \u0441\u0435\u0442\u044c \u043b\u044e\u0434\u0438 \u043b\u0438\u0446\u0430 \u043f\u0440\u043e\u0444\u0438\u043b\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438 accounts committee face family friends humans network people persons profiles social team users',
    GroupAdd:
        '\u0443\u0447\u0435\u0442\u043d\u044b\u0435 \u0437\u0430\u043f\u0438\u0441\u0438 \u043a\u043e\u043c\u0438\u0442\u0435\u0442 \u043b\u0438\u0446\u043e \u0441\u0435\u043c\u044c\u044f \u0434\u0440\u0443\u0437\u044c\u044f \u043b\u044e\u0434\u0438 \u0443\u0432\u0435\u043b\u0438\u0447\u0438\u0432\u0430\u0442\u044c \u0431\u043e\u043b\u0435\u0435 \u0441\u0435\u0442\u044c \u043b\u044e\u0434\u0438 \u043b\u0438\u0446\u0430 \u043f\u043b\u044e\u0441 \u043f\u0440\u043e\u0444\u0438\u043b\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438 accounts committee face family friends humans increase more network people persons plus profiles social team users',
    GroupRemove:
        '\u0443\u0447\u0435\u0442\u043d\u044b\u0435 \u0437\u0430\u043f\u0438\u0441\u0438 \u043a\u043e\u043c\u0438\u0442\u0435\u0442 \u043b\u0438\u0446\u043e \u0441\u0435\u043c\u044c\u044f \u0434\u0440\u0443\u0437\u044c\u044f \u043b\u044e\u0434\u0438 \u0441\u0435\u0442\u044c \u043b\u044e\u0434\u0438 \u043b\u0438\u0446\u0430 \u043f\u0440\u043e\u0444\u0438\u043b\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438 accounts committee face family friends humans network people persons profiles social team users',
    Groups: '\u0442\u0435\u043b\u043e \u043a\u043b\u0443\u0431 \u0441\u043e\u0442\u0440\u0443\u0434\u043d\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0442\u043e\u043b\u043f\u0430 \u0432\u0441\u0442\u0440\u0435\u0447\u0430 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0432\u0441\u0442\u0440\u0435\u0447\u0430 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043a\u043e\u043c\u0430\u043d\u0434\u044b body club collaboration crowd gathering human meeting people person social teams',
    GroupWork:
        '\u0430\u043b\u044c\u044f\u043d\u0441 \u043a\u0440\u0443\u0433 \u0441\u043e\u0442\u0440\u0443\u0434\u043d\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u0438\u043b\u044c\u043c \u043f\u0430\u0440\u0442\u043d\u0435\u0440\u0441\u0442\u0432\u043e \u043a\u0430\u0442\u0443\u0448\u043a\u0430 \u043a\u043e\u043c\u0430\u043d\u0434\u043d\u0430\u044f \u0440\u0430\u0431\u043e\u0442\u0430 \u0432\u043c\u0435\u0441\u0442\u0435 alliance circle collaboration film partnership reel teamwork together',
    GTranslate:
        '\u044d\u043c\u0431\u043b\u0435\u043c\u0430 Google \u044f\u0437\u044b\u043a \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0413\u043e\u0432\u043e\u0440\u044f\u0449\u0438\u0439 \u0440\u0435\u0447\u044c \u043f\u0435\u0440\u0435\u0432\u043e\u0434\u0447\u0438\u043a \u0441\u043b\u043e\u0432\u0430 emblem google language logo mark speaking speech translator words',
    Hail: '\u0442\u0435\u043b\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0432\u044b\u0431\u0438\u0440\u0430\u0442\u044c \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0442\u0430\u043a\u0441\u0438 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 body human people person pick public stop taxi transportation',
    Handshake:
        '\u0441\u043e\u0433\u043b\u0430\u0448\u0435\u043d\u0438\u0435 \u043f\u0430\u0440\u0442\u043d\u0435\u0440\u0441\u0442\u0432\u043e agreement partnership',
    Handyman:
        '\u0441\u0442\u0440\u043e\u0438\u0442\u044c \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0438\u0441\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043c\u043e\u043b\u043e\u0442\u043e\u043a \u0440\u0435\u043c\u043e\u043d\u0442 \u043e\u0442\u0432\u0435\u0440\u0442\u043a\u0430 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u044b build construction fix hammer repair screwdriver tools',
    Hardware:
        '\u043b\u043e\u043c\u0430\u0442\u044c \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u043c\u043e\u043b\u043e\u0442\u043e\u043a \u043d\u043e\u0433\u043e\u0442\u044c \u0440\u0435\u043c\u043e\u043d\u0442 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 break construction hammer nail repair tool',
    Hd: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u0438\u0435 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u043f\u0438\u0441\u044c\u043c\u043e \u0444\u0438\u043b\u044c\u043c\u044b \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u044d\u043a\u0440\u0430\u043d \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e alphabet character definition display font high letter movies quality resolution screen symbol text tv type video',
    HdrAuto:
        '\u0410 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043a\u0430\u043c\u0435\u0440\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u0440\u0443\u0433 \u0434\u0438\u043d\u0430\u043c\u0438\u0447\u043d\u044b\u0439 \u0448\u0440\u0438\u0444\u0442 \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u043f\u0438\u0441\u044c\u043c\u043e \u0424\u043e\u0442\u043e \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f A alphabet camera character circle dynamic font high letter photo range symbol text type',
    HdrAutoSelect:
        '+ \u0410 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043a\u0430\u043c\u0435\u0440\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u0440\u0443\u0433 \u0434\u0438\u043d\u0430\u043c\u0438\u0447\u043d\u044b\u0439 \u0448\u0440\u0438\u0444\u0442 \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u043f\u0438\u0441\u044c\u043c\u043e \u0424\u043e\u0442\u043e \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f + A alphabet camera character circle dynamic font high letter photo range symbol text type',
    HdrEnhancedSelect:
        '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u0438\u043d\u0430\u043c\u0438\u0447\u043d\u044b\u0439 \u0448\u0440\u0438\u0444\u0442 \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u043f\u0438\u0441\u044c\u043c\u043e \u043f\u043b\u044e\u0441 \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f add alphabet character dynamic font high letter plus range symbol text type',
    HdrOff: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0434\u0438\u043d\u0430\u043c\u0438\u0447\u043d\u044b\u0439 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u043f\u0438\u0441\u044c\u043c\u043e \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character disabled dynamic enabled enhance font high letter range select slash symbol text type',
    HdrOffSelect:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043a\u0430\u043c\u0435\u0440\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u0440\u0443\u0433 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0434\u0438\u043d\u0430\u043c\u0438\u0447\u043d\u044b\u0439 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0448\u0440\u0438\u0444\u0442 \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u043f\u0438\u0441\u044c\u043c\u043e \u0424\u043e\u0442\u043e \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet camera character circle disabled dynamic enabled font high letter photo range slash symbol text type',
    HdrOn: '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u0438\u043d\u0430\u043c\u0438\u0447\u043d\u044b\u0439 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u043f\u0438\u0441\u044c\u043c\u043e \u043f\u043b\u044e\u0441 \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f add alphabet character dynamic enhance font high letter plus range select symbol text type',
    HdrOnSelect:
        '+ \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043a\u0430\u043c\u0435\u0440\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u0440\u0443\u0433 \u0434\u0438\u043d\u0430\u043c\u0438\u0447\u043d\u044b\u0439 \u0448\u0440\u0438\u0444\u0442 \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u043f\u0438\u0441\u044c\u043c\u043e \u0424\u043e\u0442\u043e \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f + alphabet camera character circle dynamic font high letter photo range symbol text type',
    HdrPlus:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u0440\u0443\u0433 \u0434\u0438\u043d\u0430\u043c\u0438\u0447\u043d\u044b\u0439 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u043f\u0438\u0441\u044c\u043c\u043e \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f + add alphabet character circle dynamic enhance font high letter range select symbol text type',
    HdrStrong:
        '\u043a\u0440\u0443\u0433\u0438 \u0442\u043e\u0447\u043a\u0438 \u0434\u0438\u043d\u0430\u043c\u0438\u0447\u043d\u044b\u0439 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d circles dots dynamic enhance high range',
    HdrWeak:
        '\u043a\u0440\u0443\u0433\u0438 \u0442\u043e\u0447\u043a\u0438 \u0434\u0438\u043d\u0430\u043c\u0438\u0447\u043d\u044b\u0439 \u0443\u0441\u0438\u043b\u0438\u0432\u0430\u0442\u044c \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d circles dots dynamic enhance high range',
    Headphones:
        '\u0430\u043a\u0441\u0435\u0441\u0441\u0443\u0430\u0440 \u0430\u0443\u0434\u0438\u043e \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u0433\u0430\u0440\u043d\u0438\u0442\u0443\u0440\u0430 \u0421\u043b\u0443\u0448\u0430\u0442\u044c \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0432\u0443\u043a accessory audio device earphone headset listen music sound',
    HeadphonesBattery:
        '\u0430\u043a\u0441\u0435\u0441\u0441\u0443\u0430\u0440 \u0430\u0443\u0434\u0438\u043e \u0437\u0430\u0440\u044f\u0434\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u0433\u0430\u0440\u043d\u0438\u0442\u0443\u0440\u0430 \u0421\u043b\u0443\u0448\u0430\u0442\u044c \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0432\u0443\u043a accessory audio charging device earphone headset listen music sound',
    Headset:
        '\u0430\u043a\u0441\u0435\u0441\u0441\u0443\u0430\u0440 \u0430\u0443\u0434\u0438\u043e \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u0421\u043b\u0443\u0448\u0430\u0442\u044c \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0432\u0443\u043a accessory audio device earbuds earmuffs earphone headphones listen music sound',
    HeadsetMic:
        '\u0430\u043a\u0441\u0435\u0441\u0441\u0443\u0430\u0440 \u0430\u0443\u0434\u0438\u043e \u0447\u0430\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u0421\u043b\u0443\u0448\u0430\u0442\u044c \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0432\u0443\u043a \u0440\u0430\u0437\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c accessory audio chat device earphone headphones listen music sound talk',
    HeadsetOff:
        '\u0430\u043a\u0441\u0435\u0441\u0441\u0443\u0430\u0440 \u0430\u0443\u0434\u0438\u043e \u0447\u0430\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u0421\u043b\u0443\u0448\u0430\u0442\u044c \u043c\u0438\u043a\u0440\u043e\u0444\u043e\u043d \u041c\u0443\u0437\u044b\u043a\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0432\u0443\u043a \u0440\u0430\u0437\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c accessory audio chat device disabled earphone enabled headphones listen mic music slash sound talk',
    Healing:
        '\u043f\u043e\u0432\u044f\u0437\u043a\u0430 \u043f\u043e\u0432\u044f\u0437\u043a\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0447\u0440\u0435\u0437\u0432\u044b\u0447\u0430\u0439\u043d\u0430\u044f \u0441\u0438\u0442\u0443\u0430\u0446\u0438\u044f \u0438\u0441\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u0437\u0434\u043e\u0440\u043e\u0432\u044c\u0435 \u0431\u043e\u043b\u044c\u043d\u0438\u0446\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043b\u0435\u043a\u0430\u0440\u0441\u0442\u0432\u0435\u043d\u043d\u043e\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e bandage bandaid editing emergency fix health hospital image medicine',
    HealthAndSafety:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u043f\u043b\u044e\u0441 \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0449\u0438\u0442 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e + add certified plus privacy private protection security shield symbol verified',
    Hearing:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u043e\u0441\u0442\u044c \u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b\u0439 \u043f\u043e\u043c\u043e\u0433\u0430\u0442\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u043c\u043e\u0449\u044c \u043e\u0441\u043b\u0430\u0431\u043b\u0435\u043d\u043d\u044b\u0439 \u0421\u043b\u0443\u0448\u0430\u0442\u044c \u0437\u0432\u0443\u043a \u043e\u0431\u044a\u0435\u043c accessibility accessible aid handicap help impaired listen sound volume',
    HearingDisabled:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u043e\u0441\u0442\u044c \u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b\u0439 \u043f\u043e\u043c\u043e\u0433\u0430\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u043c\u043e\u0449\u044c \u043e\u0441\u043b\u0430\u0431\u043b\u0435\u043d\u043d\u044b\u0439 \u0421\u043b\u0443\u0448\u0430\u0442\u044c \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0432\u0443\u043a \u043e\u0431\u044a\u0435\u043c accessibility accessible aid enabled handicap help impaired listen off on slash sound volume',
    HeartBroken:
        '\u043b\u043e\u043c\u0430\u0442\u044c \u043e\u0441\u043d\u043e\u0432\u043d\u043e\u0439 \u0440\u0430\u0437\u0434\u0430\u0432\u0438\u0442\u044c \u0437\u0434\u043e\u0440\u043e\u0432\u044c\u0435 \u044f\u0434\u0440\u043e \u0440\u0430\u0441\u043a\u043e\u043b\u043e\u0442\u044c break core crush health nucleus split',
    HeatPump:
        '\u0432\u043e\u0437\u0434\u0443\u0445\u0430 \u043a\u043e\u043d\u0434\u0438\u0446\u0438\u043e\u043d\u0435\u0440 \u041a\u0440\u0443\u0442\u043e \u044d\u043d\u0435\u0440\u0433\u0438\u044f \u043f\u0435\u0447\u044c \u0433\u043d\u0435\u0437\u0434\u043e \u041f\u0440\u0438\u043c\u0435\u043d\u0435\u043d\u0438\u0435 air conditioner cool energy furnance nest usage',
    Height: '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0446\u0432\u0435\u0442 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043d\u0438\u0437 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043d\u0430\u043f\u043e\u043b\u043d\u044f\u0442\u044c \u0444\u043e\u0440\u043c\u0430\u0442 \u043a\u0440\u0430\u0441\u043a\u0430 \u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0440\u0430\u0437\u043c\u0435\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u043f\u0440\u043e\u0442\u044f\u0436\u0435\u043d\u0438\u0435 \u0441\u0442\u0438\u043b\u044c \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0432\u0435\u0440\u0445 \u043f\u0438\u0448\u0443 arrows color doc down editing editor fill format paint resize spreadsheet stretch style text type up writing',
    Help: '? \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043f\u043e\u043c\u043e\u0449\u044c \u043a\u0440\u0443\u0433 \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043f\u0443\u043d\u043a\u0442\u0443\u0430\u0446\u0438\u044f \u0432\u043e\u043f\u0440\u043e\u0441 \u0444\u043e\u0440\u043c\u0430 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b ? alert announcement assistance circle information mark punctuation question shape support symbol',
    HelpCenter:
        '? \u043f\u043e\u043c\u043e\u0449\u044c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043f\u0443\u043d\u043a\u0442\u0443\u0430\u0446\u0438\u044f \u0432\u043e\u043f\u0440\u043e\u0441 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b ? assistance information mark punctuation question support symbol',
    HelpOutline:
        '? \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043f\u043e\u043c\u043e\u0449\u044c \u043a\u0440\u0443\u0433 \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043f\u0443\u043d\u043a\u0442\u0443\u0430\u0446\u0438\u044f \u0432\u043e\u043f\u0440\u043e\u0441 \u0444\u043e\u0440\u043c\u0430 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b ? alert announcement assistance circle information mark punctuation question shape support symbol',
    Hevc: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u043e\u0434\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442\u0438\u0432\u043d\u043e\u0441\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e alphabet character coding efficiency font high letter symbol text type video',
    Hexagon:
        '\u0444\u043e\u0440\u043c\u0430 \u0441\u0442\u043e\u0440\u043e\u043d\u044b \u0448\u0435\u0441\u0442\u044c shape sides six',
    HideImage:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled enabled landscape mountains off on photography picture slash',
    HideSource:
        '\u043a\u0440\u0443\u0433 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043d\u0430 \u0444\u043e\u0440\u043c\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 circle disabled enabled offline on shape slash',
    Highlight:
        '\u0446\u0432\u0435\u0442 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043f\u043e\u0434\u0447\u0435\u0440\u043a\u0438\u0432\u0430\u0442\u044c \u043d\u0430\u043f\u043e\u043b\u043d\u044f\u0442\u044c \u0444\u043e\u043d\u0430\u0440\u0438\u043a \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u0440\u043a\u0435\u0440 \u043a\u0440\u0430\u0441\u043a\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 color doc editing editor emphasize fill flashlight format marker paint spreadsheet style text type writing',
    HighlightAlt:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u043d\u0430\u0436\u043c\u0438\u0442\u0435 \u043a\u0443\u0440\u0441\u043e\u0440 \u0440\u0438\u0441\u043e\u0432\u0430\u0442\u044c \u0444\u043e\u043a\u0443\u0441 \u0443\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u044c \u0432\u044b\u0431\u043e\u0440 \u0446\u0435\u043b\u044c arrow box click cursor draw focus pointer selection target',
    HighlightOff:
        '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u043a\u0440\u0443\u0433 \u0427\u0438\u0441\u0442\u043e \u043d\u0430\u0436\u043c\u0438\u0442\u0435 \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u0432\u044b\u0445\u043e\u0434 \u0444\u043e\u043a\u0443\u0441 \u043d\u0435\u0442 \u043f\u043e\u043a\u0438\u0434\u0430\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0446\u0435\u043b\u044c \u0440\u0430\u0437 cancel circle clear click close delete disable exit focus no quit remove stop target times',
    HighQuality:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u0438\u0435 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u0448\u0442\u0430\u0431-\u043a\u0432\u0430\u0440\u0442\u0438\u0440\u0430 \u043f\u0438\u0441\u044c\u043c\u043e \u0444\u0438\u043b\u044c\u043c\u044b \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u044d\u043a\u0440\u0430\u043d \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0442\u0438\u043f alphabet character definition display font hq letter movies resolution screen symbol text tv type',
    Hiking: '\u043f\u0435\u0448\u0438\u0439 \u0442\u0443\u0440\u0438\u0437\u043c \u0441\u0443\u043c\u043a\u0430 \u0430\u043b\u044c\u043f\u0438\u043d\u0438\u0437\u043c \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043a\u043e\u0441\u0442\u044e\u043c \u0433\u043e\u0440\u0430 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043f\u0430\u043b\u043a\u0430 \u0442\u0430\u0449\u0438\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0445\u043e\u0434\u044c\u0431\u0430 backpacking bag climbing duffle mountain social sports stick trail travel walking',
    History:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u043e\u0437\u0432\u0440\u0430\u0449\u0430\u0442\u044c\u0441\u044f \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c arrow backwards clock date refresh renew reverse revert rotate schedule time turn undo',
    HistoryEdu:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u0435 \u043f\u0443\u0445 \u041f\u0435\u0440\u043e \u043f\u0438\u0441\u044c\u043c\u043e \u0431\u0443\u043c\u0430\u0433\u0430 \u0440\u0443\u0447\u043a\u0430 \u043f\u0435\u0440\u043e \u0448\u043a\u043e\u043b\u0430 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u044b \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0448\u0443 document education feather letter paper pen quill school tools write writing',
    HistoryToggleOff:
        '\u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f clock date schedule time',
    Hive: '\u043f\u0447\u0435\u043b\u0430 \u0441\u043e\u0442\u044b bee honeycomb',
    Hls: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a \u0438\u043d\u0436\u0435\u043d\u0435\u0440\u0438\u044f \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u041f\u043b\u0430\u0442\u0444\u043e\u0440\u043c\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character developer engineering font letter platform symbol text type',
    HlsOff: '[\u043d\u0435 \u0432 \u0441\u0435\u0442\u0438] \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u043d\u0436\u0435\u043d\u0435\u0440\u0438\u044f \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u041f\u043b\u0430\u0442\u0444\u043e\u0440\u043c\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f [offline] alphabet character developer disabled enabled engineering font letter platform slash symbol text type',
    HMobiledata:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character font letter network service symbol text type',
    HolidayVillage:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u043f\u043b\u044f\u0436 \u043f\u043e\u0445\u043e\u0434 \u043a\u043e\u0442\u0442\u0435\u0434\u0436 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u0434\u043e\u043c \u043e\u0437\u0435\u0440\u043e \u043b\u043e\u0436\u0430 \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043e\u0441\u0442\u0430\u0442\u044c\u0441\u044f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 \u043e\u0442\u043f\u0443\u0441\u043a architecture beach camping cottage estate home house lake lodge maps place real residence residential stay traveling vacation',
    Home: '\u0430\u0434\u0440\u0435\u0441 \u043f\u0440\u0438\u043b\u043e\u0436\u0435\u043d\u0438\u0435-\u0434\u043e\u043c \u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c\u0430\u0448\u043d\u044f\u044f \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043c\u0430\u043a\u0435\u0442 \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u044d\u043a\u0440\u0430\u043d \u043f\u0440\u0438\u044e\u0442 \u0441\u0442\u0440\u0443\u043a\u0442\u0443\u0440\u0430 \u0415\u0434. \u0438\u0437\u043c \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e address application--house architecture building components design estate homepage interface layout place real residence residential screen shelter structure unit ux website window',
    HomeMax:
        '\u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0433\u0430\u0434\u0436\u0435\u0442 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0439\u043e\u0442 \u0433\u043d\u0435\u0437\u0434\u043e \u0443\u043c\u043d\u0430\u044f \u0432\u0435\u0449\u0438 device gadget hardware internet iot nest smart things',
    HomeMini:
        '\u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0433\u0430\u0434\u0436\u0435\u0442 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0439\u043e\u0442 \u0433\u043d\u0435\u0437\u0434\u043e \u0443\u043c\u043d\u0430\u044f \u0432\u0435\u0449\u0438 Internet device gadget hardware iot nest smart things',
    HomeRepairService:
        '\u043e\u0431\u043e\u0440\u0443\u0434\u043e\u0432\u0430\u043d\u0438\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u041a\u043e\u043c\u043f\u043b\u0435\u043a\u0442 \u043c\u0435\u0445\u0430\u043d\u0438\u043a \u0440\u0435\u043c\u043e\u043d\u0442 \u044f\u0449\u0438\u043a \u0434\u043b\u044f \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u043e\u0432 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u044b \u0446\u0435\u0445 equipment fix kit mechanic repairing toolbox tools workshop',
    HomeWork:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u043e\u0444\u0438\u0441 \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 architecture building estate house office place real residence residential shelter',
    HorizontalRule:
        'Gmail \u043b\u0438\u043d\u0438\u044f \u043d\u043e\u0432\u0438\u0437\u043d\u0430 gmail line novitas',
    HorizontalSplit:
        '\u0431\u0430\u0440\u044b \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u0441\u043b\u043e\u0436\u0435\u043d\u043d\u044b\u0439 bars format layout lines stacked',
    Hotel: '\u043a\u0440\u043e\u0432\u0430\u0442\u044c \u0442\u0435\u043b\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u043f\u0430\u0442\u044c \u043e\u0441\u0442\u0430\u0442\u044c\u0441\u044f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 bed body human people person sleep stay travel trip',
    HotTub: '\u043a\u0443\u043f\u0430\u043d\u0438\u0435 \u0432\u0430\u043d\u043d\u0430\u044f \u043a\u043e\u043c\u043d\u0430\u0442\u0430 \u0432\u0430\u043d\u043d\u0430 \u043e\u0442\u0435\u043b\u044c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0434\u0436\u0430\u043a\u0443\u0437\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0434\u0443\u0448 \u0441\u043f\u0430 \u0433\u043e\u0442\u043e\u0432\u0438\u0442\u044c \u043d\u0430 \u043f\u0430\u0440\u0443 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0432\u043e\u0434\u0430 bathing bathroom bathtub hotel human jacuzzi person shower spa steam travel water',
    HourglassBottom:
        '\u043e\u0431\u0440\u0430\u0442\u043d\u044b\u0439 \u043e\u0442\u0441\u0447\u0435\u0442 \u043f\u043e\u043b\u043e\u0432\u0438\u043d\u0430 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043c\u0438\u043d\u0443\u0442\u044b \u0432\u0440\u0435\u043c\u044f \u043e\u0436\u0438\u0434\u0430\u044e\u0449\u0438\u0439 countdown half loading minutes time waiting',
    HourglassDisabled:
        '\u0427\u0430\u0441\u044b \u043e\u0431\u0440\u0430\u0442\u043d\u044b\u0439 \u043e\u0442\u0441\u0447\u0435\u0442 \u043f\u0443\u0441\u0442\u043e\u0439 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043c\u0438\u043d\u0443\u0442\u044b \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0432\u0440\u0435\u043c\u044f \u043e\u0436\u0438\u0434\u0430\u044e\u0449\u0438\u0439 clock countdown empty enabled loading minutes off on slash time waiting',
    HourglassEmpty:
        '\u043e\u0431\u0440\u0430\u0442\u043d\u044b\u0439 \u043e\u0442\u0441\u0447\u0435\u0442 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043c\u0438\u043d\u0443\u0442\u044b \u041d\u0430\u0447\u0430\u043b\u043e \u0432\u0440\u0435\u043c\u044f \u043e\u0436\u0438\u0434\u0430\u044e\u0449\u0438\u0439 countdown loading minutes start time waiting',
    HourglassFull:
        '\u043e\u0431\u0440\u0430\u0442\u043d\u044b\u0439 \u043e\u0442\u0441\u0447\u0435\u0442 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043c\u0438\u043d\u0443\u0442\u044b \u0432\u0440\u0435\u043c\u044f \u043e\u0436\u0438\u0434\u0430\u044e\u0449\u0438\u0439 countdown loading minutes time waiting',
    HourglassTop:
        '\u043e\u0431\u0440\u0430\u0442\u043d\u044b\u0439 \u043e\u0442\u0441\u0447\u0435\u0442 \u043f\u043e\u043b\u043e\u0432\u0438\u043d\u0430 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043c\u0438\u043d\u0443\u0442\u044b \u0432\u0440\u0435\u043c\u044f \u043e\u0436\u0438\u0434\u0430\u044e\u0449\u0438\u0439 countdown half loading minutes time waiting',
    House: '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0441\u0435\u043c\u044c\u044f \u0434\u043e\u043c\u0430\u0448\u043d\u044f\u044f \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u043c\u0435\u0441\u0442\u0430 \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 architecture building estate family homepage places real residence residential shelter',
    Houseboat:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u043f\u043b\u044f\u0436 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u043f\u043b\u0430\u0432\u0430\u044e\u0449\u0438\u0439 \u0434\u043e\u043c \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043c\u043e\u0440\u0435 \u043e\u0441\u0442\u0430\u0442\u044c\u0441\u044f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 \u043e\u0442\u043f\u0443\u0441\u043a architecture beach estate floating home maps place real residence residential sea stay traveling vacation',
    HouseSiding:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u044d\u043a\u0441\u0442\u0435\u0440\u044c\u0435\u0440 \u0444\u0430\u0441\u0430\u0434 \u0434\u043e\u043c \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0416\u0438\u043b\u043e\u0439 architecture building construction estate exterior facade home real residential',
    HowToReg:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0433\u043e\u043b\u043e\u0441\u043e\u0432\u0430\u043d\u0438\u0435 \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0432\u044b\u0431\u043e\u0440\u044b \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u043e\u043f\u0440\u043e\u0441 \u0440\u0435\u0433\u0438\u0441\u0442\u0440 \u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u044f \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043a \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0433\u043e\u043b\u043e\u0441\u043e\u0432\u0430\u043d\u0438\u0435 \u0434\u0430 approve ballot check complete done election mark ok poll register registration select tick to validate verified vote yes',
    HowToVote:
        '\u0433\u043e\u043b\u043e\u0441\u043e\u0432\u0430\u043d\u0438\u0435 \u0432\u044b\u0431\u043e\u0440\u044b \u043e\u043f\u0440\u043e\u0441 ballot election poll',
    HPlusMobiledata:
        '+ \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f + alphabet character font letter network service symbol text type',
    Html: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u043a\u043e\u0431\u043a\u0438 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u043e\u0434 css \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a \u0438\u043d\u0436\u0435\u043d\u0435\u0440\u0438\u044f \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u041f\u043b\u0430\u0442\u0444\u043e\u0440\u043c\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet brackets character code css developer engineering font letter platform symbol text type',
    Http: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u043f\u0435\u0440\u0435\u0434\u0430\u0447\u0430 \u0442\u0438\u043f URL \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 alphabet character font internet letter network symbol text transfer type url website',
    Https: '\u0441\u0432\u044f\u0437\u044c \u0448\u0438\u0444\u0440\u043e\u0432\u0430\u0442\u044c \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043a\u043b\u044e\u0447 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u0441\u0435\u0442\u044c \u043f\u0430\u0440\u043e\u043b\u044c \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c ssl \u0441\u0435\u0442\u044c connection encrypt internet key locked network password privacy private protection safety secure security ssl web',
    Hub: '\u0446\u0435\u043d\u0442\u0440 \u0441\u0432\u044f\u0437\u044c \u043e\u0441\u043d\u043e\u0432\u043d\u043e\u0439 \u0444\u043e\u043a\u0430\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u044f\u0434\u0440\u043e \u0442\u043e\u0447\u043a\u0430 \u0442\u043e\u043f\u043e\u043b\u043e\u0433\u0438\u044f center connection core focal network nucleus point topology',
    Hvac: '\u0432\u043e\u0437\u0434\u0443\u0445\u0430 \u043a\u043e\u043d\u0434\u0438\u0446\u0438\u043e\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0431\u043e\u0433\u0440\u0435\u0432 \u0432\u0435\u043d\u0442\u0438\u043b\u044f\u0446\u0438\u044f air conditioning heating ventilation',
    Icecream:
        '\u0414\u0435\u0441\u0435\u0440\u0442 \u0435\u0434\u0430 \u043f\u0435\u0440\u0435\u043a\u0443\u0441 dessert food snack',
    IceSkating:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u043e\u0431\u0443\u0432\u044c \u043a\u043e\u043d\u044c\u043a\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c athlete athletic entertainment exercise hobby shoe skates social sports travel',
    Image: '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0420\u0430\u043c\u043a\u0430 \u0421\u043f\u0440\u044f\u0442\u0430\u0442\u044c \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled enabled frame hide landscape mountains off on photography picture slash',
    ImageAspectRatio:
        '\u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u0440\u044f\u043c\u043e\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u043f\u043b\u043e\u0449\u0430\u0434\u044c photography picture rectangle square',
    ImageNotSupported:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled enabled landscape mountains off on photography picture slash',
    ImageSearch:
        '\u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u043f\u0435\u0439\u0437\u0430\u0436 \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0432\u0438\u0434\u0435\u0442\u044c find glass landscape look magnifying mountains photography picture see',
    ImagesearchRoller:
        '\u0438\u0441\u043a\u0443\u0441\u0441\u0442\u0432\u043e \u043a\u0440\u0430\u0441\u043a\u0430 art paint',
    ImportantDevices:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0437\u0432\u0435\u0437\u0434\u0430 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0441\u0435\u0442\u044c Android cell computer desktop hardware iOS mobile monitor phone star tablet web',
    ImportContacts:
        '\u0430\u0434\u0440\u0435\u0441 \u043a\u043d\u0438\u0433\u0430 \u0434\u0440\u0443\u0437\u044c\u044f \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0436\u0443\u0440\u043d\u0430\u043b \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u043c address book friends information magazine open',
    ImportExport:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u0437 \u0438\u0441\u0441\u043b\u0435\u0434\u043e\u0432\u0430\u0442\u044c \u0432\u0432\u0435\u0440\u0445 arrows direction down explort up',
    Inbox: '\u0430\u0440\u0445\u0438\u0432 \u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u0432\u0445\u043e\u0434\u044f\u0449\u0438\u0439 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 archive email incoming message',
    IndeterminateCheckBox:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u043d\u043e\u043f\u043a\u0430 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043c\u0438\u043d\u0443\u0441 \u044d\u043a\u0440\u0430\u043d \u0432\u044b\u0431\u0440\u0430\u043d \u0432\u044b\u0431\u043e\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043d\u0435\u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u043d\u044b\u0439 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application button components control design form interface minus screen selected selection square toggle ui undetermined ux website',
    Info: '\u043e \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043f\u043e\u043c\u043e\u0449\u044c \u043f\u0443\u0437\u044b\u0440\u044c \u043a\u0440\u0443\u0433 \u0414\u0435\u0442\u0430\u043b\u0438 \u043f\u043e\u043c\u043e\u0449\u044c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0442\u044c about alert announcement announcment assistance bubble circle details help information service support',
    Input: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0448\u0430\u0433 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e arrow box download login move right',
    InsertChart:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0433\u0438\u0441\u0442\u043e\u0433\u0440\u0430\u043c\u043c\u0430 \u0431\u0430\u0440\u044b \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics barchart bars data diagram infographic measure metrics statistics tracking',
    InsertChartOutlined:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bars data diagram infographic measure metrics statistics tracking',
    InsertComment:
        '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 add bubble chat feedback message',
    InsertDriveFile:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0447\u0435\u0442 \u0432\u0435\u0449\u044c \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 bill document format invoice item sheet slide',
    InsertEmoticon:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u044d\u043c\u043e\u0434\u0437\u0438 \u043b\u0438\u0446\u043e \u0441\u0447\u0430\u0441\u0442\u043b\u0438\u0432\u044b\u0439 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043a\u0430\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043d\u0430\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account emoji face happy human like people person profile sentiment smiley user',
    InsertInvitation:
        '\u043f\u043e\u0432\u0435\u0441\u0442\u043a\u0430 \u0434\u043d\u044f \u043a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u0435\u0441\u044f\u0446 \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 C\u0435\u0433\u043e\u0434\u043d\u044f \u043d\u0435\u0434\u0435\u043b\u044f agenda calendar date event mark month range remember reminder today week',
    InsertLink:
        '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u044f\u043a\u043e\u0440\u044c \u043f\u0440\u0438\u043a\u0440\u0435\u043f\u043b\u044f\u0442\u044c \u043a\u043b\u0438\u043f \u0444\u0430\u0439\u043b \u043f\u043e\u0447\u0442\u0430 \u0421\u041c\u0418 add anchor attach clip file mail media',
    InsertPageBreak:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u0431\u0443\u043c\u0430\u0433\u0430 document file paper',
    InsertPhoto:
        '\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043e\u0431\u043e\u0438 image landscape mountains photography picture wallpaper',
    Insights:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0437\u0432\u0435\u0437\u0434\u044b \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bars chart data diagram infographic measure metrics stars statistics tracking',
    Instagram:
        '\u043c\u0430\u0440\u043a\u0430 \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 brand logo social',
    InstallDesktop:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0445\u0440\u043e\u043c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0438\u0441\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u043c\u0435\u0441\u0442\u043e \u041c\u0430\u0441\u0441\u0430 \u044d\u043a\u0440\u0430\u043d \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android chrome device display fix hardware iOS mac monitor place pwa screen web window',
    InstallMobile:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u041c\u0430\u0441\u0441\u0430 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android cell device hardware iOS phone pwa tablet',
    IntegrationInstructions:
        '\u0441\u043a\u043e\u0431\u043a\u0438 \u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u043a\u043e\u0434 css \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0438\u043d\u0436\u0435\u043d\u0435\u0440\u0438\u044f HTML \u041f\u043b\u0430\u0442\u0444\u043e\u0440\u043c\u0430 brackets clipboard code css developer document engineering html platform',
    Interests:
        '\u043a\u0440\u0443\u0433 \u0441\u0435\u0440\u0434\u0446\u0435 \u0444\u043e\u0440\u043c\u044b \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0442\u0440\u0435\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a circle heart shapes social square triangle',
    InterpreterMode:
        '\u044f\u0437\u044b\u043a \u043c\u0438\u043a\u0440\u043e\u0444\u043e\u043d \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0413\u043e\u0432\u043e\u0440\u044f\u0449\u0438\u0439 \u0441\u0438\u043c\u0432\u043e\u043b language microphone person speaking symbol',
    Inventory:
        '\u0430\u0440\u0445\u0438\u0432 \u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u043a\u0443\u043f\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0444\u0430\u0439\u043b \u0441\u043f\u0438\u0441\u043e\u043a \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u043e\u0432\u0430\u0442\u044c \u043f\u0430\u043a\u0435\u0442\u044b \u0442\u043e\u0432\u0430\u0440 \u043f\u043e\u043a\u0443\u043f\u043a\u0430 \u043c\u0430\u0433\u0430\u0437\u0438\u043d \u0437\u0430\u043f\u0430\u0441 \u0445\u0440\u0430\u043d\u0438\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u043b\u044f\u0442\u044c archive box buy check clipboard document e-commerce file list organize packages product purchase shop stock store supply',
    Inventory2:
        '\u0430\u0440\u0445\u0438\u0432 \u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u0444\u0430\u0439\u043b \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u043e\u0432\u0430\u0442\u044c \u043f\u0430\u043a\u0435\u0442\u044b \u0442\u043e\u0432\u0430\u0440 \u0437\u0430\u043f\u0430\u0441 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u043f\u043e\u0441\u0442\u0430\u0432\u043b\u044f\u0442\u044c archive box file organize packages product stock storage supply',
    InvertColors:
        '\u043a\u0430\u043f\u0435\u043b\u044c\u043a\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0442\u0442\u0435\u043d\u043e\u043a \u043f\u0435\u0440\u0435\u0432\u0435\u0440\u043d\u0443\u0442\u044b\u0439 \u0436\u0438\u0434\u043a\u043e\u0441\u0442\u044c \u043f\u0430\u043b\u0438\u0442\u0440\u0430 \u0442\u043e\u043d \u0432\u043e\u0434\u0430 droplet editing hue inverted liquid palette tone water',
    InvertColorsOff:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u043a\u0430\u043f\u0435\u043b\u044c\u043a\u0430 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043e\u0442\u0442\u0435\u043d\u043e\u043a \u043f\u0435\u0440\u0435\u0432\u0435\u0440\u043d\u0443\u0442\u044b\u0439 \u0436\u0438\u0434\u043a\u043e\u0441\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043d\u0435\u043f\u0440\u043e\u0437\u0440\u0430\u0447\u043d\u043e\u0441\u0442\u044c \u043f\u0430\u043b\u0438\u0442\u0440\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0442\u043e\u043d \u0432\u043e\u0434\u0430 disabled droplet enabled hue inverted liquid offline opacity palette slash tone water',
    IosShare:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043d\u043e\u043f\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u044d\u043a\u0441\u043f\u043e\u0440\u0442 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u0441\u0430\u0439\u0442 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043e\u0431\u043c\u0435\u043d \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0432\u0432\u0435\u0440\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 arrows button direction export internet link send sharing social up website',
    Iron: '\u043f\u0440\u0438\u0431\u043e\u0440 \u043e\u0434\u0435\u0436\u0434\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0433\u043b\u0430\u0436\u043a\u0430 \u043c\u0430\u0448\u0438\u043d\u0430 \u043e\u0431\u044a\u0435\u043a\u0442 appliance clothes electric ironing machine object',
    Iso: '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043c\u0438\u043d\u0443\u0441 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u043b\u044e\u0441 \u0434\u0430\u0442\u0447\u0438\u043a \u0437\u0430\u0442\u0432\u043e\u0440 \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0432\u044b\u0447\u0435\u0441\u0442\u044c add editing effect image minus photography picture plus sensor shutter speed subtract',
    Javascript:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u043a\u043e\u0431\u043a\u0438 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u043e\u0434 css \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a \u0438\u043d\u0436\u0435\u043d\u0435\u0440\u0438\u044f \u0448\u0440\u0438\u0444\u0442 HTML \u043f\u0438\u0441\u044c\u043c\u043e \u041f\u043b\u0430\u0442\u0444\u043e\u0440\u043c\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet brackets character code css developer engineering font html letter platform symbol text type',
    JoinFull:
        '\u043a\u0440\u0443\u0433 \u043a\u043e\u043c\u0431\u0438\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u0432\u043d\u0435\u0448\u043d\u0438\u0439 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e SQL circle combine command left outter right sql',
    JoinInner:
        '\u043a\u0440\u0443\u0433 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u0441\u043e\u043e\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0438\u0435 SQL \u0446\u0435\u043d\u043d\u043e\u0441\u0442\u0438 circle command matching sql values',
    JoinLeft:
        '\u043a\u0440\u0443\u0433 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u0441\u043e\u043e\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0438\u0435 SQL \u0446\u0435\u043d\u043d\u043e\u0441\u0442\u0438 circle command matching sql values',
    JoinRight:
        '\u043a\u0440\u0443\u0433 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u0441\u043e\u043e\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0438\u0435 SQL \u0446\u0435\u043d\u043d\u043e\u0441\u0442\u0438 circle command matching sql values',
    Kayaking:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0442\u0435\u043b\u043e \u043a\u0430\u043d\u043e\u044d \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043e\u0437\u0435\u0440\u043e \u0432\u0435\u0441\u043b\u043e \u0433\u0440\u0435\u0431\u043b\u044f \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0440\u0430\u0444\u0442\u0438\u043d\u0433 \u0440\u0435\u043a\u0430 \u0441\u0442\u0440\u043e\u043a\u0430 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043b\u0435\u0442\u043e \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0432\u043e\u0434\u0430 athlete athletic body canoe entertainment exercise hobby human lake paddle paddling people person rafting river row social sports summer travel water',
    KebabDining:
        '\u043e\u0431\u0435\u0434 \u0435\u0434\u0430 \u0435\u0434\u0430 \u043c\u044f\u0441\u043e \u0432\u0435\u0440\u0442\u0435\u043b dinner food meal meat skewer',
    Key: '\u0437\u0430\u0442\u0435\u043c\u043d\u0435\u043d\u0438\u0435 \u043f\u0430\u0440\u043e\u043b\u044c \u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u043d\u043d\u044b\u0439 \u0441\u0435\u043a\u0440\u0435\u0442 \u0440\u0430\u0437\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c blackout password restricted secret unlock',
    Keyboard:
        '\u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0432\u0445\u043e\u0434 \u043a\u043b\u0430\u0432\u0438\u0430\u0442\u0443\u0440\u0430 \u043f\u0438\u0441\u044c\u043c\u043e \u043e\u0444\u0438\u0441 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f computer device hardware input keypad letter office text type',
    KeyboardAlt:
        '\u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0432\u0445\u043e\u0434 \u043a\u043b\u0430\u0432\u0438\u0430\u0442\u0443\u0440\u0430 \u043f\u0438\u0441\u044c\u043c\u043e \u043e\u0444\u0438\u0441 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f computer device hardware input keypad letter office text type',
    KeyboardArrowDown:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0448\u0435\u0432\u0440\u043e\u043d \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u043c arrows chevron open',
    KeyboardArrowLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0448\u0435\u0432\u0440\u043e\u043d arrows chevron',
    KeyboardArrowRight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0448\u0435\u0432\u0440\u043e\u043d \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u043c \u041d\u0430\u0447\u0430\u043b\u043e arrows chevron open start',
    KeyboardArrowUp:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0448\u0435\u0432\u0440\u043e\u043d \u0420\u0430\u0437\u043c\u0435\u0441\u0442\u0438\u0442\u044c arrows chevron submit',
    KeyboardBackspace:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043e\u0441\u0442\u0430\u0432\u0438\u043b arrow left',
    KeyboardCapslock:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0432\u0432\u0435\u0440\u0445 arrow up',
    KeyboardCommandKey:
        '\u043a\u043d\u043e\u043f\u043a\u0430 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043a\u043b\u044e\u0447 button command control key',
    KeyboardControlKey:
        '\u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043a\u043b\u044e\u0447 control key',
    KeyboardDoubleArrowDown:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f arrows direction multiple navigation',
    KeyboardDoubleArrowLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f arrows direction multiple navigation',
    KeyboardDoubleArrowRight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f arrows direction multiple navigation',
    KeyboardDoubleArrowUp:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f arrows direction multiple navigation',
    KeyboardHide:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0432\u043d\u0438\u0437 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0432\u0445\u043e\u0434 \u043a\u043b\u0430\u0432\u0438\u0430\u0442\u0443\u0440\u0430 \u0442\u0435\u043a\u0441\u0442 arrow computer device down hardware input keypad text',
    KeyboardOptionKey:
        '\u0432\u0441\u0435 \u043a\u043b\u044e\u0447 \u043c\u043e\u0434\u0438\u0444\u0438\u043a\u0430\u0442\u043e\u0440 alt key modifier',
    KeyboardReturn:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u043e\u0441\u0442\u0430\u0432\u0438\u043b arrow back left',
    KeyboardTab:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0441\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0439 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e arrow next right',
    KeyboardVoice:
        '\u043c\u0438\u043a\u0440\u043e\u0444\u043e\u043d \u0448\u0443\u043c \u0440\u0435\u043a\u043e\u0440\u0434\u0435\u0440 \u043e\u0440\u0430\u0442\u043e\u0440 microphone noise recorder speaker',
    KeyOff: '[\u043d\u0435 \u0432 \u0441\u0435\u0442\u0438] \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0430 \u043f\u0430\u0440\u043e\u043b\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0440\u0430\u0437\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c [offline] disabled enabled on password slash unlock',
    KingBed:
        '\u0421\u043f\u0430\u043b\u044c\u043d\u044f \u0434\u0432\u043e\u0439\u043d\u043e\u0439 \u043c\u0435\u0431\u0435\u043b\u044c \u0434\u043e\u043c \u043e\u0442\u0435\u043b\u044c \u0434\u043e\u043c \u043d\u043e\u0447\u044c \u043f\u043e\u0434\u0443\u0448\u043a\u0438 \u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0430 \u043e\u0442\u0434\u044b\u0445\u0430\u0442\u044c \u0441\u043f\u0430\u0442\u044c bedroom double furniture home hotel house night pillows queen rest sleep',
    Kitchen:
        '\u043f\u0440\u0438\u0431\u043e\u0440 \u043a\u0430\u0431\u0438\u043d\u0435\u0442 \u0445\u043e\u043b\u043e\u0434\u043d\u043e \u0435\u0434\u0430 \u043c\u043e\u0440\u043e\u0437\u0438\u043b\u044c\u043d\u0438\u043a \u0445\u043e\u043b\u043e\u0434\u0438\u043b\u044c\u043d\u0438\u043a \u0434\u043e\u043c \u0434\u043e\u043c \u043b\u0435\u0434 \u043c\u0435\u0441\u0442\u0430 \u0445\u043e\u043b\u043e\u0434\u0438\u043b\u044c\u043d\u0438\u043a \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 appliance cabinet cold food freezer fridge home house ice places refrigerator storage',
    Kitesurfing:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u043f\u043b\u044f\u0436 \u0442\u0435\u043b\u043e \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0432\u043e\u0434\u0430 athlete athletic beach body entertainment exercise hobby human people person social sports travel water',
    Label: '\u0437\u043d\u0430\u0447\u043e\u043a \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u043e\u0442\u0441\u0442\u0443\u043f \u0432\u0435\u0449\u044c \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u043f\u043e\u0447\u0442\u0430 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u0441\u043f\u0430\u0441\u0442\u0438 \u043f\u0435\u0447\u0430\u0442\u044c \u043d\u0430\u043a\u043b\u0435\u0439\u043a\u0430 \u044f\u0440\u043b\u044b\u043a badge favorite indent item library mail remember save stamp sticker tag',
    LabelImportant:
        '\u0437\u043d\u0430\u0447\u043e\u043a \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0432\u0430\u0436\u043d\u044b\u0439. \u043e\u0442\u0441\u0442\u0443\u043f \u0432\u0435\u0449\u044c \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u043f\u043e\u0447\u0442\u0430 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u0441\u043f\u0430\u0441\u0442\u0438 \u043f\u0435\u0447\u0430\u0442\u044c \u043d\u0430\u043a\u043b\u0435\u0439\u043a\u0430 \u044f\u0440\u043b\u044b\u043a \u043a\u0440\u044b\u043b\u043e badge favorite important. indent item library mail remember save stamp sticker tag wing',
    LabelOff:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u043e\u0442\u0441\u0442\u0443\u043f \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u043f\u043e\u0447\u0442\u0430 \u043d\u0430 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u0441\u043f\u0430\u0441\u0442\u0438 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u0435\u0447\u0430\u0442\u044c \u043d\u0430\u043a\u043b\u0435\u0439\u043a\u0430 \u044f\u0440\u043b\u044b\u043a \u043a\u0440\u044b\u043b\u043e disabled enabled favorite indent library mail on remember save slash stamp sticker tag wing',
    Lan: '\u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 computer connection data internet network service',
    Landscape:
        '\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0433\u043e\u0440\u044b \u043f\u0440\u0438\u0440\u043e\u0434\u0430 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 image mountains nature photography picture',
    Landslide:
        '\u043a\u0440\u0438\u0437\u0438\u0441 \u0441\u0442\u0438\u0445\u0438\u0439\u043d\u043e\u0435 \u0431\u0435\u0434\u0441\u0442\u0432\u0438\u0435 \u0435\u0441\u0442\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0434\u043e\u0436\u0434\u044c \u0431\u0443\u0440\u044f \u043f\u043e\u0433\u043e\u0434\u0430 crisis disaster natural rain storm weather',
    Language:
        '\u0441\u0442\u0440\u0430\u043d\u0430 \u0437\u0435\u043c\u043d\u043e\u0439 \u0448\u0430\u0440 \u0433\u043b\u043e\u0431\u0443\u0441 i18n \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 l10n \u043f\u043b\u0430\u043d\u0435\u0442\u0430 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u041c\u0438\u0440 www country earth globe i18n internet l10n planet website world www',
    Laptop: '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0445\u0440\u043e\u043c \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0441\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u0441\u0430\u0439\u0442 \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0443\u043c\u043d\u0430\u044f \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u0430 Android chrome computer connect desktop device display hardware iOS link mac monitor smart tv web windows',
    LaptopChromebook:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0445\u0440\u043e\u043c\u0431\u0443\u043a \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u044d\u043a\u0440\u0430\u043d \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android chromebook device display hardware iOS mac monitor screen web window',
    LaptopMac:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u044f\u0431\u043b\u043e\u043a\u043e \u0445\u0440\u043e\u043c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u044d\u043a\u0440\u0430\u043d \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android apple chrome device display hardware iOS monitor screen web window',
    LaptopWindows:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0445\u0440\u043e\u043c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u044d\u043a\u0440\u0430\u043d \u0441\u0435\u0442\u044c Android chrome device display hardware iOS mac monitor screen web',
    LastPage:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0448\u0435\u0432\u0440\u043e\u043d \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043a\u043e\u043d\u0435\u0446 \u0432\u043f\u0435\u0440\u0435\u0434 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application arrow chevron components end forward interface right screen ui ux website',
    Launch: '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043e\u0441\u043d\u043e\u0432\u043d\u043e\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0432\u043d\u0443\u0442\u0440\u0435\u043d\u043d\u0438\u0439 \u0441\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u0441\u0430\u0439\u0442 \u043d\u043e\u0432\u044b\u0439 \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u043c \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e application arrow box components core interface internal link new open screen ui ux website window',
    Layers: '\u0434\u043e\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c\u0441\u044f \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u0437\u0430\u0438\u043c\u043e\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u044b \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043a\u0443\u0447\u0430 arrange disabled enabled interaction maps off overlay pages slash stack',
    LayersClear:
        '\u0434\u043e\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c\u0441\u044f \u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u0437\u0430\u0438\u043c\u043e\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u044b \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 arrange delete disabled enabled interaction maps off overlay pages slash',
    Leaderboard:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bars chart data diagram infographic measure metrics statistics tracking',
    LeakAdd:
        '\u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0441\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u0441\u0430\u0439\u0442 \u0441\u0435\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b\u044b \u0441\u0438\u043d\u0445\u0440\u043e\u043d\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection data link network service signals synce wireless',
    LeakRemove:
        '\u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0441\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u0441\u0430\u0439\u0442 \u0441\u0435\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b\u044b \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043d\u0445\u0440\u043e\u043d\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection data disabled enabled link network offline service signals slash synce wireless',
    LegendToggle:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u043c\u043e\u043d\u0438\u0442\u043e\u0440\u0438\u043d\u0433 \u0441\u0442\u0435\u043a\u0434\u0440\u0430\u0439\u0432\u0435\u0440 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics chart data diagram infographic measure metrics monitoring stackdriver statistics tracking',
    Lens: '\u043a\u0440\u0443\u0433 \u043f\u043e\u043b\u043d\u044b\u0439 \u0433\u0435\u043e\u043c\u0435\u0442\u0440\u0438\u044f \u043b\u0443\u043d\u0430 circle full geometry moon',
    LensBlur:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0442\u0443\u0441\u043a\u043b\u044b\u0439 \u0442\u043e\u0447\u043a\u0430 \u044d\u0444\u0444\u0435\u043a\u0442 \u0442\u0443\u043c\u0430\u043d\u043d\u044b\u0439 \u043d\u0435\u0447\u0435\u0442\u043a\u0438\u0439 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0424\u043e\u0442\u043e \u0441\u043c\u044f\u0433\u0447\u0438\u0442\u044c camera dim dot effect foggy fuzzy image photo soften',
    LibraryAdd:
        '+ \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u0441\u043b\u043e\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u041c\u0443\u0437\u044b\u043a\u0430 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0441\u043f\u0430\u0441\u0442\u0438 \u0441\u043b\u043e\u0436\u0435\u043d\u043d\u044b\u0439 \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0438\u0434\u0435\u043e + collection layers multiple music new plus save stacked symbol video',
    LibraryAddCheck:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0441\u043b\u043e\u0438 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u041c\u0443\u0437\u044b\u043a\u0430 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0441\u043b\u043e\u0436\u0435\u043d\u043d\u044b\u0439 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0432\u0438\u0434\u0435\u043e \u0434\u0430 approve collection complete done layers mark multiple music ok select stacked tick validate verified video yes',
    LibraryBooks:
        '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0430\u043b\u044c\u0431\u043e\u043c \u0430\u0443\u0434\u0438\u043e \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u0447\u0442\u0435\u043d\u0438\u0435 add album audio collection reading',
    LibraryMusic:
        '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0430\u043b\u044c\u0431\u043e\u043c \u0430\u0443\u0434\u0438\u043e \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u043f\u0435\u0441\u043d\u044f \u0437\u0432\u0443\u043a\u0438 add album audio collection song sounds',
    Light: '\u043b\u0430\u043c\u043f\u043e\u0447\u043a\u0430 \u043f\u043e\u0442\u043e\u043b\u043e\u043a \u0432\u0438\u0441\u0438\u0442 \u0432\u043d\u0443\u0442\u0440\u0438 \u0438\u043d\u0442\u0435\u0440\u044c\u0435\u0440 \u0444\u043e\u043d\u0430\u0440\u044c \u043e\u0441\u0432\u0435\u0449\u0435\u043d\u0438\u0435 \u043f\u043e\u0434\u0432\u0435\u0441\u043d\u043e\u0439 \u043d\u043e\u043c\u0435\u0440 bulb ceiling hanging inside interior lamp lighting pendent room',
    Lightbulb:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u0434\u0435\u044f \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u0431\u0443\u0447\u0435\u043d\u0438\u0435 \u0420\u0435\u0436\u0438\u043c alert announcement idea information learning mode',
    LightbulbCircle:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u0434\u0435\u044f \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f alert announcement idea information',
    LightMode:
        '\u044f\u0440\u043a\u043e\u0441\u0442\u044c \u0434\u0435\u043d\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0441\u0432\u0435\u0449\u0435\u043d\u0438\u0435 \u0443\u0442\u0440\u043e \u0443\u0442\u0440\u043e \u043d\u0435\u0431\u043e \u0441\u043e\u043b\u043d\u0435\u0447\u043d\u043e brightness day device lighting morning mornng sky sunny',
    LinearScale:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043c\u0430\u043a\u0435\u0442 \u043c\u0435\u0440\u0430 \u043c\u0435\u043d\u044e \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u0437\u0443\u043d\u043e\u043a \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e application components design interface layout measure menu screen slider ui ux website window',
    LineAxis:
        '\u0431\u0440\u043e\u0441\u0430\u0442\u044c\u0441\u044f \u0433\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u044b\u0439 \u0418\u043d\u0441\u0443\u043b\u044c\u0442 \u0432\u0435\u0440\u0442\u0438\u043a\u0430\u043b\u044c\u043d\u044b\u0439 dash horizontal stroke vertical',
    LineStyle:
        '\u0431\u0440\u043e\u0441\u0430\u0442\u044c\u0441\u044f \u043f\u0443\u043d\u043a\u0442\u0438\u0440\u043d\u044b\u0439 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u043f\u0440\u0430\u0432\u0438\u043b\u043e \u0438\u043d\u0442\u0435\u0440\u0432\u0430\u043b dash dotted editor rule spacing',
    LineWeight:
        '\u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0432\u044b\u0441\u043e\u0442\u0430 \u0440\u0430\u0437\u043c\u0435\u0440 \u0438\u043d\u0442\u0435\u0440\u0432\u0430\u043b \u0441\u0442\u0438\u043b\u044c \u0442\u043e\u043b\u0449\u0438\u043d\u0430 editor height size spacing style thickness',
    Link: '\u044f\u043a\u043e\u0440\u044c \u0446\u0435\u043f\u044c \u043a\u043b\u0438\u043f \u0441\u0432\u044f\u0437\u044c \u0432\u043d\u0435\u0448\u043d\u0438\u0439 \u0433\u0438\u043f\u0435\u0440\u0441\u0441\u044b\u043b\u043a\u0430 \u0441\u0432\u044f\u0437\u0430\u043d\u043d\u044b\u0439 \u0441\u0441\u044b\u043b\u043a\u0438 \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430 \u043d\u0435\u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0439 URL anchor chain clip connection external hyperlink linked links multimedia unlisted url',
    LinkedCamera:
        '\u0441\u0432\u044f\u0437\u044c \u043e\u0431\u044a\u0435\u043a\u0442\u0438\u0432 \u0441\u0435\u0442\u044c \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0441\u0438\u0433\u043d\u0430\u043b\u044b \u0441\u0438\u043d\u0445\u0440\u043e\u043d\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection lens network photography picture signals sync wireless',
    LinkedIn:
        '\u043c\u0430\u0440\u043a\u0430 \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 brand logo social',
    LinkOff:
        '\u044f\u043a\u043e\u0440\u044c \u043f\u0440\u0438\u043a\u0440\u0435\u043f\u0438\u043b \u0446\u0435\u043f\u044c \u043a\u043b\u0438\u043f \u0441\u0432\u044f\u0437\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0441\u0432\u044f\u0437\u0430\u043d\u043d\u044b\u0439 \u0441\u0441\u044b\u043b\u043a\u0438 \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0440\u0430\u0437\u044a\u0435\u0434\u0438\u043d\u0438\u0442\u044c URL anchor attached chain clip connection disabled enabled linked links multimedia slash unlink url',
    Liquor: '\u0430\u043b\u043a\u043e\u0433\u043e\u043b\u044c \u0431\u0430\u0440 \u0431\u0443\u0442\u044b\u043b\u043a\u0430 \u043a\u043b\u0443\u0431 \u043a\u043e\u043a\u0442\u0435\u0439\u043b\u044c \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0435\u0434\u0430 \u043f\u0430\u0440\u0442\u0438\u044f \u0445\u0440\u0430\u043d\u0438\u0442\u044c \u0432\u0438\u043d\u043e alcohol bar bottle club cocktail drink food party store wine',
    List: '\u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0444\u0430\u0439\u043b \u0444\u043e\u0440\u043c\u0430\u0442 \u0438\u043d\u0434\u0435\u043a\u0441 \u043c\u0435\u043d\u044e \u043e\u043f\u0446\u0438\u0438 \u043f\u043b\u0435\u0439\u043b\u0438\u0441\u0442 \u0437\u0430\u0434\u0430\u0447\u0430 \u0432\u0441\u0435 editor file format index menu options playlist task todo',
    ListAlt:
        '\u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u0441\u043e\u0434\u0435\u0440\u0436\u0438\u0442\u0441\u044f \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0444\u043e\u0440\u043c\u0430\u0442 \u043b\u0438\u043d\u0438\u0438 \u0418\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u0435 \u043f\u043e\u0440\u044f\u0434\u043a\u0430 \u043b\u0438\u0441\u0442 \u0441\u043b\u043e\u0436\u0435\u043d\u043d\u044b\u0439 \u0437\u0430\u0434\u0430\u0447\u0430 \u0437\u0430\u0433\u043b\u0430\u0432\u0438\u0435 \u0432\u0441\u0435 box contained editor format lines reorder sheet stacked task title todo',
    LiveHelp:
        '? \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043f\u043e\u043c\u043e\u0449\u044c \u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u0427\u0430\u0441\u0442\u043e \u0437\u0430\u0434\u0430\u0432\u0430\u0435\u043c\u044b\u0435 \u0432\u043e\u043f\u0440\u043e\u0441\u044b \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043f\u0443\u043d\u043a\u0442\u0443\u0430\u0446\u0438\u044f \u0432\u043e\u043f\u0440\u043e\u0441 \u0440\u0435\u0447\u044c \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b ? alert announcement assistance bubble chat comment communicate faq information mark message punctuation question speech support symbol',
    LiveTv: '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0430\u043d\u0442\u0435\u043d\u043d\u044b \u0445\u0440\u043e\u043c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u043a\u0438\u043d\u043e \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0440\u0443\u0447\u0435\u0439 \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android antennas chrome desktop device hardware iOS mac monitor movie play stream television web window',
    Living: '\u0441\u0442\u0443\u043b \u043a\u043e\u043c\u0444\u043e\u0440\u0442 \u0434\u0438\u0432\u0430\u043d \u0443\u043a\u0440\u0430\u0448\u0435\u043d\u0438\u0435 \u043c\u0435\u0431\u0435\u043b\u044c \u0434\u043e\u043c \u0434\u043e\u043c \u0431\u0435\u0437\u0434\u0435\u043b\u044c\u043d\u0438\u0447\u0430\u044e\u0449\u0438\u0439 \u0434\u0432\u0443\u0445\u043c\u0435\u0441\u0442\u043d\u043e\u0435 \u0441\u0438\u0434\u0435\u043d\u044c\u0435 \u043d\u043e\u043c\u0435\u0440 \u0441\u0438\u0434\u0435\u043d\u0438\u0435 \u0434\u0438\u0432\u0430\u043d chair comfort couch decoration furniture home house lounging loveseat room seating sofa',
    LocalActivity:
        '\u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u0437\u0432\u0435\u0437\u0434\u0430 \u0432\u0435\u0449\u0438 \u043f\u0440\u043e\u0435\u0437\u0434\u043d\u043e\u0439 \u0431\u0438\u043b\u0435\u0442 event star things ticket',
    LocalAirport:
        '\u0441\u0430\u043c\u043e\u043b\u0435\u0442 \u043f\u043e\u043b\u0435\u0442 \u043b\u0435\u0442\u0430\u044e\u0449\u0438\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 airplane flight flying transportation travel trip',
    LocalAtm:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0442\u0435\u043b\u0435\u0436\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u0432\u044b\u0433\u043e\u0434\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill card cart cash coin commerce credit currency dollars financial money online payment price profit shopping symbol',
    LocalBar:
        '\u0430\u043b\u043a\u043e\u0433\u043e\u043b\u044c \u0431\u0443\u0442\u044b\u043b\u043a\u0430 \u043a\u043b\u0443\u0431 \u043a\u043e\u043a\u0442\u0435\u0439\u043b\u044c \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0435\u0434\u0430 \u043b\u0438\u043a\u0435\u0440 \u043c\u0430\u0440\u0442\u0438\u043d\u0438 \u0432\u0438\u043d\u043e alcohol bottle club cocktail drink food liquor martini wine',
    LocalCafe:
        '\u0431\u0443\u0442\u044b\u043b\u043a\u0430 \u043a\u043e\u0444\u0435 \u0447\u0430\u0448\u043a\u0430 \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0435\u0434\u0430 \u043a\u0440\u0443\u0436\u043a\u0430 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d \u0447\u0430\u0439 bottle coffee cup drink food mug restaurant tea',
    LocalCarWash:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars maps transportation travel vehicle',
    LocalConvenienceStore:
        '-- 24 \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0431\u0438\u0437\u043d\u0435\u0441 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u041a\u043e\u043c\u043f\u0430\u043d\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u043a\u0430\u0440\u0442\u044b \u0440\u044b\u043d\u043e\u043a \u0414\u0435\u043d\u044c\u0433\u0438 \u043d\u043e\u0432\u044b\u0439 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043b\u044e\u0441 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0432\u0438\u0442\u0440\u0438\u043d\u0430 \u0441\u0438\u043c\u0432\u043e\u043b -- 24 bill building business card cash coin commerce company credit currency dollars maps market money new online payment plus shopping storefront symbol',
    LocalDining:
        '\u0441\u0442\u043e\u043b\u043e\u0432\u044b\u0435 \u043f\u0440\u0438\u0431\u043e\u0440\u044b \u0435\u0441\u0442\u044c \u0435\u0434\u0430 \u0432\u0438\u043b\u043a\u0430 \u043d\u043e\u0436 \u0435\u0434\u0430 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d \u043b\u043e\u0436\u043a\u0430 cutlery eat food fork knife meal restaurant spoon',
    LocalDrink:
        '\u0447\u0430\u0448\u043a\u0430 \u043a\u0430\u043f\u0435\u043b\u044c\u043a\u0430 \u0441\u0442\u0430\u043a\u0430\u043d \u0436\u0438\u0434\u043a\u043e\u0441\u0442\u044c \u043f\u0430\u0440\u043a \u0432\u043e\u0434\u0430 cup droplet glass liquid park water',
    LocalFireDepartment:
        '911 \u043f\u043e\u0436\u0430\u0440\u043d\u044b\u0439 \u043f\u043b\u0430\u043c\u044f \u0433\u043e\u0440\u044f\u0447\u0438\u0439 911 firefighter flame hot',
    LocalFlorist:
        '\u0446\u0432\u0435\u0442\u043e\u043a \u043c\u0430\u0433\u0430\u0437\u0438\u043d flower shop',
    LocalGasStation:
        '\u0430\u0432\u0442\u043e \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0437\u0430\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u0435 \u0442\u043e\u043f\u043b\u0438\u0432\u043e \u0431\u0435\u043d\u0437\u0438\u043d \u043c\u0430\u0441\u043b\u043e \u0441\u0442\u0430\u043d\u0446\u0438\u044f \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f auto car filling fuel gasoline oil station vehicle',
    LocalGroceryStore:
        '\u0440\u044b\u043d\u043e\u043a \u043c\u0430\u0433\u0430\u0437\u0438\u043d market shop',
    LocalHospital:
        '911 \u043f\u043e\u043c\u043e\u0433\u0430\u0442\u044c \u041f\u0435\u0440\u0435\u0441\u0435\u043a\u0430\u0442\u044c \u0434\u043e\u043a\u0442\u043e\u0440 \u0447\u0440\u0435\u0437\u0432\u044b\u0447\u0430\u0439\u043d\u0430\u044f \u0441\u0438\u0442\u0443\u0430\u0446\u0438\u044f \u043f\u0435\u0440\u0432\u044b\u0439 \u0437\u0434\u043e\u0440\u043e\u0432\u044c\u0435 \u043c\u0435\u0434\u0438\u0446\u0438\u043d\u0441\u043a\u0438\u0439 \u043b\u0435\u043a\u0430\u0440\u0441\u0442\u0432\u0435\u043d\u043d\u043e\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u043b\u044e\u0441 911 aid cross doctor emergency first health medical medicine plus',
    LocalHotel:
        '\u043a\u0440\u043e\u0432\u0430\u0442\u044c \u0442\u0435\u043b\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u043f\u0430\u0442\u044c \u043e\u0441\u0442\u0430\u0442\u044c\u0441\u044f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 bed body human people person sleep stay travel trip',
    LocalLaundryService:
        '\u0443\u0431\u043e\u0440\u043a\u0430 \u043e\u0434\u0435\u0436\u0434\u0430 \u0441\u0443\u0448\u0438\u043b\u043a\u0430 \u043e\u0442\u0435\u043b\u044c \u0428\u0430\u0439\u0431\u0430 cleaning clothing dryer hotel washer',
    LocalLibrary:
        '\u043a\u043d\u0438\u0433\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u0441\u0442\u0432\u043e \u043e\u0431\u0443\u0447\u0435\u043d\u0438\u0435 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0447\u0438\u0442\u0430\u0442\u044c book community learning person read',
    LocalMall:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0431\u0438\u0437\u043d\u0435\u0441 \u043a\u0443\u043f\u0438\u0442\u044c \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0442\u0435\u043b\u0435\u0436\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0441\u0443\u043c\u043e\u0447\u043a\u0430 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0432\u0438\u0442\u0440\u0438\u043d\u0430 bill building business buy card cart cash coin commerce credit currency dollars handbag money online payment shopping storefront',
    LocalOffer:
        '\u0438\u043c\u0435\u0442\u044c \u0434\u0435\u043b\u043e \u0441\u043a\u0438\u0434\u043a\u0430 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0445\u0440\u0430\u043d\u0438\u0442\u044c \u044f\u0440\u043b\u044b\u043a deal discount price shopping store tag',
    LocalParking:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0430\u0432\u0442\u043e \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u0433\u0430\u0440\u0430\u0436 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f alphabet auto car character font garage letter symbol text type vehicle',
    LocalPharmacy:
        '911 \u043f\u043e\u043c\u043e\u0433\u0430\u0442\u044c \u041f\u0435\u0440\u0435\u0441\u0435\u043a\u0430\u0442\u044c \u0447\u0440\u0435\u0437\u0432\u044b\u0447\u0430\u0439\u043d\u0430\u044f \u0441\u0438\u0442\u0443\u0430\u0446\u0438\u044f \u043f\u0435\u0440\u0432\u044b\u0439 \u0435\u0434\u0430 \u0431\u043e\u043b\u044c\u043d\u0438\u0446\u0430 \u043b\u0435\u043a\u0430\u0440\u0441\u0442\u0432\u0435\u043d\u043d\u043e\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043c\u0435\u0441\u0442\u0430 911 aid cross emergency first food hospital medicine places',
    LocalPhone:
        '\u0441\u0442\u0435\u043d\u0434 \u0432\u044b\u0437\u043e\u0432 \u0442\u0435\u043b\u0435\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u0438 booth call telecommunication',
    LocalPizza:
        '\u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0411\u044b\u0441\u0442\u0440\u043e\u0435 \u043f\u0438\u0442\u0430\u043d\u0438\u0435 \u0435\u0434\u0430 drink fastfood meal',
    LocalPolice:
        '911 \u0437\u043d\u0430\u0447\u043e\u043a \u0437\u0430\u043a\u043e\u043d \u043e\u0444\u0438\u0446\u0435\u0440 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0449\u0438\u0442 911 badge law officer protection security shield',
    LocalPostOffice:
        '\u0414\u043e\u0441\u0442\u0430\u0432\u043a\u0430 \u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043e\u043a\u0443\u0442\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0443\u043f\u0430\u043a\u043e\u0432\u043a\u0430 \u043f\u043e\u0441\u044b\u043b\u043a\u0430 \u043f\u043e\u0447\u0442\u043e\u0432\u044b\u0439 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043f\u0435\u0447\u0430\u0442\u044c delivery email envelop letter message package parcel postal send stamp',
    LocalPrintshop:
        '\u0447\u0435\u0440\u043d\u043e\u0432\u0438\u043a \u0444\u0430\u043a\u0441 \u0447\u0435\u0440\u043d\u0438\u043b\u0430 \u043c\u0430\u0448\u0438\u043d\u0430 \u043e\u0444\u0438\u0441 \u0431\u0443\u043c\u0430\u0433\u0430 \u043f\u0440\u0438\u043d\u0442\u0435\u0440 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c draft fax ink machine office paper printer send',
    LocalSee:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u043e\u0431\u044a\u0435\u043a\u0442\u0438\u0432 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 camera lens photography picture',
    LocalShipping:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u0414\u043e\u0441\u0442\u0430\u0432\u043a\u0430 \u043f\u0438\u0441\u044c\u043c\u043e \u043f\u043e\u0447\u0442\u0430 \u043a\u0430\u0440\u0442\u044b \u043e\u0444\u0438\u0441 \u0443\u043f\u0430\u043a\u043e\u0432\u043a\u0430 \u043f\u043e\u0441\u044b\u043b\u043a\u0430 \u043f\u043e\u0447\u0442\u043e\u0432\u044b\u0439 \u043f\u043e\u043b\u0443 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u043f\u0435\u0447\u0430\u0442\u044c \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0433\u0440\u0443\u0437\u043e\u0432\u0430\u044f \u043c\u0430\u0448\u0438\u043d\u0430 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars delivery letter mail maps office package parcel postal semi send shopping stamp transportation truck vehicle',
    LocalTaxi:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0442\u0430\u043a\u0441\u0438 \u0432\u044b\u0437\u043e\u0432 \u043c\u0430\u0448\u0438\u043d\u044b \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043f\u043e\u0434\u044a\u0435\u043c\u043d\u0438\u043a \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0443\u0431\u0435\u0440 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0436\u0435\u043b\u0442\u044b\u0439 automobile cab call cars direction lyft maps public transportation uber vehicle yellow',
    LocationCity:
        '\u0430\u043f\u0430\u0440\u0442\u0430\u043c\u0435\u043d\u0442\u044b \u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0437\u0434\u0430\u043d\u0438\u044f \u0431\u0438\u0437\u043d\u0435\u0441 \u041a\u043e\u043c\u043f\u0430\u043d\u0438\u044f \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u043f\u0435\u0439\u0437\u0430\u0436 \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 \u0433\u043e\u0440\u043e\u0434\u043e\u043a \u0433\u043e\u0440\u043e\u0434\u0441\u043a\u043e\u0439 apartments architecture buildings business company estate home landscape place real residence residential shelter town urban',
    LocationDisabled:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043a\u0430\u0440\u0442\u044b \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u0443\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 destination direction enabled maps off pin place pointer slash stop tracking',
    LocationOff:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e GPS \u043a\u0430\u0440\u0442\u044b \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043d\u043e\u043c\u0435\u0440 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 destination direction disabled enabled gps maps pin place room slash stop',
    LocationOn:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e GPS \u043a\u0430\u0440\u0442\u044b \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043d\u043e\u043c\u0435\u0440 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 destination direction disabled enabled gps maps off pin place room slash stop',
    LocationSearching:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u0443\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 destination direction maps pin place pointer stop tracking',
    Lock: '\u0441\u0432\u044f\u0437\u044c \u043a\u043b\u044e\u0447 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u0432\u044b\u0439\u0442\u0438 \u0437\u0430\u043c\u043e\u043a \u043f\u0430\u0440\u043e\u043b\u044c \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0432\u044b\u0445\u043e\u0434 connection key locked logout padlock password privacy private protection safety secure security signout',
    LockClock:
        '\u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u043f\u0430\u0440\u043e\u043b\u044c \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0432\u0440\u0435\u043c\u044f date locked password privacy private protection safety schedule secure security time',
    LockOpen:
        '\u0441\u0432\u044f\u0437\u044c \u043a\u043b\u044e\u0447 \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0437\u0430\u043c\u043e\u043a \u043f\u0430\u0440\u043e\u043b\u044c \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0440\u0435\u0433\u0438\u0441\u0442\u0440 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0437\u043d\u0430\u043a \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0440\u0430\u0437\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d connection key login padlock password privacy private protection register safety secure security signin signup unlocked',
    LockReset:
        '\u043e\u043a\u043e\u043b\u043e \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u043f\u0430\u0440\u043e\u043b\u044c \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c around inprogress loading locked password privacy private protection refresh renew rotate safety secure security turn',
    Login: '\u0434\u043e\u0441\u0442\u0443\u043f \u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0432\u043e\u0439\u0442\u0438 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 access application arrow components design enter interface left screen ui ux website',
    LogoDev: 'dev.to dev.to',
    Logout: '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0432\u044b\u0445\u043e\u0434 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043e\u0441\u0442\u0430\u0432\u043b\u044f\u0442\u044c \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application arrow components design exit interface leave login right screen ui ux website',
    Looks: '\u043a\u0440\u0443\u0433 \u043f\u043e\u043b\u043e\u0432\u0438\u043d\u0430 \u0440\u0430\u0434\u0443\u0433\u0430 circle half rainbow',
    Looks3: '\u0446\u0438\u0444\u0440\u0430 \u0447\u0438\u0441\u043b\u0430 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0441\u0438\u043c\u0432\u043e\u043b digit numbers square symbol',
    Looks4: '\u0446\u0438\u0444\u0440\u0430 \u0447\u0438\u0441\u043b\u0430 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0441\u0438\u043c\u0432\u043e\u043b digit numbers square symbol',
    Looks5: '\u0446\u0438\u0444\u0440\u0430 \u0447\u0438\u0441\u043b\u0430 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0441\u0438\u043c\u0432\u043e\u043b digit numbers square symbol',
    Looks6: '\u0446\u0438\u0444\u0440\u0430 \u0447\u0438\u0441\u043b\u0430 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0441\u0438\u043c\u0432\u043e\u043b digit numbers square symbol',
    LooksOne:
        '1 \u0446\u0438\u0444\u0440\u0430 \u0447\u0438\u0441\u043b\u0430 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0441\u0438\u043c\u0432\u043e\u043b 1 digit numbers square symbol',
    LooksTwo:
        '2 \u0446\u0438\u0444\u0440\u0430 \u0447\u0438\u0441\u043b\u0430 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0441\u0438\u043c\u0432\u043e\u043b 2 digit numbers square symbol',
    Loop: '\u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u043f\u043e\u0433\u0440\u0443\u0437\u0447\u0438\u043a \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u041c\u0443\u0437\u044b\u043a\u0430 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u043e\u0432\u0442\u043e\u0440\u0435\u043d\u0438\u0435 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c around arrows direction inprogress loader loading music navigation refresh renew repeat rotate turn',
    Loupe: '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0414\u0435\u0442\u0430\u043b\u0438 \u0444\u043e\u043a\u0443\u0441 \u0441\u0442\u0430\u043a\u0430\u043d \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0441\u0438\u043c\u0432\u043e\u043b + add details focus glass magnifying new plus symbol',
    LowPriority:
        '\u0434\u043e\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c\u0441\u044f \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u043d\u0438\u0436\u043d\u0438\u0439 \u0441\u043f\u0438\u0441\u043e\u043a \u0448\u0430\u0433 \u0437\u0430\u043a\u0430\u0437 \u0437\u0430\u0434\u0430\u0447\u0430 \u0432\u0441\u0435 arrange arrow backward bottom list move order task todo',
    Loyalty:
        '\u0437\u043d\u0430\u0447\u043e\u043a \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043a\u0440\u0435\u0434\u0438\u0442 \u0441\u0435\u0440\u0434\u0446\u0435 \u043b\u044e\u0431\u043e\u0432\u044c \u0447\u043b\u0435\u043d\u0441\u0442\u0432\u043e \u043c\u0438\u043b\u0438 \u0442\u043e\u0447\u043a\u0438 \u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0430 \u0440\u0430\u0441\u043f\u0440\u043e\u0434\u0430\u0436\u0430 \u043f\u043e\u0434\u043f\u0438\u0441\u043a\u0430 \u044f\u0440\u043b\u044b\u043a \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 badge card credit heart love membership miles points program sale subscription tag travel trip',
    LteMobiledata:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 alphabet character font internet letter network speed symbol text type wifi wireless',
    LtePlusMobiledata:
        '+ \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 + alphabet character font internet letter network speed symbol text type wifi wireless',
    Luggage:
        '\u0430\u044d\u0440\u043e\u043f\u043e\u0440\u0442 \u0411\u0430\u0433\u0430\u0436 \u043d\u0435\u0441\u0442\u0438 \u043f\u043e\u043b\u0435\u0442 \u043e\u0442\u0435\u043b\u044c \u043d\u0430 \u0447\u0435\u043c\u043e\u0434\u0430\u043d \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 airport baggage carry flight hotel on suitcase travel trip',
    LunchDining:
        '\u0437\u0430\u0432\u0442\u0440\u0430\u043a \u043e\u0431\u0435\u0434 \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0411\u044b\u0441\u0442\u0440\u043e\u0435 \u043f\u0438\u0442\u0430\u043d\u0438\u0435 \u0433\u0430\u043c\u0431\u0443\u0440\u0433\u0435\u0440 \u0435\u0434\u0430 breakfast dinner drink fastfood hamburger meal',
    Lyrics: '\u0430\u0443\u0434\u0438\u043e \u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u043a\u043b\u044e\u0447 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u043f\u0435\u0441\u043d\u044f \u0437\u0432\u0443\u043a \u0440\u0435\u0447\u044c \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u0442\u044c audio bubble chat comment communicate feedback key message music note song sound speech track',
    Mail: '\u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043a\u043e\u043d\u0432\u0435\u0440\u0442 \u0432\u0445\u043e\u0434\u044f\u0449\u0438\u0435 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c email envelope inbox letter message send',
    MailLock:
        '\u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043e\u043a\u0443\u0442\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0441\u044c\u043c\u043e \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043f\u0430\u0440\u043e\u043b\u044c \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c email envelop letter locked message password privacy private protection safety secure security send',
    MailOutline:
        '\u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043a\u043e\u043d\u0432\u0435\u0440\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u043f\u043e\u0447\u0442\u0430 \u041f\u043e\u043b\u0443\u0447\u0430\u0442\u044c \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c email envelope letter message note post receive send write',
    Male: '\u043c\u0430\u043b\u044c\u0447\u0438\u043a \u041f\u043e\u043b \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u0438\u043c\u0432\u043e\u043b boy gender man social symbol',
    Man: '\u043c\u0430\u043b\u044c\u0447\u0438\u043a \u041f\u043e\u043b \u043c\u0443\u0436\u0447\u0438\u043d\u0430 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u0438\u043c\u0432\u043e\u043b boy gender male social symbol',
    ManageAccounts:
        '\u0441\u0434\u0430\u0447\u0430 \u0414\u0435\u0442\u0430\u043b\u0438 \u043b\u0438\u0446\u043e \u043c\u0435\u0445\u0430\u043d\u0438\u0437\u043c \u043e\u043f\u0446\u0438\u0438 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0441\u0435\u0440\u0432\u0438\u0441-\u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c change details face gear options people person profile service-human settings user',
    ManageHistory:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u0441\u0434\u0430\u0447\u0430 \u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0414\u0435\u0442\u0430\u043b\u0438 \u043c\u0435\u0445\u0430\u043d\u0438\u0437\u043c \u043e\u043f\u0446\u0438\u0438 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0432\u0440\u0435\u043c\u044f \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c application arrow backwards change clock date details gear options refresh renew reverse rotate schedule settings time turn',
    ManageSearch:
        '\u0441\u0442\u0430\u043a\u0430\u043d \u0438\u0441\u0442\u043e\u0440\u0438\u044f \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u0442\u0435\u043a\u0441\u0442 glass history magnifying text',
    Map: '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c destination direction location maps pin place route stop travel',
    MapsHomeWork:
        '\u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u043e\u0444\u0438\u0441 building house office',
    MapsUgc:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u043f\u0443\u0437\u044b\u0440\u044c \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0440\u0435\u0447\u044c \u0441\u0438\u043c\u0432\u043e\u043b + add bubble comment communicate feedback message new plus speech symbol',
    Margin: '\u0434\u0438\u0437\u0430\u0439\u043d \u043c\u0430\u043a\u0435\u0442 \u043d\u0430\u0431\u0438\u0432\u043a\u0430 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c design layout padding size square',
    MarkAsUnread:
        '\u043e\u043a\u0443\u0442\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0441\u044c\u043c\u043e \u043f\u043e\u0447\u0442\u0430 \u043f\u043e\u0447\u0442\u043e\u0432\u044b\u0439 \u041f\u043e\u043b\u0443\u0447\u0430\u0442\u044c \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c envelop letter mail postal receive send',
    MarkChatRead:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u043f\u0443\u0437\u044b\u0440\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u043b\u0430\u043b \u0440\u0435\u0447\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve bubble check comment communicate complete done message ok select sent speech tick verified yes',
    MarkChatUnread:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u043a\u0440\u0443\u0433 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0440\u0435\u0447\u044c bubble circle comment communicate message notification speech',
    MarkEmailRead:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u043e\u043a\u0443\u0442\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043f\u043e\u0441\u043b\u0430\u043b \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u0434\u0430 approve check complete done envelop letter message note ok select send sent tick yes',
    MarkEmailUnread:
        '\u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043a\u0440\u0443\u0433 \u043e\u043a\u0443\u0442\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c check circle envelop letter message note notification send',
    Markunread:
        '\u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043a\u043e\u043d\u0432\u0435\u0440\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c email envelope letter message send',
    MarkUnreadChatAlt:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u043a\u0440\u0443\u0433 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0440\u0435\u0447\u044c bubble circle comment communicate message notification speech',
    MarkunreadMailbox:
        '\u0434\u043e\u0441\u0442\u0430\u0432\u043b\u044f\u0442\u044c \u043e\u043a\u0443\u0442\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0441\u044c\u043c\u043e \u043f\u043e\u0447\u0442\u043e\u0432\u044b\u0439 \u043f\u043e\u0447\u0442\u043e\u0432\u044b\u0439 \u044f\u0449\u0438\u043a \u041f\u043e\u043b\u0443\u0447\u0430\u0442\u044c \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c deliver envelop letter postal postbox receive send',
    Masks: '\u0432\u043e\u0437\u0434\u0443\u0445\u0430 \u043f\u043e\u043a\u0440\u044b\u0442\u0438\u0435 COVID-19 \u043b\u0438\u0446\u043e \u0431\u043e\u043b\u044c\u043d\u0438\u0446\u0430 \u043c\u0435\u0434\u0438\u0446\u0438\u043d\u0441\u043a\u0438\u0439 \u0437\u0430\u0433\u0440\u044f\u0437\u043d\u0435\u043d\u0438\u0435 \u0437\u0430\u0449\u0438\u0442\u0430 \u0440\u0435\u0441\u043f\u0438\u0440\u0430\u0442\u043e\u0440 \u0431\u043e\u043b\u044c\u043d\u043e\u0439 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 air cover covid face hospital medical pollution protection respirator sick social',
    Maximize:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043b\u0438\u043d\u0438\u044f \u044d\u043a\u0440\u0430\u043d \u0444\u043e\u0440\u043c\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application components design interface line screen shape ui ux website',
    MediaBluetoothOff:
        '\u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u0441\u0442\u0440\u0438\u0436\u043a\u0430 \u0441\u0438\u0433\u043d\u0430\u043b \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection connectivity device disabled enabled music note offline paring signal slash symbol wireless',
    MediaBluetoothOn:
        '\u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043e\u043d\u043b\u0430\u0439\u043d \u0441\u0442\u0440\u0438\u0436\u043a\u0430 \u0441\u0438\u0433\u043d\u0430\u043b \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection connectivity device disabled enabled music note off online paring signal slash symbol wireless',
    Mediation:
        '\u0430\u043b\u044c\u0442\u0435\u0440\u043d\u0430\u0442\u0438\u0432\u0430 \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043c\u043f\u0440\u043e\u043c\u0438\u0441\u0441 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0442\u043e\u0447\u043a\u0438 \u041f\u0435\u0440\u0435\u0433\u043e\u0432\u043e\u0440\u044b \u043f\u0430\u0440\u0442\u0438\u044f \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u0441\u0442\u0440\u0443\u043a\u0442\u0443\u0440\u0430 alternative arrows compromise direction dots negotiation party right structure',
    MedicalInformation:
        '\u0437\u043d\u0430\u0447\u043e\u043a \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0437\u0434\u043e\u0440\u043e\u0432\u044c\u0435 \u044f \u0431\u044b \u0421\u0435\u0440\u0432\u0438\u0441\u044b badge card health id services',
    MedicalServices:
        '\u043f\u043e\u043c\u043e\u0433\u0430\u0442\u044c \u0441\u0443\u043c\u043a\u0430 \u043f\u043e\u0440\u0442\u0444\u0435\u043b\u044c \u0447\u0440\u0435\u0437\u0432\u044b\u0447\u0430\u0439\u043d\u0430\u044f \u0441\u0438\u0442\u0443\u0430\u0446\u0438\u044f \u043f\u0435\u0440\u0432\u044b\u0439 \u041a\u043e\u043c\u043f\u043b\u0435\u043a\u0442 \u043b\u0435\u043a\u0430\u0440\u0441\u0442\u0432\u0435\u043d\u043d\u043e\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e aid bag briefcase emergency first kit medicine',
    Medication:
        '\u0434\u043e\u043a\u0442\u043e\u0440 \u043b\u0435\u043a\u0430\u0440\u0441\u0442\u0432\u043e \u0447\u0440\u0435\u0437\u0432\u044b\u0447\u0430\u0439\u043d\u0430\u044f \u0441\u0438\u0442\u0443\u0430\u0446\u0438\u044f \u0431\u043e\u043b\u044c\u043d\u0438\u0446\u0430 \u043b\u0435\u043a\u0430\u0440\u0441\u0442\u0432\u0435\u043d\u043d\u043e\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u0430\u043f\u0442\u0435\u043a\u0430 \u0442\u0430\u0431\u043b\u0435\u0442\u043a\u0438 \u0440\u0435\u0446\u0435\u043f\u0442 doctor drug emergency hospital medicine pharmacy pills prescription',
    MedicationLiquid:
        '+ \u0431\u0443\u0442\u044b\u043b\u043a\u0430 \u0434\u043e\u043a\u0442\u043e\u0440 \u043b\u0435\u043a\u0430\u0440\u0441\u0442\u0432\u043e \u0437\u0434\u043e\u0440\u043e\u0432\u044c\u0435 \u0431\u043e\u043b\u044c\u043d\u0438\u0446\u0430 \u043b\u0435\u043a\u0430\u0440\u0441\u0442\u0432\u0430 \u043b\u0435\u043a\u0430\u0440\u0441\u0442\u0432\u0435\u043d\u043d\u043e\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u0430\u043f\u0442\u0435\u043a\u0430 \u043b\u043e\u0436\u043a\u0430 \u0441\u0443\u0434\u043d\u043e + bottle doctor drug health hospital medications medicine pharmacy spoon vessel',
    MeetingRoom:
        '\u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0434\u0432\u0435\u0440\u043d\u043e\u0439 \u043f\u0440\u043e\u0435\u043c \u0432\u0445\u043e\u0434 \u0434\u043e\u043c \u0434\u043e\u043c \u0438\u043d\u0442\u0435\u0440\u044c\u0435\u0440 \u0432\u044b\u0439\u0442\u0438 \u043e\u0444\u0438\u0441 \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u043c \u043c\u0435\u0441\u0442\u0430 \u0432\u044b\u0445\u043e\u0434 building doorway entrance home house interior logout office open places signout',
    Memory: '\u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0447\u0438\u043f \u0446\u0438\u0444\u0440\u043e\u0432\u043e\u0439 \u043c\u0438\u043a\u0440\u043e \u043f\u0440\u043e\u0446\u0435\u0441\u0441\u043e\u0440 \u0441\u0434 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 card chip digital micro processor sd storage',
    Menu: '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0433\u0430\u043c\u0431\u0443\u0440\u0433\u0435\u0440 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043b\u0438\u043d\u0438\u0438 \u043f\u043b\u0435\u0439\u043b\u0438\u0441\u0442 \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application components hamburger interface lines playlist screen ui ux website',
    MenuBook:
        '\u0441\u0442\u043e\u043b\u043e\u0432\u0430\u044f \u0435\u0434\u0430 \u0435\u0434\u0430 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d dining food meal page restaurant',
    MenuOpen:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0448\u0435\u0432\u0440\u043e\u043d \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0433\u0430\u043c\u0431\u0443\u0440\u0433\u0435\u0440 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043b\u0438\u043d\u0438\u0438 \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application arrow chevron components hamburger interface left lines screen ui ux website',
    Merge: '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path route sign traffic',
    MergeType:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u043c\u0431\u0438\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0444\u043e\u0440\u043c\u0430\u0442 \u0442\u0435\u043a\u0441\u0442 arrow combine direction format text',
    Message:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0440\u0435\u0447\u044c \u0440\u0430\u0437\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c \u0442\u0435\u043a\u0441\u0442 bubble chat comment communicate feedback speech talk text',
    Mic: '\u0441\u043b\u0443\u0445 \u043c\u0438\u043a\u0440\u043e\u0444\u043e\u043d \u0448\u0443\u043c \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u043e\u0438\u0441\u043a \u0437\u0432\u0443\u043a \u0440\u0435\u0447\u044c \u0433\u043e\u043b\u043e\u0441 hearing microphone noise record search sound speech voice',
    MicExternalOff:
        '\u0430\u0443\u0434\u0438\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043c\u0438\u043a\u0440\u043e\u0444\u043e\u043d \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0432\u0443\u043a \u0433\u043e\u043b\u043e\u0441 audio disabled enabled microphone slash sound voice',
    MicExternalOn:
        '\u0430\u0443\u0434\u0438\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043c\u0438\u043a\u0440\u043e\u0444\u043e\u043d \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0432\u0443\u043a \u0433\u043e\u043b\u043e\u0441 audio disabled enabled microphone off slash sound voice',
    MicNone:
        '\u0441\u043b\u0443\u0445 \u043c\u0438\u043a\u0440\u043e\u0444\u043e\u043d \u0448\u0443\u043c \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u0437\u0432\u0443\u043a \u0433\u043e\u043b\u043e\u0441 hearing microphone noise record sound voice',
    MicOff: '\u0430\u0443\u0434\u0438\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0441\u043b\u0443\u0445 \u043c\u0438\u043a\u0440\u043e\u0444\u043e\u043d \u0448\u0443\u043c \u0437\u0430\u043f\u0438\u0441\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0432\u0443\u043a \u0433\u043e\u043b\u043e\u0441 audio disabled enabled hearing microphone noise recording slash sound voice',
    Microwave:
        '\u043f\u0440\u0438\u0431\u043e\u0440 \u043f\u0440\u0438\u0433\u043e\u0442\u043e\u0432\u043b\u0435\u043d\u0438\u0435 \u0435\u0434\u044b \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u043d\u0430\u0433\u0440\u0435\u0432\u0430\u0442\u044c \u0434\u043e\u043c \u0434\u043e\u043c \u043a\u0443\u0445\u043d\u044f \u043c\u0430\u0448\u0438\u043d\u0430 appliance cooking electric heat home house kitchen machine',
    MilitaryTech:
        '\u0430\u0440\u043c\u0438\u044f \u043d\u0430\u0433\u0440\u0430\u0434\u0430 \u0437\u043d\u0430\u0447\u043e\u043a \u0447\u0435\u0441\u0442\u044c \u043c\u0435\u0434\u0430\u043b\u044c \u0446\u0435\u043d\u0430 \u0437\u0430\u043a\u0430\u0437 \u043f\u0440\u0438\u0432\u0438\u043b\u0435\u0433\u0438\u044f \u043f\u0440\u0438\u0437 \u043a\u043b\u0430\u0441\u0441\u0438\u0444\u0438\u0446\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043d\u0430\u0433\u0440\u0430\u0434\u0430 \u043b\u0435\u043d\u0442\u0430 \u0441\u043e\u043b\u0434\u0430\u0442 \u0437\u0432\u0435\u0437\u0434\u0430 \u0441\u0442\u0430\u0442\u0443\u0441 \u0442\u0440\u043e\u0444\u0435\u0439 \u043f\u043e\u0431\u0435\u0434\u0438\u0442\u0435\u043b\u044c army award badge honor medal merit order privilege prize rank reward ribbon soldier star status trophy winner',
    Minimize:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u043b\u0438\u043d\u0438\u044f \u044d\u043a\u0440\u0430\u043d \u0444\u043e\u0440\u043c\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application components design interface line screen shape ui ux website',
    MinorCrash:
        '\u043d\u0435\u0441\u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0441\u043b\u0443\u0447\u0430\u0439 \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u0441\u0442\u043e\u043b\u043a\u043d\u043e\u0432\u0435\u043d\u0438\u0435 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f accident automobile cars collision directions maps public transportation vehicle',
    MissedVideoCall:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0430\u043c\u0435\u0440\u0430 \u0441\u044a\u0435\u043c\u043a\u0438 \u0444\u0438\u043b\u044c\u043c\u0430 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u0432\u0438\u0434\u0435\u043e\u0441\u044a\u0435\u043c\u043a\u0430 arrow camera filming hardware image motion picture record videography',
    Mms: '\u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0435\u0439\u0437\u0430\u0436 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0433\u043e\u0440\u044b \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0440\u0435\u0447\u044c bubble chat comment communicate feedback image landscape message mountains multimedia photography picture speech',
    MobiledataOff:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043d\u0438\u0437 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0432\u0432\u0435\u0440\u0445 Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 arrow disabled down enabled internet network on slash speed up wifi wireless',
    MobileFriendly:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u043a\u043b\u0435\u0442\u043a\u0430 \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 Android approve cell check complete device done hardware iOS mark ok phone select tablet tick validate verified yes',
    MobileOff:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0442\u0438\u0448\u0438\u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android cell device disabled enabled hardware iOS phone silence slash tablet',
    MobileScreenShare:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0437\u0435\u0440\u043a\u0430\u043b\u043e \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u0440\u0438\u043d\u043a\u0430\u0441\u0442 \u043f\u043e\u0442\u043e\u043a\u043e\u0432\u043e\u0435 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 Android arrow cell device hardware iOS mirror monitor phone screencast streaming tablet tv wireless',
    Mode: '\u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0447\u0435\u0440\u043d\u043e\u0432\u0438\u043a \u0440\u0438\u0441\u043e\u0432\u0430\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c compose create draft draw edit pencil write',
    ModeComment:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0420\u0435\u0436\u0438\u043c \u0440\u0435\u0447\u044c bubble chat comment communicate feedback message mode speech',
    ModeEdit:
        '\u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0447\u0435\u0440\u043d\u043e\u0432\u0438\u043a \u0440\u0438\u0441\u043e\u0432\u0430\u0442\u044c \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c compose create draft draw pencil write',
    ModeEditOutline:
        '\u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0447\u0435\u0440\u043d\u043e\u0432\u0438\u043a \u0440\u0438\u0441\u043e\u0432\u0430\u0442\u044c \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c compose create draft draw pencil write',
    ModeFanOff:
        '\u0432\u043e\u0437\u0434\u0443\u0445\u0430 \u043a\u043e\u043d\u0434\u0438\u0446\u0438\u043e\u043d\u0435\u0440 \u041a\u0440\u0443\u0442\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0433\u043d\u0435\u0437\u0434\u043e \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 air conditioner cool disabled enabled nest slash',
    ModelTraining:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043b\u0430\u043c\u043f\u043e\u0447\u043a\u0430 \u0438\u0434\u0435\u044f \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u043b\u0435\u0433\u043a\u0438\u0439 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0441\u0441\u0442\u0430\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u0440\u0430\u0449\u0430\u0442\u044c arrow bulb idea inprogress light loading refresh renew restore reverse rotate',
    ModeNight:
        '\u0442\u0435\u043c\u043d\u044b\u0439 \u0431\u0435\u0441\u043f\u043e\u043a\u043e\u0438\u0442\u044c \u043b\u0443\u043d\u0430 \u0441\u043f\u0430\u0442\u044c \u043f\u043e\u0433\u043e\u0434\u0430 dark disturb moon sleep weather',
    ModeOfTravel:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 arrow destination direction location maps pin place stop transportation trip',
    ModeStandby:
        '\u0431\u0435\u0441\u043f\u043e\u043a\u043e\u0438\u0442\u044c \u0441\u0438\u043b\u0430 \u0441\u043f\u0430\u0442\u044c \u0446\u0435\u043b\u044c disturb power sleep target',
    MonetizationOn:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043a\u0440\u0443\u0433 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u0432\u044b\u0433\u043e\u0434\u0430 \u0440\u0430\u0441\u043f\u0440\u043e\u0434\u0430\u0436\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill card cash circle coin commerce cost credit currency dollars finance money online payment price profit sale shopping symbol',
    Money: '100 \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0446\u0438\u0444\u0440\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u0432\u044b\u0433\u043e\u0434\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b 100 bill card cash coin commerce cost credit currency digit dollars finance number online payment price profit shopping symbol',
    MoneyOff:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0442\u0435\u043b\u0435\u0436\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u0432\u044b\u0433\u043e\u0434\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043c\u0432\u043e\u043b bill card cart cash coin commerce credit currency disabled dollars enabled finance money online payment price profit shopping slash symbol',
    MoneyOffCsred:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0442\u0435\u043b\u0435\u0436\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043c\u0432\u043e\u043b bill card cart cash coin commerce credit currency disabled dollars enabled online payment shopping slash symbol',
    Monitor:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0445\u0440\u043e\u043c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u044d\u043a\u0440\u0430\u043d \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android chrome device display hardware iOS mac screen web window',
    MonitorHeart:
        '\u0438\u0441\u0445\u043e\u0434\u043d\u044b\u0439 \u0443\u0440\u043e\u0432\u0435\u043d\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0442\u0430\u043a \u0434\u0430\u043b\u0435\u0435 \u0444\u0438\u0442\u043d\u0435\u0441 \u0437\u0434\u043e\u0440\u043e\u0432\u044c\u0435 \u043c\u0435\u0434\u0438\u0446\u0438\u043d\u0441\u043a\u0438\u0439 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u0442\u044c baseline device ecc fitness health medical track',
    MonitorWeight:
        '\u0442\u0435\u043b\u043e \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0434\u0438\u0435\u0442\u0430 \u0437\u0434\u043e\u0440\u043e\u0432\u044c\u0435 \u0448\u043a\u0430\u043b\u0430 \u0443\u043c\u043d\u0430\u044f body device diet health scale smart',
    MonochromePhotos:
        '\u0447\u0435\u0440\u043d\u044b\u0439 \u043a\u0430\u043c\u0435\u0440\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0431\u0435\u043b\u044b\u0439 black camera image photography picture white',
    Mood: '\u044d\u043c\u043e\u0434\u0437\u0438 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u044d\u043c\u043e\u0446\u0438\u0438 \u0432\u044b\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u043b\u0438\u0446\u043e \u0447\u0443\u0432\u0441\u0442\u0432\u0430 \u0440\u0430\u0434\u043e\u0441\u0442\u043d\u044b\u0439 \u0441\u0447\u0430\u0441\u0442\u044c\u0435 \u0441\u0447\u0430\u0441\u0442\u043b\u0438\u0432\u044b\u0439 \u043a\u0430\u043a \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0434\u043e\u0432\u043e\u043b\u044c\u043d\u044b\u0439 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u0443\u043b\u044b\u0431\u0430\u0435\u0442\u0441\u044f \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043e\u043f\u0440\u043e\u0441 emoji emoticon emotions expressions face feelings glad happiness happy like person pleased smiley smiling social survey',
    MoodBad:
        '\u0440\u0430\u0437\u043e\u0447\u0430\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043d\u0435 \u043d\u0440\u0430\u0432\u0438\u0442\u0441\u044f \u044d\u043c\u043e\u0434\u0437\u0438 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u044d\u043c\u043e\u0446\u0438\u0438 \u0432\u044b\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u043b\u0438\u0446\u043e \u0447\u0443\u0432\u0441\u0442\u0432\u0430 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043e\u043f\u0440\u043e\u0441 \u043d\u0435\u0441\u0447\u0430\u0441\u0442\u044c\u0435 \u043d\u0435\u0441\u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u043d\u0435\u0434\u043e\u0432\u043e\u043b\u044c\u043d\u044b\u0439 \u043d\u0435 \u0443\u043b\u044b\u0431\u0430\u0442\u044c\u0441\u044f \u043d\u0435\u0443\u043b\u044b\u0431\u0447\u0438\u0432\u044b\u0439 disappointment dislike emoji emoticon emotions expressions face feelings person rating smiley social survey unhappiness unhappy unpleased unsmile unsmiling',
    Moped: '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0432\u0435\u043b\u043e\u0441\u0438\u043f\u0435\u0434 \u043c\u0430\u0448\u0438\u043d\u044b \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043c\u043e\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u043d\u043d\u044b\u0439 \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0441\u0430\u043c\u043e\u043a\u0430\u0442 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0432\u0435\u0441\u043f\u0430 automobile bike cars direction maps motorized public scooter transportation vehicle vespa',
    More: '3 \u0430\u0440\u0445\u0438\u0432 \u0437\u043d\u0430\u0447\u043e\u043a \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u0442\u043e\u0447\u043a\u0438 \u0442\u0430\u043a \u0434\u0430\u043b\u0435\u0435 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u043e\u0442\u0441\u0442\u0443\u043f \u044d\u0442\u0438\u043a\u0435\u0442\u043a\u0430 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u0441\u043f\u0430\u0441\u0442\u0438 \u043f\u0435\u0447\u0430\u0442\u044c \u043d\u0430\u043a\u043b\u0435\u0439\u043a\u0430 \u0432\u043a\u043b\u0430\u0434\u043a\u0430 \u044f\u0440\u043b\u044b\u043a \u0442\u0440\u0438 3 archive badge bookmark dots etc favorite indent label remember save stamp sticker tab tag three',
    MoreHoriz:
        '3 \u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0442\u043e\u0447\u043a\u0438 \u0442\u0430\u043a \u0434\u0430\u043b\u0435\u0435 \u0433\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u044b\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 iOS \u0432 \u043e\u0436\u0438\u0434\u0430\u043d\u0438\u0438 \u044d\u043a\u0440\u0430\u043d \u0441\u0442\u0430\u0442\u0443\u0441 \u0442\u0440\u0438 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 3 application components dots etc horizontal interface ios pending screen status three ui ux website',
    MoreTime:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0441\u0438\u043c\u0432\u043e\u043b + add clock date new plus schedule symbol',
    MoreVert:
        '3 \u0430\u043d\u0434\u0440\u043e\u0438\u0434 \u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0442\u043e\u0447\u043a\u0438 \u0442\u0430\u043a \u0434\u0430\u043b\u0435\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u044d\u043a\u0440\u0430\u043d \u0442\u0440\u0438 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0432\u0435\u0440\u0442\u0438\u043a\u0430\u043b\u044c\u043d\u044b\u0439 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 3 android application components dots etc interface screen three ui ux vertical website',
    Mosque: '\u0438\u0441\u043b\u0430\u043c\u0441\u043a\u0438\u0439 \u043c\u0435\u0447\u0435\u0442\u044c \u043c\u0443\u0441\u0443\u043b\u044c\u043c\u0430\u043d\u0438\u043d \u0440\u0435\u043b\u0438\u0433\u0438\u044f \u0434\u0443\u0445\u043e\u0432\u043d\u044b\u0439 \u043f\u043e\u043a\u043b\u043e\u043d\u0435\u043d\u0438\u0435 islamic masjid muslim religion spiritual worship',
    MotionPhotosAuto:
        '\u0410 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0430\u043d\u0438\u043c\u0430\u0446\u0438\u044f \u0430\u0432\u0442\u043e\u043c\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u0440\u0443\u0433 \u0448\u0440\u0438\u0444\u0442 \u0433\u0438\u0444\u043a\u0430 \u043f\u0438\u0441\u044c\u043c\u043e \u0436\u0438\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e A alphabet animation automatic character circle font gif letter live symbol text type video',
    MotionPhotosOff:
        '\u0430\u043d\u0438\u043c\u0430\u0446\u0438\u044f \u043a\u0440\u0443\u0433 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0432\u0438\u0434\u0435\u043e animation circle disabled enabled slash video',
    Mouse: '\u043d\u0430\u0436\u043c\u0438\u0442\u0435 \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u043a\u0443\u0440\u0441\u043e\u0440 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 click computer cursor device hardware wireless',
    MoveDown:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u041f\u0440\u044b\u0436\u043e\u043a \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043f\u0435\u0440\u0435\u0434\u0430\u0447\u0430 arrow direction jump navigation transfer',
    MoveToInbox:
        '\u0430\u0440\u0445\u0438\u0432 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0432\u043d\u0438\u0437 \u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043e\u043a\u0443\u0442\u044b\u0432\u0430\u0442\u044c \u0432\u0445\u043e\u0434\u044f\u0449\u0438\u0439 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0448\u0430\u0433 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043a archive arrow down email envelop incoming letter message move send to',
    MoveUp: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u041f\u0440\u044b\u0436\u043e\u043a \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043f\u0435\u0440\u0435\u0434\u0430\u0447\u0430 arrow direction jump navigation transfer',
    Movie: '\u043a\u0438\u043d\u043e \u0444\u0438\u043b\u044c\u043c \u0421\u041c\u0418 \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u0448\u0438\u0444\u0435\u0440 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0432\u0438\u0434\u0435\u043e \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c cinema film media screen show slate tv video watch',
    MovieCreation:
        '\u0445\u043b\u043e\u043f\u0443\u0448\u043a\u0430 \u0444\u0438\u043b\u044c\u043c \u0444\u0438\u043b\u044c\u043c\u044b \u0448\u0438\u0444\u0435\u0440 \u0432\u0438\u0434\u0435\u043e clapperboard film movies slate video',
    MovieFilter:
        '\u0445\u043b\u043e\u043f\u0443\u0448\u043a\u0430 \u0442\u0432\u043e\u0440\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u0438\u043b\u044c\u043c \u0444\u0438\u043b\u044c\u043c\u044b \u0448\u0438\u0444\u0435\u0440 \u0437\u0432\u0435\u0437\u0434\u044b \u0432\u0438\u0434\u0435\u043e clapperboard creation film movies slate stars video',
    Moving: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0432\u0432\u0435\u0440\u0445 arrow direction navigation travel up',
    Mp: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0438\u0441\u044c\u043c\u043e \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u044c \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character font image letter megapixel photography pixels quality resolution symbol text type',
    MultilineChart:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043b\u0438\u043d\u0438\u044f \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bars data diagram infographic line measure metrics multiple statistics tracking',
    MultipleStop:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u0442\u043e\u0447\u043a\u0438 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e arrows directions dots left maps navigation right',
    Museum: '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u041f\u0440\u0438\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u0432\u044b\u0441\u0442\u0430\u0432\u043a\u0430 \u0438\u0441\u0441\u043b\u0435\u0434\u043e\u0432\u0430\u0442\u044c \u043c\u0435\u0441\u0442\u043d\u044b\u0439 \u043a\u043e\u0441\u0442\u043d\u044b\u0439 \u043c\u043e\u0437\u0433 \u043c\u0435\u0441\u0442\u0430 \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0432\u0438\u0434\u0435\u0442\u044c \u043c\u0430\u0433\u0430\u0437\u0438\u043d \u0445\u0440\u0430\u043d\u0438\u0442\u044c \u0442\u0443\u0440 architecture attraction building estate event exhibition explore local palces places real see shop store tour',
    MusicNote:
        '\u0430\u0443\u0434\u0438\u043e \u0442\u0440\u0435\u043a \u043a\u043b\u044e\u0447 \u0437\u0432\u0443\u043a audiotrack key sound',
    MusicOff:
        '\u0430\u0443\u0434\u0438\u043e \u0442\u0440\u0435\u043a \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043a\u043b\u044e\u0447 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0432\u0443\u043a audiotrack disabled enabled key note on slash sound',
    MusicVideo:
        '\u0433\u0440\u0443\u043f\u043f\u0430 \u043c\u0432 \u0437\u0430\u043f\u0438\u0441\u044c \u044d\u043a\u0440\u0430\u043d \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c band mv recording screen tv watch',
    MyLocation:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u0442\u043e\u0447\u043a\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 destination direction maps navigation pin place point stop',
    Nat: '\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u044f communication',
    Nature: '\u043b\u0435\u0441 \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u0439 \u0432\u043d\u0435 \u043f\u0430\u0440\u043a \u0434\u0435\u0440\u0435\u0432\u043e \u043f\u0443\u0441\u0442\u044b\u043d\u044f forest outdoor outside park tree wilderness',
    NaturePeople:
        '\u041c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u044f \u0442\u0435\u043b\u043e \u043b\u0435\u0441 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u0439 \u0432\u043d\u0435 \u043f\u0430\u0440\u043a \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0434\u0435\u0440\u0435\u0432\u043e \u043f\u0443\u0441\u0442\u044b\u043d\u044f activity body forest human outdoor outside park person tree wilderness',
    NavigateBefore:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0441\u0442\u0430\u0432\u0438\u043b arrows direction left',
    NavigateNext:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e arrows direction right',
    Navigation:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u0442\u043e\u0447\u043a\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 arrow destination direction location maps pin place point stop',
    NearbyError:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert attention caution danger exclamation important mark notification symbol warning',
    NearbyOff:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled enabled on slash',
    NearMe: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u0442\u043e\u0447\u043a\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 arrow destination direction location maps navigation pin place point stop',
    NearMeDisabled:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u0442\u043e\u0447\u043a\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 destination direction enabled location maps navigation off pin place point slash',
    NestCamWiredStand:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0441\u044a\u0435\u043c\u043a\u0438 \u0444\u0438\u043b\u044c\u043c\u0430 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0432\u0438\u0434\u0435\u043e\u0441\u044a\u0435\u043c\u043a\u0430 camera filming hardware image motion picture videography',
    NetworkCell:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular data internet mobile phone speed wifi wireless',
    NetworkCheck:
        '\u0441\u0432\u044f\u0437\u044c \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u0435\u0442\u0440 \u0441\u0438\u0433\u043d\u0430\u043b \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection internet meter signal speed tick wifi wireless',
    NetworkLocked:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b\u0439 \u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u043e\u0448\u0438\u0431\u043a\u0430 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043d\u0435\u0442 \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u043d\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 alert available cellular connection data error internet mobile not privacy private protection restricted safety secure security service signal warning wifi wireless',
    NetworkPing:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b\u0439 \u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 IP \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 alert available cellular connection data internet ip mobile service signal wifi wireless',
    NetworkWifi:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular data internet mobile phone speed wireless',
    NewReleases:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043b\u043e\u043f\u0430\u0442\u044c\u0441\u044f \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0437\u0432\u0435\u0437\u0434\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert announcement attention burst caution danger error exclamation important mark notification star symbol warning',
    Newspaper:
        '\u0441\u0442\u0430\u0442\u044c\u044f \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u0436\u0443\u0440\u043d\u0430\u043b \u0421\u041c\u0418 \u0437\u0430\u043c\u0435\u0442\u043a\u0438 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0442\u0435\u043a\u0441\u0442 \u043f\u0438\u0448\u0443 article data document drive file folders magazine media notes page sheet slide text writing',
    NextPlan:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0440\u0443\u0433 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e arrow circle right',
    NextWeek:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0411\u0430\u0433\u0430\u0436 \u043f\u043e\u0440\u0442\u0444\u0435\u043b\u044c \u0431\u0438\u0437\u043d\u0435\u0441 \u0447\u0435\u043c\u043e\u0434\u0430\u043d arrow baggage briefcase business suitcase',
    Nfc: '\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u044f \u0434\u0430\u043d\u043d\u044b\u0435 \u043f\u043e\u043b\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043e\u043a\u043e\u043b\u043e \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 communication data field mobile near wireless',
    Nightlife:
        '\u0430\u043b\u043a\u043e\u0433\u043e\u043b\u044c \u0431\u0430\u0440 \u0431\u0443\u0442\u044b\u043b\u043a\u0430 \u043a\u043b\u0443\u0431 \u043a\u043e\u043a\u0442\u0435\u0439\u043b\u044c \u0442\u0430\u043d\u0435\u0446 \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0435\u0434\u0430 \u0441\u0442\u0430\u043a\u0430\u043d \u043b\u0438\u043a\u0435\u0440 \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u0432\u0438\u043d\u043e alcohol bar bottle club cocktail dance drink food glass liquor music note wine',
    Nightlight:
        '\u0442\u0435\u043c\u043d\u044b\u0439 \u0431\u0435\u0441\u043f\u043e\u043a\u043e\u0438\u0442\u044c \u0420\u0435\u0436\u0438\u043c \u043b\u0443\u043d\u0430 \u0441\u043f\u0430\u0442\u044c \u043f\u043e\u0433\u043e\u0434\u0430 dark disturb mode moon sleep weather',
    NightlightRound:
        '\u0442\u0435\u043c\u043d\u044b\u0439 \u043f\u043e\u043b\u043e\u0432\u0438\u043d\u0430 \u0420\u0435\u0436\u0438\u043c \u043b\u0443\u043d\u0430 dark half mode moon',
    NightShelter:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u043a\u0440\u043e\u0432\u0430\u0442\u044c \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0431\u0435\u0437\u0434\u043e\u043c\u043d\u044b\u0439 \u0434\u043e\u043c \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0441\u043f\u0430\u0442\u044c architecture bed building estate homeless house place real sleep',
    NightsStay:
        '\u043e\u0431\u043b\u0430\u043a\u043e \u043f\u043e\u043b\u0443\u043c\u0435\u0441\u044f\u0446 \u0442\u0435\u043c\u043d\u044b\u0439 \u0420\u0435\u0436\u0438\u043c \u043b\u0443\u043d\u0430 \u0444\u0430\u0437\u044b \u0442\u0438\u0448\u0438\u043d\u0430 \u0442\u0438\u0445\u0438\u0439 \u043d\u0435\u0431\u043e \u0432\u0440\u0435\u043c\u044f \u043f\u043e\u0433\u043e\u0434\u0430 cloud crescent dark mode moon phases silence silent sky time weather',
    NineK: '9000 9\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e 9000 9K alphabet character digit display font letter number pixels resolution symbol text type video',
    NineKPlus:
        '+ 9000 9\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e + 9000 9K alphabet character digit display font letter number pixels resolution symbol text type video',
    NineMp: '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digit font image letters megapixels number quality resolution symbol text type',
    NineteenMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    NoAccounts:
        '\u0430\u0432\u0430\u0442\u0430\u0440 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043c\u0438\u043d\u0438\u0430\u0442\u044e\u0440\u0430 \u043d\u0435\u0434\u043e\u0441\u0442\u0443\u043f\u0435\u043d \u043d\u0435\u043e\u043f\u043e\u0437\u043d\u0430\u043d\u043d\u044b\u0439 \u043d\u0435\u0438\u0437\u0432\u0435\u0441\u0442\u043d\u044b\u0439 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c avatar disabled enabled face human offline people person profile slash thumbnail unavailable unidentifiable unknown user',
    NoBackpack:
        '\u0430\u043a\u0441\u0435\u0441\u0441\u0443\u0430\u0440 \u043f\u043e\u0440\u0442\u0444\u0435\u043b\u044c \u0440\u044e\u043a\u0437\u0430\u043a \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c accessory bookbag knapsack travel',
    NoCell: '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android device disabled enabled hardware iOS mobile off phone slash tablet',
    NoCrash:
        '\u043d\u0435\u0441\u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0441\u043b\u0443\u0447\u0430\u0439 \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u0441\u0442\u043e\u043b\u043a\u043d\u043e\u0432\u0435\u043d\u0438\u0435 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u044b\u0439 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0432\u043e\u0439\u0442\u0438 \u043a\u0430\u0440\u0442\u044b \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0445\u043e\u0440\u043e\u0448\u043e \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0434\u0430 accident automobile cars check collision confirm correct direction done enter maps mark okay select tick transportation vehicle yes',
    NoDrinks:
        '\u0430\u043b\u043a\u043e\u0433\u043e\u043b\u044c \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0431\u0443\u0442\u044b\u043b\u043a\u0430 \u043a\u043e\u043a\u0442\u0435\u0439\u043b\u044c \u0435\u0434\u0430 \u043b\u0438\u043a\u0435\u0440 \u0432\u0438\u043d\u043e alcohol beverage bottle cocktail food liquor wine',
    NoEncryption:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0437\u0430\u043c\u043e\u043a \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043f\u0430\u0440\u043e\u043b\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled enabled lock off password safety security slash',
    NoEncryptionGmailerrorred:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled enabled locked off slash',
    NoFlash:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043c\u043e\u043b\u043d\u0438\u044f \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0443\u0434\u0430\u0440 \u043c\u043e\u043b\u043d\u0438\u0438 camera disabled enabled image lightning off on photography picture slash thunderbolt',
    NoFood: '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0411\u044b\u0441\u0442\u0440\u043e\u0435 \u043f\u0438\u0442\u0430\u043d\u0438\u0435 \u0433\u0430\u043c\u0431\u0443\u0440\u0433\u0435\u0440 \u0435\u0434\u0430 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 disabled drink enabled fastfood hamburger meal off on slash',
    NoiseAware:
        '\u0430\u0443\u0434\u0438\u043e \u043e\u0442\u043c\u0435\u043d\u0430 \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u0437\u0432\u0443\u043a audio cancellation music note sound',
    NoiseControlOff:
        '[\u043d\u0435 \u0432 \u0441\u0435\u0442\u0438] \u0430\u0443\u0434\u0438\u043e \u043e\u0441\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u043d\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0432\u0443\u043a [offline] audio aware cancellation disabled enabled music note slash sound',
    NoLuggage:
        '\u0411\u0430\u0433\u0430\u0436 \u043d\u0435\u0441\u0442\u0438 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0447\u0435\u043c\u043e\u0434\u0430\u043d \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c baggage carry disabled enabled off on slash suitcase travel',
    NoMeals:
        '\u0441\u0442\u043e\u043b\u043e\u0432\u0430\u044f \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0435\u0441\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0435\u0434\u0430 \u0432\u0438\u043b\u043a\u0430 \u043d\u043e\u0436 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043b\u043e\u0436\u043a\u0430 \u043f\u043e\u0441\u0443\u0434\u0430 dining disabled eat enabled food fork knife off restaurant slash spoon utensils',
    NoMeetingRoom:
        '\u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0434\u0432\u0435\u0440\u043d\u043e\u0439 \u043f\u0440\u043e\u0435\u043c \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u0445\u043e\u0434 \u0434\u043e\u043c \u0434\u043e\u043c \u0438\u043d\u0442\u0435\u0440\u044c\u0435\u0440 \u043e\u0444\u0438\u0441 \u043d\u0430 \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u043c \u043c\u0435\u0441\u0442\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 building disabled doorway enabled entrance home house interior office on open places slash',
    NoPhotography:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 camera disabled enabled image off on picture slash',
    NordicWalking:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0442\u0435\u043b\u043e \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u043f\u0435\u0448\u0438\u0439 \u0442\u0443\u0440\u0438\u0437\u043c \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0445\u043e\u0434\u043e\u043a athlete athletic body entertainment exercise hiking hobby human people person social sports travel walker',
    North: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0432\u0432\u0435\u0440\u0445 arrow directional maps navigation up',
    NorthEast:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0441\u0435\u0432\u0435\u0440 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u0432\u0432\u0435\u0440\u0445 arrow maps navigation noth right up',
    NorthWest:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0432\u0432\u0435\u0440\u0445 arrow directional left maps navigation up',
    NoSim: '\u043a\u0430\u043c\u0435\u0440\u0430 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0432\u044b\u0431\u0440\u0430\u0441\u044b\u0432\u0430\u0442\u044c \u0432\u0441\u0442\u0430\u0432\u043b\u044f\u0442\u044c \u041f\u0430\u043c\u044f\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 camera card device eject insert memory phone storage',
    NoStroller:
        '\u043c\u0430\u043b\u044b\u0448 \u0437\u0430\u0431\u043e\u0442\u0430 \u043f\u0435\u0440\u0435\u0432\u043e\u0437\u043a\u0430 \u0434\u0435\u0442\u0438 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043c\u043b\u0430\u0434\u0435\u043d\u0435\u0446 \u0440\u0435\u0431\u0435\u043d\u043e\u043a \u043d\u043e\u0432\u043e\u0440\u043e\u0436\u0434\u0435\u043d\u043d\u044b\u0439 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u0440\u043e\u0434\u0438\u0442\u0435\u043b\u0438 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0440\u0435\u0431\u0435\u043d\u043e\u043a, \u043d\u0430\u0447\u0438\u043d\u0430\u044e\u0449\u0438\u0439 \u0445\u043e\u0434\u0438\u0442\u044c \u043c\u043e\u043b\u043e\u0434\u043e\u0439 baby care carriage children disabled enabled infant kid newborn off on parents slash toddler young',
    NotAccessible:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u043e\u0441\u0442\u044c \u0442\u0435\u043b\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u043c\u043e\u0449\u044c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0438\u043d\u0432\u0430\u043b\u0438\u0434\u043d\u043e\u0435 \u043a\u0440\u0435\u0441\u043b\u043e accessibility body handicap help human person wheelchair',
    Note: '\u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0431\u0443\u043c\u0430\u0433\u0430 bookmark message paper',
    NoteAdd:
        '+ -\u0434\u043e\u043a \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u043d\u043e\u0432\u044b\u0439 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0431\u0443\u043c\u0430\u0433\u0430 \u043f\u043b\u044e\u0441 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0438\u0448\u0443 + -doc create data document drive file folders new page paper plus sheet slide symbol writing',
    NoteAlt:
        '\u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u043f\u0430\u043c\u044f\u0442\u043a\u0430 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0431\u0443\u043c\u0430\u0433\u0430 \u043f\u0438\u0448\u0443 clipboard document file memo page paper writing',
    Notes: '\u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0442\u0435\u043a\u0441\u0442 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0448\u0443 comment document text write writing',
    NotificationAdd:
        '+ \u0430\u043a\u0442\u0438\u0432\u043d\u044b\u0439 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u043f\u0435\u0440\u0435\u0437\u0432\u043e\u043d \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u044f \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u044f\u0442\u044c \u043f\u043b\u044e\u0441 \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0437\u0432\u0435\u043d\u0435\u0442\u044c \u0437\u0432\u0443\u043a \u0441\u0438\u043c\u0432\u043e\u043b + active alarm alert bell chime notifications notify plus reminder ring sound symbol',
    NotificationImportant:
        '! \u0430\u043a\u0442\u0438\u0432\u043d\u044b\u0439 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u0435\u0440\u0435\u0437\u0432\u043e\u043d \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u044f \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u044f\u0442\u044c \u043f\u0440\u043e\u0431\u043b\u0435\u043c\u0430 \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0437\u0432\u0435\u043d\u0435\u0442\u044c \u0437\u0432\u0443\u043a \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! active alarm alert announcement attention bell caution chime danger error exclamation feedback mark notifications notify problem reminder ring sound symbol warning',
    Notifications:
        '\u0430\u043a\u0442\u0438\u0432\u043d\u044b\u0439 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u043f\u0435\u0440\u0435\u0437\u0432\u043e\u043d \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u044f\u0442\u044c \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0437\u0432\u0435\u043d\u0435\u0442\u044c \u0437\u0432\u0443\u043a active alarm alert bell chime notify reminder ring sound',
    NotificationsActive:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u043f\u0435\u0440\u0435\u0437\u0432\u043e\u043d \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u044f\u0442\u044c \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0437\u0432\u043e\u043d \u0437\u0432\u0443\u043a alarm alert bell chime notify reminder ringing sound',
    NotificationsNone:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u044f\u0442\u044c \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0437\u0432\u0435\u043d\u0435\u0442\u044c \u0437\u0432\u0443\u043a alarm alert bell notify reminder ring sound',
    NotificationsOff:
        '\u0430\u043a\u0442\u0438\u0432\u043d\u044b\u0439 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u043f\u0435\u0440\u0435\u0437\u0432\u043e\u043d \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u044f\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0437\u0432\u0435\u043d\u0435\u0442\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0432\u0443\u043a active alarm alert bell chime disabled enabled notify offline reminder ring slash sound',
    NotificationsPaused:
        '--- \u0430\u043a\u0442\u0438\u0432\u043d\u044b\u0439 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0442\u044b \u0441\u043c\u043e\u0436\u0435\u0448\u044c \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u043f\u0435\u0440\u0435\u0437\u0432\u043e\u043d \u0438\u0433\u043d\u043e\u0440\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u044f\u0442\u044c \u041f\u0430\u0443\u0437\u0430 \u0442\u0438\u0445\u043e \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0437\u0432\u0435\u043d\u0435\u0442\u044c \u0441\u043f\u0430\u0442\u044c \u0432\u0437\u0434\u0440\u0435\u043c\u043d\u0443\u0442\u044c \u0437\u0432\u0443\u043a \u0437\u0437\u0437 --- active alarm aleet alert bell chime ignore notify pause quiet reminder ring sleep snooze sound zzz',
    NotInterested:
        '\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u044b\u0439 \u0437\u0430\u043f\u0440\u0435\u0449\u0435\u043d \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u043a\u0440\u0443\u0433 \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u043d\u0435 \u043d\u0440\u0430\u0432\u0438\u0442\u0441\u044f \u0432\u044b\u0445\u043e\u0434 \u0437\u0430\u0438\u043d\u0442\u0435\u0440\u0435\u0441\u043e\u0432\u0430\u043d \u043d\u0435\u0442 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0437\u0430\u043f\u0440\u0435\u0449\u0435\u043d\u043d\u044b\u0439 \u043f\u043e\u043a\u0438\u0434\u0430\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 allowed banned cancel circle close disabled dislike exit interested not off prohibited quit remove stop',
    NotListedLocation:
        '? \u043f\u043e\u043c\u043e\u0449\u044c \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043f\u043e\u043c\u043e\u0449\u044c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043f\u0443\u043d\u043a\u0442\u0443\u0430\u0446\u0438\u044f \u0432\u043e\u043f\u0440\u043e\u0441\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u0437\u043d\u0430\u043a \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b ? assistance destination direction help information maps pin place punctuation questionmark stop support symbol',
    NoTransfer:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0430\u0432\u0442\u043e\u0431\u0443\u0441 \u043c\u0430\u0448\u0438\u043d\u044b \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043a\u0430\u0440\u0442\u044b \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile bus cars direction disabled enabled maps off public slash transportation vehicle',
    NotStarted:
        '\u043a\u0440\u0443\u0433 \u0421\u041c\u0418 \u041f\u0430\u0443\u0437\u0430 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0432\u0438\u0434\u0435\u043e circle media pause play video',
    Numbers:
        '\u0446\u0438\u0444\u0440\u0430 \u0441\u0438\u043c\u0432\u043e\u043b digit symbol',
    OfflineBolt:
        '\u043a\u0440\u0443\u0433 \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0431\u044b\u0441\u0442\u0440\u043e \u0432\u0441\u043f\u044b\u0448\u043a\u0430 \u043c\u043e\u043b\u043d\u0438\u044f \u0438\u0441\u043a\u0440\u0430 \u0443\u0434\u0430\u0440 \u043c\u043e\u043b\u043d\u0438\u0438 circle electric fast flash lightning spark thunderbolt',
    OfflinePin:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u043a\u0440\u0443\u0433 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve checkmark circle complete done ok select tick validate verified yes',
    OfflineShare:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043b\u0435\u0442\u043a\u0430 \u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0441\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u0441\u0430\u0439\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android arrow cell connect device direction hardware iOS link mobile multiple phone right tablet',
    OilBarrel:
        '\u043a\u0430\u043f\u0435\u043b\u044c\u043a\u0430 \u0431\u0435\u043d\u0437\u0438\u043d \u0433\u043d\u0435\u0437\u0434\u043e \u0432\u043e\u0434\u0430 droplet gasoline nest water',
    OndemandVideo:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0445\u0440\u043e\u043c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android chrome desktop device hardware iOS mac monitor play television tv web window',
    OnDeviceTraining:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043b\u0430\u043c\u043f\u043e\u0447\u043a\u0430 \u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0438\u0434\u0435\u044f \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u043b\u0435\u0433\u043a\u0438\u0439 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043c\u043e\u0434\u0435\u043b\u044c \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0441\u0441\u0442\u0430\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0442\u0435\u043b\u0435\u0444\u043e\u043d arrow bulb call cell contact hardware idea inprogress light loading mobile model refresh renew restore reverse rotate telephone',
    OneK: '1000 1\u00a0\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e 1000 1K alphabet character digit display font letter number pixels resolution symbol text type video',
    OneKk: '10000 10 \u0442\u044b\u0441. \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e 10000 10K alphabet character digit display font letter number pixels resolution symbol text type video',
    OneKPlus:
        '+ 1000 1\u00a0\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e + 1000 1K alphabet character digit display font letter number pixels resolution symbol text type video',
    OnlinePrediction:
        '\u043b\u0430\u043c\u043f\u043e\u0447\u043a\u0430 \u0441\u0432\u044f\u0437\u044c \u0438\u0434\u0435\u044f \u043b\u0435\u0433\u043a\u0438\u0439 \u0441\u0435\u0442\u044c \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 bulb connection idea light network signal wireless',
    Opacity:
        '\u0446\u0432\u0435\u0442 \u043a\u0430\u043f\u0435\u043b\u044c\u043a\u0430 \u043e\u0442\u0442\u0435\u043d\u043e\u043a \u043f\u0435\u0440\u0435\u0432\u0435\u0440\u043d\u0443\u0442\u044b\u0439 \u0436\u0438\u0434\u043a\u043e\u0441\u0442\u044c \u043f\u0430\u043b\u0438\u0442\u0440\u0430 \u0442\u043e\u043d \u0432\u043e\u0434\u0430 color droplet hue inverted liquid palette tone water',
    OpenInBrowser:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u043d\u043e\u0432\u044b\u0439 \u0432\u0432\u0435\u0440\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e arrow box new up website window',
    OpenInFull:
        '\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0440\u0430\u0441\u0448\u0438\u0440\u044f\u0442\u044c \u0440\u0430\u0441\u0442\u0438 \u0448\u0430\u0433 action arrows expand grow move',
    OpenInNew:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0441\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u0441\u0430\u0439\u0442 \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e application arrow box components interface link screen ui ux website window',
    OpenInNewOff:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u044d\u043a\u0441\u043f\u043e\u0440\u0442 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043e\u043a\u043d\u043e arrow box disabled enabled export on slash window',
    OpenWith:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0441\u0448\u0438\u0440\u044f\u0442\u044c \u0448\u0430\u0433 arrows directional expand move',
    OtherHouses:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u043a\u043e\u0442\u0442\u0435\u0434\u0436 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043e\u0441\u0442\u0430\u0442\u044c\u0441\u044f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 architecture cottage estate home maps place real residence residential stay traveling',
    Outbound:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0440\u0443\u0433 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u0432\u0432\u0435\u0440\u0445 arrow circle directional right up',
    Outbox: '\u043f\u043e\u0447\u0442\u0430 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043f\u043e\u0441\u043b\u0430\u043b mail send sent',
    OutdoorGrill:
        '\u0431\u0430\u0440\u0431\u0435\u043a\u044e \u0431\u0430\u0440\u0431\u0435\u043a\u044e \u0431\u0430\u0440\u0431\u0435\u043a\u044e \u0434\u0440\u0435\u0432\u0435\u0441\u043d\u044b\u0439 \u0443\u0433\u043e\u043b\u044c \u043f\u0440\u0438\u0433\u043e\u0442\u043e\u0432\u043b\u0435\u043d\u0438\u0435 \u0435\u0434\u044b \u0434\u043e\u043c \u0434\u043e\u043c \u0432\u043d\u0435 barbecue barbeque bbq charcoal cooking home house outside',
    Outlet: '\u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0437\u0430\u0442\u044b\u043a\u0430\u0442\u044c \u0441\u0438\u043b\u0430 connecter electricity plug power',
    OutlinedFlag:
        '\u0441\u0442\u0440\u0430\u043d\u0430 \u0426\u0435\u043b\u044c \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043d\u0430\u0446\u0438\u044f \u043e\u0442\u0447\u0435\u0442 \u041d\u0430\u0447\u0430\u043b\u043e country goal mark nation report start',
    Padding:
        '\u0434\u0438\u0437\u0430\u0439\u043d \u043c\u0430\u043a\u0435\u0442 \u043f\u043e\u043b\u0435 \u0440\u0430\u0437\u043c\u0435\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c design layout margin size square',
    Pages: '\u0441\u0442\u0430\u0442\u044c\u044f \u0433\u043f\u043b\u044e\u0441 \u0431\u0443\u043c\u0430\u0433\u0430 \u043f\u043e\u0447\u0442\u0430 \u0437\u0432\u0435\u0437\u0434\u0430 article gplus paper post star',
    Pageview:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u0431\u0443\u043c\u0430\u0433\u0430 \u043f\u043e\u0438\u0441\u043a document find glass magnifying paper search',
    Paid: '\u043a\u0440\u0443\u0433 \u0432\u0430\u043b\u044e\u0442\u0430 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043f\u043b\u0430\u0442\u0430 \u0441\u0434\u0435\u043b\u043a\u0430 circle currency money payment transaction',
    Palette:
        '\u0438\u0441\u043a\u0443\u0441\u0441\u0442\u0432\u043e \u0446\u0432\u0435\u0442\u0430 \u0444\u0438\u043b\u044c\u0442\u0440\u044b \u043a\u0440\u0430\u0441\u043a\u0430 art colors filters paint',
    Panorama:
        '\u0443\u0433\u043e\u043b \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u041f\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0448\u0438\u0440\u043e\u043a\u0438\u0439 angle image mountains photography picture view wide',
    PanoramaFishEye:
        '\u0443\u0433\u043e\u043b \u043a\u0440\u0443\u0433 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0448\u0438\u0440\u043e\u043a\u0438\u0439 angle circle image photography picture wide',
    PanoramaHorizontal:
        '\u0443\u0433\u043e\u043b \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0448\u0438\u0440\u043e\u043a\u0438\u0439 angle image photography picture wide',
    PanoramaHorizontalSelect:
        '\u0443\u0433\u043e\u043b \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0448\u0438\u0440\u043e\u043a\u0438\u0439 angle image photography picture wide',
    PanoramaPhotosphere:
        '\u0443\u0433\u043e\u043b \u0433\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u044b\u0439 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0448\u0438\u0440\u043e\u043a\u0438\u0439 angle horizontal image photography picture wide',
    PanoramaPhotosphereSelect:
        '\u0443\u0433\u043e\u043b \u0433\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u044b\u0439 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0448\u0438\u0440\u043e\u043a\u0438\u0439 angle horizontal image photography picture wide',
    PanoramaVertical:
        '\u0443\u0433\u043e\u043b \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0448\u0438\u0440\u043e\u043a\u0438\u0439 angle image photography picture wide',
    PanoramaVerticalSelect:
        '\u0443\u0433\u043e\u043b \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0448\u0438\u0440\u043e\u043a\u0438\u0439 angle image photography picture wide',
    PanoramaWideAngle:
        '\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 image photography picture',
    PanoramaWideAngleSelect:
        '\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 image photography picture',
    PanTool:
        '\u0442\u044f\u0433\u0430 \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0420\u0443\u043a\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0448\u0430\u0433 \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0442\u0440\u043e\u0433\u0430\u0442\u044c \u0436\u0434\u0430\u0442\u044c drag fingers gesture hands human move scan stop touch wait',
    PanToolAlt:
        '\u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0420\u0443\u043a\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0448\u0430\u0433 \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 fingers gesture hands human move scan stop',
    Paragliding:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0442\u0435\u043b\u043e \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u043b\u0435\u0442\u0430\u0442\u044c \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0430\u0440\u0430\u0448\u044e\u0442 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u044b\u0436\u043a\u0438 \u0441 \u043f\u0430\u0440\u0430\u0448\u044e\u0442\u043e\u043c \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c athlete athletic body entertainment exercise fly hobby human parachute people person skydiving social sports travel',
    Park: '\u041f\u0440\u0438\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u0435 \u0441\u0432\u0435\u0436\u0438\u0439 \u043c\u0435\u0441\u0442\u043d\u044b\u0439 \u043f\u0440\u0438\u0440\u043e\u0434\u0430 \u0432\u043d\u0435 \u0437\u0430\u0432\u043e\u0434 \u0434\u0435\u0440\u0435\u0432\u043e attraction fresh local nature outside plant tree',
    PartyMode:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u043e\u0431\u044a\u0435\u043a\u0442\u0438\u0432 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 camera lens photography picture',
    Password:
        '\u043a\u043b\u044e\u0447 \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0448\u0442\u044b\u0440\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0437\u0432\u0435\u0437\u0434\u0430 \u0440\u0430\u0437\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c key login pin security star unlock',
    Pattern:
        '\u043a\u043b\u044e\u0447 \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u043f\u0430\u0440\u043e\u043b\u044c \u0448\u0442\u044b\u0440\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0437\u0432\u0435\u0437\u0434\u0430 \u0440\u0430\u0437\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c key login password pin security star unlock',
    Pause: '\u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u0432 \u043e\u0436\u0438\u0434\u0430\u043d\u0438\u0438 \u0438\u0433\u0440\u043e\u043a \u0441\u0442\u0430\u0442\u0443\u0441 \u0432\u0438\u0434\u0435\u043e \u0436\u0434\u0430\u0442\u044c controls media music pending player status video wait',
    PauseCircle:
        '\u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u0432\u0438\u0434\u0435\u043e controls media music video',
    PauseCircleFilled:
        '\u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u0432 \u043e\u0436\u0438\u0434\u0430\u043d\u0438\u0438 \u0441\u0442\u0430\u0442\u0443\u0441 \u0432\u0438\u0434\u0435\u043e \u0436\u0434\u0430\u0442\u044c controls media music pending status video wait',
    PauseCircleOutline:
        '\u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u0432 \u043e\u0436\u0438\u0434\u0430\u043d\u0438\u0438 \u0441\u0442\u0430\u0442\u0443\u0441 \u0432\u0438\u0434\u0435\u043e \u0436\u0434\u0430\u0442\u044c controls media music pending status video wait',
    PausePresentation:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0432 \u043e\u0436\u0438\u0434\u0430\u043d\u0438\u0438 \u044d\u043a\u0440\u0430\u043d \u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f \u0441\u043b\u0430\u0439\u0434\u044b \u0441\u0442\u0430\u0442\u0443\u0441 \u0436\u0434\u0430\u0442\u044c \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e www application desktop device pending screen share slides status wait website window www',
    Payment:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442\u043d\u0430\u044f \u043a\u0430\u0440\u0442\u0430 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0444\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill cash charge coin commerce cost creditcard currency dollars finance financial information money online price shopping symbol',
    Payments:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0441\u043b\u043e\u0439 \u0414\u0435\u043d\u044c\u0433\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043e\u043d\u043b\u0430\u0439\u043d \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill card cash coin commerce cost credit currency dollars finance layer money multiple online price shopping symbol',
    PedalBike:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0432\u0435\u043b\u043e\u0441\u0438\u043f\u0435\u0434 \u043c\u0430\u0448\u0438\u043d\u044b \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0441\u0430\u043c\u043e\u043a\u0430\u0442 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0432\u0435\u0441\u043f\u0430 automobile bicycle cars direction human maps public route scooter transportation vehicle vespa',
    Pending:
        '\u043a\u0440\u0443\u0433 \u0442\u043e\u0447\u043a\u0438 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043f\u0440\u043e\u0433\u0440\u0435\u0441\u0441 \u043e\u0436\u0438\u0434\u0430\u044e\u0449\u0438\u0439 circle dots loading progress waiting',
    PendingActions:
        '\u0431\u0443\u0444\u0435\u0440 \u043e\u0431\u043c\u0435\u043d\u0430 \u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f clipboard clock date document remember schedule time',
    Pentagon:
        '\u043f\u044f\u0442\u044c \u0444\u043e\u0440\u043c\u0430 \u0441\u0442\u043e\u0440\u043e\u043d\u044b five shape sides',
    People: '\u0443\u0447\u0435\u0442\u043d\u044b\u0435 \u0437\u0430\u043f\u0438\u0441\u0438 \u043a\u043e\u043c\u0438\u0442\u0435\u0442 \u0441\u043e\u043e\u0431\u0449\u0435\u0441\u0442\u0432\u043e \u043b\u0438\u0446\u043e \u0441\u0435\u043c\u044c\u044f \u0434\u0440\u0443\u0437\u044c\u044f \u0433\u0440\u0443\u043f\u043f\u0430 \u043b\u044e\u0434\u0438 \u0441\u0435\u0442\u044c \u043b\u0438\u0446\u0430 \u043f\u0440\u043e\u0444\u0438\u043b\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438 accounts committee community face family friends group humans network persons profiles social team users',
    PeopleAlt:
        '\u0443\u0447\u0435\u0442\u043d\u044b\u0435 \u0437\u0430\u043f\u0438\u0441\u0438 \u043a\u043e\u043c\u0438\u0442\u0435\u0442 \u043b\u0438\u0446\u043e \u0441\u0435\u043c\u044c\u044f \u0434\u0440\u0443\u0437\u044c\u044f \u0433\u0440\u0443\u043f\u043f\u0430 \u043b\u044e\u0434\u0438 \u0441\u0435\u0442\u044c \u043b\u0438\u0446\u0430 \u043f\u0440\u043e\u0444\u0438\u043b\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438 accounts committee face family friends group humans network persons profiles social team users',
    PeopleOutline:
        '\u0443\u0447\u0435\u0442\u043d\u044b\u0435 \u0437\u0430\u043f\u0438\u0441\u0438 \u043a\u043e\u043c\u0438\u0442\u0435\u0442 \u043b\u0438\u0446\u043e \u0441\u0435\u043c\u044c\u044f \u0434\u0440\u0443\u0437\u044c\u044f \u0433\u0440\u0443\u043f\u043f\u0430 \u043b\u044e\u0434\u0438 \u0441\u0435\u0442\u044c \u043b\u0438\u0446\u0430 \u043f\u0440\u043e\u0444\u0438\u043b\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438 accounts committee face family friends group humans network persons profiles social team users',
    Percent:
        '\u043c\u0430\u0442\u0435\u043c\u0430\u0442\u0438\u043a\u0430 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0438\u043c\u0432\u043e\u043b math number symbol',
    PermCameraMic:
        '\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043c\u0438\u043a\u0440\u043e\u0444\u043e\u043d \u043c\u0438\u043d \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043e\u0440\u0430\u0442\u043e\u0440 image microphone min photography picture speaker',
    PermContactCalendar:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u043f\u043e\u0432\u0435\u0441\u0442\u043a\u0430 \u0434\u043d\u044f \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account agenda date face human information people person profile schedule time user',
    PermDataSetting:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u043d\u0430\u0441\u0442\u0440\u043e\u0438\u0442\u044c \u043c\u0435\u0445\u0430\u043d\u0438\u0437\u043c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0441\u0435\u0442\u044c \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular configure gear information network settings wifi wireless',
    PermDeviceInformation:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u043b\u0435\u0442\u043a\u0430 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0432\u0430\u0436\u043d\u044b\u0439 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android alert announcement cell hardware iOS important mobile phone tablet',
    PermIdentity:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0441\u043f\u0430\u0441\u0442\u0438, \u043c\u0438\u043d\u0438\u0430\u0442\u044e\u0440\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account avatar face human information people person profile save, thumbnail user',
    PermMedia:
        '\u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u0434\u0430\u043d\u043d\u044b\u0435 \u043a\u0430\u0442\u0430\u043b\u043e\u0433\u0438 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 collection data directories document file folders images landscape mountains photography picture save storage',
    PermPhoneMsg:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0437\u0430\u043f\u0438\u0441\u044c \u0441\u043f\u0430\u0441\u0442\u0438 \u0440\u0435\u0447\u044c \u0442\u0435\u043b\u0435\u0444\u043e\u043d \u0433\u043e\u043b\u043e\u0441 bubble call cell chat comment communicate contact device message mobile recording save speech telephone voice',
    PermScanWifi:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0432\u044f\u0437\u044c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 alert announcement connection information internet network service signal wireless',
    Person: '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account avatar face human people profile user',
    PersonAdd:
        '+ \u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u043b\u0438\u0446\u043e \u0434\u0440\u0443\u0433 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043d\u043e\u0432\u044b\u0439 \u043b\u044e\u0434\u0438 \u043f\u043b\u044e\u0441 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c + account avatar face friend human new people plus profile symbol user',
    PersonAddAlt:
        '+ \u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u043f\u043b\u044e\u0441 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c + account face human people plus profile user',
    PersonAddDisabled:
        '+ \u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043d\u043e\u0432\u044b\u0439 \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043b\u044e\u0434\u0438 \u043f\u043b\u044e\u0441 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c + account enabled face human new offline people plus profile slash symbol user',
    PersonalVideo:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u0430\u043c\u0435\u0440\u0430 \u0445\u0440\u043e\u043c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android cam chrome desktop device hardware iOS mac monitor television tv web window',
    PersonOff:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account avatar disabled enabled face human people profile slash user',
    PersonOutline:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account avatar face human people profile user',
    PersonPin:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043b\u0438\u0446\u043e GPS \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043b\u044e\u0434\u0438 \u043c\u0435\u0441\u0442\u043e \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account avatar destination direction face gps human location maps people place profile stop user',
    PersonPinCircle:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043b\u0438\u0446\u043e GPS \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043b\u044e\u0434\u0438 \u043c\u0435\u0441\u0442\u043e \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account destination direction face gps human location maps people place profile stop user',
    PersonRemove:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u0423\u0434\u0430\u043b\u0438\u0442\u044c \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043c\u0438\u043d\u0443\u0441 \u043b\u044e\u0434\u0438 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0443\u0434\u0430\u043b\u0438\u0442\u044c \u0438\u0437 \u0434\u0440\u0443\u0437\u0435\u0439 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account avatar delete face human minus people profile unfriend user',
    PersonSearch:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u043b\u0438\u0446\u043e \u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u043b\u044e\u0434\u0438 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account avatar face find glass human look magnifying people profile user',
    PestControl:
        '\u043e\u0448\u0438\u0431\u043a\u0430 \u0438\u0441\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u044c \u043d\u0430\u0441\u0435\u043a\u043e\u043c\u044b\u0435 bug exterminator insects',
    PestControlRodent:
        '\u0438\u0441\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u044c \u043c\u044b\u0448\u0435\u0439 exterminator mice',
    Pets: '\u0436\u0438\u0432\u043e\u0442\u043d\u043e\u0435 \u043a\u043e\u0448\u043a\u0430 \u043a\u043e\u0433\u043e\u0442\u044c \u0441\u043e\u0431\u0430\u043a\u0430 \u0440\u0443\u043a\u0430 \u043b\u0430\u043f\u0430 animal cat claw dog hand paw',
    Phishing:
        '\u043b\u043e\u0432\u0438\u0442 \u0440\u044b\u0431\u0443 \u043c\u043e\u0448\u0435\u043d\u043d\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043a\u0440\u044e\u043a \u043c\u043e\u0448\u0435\u043d\u043d\u0438\u0447\u0435\u0441\u0442\u0432\u043e fishing fraud hook scam',
    Phone: '\u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u0447\u0430\u0442 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0442\u0435\u043b\u0435\u0444\u043e\u043d \u0442\u0435\u043a\u0441\u0442 call cell chat contact device hardware mobile telephone text',
    PhoneAndroid:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 cell device hardware iOS mobile tablet',
    PhoneBluetoothSpeaker:
        '\u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u0433\u043d\u0430\u043b \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043b\u0435\u0444\u043e\u043d \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 call cell connection connectivity contact device hardware mobile signal symbol telephone wireless',
    PhoneCallback:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0432\u043d\u0438\u0437 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0442\u0435\u043b\u0435\u0444\u043e\u043d arrow cell contact device down hardware mobile telephone',
    PhoneDisabled:
        '\u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0442\u0435\u043b\u0435\u0444\u043e\u043d call cell contact device enabled hardware mobile offline slash telephone',
    PhoneEnabled:
        '\u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0442\u0435\u043b\u0435\u0444\u043e\u043d call cell contact device hardware mobile telephone',
    PhoneForwarded:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u0442\u0435\u043b\u0435\u0444\u043e\u043d arrow call cell contact device direction hardware mobile right telephone',
    PhoneInTalk:
        '\u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u0441\u0432\u044f\u0437\u044c \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0434\u0430\u043d\u043d\u044b\u0435 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0437\u0432\u0443\u043a \u043e\u0440\u0430\u0442\u043e\u0440 \u0442\u0435\u043b\u0435\u0444\u043e\u043d \u0432\u043e\u043b\u043d\u044b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 call cell connection contact data device hardware mobile network scan service signal sound speaker telephone waves wireless',
    PhoneIphone:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u044f\u0431\u043b\u043e\u043a\u043e \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android apple cell device hardware iOS mobile tablet',
    Phonelink:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0445\u0440\u043e\u043c \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u043d\u0445\u0440\u043e\u043d\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u0430 Android chrome computer connect desktop device hardware iOS mac mobile sync tablet web windows',
    PhonelinkErase:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u043a\u043b\u0435\u0442\u043a\u0430 \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0432\u044b\u0445\u043e\u0434 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043d\u0435\u0442 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android cancel cell close connection device exit hardware iOS mobile no remove stop tablet',
    PhonelinkLock:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0441\u0442\u0435\u0440\u0435\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043f\u0430\u0440\u043e\u043b\u044c \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android cell connection device erase hardware iOS locked mobile password privacy private protection safety secure security tablet',
    PhonelinkOff:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0445\u0440\u043e\u043c \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043d\u0445\u0440\u043e\u043d\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u0430 Android chrome computer connect desktop device disabled enabled hardware iOS mac mobile slash sync tablet web windows',
    PhonelinkRing:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 Android cell connection data device hardware iOS mobile network service signal tablet wireless',
    PhonelinkSetup:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0432\u044b\u0437\u043e\u0432 \u0447\u0430\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0442\u0435\u043a\u0441\u0442 Android call chat device hardware iOS information mobile settings tablet text',
    PhoneLocked:
        '\u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043f\u0430\u0440\u043e\u043b\u044c \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0442\u0435\u043b\u0435\u0444\u043e\u043d call cell contact device hardware mobile password privacy private protection safety secure security telephone',
    PhoneMissed:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0442\u0435\u043b\u0435\u0444\u043e\u043d arrow call cell contact device hardware mobile telephone',
    PhonePaused:
        '\u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0442\u0435\u043b\u0435\u0444\u043e\u043d \u0436\u0434\u0430\u0442\u044c call cell contact device hardware mobile telephone wait',
    Photo: '\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 image mountains photography picture',
    PhotoAlbum:
        '\u0430\u0440\u0445\u0438\u0432 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u044d\u0442\u0438\u043a\u0435\u0442\u043a\u0430 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u044f\u0440\u043b\u044b\u043a archive bookmark image label library mountains photography picture ribbon save tag',
    PhotoCamera:
        '\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 image photography picture',
    PhotoCameraBack:
        '\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0437\u0430\u0434\u043d\u0438\u0439 image landscape mountains photography picture rear',
    PhotoCameraFront:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u043e\u0440\u0442\u0440\u0435\u0442 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account face human image people person photography picture portrait profile user',
    PhotoFilter:
        '\u0444\u0438\u043b\u044c\u0442\u0440\u044b \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0437\u0432\u0435\u0437\u0434\u044b filters image photography picture stars',
    PhotoLibrary:
        '\u0430\u043b\u044c\u0431\u043e\u043c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 album image mountains photography picture',
    PhotoSizeSelectActual:
        '\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 image mountains photography picture',
    PhotoSizeSelectLarge:
        '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0430\u043b\u044c\u0431\u043e\u043c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 adjust album editing image library mountains photography picture',
    PhotoSizeSelectSmall:
        '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0430\u043b\u044c\u0431\u043e\u043c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u043e\u043b\u044c\u0448\u043e\u0439 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 adjust album editing image large library mountains photography picture',
    Php: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u043a\u043e\u0431\u043a\u0438 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043a\u043e\u0434 css \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a \u0438\u043d\u0436\u0435\u043d\u0435\u0440\u0438\u044f \u0448\u0440\u0438\u0444\u0442 HTML \u043f\u0438\u0441\u044c\u043c\u043e \u041f\u043b\u0430\u0442\u0444\u043e\u0440\u043c\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet brackets character code css developer engineering font html letter platform symbol text type',
    Piano: '\u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 \u043a\u043b\u0430\u0432\u0438\u0430\u0442\u0443\u0440\u0430 \u043a\u043b\u044e\u0447\u0438 \u043c\u0443\u0437\u044b\u043a\u0430\u043b\u044c\u043d\u044b\u0439 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 instrument keyboard keys musical social',
    PianoOff:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 \u043a\u043b\u0430\u0432\u0438\u0430\u0442\u0443\u0440\u0430 \u043a\u043b\u044e\u0447\u0438 \u043c\u0443\u0437\u044b\u043a\u0430\u043b\u044c\u043d\u044b\u0439 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 disabled enabled instrument keyboard keys musical on slash social',
    PictureAsPdf:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0438\u0441\u044c\u043c\u043e \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character document file font image letter multiple photography symbol text type',
    PictureInPicture:
        '\u043e\u0431\u0440\u0435\u0437\u0430\u043d\u043d\u044b\u0439 \u043f\u0435\u0440\u0435\u043a\u0440\u044b\u0432\u0430\u0442\u044c \u0424\u043e\u0442\u043e \u0434\u043e\u043b\u0436\u043d\u043e\u0441\u0442\u044c \u0444\u043e\u0440\u043c\u0430 cropped overlap photo position shape',
    PictureInPictureAlt:
        '\u043e\u0431\u0440\u0435\u0437\u0430\u043d\u043d\u044b\u0439 \u043f\u0435\u0440\u0435\u043a\u0440\u044b\u0432\u0430\u0442\u044c \u0424\u043e\u0442\u043e \u0434\u043e\u043b\u0436\u043d\u043e\u0441\u0442\u044c \u0444\u043e\u0440\u043c\u0430 cropped overlap photo position shape',
    PieChart:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bars data diagram infographic measure metrics statistics tracking',
    PieChartOutline:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bars data diagram infographic measure metrics statistics tracking',
    Pin: '1 2 3 \u0446\u0438\u0444\u0440\u0430 \u043a\u043b\u044e\u0447 \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0432\u044b\u0439\u0442\u0438 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0430\u0440\u043e\u043b\u044c \u0448\u0430\u0431\u043b\u043e\u043d \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0437\u0432\u0435\u0437\u0434\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0440\u0430\u0437\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c 1 2 3 digit key login logout number password pattern security star symbol unlock',
    Pinch: '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043c\u043f\u0440\u0435\u0441\u0441 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u041f\u0430\u043b\u0435\u0446 \u043f\u043e\u043d\u044f\u0442\u044c \u0440\u0443\u043a\u0430 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0449\u0438\u043f\u0430\u0442\u044c \u0441\u0436\u0438\u043c\u0430\u0442\u044c \u043f\u043e\u0434\u043f\u0440\u0430\u0432\u0438\u0442\u044c arrows compress direction finger grasp hand navigation nip squeeze tweak',
    PinDrop:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 GPS \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043c\u0435\u0441\u0442\u043e \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 destination direction gps location maps navigation place stop',
    Pinterest:
        '\u043c\u0430\u0440\u043a\u0430 \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 brand logo social',
    PivotTableChart:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0431\u0430\u0440\u044b \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0441\u0435\u0442\u043a\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043b\u0438\u0441\u0442 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics arrows bars data diagram direction drive editing grid infographic measure metrics rotate sheet statistics tracking',
    Pix: '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0411\u0440\u0430\u0437\u0438\u043b\u0438\u044f \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043f\u043b\u0430\u0442\u0430 bill brazil card cash commerce credit currency finance money payment',
    Place: '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0448\u0442\u044b\u0440\u044c \u0442\u043e\u0447\u043a\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 destination direction location maps navigation pin point stop',
    Plagiarism:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0431\u0443\u043c\u0430\u0433\u0430 \u043f\u043e\u0438\u0441\u043a \u0432\u0438\u0434\u0435\u0442\u044c document find glass look magnifying page paper search see',
    PlayArrow:
        '\u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u0438\u0433\u0440\u043e\u043a \u041d\u0430\u0447\u0430\u043b\u043e \u0432\u0438\u0434\u0435\u043e controls media music player start video',
    PlayCircle:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u0432\u0438\u0434\u0435\u043e arrow controls media music video',
    PlayCircleFilled:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u041d\u0430\u0447\u0430\u043b\u043e \u0432\u0438\u0434\u0435\u043e arrow controls media music start video',
    PlayCircleFilledWhite: '\u041d\u0430\u0447\u0430\u043b\u043e start',
    PlayCircleOutline:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u041d\u0430\u0447\u0430\u043b\u043e \u0432\u0438\u0434\u0435\u043e arrow controls media music start video',
    PlayDisabled:
        '\u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0432\u0438\u0434\u0435\u043e controls enabled media music off slash video',
    PlayForWork:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0440\u0443\u0433 \u0432\u043d\u0438\u0437 Google \u043f\u043e\u043b\u043e\u0432\u0438\u043d\u0430 arrow circle down google half',
    PlayLesson:
        '\u0430\u0443\u0434\u0438\u043e \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u0446\u0438\u0444\u0440\u043e\u0432\u043e\u0439 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u043a\u043d\u0438\u0433\u0430 \u0443\u0440\u043e\u043a \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0447\u0442\u0435\u043d\u0438\u0435 \u043b\u0435\u043d\u0442\u0430 audio bookmark digital ebook lesson multimedia play reading ribbon',
    PlaylistAdd:
        '+ \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u041c\u0443\u0437\u044b\u043a\u0430 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0441\u0438\u043c\u0432\u043e\u043b \u0437\u0430\u0434\u0430\u0447\u0430 \u0432\u0441\u0435 + collection music new plus symbol task todo',
    PlaylistAddCheck:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u041c\u0443\u0437\u044b\u043a\u0430 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0437\u0430\u0434\u0430\u0447\u0430 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u0432\u0441\u0435 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve checkmark collection complete done music ok select task tick todo validate verified yes',
    PlaylistAddCheckCircle:
        '\u0430\u043b\u044c\u0431\u043e\u043c \u0445\u0443\u0434\u043e\u0436\u043d\u0438\u043a \u0430\u0443\u0434\u0438\u043e CD \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u0437\u0432\u0443\u043a \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u0442\u044c album artist audio cd collection mark music record sound track',
    PlaylistAddCircle:
        '\u0430\u043b\u044c\u0431\u043e\u043c \u0445\u0443\u0434\u043e\u0436\u043d\u0438\u043a \u0430\u0443\u0434\u0438\u043e CD \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u0437\u0432\u0443\u043a \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u0442\u044c album artist audio cd check collection mark music record sound track',
    PlaylistPlay:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u041c\u0443\u0437\u044b\u043a\u0430 arow arrow collection music',
    PlaylistRemove:
        '- \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u043c\u0438\u043d\u0443\u0441 \u041c\u0443\u0437\u044b\u043a\u0430 - collection minus music',
    Plumbing:
        '\u0441\u0442\u0440\u043e\u0438\u0442\u044c \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0438\u0441\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u0440\u0430\u0437\u043d\u043e\u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0440\u0435\u043c\u043e\u043d\u0442 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u044b \u0433\u0430\u0435\u0447\u043d\u044b\u0439 \u043a\u043b\u044e\u0447 build construction fix handyman repair tools wrench',
    PlusOne:
        '1 \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0446\u0438\u0444\u0440\u0430 \u0443\u0432\u0435\u043b\u0438\u0447\u0438\u0432\u0430\u0442\u044c \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0438\u043c\u0432\u043e\u043b 1 add digit increase number symbol',
    Podcasts:
        '\u0442\u0440\u0430\u043d\u0441\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u041a\u0430\u0441\u0442\u0438\u043d\u0433 \u0441\u0435\u0442\u044c \u0441\u0438\u0433\u043d\u0430\u043b \u043f\u0435\u0440\u0435\u0434\u0430\u0447\u0430 \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 broadcast casting network signal transmitting wireless',
    PointOfSale:
        '\u043f\u0440\u043e\u0432\u0435\u0440\u0438\u0442\u044c \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0430 \u0442\u043e\u0440\u0433\u043e\u0432\u0435\u0446 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u0437 \u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u0441\u0438\u0441\u0442\u0435\u043c\u0430 \u0441\u0434\u0435\u043b\u043a\u0430 checkout cost machine merchant money payment pos retail system transaction',
    Policy: '\u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0437\u0430\u043a\u043e\u043d\u043d\u044b\u0439 \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u043f\u043e\u0438\u0441\u043a \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0432\u0438\u0434\u0435\u0442\u044c \u0449\u0438\u0442 \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e certified find glass legal look magnifying privacy private protection search security see shield verified',
    Poll: '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0433\u0438\u0441\u0442\u043e\u0433\u0440\u0430\u043c\u043c\u0430 \u0431\u0430\u0440\u044b \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u043f\u0440\u043e\u0441 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 \u0433\u043e\u043b\u043e\u0441\u043e\u0432\u0430\u043d\u0438\u0435 analytics barchart bars data diagram infographic measure metrics statistics survey tracking vote',
    Polyline:
        '\u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0434\u0438\u0437\u0430\u0439\u043d \u0440\u0438\u0441\u043e\u0432\u0430\u0442\u044c \u0432\u0435\u043a\u0442\u043e\u0440 compose create design draw vector',
    Pool: '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u043f\u043b\u044f\u0436 \u0442\u0435\u043b\u043e \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043e\u043a\u0435\u0430\u043d \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043c\u0435\u0441\u0442\u0430 \u043c\u043e\u0440\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043f\u043b\u0430\u0432\u0430\u043d\u0438\u0435 \u0432\u043e\u0434\u0430 athlete athletic beach body entertainment exercise hobby human ocean people person places sea sports swimming water',
    PortableWifiOff:
        '\u0441\u0432\u044f\u0437\u0430\u043d\u043e \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u041f\u0440\u0438\u043c\u0435\u043d\u0435\u043d\u0438\u0435 \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connected connection data device disabled enabled internet network offline service signal slash usage wireless',
    Portrait:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0424\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account face human people person photo picture profile user',
    PostAdd:
        '+ \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u0432\u0435\u0449\u044c \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0431\u0443\u043c\u0430\u0433\u0430 \u043f\u043b\u044e\u0441 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0442\u0435\u043a\u0441\u0442 \u043f\u0438\u0448\u0443 + data document drive file folders item page paper plus sheet slide text writing',
    Power: '\u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u0448\u043d\u0443\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u043e\u043d\u043b\u0430\u0439\u043d \u0432\u044b\u0445\u043e\u0434 \u0437\u0430\u0442\u044b\u043a\u0430\u0442\u044c \u0440\u0430\u0437\u044a\u0435\u043c charge cord electrical online outlet plug socket',
    PowerInput:
        '\u041e\u043a\u0440\u0443\u0433 \u041a\u043e\u043b\u0443\u043c\u0431\u0438\u044f \u043b\u0438\u043d\u0438\u0438 \u043f\u043e\u0441\u0442\u0430\u0432\u043b\u044f\u0442\u044c dc lines supply',
    PowerOff:
        '\u043e\u0431\u0432\u0438\u043d\u0435\u043d\u0438\u0435 \u0448\u043d\u0443\u0440 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0430 \u0432\u044b\u0445\u043e\u0434 \u0437\u0430\u0442\u044b\u043a\u0430\u0442\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 charge cord disabled electrical enabled on outlet plug slash',
    PowerSettingsNew:
        '\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0441\u043f\u0430\u0441\u0442\u0438 \u043d\u0435\u0438\u0441\u043f\u0440\u0430\u0432\u043d\u043e\u0441\u0442\u044c information off save shutdown',
    PrecisionManufacturing:
        '\u0440\u0443\u043a\u0430 \u0430\u0432\u0442\u043e\u043c\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0446\u0435\u043f\u044c \u043a\u043e\u043d\u0432\u0435\u0439\u0435\u0440 \u043a\u0440\u0430\u043d \u0444\u0430\u0431\u0440\u0438\u043a\u0430 \u043f\u0440\u043e\u043c\u044b\u0448\u043b\u0435\u043d\u043d\u043e\u0441\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043c\u0435\u0445\u0430\u043d\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u043f\u0440\u043e\u0438\u0437\u0432\u043e\u0434\u0441\u0442\u0432\u043e \u0440\u0435\u043c\u043e\u043d\u0442 \u0440\u043e\u0431\u043e\u0442 \u043f\u043e\u0441\u0442\u0430\u0432\u043b\u044f\u0442\u044c \u0441\u043a\u043b\u0430\u0434 arm automatic chain conveyor crane factory industry machinery mechanical production repairing robot supply warehouse',
    PregnantWoman:
        '\u043c\u0430\u043b\u044b\u0448 \u0440\u043e\u0436\u0434\u0435\u043d\u0438\u0435 \u0442\u0435\u043b\u043e \u0436\u0435\u043d\u0441\u043a\u0438\u0439 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u0435\u0434\u0438 \u043c\u0430\u0442\u0435\u0440\u0438\u043d\u0441\u0442\u0432\u043e \u041c\u0430\u043c\u0430 \u043c\u0430\u0442\u044c \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0436\u0435\u043d\u0449\u0438\u043d\u044b baby birth body female human lady maternity mom mother people person user women',
    PresentToAll:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043f\u0440\u0435\u0437\u0435\u043d\u0442\u0430\u0446\u0438\u044f \u044d\u043a\u0440\u0430\u043d \u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f \u0441\u043b\u0430\u0439\u0434\u044b \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 arrow presentation screen share slides website',
    Preview:
        '\u0434\u0438\u0437\u0430\u0439\u043d \u0433\u043b\u0430\u0437 \u043c\u0430\u043a\u0435\u0442 \u043f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u044d\u043a\u0440\u0430\u043d \u0432\u0438\u0434\u0435\u0442\u044c \u043f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e www design eye layout reveal screen see show website window www',
    PriceChange:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0432\u043d\u0438\u0437 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0432\u0435\u0440\u0445 arrows bill card cash coin commerce cost credit currency dollars down finance money online payment shopping symbol up',
    PriceCheck:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043f\u043e\u043b\u043d\u044b\u0439 \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0414\u0435\u043d\u044c\u0433\u0438 Ok \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve bill card cash coin commerce complete cost credit currency dollars done finance mark money ok online payment select shopping symbol tick validate verified yes',
    Print: '\u0447\u0435\u0440\u043d\u043e\u0432\u0438\u043a \u0444\u0430\u043a\u0441 \u0447\u0435\u0440\u043d\u0438\u043b\u0430 \u043c\u0430\u0448\u0438\u043d\u0430 \u043e\u0444\u0438\u0441 \u0431\u0443\u043c\u0430\u0433\u0430 \u043f\u0440\u0438\u043d\u0442\u0435\u0440 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c draft fax ink machine office paper printer send',
    PrintDisabled:
        '\u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u0431\u0443\u043c\u0430\u0433\u0430 \u043f\u0440\u0438\u043d\u0442\u0435\u0440 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 enabled off on paper printer slash',
    PriorityHigh:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert attention caution danger error exclamation important mark notification symbol warning',
    PrivacyTip:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043f\u043e\u043c\u043e\u0449\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u0414\u0435\u0442\u0430\u043b\u0438 \u043f\u043e\u043c\u043e\u0449\u044c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0449\u0438\u0442 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e alert announcement announcment assistance certified details help information private protection security service shield support verified',
    ProductionQuantityLimits:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0442\u0435\u043b\u0435\u0436\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0414\u0435\u043d\u044c\u0433\u0438 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert attention bill card cart cash caution coin commerce credit currency danger dollars error exclamation important mark money notification online payment shopping symbol warning',
    Propane: '\u0433\u0430\u0437 \u0433\u043d\u0435\u0437\u0434\u043e gas nest',
    PropaneTank:
        '\u0431\u0430\u0440\u0431\u0435\u043a\u044e \u0433\u0430\u0437 \u0433\u0440\u0438\u043b\u044c \u0433\u043d\u0435\u0437\u0434\u043e bbq gas grill nest',
    Psychology:
        '\u043f\u043e\u0432\u0435\u0434\u0435\u043d\u0438\u0435 \u0442\u0435\u043b\u043e \u043c\u043e\u0437\u0433 \u043f\u043e\u0437\u043d\u0430\u0432\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u0444\u0443\u043d\u043a\u0446\u0438\u044f \u043c\u0435\u0445\u0430\u043d\u0438\u0437\u043c \u0433\u043b\u0430\u0432\u0430 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0438\u043d\u0442\u0435\u043b\u043b\u0435\u043a\u0442\u0443\u0430\u043b \u043f\u0441\u0438\u0445\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0440\u0430\u0437\u0443\u043c \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u0435\u0434\u043f\u043e\u0447\u0442\u0435\u043d\u0438\u044f \u043f\u0441\u0438\u0445\u0438\u0430\u0442\u0440\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u043d\u0430\u0443\u043a\u0430 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0442\u0435\u0440\u0430\u043f\u0438\u044f \u043c\u044b\u0448\u043b\u0435\u043d\u0438\u0435 \u043c\u044b\u0441\u043b\u0438 behavior body brain cognitive function gear head human intellectual mental mind people person preferences psychiatric science settings social therapy thinking thoughts',
    Public: '\u0441\u0442\u0440\u0430\u043d\u0430 \u0437\u0435\u043c\u043d\u043e\u0439 \u0448\u0430\u0440 \u0413\u043b\u043e\u0431\u0430\u043b\u044c\u043d\u044b\u0439 \u0433\u043b\u043e\u0431\u0443\u0441 \u044f\u0437\u044b\u043a \u043a\u0430\u0440\u0442\u0430 \u0441\u0435\u0442\u044c \u043f\u043b\u0430\u043d\u0435\u0442\u0430 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e \u0441\u0435\u0442\u044c \u041c\u0438\u0440 country earth global globe language map network planet social space web world',
    PublicOff:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0437\u0435\u043c\u043d\u043e\u0439 \u0448\u0430\u0440 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0413\u043b\u043e\u0431\u0430\u043b\u044c\u043d\u044b\u0439 \u0433\u043b\u043e\u0431\u0443\u0441 \u043a\u0430\u0440\u0442\u0430 \u0441\u0435\u0442\u044c \u043d\u0430 \u043f\u043b\u0430\u043d\u0435\u0442\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e \u0441\u0435\u0442\u044c \u041c\u0438\u0440 disabled earth enabled global globe map network on planet slash social space web world',
    Publish:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043e\u0431\u043b\u0430\u043a\u043e \u0444\u0430\u0439\u043b \u0438\u043c\u043f\u043e\u0440\u0442 \u0420\u0430\u0437\u043c\u0435\u0441\u0442\u0438\u0442\u044c \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c arrow cloud file import submit upload',
    PublishedWithChanges:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u043c\u0435\u043d\u044f\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve arrows check complete done inprogress loading mark ok refresh renew replace rotate select tick validate verified yes',
    PunchClock:
        '\u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0442\u0430\u0439\u043c\u0435\u0440 \u0442\u0430\u0431\u0435\u043b\u044c \u0443\u0447\u0435\u0442\u0430 \u0440\u0430\u0431\u043e\u0447\u0435\u0433\u043e \u0432\u0440\u0435\u043c\u0435\u043d\u0438 date schedule timer timesheet',
    PushPin:
        '\u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043c\u0430\u0440\u043a\u0435\u0440 \u043c\u0435\u0441\u0442\u043e \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u0441\u043f\u0430\u0441\u0442\u0438 location marker place remember save',
    QrCode: '\u0448\u0442\u0440\u0438\u0445-\u043a\u043e\u0434 \u043a\u0430\u043c\u0435\u0440\u0430 \u0421\u041c\u0418 \u0442\u043e\u0432\u0430\u0440 \u0431\u044b\u0441\u0442\u0440\u044b\u0439 \u043e\u0442\u043a\u043b\u0438\u043a \u0441\u043c\u0430\u0440\u0442\u0444\u043e\u043d URL-\u0430\u0434\u0440\u0435\u0441\u0430 barcode camera media product quick response smartphone urls',
    QrCode2:
        '\u0448\u0442\u0440\u0438\u0445-\u043a\u043e\u0434 \u043a\u0430\u043c\u0435\u0440\u0430 \u0421\u041c\u0418 \u0442\u043e\u0432\u0430\u0440 \u0431\u044b\u0441\u0442\u0440\u044b\u0439 \u043e\u0442\u043a\u043b\u0438\u043a \u0441\u043c\u0430\u0440\u0442\u0444\u043e\u043d URL-\u0430\u0434\u0440\u0435\u0441\u0430 barcode camera media product quick response smartphone urls',
    QrCodeScanner:
        '\u0448\u0442\u0440\u0438\u0445-\u043a\u043e\u0434 \u043a\u0430\u043c\u0435\u0440\u0430 \u0421\u041c\u0418 \u0442\u043e\u0432\u0430\u0440 \u0431\u044b\u0441\u0442\u0440\u044b\u0439 \u043e\u0442\u043a\u043b\u0438\u043a \u0441\u043c\u0430\u0440\u0442\u0444\u043e\u043d URL-\u0430\u0434\u0440\u0435\u0441\u0430 barcode camera media product quick response smartphone urls',
    QueryBuilder:
        '\u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0447\u0430\u0441 \u043c\u0438\u043d\u0443\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f clock date hour minute save schedule time',
    QueryStats:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043b\u0438\u043d\u0438\u044f \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u043f\u043e\u0438\u0441\u043a \u0432\u0438\u0434\u0435\u0442\u044c \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics chart data diagram find glass infographic line look magnifying measure metrics search see statistics tracking',
    QuestionAnswer:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u0431\u0435\u0441\u0435\u0434\u0430 \u0431\u0435\u0441\u0435\u0434\u043e\u0432\u0430\u0442\u044c \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0440\u0435\u0447\u044c \u0440\u0430\u0437\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c bubble chat comment communicate conversation converse feedback message speech talk',
    QuestionMark:
        '? \u043f\u043e\u043c\u043e\u0449\u044c \u043f\u043e\u043c\u043e\u0449\u044c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043f\u0443\u043d\u043a\u0442\u0443\u0430\u0446\u0438\u044f \u0432\u043e\u043f\u0440\u043e\u0441 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b ? assistance help information mark punctuation question support symbol',
    Queue: '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u0441\u043b\u043e\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u043b\u0435\u0439\u043b\u0438\u0441\u0442 \u043a\u0443\u0447\u0430 \u0440\u0443\u0447\u0435\u0439 \u0432\u0438\u0434\u0435\u043e add collection layers multiple music playlist stack stream video',
    QueueMusic:
        '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u043f\u043b\u0435\u0439\u043b\u0438\u0441\u0442 \u0440\u0443\u0447\u0435\u0439 add collection playlist stream',
    QueuePlayNext:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u041c\u0443\u0437\u044b\u043a\u0430 \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u0435\u0439\u043b\u0438\u0441\u0442 \u043f\u043b\u044e\u0441 \u044d\u043a\u0440\u0430\u043d \u0433\u043e\u0442\u043e\u0432\u0438\u0442\u044c \u043d\u0430 \u043f\u0430\u0440\u0443 \u0441\u0438\u043c\u0432\u043e\u043b \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0432\u0438\u0434\u0435\u043e + add arrow collection desktop device display hardware monitor music new playlist plus screen steam symbol tv video',
    Quickreply:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u0431\u044b\u0441\u0442\u0440\u043e \u043c\u043e\u043b\u043d\u0438\u044f \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0440\u0435\u0447\u044c \u0443\u0434\u0430\u0440 \u043c\u043e\u043b\u043d\u0438\u0438 bubble chat comment communicate fast lightning message speech thunderbolt',
    Quiz: '? \u043f\u043e\u043c\u043e\u0449\u044c \u0427\u0430\u0441\u0442\u043e \u0437\u0430\u0434\u0430\u0432\u0430\u0435\u043c\u044b\u0435 \u0432\u043e\u043f\u0440\u043e\u0441\u044b \u043f\u043e\u043c\u043e\u0449\u044c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043f\u0443\u043d\u043a\u0442\u0443\u0430\u0446\u0438\u044f \u0432\u043e\u043f\u0440\u043e\u0441 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u0441\u0442 ? assistance faq help information mark punctuation question support symbol test',
    Radar: '\u043e\u0431\u043d\u0430\u0440\u0443\u0436\u0438\u0442\u044c \u0432\u043e\u0435\u043d\u043d\u044b\u0439 \u043e\u043a\u043e\u043b\u043e \u0441\u0435\u0442\u044c \u0434\u043e\u043b\u0436\u043d\u043e\u0441\u0442\u044c \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 detect military near network position scan',
    Radio: '\u0430\u043d\u0442\u0435\u043d\u043d\u0430 \u0430\u0443\u0434\u0438\u043e \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0447\u0430\u0441\u0442\u043e\u0442\u0430 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0421\u043b\u0443\u0448\u0430\u0442\u044c \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u0438\u0433\u0440\u043e\u043a \u0441\u0438\u0433\u043d\u0430\u043b \u043c\u0435\u043b\u043e\u0434\u0438\u044f antenna audio device frequency hardware listen media music player signal tune',
    RadioButtonChecked:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043f\u0443\u043b\u044f \u043a\u0440\u0443\u0433 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0442\u043e\u0447\u043a\u0430 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u044d\u043a\u0440\u0430\u043d \u0432\u044b\u0431\u0440\u0430\u043d \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application bullet circle components design form interface off point record screen selected toggle ui ux website',
    RadioButtonUnchecked:
        '\u043f\u0443\u043b\u044f \u043a\u0440\u0443\u0433 \u0441\u043d\u044f\u0442 \u0441 \u0432\u044b\u0431\u043e\u0440\u0430 \u0444\u043e\u0440\u043c\u0430 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0442\u043e\u0447\u043a\u0430 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c bullet circle deselected form off point record toggle',
    RailwayAlert:
        '! \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0432\u0435\u043b\u043e\u0441\u0438\u043f\u0435\u0434 \u043c\u0430\u0448\u0438\u043d\u044b \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043a\u0430\u0440\u0442\u044b \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0441\u0430\u043c\u043e\u043a\u0430\u0442 \u043c\u0435\u0442\u0440\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0440\u0435\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0432\u0435\u0441\u043f\u0430 \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! attention automobile bike cars caution danger direction error exclamation important maps mark notification public scooter subway symbol train transportation vehicle vespa warning',
    RamenDining:
        '\u0437\u0430\u0432\u0442\u0440\u0430\u043a \u043e\u0431\u0435\u0434 \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0411\u044b\u0441\u0442\u0440\u043e\u0435 \u043f\u0438\u0442\u0430\u043d\u0438\u0435 \u043e\u0431\u0435\u0434 \u0435\u0434\u0430 \u043b\u0430\u043f\u0448\u0430 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d breakfast dinner drink fastfood lunch meal noodles restaurant',
    RampLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path route sign traffic',
    RampRight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path route sign traffic',
    RateReview:
        '\u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u0437\u0432\u0435\u0437\u0434\u044b \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c chat comment feedback message pencil stars write',
    RawOff: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0438\u0441\u044c\u043c\u043e \u043e\u0440\u0438\u0433\u0438\u043d\u0430\u043b\u044c\u043d\u044b\u0439 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character disabled enabled font image letter original photography slash symbol text type',
    RawOn: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0438\u0441\u044c\u043c\u043e \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043e\u0440\u0438\u0433\u0438\u043d\u0430\u043b\u044c\u043d\u044b\u0439 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character disabled enabled font image letter off original photography slash symbol text type',
    ReadMore:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0442\u0435\u043a\u0441\u0442 arrow text',
    Receipt:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043a\u0440\u0435\u0434\u0438\u0442 \u0441\u0447\u0435\u0442 \u0431\u0443\u043c\u0430\u0433\u0430 \u043e\u043f\u043b\u0430\u0442\u0430 \u0440\u0430\u0441\u043f\u0440\u043e\u0434\u0430\u0436\u0430 \u0441\u0434\u0435\u043b\u043a\u0430 bill credit invoice paper payment sale transaction',
    ReceiptLong:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0441\u043f\u0438\u0441\u043e\u043a \u043e\u0444\u043e\u0440\u043c\u043b\u0435\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442\u0430\u0446\u0438\u0438 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u0445\u0440\u0430\u043d\u0438\u0442\u044c \u0441\u0434\u0435\u043b\u043a\u0430 bill check document list paperwork record store transaction',
    RecentActors:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u043a\u0430\u0440\u0442\u044b \u043a\u0430\u0440\u0443\u0441\u0435\u043b\u044c \u043a\u043e\u043d\u0442\u0430\u043a\u0442\u044b \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u043b\u043e\u0438 \u0441\u043f\u0438\u0441\u043e\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043c\u0438\u043d\u0438\u0430\u0442\u044e\u0440\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account avatar cards carousel contacts face human layers list people person profile thumbnail user',
    Recommend:
        '\u043e\u0434\u043e\u0431\u0440\u0435\u043d\u043d\u044b\u0439 \u043a\u0440\u0443\u0433 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0436\u0435\u0441\u0442 \u0440\u0443\u043a\u0430 \u043a\u0430\u043a \u0440\u0435\u0430\u043a\u0446\u0438\u044f \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0442\u044c \u0431\u043e\u043b\u044c\u0448\u0438\u0435 \u043f\u0430\u043b\u044c\u0446\u044b \u0427\u0442\u043e \u0436 approved circle confirm favorite gesture hand like reaction social support thumbs well',
    RecordVoiceOver:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0437\u0430\u043f\u0438\u0441\u044c \u0437\u0432\u0443\u043a \u0413\u043e\u0432\u043e\u0440\u044f\u0449\u0438\u0439 \u0440\u0435\u0447\u044c \u0441\u0442\u0435\u043d\u043e\u0433\u0440\u0430\u043c\u043c\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account face human people person profile recording sound speaking speech transcript user',
    Rectangle:
        '\u0447\u0435\u0442\u044b\u0440\u0435 \u043f\u0430\u0440\u0430\u043b\u043b\u0435\u043b\u043e\u0433\u0440\u0430\u043c\u043c\u044b \u043c\u043d\u043e\u0433\u043e\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a\u0438 \u0447\u0435\u0442\u044b\u0440\u0435\u0445\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a\u0438 \u043f\u0435\u0440\u0435\u0434\u0435\u043b\u044b\u0432\u0430\u0442\u044c \u0444\u043e\u0440\u043c\u0430 \u0441\u0442\u043e\u0440\u043e\u043d\u044b four parallelograms polygons quadrilaterals recangle shape sides',
    Reddit: '\u043c\u0430\u0440\u043a\u0430 \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 brand logo social',
    Redeem: '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0442\u0435\u043b\u0435\u0436\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043a\u0430\u0442 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u043f\u043e\u0434\u0430\u0440\u043e\u0447\u043d\u0430\u044f \u043a\u0430\u0440\u0442\u0430 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u0434\u0430\u0440\u043e\u043a \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c bill cart cash certificate coin commerce credit currency dollars giftcard money online payment present shopping',
    Redo: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u0432\u043f\u0435\u0440\u0435\u0434 \u0441\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0439 \u043f\u043e\u0432\u0442\u043e\u0440\u0435\u043d\u0438\u0435 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c arrow backward forward next repeat rotate undo',
    ReduceCapacity:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0442\u0435\u043b\u043e COVID-19 \u0441\u043d\u0438\u0436\u0430\u0442\u044c\u0441\u044f \u0432\u043d\u0438\u0437 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 arrow body covid decrease down human people person social',
    Refresh:
        '\u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c around arrows direction inprogress loading navigation refresh renew right rotate turn',
    RememberMe:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0430\u0432\u0430\u0442\u0430\u0440 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0447\u0435\u043b\u043e\u0432\u0435\u043a iOS \u043b\u0438\u0447\u043d\u043e\u0441\u0442\u044c \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c Android avatar device hardware human iOS identity mobile people person phone profile tablet user',
    Remove: '\u041c\u043e\u0436\u043d\u043e \u0423\u0434\u0430\u043b\u0438\u0442\u044c \u043b\u0438\u043d\u0438\u044f \u043c\u0438\u043d\u0443\u0441 \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u0432\u044b\u0447\u0435\u0441\u0442\u044c \u0432\u044b\u0447\u0435\u0441\u0442\u044c \u043c\u0443\u0441\u043e\u0440 can delete line minus negative substract subtract trash',
    RemoveCircle:
        '\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u044b\u0439 \u0437\u0430\u043f\u0440\u0435\u0449\u0435\u043d \u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u041c\u043e\u0436\u043d\u043e \u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u043c\u0438\u043d\u0443\u0441 \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u043d\u0435\u0442 \u0432\u044b\u0447\u0435\u0441\u0442\u044c \u043c\u0443\u0441\u043e\u0440 allowed banned block can delete disable minus negative not substract trash',
    RemoveCircleOutline:
        '\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u044b\u0439 \u0437\u0430\u043f\u0440\u0435\u0449\u0435\u043d \u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u041c\u043e\u0436\u043d\u043e \u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u043c\u0438\u043d\u0443\u0441 \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u043d\u0435\u0442 \u0432\u044b\u0447\u0435\u0441\u0442\u044c \u043c\u0443\u0441\u043e\u0440 allowed banned block can delete disable minus negative not substract trash',
    RemoveDone:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0437\u0430\u043a\u043e\u043d\u0447\u0435\u043d\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u0434\u0430 approve check complete disabled enabled finished mark multiple off ok select slash tick yes',
    RemoveFromQueue:
        '\u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0441\u043f\u0438\u0441\u043e\u043a \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u044d\u043a\u0440\u0430\u043d \u0433\u043e\u0442\u043e\u0432\u0438\u0442\u044c \u043d\u0430 \u043f\u0430\u0440\u0443 \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 collection desktop device display hardware list monitor screen steam television',
    RemoveModerator:
        '\u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0449\u0438\u0442 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e certified disabled enabled off privacy private protection security shield slash verified',
    RemoveRedEye:
        '\u0440\u0430\u0434\u0443\u0436\u043d\u0430\u044f \u043e\u0431\u043e\u043b\u043e\u0447\u043a\u0430 \u0418\u0449\u0443 \u043f\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u043f\u0440\u043e\u0441\u043c\u043e\u0442\u0440 \u0432\u0438\u0434\u0435\u0442\u044c \u0437\u0440\u0435\u043d\u0438\u0435 \u0437\u0440\u0435\u043d\u0438\u0435 iris looking preview see sight vision',
    RemoveRoad:
        '- \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u044b\u0445\u043e\u0434 \u0448\u043e\u0441\u0441\u0435 \u043a\u0430\u0440\u0442\u044b \u043c\u0438\u043d\u0443\u0441 \u043d\u043e\u0432\u044b\u0439 \u043d\u0435\u0442 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0443\u043b\u0438\u0446\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 - cancel close destination direction exit highway maps minus new no stop street symbol traffic',
    RemoveShoppingCart:
        '\u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043f\u0440\u043e\u0432\u0435\u0440\u0438\u0442\u044c \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 card cash checkout coin commerce credit currency disabled dollars enabled off online payment slash tick',
    Reorder:
        '\u0444\u043e\u0440\u043c\u0430\u0442 \u043b\u0438\u043d\u0438\u0438 \u0441\u043f\u0438\u0441\u043e\u043a \u0441\u043b\u043e\u0436\u0435\u043d\u043d\u044b\u0439 format lines list stacked',
    Repeat: '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u0432\u0438\u0434\u0435\u043e arrows controls media music video',
    RepeatOn:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u0432\u0438\u0434\u0435\u043e arrows controls media music video',
    RepeatOne:
        '1 \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0446\u0438\u0444\u0440\u0430 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0438\u0434\u0435\u043e 1 arrows controls digit media music number symbol video',
    RepeatOneOn:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0446\u0438\u0444\u0440\u0430 \u0421\u041c\u0418 \u041c\u0443\u0437\u044b\u043a\u0430 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0438\u0434\u0435\u043e arrows controls digit media music number symbol video',
    Replay: '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u041c\u0443\u0437\u044b\u043a\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u0435\u0440\u0435\u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u043e\u0432\u0442\u043e\u0440\u0435\u043d\u0438\u0435 \u043f\u043e\u0432\u0442\u043e\u0440\u0438\u0442\u044c \u043f\u043e\u043f\u044b\u0442\u043a\u0443 \u043f\u0435\u0440\u0435\u043c\u0430\u0442\u044b\u0432\u0430\u0442\u044c \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0432\u0438\u0434\u0435\u043e arrows controls music refresh reload renew repeat retry rewind undo video',
    Replay10:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0446\u0438\u0444\u0440\u0430 \u041c\u0443\u0437\u044b\u043a\u0430 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u043e\u0432\u0442\u043e\u0440\u0435\u043d\u0438\u0435 \u043f\u0435\u0440\u0435\u043c\u0430\u0442\u044b\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b 10 \u0432\u0438\u0434\u0435\u043e arrows controls digit music number refresh renew repeat rewind symbol ten video',
    Replay30:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0446\u0438\u0444\u0440\u0430 \u041c\u0443\u0437\u044b\u043a\u0430 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u043e\u0432\u0442\u043e\u0440\u0435\u043d\u0438\u0435 \u043f\u0435\u0440\u0435\u043c\u0430\u0442\u044b\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0440\u0438\u0434\u0446\u0430\u0442\u044c \u0432\u0438\u0434\u0435\u043e arrows controls digit music number refresh renew repeat rewind symbol thirty video',
    Replay5:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0446\u0438\u0444\u0440\u0430 \u043f\u044f\u0442\u044c \u041c\u0443\u0437\u044b\u043a\u0430 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u043e\u0432\u0442\u043e\u0440\u0435\u043d\u0438\u0435 \u043f\u0435\u0440\u0435\u043c\u0430\u0442\u044b\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0438\u0434\u0435\u043e arrows controls digit five music number refresh renew repeat rewind symbol video',
    ReplayCircleFilled:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u041c\u0443\u0437\u044b\u043a\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u043e\u0432\u0442\u043e\u0440\u0435\u043d\u0438\u0435 \u0432\u0438\u0434\u0435\u043e arrows controls music refresh renew repeat video',
    Reply: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043f\u043e\u0447\u0442\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f arrow backward left mail message send share',
    ReplyAll:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u0437\u0430\u0434 \u0433\u0440\u0443\u043f\u043f\u0430 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043f\u043e\u0447\u0442\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f arrows backward group left mail message multiple send share',
    Report: '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0432\u043e\u0441\u044c\u043c\u0438\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert attention caution danger error exclamation important mark notification octagon symbol warning',
    ReportGmailerrorred:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0432\u043e\u0441\u044c\u043c\u0438\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert attention caution danger exclamation important mark notification octagon symbol warning',
    ReportOff:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0432\u043e\u0441\u044c\u043c\u0438\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert attention caution danger disabled enabled error exclamation important mark notification octagon offline slash symbol warning',
    ReportProblem:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0440\u0435\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert announcement attention caution danger error exclamation feedback important mark notification symbol triangle warning',
    RequestPage:
        '\u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u0431\u0443\u043c\u0430\u0433\u0430 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u043f\u0438\u0448\u0443 data document drive file folders paper sheet slide writing',
    RequestQuote:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0441\u0438\u043c\u0432\u043e\u043b bill card cash coin commerce cost credit currency dollars finance money online payment price shopping symbol',
    ResetTv:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 arrow device hardware monitor television',
    RestartAlt:
        '\u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043f\u0435\u0440\u0435\u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u043e\u0432\u0442\u043e\u0440\u0435\u043d\u0438\u0435 \u043f\u0435\u0440\u0435\u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c around arrow inprogress loading reboot refresh renew repeat reset',
    Restaurant:
        '\u0437\u0430\u0432\u0442\u0440\u0430\u043a \u0441\u0442\u043e\u043b\u043e\u0432\u044b\u0435 \u043f\u0440\u0438\u0431\u043e\u0440\u044b \u0441\u0442\u043e\u043b\u043e\u0432\u0430\u044f \u043e\u0431\u0435\u0434 \u0435\u0441\u0442\u044c \u0435\u0434\u0430 \u0432\u0438\u043b\u043a\u0430 \u043d\u043e\u0436 \u043c\u0435\u0441\u0442\u043d\u044b\u0439 \u043e\u0431\u0435\u0434 \u0435\u0434\u0430 \u043c\u0435\u0441\u0442\u0430 \u043b\u043e\u0436\u043a\u0430 \u043f\u043e\u0441\u0443\u0434\u0430 breakfast cutlery dining dinner eat food fork knife local lunch meal places spoon utensils',
    RestaurantMenu:
        '\u043a\u043d\u0438\u0433\u0430 \u0441\u0442\u043e\u043b\u043e\u0432\u0430\u044f \u0435\u0441\u0442\u044c \u0435\u0434\u0430 \u0432\u0438\u043b\u043a\u0430 \u043d\u043e\u0436 \u043c\u0435\u0441\u0442\u043d\u044b\u0439 \u0435\u0434\u0430 \u043b\u043e\u0436\u043a\u0430 book dining eat food fork knife local meal spoon',
    Restore:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0438\u0441\u0442\u043e\u0440\u0438\u044f \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c arrow backwards clock date history refresh renew reverse rotate schedule time turn undo',
    RestoreFromTrash:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u041c\u043e\u0436\u043d\u043e \u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0423\u0434\u0430\u043b\u0438\u0442\u044c \u043c\u0443\u0441\u043e\u0440 \u0438\u0441\u0442\u043e\u0440\u0438\u044f \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c \u0432\u0432\u0435\u0440\u0445 arrow backwards can clock date delete garbage history refresh remove renew reverse rotate schedule time turn up',
    RestorePage:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u0438\u0441\u0442\u043e\u0440\u0438\u044f \u0431\u0443\u043c\u0430\u0433\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043b\u0438\u0441\u0442 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0441\u0435\u0442\u044c arrow data doc file history paper refresh rotate sheet storage undo web',
    Reviews:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0440\u0435\u043a\u043e\u043c\u0435\u043d\u0434\u0430\u0446\u0438\u044f \u0440\u0435\u0447\u044c bubble chat comment communicate feedback message rate rating recommendation speech',
    RiceBowl:
        '\u043e\u0431\u0435\u0434 \u0435\u0434\u0430 \u043e\u0431\u0435\u0434 \u0435\u0434\u0430 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d dinner food lunch meal restaurant',
    RingVolume:
        '\u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0432\u0445\u043e\u0434\u044f\u0449\u0438\u0439 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0437\u0432\u043e\u043d\u043e\u043a \u0437\u0432\u0443\u043a \u0442\u0435\u043b\u0435\u0444\u043e\u043d calling cell contact device hardware incoming mobile ringer sound telephone',
    RMobiledata:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet character font letter symbol text type',
    Rocket: '\u043a\u043e\u0441\u043c\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u043a\u043e\u0440\u0430\u0431\u043b\u044c spaceship',
    RocketLaunch:
        '\u043a\u043e\u0441\u043c\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u043a\u043e\u0440\u0430\u0431\u043b\u044c \u041e\u0442\u0433\u0443\u043b spaceship takeoff',
    RollerShades:
        '\u0436\u0430\u043b\u044e\u0437\u0438 \u043f\u043e\u043a\u0440\u044b\u0442\u0438\u0435 \u0448\u0442\u043e\u0440\u044b \u0433\u043d\u0435\u0437\u0434\u043e \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u043c \u0437\u0430\u0442\u0432\u043e\u0440 \u0437\u043e\u043d\u0442\u0438\u043a blinds cover curtains nest open shutter sunshade',
    RollerShadesClosed:
        '\u0436\u0430\u043b\u044e\u0437\u0438 \u043f\u043e\u043a\u0440\u044b\u0442\u0438\u0435 \u0448\u0442\u043e\u0440\u044b \u0433\u043d\u0435\u0437\u0434\u043e \u0437\u0430\u0442\u0432\u043e\u0440 \u0437\u043e\u043d\u0442\u0438\u043a blinds cover curtains nest shutter sunshade',
    RollerSkating:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u043e\u0431\u0443\u0432\u044c \u043a\u043e\u043d\u044c\u043a\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c athlete athletic entertainment exercise hobby shoe skates social sports travel',
    Roofing:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0434\u044b\u043c\u043e\u0432\u0430\u044f \u0442\u0440\u0443\u0431\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u0434\u043e\u043c \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u043f\u0440\u0438\u044e\u0442 architecture building chimney construction estate home house real residence residential service shelter',
    Room: '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 GPS \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043c\u0430\u0440\u043a\u0435\u0440 \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043c\u0435\u0441\u0442\u043e \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 destination direction gps location maps marker pin place spot stop',
    RoomPreferences:
        '\u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0434\u0432\u0435\u0440\u043d\u043e\u0439 \u043f\u0440\u043e\u0435\u043c \u0432\u0445\u043e\u0434 \u043c\u0435\u0445\u0430\u043d\u0438\u0437\u043c \u0434\u043e\u043c \u0434\u043e\u043c \u0438\u043d\u0442\u0435\u0440\u044c\u0435\u0440 \u043e\u0444\u0438\u0441 \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u043c \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 building doorway entrance gear home house interior office open settings',
    RoomService:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u043a\u043e\u043d\u0441\u044c\u0435\u0440\u0436 \u0414\u043e\u0441\u0442\u0430\u0432\u043a\u0430 \u043e\u0442\u0435\u043b\u044c \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u044f\u0442\u044c alert bell concierge delivery hotel notify',
    Rotate90DegreesCcw:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0424\u043e\u0442\u043e \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c arrows direction editing image photo turn',
    Rotate90DegreesCw:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043f\u0440\u043e\u0442\u0438\u0432 \u0447\u0430\u0441\u043e\u0432\u043e\u0439 \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0424\u043e\u0442\u043e \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c arrows ccw direction editing image photo turn',
    RotateLeft:
        '\u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0440\u0443\u0433 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u0435\u0440\u0435\u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u0435\u0440\u0435\u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c around arrow circle direction inprogress loading refresh reload renew reset turn',
    RotateRight:
        '\u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0440\u0443\u0433 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c around arrow circle direction inprogress loading refresh renew turn',
    RoundaboutLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path route sign traffic',
    RoundaboutRight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path route sign traffic',
    RoundedCorner:
        '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0444\u043e\u0440\u043c\u0430 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0442\u0440\u0430\u043d\u0441\u0444\u043e\u0440\u043c\u0438\u0440\u043e\u0432\u0430\u0442\u044c adjust edit shape square transform',
    Route: '\u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 directions maps path sign traffic',
    Router: '\u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u043a\u0430\u0431\u0435\u043b\u044c \u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u0441\u0438\u0433\u043d\u0430\u043b Wi-Fi box cable connection device hardware internet network signal wifi',
    Rowing: '\u041c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u044f \u043b\u043e\u0434\u043a\u0430 \u0442\u0435\u043b\u043e \u043a\u0430\u043d\u043e\u044d \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u0432\u043e\u0434\u0430 activity boat body canoe human people person sports water',
    RssFeed:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0431\u043b\u043e\u0433 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 application blog connection data internet network service signal website wifi wireless',
    Rsvp: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u043f\u0440\u0438\u0433\u043b\u0430\u0448\u0435\u043d\u0438\u0435 \u043f\u0440\u0438\u0433\u043b\u0430\u0448\u0430\u0442\u044c \u043f\u0438\u0441\u044c\u043c\u043e \u043f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430 \u0440\u0435\u0430\u0433\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043e\u0442\u0432\u0435\u0447\u0430\u0442\u044c \u0431\u0443\u0434\u0443\u0442 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0442\u044b alphabet character font invitation invite letter pla\u00eet respond r\u00e9pondez sil symbol text type vous',
    Rtt: '\u0432\u044b\u0437\u043e\u0432 \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0440\u0442 \u0442\u0435\u043a\u0441\u0442 \u0432\u0440\u0435\u043c\u044f call real rrt text time',
    Rule: '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u043d\u0435\u043f\u043e\u043b\u043d\u044b\u0439 \u043b\u0438\u043d\u0438\u044f \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043e\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044e\u0449\u0438\u0439 \u043d\u0435\u0442 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u043d\u0435\u043f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u044b\u0439 \u0418\u043a\u0441 \u0434\u0430 approve check done incomplete line mark missing no ok select tick validate verified wrong x yes',
    RuleFolder:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043f\u043e\u043b\u043d\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0432\u044b\u0445\u043e\u0434 \u0444\u0430\u0439\u043b \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043d\u0435\u0442 Ok \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve cancel check close complete data document done drive exit file mark no ok remove select sheet slide storage tick validate verified yes',
    RunCircle:
        '\u0442\u0435\u043b\u043e \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0411\u0435\u0433 body exercise human people person running',
    RunningWithErrors:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043e\u0431\u0440\u0430\u0431\u043e\u0442\u043a\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0440\u0435\u043c\u044f \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert attention caution danger duration exclamation important mark notification processing symbol time warning',
    RvHookup:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043f\u0440\u0438\u043a\u0440\u0435\u043f\u043b\u044f\u0442\u044c \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043d\u0430\u0437\u0430\u0434 \u043c\u0430\u0448\u0438\u043d\u044b \u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u0442\u0440\u0435\u0439\u043b\u0435\u0440 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0433\u0440\u0443\u0437\u043e\u0432\u0430\u044f \u043c\u0430\u0448\u0438\u043d\u0430 \u0438\u0437 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f arrow attach automobile automotive back cars connect direction left maps public right trailer transportation travel truck van vehicle',
    SafetyCheck:
        '\u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u0427\u0430\u0441\u044b \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0449\u0438\u0442 \u0432\u0440\u0435\u043c\u044f \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e certified clock privacy private protection schedule security shield time verified',
    SafetyDivider:
        '\u043e\u0442\u0434\u0435\u043b\u044c\u043d\u043e \u0440\u0430\u0441\u0441\u0442\u043e\u044f\u043d\u0438\u0435 \u043e\u0442\u0434\u0435\u043b\u044c\u043d\u044b\u0439 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e apart distance separate social space',
    Sailing:
        '\u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u043b\u043e\u0432\u0438\u0442 \u0440\u044b\u0431\u0443 \u0445\u043e\u0431\u0431\u0438 \u043e\u043a\u0435\u0430\u043d \u043f\u0430\u0440\u0443\u0441\u043d\u0430\u044f \u043b\u043e\u0434\u043a\u0430 \u043c\u043e\u0440\u0435 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0432\u043e\u0434\u0430 entertainment fishing hobby ocean sailboat sea social sports travel water',
    Sanitizer:
        '\u0431\u0430\u043a\u0442\u0435\u0440\u0438\u0438 \u0431\u0443\u0442\u044b\u043b\u043a\u0430 \u0447\u0438\u0441\u0442\u044b\u0439 COVID-19 \u0434\u0435\u0437\u0438\u043d\u0444\u0438\u0446\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043c\u0438\u043a\u0440\u043e\u0431\u044b \u043d\u0430\u0441\u043e\u0441 bacteria bottle clean covid disinfect germs pump',
    Satellite:
        'Bluetooth \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u0434\u0430\u043d\u043d\u044b\u0435 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043f\u0435\u0439\u0437\u0430\u0436 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0433\u043e\u0440\u044b \u0441\u0435\u0442\u044c \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0441\u0438\u043c\u0432\u043e\u043b Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439-- bluetooth connection connectivity data device image internet landscape location maps mountains network photography picture scan service signal symbol wifi wireless--',
    SatelliteAlt:
        '\u0430\u043b\u044c\u0442\u0435\u0440\u043d\u0430\u0442\u0438\u0432\u0430 \u0438\u0441\u043a\u0443\u0441\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u044f \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e \u0441\u0442\u0430\u043d\u0446\u0438\u044f \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 alternative artificial communication space station television',
    Save: '\u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0441\u043a\u0435\u0442\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u0433\u0438\u0431\u043a\u0438\u0439 \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c data diskette document drive file floppy multimedia storage write',
    SaveAlt:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0434\u0438\u0441\u043a\u0435\u0442\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043d\u0438\u0437 \u0444\u0430\u0439\u043b \u0433\u0438\u0431\u043a\u0438\u0439 \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c arrow diskette document down file floppy multimedia write',
    SaveAs: '\u0441\u043e\u0447\u0438\u043d\u044f\u0442\u044c \u0421\u043e\u0437\u0434\u0430\u0439\u0442\u0435 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0441\u043a \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0447\u0435\u0440\u043d\u043e\u0432\u0438\u043a \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0444\u0430\u0439\u043b \u0433\u0438\u0431\u043a\u0438\u0439 \u0432\u0445\u043e\u0434 \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430 \u043a\u0430\u0440\u0430\u043d\u0434\u0430\u0448 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0448\u0443 compose create data disk document draft drive editing file floppy input multimedia pencil storage write writing',
    SavedSearch:
        '\u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0432\u0430\u0436\u043d\u044b\u0439 \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u043e\u0442\u043c\u0435\u0447\u0435\u043d \u0432\u0438\u0434\u0435\u0442\u044c \u0437\u0432\u0435\u0437\u0434\u0430 find glass important look magnifying marked see star',
    Savings:
        '\u0431\u0430\u043d\u043a \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0421\u0442\u043e\u0438\u043c\u043e\u0441\u0442\u044c \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0444\u0438\u043d\u0430\u043d\u0441\u044b \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u0440\u043e\u0441\u0435\u043d\u043e\u043a \u0441\u0438\u043c\u0432\u043e\u043b bank bill card cash coin commerce cost credit currency dollars finance money online payment piggy symbol',
    Scale: '\u043c\u0435\u0440\u0430 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u043c\u0430\u0441\u0441\u0430 measure monitor weight',
    Scanner:
        '\u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u0430\u0448\u0438\u043d\u0430 copy device hardware machine',
    ScatterPlot:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u043a\u0440\u0443\u0433\u0438 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0442\u043e\u0447\u043a\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bars chart circles data diagram dot infographic measure metrics statistics tracking',
    Schedule:
        '\u043a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c \u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u0432\u0440\u0435\u043c\u044f calendar clock date mark save time',
    ScheduleSend:
        '\u043a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c \u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043f\u0438\u0441\u044c\u043c\u043e \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f \u0432\u0440\u0435\u043c\u044f calendar clock date email letter remember share time',
    Schema: '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u043f\u043e\u0442\u043e\u043a \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics chart data diagram flow infographic measure metrics statistics tracking',
    School: '\u0430\u043a\u0430\u0434\u0435\u043c\u0438\u044f \u0434\u043e\u0441\u0442\u0438\u0436\u0435\u043d\u0438\u0435 \u043a\u0440\u044b\u0448\u043a\u0430 \u0443\u0447\u0435\u0431\u043d\u044b\u0439 \u043a\u043b\u0430\u0441\u0441 \u043a\u043e\u043b\u043b\u0435\u0434\u0436 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u0435 \u0432\u044b\u043f\u0443\u0441\u043a\u043d\u043e\u0439 \u0418\u043c\u0435\u0435\u0442 \u0437\u043d\u0430\u043d\u0438\u044f \u043e\u0431\u0443\u0447\u0435\u043d\u0438\u0435 \u0423\u043d\u0438\u0432\u0435\u0440\u0441\u0438\u0442\u0435\u0442 academy achievement cap class college education graduation hat knowledge learning university',
    Science:
        '\u0441\u0442\u0430\u043a\u0430\u043d \u0445\u0438\u043c\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u0445\u0438\u043c\u0438\u044f \u044d\u043a\u0441\u043f\u0435\u0440\u0438\u043c\u0435\u043d\u0442 \u043a\u043e\u043b\u0431\u0430 \u0441\u0442\u0430\u043a\u0430\u043d \u043b\u0430\u0431\u043e\u0440\u0430\u0442\u043e\u0440\u0438\u044f \u0438\u0441\u0441\u043b\u0435\u0434\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0430\u044f \u0440\u0430\u0431\u043e\u0442\u0430 \u0442\u0440\u0443\u0431\u043a\u0430 beaker chemical chemistry experiment flask glass laboratory research tube',
    Score: '2\u043a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043f\u0438\u0441\u044c\u043c\u043e \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 \u0442\u0438\u043f 2k alphabet analytics bars character chart data diagram digit font infographic letter measure metrics number statistics symbol text tracking type',
    Scoreboard:
        '\u0442\u043e\u0447\u043a\u0438 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 points sports',
    ScreenLockLandscape:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android device hardware iOS mobile phone rotate security tablet',
    ScreenLockPortrait:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android device hardware iOS mobile phone rotate security tablet',
    ScreenLockRotation:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c Android arrow device hardware iOS mobile phone rotate tablet turn',
    ScreenRotation:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c Android arrow device hardware iOS mobile phone rotate tablet turn',
    ScreenRotationAlt:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c Android arrow device hardware iOS mobile phone rotate tablet turn',
    ScreenSearchDesktop:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0437\u0430\u043c\u043e\u043a \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0441\u0435\u0442\u044c Android arrow device hardware iOS lock monitor rotate web',
    ScreenShare:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0412 \u0440\u043e\u043b\u044f\u0445 \u0445\u0440\u043e\u043c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043d\u043e\u0443\u0442\u0431\u0443\u043a \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u0437\u0435\u0440\u043a\u0430\u043b\u043e \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0433\u043e\u0442\u043e\u0432\u0438\u0442\u044c \u043d\u0430 \u043f\u0430\u0440\u0443 \u043f\u043e\u0442\u043e\u043a\u043e\u0432\u043e\u0435 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android arrow cast chrome device display hardware iOS laptop mac mirror monitor steam streaming web window',
    Screenshot:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u043e\u0431\u0440\u0435\u0437\u0430\u0442\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android cell crop device hardware iOS mobile phone tablet',
    ScreenshotMonitor:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0445\u0440\u043e\u043c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u0441\u043a\u0440\u0438\u043d\u0448\u043e\u0442 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android chrome desktop device display hardware iOS mac screengrab web window',
    ScubaDiving:
        '\u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043f\u043b\u0430\u0432\u0430\u043d\u0438\u0435 entertainment exercise hobby social swimming',
    Sd: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043a\u0430\u043c\u0435\u0440\u0430 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0434\u0430\u043d\u043d\u044b\u0435 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0446\u0438\u0444\u0440\u043e\u0432\u043e\u0439 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0432\u0441\u043f\u044b\u0448\u043a\u0430 \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0438\u0441\u044c\u043c\u043e \u041f\u0430\u043c\u044f\u0442\u044c \u0424\u043e\u0442\u043e \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet camera card character data device digital drive flash font image letter memory photo secure symbol text type',
    SdCard: '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u043e\u0432\u043e\u0439 \u041f\u0430\u043c\u044f\u0442\u044c \u0444\u043e\u0442\u043e \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 camera digital memory photos secure storage',
    SdCardAlert:
        '! \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043a\u0430\u043c\u0435\u0440\u0430 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0446\u0438\u0444\u0440\u043e\u0432\u043e\u0439 \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u041f\u0430\u043c\u044f\u0442\u044c \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! attention camera caution danger digital error exclamation important mark memory notification photos secure storage symbol warning',
    SdStorage:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0446\u0438\u0444\u0440\u043e\u0432\u043e\u0439 \u041f\u0430\u043c\u044f\u0442\u044c \u043c\u0438\u043a\u0440\u043e\u0441\u0434 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 camera card data digital memory microsd secure',
    Search: '\u0444\u0438\u043b\u044c\u0442\u0440 \u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u0432\u0438\u0434\u0435\u0442\u044c \u0432\u0432\u0435\u0440\u0445 filter find glass look magnifying see up',
    SearchOff:
        '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u043d\u0430 \u0432\u0438\u0434\u0435\u0442\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0418\u043a\u0441 cancel close disabled enabled find glass look magnifying on see slash stop x',
    Security:
        '\u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0449\u0438\u0442 \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e certified privacy private protection shield verified',
    SecurityUpdate:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android arrow device download hardware iOS mobile phone tablet',
    SecurityUpdateGood:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 Ok \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 Android checkmark device hardware iOS mobile ok phone tablet tick',
    SecurityUpdateWarning:
        '! \u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u043b\u0430\u043d\u0448\u0435\u0442 ! Android alert attention caution danger device download error exclamation hardware iOS important mark mobile notification phone symbol tablet',
    Segment:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0448\u0440\u0438\u0444\u0442\u044b \u0444\u043e\u0440\u043c\u0430\u0442 \u043b\u0438\u043d\u0438\u0438 \u0441\u043f\u0438\u0441\u043e\u043a \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u0447\u0430\u0441\u0442\u044c \u043a\u0443\u0441\u043e\u043a \u043f\u0440\u0430\u0432\u0438\u043b\u0430 \u0441\u0442\u0438\u043b\u044c \u0442\u0435\u043a\u0441\u0442 alignment fonts format lines list paragraph part piece rules style text',
    SelectAll:
        '\u0432\u044b\u0431\u043e\u0440 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 selection square tool',
    SelfImprovement:
        '\u0442\u0435\u043b\u043e \u0441\u043f\u043e\u043a\u043e\u0439\u0441\u0442\u0432\u0438\u0435 \u0437\u0430\u0431\u043e\u0442\u0430 \u043f\u0440\u043e\u0432\u043e\u0434\u0438\u0442\u044c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043c\u0435\u0434\u0438\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043c\u0435\u0434\u0438\u0442\u0430\u0446\u0438\u044f \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0440\u0430\u0441\u0441\u043b\u0430\u0431\u043b\u044f\u0442\u044c\u0441\u044f \u0441\u0438\u0434\u0438\u0442 \u0431\u043b\u0430\u0433\u043e\u043f\u043e\u043b\u0443\u0447\u0438\u0435 \u0439\u043e\u0433\u0430 \u0431\u044b\u043b body calm care chi human meditate meditation people person relax sitting wellbeing yoga zen',
    Sell: '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0442\u0435\u043b\u0435\u0436\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0446\u0435\u043d\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u044f\u0440\u043b\u044b\u043a bill card cart cash coin commerce credit currency dollars money online payment price shopping tag',
    Send: '\u0447\u0430\u0442 \u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0431\u0443\u043c\u0430\u0433\u0430 \u0441\u0430\u043c\u043e\u043b\u0435\u0442 \u041e\u0442\u0432\u0435\u0442\u0438\u0442\u044c \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f \u0442\u0435\u043b\u0435\u0433\u0440\u0430\u043c\u043c\u0430 chat email message paper plane reply right share telegram',
    SendAndArchive:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u043f\u0430\u0441\u0442\u0438 \u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f arrow download email letter save share',
    SendTimeExtension:
        '\u0434\u043e\u0441\u0442\u0430\u0432\u043b\u044f\u0442\u044c \u043e\u0442\u043f\u0440\u0430\u0432\u043b\u044f\u0442\u044c \u043e\u043a\u0443\u0442\u044b\u0432\u0430\u0442\u044c \u043f\u043e\u0447\u0442\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 deliver dispatch envelop mail message schedule',
    SendToMobile:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u044d\u043a\u0441\u043f\u043e\u0440\u0442 \u0432\u043f\u0435\u0440\u0435\u0434 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android arrow device export forward hardware iOS phone right share tablet',
    SensorOccupied:
        '\u0442\u0435\u043b\u043e \u0441\u0432\u044f\u0437\u044c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u0435\u0442\u044c \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0434\u0430\u0442\u0447\u0438\u043a\u0438 \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 body connection human network people person scan sensors signal wireless',
    Sensors:
        '\u0441\u0432\u044f\u0437\u044c \u0441\u0435\u0442\u044c \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection network scan signal wireless',
    SensorsOff:
        '\u0441\u0432\u044f\u0437\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0441\u0435\u0442\u044c \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0441\u0438\u0433\u043d\u0430\u043b \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection disabled enabled network scan signal slash wireless',
    SentimentDissatisfied:
        '\u0437\u043b\u043e\u0439 \u0440\u0430\u0441\u0441\u0442\u0440\u043e\u0435\u043d\u043d\u044b\u0439 \u043d\u0435 \u043d\u0440\u0430\u0432\u0438\u0442\u0441\u044f \u044d\u043c\u043e\u0434\u0437\u0438 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u044d\u043c\u043e\u0446\u0438\u0438 \u0432\u044b\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u043b\u0438\u0446\u043e \u0447\u0443\u0432\u0441\u0442\u0432\u0430 \u0445\u043c\u0443\u0440\u0438\u0442\u044c\u0441\u044f \u043d\u0430\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0433\u0440\u0443\u0441\u0442\u043d\u044b\u0439 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u043e\u043f\u0440\u043e\u0441 \u043d\u0435\u0441\u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u043d\u0435\u0443\u0434\u043e\u0432\u043b\u0435\u0442\u0432\u043e\u0440\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0441\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e angry disappointed dislike emoji emoticon emotions expressions face feelings frown mood person sad smiley survey unhappy unsatisfied upset',
    SentimentNeutral:
        '\u0431\u0435\u0437 \u044d\u043c\u043e\u0446\u0438\u0439 \u044d\u043c\u043e\u0446\u0438\u0438 \u0432\u044b\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u043b\u0438\u0446\u043e \u0447\u0443\u0432\u0441\u0442\u0432\u0430 \u0440\u0430\u0432\u043d\u043e\u0434\u0443\u0448\u0438\u0435 \u043d\u0430\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435 \u0445\u043e\u0440\u043e\u0448\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043e\u043f\u0440\u043e\u0441 emotionless emotions expressions face feelings indifference mood okay person survey',
    SentimentSatisfied:
        '\u044d\u043c\u043e\u0434\u0437\u0438 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u044d\u043c\u043e\u0446\u0438\u0438 \u0432\u044b\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u043b\u0438\u0446\u043e \u0447\u0443\u0432\u0441\u0442\u0432\u0430 \u0440\u0430\u0434\u043e\u0441\u0442\u043d\u044b\u0439 \u0441\u0447\u0430\u0441\u0442\u044c\u0435 \u0441\u0447\u0430\u0441\u0442\u043b\u0438\u0432\u044b\u0439 \u043a\u0430\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0434\u043e\u0432\u043e\u043b\u044c\u043d\u044b\u0439 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u0443\u043b\u044b\u0431\u0430\u0435\u0442\u0441\u044f \u043e\u043f\u0440\u043e\u0441 emoji emoticon emotions expressions face feelings glad happiness happy like mood person pleased smiley smiling survey',
    SentimentSatisfiedAlt:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u044d\u043c\u043e\u0434\u0437\u0438 \u043b\u0438\u0446\u043e \u0441\u0447\u0430\u0441\u0442\u043b\u0438\u0432\u044b\u0439 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0443\u043b\u044b\u0431\u043a\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account emoji face happy human people person profile smile user',
    SentimentVeryDissatisfied:
        '\u0437\u043b\u043e\u0439 \u0440\u0430\u0441\u0441\u0442\u0440\u043e\u0435\u043d\u043d\u044b\u0439 \u043d\u0435 \u043d\u0440\u0430\u0432\u0438\u0442\u0441\u044f \u044d\u043c\u043e\u0434\u0437\u0438 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u044d\u043c\u043e\u0446\u0438\u0438 \u0432\u044b\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u043b\u0438\u0446\u043e \u0447\u0443\u0432\u0441\u0442\u0432\u0430 \u043d\u0430\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0433\u0440\u0443\u0441\u0442\u043d\u044b\u0439 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u043f\u0435\u0447\u0430\u043b\u044c \u043e\u043f\u0440\u043e\u0441 \u043d\u0435\u0441\u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u043d\u0435\u0443\u0434\u043e\u0432\u043b\u0435\u0442\u0432\u043e\u0440\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0441\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e angry disappointed dislike emoji emoticon emotions expressions face feelings mood person sad smiley sorrow survey unhappy unsatisfied upset',
    SentimentVerySatisfied:
        '\u044d\u043c\u043e\u0434\u0437\u0438 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u044d\u043c\u043e\u0446\u0438\u0438 \u0432\u044b\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u043b\u0438\u0446\u043e \u0447\u0443\u0432\u0441\u0442\u0432\u0430 \u0440\u0430\u0434\u043e\u0441\u0442\u043d\u044b\u0439 \u0441\u0447\u0430\u0441\u0442\u044c\u0435 \u0441\u0447\u0430\u0441\u0442\u043b\u0438\u0432\u044b\u0439 \u043a\u0430\u043a \u043d\u0430\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0434\u043e\u0432\u043e\u043b\u044c\u043d\u044b\u0439 \u0441\u043c\u0430\u0439\u043b\u0438\u043a \u0443\u043b\u044b\u0431\u0430\u0435\u0442\u0441\u044f \u043e\u043f\u0440\u043e\u0441 emoji emoticon emotions expressions face feelings glad happiness happy like mood person pleased smiley smiling survey',
    SetMeal:
        '\u043f\u0430\u043b\u043e\u0447\u043a\u0438 \u0434\u043b\u044f \u0435\u0434\u044b \u043e\u0431\u0435\u0434 \u0440\u044b\u0431\u044b \u0435\u0434\u0430 \u043e\u0431\u0435\u0434 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d \u0442\u0435\u0439\u0448\u043e\u043a\u0443 chopsticks dinner fish food lunch restaurant teishoku',
    Settings:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0434\u0430\u0447\u0430 \u0414\u0435\u0442\u0430\u043b\u0438 \u043c\u0435\u0445\u0430\u043d\u0438\u0437\u043c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u043f\u0446\u0438\u0438 \u043b\u0438\u0447\u043d\u044b\u0439 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 application change details gear information options personal service',
    SettingsAccessibility:
        '\u0442\u0435\u043b\u043e \u0414\u0435\u0442\u0430\u043b\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043b\u044e\u0434\u0438 \u043b\u0438\u0447\u043d\u044b\u0439 \u043f\u0440\u0435\u0434\u043f\u043e\u0447\u0442\u0435\u043d\u0438\u044f \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c body details human information people personal preferences profile user',
    SettingsApplications:
        '\u0441\u0434\u0430\u0447\u0430 \u0414\u0435\u0442\u0430\u043b\u0438 \u043c\u0435\u0445\u0430\u043d\u0438\u0437\u043c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u043f\u0446\u0438\u0438 \u043b\u0438\u0447\u043d\u044b\u0439 \u0441\u043f\u0430\u0441\u0442\u0438 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 change details gear information options personal save service',
    SettingsBackupRestore:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u0438\u0441\u0442\u043e\u0440\u0438\u044f \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0432\u0440\u0435\u043c\u044f \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c arrow backwards history refresh reverse rotate time undo',
    SettingsBluetooth:
        '\u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0441\u0435\u0442\u044c \u0441\u0438\u0433\u043d\u0430\u043b \u0441\u0438\u043c\u0432\u043e\u043b Wi-Fi connection connectivity device network signal symbol wifi',
    SettingsBrightness:
        '\u0442\u0435\u043c\u043d\u044b\u0439 \u0444\u0438\u043b\u044c\u0442\u0440 \u043b\u0435\u0433\u043a\u0438\u0439 \u0420\u0435\u0436\u0438\u043c \u0441\u043e\u043b\u043d\u0446\u0435 dark filter light mode sun',
    SettingsCell:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0421\u043e\u0442\u043e\u0432\u044b\u0439 \u0442\u0435\u043b\u0435\u0444\u043e\u043d \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android cellphone device hardware iOS mobile tablet',
    SettingsEthernet:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0441\u043a\u043e\u0431\u043a\u0438 \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u0442\u043e\u0447\u043a\u0438 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u0441\u043a\u043e\u0431\u043a\u0430 Wi-Fi arrows brackets computer connection connectivity dots internet network parenthesis wifi',
    SettingsInputAntenna:
        '\u0442\u0440\u0430\u043d\u0441\u043b\u044f\u0446\u0438\u044f \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u0442\u043e\u0447\u043a\u0438 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u0441\u043a\u0440\u0438\u043d\u043a\u0430\u0441\u0442 \u0440\u0443\u0447\u0435\u0439 Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 airplay arrows computer connection connectivity dots internet network screencast stream wifi wireless',
    SettingsInputComponent:
        '\u0430\u0443\u0434\u0438\u043e \u0438\u0437 \u043a\u0430\u0431\u0435\u043b\u0438 \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0432\u0438\u043b\u043a\u0438 \u0442\u043e\u0447\u043a\u0438 \u0432\u0438\u0434\u0435\u043e Wi-Fi audio av cables connection connectivity internet plugs points video wifi',
    SettingsInputComposite:
        '\u043a\u0430\u0431\u0435\u043b\u044c \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u0430\u044f \u0447\u0430\u0441\u0442\u044c \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u0432\u0438\u043b\u043a\u0438 \u0442\u043e\u0447\u043a\u0438 cable component connection connectivity plugs points',
    SettingsInputHdmi:
        '\u043a\u0430\u0431\u0435\u043b\u044c \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u0438\u0435 \u0432\u044b\u0441\u043e\u043a\u0430\u044f \u043f\u043b\u0430\u0433\u0438\u043d \u0442\u043e\u0447\u043a\u0438 \u0432\u0438\u0434\u0435\u043e \u043f\u0440\u043e\u0432\u043e\u0434 cable connection connectivity definition high plugin points video wire',
    SettingsInputSvideo:
        '\u043a\u0430\u0431\u0435\u043b\u044c \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u0438\u0435 \u043f\u043b\u0430\u0433\u0438\u043d \u0432\u0438\u043b\u043a\u0438 \u0442\u043e\u0447\u043a\u0438 \u0441\u0442\u0430\u043d\u0434\u0430\u0440\u0442\u043d\u044b\u0439 \u043f\u043e\u043d\u0440\u0430\u0432\u0438\u043b\u043e\u0441\u044c cable connection connectivity definition plugin plugs points standard svideo,',
    SettingsOverscan:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0440\u0430\u0441\u0448\u0438\u0440\u044f\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0424\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 arrows expand image photo picture',
    SettingsPhone:
        '\u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0442\u0435\u043b\u0435\u0444\u043e\u043d call cell contact device hardware mobile telephone',
    SettingsPower:
        '\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0441\u043f\u0430\u0441\u0442\u0438 \u043d\u0435\u0438\u0441\u043f\u0440\u0430\u0432\u043d\u043e\u0441\u0442\u044c information off save shutdown',
    SettingsRemote:
        'Bluetooth \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0441\u0438\u0433\u043d\u0430\u043b Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 bluetooth connection connectivity control device signal wifi wireless',
    SettingsSuggest:
        '\u0441\u0434\u0430\u0447\u0430 \u0414\u0435\u0442\u0430\u043b\u0438 \u043c\u0435\u0445\u0430\u043d\u0438\u0437\u043c \u043e\u043f\u0446\u0438\u0438 \u0440\u0435\u043a\u043e\u043c\u0435\u043d\u0434\u0430\u0446\u0438\u044f \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u043f\u0440\u0435\u0434\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u0441\u0438\u0441\u0442\u0435\u043c\u0430 change details gear options recommendation service suggestion system',
    SettingsSystemDaydream:
        '\u0440\u0435\u0437\u0435\u0440\u0432\u043d\u043e\u0435 \u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0431\u043b\u0430\u043a\u043e \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 backup cloud drive storage',
    SettingsVoice:
        '\u043c\u0438\u043a\u0440\u043e\u0444\u043e\u043d \u0440\u0435\u043a\u043e\u0440\u0434\u0435\u0440 \u043e\u0440\u0430\u0442\u043e\u0440 microphone recorder speaker',
    SevenK: '7000 7\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e 7000 7K alphabet character digit display font letter number pixels resolution symbol text type video',
    SevenKPlus:
        '+ 7000 7\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e + 7000 7K alphabet character digit display font letter number pixels resolution symbol text type video',
    SevenMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digit font image letters megapixels number quality resolution symbol text type',
    SeventeenMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    SevereCold:
        '\u043a\u0440\u0438\u0437\u0438\u0441 \u0434\u0438\u0430\u0441\u0442\u0435\u0440 \u0441\u043d\u0435\u0436\u0438\u043d\u043a\u0430 \u043f\u043e\u0433\u043e\u0434\u0430 crisis diaster snowflake weather',
    Share: '\u0430\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0441\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u0441\u0430\u0439\u0442 \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u0441\u0435\u0442\u044c \u043e\u043f\u0446\u0438\u0438 \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u043e\u0431\u0449\u0438\u0439 \u043e\u0431\u043c\u0435\u043d \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 android connect contect link multimedia multiple network options send shared sharing social',
    ShareLocation:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 GPS \u043a\u0430\u0440\u0442\u044b \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 destination direction gps maps pin place stop tracking',
    Shield: '\u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e certified privacy private protection secure security verified',
    ShieldMoon:
        '\u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u0431\u0435\u0441\u043f\u043e\u043a\u043e\u0438\u0442\u044c \u0434\u0435\u043b\u0430\u0442\u044c \u043d\u043e\u0447\u044c \u043d\u0435\u0442 \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e certified disturb do night not privacy private protection security verified',
    Shop: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0441\u0443\u043c\u043a\u0430 \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043f\u043e\u0440\u0442\u0444\u0435\u043b\u044c \u043a\u0443\u043f\u0438\u0442\u044c \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0442\u0435\u043b\u0435\u0436\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 Google \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u043f\u043e\u043a\u0443\u043f\u043a\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0445\u0440\u0430\u043d\u0438\u0442\u044c arrow bag bill briefcase buy card cart cash coin commerce credit currency dollars google money online payment play purchase shopping store',
    Shop2: '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0443\u043f\u0438\u0442\u044c \u0442\u0435\u043b\u0435\u0436\u043a\u0430 Google \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u043f\u043e\u043a\u0443\u043f\u043a\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c add arrow buy cart google play purchase shopping',
    ShoppingBag:
        '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0431\u0438\u0437\u043d\u0435\u0441 \u043a\u0443\u043f\u0438\u0442\u044c \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0442\u0435\u043b\u0435\u0436\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u0432\u0438\u0442\u0440\u0438\u043d\u0430 bill business buy card cart cash coin commerce credit currency dollars money online payment storefront',
    ShoppingBasket:
        '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043a\u0443\u043f\u0438\u0442\u044c \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0442\u0435\u043b\u0435\u0436\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043f\u0440\u043e\u0432\u0435\u0440\u0438\u0442\u044c \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u043a\u0443\u043f\u043a\u0430 add bill buy card cart cash checkout coin commerce credit currency dollars money online payment purchase',
    ShoppingCart:
        '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u043a\u0443\u043f\u0438\u0442\u044c \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043f\u0440\u043e\u0432\u0435\u0440\u0438\u0442\u044c \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u043a\u0443\u043f\u043a\u0430 add bill buy card cash checkout coin commerce credit currency dollars money online payment purchase',
    ShoppingCartCheckout:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e arrow cash coin commerce currency dollars money online payment right',
    ShopTwo:
        '\u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043f\u043e\u0440\u0442\u0444\u0435\u043b\u044c \u043a\u0443\u043f\u0438\u0442\u044c \u0442\u0435\u043b\u0435\u0436\u043a\u0430 Google \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u043f\u043e\u043a\u0443\u043f\u043a\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c add arrow briefcase buy cart google play purchase shopping',
    Shortcut:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043f\u0435\u0440\u0435\u0434 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e arrow direction forward right',
    ShortText:
        '\u043a\u0440\u0430\u0442\u043a\u0438\u0439 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u043b\u0438\u043d\u0438\u0438 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0448\u0443 brief comment document lines note write writing',
    ShowChart:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043b\u0438\u043d\u0438\u044f \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u043f\u0440\u0435\u0437\u0435\u043d\u0442\u0430\u0446\u0438\u044f \u043f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u0437\u0430\u043f\u0430\u0441 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bars chart data diagram infographic line measure metrics presentation show statistics stock tracking',
    Shower: '\u0432\u0430\u043d\u043d\u0430\u044f \u043a\u043e\u043c\u043d\u0430\u0442\u0430 \u0448\u043a\u0430\u0444 \u0434\u043e\u043c \u0434\u043e\u043c \u043c\u0435\u0441\u0442\u043e \u0441\u0430\u043d\u0442\u0435\u0445\u043d\u0438\u043a\u0430 \u0440\u0430\u0437\u0431\u0440\u044b\u0437\u0433\u0438\u0432\u0430\u0442\u0435\u043b\u044c \u043c\u044b\u0442\u044c \u0432\u043e\u0434\u0430 \u0422\u0443\u0430\u043b\u0435\u0442 bathroom closet home house place plumbing sprinkler wash water wc',
    Shuffle:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u041c\u0443\u0437\u044b\u043a\u0430 \u0441\u043b\u0443\u0447\u0430\u0439\u043d\u044b\u0439 \u0432\u0438\u0434\u0435\u043e arrows controls music random video',
    ShuffleOn:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u041c\u0443\u0437\u044b\u043a\u0430 \u0441\u043b\u0443\u0447\u0430\u0439\u043d\u044b\u0439 \u0432\u0438\u0434\u0435\u043e arrows controls music random video',
    ShutterSpeed:
        '\u043e\u0442\u0432\u0435\u0440\u0441\u0442\u0438\u0435 \u043a\u0430\u043c\u0435\u0440\u0430 \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043e\u0431\u044a\u0435\u043a\u0442\u0438\u0432 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0444\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0442\u0430\u0439\u043c\u0435\u0440 \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c aperture camera duration image lens photography photos picture setting stop timer watch',
    Sick: 'COVID-19 \u0434\u0438\u0441\u043a\u043e\u043c\u0444\u043e\u0440\u0442 \u044d\u043c\u043e\u0446\u0438\u0438 \u0432\u044b\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u043b\u0438\u0446\u043e \u0447\u0443\u0432\u0441\u0442\u0432\u0430 \u0436\u0430\u0440 \u0433\u0440\u0438\u043f\u043f \u0431\u043e\u043b\u044c\u043d\u043e\u0439 \u043d\u0430\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435 \u0431\u043e\u043b\u044c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043e\u043f\u0440\u043e\u0441 \u0440\u0430\u0441\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e covid discomfort emotions expressions face feelings fever flu ill mood pain person survey upset',
    SignalCellular0Bar:
        '\u0434\u0430\u043d\u043d\u044b\u0435 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 data internet mobile network phone speed wifi wireless',
    SignalCellular4Bar:
        '\u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 data internet mobile network phone speed wifi wireless',
    SignalCellularAlt:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 analytics bar chart data diagram infographic internet measure metrics mobile network phone statistics tracking wifi wireless',
    SignalCellularAlt1Bar:
        '\u0434\u0430\u043d\u043d\u044b\u0435 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 data internet mobile network phone speed wifi wireless',
    SignalCellularAlt2Bar:
        '\u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 data internet mobile network phone speed wifi wireless',
    SignalCellularConnectedNoInternet0Bar:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 ! alert attention caution danger data error exclamation important mark mobile network notification phone symbol warning wifi wireless',
    SignalCellularConnectedNoInternet1Bar: '\u0441\u0435\u0442\u044c network',
    SignalCellularConnectedNoInternet2Bar: '\u0441\u0435\u0442\u044c network',
    SignalCellularConnectedNoInternet3Bar: '\u0441\u0435\u0442\u044c network',
    SignalCellularConnectedNoInternet4Bar:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 ! alert attention caution danger data error exclamation important mark mobile network notification phone symbol warning wifi wireless',
    SignalCellularNodata:
        '\u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043e\u043a\u0438\u0434\u0430\u0442\u044c Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 \u0418\u043a\u0441 internet mobile network offline phone quit wifi wireless x',
    SignalCellularNoSim:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u0447\u0438\u043f \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u041f\u0430\u043c\u044f\u0442\u044c \u0441\u0435\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 camera card chip device disabled enabled memory network offline phone slash storage',
    SignalCellularNull:
        '\u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 data internet mobile network phone wifi wireless',
    SignalCellularOff:
        '\u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 data disabled enabled internet mobile network offline phone slash wifi wireless',
    SignalWifi0Bar:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular data internet mobile network phone wireless',
    SignalWifi1Bar: '\u0441\u0435\u0442\u044c network',
    SignalWifi1BarLock: '\u0441\u0435\u0442\u044c network',
    SignalWifi2Bar: '\u0441\u0435\u0442\u044c network',
    SignalWifi2BarLock: '\u0441\u0435\u0442\u044c network',
    SignalWifi3Bar: '\u0441\u0435\u0442\u044c network',
    SignalWifi3BarLock: '\u0441\u0435\u0442\u044c network',
    SignalWifi4Bar:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular data internet mobile network phone wireless',
    SignalWifi4BarLock:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u043f\u0430\u0440\u043e\u043b\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular data internet locked mobile network password phone privacy private protection safety secure security wireless',
    SignalWifiBad:
        '\u0431\u0430\u0440 \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0432\u044b\u0445\u043e\u0434 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u043d\u0435\u0442 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043e\u043a\u0438\u0434\u0430\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 bar cancel cellular close data exit internet mobile network no phone quit remove stop wireless',
    SignalWifiConnectedNoInternet4:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 \u0418\u043a\u0441 cellular data mobile network offline phone wireless x',
    SignalWifiOff:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular data disabled enabled internet mobile network phone slash speed wireless',
    SignalWifiStatusbar4Bar:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular data internet mobile network phone speed wireless',
    SignalWifiStatusbarConnectedNoInternet4:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 ! alert attention caution cellular danger data error exclamation important mark mobile network notification phone speed symbol warning wireless',
    SignalWifiStatusbarNull:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular data internet mobile network phone speed wireless',
    SignLanguage:
        '\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u044f \u0433\u043b\u0443\u0445\u043e\u0439 \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0440\u0443\u043a\u0430 communication deaf fingers gesture hand',
    Signpost:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043a\u0430\u0440\u0442\u044b \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u0441\u0438\u0433\u043d\u0430\u043b \u0437\u043d\u0430\u043a\u0438 \u0443\u043b\u0438\u0446\u0430 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrow direction left maps right signal signs street traffic',
    SimCard:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0447\u0438\u043f \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u041f\u0430\u043c\u044f\u0442\u044c \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 camera chip device memory network phone storage',
    SimCardAlert:
        '! \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043a\u0430\u043c\u0435\u0440\u0430 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0446\u0438\u0444\u0440\u043e\u0432\u043e\u0439 \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u041f\u0430\u043c\u044f\u0442\u044c \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e \u0441\u0434 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! attention camera caution danger digital error exclamation important mark memory notification photos sd secure storage symbol warning',
    SimCardDownload:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0430\u043c\u0435\u0440\u0430 \u0447\u0438\u043f \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u041f\u0430\u043c\u044f\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 arrow camera chip device memory phone storage',
    SingleBed:
        '\u0421\u043f\u0430\u043b\u044c\u043d\u044f \u0434\u0432\u043e\u0439\u043d\u043e\u0439 \u043c\u0435\u0431\u0435\u043b\u044c \u0434\u043e\u043c \u043e\u0442\u0435\u043b\u044c \u0434\u043e\u043c \u043a\u043e\u0440\u043e\u043b\u044c \u043d\u043e\u0447\u044c \u043f\u043e\u0434\u0443\u0448\u043a\u0438 \u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0430 \u043e\u0442\u0434\u044b\u0445\u0430\u0442\u044c \u0441\u043f\u0430\u0442\u044c \u0431\u043b\u0438\u0437\u043d\u0435\u0446 bedroom double furniture home hotel house king night pillows queen rest sleep twin',
    Sip: '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0432\u044b\u0437\u043e\u0432 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043d\u043e\u043c\u0435\u0440\u043e\u043d\u0430\u0431\u0438\u0440\u0430\u0442\u0435\u043b\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u043e\u0441\u0432\u044f\u0449\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043d\u0430\u0434 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u0440\u043e\u0442\u043e\u043a\u043e\u043b \u043c\u0430\u0440\u0448\u0440\u0443\u0442\u0438\u0437\u0430\u0446\u0438\u044f \u0441\u0435\u0430\u043d\u0441 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0433\u043e\u043b\u043e\u0441 alphabet call character dialer font initiation internet letter over phone protocol routing session symbol text type voice',
    SixK: '6000 6\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e 6000 6K alphabet character digit display font letter number pixels resolution symbol text type video',
    SixKPlus:
        '+ 6000 6\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e + 6000 6K alphabet character digit display font letter number pixels resolution symbol text type video',
    SixMp: '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digit font image letters megapixels number quality resolution symbol text type',
    SixteenMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    SixtyFps:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u0430 \u043a\u0430\u0434\u0440\u044b \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0438\u0434\u0435\u043e camera digit frames number symbol video',
    SixtyFpsSelect:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0420\u0430\u043c\u043a\u0430 \u0447\u0430\u0441\u0442\u043e\u0442\u0430 \u0447\u0438\u0441\u043b\u0430 \u0437\u0430 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0441\u0435\u043a\u0443\u043d\u0434\u044b \u0432\u0438\u0434\u0435\u043e camera digits frame frequency numbers per rate seconds video',
    Skateboarding:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0442\u0435\u043b\u043e \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u043a\u0435\u0439\u0442\u0431\u043e\u0440\u0434\u0438\u0441\u0442 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 athlete athletic body entertainment exercise hobby human people person skateboarder social sports',
    SkipNext:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0432\u043f\u0435\u0440\u0435\u0434 \u041c\u0443\u0437\u044b\u043a\u0430 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u043f\u0440\u0435\u0434\u044b\u0434\u0443\u0449\u0438\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0432\u0438\u0434\u0435\u043e arrow back controls forward music play previous transport video',
    SkipPrevious:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u0432\u043f\u0435\u0440\u0435\u0434 \u041c\u0443\u0437\u044b\u043a\u0430 \u0441\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0439 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0432\u0438\u0434\u0435\u043e arrow backward controls forward music next play transport video',
    Sledding:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0442\u0435\u043b\u043e \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u0430\u043d\u0438 \u0441\u043d\u0435\u0433 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0437\u0438\u043c\u0430 athlete athletic body entertainment exercise hobby human people person sledge snow social sports travel winter',
    Slideshow:
        '\u043a\u0438\u043d\u043e \u0444\u043e\u0442\u043e \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u043f\u0440\u0435\u0437\u0435\u043d\u0442\u0430\u0446\u0438\u044f \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0432\u0438\u0434\u0435\u043e \u041f\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c movie photos play presentation square video view',
    SlowMotionVideo:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0440\u0443\u0433 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u041c\u0443\u0437\u044b\u043a\u0430 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0432\u0440\u0435\u043c\u044f arrow circle controls music play speed time',
    SmartButton:
        '\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u0430\u0432\u0442\u043e \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043a\u043e\u043c\u043f\u043e\u0437\u0438\u0442\u043e\u0440 \u0444\u0443\u043d\u043a\u0446\u0438\u044f \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0441\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 \u0437\u0432\u0435\u0437\u0434\u044b \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 action auto components composer function interface special stars ui ux website',
    SmartDisplay:
        '\u0442\u0440\u0430\u043d\u0441\u043b\u044f\u0446\u0438\u044f \u0445\u0440\u043e\u043c \u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0441\u043a\u0440\u0438\u043d\u043a\u0430\u0441\u0442 \u0440\u0443\u0447\u0435\u0439 \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 \u0432\u0438\u0434\u0435\u043e \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 airplay chrome connect device screencast stream television tv video wireless',
    Smartphone:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u0447\u0430\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0442\u0435\u043a\u0441\u0442 Android call cell chat device hardware iOS mobile tablet text',
    SmartScreen:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0442\u0440\u0430\u043d\u0441\u043b\u044f\u0446\u0438\u044f \u043a\u043b\u0435\u0442\u043a\u0430 \u0441\u043e\u0435\u0434\u0438\u043d\u044f\u0442\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u0440\u0438\u043d\u043a\u0430\u0441\u0442 \u0440\u0443\u0447\u0435\u0439 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0432\u0438\u0434\u0435\u043e Android airplay cell connect device hardware iOS mobile phone screencast stream tablet video',
    SmartToy:
        '\u0438\u0433\u0440\u044b \u0440\u043e\u0431\u043e\u0442 games robot',
    SmokeFree:
        '\u0441\u0438\u0433\u0430\u0440\u0435\u0442\u0430 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0438\u043a\u043e\u0433\u0434\u0430 \u043d\u0435\u0442 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043c\u0435\u0441\u0442\u0430 \u0437\u0430\u043f\u0440\u0435\u0449\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043a\u0443\u0440\u0435\u043d\u0438\u0435 \u0442\u0430\u0431\u0430\u043a \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 \u0437\u043e\u043d\u0430 cigarette disabled enabled never no off places prohibited slash smoking tobacco warning zone',
    SmokingRooms:
        '\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u044b\u0439 \u0441\u0438\u0433\u0430\u0440\u0435\u0442\u0430 \u043c\u0435\u0441\u0442\u0430 \u0434\u044b\u043c \u0442\u0430\u0431\u0430\u043a \u0437\u043e\u043d\u0430 allowed cigarette places smoke tobacco zone',
    Sms: '3 \u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u044f \u0431\u0435\u0441\u0435\u0434\u0430 \u0442\u043e\u0447\u043a\u0438 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0431\u043e\u043b\u0435\u0435 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0440\u0435\u0447\u044c \u0442\u0440\u0438 3 bubble chat comment communication conversation dots message more service speech three',
    SmsFailed:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043f\u0443\u0437\u044b\u0440\u0438 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u044f \u0431\u0435\u0441\u0435\u0434\u0430 \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0440\u0435\u0447\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! alert attention bubbles caution chat comment communication conversation danger error exclamation important mark message notification service speech symbol warning',
    SnippetFolder:
        '\u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 data document drive file sheet slide storage',
    Snooze: '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u0427\u0430\u0441\u044b \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u043b\u0435\u043d \u0442\u0430\u0439\u043c\u0435\u0440 \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c alarm bell clock duration notification set timer watch',
    Snowboarding:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0442\u0435\u043b\u043e \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0437\u0438\u043c\u0430 athlete athletic body entertainment exercise hobby human people person social sports travel winter',
    Snowmobile:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043a\u0438\u043c\u043e\u0431\u0438\u043b\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0437\u0438\u043c\u0430 automobile car direction skimobile social sports transportation travel vehicle winter',
    Snowshoeing:
        '\u0442\u0435\u043b\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0445\u043e\u0434\u044c\u0431\u0430 \u0437\u0438\u043c\u0430 body human people person sports travel walking winter',
    Soap: '\u0432\u0430\u043d\u043d\u0430\u044f \u043a\u043e\u043c\u043d\u0430\u0442\u0430 \u0447\u0438\u0441\u0442\u044b\u0439 \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0440\u0443\u043a\u0430 \u043c\u044b\u0442\u044c \u0422\u0443\u0430\u043b\u0435\u0442 bathroom clean fingers gesture hand wash wc',
    SocialDistance:
        '6 \u043e\u0442\u0434\u0435\u043b\u044c\u043d\u043e \u0442\u0435\u043b\u043e \u0444\u0443\u0442\u043e\u0432 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e 6 apart body ft human people person space',
    SolarPower:
        '\u044d\u043a\u043e \u044d\u043d\u0435\u0440\u0433\u0438\u044f \u043d\u0430\u0433\u0440\u0435\u0432\u0430\u0442\u044c \u0433\u043d\u0435\u0437\u0434\u043e \u0441\u043e\u043b\u043d\u0435\u0447\u043d\u043e eco energy heat nest sunny',
    Sort: '\u0444\u0438\u043b\u044c\u0442\u0440 \u043d\u0430\u0439\u0442\u0438 \u043b\u0438\u043d\u0438\u0438 \u0441\u043f\u0438\u0441\u043e\u043a \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u043e\u0432\u0430\u0442\u044c filter find lines list organize',
    SortByAlpha:
        '\u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0438\u0442\u044c \u043f\u043e \u0430\u043b\u0444\u0430\u0432\u0438\u0442\u0443 \u0432 \u043f\u043e \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u0431\u0443\u043a\u0432\u044b \u0441\u043f\u0438\u0441\u043e\u043a \u0437\u0430\u043a\u0430\u0437 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u043e\u0432\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabetize az by character font letters list order organize symbol text type',
    Sos: '\u0448\u0440\u0438\u0444\u0442 \u043f\u043e\u043c\u043e\u0449\u044c \u0431\u0443\u043a\u0432\u044b \u0441\u043f\u0430\u0441\u0442\u0438 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f font help letters save text type',
    SoupKitchen:
        '\u0437\u0430\u0432\u0442\u0440\u0430\u043a \u043f\u043e\u0437\u0434\u043d\u0438\u0439 \u0437\u0430\u0432\u0442\u0440\u0430\u043a \u0441\u0442\u043e\u043b\u043e\u0432\u0430\u044f \u0435\u0434\u0430 \u043e\u0431\u0435\u0434 \u0435\u0434\u0430 breakfast brunch dining food lunch meal',
    Source: '\u043a\u043e\u0434 \u043a\u043e\u043c\u043f\u043e\u0437\u0438\u0442\u043e\u0440 \u0441\u043e\u0434\u0435\u0440\u0436\u0430\u043d\u0438\u0435 \u0442\u0432\u043e\u0440\u0447\u0435\u0441\u0442\u0432\u043e \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0430 \u0420\u0435\u0436\u0438\u043c \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u041f\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c code composer content creation data document file folder mode storage view',
    South: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u0432\u043d\u0438\u0437 \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f arrow directional down maps navigation',
    SouthAmerica:
        '\u0410\u043c\u0435\u0440\u0438\u043a\u0430 \u043a\u043e\u043d\u0442\u0438\u043d\u0435\u043d\u0442 \u043f\u0435\u0439\u0437\u0430\u0436 \u043c\u0435\u0441\u0442\u043e \u043e\u0431\u043b\u0430\u0441\u0442\u044c, \u043a\u0440\u0430\u0439 \u044e\u0433 america continent landscape place region south',
    SouthEast:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u0432\u043d\u0438\u0437 \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e arrow directional down maps navigation right',
    SouthWest:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u0432\u043d\u0438\u0437 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f arrow directional down left maps navigation',
    Spa: '\u0430\u0440\u043e\u043c\u0430\u0442\u0435\u0440\u0430\u043f\u0438\u044f \u0446\u0432\u0435\u0442\u043e\u043a \u0437\u0434\u0440\u0430\u0432\u043e\u043e\u0445\u0440\u0430\u043d\u0435\u043d\u0438\u0435 \u043b\u0438\u0441\u0442 \u043c\u0430\u0441\u0441\u0430\u0436 \u043c\u0435\u0434\u0438\u0442\u0430\u0446\u0438\u044f \u043f\u0440\u0438\u0440\u043e\u0434\u0430 \u043b\u0435\u043f\u0435\u0441\u0442\u043a\u0438 \u043c\u0435\u0441\u0442\u0430 \u0440\u0430\u0441\u0441\u043b\u0430\u0431\u043b\u044f\u0442\u044c\u0441\u044f \u0431\u043b\u0430\u0433\u043e\u043f\u043e\u043b\u0443\u0447\u0438\u0435 \u0437\u0434\u043e\u0440\u043e\u0432\u044c\u0435 aromatherapy flower healthcare leaf massage meditation nature petals places relax wellbeing wellness',
    SpaceBar:
        '\u043a\u043b\u0430\u0432\u0438\u0430\u0442\u0443\u0440\u0430 \u043b\u0438\u043d\u0438\u044f keyboard line',
    SpatialAudio:
        '\u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u0437\u0432\u0443\u043a music note sound',
    SpatialAudioOff:
        '[\u043d\u0435 \u0432 \u0441\u0435\u0442\u0438] \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0432\u0443\u043a [offline] disabled enabled music note on slash sound',
    SpatialTracking:
        '[\u043d\u0435 \u0432 \u0441\u0435\u0442\u0438] \u0430\u0443\u0434\u0438\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u041c\u0443\u0437\u044b\u043a\u0430 \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0432\u0443\u043a [offline] audio disabled enabled music note on slash sound',
    Speaker:
        '\u0430\u0443\u0434\u0438\u043e \u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u044b\u0439 \u0433\u0440\u043e\u043c\u043a\u0438\u0439 \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0432\u0443\u043a \u0441\u0442\u0435\u0440\u0435\u043e \u0441\u0438\u0441\u0442\u0435\u043c\u0430 \u0432\u0438\u0434\u0435\u043e audio box electronic loud music sound stereo system video',
    SpeakerGroup:
        '\u0430\u0443\u0434\u0438\u043e \u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u044b\u0439 \u0433\u0440\u043e\u043c\u043a\u0438\u0439 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0432\u0443\u043a \u0441\u0442\u0435\u0440\u0435\u043e \u0441\u0438\u0441\u0442\u0435\u043c\u0430 \u0432\u0438\u0434\u0435\u043e audio box electronic loud multiple music sound stereo system video',
    SpeakerNotes:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u043a\u0430\u0440\u0442\u044b \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u043f\u0438\u0441\u043e\u043a \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0440\u0435\u0447\u044c \u0442\u0435\u043a\u0441\u0442 bubble cards chat comment communicate format list message speech text',
    SpeakerNotesOff:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u043a\u0430\u0440\u0442\u044b \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u043f\u0438\u0441\u043e\u043a \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0440\u0435\u0447\u044c \u0442\u0435\u043a\u0441\u0442 bubble cards chat comment communicate disabled enabled format list message on slash speech text',
    SpeakerPhone:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0437\u0432\u0443\u043a \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u043e\u0431\u044a\u0435\u043c Android cell device hardware iOS mobile sound tablet volume',
    Speed: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0427\u0430\u0441\u044b \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u043d\u0430\u0431\u0438\u0440\u0430\u0442\u044c \u043d\u043e\u043c\u0435\u0440 \u0431\u044b\u0441\u0442\u0440\u043e \u0438\u0437\u043c\u0435\u0440\u044f\u0442\u044c \u043c\u0435\u0440\u0430 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u041c\u0443\u0437\u044b\u043a\u0430 \u043c\u0435\u0434\u043b\u0435\u043d\u043d\u044b\u0439 \u0441\u043f\u0438\u0434\u043e\u043c\u0435\u0442\u0440 \u0442\u0435\u0441\u0442 \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0432\u0438\u0434\u0435\u043e arrow clock controls dial fast gauge measure motion music slow speedometer test velocity video',
    Spellcheck:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e Ok \u043f\u0440\u043e\u0446\u0435\u0441\u0441\u043e\u0440 \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u0442\u0438\u043f \u0441\u043b\u043e\u0432\u043e \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u0434\u0430 alphabet approve character checkmark edit font letter ok processor select symbol text tick type word write yes',
    Splitscreen:
        '\u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u043c\u043d\u043e\u0433\u043e\u0437\u0430\u0434\u0430\u0447\u043d\u043e\u0441\u0442\u044c \u0434\u0432\u0430 grid layout multitasking two',
    Spoke: '\u0441\u0432\u044f\u0437\u044c \u0441\u0435\u0442\u044c \u0440\u0430\u0434\u0438\u0443\u0441 connection network radius',
    Sports: '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0431\u0430\u0441\u043a\u0435\u0442\u0431\u043e\u043b \u0434\u0443\u0435\u0442 \u0442\u0440\u0435\u043d\u0435\u0440 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0438\u0433\u0440\u0430 \u0445\u043e\u0431\u0431\u0438 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442 \u0436\u0438\u0442\u044c \u0440\u0435\u0444\u0435\u0440\u0438 \u0444\u0443\u0442\u0431\u043e\u043b\u044c\u043d\u044b\u0439 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0437\u0432\u0443\u043a \u0442\u0440\u043e\u0444\u0435\u0439 \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 \u0441\u0432\u0438\u0441\u0442 athlete athletic basketball blowing coach entertainment exercise game hobby instrument live referee soccer social sound trophy warning whistle',
    SportsBar:
        '\u0430\u043b\u043a\u043e\u0433\u043e\u043b\u044c \u043f\u0438\u0432\u043e \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u043b\u0438\u043a\u0435\u0440 \u043f\u0438\u043d\u0442\u0430 \u043c\u0435\u0441\u0442\u0430 \u043f\u0430\u0431 alcohol beer drink liquor pint places pub',
    SportsBaseball:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0438\u0433\u0440\u0430 \u0445\u043e\u0431\u0431\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 athlete athletic entertainment exercise game hobby social',
    SportsBasketball:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0438\u0433\u0440\u0430 \u0445\u043e\u0431\u0431\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 athlete athletic entertainment exercise game hobby social',
    SportsCricket:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u043c\u044f\u0447 \u043e\u0434\u0438\u043d \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0438\u0433\u0440\u0430 \u0445\u043e\u0431\u0431\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 athlete athletic ball bat entertainment exercise game hobby social',
    SportsEsports:
        '\u043a\u043e\u043d\u0442\u0440\u043e\u043b\u043b\u0435\u0440 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0433\u0435\u0439\u043c\u043f\u0430\u0434 \u0438\u0433\u0440\u044b \u0445\u043e\u0431\u0431\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u0438\u0433\u0440\u043e\u0432\u0430\u044f \u043f\u0440\u0438\u0441\u0442\u0430\u0432\u043a\u0430 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0432\u0438\u0434\u0435\u043e Xbox controller entertainment gamepad gaming hobby online playstation social video xbox',
    SportsFootball:
        '\u0430\u043c\u0435\u0440\u0438\u043a\u0430\u043d\u0441\u043a\u0438\u0439 \u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0438\u0433\u0440\u0430 \u0445\u043e\u0431\u0431\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 american athlete athletic entertainment exercise game hobby social',
    SportsGolf:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u043c\u044f\u0447 \u043a\u043b\u0443\u0431 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0438\u0433\u0440\u0430 \u0438\u0433\u0440\u043e\u043a \u0432 \u0433\u043e\u043b\u044c\u0444 \u0438\u0433\u0440\u0430 \u0432 \u0433\u043e\u043b\u044c\u0444 \u0445\u043e\u0431\u0431\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 athlete athletic ball club entertainment exercise game golfer golfing hobby social',
    SportsGymnastics:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 athlete athletic entertainment exercise hobby social',
    SportsHandball:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0442\u0435\u043b\u043e \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0438\u0433\u0440\u0430 \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 athlete athletic body entertainment exercise game hobby human people person social',
    SportsHockey:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0438\u0433\u0440\u0430 \u0445\u043e\u0431\u0431\u0438 \u043b\u0435\u0434 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043f\u0430\u043b\u043e\u0447\u043a\u0438 athlete athletic entertainment exercise game hobby ice social sticks',
    SportsKabaddi:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0442\u0435\u043b\u043e \u0431\u043e\u0439 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0431\u043e\u0440\u044c\u0431\u0430 \u0438\u0433\u0440\u0430 \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0434\u0437\u044e\u0434\u043e \u0431\u043e\u0435\u0432\u043e\u0439 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0431\u043e\u0440\u043e\u0442\u044c\u0441\u044f \u0431\u043e\u0440\u044c\u0431\u0430 athlete athletic body combat entertainment exercise fighting game hobby human judo martial people person social wrestle wrestling',
    SportsMartialArts:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043a\u0430\u0440\u0430\u0442\u044d \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 athlete athletic entertainment exercise hobby human karate people person social',
    SportsMma:
        '\u0438\u0441\u043a\u0443\u0441\u0441\u0442\u0432\u0430 \u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0437\u0430\u043d\u0438\u043c\u0430\u0442\u044c\u0441\u044f \u0431\u043e\u043a\u0441\u043e\u043c \u0431\u043e\u0439 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0431\u043e\u0440\u044c\u0431\u0430 \u0438\u0433\u0440\u0430 \u043f\u0435\u0440\u0447\u0430\u0442\u043a\u0430 \u0445\u043e\u0431\u0431\u0438 \u0431\u043e\u0435\u0432\u043e\u0439 \u0441\u043c\u0435\u0448\u0430\u043d\u043d\u044b\u0439 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 arts athlete athletic boxing combat entertainment exercise fighting game glove hobby martial mixed social',
    SportsMotorsports:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0432\u0435\u043b\u043e\u0441\u0438\u043f\u0435\u0434 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0432\u043e\u0436\u0434\u0435\u043d\u0438\u0435 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0448\u043b\u0435\u043c \u0445\u043e\u0431\u0431\u0438 \u043c\u043e\u0442\u043e\u0446\u0438\u043a\u043b \u0437\u0430\u0449\u0438\u0449\u0430\u0442\u044c \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f athlete athletic automobile bike drive driving entertainment helmet hobby motorcycle protect social vehicle',
    SportsRugby:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u043c\u044f\u0447 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0438\u0433\u0440\u0430 \u0445\u043e\u0431\u0431\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 athlete athletic ball entertainment exercise game hobby social',
    SportsScore:
        '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u0444\u043b\u0430\u0433 \u0426\u0435\u043b\u044c destination flag goal',
    SportsSoccer:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0444\u0443\u0442\u0431\u043e\u043b \u0438\u0433\u0440\u0430 \u0445\u043e\u0431\u0431\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 athlete athletic entertainment exercise football game hobby social',
    SportsTennis:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u043c\u044f\u0447 \u043e\u0434\u0438\u043d \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0438\u0433\u0440\u0430 \u0445\u043e\u0431\u0431\u0438 \u0440\u044d\u043a\u0435\u0442 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 athlete athletic ball bat entertainment exercise game hobby racket social',
    SportsVolleyball:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0438\u0433\u0440\u0430 \u0445\u043e\u0431\u0431\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 athlete athletic entertainment exercise game hobby social',
    Square: '\u0440\u0438\u0441\u043e\u0432\u0430\u0442\u044c \u0447\u0435\u0442\u044b\u0440\u0435 \u0447\u0435\u0442\u044b\u0440\u0435\u0445\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u0444\u043e\u0440\u043c\u0430 \u0441\u0442\u043e\u0440\u043e\u043d\u044b draw four quadrangle shape sides',
    SquareFoot:
        '\u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u043d\u043e\u0433\u0438 \u0434\u044e\u0439\u043c\u044b \u0434\u043b\u0438\u043d\u0430 \u0438\u0437\u043c\u0435\u0440\u0435\u043d\u0438\u0435 \u043f\u0440\u0430\u0432\u0438\u0442\u0435\u043b\u044c \u0448\u043a\u043e\u043b\u0430 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u043b\u0435\u043d \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u044b construction feet inches length measurement ruler school set tools',
    SsidChart:
        '\u0433\u0440\u0430\u0444\u0438\u043a \u043b\u0438\u043d\u0438\u0438 \u0441\u0435\u0442\u044c Wi-Fi graph lines network wifi',
    StackedBarChart:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430-\u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics chart-chart data diagram infographic measure metrics statistics tracking',
    StackedLineChart:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics data diagram infographic measure metrics statistics tracking',
    Stadium:
        '\u041c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u044f \u0430\u043c\u0444\u0438\u0442\u0435\u0430\u0442\u0440 \u0430\u0440\u0435\u043d\u0430 \u041a\u043e\u043b\u0438\u0437\u0435\u0439 \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u043c\u0435\u0441\u0442\u043d\u044b\u0439 \u0437\u0432\u0435\u0437\u0434\u0430 \u0432\u0435\u0449\u0438 \u043f\u0440\u043e\u0435\u0437\u0434\u043d\u043e\u0439 \u0431\u0438\u043b\u0435\u0442 activity amphitheater arena coliseum event local star things ticket',
    Stairs: '\u0432\u043d\u0438\u0437 \u043b\u0435\u0441\u0442\u043d\u0438\u0446\u0430 \u0432\u0432\u0435\u0440\u0445 down staircase up',
    Star: '\u041b\u0443\u0447\u0448\u0438\u0439 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0432\u044b\u0434\u0435\u043b\u044f\u0442\u044c \u0440\u0430\u043d\u0436\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0441\u043f\u0430\u0441\u0442\u0438 \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c best bookmark favorite highlight ranking rate rating save toggle',
    StarBorder:
        '\u041b\u0443\u0447\u0448\u0438\u0439 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0432\u044b\u0434\u0435\u043b\u044f\u0442\u044c \u043a\u043e\u043d\u0442\u0443\u0440 \u0440\u0430\u043d\u0436\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0441\u043f\u0430\u0441\u0442\u0438 \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c best bookmark favorite highlight outline ranking rate rating save toggle',
    StarBorderPurple500:
        '\u041b\u0443\u0447\u0448\u0438\u0439 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0432\u044b\u0434\u0435\u043b\u044f\u0442\u044c \u043a\u043e\u043d\u0442\u0443\u0440 \u0440\u0430\u043d\u0436\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0441\u043f\u0430\u0441\u0442\u0438 \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c best bookmark favorite highlight outline ranking rate rating save toggle',
    StarHalf:
        '0,5 1/2 \u0434\u043e\u0441\u0442\u0438\u0436\u0435\u043d\u0438\u0435 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0432\u044b\u0434\u0435\u043b\u044f\u0442\u044c \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0447\u0435\u043d \u0440\u0430\u043d\u0436\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u043d\u0430\u0433\u0440\u0430\u0434\u0430 \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d \u0444\u043e\u0440\u043c\u0430 \u0441\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c 0.5 1/2 achievement bookmark favorite highlight important marked ranking rate rating reward saved shape special toggle',
    StarOutline:
        '\u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u043f\u043e\u043b\u043e\u0432\u0438\u043d\u0430 \u0432\u044b\u0434\u0435\u043b\u044f\u0442\u044c \u0440\u0430\u043d\u0436\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0441\u043f\u0430\u0441\u0442\u0438 \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c bookmark favorite half highlight ranking rate rating save toggle',
    StarPurple500:
        '\u041b\u0443\u0447\u0448\u0438\u0439 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0432\u044b\u0434\u0435\u043b\u044f\u0442\u044c \u0440\u0430\u043d\u0436\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0441\u043f\u0430\u0441\u0442\u0438 \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c best bookmark favorite highlight ranking rate rating save toggle',
    StarRate:
        '\u0434\u043e\u0441\u0442\u0438\u0436\u0435\u043d\u0438\u0435 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0432\u044b\u0434\u0435\u043b\u044f\u0442\u044c \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0447\u0435\u043d \u0440\u0430\u043d\u0436\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u043d\u0430\u0433\u0440\u0430\u0434\u0430 \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d \u0444\u043e\u0440\u043c\u0430 \u0441\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 achievement bookmark favorite highlight important marked ranking rating reward saved shape special',
    Stars: '\u0434\u043e\u0441\u0442\u0438\u0436\u0435\u043d\u0438\u0435 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043a\u0440\u0443\u0433 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0432\u044b\u0434\u0435\u043b\u044f\u0442\u044c \u0432\u0430\u0436\u043d\u044b\u0439 \u043a\u0430\u043a \u043b\u044e\u0431\u043e\u0432\u044c \u043e\u0442\u043c\u0435\u0447\u0435\u043d \u0440\u0430\u043d\u0436\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u043d\u0430\u0433\u0440\u0430\u0434\u0430 \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d \u0444\u043e\u0440\u043c\u0430 \u0441\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 achievement bookmark circle favorite highlight important like love marked ranking rate rating reward saved shape special',
    Start: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043b\u0430\u0432\u0438\u0430\u0442\u0443\u0440\u0430 \u0441\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0439 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e arrow keyboard next right',
    StayCurrentLandscape:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android device hardware iOS mobile phone tablet',
    StayCurrentPortrait:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android device hardware iOS mobile phone tablet',
    StayPrimaryLandscape:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0422\u0435\u043a\u0443\u0449\u0438\u0439 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android current device hardware iOS mobile phone tablet',
    StayPrimaryPortrait:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0422\u0435\u043a\u0443\u0449\u0438\u0439 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android current device hardware iOS mobile phone tablet',
    StickyNote2:
        '\u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0431\u0443\u043c\u0430\u0433\u0430 \u0442\u0435\u043a\u0441\u0442 \u043f\u0438\u0448\u0443 bookmark message paper text writing',
    Stop: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u041c\u0443\u0437\u044b\u043a\u0430 \u041f\u0430\u0443\u0437\u0430 \u0438\u0433\u0440\u043e\u043a \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0432\u0438\u0434\u0435\u043e arrow controls music pause player square video',
    StopCircle:
        '\u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u0442 \u041c\u0443\u0437\u044b\u043a\u0430 \u041f\u0430\u0443\u0437\u0430 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0432\u0438\u0434\u0435\u043e controls music pause play square video',
    StopScreenShare:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0412 \u0440\u043e\u043b\u044f\u0445 \u0445\u0440\u043e\u043c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043d\u043e\u0443\u0442\u0431\u0443\u043a \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u0437\u0435\u0440\u043a\u0430\u043b\u043e \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0433\u043e\u0442\u043e\u0432\u0438\u0442\u044c \u043d\u0430 \u043f\u0430\u0440\u0443 \u043f\u043e\u0442\u043e\u043a\u043e\u0432\u043e\u0435 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android arrow cast chrome device disabled display enabled hardware iOS laptop mac mirror monitor offline slash steam streaming web window',
    Storage:
        '\u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0431\u0430\u0437\u0430 \u0434\u0430\u043d\u043d\u044b\u0445 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u041f\u0430\u043c\u044f\u0442\u044c \u0441\u0435\u0442\u044c \u0441\u0435\u0440\u0432\u0435\u0440 computer database drive memory network server',
    Store: '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u0431\u0438\u0437\u043d\u0435\u0441 \u043a\u0443\u043f\u0438\u0442\u044c \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043c\u043e\u043d\u0435\u0442\u0430 \u041a\u043e\u043c\u043f\u0430\u043d\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0440\u044b\u043d\u043e\u043a \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u043e\u043a\u0443\u043f\u043a\u0430 \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u0432\u0438\u0442\u0440\u0438\u043d\u0430 bill building business buy card cash coin company credit currency dollars e-commerce market money online payment purchase shopping storefront',
    Storefront:
        '\u0431\u0438\u0437\u043d\u0435\u0441 \u043a\u0443\u043f\u0438\u0442\u044c \u043a\u0430\u0444\u0435 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0440\u044b\u043d\u043e\u043a \u0442\u043e\u0440\u0433\u043e\u0432\u0435\u0446 \u043c\u0435\u0441\u0442\u0430 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d \u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u043f\u0440\u043e\u0434\u0430\u0432\u0430\u0442\u044c \u043f\u043e\u0445\u043e\u0434 \u043f\u043e \u043c\u0430\u0433\u0430\u0437\u0438\u043d\u0430\u043c \u043b\u0430\u0440\u0435\u043a business buy cafe commerce market merchant places restaurant retail sell shopping stall',
    StoreMallDirectory:
        '\u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e building',
    Storm: '\u043f\u0440\u043e\u0433\u043d\u043e\u0437 \u0443\u0440\u0430\u0433\u0430\u043d \u0442\u0435\u043c\u043f\u0435\u0440\u0430\u0442\u0443\u0440\u0430 \u0442\u0432\u0438\u0441\u0442\u0435\u0440 \u043f\u043e\u0433\u043e\u0434\u0430 \u0432\u0435\u0442\u0435\u0440 forecast hurricane temperature twister weather wind',
    Straight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u0432\u0432\u0435\u0440\u0445 arrows directions maps navigation path route sign traffic up',
    Straighten:
        '\u0434\u043b\u0438\u043d\u0430 \u0438\u0437\u043c\u0435\u0440\u0435\u043d\u0438\u0435 \u043f\u0438\u0430\u043d\u0438\u043d\u043e \u043f\u0440\u0430\u0432\u0438\u0442\u0435\u043b\u044c \u0440\u0430\u0437\u043c\u0435\u0440 length measurement piano ruler size',
    Stream: '\u0412 \u0440\u043e\u043b\u044f\u0445 \u0441\u0432\u044f\u0437\u0430\u043d\u043e \u043f\u043e\u0434\u0430\u0447\u0430 \u0436\u0438\u0442\u044c \u0441\u0435\u0442\u044c \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cast connected feed live network signal wireless',
    Streetview:
        'GPS \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b gps location maps',
    StrikethroughS:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u041f\u0435\u0440\u0435\u0441\u0435\u043a\u0430\u0442\u044c \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0432\u043d\u0435 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u0438 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alphabet character cross doc editing editor font letter out spreadsheet styles symbol text type writing',
    Stroller:
        '\u043c\u0430\u043b\u044b\u0448 \u0437\u0430\u0431\u043e\u0442\u0430 \u043f\u0435\u0440\u0435\u0432\u043e\u0437\u043a\u0430 \u0434\u0435\u0442\u0438 \u043c\u043b\u0430\u0434\u0435\u043d\u0435\u0446 \u0440\u0435\u0431\u0435\u043d\u043e\u043a \u043d\u043e\u0432\u043e\u0440\u043e\u0436\u0434\u0435\u043d\u043d\u044b\u0439 \u0440\u0435\u0431\u0435\u043d\u043e\u043a, \u043d\u0430\u0447\u0438\u043d\u0430\u044e\u0449\u0438\u0439 \u0445\u043e\u0434\u0438\u0442\u044c \u043c\u043e\u043b\u043e\u0434\u043e\u0439 baby care carriage children infant kid newborn toddler young',
    Style: '\u0431\u0443\u043a\u043b\u0435\u0442 \u043a\u0430\u0440\u0442\u044b \u0444\u0438\u043b\u044c\u0442\u0440\u044b \u043e\u043f\u0446\u0438\u0438 \u0442\u0435\u0433\u0438 booklet cards filters options tags',
    SubdirectoryArrowLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0432\u043d\u0438\u0437 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f arrow down navigation',
    SubdirectoryArrowRight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0432\u043d\u0438\u0437 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f arrow down navigation',
    Subject:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043f\u043e\u043b\u043d\u044b\u0439 \u043e\u043f\u0440\u0430\u0432\u0434\u044b\u0432\u0430\u0442\u044c \u043b\u0438\u043d\u0438\u0438 \u0441\u043f\u0438\u0441\u043e\u043a \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u0442\u0435\u043a\u0441\u0442 \u043f\u0438\u0448\u0443 alignment document email full justify lines list note text writing',
    Subscript:
        '2 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 Gmail \u043d\u043e\u0432\u0438\u0437\u043d\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u043f\u0438\u0448\u0443 2 doc editing editor gmail novitas spreadsheet style symbol text writing',
    Subscriptions:
        '\u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0421\u041c\u0418 \u0437\u0430\u043a\u0430\u0437 \u043f\u043b\u0435\u0439\u043b\u0438\u0441\u0442 \u043e\u0447\u0435\u0440\u0435\u0434\u044c \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u043f\u043e\u0434\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c\u0441\u044f YouTube enroll media order playlist queue signup subscribe youtube',
    Subtitles:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u043e\u0441\u0442\u044c \u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b\u0439 \u043f\u043e\u0434\u043f\u0438\u0441\u0438 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0437\u0430\u043a\u0440\u044b\u0442\u043e \u0434\u0435\u043a\u043e\u0434\u0435\u0440 \u044f\u0437\u044b\u043a \u0421\u041c\u0418 \u0444\u0438\u043b\u044c\u043c\u044b \u043f\u0435\u0440\u0435\u0432\u0435\u0441\u0442\u0438 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 accessibility accessible captions character closed decoder language media movies translate tv',
    SubtitlesOff:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u043e\u0441\u0442\u044c \u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b\u0439 \u043f\u043e\u0434\u043f\u0438\u0441\u044c \u0437\u0430\u043a\u0440\u044b\u0442\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u044f\u0437\u044b\u043a \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u0435\u0440\u0435\u0432\u0435\u0441\u0442\u0438 \u0432\u0438\u0434\u0435\u043e accessibility accessible caption closed disabled enabled language slash translate video',
    Subway: '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0432\u0435\u043b\u043e\u0441\u0438\u043f\u0435\u0434 \u043c\u0430\u0448\u0438\u043d\u044b \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0442\u0440\u043e \u0440\u0435\u043b\u044c\u0441 \u0441\u0430\u043c\u043e\u043a\u0430\u0442 \u0442\u0440\u0435\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0442\u0443\u043d\u043d\u0435\u043b\u044c \u043f\u043e\u0434 \u0437\u0435\u043c\u043b\u0435\u0439 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0432\u0435\u0441\u043f\u0430 automobile bike cars maps metro rail scooter train transportation travel tunnel underground vehicle vespa',
    Summarize:
        '\u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0441\u043f\u0438\u0441\u043e\u043a \u043c\u0435\u043d\u044e \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u043e\u0442\u0447\u0435\u0442 \u0440\u0435\u0437\u044e\u043c\u0435 document list menu note report summary',
    Superscript:
        '2 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 Gmail \u043d\u043e\u0432\u0438\u0437\u043d\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0441\u0442\u0438\u043b\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u043f\u0438\u0448\u0443 2 doc editing editor gmail novitas spreadsheet style symbol text writing',
    SupervisedUserCircle:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0440\u043e\u0434\u0438\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0440\u043e\u0434\u0438\u0442\u0435\u043b\u0438 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0440\u0443\u043a\u043e\u0432\u043e\u0434\u0438\u0442\u0435\u043b\u044c account avatar control face human parental parents people person profile supervisor',
    SupervisorAccount:
        '\u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440 \u0430\u0432\u0430\u0442\u0430\u0440 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0440\u043e\u0434\u0438\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0440\u043e\u0434\u0438\u0442\u0435\u043b\u0438 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u0438\u0440\u0443\u0435\u043c\u044b\u0439 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c administrator avatar control face human parental parents people person profile supervised user',
    Support:
        '\u043f\u043e\u043c\u043e\u0447\u044c \u043f\u043e\u043c\u043e\u0449\u044c \u0441\u043f\u0430\u0441\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u043a\u0440\u0443\u0433 \u0441\u043f\u0430\u0441\u0430\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c assist help lifebuoy rescue safety',
    SupportAgent:
        '\u0437\u0430\u0431\u043e\u0442\u0430 \u043f\u043e\u043a\u0443\u043f\u0430\u0442\u0435\u043b\u044c \u043b\u0438\u0446\u043e \u043d\u0430\u0443\u0448\u043d\u0438\u043a\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u0435\u0434\u0441\u0442\u0430\u0432\u0438\u0442\u0435\u043b\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 care customer face headphone person representative service',
    Surfing:
        '\u0441\u043f\u043e\u0440\u0442\u0441\u043c\u0435\u043d \u0421\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u043e\u0435 \u043f\u043b\u044f\u0436 \u0442\u0435\u043b\u043e \u0440\u0430\u0437\u0432\u043b\u0435\u0447\u0435\u043d\u0438\u044f \u0443\u043f\u0440\u0430\u0436\u043d\u0435\u043d\u0438\u0435 \u0445\u043e\u0431\u0431\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043c\u043e\u0440\u0435 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u043f\u043e\u0440\u0442\u0438\u0432\u043d\u044b\u0439 \u043b\u0435\u0442\u043e \u0432\u043e\u0434\u0430 athlete athletic beach body entertainment exercise hobby human people person sea social sports summer water',
    SurroundSound:
        '\u0430\u0443\u0434\u0438\u043e \u043a\u0440\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u043e\u0440\u0430\u0442\u043e\u0440 \u0441\u0438\u0441\u0442\u0435\u043c\u0430 \u043e\u0431\u044a\u0435\u043c \u043e\u0431\u044a\u0435\u043c \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 audio circle signal speaker system volume volumn wireless',
    SwapCalls:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u041f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f arrows device direction mobile share',
    SwapHoriz:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u0437\u0430\u0434 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043f\u0435\u0440\u0435\u0434 \u0433\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u044b\u0439 arrows back direction forward horizontal',
    SwapHorizontalCircle:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u0437\u0430\u0434 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043f\u0435\u0440\u0435\u0434 arrows back direction forward',
    SwapVert:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u0437\u0430\u0434 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u0437 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0432\u0432\u0435\u0440\u0445 \u0432\u0435\u0440\u0442\u0438\u043a\u0430\u043b\u044c\u043d\u044b\u0439 arrows back direction down navigation up vertical',
    SwapVerticalCircle:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u0437\u0430\u0434 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u0437 \u0433\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u044b\u0439 \u0432\u0432\u0435\u0440\u0445 arrows back direction down horizontal up',
    Swipe: '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0420\u0443\u043a\u0438 \u0442\u0440\u043e\u0433\u0430\u0442\u044c arrows fingers gesture hands touch',
    SwipeDown:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0438\u0442\u044c \u041f\u0430\u043b\u0435\u0446 \u0420\u0443\u043a\u0438 \u0445\u0438\u0442 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0437\u0430\u0431\u0430\u0441\u0442\u043e\u0432\u043a\u0430 \u043a\u0430\u0447\u0430\u0442\u044c \u0441\u0447\u0438\u0442\u044b\u0432\u0430\u043d\u0438\u0435 \u0431\u0440\u0430\u0442\u044c arrows direction disable enable finger hands hit navigation strike swing swpie take',
    SwipeDownAlt:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0438\u0442\u044c \u041f\u0430\u043b\u0435\u0446 \u0420\u0443\u043a\u0438 \u0445\u0438\u0442 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0437\u0430\u0431\u0430\u0441\u0442\u043e\u0432\u043a\u0430 \u043a\u0430\u0447\u0430\u0442\u044c \u0441\u0447\u0438\u0442\u044b\u0432\u0430\u043d\u0438\u0435 \u0431\u0440\u0430\u0442\u044c arrows direction disable enable finger hands hit navigation strike swing swpie take',
    SwipeLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u041f\u0430\u043b\u0435\u0446 \u0440\u0443\u043a\u0430 \u0445\u0438\u0442 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043e\u0442\u043a\u043b\u043e\u043d\u044f\u0442\u044c \u0437\u0430\u0431\u0430\u0441\u0442\u043e\u0432\u043a\u0430 \u043a\u0430\u0447\u0430\u0442\u044c \u0431\u0440\u0430\u0442\u044c arrows finger hand hit navigation reject strike swing take',
    SwipeLeftAlt:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u041f\u0430\u043b\u0435\u0446 \u0440\u0443\u043a\u0430 \u0445\u0438\u0442 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043e\u0442\u043a\u043b\u043e\u043d\u044f\u0442\u044c \u0437\u0430\u0431\u0430\u0441\u0442\u043e\u0432\u043a\u0430 \u043a\u0430\u0447\u0430\u0442\u044c \u0431\u0440\u0430\u0442\u044c arrows finger hand hit navigation reject strike swing take',
    SwipeRight:
        '\u043f\u0440\u0438\u043d\u0438\u043c\u0430\u0442\u044c \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u041f\u0430\u043b\u0435\u0446 \u0420\u0443\u043a\u0438 \u0445\u0438\u0442 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0437\u0430\u0431\u0430\u0441\u0442\u043e\u0432\u043a\u0430 \u043a\u0430\u0447\u0430\u0442\u044c \u0441\u0447\u0438\u0442\u044b\u0432\u0430\u043d\u0438\u0435 \u0431\u0440\u0430\u0442\u044c accept arrows direction finger hands hit navigation strike swing swpie take',
    SwipeRightAlt:
        '\u043f\u0440\u0438\u043d\u0438\u043c\u0430\u0442\u044c \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u041f\u0430\u043b\u0435\u0446 \u0420\u0443\u043a\u0438 \u0445\u0438\u0442 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0437\u0430\u0431\u0430\u0441\u0442\u043e\u0432\u043a\u0430 \u043a\u0430\u0447\u0430\u0442\u044c \u0441\u0447\u0438\u0442\u044b\u0432\u0430\u043d\u0438\u0435 \u0431\u0440\u0430\u0442\u044c accept arrows direction finger hands hit navigation strike swing swpie take',
    SwipeUp:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0438\u0442\u044c \u041f\u0430\u043b\u0435\u0446 \u0420\u0443\u043a\u0438 \u0445\u0438\u0442 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0437\u0430\u0431\u0430\u0441\u0442\u043e\u0432\u043a\u0430 \u043a\u0430\u0447\u0430\u0442\u044c \u0441\u0447\u0438\u0442\u044b\u0432\u0430\u043d\u0438\u0435 \u0431\u0440\u0430\u0442\u044c arrows direction disable enable finger hands hit navigation strike swing swpie take',
    SwipeUpAlt:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0438\u0442\u044c \u041f\u0430\u043b\u0435\u0446 \u0420\u0443\u043a\u0438 \u0445\u0438\u0442 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0437\u0430\u0431\u0430\u0441\u0442\u043e\u0432\u043a\u0430 \u043a\u0430\u0447\u0430\u0442\u044c \u0441\u0447\u0438\u0442\u044b\u0432\u0430\u043d\u0438\u0435 \u0431\u0440\u0430\u0442\u044c arrows direction disable enable finger hands hit navigation strike swing swpie take',
    SwipeVertical:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u041f\u0430\u043b\u0435\u0446 \u0420\u0443\u043a\u0438 \u0445\u0438\u0442 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0437\u0430\u0431\u0430\u0441\u0442\u043e\u0432\u043a\u0430 \u043a\u0430\u0447\u0430\u0442\u044c \u0441\u0447\u0438\u0442\u044b\u0432\u0430\u043d\u0438\u0435 \u0431\u0440\u0430\u0442\u044c \u0432\u0435\u0440\u0442\u0438\u043a\u0443\u043b arrows direction finger hands hit navigation strike swing swpie take verticle',
    SwitchAccessShortcut:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043d\u043e\u0432\u044b\u0439 \u043a \u0441\u0435\u0432\u0435\u0440\u0443 \u0437\u0432\u0435\u0437\u0434\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0432\u0435\u0440\u0445 arrows direction navigation new north star symbol up',
    SwitchAccessShortcutAdd:
        '+ \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043d\u043e\u0432\u044b\u0439 \u043a \u0441\u0435\u0432\u0435\u0440\u0443 \u043f\u043b\u044e\u0441 \u0437\u0432\u0435\u0437\u0434\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0432\u0435\u0440\u0445 + arrows direction navigation new north plus star symbol up',
    SwitchAccount:
        '\u0432\u044b\u0431\u043e\u0440 \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043e\u043f\u0446\u0438\u0438 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c choices face human multiple options people person profile social user',
    SwitchCamera:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 arrows photography picture',
    SwitchLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c arrows directional navigation toggle',
    SwitchRight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c arrows directional navigation toggle',
    SwitchVideo:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u0430\u043c\u0435\u0440\u0430 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0432\u0438\u0434\u0435\u043e arrows camera photography videos',
    Synagogue:
        '\u0435\u0432\u0440\u0435\u0439\u0441\u043a\u0438\u0439 \u0440\u0435\u043b\u0438\u0433\u0438\u044f \u0441\u0438\u043d\u0430\u0433\u043e\u0433\u0430 \u0434\u0443\u0445\u043e\u0432\u043d\u044b\u0439 \u0445\u0440\u0430\u043c \u043f\u043e\u043a\u043b\u043e\u043d\u0435\u043d\u0438\u0435 jewish religion shul spiritual temple worship',
    Sync: '360 \u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c 360 around arrows direction inprogress loading refresh renew rotate turn',
    SyncAlt:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0433\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u044b\u0439 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438 \u041e\u0431\u043d\u043e\u0432\u0438\u0442\u044c Wi-Fi arrows horizontal internet technology update wifi',
    SyncDisabled:
        '360 \u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c 360 around arrows direction enabled inprogress loading off refresh renew rotate slash turn',
    SyncLock:
        '\u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u043f\u0430\u0440\u043e\u043b\u044c \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c around arrows locked password privacy private protection renew rotate safety secure security turn',
    SyncProblem:
        '! 360 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 ! 360 alert around arrows attention caution danger direction error exclamation important inprogress loading mark notification refresh renew rotate symbol turn warning',
    SystemSecurityUpdate:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0432\u043d\u0438\u0437 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android arrow cell device down hardware iOS mobile phone tablet',
    SystemSecurityUpdateGood:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u043a\u043b\u0435\u0442\u043a\u0430 \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 Ok \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 Android approve cell check complete device done hardware iOS mark mobile ok phone select tablet tick validate verified yes',
    SystemSecurityUpdateWarning:
        '! \u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043a\u043b\u0435\u0442\u043a\u0430 \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u043b\u0430\u043d\u0448\u0435\u0442 ! Android alert attention caution cell danger device error exclamation hardware iOS important mark mobile notification phone symbol tablet',
    SystemUpdate:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u0438\u0442\u044c \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043f\u043b\u0430\u043d\u0448\u0435\u0442 Android arrows cell device direction download hardware iOS install mobile phone tablet',
    SystemUpdateAlt:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u044d\u043a\u0441\u043f\u043e\u0440\u0442 arrow download export',
    Tab: '\u0431\u0440\u0430\u0443\u0437\u0435\u0440 \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442\u044b \u043f\u0430\u043f\u043a\u0430 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0432\u043a\u043b\u0430\u0434\u043a\u0438 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u0430 browser computer documents folder internet tabs website windows',
    TableBar:
        '\u043a\u0430\u0444\u0435 \u043a\u0440\u0443\u0433\u043b\u044b\u0439 cafe round',
    TableChart:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440\u044b \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0441\u0435\u0442\u043a\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bars data diagram grid infographic measure metrics statistics tracking',
    TableRestaurant:
        '\u0431\u0430\u0440 \u0441\u0442\u043e\u043b\u043e\u0432\u0430\u044f bar dining',
    TableRows:
        '\u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u0441\u043b\u043e\u0436\u0435\u043d\u043d\u044b\u0439 grid layout lines stacked',
    Tablet: '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0430\u0439\u043f\u0430\u0434 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c Android device hardware iOS ipad mobile web',
    TabletAndroid:
        '\u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0430\u0439\u043f\u0430\u0434 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c device hardware iOS ipad mobile web',
    TabletMac:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u044f\u0431\u043b\u043e\u043a\u043e \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0430\u0439\u043f\u0430\u0434 \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0441\u0435\u0442\u044c Android apple device hardware iOS ipad mac mobile tablet web',
    TableView:
        '\u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u0433\u0440\u0443\u043f\u043f\u0430 \u043c\u0430\u043a\u0435\u0442 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e format grid group layout multiple',
    TabUnselected:
        '\u0431\u0440\u0430\u0443\u0437\u0435\u0440 \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442\u044b \u043f\u0430\u043f\u043a\u0430 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0432\u043a\u043b\u0430\u0434\u043a\u0438 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u0430 browser computer documents folder internet tabs website windows',
    Tag: '\u0445\u044d\u0448\u0442\u0435\u0433 \u043a\u043b\u044e\u0447 \u0421\u041c\u0418 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0444\u0443\u043d\u0442 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0442\u0440\u0435\u043d\u0434 hashtag key media number pound social trend',
    TagFaces:
        '\u044d\u043c\u043e\u0434\u0437\u0438 \u044d\u043c\u043e\u0446\u0438\u044f \u0441\u0447\u0430\u0441\u0442\u043b\u0438\u0432\u044b\u0439 \u0434\u043e\u0432\u043e\u043b\u0435\u043d \u0443\u043b\u044b\u0431\u043a\u0430 emoji emotion happy satisfied smile',
    TakeoutDining:
        '\u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440 \u0414\u043e\u0441\u0442\u0430\u0432\u043a\u0430 \u0435\u0434\u0430 \u0435\u0434\u0430 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d box container delivery food meal restaurant',
    TapAndPlay:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u043a\u043b\u0435\u0442\u043a\u0430 \u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u043d\u0444\u0441 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u0438\u0433\u043d\u0430\u043b \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u043a Wi-Fi \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 Android cell connection device hardware iOS internet mobile network nfc phone signal tablet to wifi wireless',
    Tapas: '\u0437\u0430\u043a\u0443\u0441\u043a\u0430 \u043f\u043e\u0437\u0434\u043d\u0438\u0439 \u0437\u0430\u0432\u0442\u0440\u0430\u043a \u043e\u0431\u0435\u0434 \u0435\u0434\u0430 \u043e\u0431\u0435\u0434 \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d \u043f\u0435\u0440\u0435\u043a\u0443\u0441 appetizer brunch dinner food lunch restaurant snack',
    Task: '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0438 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0431\u0443\u043c\u0430\u0433\u0430 \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u043f\u0438\u0448\u0443 \u0434\u0430 approve check complete data document done drive file folders mark ok page paper select sheet slide tick validate verified writing yes',
    TaskAlt:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043a\u0440\u0443\u0433 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve check circle complete done mark ok select tick validate verified yes',
    TaxiAlert:
        '! \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0442\u0430\u043a\u0441\u0438 \u043c\u0430\u0448\u0438\u043d\u044b \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043f\u043e\u0434\u044a\u0435\u043c\u043d\u0438\u043a \u043a\u0430\u0440\u0442\u044b \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0443\u0431\u0435\u0440 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 \u0436\u0435\u043b\u0442\u044b\u0439 ! attention automobile cab cars caution danger direction error exclamation important lyft maps mark notification public symbol transportation uber vehicle warning yellow',
    Telegram:
        '\u043c\u0430\u0440\u043a\u0430 \u0432\u044b\u0437\u043e\u0432 \u0447\u0430\u0442 \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u043e\u0431\u043c\u0435\u043d \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u044f\u043c\u0438 \u0433\u043e\u043b\u043e\u0441 brand call chat logo messaging voice',
    TempleBuddhist:
        '\u0411\u0443\u0434\u0434\u0430 \u0431\u0443\u0434\u0434\u0438\u0437\u043c \u043c\u043e\u043d\u0430\u0441\u0442\u044b\u0440\u044c \u0440\u0435\u043b\u0438\u0433\u0438\u044f \u0434\u0443\u0445\u043e\u0432\u043d\u044b\u0439 \u043f\u043e\u043a\u043b\u043e\u043d\u0435\u043d\u0438\u0435 buddha buddhism monastery religion spiritual worship',
    TempleHindu:
        '\u0438\u043d\u0434\u0443\u0438\u0437\u043c \u0438\u043d\u0434\u0443\u0441 \u043c\u0430\u043d\u0434\u0438\u0440 \u0440\u0435\u043b\u0438\u0433\u0438\u044f \u0434\u0443\u0445\u043e\u0432\u043d\u044b\u0439 \u043f\u043e\u043a\u043b\u043e\u043d\u0435\u043d\u0438\u0435 hinduism hindus mandir religion spiritual worship',
    TenMp: '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    Terminal:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u043e\u0434 \u044d\u043c\u0443\u043b\u044f\u0442\u043e\u0440 \u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0430 \u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u043d\u043e\u0433\u043e \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u044f application code emulator program software',
    Terrain:
        '\u0433\u0435\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u0430 geography landscape mountain',
    TextDecrease:
        '- \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043c\u0438\u043d\u0443\u0441 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0440\u0430\u0437\u043c\u0435\u0440 \u0432\u044b\u0447\u0435\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0438\u043f - alphabet character font letter minus remove resize subtract symbol type',
    TextFields:
        '\u0422 \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u0432\u0445\u043e\u0434 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0438\u043f T add alphabet character font input letter symbol type',
    TextFormat:
        '\u0410 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0441\u0442\u0438\u043b\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0438\u043f A alphabet character font letter square style symbol type',
    TextIncrease:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043d\u043e\u0432\u044b\u0439 \u043f\u043b\u044e\u0441 \u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0440\u0430\u0437\u043c\u0435\u0440 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0438\u043f + add alphabet character font letter new plus resize symbol type',
    TextRotateUp:
        '\u0410 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043f\u043e\u043b\u0435 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0448\u0430\u0433 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0438\u043f A alphabet arrow character field font letter move symbol type',
    TextRotateVertical:
        '\u0410 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0432\u043d\u0438\u0437 \u043f\u043e\u043b\u0435 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0448\u0430\u0433 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0438\u043f \u0432\u0435\u0440\u0442\u0438\u043a\u0443\u043b A alphabet arrow character down field font letter move symbol type verticle',
    TextRotationAngledown:
        '\u0410 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043f\u043e\u043b\u0435 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0448\u0430\u0433 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0438\u043f A alphabet arrow character field font letter move rotate symbol type',
    TextRotationAngleup:
        '\u0410 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043f\u043e\u043b\u0435 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0448\u0430\u0433 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0438\u043f A alphabet arrow character field font letter move rotate symbol type',
    TextRotationDown:
        '\u0410 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043f\u043e\u043b\u0435 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0448\u0430\u0433 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0438\u043f A alphabet arrow character field font letter move rotate symbol type',
    TextRotationNone:
        '\u0410 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u043f\u043e\u043b\u0435 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0448\u0430\u0433 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0438\u043f A alphabet arrow character field font letter move rotate symbol type',
    Textsms:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u0442\u043e\u0447\u043a\u0438 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0440\u0435\u0447\u044c bubble chat comment communicate dots feedback message speech',
    TextSnippet:
        '\u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0444\u0430\u0439\u043b \u0437\u0430\u043c\u0435\u0442\u043a\u0438 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 \u043f\u0438\u0448\u0443 data document file notes storage writing',
    Texture:
        '\u0434\u0438\u0430\u0433\u043e\u043d\u0430\u043b\u044c \u043b\u0438\u043d\u0438\u0438 \u0448\u0430\u0431\u043b\u043e\u043d \u043f\u043e\u043b\u043e\u0441\u044b diagonal lines pattern stripes',
    TheaterComedy:
        '\u0411\u0440\u043e\u0434\u0432\u0435\u0439 \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u043a\u0438\u043d\u043e \u043c\u0443\u0437\u044b\u043a\u0430\u043b\u044c\u043d\u044b\u0439 \u043c\u0435\u0441\u0442\u0430 \u043f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u0432\u0441\u0442\u0430\u0432\u0430\u0442\u044c \u0442\u0443\u0440 \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c broadway event movie musical places show standup tour watch',
    Theaters:
        '\u0444\u0438\u043b\u044c\u043c \u0421\u041c\u0418 \u0444\u0438\u043b\u044c\u043c\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0441\u0435\u0430\u043d\u0441\u044b \u0432\u0438\u0434\u0435\u043e \u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c film media movies photography showtimes video watch',
    Thermostat:
        '\u043f\u0440\u043e\u0433\u043d\u043e\u0437 \u0442\u0435\u043c\u043f\u0435\u0440\u0430\u0442\u0443\u0440\u0430 \u043f\u043e\u0433\u043e\u0434\u0430 forecast temperature weather',
    ThermostatAuto:
        '\u0410 \u0426\u0435\u043b\u044c\u0441\u0438\u044f \u0424\u0430\u0440\u0435\u043d\u0433\u0435\u0439\u0442 \u0442\u0435\u043c\u043f\u0435\u0440\u0430\u0442\u0443\u0440\u0430 \u0442\u0435\u0440\u043c\u043e\u043c\u0435\u0442\u0440 A celsius fahrenheit temperature thermometer',
    ThirteenMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    ThirtyFps:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043a\u0430\u043c\u0435\u0440\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u043a\u0430\u0434\u0440\u044b \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e alphabet camera character digit font frames letter number symbol text type video',
    ThirtyFpsSelect:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0420\u0430\u043c\u043a\u0430 \u0447\u0430\u0441\u0442\u043e\u0442\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0447\u0438\u0441\u043b\u0430 \u0437\u0430 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0441\u0435\u043a\u0443\u043d\u0434\u044b \u0432\u0438\u0434\u0435\u043e camera digits frame frequency image numbers per rate seconds video',
    ThreeDRotation:
        '3d \u0414 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0438\u0437 \u043a\u0430\u043c\u0435\u0440\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0440 3d D alphabet arrows av camera character digit font letter number symbol text type vr',
    ThreeGMobiledata:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u0438\u0433\u043d\u0430\u043b \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f Wi-Fi alphabet cellular character digit font letter network number phone signal speed symbol text type wifi',
    ThreeK: '3000 3\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e 3000 3K alphabet character digit display font letter number pixels resolution symbol text type video',
    ThreeKPlus:
        '+ 3000 3\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e + 3000 3K alphabet character digit display font letter number pixels resolution symbol text type video',
    ThreeMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digit font image letters megapixels number quality resolution symbol text type',
    ThreeP: '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0430\u0432\u0430\u0442\u0430\u0440 \u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043f\u0430\u0440\u0442\u0438\u044f \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0440\u0435\u0447\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account avatar bubble chat comment communicate face human message party people person profile speech user',
    ThreeSixty:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0438\u0437 \u043a\u0430\u043c\u0435\u0440\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0432\u0440\u0430\u0449\u0435\u043d\u0438\u0435 \u0432\u0440 arrow av camera direction rotate rotation vr',
    ThumbDown:
        '\u043d\u0435 \u043d\u0440\u0430\u0432\u0438\u0442\u0441\u044f \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u0433\u043e\u043b\u043e\u0441 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0420\u0443\u043a\u0438 \u0440\u0430\u043d\u0436\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u043e\u0442\u043a\u043b\u043e\u043d\u044f\u0442\u044c \u0432\u0432\u0435\u0440\u0445 dislike downvote favorite fingers gesture hands ranking rate rating reject up',
    ThumbDownAlt:
        '\u041f\u043b\u043e\u0445\u043e \u043e\u0442\u043a\u043b\u043e\u043d\u0438\u0442\u044c \u043d\u0435 \u043e\u0434\u043e\u0431\u0440\u044f\u0442\u044c \u043d\u0435 \u043d\u0440\u0430\u0432\u0438\u0442\u0441\u044f \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0440\u0443\u043a\u0430 \u043d\u0435\u043d\u0430\u0432\u0438\u0434\u0435\u0442\u044c \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u043d\u0435\u0442 \u043e\u0442\u043a\u043b\u043e\u043d\u044f\u0442\u044c \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0432\u0435\u0442\u043e \u0433\u043e\u043b\u043e\u0441\u043e\u0432\u0430\u043d\u0438\u0435 bad decline disapprove dislike feedback hand hate negative no reject social veto vote',
    ThumbDownOffAlt:
        '[\u043d\u0435 \u0432 \u0441\u0435\u0442\u0438] \u041f\u043b\u043e\u0445\u043e \u043e\u0442\u043a\u043b\u043e\u043d\u0438\u0442\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u043d\u0435 \u043e\u0434\u043e\u0431\u0440\u044f\u0442\u044c \u043d\u0435 \u043d\u0440\u0430\u0432\u0438\u0442\u0441\u044f \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0437\u0430\u043f\u043e\u043b\u043d\u0435\u043d\u043d\u044b\u0439 \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0420\u0443\u043a\u0438 \u043d\u0435\u043d\u0430\u0432\u0438\u0434\u0435\u0442\u044c \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u043d\u0435\u0442 \u043d\u0430 \u0440\u0430\u043d\u0436\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u043e\u0442\u043a\u043b\u043e\u043d\u044f\u0442\u044c \u0433\u0440\u0443\u0441\u0442\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0432\u0435\u0442\u043e \u0433\u043e\u043b\u043e\u0441\u043e\u0432\u0430\u043d\u0438\u0435 [offline] bad decline disabled disapprove dislike enabled favorite feedback filled fingers gesture hands hate negative no on ranking rate rating reject sad slash social veto vote',
    ThumbsUpDown:
        '\u043d\u0435 \u043d\u0440\u0430\u0432\u0438\u0442\u0441\u044f \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0420\u0443\u043a\u0438 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0433\u043e\u043b\u043e\u0441\u043e\u0432\u0430\u043d\u0438\u0435 dislike favorite fingers gesture hands rate rating vote',
    ThumbUp:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u043d\u0435 \u043d\u0440\u0430\u0432\u0438\u0442\u0441\u044f \u0432\u043d\u0438\u0437 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0420\u0443\u043a\u0438 \u0440\u0430\u043d\u0436\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0443\u0441\u043f\u0435\u0445 \u0433\u043e\u043b\u043e\u0441\u043e\u0432\u0430\u0442\u044c \u0437\u0430 approve dislike down favorite fingers gesture hands ranking rate rating success upvote',
    ThumbUpAlt:
        '\u0441\u043e\u0433\u043b\u0430\u0441\u043e\u0432\u0430\u043d\u043e \u043e\u0434\u043e\u0431\u0440\u0435\u043d\u043d\u044b\u0439 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u044b\u0439 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0445\u043e\u0440\u043e\u0448\u0438\u0439 \u0440\u0443\u043a\u0430 \u0441\u0447\u0430\u0441\u0442\u043b\u0438\u0432\u044b\u0439 \u043a\u0430\u043a \u0445\u043e\u0440\u043e\u0448\u043e \u043f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u0443\u0434\u043e\u0432\u043b\u0435\u0442\u0432\u043e\u0440\u0435\u043d\u0438\u0435 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0443\u0441\u043f\u0435\u0445 \u0433\u043e\u043b\u043e\u0441\u043e\u0432\u0430\u043d\u0438\u0435 \u0434\u0430 agreed approved confirm correct favorite feedback good hand happy like okay positive satisfaction social success vote yes',
    ThumbUpOffAlt:
        '[\u043d\u0435 \u0432 \u0441\u0435\u0442\u0438] \u0441\u043e\u0433\u043b\u0430\u0441\u043e\u0432\u0430\u043d\u043e \u043e\u0434\u043e\u0431\u0440\u0435\u043d\u043d\u044b\u0439 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u044b\u0439 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0445\u043e\u0440\u043e\u0448\u0438\u0439 \u0420\u0443\u043a\u0438 \u0441\u0447\u0430\u0441\u0442\u043b\u0438\u0432\u044b\u0439 \u043a\u0430\u043a \u0445\u043e\u0440\u043e\u0448\u043e \u043f\u043e\u043b\u043e\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u0440\u0430\u043d\u0436\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0443\u0434\u043e\u0432\u043b\u0435\u0442\u0432\u043e\u0440\u0435\u043d\u0438\u0435 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0433\u043e\u043b\u043e\u0441\u043e\u0432\u0430\u043d\u0438\u0435 \u0434\u0430 [offline] agreed approved confirm correct disabled enabled favorite feedback fingers gesture good hands happy like okay positive ranking rate rating satisfaction slash social vote yes',
    Thunderstorm:
        '\u043e\u0431\u043b\u0430\u043a\u043e \u043c\u043e\u043b\u043d\u0438\u044f \u0434\u043e\u0436\u0434\u044c \u043f\u043e\u0433\u043e\u0434\u0430 cloud lightning rain weather',
    Timelapse:
        '\u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u0424\u043e\u0442\u043e \u0442\u0430\u0439\u043c\u0435\u0440 \u0432\u0438\u0434\u0435\u043e duration motion photo timer video',
    Timeline:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0433\u0440\u0430\u0444\u0438\u043a \u0438\u0441\u0442\u043e\u0440\u0438\u044f \u043b\u0438\u043d\u0438\u044f \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u0442\u043e\u0447\u043a\u0438 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 \u0432 \u0442\u0440\u0435\u043d\u0434\u0435 \u0437\u0438\u0433\u0437\u0430\u0433 \u0437\u0438\u0433\u0437\u0430\u0433 analytics chart data graph history line movement points tracking trending zigzag zigzap',
    Timer: '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u0427\u0430\u0441\u044b \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0435\u043a\u0443\u043d\u0434\u043e\u043c\u0435\u0440 \u0436\u0434\u0430\u0442\u044c alarm alart bell clock disabled duration enabled notification off slash stopwatch wait',
    Timer10:
        '\u0446\u0438\u0444\u0440\u044b \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0438\u0441\u043b\u0430 \u0441\u0435\u043a\u0443\u043d\u0434\u044b digits duration numbers seconds',
    Timer10Select:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043a\u0430\u043c\u0435\u0440\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0435\u043a\u0443\u043d\u0434\u044b \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet camera character digit font letter number seconds symbol text type',
    Timer3: '\u0446\u0438\u0444\u0440\u044b \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0438\u0441\u043b\u0430 \u0441\u0435\u043a\u0443\u043d\u0434\u044b digits duration numbers seconds',
    Timer3Select:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043a\u0430\u043c\u0435\u0440\u0430 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0435\u043a\u0443\u043d\u0434\u044b \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f alphabet camera character digit font letter number seconds symbol text type',
    TimerOff:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043e\u043b\u043e\u043a\u043e\u043b \u0427\u0430\u0441\u044b \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u0435\u043a\u0443\u043d\u0434\u043e\u043c\u0435\u0440 alarm alart bell clock disabled duration enabled notification slash stopwatch',
    TimesOneMobiledata:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u0441\u0435\u0442\u044c \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u0438\u0433\u043d\u0430\u043b \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f Wi-Fi alphabet cellular character digit font letter network number phone signal speed symbol text type wifi',
    TimeToLeave:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0430 \u0442\u0430\u043a\u0436\u0435 \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars destination direction drive estimate eta maps public transportation travel trip vehicle',
    TipsAndUpdates:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0438\u0434\u0435\u044f \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0444\u043e\u043d\u0430\u0440\u044c \u043b\u0430\u043c\u043f\u043e\u0447\u043a\u0430 \u0437\u0432\u0435\u0437\u0434\u044b alert announcement electricity idea information lamp lightbulb stars',
    TireRepair:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u0438\u0437\u043c\u0435\u0440\u044f\u0442\u044c \u043c\u0435\u0445\u0430\u043d\u0438\u043a \u0434\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars gauge mechanic pressure vehicle',
    Title: '\u0422 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0448\u0440\u0438\u0444\u0442 \u0437\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a \u043f\u0438\u0441\u044c\u043c\u043e \u043f\u0440\u0435\u0434\u043c\u0435\u0442 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f T alphabet character font header letter subject symbol text type',
    Toc: '\u0441\u043e\u0434\u0435\u0440\u0436\u0430\u043d\u0438\u0435 \u0444\u043e\u0440\u043c\u0430\u0442 \u043b\u0438\u043d\u0438\u0438 \u0441\u043f\u0438\u0441\u043e\u043a \u0418\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u0435 \u043f\u043e\u0440\u044f\u0434\u043a\u0430 \u0441\u043b\u043e\u0436\u0435\u043d\u043d\u044b\u0439 \u0441\u0442\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u0442\u0443\u043b\u044b content format lines list reorder stacked table text titles',
    Today: '\u043f\u043e\u0432\u0435\u0441\u0442\u043a\u0430 \u0434\u043d\u044f \u043a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u043c\u0435\u0440\u043e\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u0435\u0441\u044f\u0446 \u0434\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043d\u0430\u043f\u043e\u043c\u0438\u043d\u0430\u043d\u0438\u0435 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f \u043d\u0435\u0434\u0435\u043b\u044f agenda calendar date event mark month range remember reminder schedule time week',
    ToggleOff:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043a\u043e\u043d\u0444\u0438\u0433\u0443\u0440\u0430\u0446\u0438\u044f \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u0434\u0438\u0437\u0430\u0439\u043d \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u043d\u0435\u0441\u043f\u043e\u0441\u043e\u0431\u043d\u044b\u0439 \u043d\u0435\u0430\u043a\u0442\u0438\u0432\u043d\u044b\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0432\u044b\u0431\u043e\u0440 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043f\u043e\u043b\u0437\u0443\u043d\u043e\u043a \u0432\u044b\u043a\u043b\u044e\u0447\u0430\u0442\u0435\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application components configuration control design disable inable inactive interface selection settings slider switch ui ux website',
    ToggleOn:
        '\u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043e\u0441\u0442\u0430\u0432\u043d\u044b\u0435 \u0447\u0430\u0441\u0442\u0438 \u043a\u043e\u043d\u0444\u0438\u0433\u0443\u0440\u0430\u0446\u0438\u044f \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u0434\u0438\u0437\u0430\u0439\u043d \u0437\u0430\u043f\u0440\u0435\u0449\u0430\u0442\u044c \u043d\u0435\u0441\u043f\u043e\u0441\u043e\u0431\u043d\u044b\u0439 \u043d\u0435\u0430\u043a\u0442\u0438\u0432\u043d\u044b\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0432\u044b\u0431\u043e\u0440 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043f\u043e\u043b\u0437\u0443\u043d\u043e\u043a \u0432\u044b\u043a\u043b\u044e\u0447\u0430\u0442\u0435\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 application components configuration control design disable inable inactive interface off selection settings slider switch ui ux website',
    Token: '\u0437\u043d\u0430\u0447\u043e\u043a \u0448\u0435\u0441\u0442\u0438\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0449\u0438\u0442 \u0437\u043d\u0430\u043a \u0441\u0438\u043c\u0432\u043e\u043b badge hexagon mark shield sign symbol',
    Toll: '\u0437\u0430\u043a\u043e\u043d\u043e\u043f\u0440\u043e\u0435\u043a\u0442 \u0441\u0442\u0435\u043d\u0434 \u043e\u0442\u043a\u0440\u044b\u0442\u043a\u0430 \u043d\u0430\u043b\u0438\u0447\u043d\u044b\u0435 \u043a\u0440\u0443\u0433\u0438 \u043c\u043e\u043d\u0435\u0442\u0430 \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u043a\u0440\u0435\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442\u0430 \u0434\u043e\u043b\u043b\u0430\u0440\u043e\u0432 \u0448\u043e\u0441\u0441\u0435 \u0414\u0435\u043d\u044c\u0433\u0438 \u043e\u043d\u043b\u0430\u0439\u043d \u043e\u043f\u043b\u0430\u0442\u0430 \u043f\u0440\u043e\u0435\u0437\u0434\u043d\u043e\u0439 \u0431\u0438\u043b\u0435\u0442 bill booth card cash circles coin commerce credit currency dollars highway money online payment ticket',
    Tonality:
        '\u043a\u0440\u0443\u0433 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0444\u0438\u043b\u044c\u0442\u0440 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 circle editing filter image photography picture',
    Topic: '\u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u0444\u0430\u0439\u043b \u043f\u0430\u043f\u043a\u0430 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 data document drive file folder sheet slide storage',
    Tornado:
        '\u043a\u0440\u0438\u0437\u0438\u0441 \u0441\u0442\u0438\u0445\u0438\u0439\u043d\u043e\u0435 \u0431\u0435\u0434\u0441\u0442\u0432\u0438\u0435 \u0435\u0441\u0442\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0434\u043e\u0436\u0434\u044c \u0431\u0443\u0440\u044f \u043f\u043e\u0433\u043e\u0434\u0430 \u0432\u0435\u0442\u0435\u0440 crisis disaster natural rain storm weather wind',
    TouchApp:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0440\u0443\u043a\u0430 \u041d\u0430\u0436\u043c\u0438\u0442\u0435 \u043f\u0440\u043e\u0432\u0435\u0434\u0438\u0442\u0435 \u043f\u0430\u043b\u044c\u0446\u0435\u043c \u043d\u0430\u0436\u043c\u0438\u0442\u0435 arrow command fingers gesture hand press swipe tap',
    Tour: '\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u0444\u043b\u0430\u0433 \u043c\u0435\u0441\u0442\u0430 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u043f\u043e\u0441\u0435\u0449\u0430\u0442\u044c destination flag places travel visit',
    Toys: '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043f\u043e\u043a\u043b\u043e\u043d\u043d\u0438\u043a \u0438\u0433\u0440\u044b \u0414\u0435\u0442\u0438 \u0432\u0435\u0442\u0440\u044f\u043d\u0430\u044f \u043c\u0435\u043b\u044c\u043d\u0438\u0446\u0430 car fan games kids windmill',
    TrackChanges:
        '\u043f\u0440\u044f\u043c\u043e \u0432 \u0442\u043e\u0447\u043a\u0443 \u043a\u0440\u0443\u0433 \u044d\u0432\u043e\u043b\u044e\u0446\u0438\u043e\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043b\u0438\u043d\u0438\u0438 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u0440\u0430\u0434\u0430\u0440 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0441\u0434\u0432\u0438\u0433 \u0446\u0435\u043b\u044c bullseye circle evolve lines movement radar rotate shift target',
    Traffic:
        '\u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043b\u0435\u0433\u043a\u0438\u0439 \u043a\u0430\u0440\u0442\u044b \u0441\u0438\u0433\u043d\u0430\u043b \u0443\u043b\u0438\u0446\u0430 direction light maps signal street',
    Train: '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0440\u0435\u043b\u044c\u0441 \u043c\u0435\u0442\u0440\u043e \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars direction maps public rail subway transportation vehicle',
    Tram: '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u043c\u0430\u0448\u0438\u043d\u044b \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u0440\u0435\u043b\u044c\u0441 \u043c\u0435\u0442\u0440\u043e \u0442\u0440\u0435\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f automobile cars direction maps public rail subway train transportation vehicle',
    TransferWithinAStation:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0442\u0435\u043b\u043e \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043a\u0430\u0440\u0442\u044b \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u0442\u0440\u0430\u043d\u0437\u0438\u0442 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0445\u043e\u0434\u0438\u0442\u044c arrows body direction human left maps people person public right route stop transit transportation vehicle walk',
    Transform:
        '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043e\u0431\u0440\u0435\u0437\u0430\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0424\u043e\u0442\u043e \u043a\u0430\u0440\u0442\u0438\u043d\u0430 adjust crop editing image photo picture',
    Transgender:
        '\u0436\u0435\u043d\u0441\u043a\u0438\u0439 \u041b\u0413\u0411\u0422 \u043d\u0435\u0439\u0442\u0440\u0430\u043b\u044c\u043d\u044b\u0439 \u043d\u0435\u0439\u0442\u0440\u0430\u043b\u044c\u043d\u044b\u0439 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u0438\u043c\u0432\u043e\u043b female lgbt neutral neutrual social symbol',
    TransitEnterexit:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 arrow direction maps navigation route transportation',
    Translate:
        '\u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u044f\u0437\u044b\u043a \u043f\u0438\u0441\u044c\u043c\u043e \u0413\u043e\u0432\u043e\u0440\u044f\u0449\u0438\u0439 \u0440\u0435\u0447\u044c \u0442\u0435\u043a\u0441\u0442 \u043f\u0435\u0440\u0435\u0432\u043e\u0434\u0447\u0438\u043a \u0441\u043b\u043e\u0432\u0430 alphabet language letter speaking speech text translator words',
    TravelExplore:
        '\u0437\u0435\u043c\u043d\u043e\u0439 \u0448\u0430\u0440 \u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0413\u043b\u043e\u0431\u0430\u043b\u044c\u043d\u044b\u0439 \u0433\u043b\u043e\u0431\u0443\u0441 \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u0430 \u0441\u0435\u0442\u044c \u043f\u043b\u0430\u043d\u0435\u0442\u0430 \u043f\u043e\u0438\u0441\u043a \u0432\u0438\u0434\u0435\u0442\u044c \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u043f\u0440\u043e\u0441\u0442\u0440\u0430\u043d\u0441\u0442\u0432\u043e \u0441\u0435\u0442\u044c \u041c\u0438\u0440 earth find glass global globe look magnifying map network planet search see social space web world',
    TrendingDown:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0441\u0434\u0430\u0447\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0440\u0430\u0441\u043f\u0440\u043e\u0434\u0430\u0436\u0430 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics arrow change chart data diagram infographic measure metrics movement rate rating sale statistics tracking',
    TrendingFlat:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0441\u0434\u0430\u0447\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0433\u0440\u0430\u0444\u0438\u043a \u043c\u0435\u0442\u0440\u0438\u043a\u0430 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 arrow change chart data graph metric movement rate right tracking',
    TrendingUp:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0441\u0434\u0430\u0447\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u043e\u0446\u0435\u043d\u0438\u0432\u0430\u0442\u044c \u0440\u0435\u0439\u0442\u0438\u043d\u0433 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics arrow change chart data diagram infographic measure metrics movement rate rating statistics tracking',
    TripOrigin:
        '\u043a\u0440\u0443\u0433 \u0412\u044b\u043b\u0435\u0442 \u0438\u0437 circle departure',
    Try: '\u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043f\u0443\u0437\u044b\u0440\u044c \u0447\u0430\u0442 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0432\u044b\u0434\u0435\u043b\u044f\u0442\u044c \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0447\u0435\u043d \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d \u0444\u043e\u0440\u043c\u0430 \u0441\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 \u0440\u0435\u0447\u044c \u0437\u0432\u0435\u0437\u0434\u0430 bookmark bubble chat comment communicate favorite feedback highlight important marked message saved shape special speech star',
    Tsunami:
        '\u043a\u0440\u0438\u0437\u0438\u0441 \u0441\u0442\u0438\u0445\u0438\u0439\u043d\u043e\u0435 \u0431\u0435\u0434\u0441\u0442\u0432\u0438\u0435 \u043d\u0430\u0432\u043e\u0434\u043d\u0435\u043d\u0438\u0435 \u0434\u043e\u0436\u0434\u044c \u0431\u0443\u0440\u044f \u043f\u043e\u0433\u043e\u0434\u0430 crisis disaster flood rain storm weather',
    Tty: '\u0432\u044b\u0437\u043e\u0432 \u043a\u043b\u0435\u0442\u043a\u0430 \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0433\u043b\u0443\u0445\u043e\u0439 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043e\u0441\u043b\u0430\u0431\u043b\u0435\u043d\u043d\u044b\u0439 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0440\u0435\u0447\u044c \u0440\u0430\u0437\u0433\u043e\u0432\u0430\u0440\u0438\u0432\u0430\u0442\u044c \u0442\u0435\u043b\u0435\u0444\u043e\u043d \u0442\u0435\u043a\u0441\u0442 call cell contact deaf device hardware impaired mobile speech talk telephone text',
    Tune: '\u0440\u0435\u0433\u0443\u043b\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u043f\u0446\u0438\u0438 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u043f\u043e\u043b\u0437\u0443\u043d\u043a\u0438 adjust editing options settings sliders',
    Tungsten:
        '\u044d\u043b\u0435\u043a\u0442\u0440\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0432 \u043f\u043e\u043c\u0435\u0449\u0435\u043d\u0438\u0438 \u0444\u043e\u043d\u0430\u0440\u044c \u043b\u0430\u043c\u043f\u043e\u0447\u043a\u0430 \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440 electricity indoor lamp lightbulb setting',
    TurnedIn:
        '\u0430\u0440\u0445\u0438\u0432 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0432\u0435\u0449\u044c \u044d\u0442\u0438\u043a\u0435\u0442\u043a\u0430 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u0447\u0442\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u0420\u0430\u0437\u043c\u0435\u0441\u0442\u0438\u0442\u044c \u044f\u0440\u043b\u044b\u043a archive bookmark favorite item label library reading remember ribbon save submit tag',
    TurnedInNot:
        '\u0430\u0440\u0445\u0438\u0432 \u0437\u0430\u043a\u043b\u0430\u0434\u043a\u0430 \u043b\u044e\u0431\u0438\u043c\u044b\u0439 \u0432\u0435\u0449\u044c \u044d\u0442\u0438\u043a\u0435\u0442\u043a\u0430 \u0431\u0438\u0431\u043b\u0438\u043e\u0442\u0435\u043a\u0430 \u043a\u043e\u043d\u0442\u0443\u0440 \u0447\u0442\u0435\u043d\u0438\u0435 \u0437\u0430\u043f\u043e\u043c\u043d\u0438\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u0441\u043f\u0430\u0441\u0442\u0438 \u0420\u0430\u0437\u043c\u0435\u0441\u0442\u0438\u0442\u044c \u044f\u0440\u043b\u044b\u043a archive bookmark favorite item label library outline reading remember ribbon save submit tag',
    TurnLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path route sign traffic',
    TurnRight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path route sign traffic',
    TurnSharpLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path route sign traffic',
    TurnSharpRight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path route sign traffic',
    TurnSlightLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u041f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path right route sign traffic',
    TurnSlightRight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u043e\u0441\u0442\u0440\u044b\u0439 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 arrows directions maps navigation path route sharp sign traffic',
    Tv: '\u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u043b\u0438\u043d\u0435\u0439\u043d\u044b\u0439 \u0436\u0438\u0437\u043d\u044c \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u043d\u043e\u043c\u0435\u0440 \u0441\u043a\u0440\u0438\u043d\u043a\u0430\u0441\u0442 \u0440\u0443\u0447\u0435\u0439 \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0432\u0438\u0434\u0435\u043e \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 device display linear living monitor room screencast stream television video wireless',
    TvOff: '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0445\u0440\u043e\u043c \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u0430\u043a\u0438\u043d\u0442\u043e\u0448 \u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0442\u0435\u043b\u0435\u0432\u0438\u0434\u0435\u043d\u0438\u0435 \u0441\u0435\u0442\u044c \u043e\u043a\u043d\u043e Android chrome desktop device disabled enabled hardware iOS mac monitor slash television web window',
    TwelveMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    TwentyFourMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    TwentyOneMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    TwentyThreeMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    TwentyTwoMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    TwentyZeroMp:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u044b \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u0447\u0438\u0441\u043b\u0430 \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digits font image letters megapixels numbers quality resolution symbol text type',
    Twitter:
        '\u043c\u0430\u0440\u043a\u0430 \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 brand logo social',
    TwoK: '2000 \u0433. 2\u041a \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u0438\u043a\u0441\u0435\u043b\u0435\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0438\u0434\u0435\u043e 2000 2K alphabet character digit display font letter number pixels resolution symbol text type video',
    TwoKPlus:
        '+ \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u043f\u0438\u0441\u044c\u043c\u043e \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f + alphabet character digit font letter number symbol text type',
    TwoMp: '\u043a\u0430\u043c\u0435\u0440\u0430 \u0446\u0438\u0444\u0440\u0430 \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0431\u0443\u043a\u0432\u044b \u043c\u0435\u0433\u0430\u043f\u0438\u043a\u0441\u0435\u043b\u0438 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043a\u0430\u0447\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u044e\u0449\u0430\u044f \u0441\u043f\u043e\u0441\u043e\u0431\u043d\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f camera digit font image letters megapixels number quality resolution symbol text type',
    TwoWheeler:
        '\u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c \u0432\u0435\u043b\u043e\u0441\u0438\u043f\u0435\u0434 \u043c\u0430\u0448\u0438\u043d\u044b \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043c\u043e\u043f\u0435\u0434 \u043c\u043e\u0442\u043e\u0446\u0438\u043a\u043b \u043c\u043e\u0442\u043e\u0446\u0438\u043a\u043b \u043f\u0443\u0431\u043b\u0438\u0447\u043d\u044b\u0439 \u043f\u043e\u0435\u0437\u0434\u043a\u0430 \u0432\u0435\u0440\u0445\u043e\u0432\u0430\u044f \u0435\u0437\u0434\u0430 \u0441\u0430\u043c\u043e\u043a\u0430\u0442 \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442 \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u043e\u0432\u0430\u0442\u044c \u0434\u0432\u0430 \u043c\u0435\u0442\u0440\u0430 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u043f\u0435\u0440\u0435\u0434\u0432\u0438\u0436\u0435\u043d\u0438\u044f \u0423\u0438\u043b\u0435\u0440 \u043a\u043e\u043b\u0435\u0441\u0430 automobile bicycle cars direction maps moped motorbike motorcycle public ride riding scooter transportation travel twom vehicle wheeler wheels',
    Umbrella:
        '\u043f\u043b\u044f\u0436 \u0437\u0430\u0449\u0438\u0442\u0430 \u0434\u043e\u0436\u0434\u044c \u0441\u043e\u043b\u043d\u0435\u0447\u043d\u043e beach protection rain sunny',
    Unarchive:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0432\u0445\u043e\u0434\u044f\u0449\u0438\u0435 \u043f\u043e\u0447\u0442\u0430 \u0445\u0440\u0430\u043d\u0438\u0442\u044c \u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0432\u0432\u0435\u0440\u0445 arrow inbox mail store undo up',
    Undo: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u043f\u043e\u0447\u0442\u0430 \u043f\u0440\u0435\u0434\u044b\u0434\u0443\u0449\u0438\u0439 \u043f\u0435\u0440\u0435\u0434\u0435\u043b\u044b\u0432\u0430\u0442\u044c \u043f\u043e\u0432\u0442\u043e\u0440\u0435\u043d\u0438\u0435 \u0432\u0440\u0430\u0449\u0430\u0442\u044c arrow backward mail previous redo repeat rotate',
    UnfoldLess:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0448\u0435\u0432\u0440\u043e\u043d \u043a\u0440\u0430\u0445 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0440\u0430\u0441\u0448\u0438\u0440\u044f\u0435\u043c\u044b\u0439 \u0432\u043d\u0443\u0442\u0440\u044c \u0441\u043f\u0438\u0441\u043e\u043a \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0432\u0432\u0435\u0440\u0445 arrows chevron collapse direction expandable inward list navigation up',
    UnfoldMore:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0448\u0435\u0432\u0440\u043e\u043d \u043a\u0440\u0430\u0445 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u0437 \u0440\u0430\u0441\u0448\u0438\u0440\u044f\u0435\u043c\u044b\u0439 \u0441\u043f\u0438\u0441\u043e\u043a \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f arrows chevron collapse direction down expandable list navigation',
    Unpublished:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043a\u0440\u0443\u0433 \u043f\u043e\u043b\u043d\u044b\u0439 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve check circle complete disabled done enabled mark off ok select slash tick validate verified yes',
    Unsubscribe:
        '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u042d\u043b. \u0430\u0434\u0440\u0435\u0441 \u043e\u043a\u0443\u0442\u044b\u0432\u0430\u0442\u044c \u043f\u043e\u0434\u043f\u0438\u0441\u0430\u0442\u044c\u0441\u044f \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u041d\u043e\u0432\u043e\u0441\u0442\u043d\u0430\u044f \u0440\u0430\u0441\u0441\u044b\u043b\u043a\u0430 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c cancel close email envelop esubscribe message newsletter off remove send',
    Upcoming:
        '\u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c \u043f\u043e\u0447\u0442\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 alarm calendar mail message notification',
    Update: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u0427\u0430\u0441\u044b \u0432\u043f\u0435\u0440\u0435\u0434 \u0431\u0443\u0434\u0443\u0449\u0435\u0435 \u0438\u0441\u0442\u043e\u0440\u0438\u044f \u043d\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f arrow backwards clock forward future history load refresh reverse rotate schedule time',
    UpdateDisabled:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u0427\u0430\u0441\u044b \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0432\u043f\u0435\u0440\u0435\u0434 \u0438\u0441\u0442\u043e\u0440\u0438\u044f \u043d\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043d\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0432\u0440\u0435\u043c\u044f arrow backwards clock enabled forward history load off on refresh reverse rotate schedule slash time',
    Upgrade:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u044d\u043a\u0441\u043f\u043e\u0440\u0442 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u043b\u0435\u043d\u044b \u043b\u0438\u043d\u0438\u044f \u0437\u0430\u043c\u0435\u043d\u044f\u0442\u044c \u041e\u0431\u043d\u043e\u0432\u0438\u0442\u044c arrow export instal line replace update',
    Upload: '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 arrows download drive',
    UploadFile:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u0432\u043e\u0434\u0438\u0442\u044c \u043c\u0430\u0448\u0438\u043d\u0443 \u043f\u0430\u043f\u043a\u0438 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0431\u0443\u043c\u0430\u0433\u0430 \u043b\u0438\u0441\u0442 \u0433\u043e\u0440\u043a\u0430 \u043f\u0438\u0448\u0443 arrow data document download drive folders page paper sheet slide writing',
    Usb: '\u043a\u0430\u0431\u0435\u043b\u044c \u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043f\u0440\u043e\u0432\u043e\u0434 cable connection device wire',
    UsbOff: '\u043a\u0430\u0431\u0435\u043b\u044c \u0441\u0432\u044f\u0437\u044c \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u043f\u0440\u043e\u0432\u043e\u0434 cable connection device wire',
    UTurnLeft:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u0440\u0430\u0437\u0432\u043e\u0440\u043e\u0442 arrows directions maps navigation path route sign traffic u-turn',
    UTurnRight:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u044f \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f \u0434\u043e\u0440\u043e\u0436\u043a\u0430 \u043c\u0430\u0440\u0448\u0440\u0443\u0442 \u0437\u043d\u0430\u043a \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u0440\u0430\u0437\u0432\u043e\u0440\u043e\u0442 arrows directions maps navigation path route sign traffic u-turn',
    Vaccines:
        '\u043f\u043e\u043c\u043e\u0433\u0430\u0442\u044c COVID-19 \u0434\u043e\u043a\u0442\u043e\u0440 \u043b\u0435\u043a\u0430\u0440\u0441\u0442\u0432\u043e \u0447\u0440\u0435\u0437\u0432\u044b\u0447\u0430\u0439\u043d\u0430\u044f \u0441\u0438\u0442\u0443\u0430\u0446\u0438\u044f \u0431\u043e\u043b\u044c\u043d\u0438\u0446\u0430 \u0438\u043c\u043c\u0443\u043d\u0438\u0442\u0435\u0442 \u0438\u043d\u044a\u0435\u043a\u0446\u0438\u044f \u043c\u0435\u0434\u0438\u0446\u0438\u043d\u0441\u043a\u0438\u0439 \u043c\u0435\u0434\u0438\u043a\u0430\u043c\u0435\u043d\u0442 \u043b\u0435\u043a\u0430\u0440\u0441\u0442\u0432\u0435\u043d\u043d\u043e\u0435 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u043e \u0438\u0433\u043e\u043b\u043a\u0430 \u0430\u043f\u0442\u0435\u043a\u0430 \u0431\u043e\u043b\u044c\u043d\u043e\u0439 \u0448\u043f\u0440\u0438\u0446 \u0432\u0430\u043a\u0446\u0438\u043d\u0430\u0446\u0438\u044f \u0444\u043b\u0430\u043a\u043e\u043d aid covid doctor drug emergency hospital immunity injection medical medication medicine needle pharmacy sick syringe vaccination vial',
    VapeFree:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u044b\u0435 \u0441\u0438\u0433\u0430\u0440\u0435\u0442\u044b \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0438\u043a\u043e\u0433\u0434\u0430 \u043d\u0435\u0442 \u0432\u044b\u043a\u043b\u044e\u0447\u0435\u043d\u043d\u044b\u0439 \u043c\u0435\u0441\u0442\u0430 \u0437\u0430\u043f\u0440\u0435\u0449\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0434\u044b\u043c \u043a\u0443\u0440\u0435\u043d\u0438\u0435 \u0442\u0430\u0431\u0430\u043a \u0432\u0435\u0439\u043f\u0438\u043d\u0433 \u043f\u0430\u0440 \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 \u0437\u043e\u043d\u0430 disabled e-cigarette enabled never no off places prohibited slash smoke smoking tobacco vaping vapor warning zone',
    VapingRooms:
        '\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u044b\u0439 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u044b\u0435 \u0441\u0438\u0433\u0430\u0440\u0435\u0442\u044b \u043d\u0438\u043a\u043e\u0433\u0434\u0430 \u043d\u0435\u0442 \u043c\u0435\u0441\u0442\u0430 \u0437\u0430\u043f\u0440\u0435\u0449\u0435\u043d\u043d\u044b\u0439 \u0434\u044b\u043c \u043a\u0443\u0440\u0435\u043d\u0438\u0435 \u0442\u0430\u0431\u0430\u043a \u0432\u0435\u0439\u043f \u043f\u0430\u0440 \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 \u0437\u043e\u043d\u0430 allowed e-cigarette never no places prohibited smoke smoking tobacco vape vapor warning zone',
    Verified:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0437\u043d\u0430\u0447\u043e\u043a \u043b\u043e\u043f\u0430\u0442\u044c\u0441\u044f \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0437\u0432\u0435\u0437\u0434\u0430 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u0434\u0430 approve badge burst check complete done mark ok select star tick validate yes',
    VerifiedUser:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0430\u0443\u0434\u0438\u0442 \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043d\u044b\u0439 \u0433\u0430\u043b\u043e\u0447\u043a\u0430 \u043f\u043e\u043b\u043d\u044b\u0439 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e Ok \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u0449\u0438\u0442 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u0434\u0430 approve audit certified checkmark complete done ok privacy private protection security select shield tick validate yes',
    VerticalAlignBottom:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043d\u0438\u0437 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u043f\u0438\u0448\u0443 alignment arrow doc down editing editor spreadsheet text type writing',
    VerticalAlignCenter:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0432\u043d\u0438\u0437 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0432\u0435\u0440\u0445 \u043f\u0438\u0448\u0443 alignment arrow doc down editing editor spreadsheet text type up writing',
    VerticalAlignTop:
        '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0432\u0432\u0435\u0440\u0445 \u043f\u0438\u0448\u0443 alignment arrow doc editing editor spreadsheet text type up writing',
    VerticalShades:
        '\u0436\u0430\u043b\u044e\u0437\u0438 \u043f\u043e\u043a\u0440\u044b\u0442\u0438\u0435 \u0448\u0442\u043e\u0440\u044b \u0433\u043d\u0435\u0437\u0434\u043e \u043e\u0442\u043a\u0440\u044b\u0442\u044b\u043c \u0437\u0430\u0442\u0432\u043e\u0440 \u0437\u043e\u043d\u0442\u0438\u043a blinds cover curtains nest open shutter sunshade',
    VerticalShadesClosed:
        '\u0436\u0430\u043b\u044e\u0437\u0438 \u043f\u043e\u043a\u0440\u044b\u0442\u0438\u0435 \u0448\u0442\u043e\u0440\u044b \u0433\u043d\u0435\u0437\u0434\u043e \u0440\u043e\u043b\u0438\u043a \u0437\u0430\u0442\u0432\u043e\u0440 \u0437\u043e\u043d\u0442\u0438\u043a blinds cover curtains nest roller shutter sunshade',
    VerticalSplit:
        '\u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u0442\u0435\u043a\u0441\u0442 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043f\u0438\u0448\u0443 design format grid layout paragraph text website writing',
    Vibration:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043a\u043b\u0435\u0442\u043a\u0430 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 iOS \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0420\u0435\u0436\u0438\u043c \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0442\u0438\u0448\u0438\u043d\u0430 \u0442\u0438\u0445\u0438\u0439 \u043f\u043b\u0430\u043d\u0448\u0435\u0442 \u0432\u0438\u0431\u0440\u0438\u0440\u043e\u0432\u0430\u0442\u044c Android alert cell device hardware iOS mobile mode motion notification phone silence silent tablet vibrate',
    VideoCall:
        '+ \u0434\u043e\u0431\u0430\u0432\u043b\u044f\u0442\u044c \u043a\u0430\u043c\u0435\u0440\u0430 \u0447\u0430\u0442 \u043a\u043e\u043d\u0444\u0435\u0440\u0435\u043d\u0446\u0438\u044f \u0441\u044a\u0435\u043c\u043a\u0438 \u0444\u0438\u043b\u044c\u043c\u0430 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u043d\u043e\u0432\u044b\u0439 \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u043b\u044e\u0441 \u044d\u043a\u0440\u0430\u043d \u0441\u0438\u043c\u0432\u043e\u043b \u0432\u0438\u0434\u0435\u043e\u0441\u044a\u0435\u043c\u043a\u0430 + add camera chat conference filming hardware image motion new picture plus screen symbol videography',
    Videocam:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0447\u0430\u0442 \u043a\u043e\u043d\u0444\u0435\u0440\u0435\u043d\u0446\u0438\u044f \u0441\u044a\u0435\u043c\u043a\u0438 \u0444\u0438\u043b\u044c\u043c\u0430 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u044d\u043a\u0440\u0430\u043d \u0432\u0438\u0434\u0435\u043e\u0441\u044a\u0435\u043c\u043a\u0430 camera chat conference filming hardware image motion picture screen videography',
    VideoCameraBack:
        '\u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0437\u0430\u0434\u043d\u0438\u0439 image landscape mountains photography picture rear',
    VideoCameraFront:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account face human image people person photography picture profile user',
    VideocamOff:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0447\u0430\u0442 \u043a\u043e\u043d\u0444\u0435\u0440\u0435\u043d\u0446\u0438\u044f \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0441\u044a\u0435\u043c\u043a\u0438 \u0444\u0438\u043b\u044c\u043c\u0430 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u044d\u043a\u0440\u0430\u043d \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0432\u0438\u0434\u0435\u043e\u0441\u044a\u0435\u043c\u043a\u0430 camera chat conference disabled enabled filming hardware image motion offline picture screen slash videography',
    VideoFile:
        '\u043a\u0430\u043c\u0435\u0440\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0441\u044a\u0435\u043c\u043a\u0438 \u0444\u0438\u043b\u044c\u043c\u0430 \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0434\u0432\u0438\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u0432\u0438\u0434\u0435\u043e\u0441\u044a\u0435\u043c\u043a\u0430 camera document filming hardware image motion picture videography',
    VideogameAsset:
        '\u043f\u0440\u0438\u0441\u0442\u0430\u0432\u043a\u0430 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u043b\u0435\u0440 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0433\u0435\u0439\u043c\u043f\u0430\u0434 \u0438\u0433\u0440\u044b \u043d\u0438\u043d\u0442\u0435\u043d\u0434\u043e \u0438\u0433\u0440\u043e\u0432\u0430\u044f \u043f\u0440\u0438\u0441\u0442\u0430\u0432\u043a\u0430 Xbox console controller device gamepad gaming nintendo playstation xbox',
    VideogameAssetOff:
        '\u043f\u0440\u0438\u0441\u0442\u0430\u0432\u043a\u0430 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u043b\u0435\u0440 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0433\u0435\u0439\u043c\u043f\u0430\u0434 \u0438\u0433\u0440\u044b \u0438\u0433\u0440\u043e\u0432\u0430\u044f \u043f\u0440\u0438\u0441\u0442\u0430\u0432\u043a\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 console controller device disabled enabled gamepad gaming playstation slash',
    VideoLabel:
        '\u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0432\u0435\u0449\u044c \u044d\u043a\u0440\u0430\u043d \u043e\u043a\u043d\u043e device item screen window',
    VideoLibrary:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u043e\u043b\u043b\u0435\u043a\u0446\u0438\u044f \u0438\u0433\u0440\u0430\u0442\u044c \u0432 arrow collection play',
    VideoSettings:
        '\u0441\u0434\u0430\u0447\u0430 \u0414\u0435\u0442\u0430\u043b\u0438 \u043c\u0435\u0445\u0430\u043d\u0438\u0437\u043c \u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u043f\u0446\u0438\u0438 \u0438\u0433\u0440\u0430\u0442\u044c \u0432 \u044d\u043a\u0440\u0430\u043d \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u043e\u043a\u043d\u043e change details gear information options play screen service window',
    VideoStable:
        '\u0441\u044a\u0435\u043c\u043a\u0438 \u0444\u0438\u043b\u044c\u043c\u0430 \u0437\u0430\u043f\u0438\u0441\u044c \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440 \u0441\u0442\u0430\u0431\u0438\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0437\u0430\u043f\u0438\u0441\u044c filming recording setting stability taping',
    ViewAgenda:
        '\u0431\u043b\u043e\u043a\u0438 \u043a\u0430\u0440\u0442\u044b \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0432\u0435\u0431-\u0441\u0430\u0439\u0442, \u0441\u043b\u043e\u0436\u0435\u043d\u043d\u044b\u0439 blocks cards design format grid layout website,stacked',
    ViewArray:
        '\u0431\u043b\u043e\u043a\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 blocks design format grid layout website',
    ViewCarousel:
        '\u0431\u0430\u043d\u043d\u0435\u0440 \u0431\u043b\u043e\u043a\u0438 \u043a\u0430\u0440\u0442\u044b \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438 \u043c\u0430\u043a\u0435\u0442 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 banner blocks cards design format grid images layout website',
    ViewColumn:
        '\u0431\u043b\u043e\u043a\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0432\u0435\u0440\u0442\u0438\u043a\u0430\u043b\u044c\u043d\u044b\u0439 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 blocks design format grid layout vertical website',
    ViewComfy:
        '\u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0448\u0430\u0431\u043b\u043e\u043d \u043a\u0432\u0430\u0434\u0440\u0430\u0442\u044b grid layout pattern squares',
    ViewComfyAlt:
        '\u0443\u044e\u0442\u043d\u044b\u0439 \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u0441\u0435\u0442\u044c cozy design format layout web',
    ViewCompact:
        '\u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0448\u0430\u0431\u043b\u043e\u043d \u043a\u0432\u0430\u0434\u0440\u0430\u0442\u044b grid layout pattern squares',
    ViewCompactAlt:
        '\u043f\u043b\u043e\u0442\u043d\u044b\u0439 \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u0441\u0435\u0442\u044c dense design format layout web',
    ViewCozy:
        '\u0443\u0434\u043e\u0431\u043d\u044b\u0439 \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u043c\u0430\u043a\u0435\u0442 \u0441\u0435\u0442\u044c comfy design format layout web',
    ViewDay:
        '\u0431\u043b\u043e\u043a\u0438 \u043a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c \u043a\u0430\u0440\u0442\u044b \u043a\u0430\u0440\u0443\u0441\u0435\u043b\u044c \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043d\u0435\u0434\u0435\u043b\u044f blocks calendar cards carousel design format grid layout website week',
    ViewHeadline:
        '\u0431\u043b\u043e\u043a\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u043f\u0430\u0440\u0430\u0433\u0440\u0430\u0444 \u0442\u0435\u043a\u0441\u0442 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 blocks design format grid layout paragraph text website',
    ViewInAr:
        '3d \u0434\u043e\u043f\u043e\u043b\u043d\u0435\u043d\u043d\u044b\u0439 \u043a\u0443\u0431 \u043c\u0435\u0447\u0442\u0430 \u0433\u0430\u0440\u043d\u0438\u0442\u0443\u0440\u0430 \u0440\u0435\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u043f\u043b\u043e\u0449\u0430\u0434\u044c \u0432\u0440 3d augmented cube daydream headset reality square vr',
    ViewKanban:
        '\u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0448\u0430\u0431\u043b\u043e\u043d \u043a\u0432\u0430\u0434\u0440\u0430\u0442\u044b grid layout pattern squares',
    ViewList:
        '\u0431\u043b\u043e\u043a\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u0418\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u0435 \u043f\u043e\u0440\u044f\u0434\u043a\u0430 \u0441\u043b\u043e\u0436\u0435\u043d\u043d\u044b\u0439 \u0437\u0430\u0433\u043b\u0430\u0432\u0438\u0435 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 blocks design format grid layout lines reorder stacked title website',
    ViewModule:
        '\u0431\u043b\u043e\u043a\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0418\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u0435 \u043f\u043e\u0440\u044f\u0434\u043a\u0430 \u043a\u0432\u0430\u0434\u0440\u0430\u0442\u044b \u0441\u043b\u043e\u0436\u0435\u043d\u043d\u044b\u0439 \u0437\u0430\u0433\u043b\u0430\u0432\u0438\u0435 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 blocks design format grid layout reorder squares stacked title website',
    ViewQuilt:
        '\u0431\u043b\u043e\u043a\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0418\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u0435 \u043f\u043e\u0440\u044f\u0434\u043a\u0430 \u043a\u0432\u0430\u0434\u0440\u0430\u0442\u044b \u0441\u043b\u043e\u0436\u0435\u043d\u043d\u044b\u0439 \u0437\u0430\u0433\u043b\u0430\u0432\u0438\u0435 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 blocks design format grid layout reorder squares stacked title website',
    ViewSidebar:
        '\u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0441\u0435\u0442\u044c design format grid layout web',
    ViewStream:
        '\u0431\u043b\u043e\u043a\u0438 \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u043b\u0438\u043d\u0438\u0438 \u0441\u043f\u0438\u0441\u043e\u043a \u0418\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u0435 \u043f\u043e\u0440\u044f\u0434\u043a\u0430 \u0441\u043b\u043e\u0436\u0435\u043d\u043d\u044b\u0439 \u0437\u0430\u0433\u043b\u0430\u0432\u0438\u0435 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 blocks design format grid layout lines list reorder stacked title website',
    ViewTimeline:
        '\u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0448\u0430\u0431\u043b\u043e\u043d \u043a\u0432\u0430\u0434\u0440\u0430\u0442\u044b grid layout pattern squares',
    ViewWeek:
        '\u0431\u0430\u0440\u044b \u0431\u043b\u043e\u043a\u0438 \u0441\u0442\u043e\u043b\u0431\u0446\u044b \u0434\u0435\u043d\u044c \u0434\u0438\u0437\u0430\u0439\u043d \u0444\u043e\u0440\u043c\u0430\u0442 \u0441\u0435\u0442\u043a\u0430 \u043c\u0430\u043a\u0435\u0442 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 bars blocks columns day design format grid layout website',
    Vignette:
        '\u0433\u0440\u0430\u043d\u0438\u0446\u0430 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u044d\u0444\u0444\u0435\u043a\u0442 \u0444\u0438\u043b\u044c\u0442\u0440 \u0433\u0440\u0430\u0434\u0438\u0435\u043d\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440 border editing effect filter gradient image photography setting',
    Villa: '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u043f\u043b\u044f\u0436 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u0434\u043e\u043c \u043a\u0430\u0440\u0442\u044b \u043c\u0435\u0441\u0442\u043e \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043e\u0441\u0442\u0430\u0442\u044c\u0441\u044f \u043f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0435 \u043e\u0442\u043f\u0443\u0441\u043a architecture beach estate home house maps place real residence residential stay traveling vacation',
    Visibility:
        '\u0433\u043b\u0430\u0437 \u043d\u0430 \u043f\u0430\u0440\u043e\u043b\u044c \u043f\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u043f\u0440\u043e\u0441\u043c\u043e\u0442\u0440 \u043f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u0432\u0438\u0434\u0435\u0442\u044c \u043f\u043e\u043a\u0430\u0437\u0430\u043d\u043e \u0432\u0438\u0434\u0438\u043c\u043e\u0441\u0442\u044c eye on password preview reveal see shown visability',
    VisibilityOff:
        '\u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0433\u043b\u0430\u0437 \u0441\u043a\u0440\u044b\u0442\u044b\u0439 \u043d\u0435\u0432\u0438\u0434\u0438\u043c\u044b\u0439 \u043d\u0430 \u043f\u0430\u0440\u043e\u043b\u044c \u043f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u0432\u0438\u0434\u0435\u0442\u044c \u043f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u041f\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0432\u0438\u0434\u0438\u043c\u043e\u0441\u0442\u044c disabled enabled eye hidden invisible on password reveal see show slash view visability',
    VoiceChat:
        '\u043f\u0443\u0437\u044b\u0440\u044c \u043a\u0430\u043c\u0435\u0440\u0430 \u043a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439 \u043e\u0431\u0449\u0430\u0442\u044c\u0441\u044f \u0444\u0435\u0439\u0441\u0442\u0430\u0439\u043c \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u0440\u0435\u0447\u044c \u0432\u0438\u0434\u0435\u043e bubble camera comment communicate facetime feedback message speech video',
    Voicemail:
        '\u0432\u044b\u0437\u043e\u0432 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0435 \u043f\u0440\u043e\u043f\u0443\u0449\u0435\u043d\u043d\u044b\u0439 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0437\u0430\u043f\u0438\u0441\u044c call device message missed mobile phone recording',
    VoiceOverOff:
        '\u0443\u0447\u0435\u0442\u043d\u0430\u044f \u0437\u0430\u043f\u0438\u0441\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043b\u0438\u0446\u043e \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043b\u044e\u0434\u0438 \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0437\u0430\u043f\u0438\u0441\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0413\u043e\u0432\u043e\u0440\u044f\u0449\u0438\u0439 \u0440\u0435\u0447\u044c \u0441\u0442\u0435\u043d\u043e\u0433\u0440\u0430\u043c\u043c\u0430 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c account disabled enabled face human people person profile recording slash speaking speech transcript user',
    Volcano:
        '\u043a\u0440\u0438\u0437\u0438\u0441 \u0441\u0442\u0438\u0445\u0438\u0439\u043d\u043e\u0435 \u0431\u0435\u0434\u0441\u0442\u0432\u0438\u0435 \u0438\u0437\u0432\u0435\u0440\u0436\u0435\u043d\u0438\u0435 \u0434\u043e\u0441\u0442\u0430\u0442\u043e\u0447\u043d\u043e \u043c\u0430\u0433\u043c\u0430 \u0435\u0441\u0442\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0439 crisis disaster eruption lava magma natural',
    VolumeDown:
        '\u0430\u0443\u0434\u0438\u043e \u0438\u0437 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u041c\u0443\u0437\u044b\u043a\u0430 \u0442\u0438\u0448\u0435 \u0442\u0441\u0441 \u043c\u044f\u0433\u043a\u0438\u0439 \u0437\u0432\u0443\u043a \u043e\u0440\u0430\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 audio av control music quieter shh soft sound speaker tv',
    VolumeMute:
        '\u0430\u0443\u0434\u0438\u043e \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0432\u0443\u043a \u043e\u0440\u0430\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 audio control music sound speaker tv',
    VolumeOff:
        '\u0430\u0443\u0434\u0438\u043e \u0438\u0437 \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u043d\u0438\u0437\u043a\u0438\u0439 \u041c\u0443\u0437\u044b\u043a\u0430 \u043d\u0435\u043c\u043e\u0439 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0437\u0432\u0443\u043a \u043e\u0440\u0430\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 audio av control disabled enabled low music mute slash sound speaker tv',
    VolumeUp:
        '\u0430\u0443\u0434\u0438\u043e \u043a\u043e\u043d\u0442\u0440\u043e\u043b\u044c \u041c\u0443\u0437\u044b\u043a\u0430 \u0437\u0432\u0443\u043a \u043e\u0440\u0430\u0442\u043e\u0440 \u0422\u0435\u043b\u0435\u0432\u0438\u0437\u043e\u0440 audio control music sound speaker tv',
    VolunteerActivism:
        '\u043f\u043e\u0436\u0435\u0440\u0442\u0432\u043e\u0432\u0430\u043d\u0438\u0435 \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0434\u0430\u0432\u0430\u0442\u044c \u0420\u0443\u043a\u0438 \u0441\u0435\u0440\u0434\u0446\u0435 \u043b\u044e\u0431\u043e\u0432\u044c \u043e\u0431\u043c\u0435\u043d donation fingers gesture giving hands heart love sharing',
    VpnKey: '\u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0441\u0435\u0442\u044c \u043f\u0430\u0440\u043e\u043b\u044c \u043f\u0430\u0440\u043e\u043b\u044c \u0440\u0435\u0433\u0438\u0441\u0442\u0440 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0437\u043d\u0430\u043a \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0440\u0430\u0437\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c login network passcode password register security signin signup unlock',
    VpnKeyOff:
        '[\u043d\u0435 \u0432 \u0441\u0435\u0442\u0438] \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0441\u0435\u0442\u044c \u043d\u0430 \u043f\u0430\u0440\u043e\u043b\u044c \u043f\u0430\u0440\u043e\u043b\u044c \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0440\u0430\u0437\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c [offline] disabled enabled network on passcode password slash unlock',
    VpnLock:
        '\u0437\u0435\u043c\u043d\u043e\u0439 \u0448\u0430\u0440 \u0433\u043b\u043e\u0431\u0443\u0441 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u0441\u0435\u0442\u044c \u043f\u0430\u0440\u043e\u043b\u044c \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0432\u0438\u0440\u0442\u0443\u0430\u043b\u044c\u043d\u044b\u0439 \u041c\u0438\u0440 earth globe locked network password privacy private protection safety secure security virtual world',
    Vrpano: '\u0443\u0433\u043e\u043b \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0435\u0439\u0437\u0430\u0436 \u0433\u043e\u0440\u044b \u043f\u0430\u043d\u043e\u0440\u0430\u043c\u0430 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 \u041f\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0448\u0438\u0440\u043e\u043a\u0438\u0439 angle image landscape mountains panorama photography picture view wide',
    Wallpaper:
        '\u0444\u043e\u043d \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0435\u0439\u0437\u0430\u0436 \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u0430 background image landscape photography picture',
    Warehouse:
        '\u0433\u0430\u0440\u0430\u0436 \u043f\u0440\u043e\u043c\u044b\u0448\u043b\u0435\u043d\u043d\u043e\u0441\u0442\u044c \u043f\u0440\u043e\u0438\u0437\u0432\u043e\u0434\u0441\u0442\u0432\u043e \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0435 garage industry manufacturing storage',
    Warning:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u043e\u0431\u044a\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u041e\u0431\u0440\u0430\u0442\u043d\u0430\u044f \u0441\u0432\u044f\u0437\u044c \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043f\u0440\u043e\u0431\u043b\u0435\u043c\u0430 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0440\u0435\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a ! alert announcement attention caution danger error exclamation feedback important mark notification problem symbol triangle',
    WarningAmber:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u0448\u0438\u0431\u043a\u0430 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0440\u0435\u0443\u0433\u043e\u043b\u044c\u043d\u0438\u043a ! alert attention caution danger error exclamation important mark notification symbol triangle',
    Wash: '\u0432\u0430\u043d\u043d\u0430\u044f \u043a\u043e\u043c\u043d\u0430\u0442\u0430 \u0447\u0438\u0441\u0442\u044b\u0439 \u043f\u0430\u043b\u044c\u0446\u044b \u0436\u0435\u0441\u0442 \u0440\u0443\u043a\u0430 \u0422\u0443\u0430\u043b\u0435\u0442 bathroom clean fingers gesture hand wc',
    Watch: '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0427\u0430\u0441\u044b \u0433\u0430\u0434\u0436\u0435\u0442 iOS \u0443\u043c\u043d\u044b\u0435 \u0447\u0430\u0441\u044b \u0432\u0440\u0435\u043c\u044f \u0432\u0440 \u043d\u043e\u0441\u0438\u043c\u044b\u0435 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u0430 \u0441\u0435\u0442\u044c \u043d\u0430\u0440\u0443\u0447\u043d\u044b\u0435 \u0447\u0430\u0441\u044b Android clock gadget iOS smartwatch time vr wearables web wristwatch',
    WatchLater:
        '\u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0447\u0430\u0441 \u043c\u0438\u043d\u0443\u0442\u0430 \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0432\u0440\u0435\u043c\u044f clock date hour minute schedule time',
    WatchOff:
        '\u0410\u043d\u0434\u0440\u043e\u0438\u0434 \u0427\u0430\u0441\u044b \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u0433\u0430\u0434\u0436\u0435\u0442 iOS \u0437\u0430\u043a\u0440\u044b\u0442\u044c \u0432\u0440\u0435\u043c\u044f \u0432\u0440 \u043d\u043e\u0441\u0438\u043c\u044b\u0435 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u0430 \u0441\u0435\u0442\u044c \u043d\u0430\u0440\u0443\u0447\u043d\u044b\u0435 \u0447\u0430\u0441\u044b Android clock close gadget iOS shut time vr wearables web wristwatch',
    Water: '\u0446\u0432\u0435\u0442 \u043c\u043e\u0440\u0441\u043a\u043e\u0439 \u0432\u043e\u043b\u043d\u044b \u043f\u043b\u044f\u0436 \u043e\u0437\u0435\u0440\u043e \u043e\u043a\u0435\u0430\u043d \u0440\u0435\u043a\u0430 \u0432\u043e\u043b\u043d\u044b \u043f\u043e\u0433\u043e\u0434\u0430 aqua beach lake ocean river waves weather',
    WaterDamage:
        '\u0430\u0440\u0445\u0438\u0442\u0435\u043a\u0442\u0443\u0440\u0430 \u0441\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e \u043a\u0430\u043f\u0435\u043b\u044c\u043a\u0430 \u0438\u043c\u0443\u0449\u0435\u0441\u0442\u0432\u043e \u0434\u043e\u043c \u0443\u0442\u0435\u0447\u043a\u0430 \u0441\u0430\u043d\u0442\u0435\u0445\u043d\u0438\u043a\u0430 \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0438\u0439 \u0440\u0435\u0437\u0438\u0434\u0435\u043d\u0446\u0438\u044f \u0416\u0438\u043b\u043e\u0439 \u043f\u0440\u0438\u044e\u0442 architecture building droplet estate house leak plumbing real residence residential shelter',
    WaterfallChart:
        '\u0430\u043d\u0430\u043b\u0438\u0442\u0438\u043a\u0430 \u0431\u0430\u0440 \u0434\u0430\u043d\u043d\u044b\u0435 \u0434\u0438\u0430\u0433\u0440\u0430\u043c\u043c\u0430 \u0438\u043d\u0444\u043e\u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043c\u0435\u0440\u0430 \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u0435\u043b\u0438 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043a\u0430 \u043e\u0442\u0441\u043b\u0435\u0436\u0438\u0432\u0430\u043d\u0438\u0435 analytics bar data diagram infographic measure metrics statistics tracking',
    Waves: '\u043f\u043b\u044f\u0436 \u043e\u0437\u0435\u0440\u043e \u043e\u043a\u0435\u0430\u043d \u0431\u0430\u0441\u0441\u0435\u0439\u043d \u0440\u0435\u043a\u0430 \u043c\u043e\u0440\u0435 \u043f\u043b\u0430\u0432\u0430\u0442\u044c \u0432\u043e\u0434\u0430 beach lake ocean pool river sea swim water',
    WbAuto: '\u0410 \u0412 \u0430\u043b\u0444\u0430\u0432\u0438\u0442 \u0430\u0432\u0442\u043e\u043c\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0438\u0439 \u043e\u0441\u0442\u0430\u0442\u043e\u043a \u0441\u0440\u0435\u0434\u0441\u0442\u0432 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u0436 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0448\u0440\u0438\u0444\u0442 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u0438\u0441\u044c\u043c\u043e \u0444\u043e\u0442\u043e\u0433\u0440\u0430\u0444\u0438\u044f \u0441\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043a\u0441\u0442 \u0442\u0438\u043f \u0431\u0435\u043b\u044b\u0439 \u0432\u043f A W alphabet automatic balance character editing font image letter photography symbol text type white wp',
    WbCloudy:
        '\u043e\u0441\u0442\u0430\u0442\u043e\u043a \u0441\u0440\u0435\u0434\u0441\u0442\u0432 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0431\u0435\u043b\u044b\u0439 \u0432\u043f balance editing white wp',
    WbIncandescent:
        '\u043e\u0441\u0442\u0430\u0442\u043e\u043a \u0441\u0440\u0435\u0434\u0441\u0442\u0432 \u044f\u0440\u043a\u0438\u0439 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0444\u043e\u043d\u0430\u0440\u044c \u043b\u0430\u043c\u043f\u043e\u0447\u043a\u0430 \u043e\u0441\u0432\u0435\u0449\u0435\u043d\u0438\u0435 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0431\u0435\u043b\u044b\u0439 \u0432\u043f balance bright editing lamp lightbulb lighting settings white wp',
    WbIridescent:
        '\u043e\u0441\u0442\u0430\u0442\u043e\u043a \u0441\u0440\u0435\u0434\u0441\u0442\u0432 \u044f\u0440\u043a\u0438\u0439 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u0441\u0432\u0435\u0449\u0435\u043d\u0438\u0435 \u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0431\u0435\u043b\u044b\u0439 \u0432\u043f balance bright editing lighting settings white wp',
    WbShade:
        '\u043e\u0441\u0442\u0430\u0442\u043e\u043a \u0441\u0440\u0435\u0434\u0441\u0442\u0432 \u0434\u043e\u043c \u043e\u0441\u0432\u0435\u0449\u0435\u043d\u0438\u0435 \u0431\u0435\u043b\u044b\u0439 balance house lighting white',
    WbSunny:
        '\u043e\u0441\u0442\u0430\u0442\u043e\u043a \u0441\u0440\u0435\u0434\u0441\u0442\u0432 \u044f\u0440\u043a\u0438\u0439 \u043e\u0441\u0432\u0435\u0449\u0435\u043d\u0438\u0435 \u043f\u043e\u0433\u043e\u0434\u0430 \u0431\u0435\u043b\u044b\u0439 balance bright lighting weather white',
    WbTwilight:
        '\u043e\u0441\u0442\u0430\u0442\u043e\u043a \u0441\u0440\u0435\u0434\u0441\u0442\u0432 \u043e\u0441\u0432\u0435\u0449\u0435\u043d\u0438\u0435 \u043f\u043e\u043b\u0434\u0435\u043d\u044c \u0437\u0430\u043a\u0430\u0442 \u0441\u043e\u043b\u043d\u0446\u0430 \u0431\u0435\u043b\u044b\u0439 balance lighting noon sunset white',
    Wc: '\u0432\u0430\u043d\u043d\u0430\u044f \u043a\u043e\u043c\u043d\u0430\u0442\u0430 \u0448\u043a\u0430\u0444 \u0436\u0435\u043d\u0441\u043a\u0438\u0439 \u041f\u043e\u043b \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u043a\u043e\u043c\u043d\u0430\u0442\u0430 \u043e\u0442\u0434\u044b\u0445\u0430 \u0442\u0443\u0430\u043b\u0435\u0442 \u0443\u043d\u0438\u0441\u0435\u043a\u0441 \u043c\u044b\u0442\u044c \u0432\u043e\u0434\u0430 \u0436\u0435\u043d\u0449\u0438\u043d\u044b bathroom closet female gender man person restroom toilet unisex wash water women',
    Web: '\u0431\u043b\u043e\u043a\u0438 \u0431\u0440\u0430\u0443\u0437\u0435\u0440 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u044d\u043a\u0440\u0430\u043d \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 www blocks browser internet page screen website www',
    WebAsset:
        '-\u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u0437\u0430\u044f\u0432\u043b\u0435\u043d\u0438\u0435 \u0431\u0440\u0430\u0443\u0437\u0435\u0440 \u0434\u0438\u0437\u0430\u0439\u043d \u0440\u0430\u0431\u043e\u0447\u0438\u0439 \u0441\u0442\u043e\u043b \u0441\u043a\u0430\u0447\u0430\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u0430\u043a\u0435\u0442 \u044d\u043a\u0440\u0430\u043d \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 \u0443\u0445 \u0432\u0438\u0434\u0435\u043e \u043e\u043a\u043d\u043e www -website application browser design desktop download image interface internet layout screen ui ux video window www',
    WebAssetOff:
        '\u0431\u0440\u0430\u0443\u0437\u0435\u0440 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043d\u0430 \u044d\u043a\u0440\u0430\u043d \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0432\u0435\u0431-\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430 \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u0430 www browser disabled enabled internet on screen slash webpage website windows www',
    Webhook:
        'API \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a \u0440\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u043a\u0430 \u043f\u0440\u0435\u0434\u043f\u0440\u0438\u044f\u0442\u0438\u0435 \u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u043d\u043e\u0433\u043e \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u044f api developer development enterprise software',
    Weekend:
        '\u0441\u0442\u0443\u043b \u0434\u0438\u0432\u0430\u043d \u043c\u0435\u0431\u0435\u043b\u044c \u0434\u043e\u043c \u0436\u0438\u0437\u043d\u044c \u0431\u0435\u0437\u0434\u0435\u043b\u044c\u043d\u0438\u0447\u0430\u0442\u044c \u0440\u0430\u0441\u0441\u043b\u0430\u0431\u043b\u044f\u0442\u044c\u0441\u044f \u043d\u043e\u043c\u0435\u0440 \u0441\u0438\u0434\u0435\u043d\u044c\u0435 chair couch furniture home living lounge relax room seat',
    West: '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u043d\u044b\u0439 \u043e\u0441\u0442\u0430\u0432\u0438\u043b \u043a\u0430\u0440\u0442\u044b \u043d\u0430\u0432\u0438\u0433\u0430\u0446\u0438\u044f arrow directional left maps navigation',
    WhatsApp:
        '\u043c\u0430\u0440\u043a\u0430 \u0432\u044b\u0437\u043e\u0432 \u0447\u0430\u0442 \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u043e\u0431\u043c\u0435\u043d \u0441\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u044f\u043c\u0438 \u0433\u043e\u043b\u043e\u0441 brand call chat logo messaging voice',
    Whatshot:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043a\u0440\u0443\u0433 \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u041e\u0433\u043e\u043d\u044c \u043a\u0430\u0434\u0440\u044b \u043a\u0440\u0443\u0433\u043b\u044b\u0439 \u0432 \u0442\u0440\u0435\u043d\u0434\u0435 arrow circle direction fire frames round trending',
    WheelchairPickup:
        '\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u043e\u0441\u0442\u044c \u0434\u043e\u0441\u0442\u0443\u043f\u043d\u044b\u0439 \u0442\u0435\u043b\u043e \u0438\u043d\u0432\u0430\u043b\u0438\u0434\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u043c\u043e\u0449\u044c \u0447\u0435\u043b\u043e\u0432\u0435\u043a \u0447\u0435\u043b\u043e\u0432\u0435\u043a accessibility accessible body handicap help human person',
    WhereToVote:
        '\u043e\u0434\u043e\u0431\u0440\u0438\u0442\u044c \u0433\u043e\u043b\u043e\u0441\u043e\u0432\u0430\u043d\u0438\u0435 \u041f\u0440\u043e\u0432\u0435\u0440\u044c\u0442\u0435 \u043f\u043e\u043b\u043d\u044b\u0439 \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0412\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u043e \u0432\u044b\u0431\u043e\u0440\u044b \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u043e\u0442\u043c\u0435\u0442\u043a\u0430 Ok \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043e\u043f\u0440\u043e\u0441 \u0412\u044b\u0431\u0440\u0430\u0442\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043f\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0430\u043b\u043e\u0447\u043a\u0443 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0442\u044c \u043f\u0440\u043e\u0432\u0435\u0440\u0435\u043d\u043e \u0434\u0430 approve ballot check complete destination direction done election location maps mark ok pin place poll select stop tick validate verified yes',
    Widgets:
        '\u043f\u0440\u0438\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u0431\u043b\u043e\u043a\u0438 \u043a\u043e\u0440\u043e\u0431\u043a\u0430 \u043c\u0435\u043d\u044e \u043f\u0430\u0440\u0430\u043c\u0435\u0442\u0440 \u043a\u0432\u0430\u0434\u0440\u0430\u0442\u044b \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0438\u043d\u0442\u0435\u0440\u0444\u0435\u0439\u0441 app blocks box menu setting squares ui',
    Wifi: '\u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection data internet network scan service signal wireless',
    Wifi1Bar:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular connection data internet mobile network phone scan service signal wireless',
    Wifi2Bar:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular connection data internet mobile network phone scan service signal wireless',
    WifiCalling:
        '\u043a\u043b\u0435\u0442\u043a\u0430 \u0441\u0432\u044f\u0437\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u044f \u043a\u043e\u043d\u0442\u0430\u043a\u0442 \u0443\u0441\u0442\u0440\u043e\u0439\u0441\u0442\u0432\u043e \u0430\u043f\u043f\u0430\u0440\u0430\u0442\u043d\u043e\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u0433\u043d\u0430\u043b \u0442\u0435\u043b\u0435\u0444\u043e\u043d \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cell connection connectivity contact device hardware mobile signal telephone wireless',
    WifiCalling3:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular data internet mobile network phone speed wireless',
    WifiChannel:
        '(\u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c) [\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439] \u0441\u0435\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 (scan) [cellular connection data internet mobile] network service signal wireless',
    WifiFind:
        '(\u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c) [\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u043e\u0431\u043d\u0430\u0440\u0443\u0436\u0438\u0442\u044c \u043e\u0431\u043d\u0430\u0440\u0443\u0436\u0438\u0442\u044c \u0441\u0442\u0430\u043a\u0430\u043d \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439] \u0441\u0435\u0442\u044c \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043f\u043e\u0438\u0441\u043a \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 (scan) [cellular connection data detect discover glass internet look magnifying mobile] network notice search service signal wireless',
    WifiLock:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u043f\u0430\u0440\u043e\u043b\u044c \u041a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u044c \u0447\u0430\u0441\u0442\u043d\u044b\u0439 \u0437\u0430\u0449\u0438\u0442\u0430 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular connection data internet locked mobile network password privacy private protection safety secure security service signal wireless',
    WifiOff:
        '\u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0441\u0435\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 connection data disabled enabled internet network offline scan service signal slash wireless',
    WifiPassword:
        '(\u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u0442\u044c) [\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u0437\u0430\u043c\u043e\u043a \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439] \u0441\u0435\u0442\u044c \u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u044b\u0439 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 (scan) [cellular connection data internet lock mobile] network secure service signal wireless',
    WifiProtectedSetup:
        '\u043e\u043a\u043e\u043b\u043e \u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u0432\u0440\u0430\u0449\u0430\u0442\u044c around arrows rotate',
    WifiTethering:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular connection data internet mobile network phone scan service signal speed wireless',
    WifiTetheringError:
        '! \u0442\u0440\u0435\u0432\u043e\u0433\u0430 \u0432\u043d\u0438\u043c\u0430\u043d\u0438\u0435 \u043e\u0441\u0442\u043e\u0440\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0441\u0432\u044f\u0437\u044c \u041e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0432\u043e\u0441\u043a\u043b\u0438\u0446\u0430\u043d\u0438\u0435 \u0432\u0430\u0436\u043d\u044b\u0439 \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043e\u0442\u043c\u0435\u0442\u043a\u0430 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u043e\u043a\u0440\u0443\u0433\u043b\u044b\u0439 \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435 \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 ! alert attention caution cellular connection danger data exclamation important internet mark mobile network notification phone rounded scan service signal speed symbol warning wireless',
    WifiTetheringOff:
        '\u0441\u043e\u0442\u043e\u0432\u044b\u0439 \u0441\u0432\u044f\u0437\u044c \u0434\u0430\u043d\u043d\u044b\u0435 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0418\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0441\u0435\u0442\u044c \u043d\u0435 \u0432 \u0441\u0435\u0442\u0438 \u0422\u0435\u043b\u0435\u0444\u043e\u043d \u0441\u043a\u0430\u043d\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u043e\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u0443\u0441\u043b\u0443\u0433 \u0441\u0438\u0433\u043d\u0430\u043b \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0441\u043a\u043e\u0440\u043e\u0441\u0442\u044c \u0431\u0435\u0441\u043f\u0440\u043e\u0432\u043e\u0434\u043d\u043e\u0439 cellular connection data disabled enabled internet mobile network offline phone scan service signal slash speed wireless',
    Window: '\u0417\u0430\u043a\u0440\u044b\u0442\u044c \u0441\u0442\u0430\u043a\u0430\u043d \u0441\u0435\u0442\u043a\u0430 \u0434\u043e\u043c \u0434\u043e\u043c \u0438\u043d\u0442\u0435\u0440\u044c\u0435\u0440 \u043c\u0430\u043a\u0435\u0442 \u0432\u043d\u0435 close glass grid home house interior layout outside',
    WindPower:
        '\u044d\u043a\u043e \u044d\u043d\u0435\u0440\u0433\u0438\u044f \u0433\u043d\u0435\u0437\u0434\u043e \u0432\u0435\u0442\u0440\u0435\u043d\u044b\u0439 eco energy nest windy',
    WineBar:
        '\u0430\u043b\u043a\u043e\u0433\u043e\u043b\u044c \u043a\u043e\u043a\u0442\u0435\u0439\u043b\u044c \u0447\u0430\u0448\u043a\u0430 \u043d\u0430\u043f\u0438\u0442\u043e\u043a \u0441\u0442\u0430\u043a\u0430\u043d \u043b\u0438\u043a\u0435\u0440 alcohol cocktail cup drink glass liquor',
    Woman: '\u0436\u0435\u043d\u0441\u043a\u0438\u0439 \u041f\u043e\u043b \u0434\u0435\u0432\u043e\u0447\u043a\u0430 \u043b\u0435\u0434\u0438 \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0441\u0438\u043c\u0432\u043e\u043b \u0436\u0435\u043d\u0449\u0438\u043d\u044b female gender girl lady social symbol women',
    Work: '-\u043f\u043e\u0440\u0442\u0444\u0435\u043b\u044c \u0411\u0430\u0433\u0430\u0436 \u0431\u0438\u0437\u043d\u0435\u0441 \u0440\u0430\u0431\u043e\u0442\u0430 \u0447\u0435\u043c\u043e\u0434\u0430\u043d -briefcase baggage business job suitcase',
    WorkHistory:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u0411\u0430\u0433\u0430\u0436 \u043f\u043e\u0440\u0442\u0444\u0435\u043b\u044c \u0431\u0438\u0437\u043d\u0435\u0441 \u0427\u0430\u0441\u044b \u0441\u0432\u0438\u0434\u0430\u043d\u0438\u0435 \u0440\u0430\u0431\u043e\u0442\u0430 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0440\u0430\u0441\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0447\u0435\u043c\u043e\u0434\u0430\u043d \u0432\u0440\u0435\u043c\u044f \u043f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c arrow backwards baggage briefcase business clock date job refresh renew reverse rotate schedule suitcase time turn',
    WorkOff:
        '\u0411\u0430\u0433\u0430\u0436 \u043f\u043e\u0440\u0442\u0444\u0435\u043b\u044c \u0431\u0438\u0437\u043d\u0435\u0441 \u0438\u043d\u0432\u0430\u043b\u0438\u0434 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u043e \u0440\u0430\u0431\u043e\u0442\u0430 \u043d\u0430 \u043a\u043e\u0441\u0430\u044f \u0447\u0435\u0440\u0442\u0430 \u0447\u0435\u043c\u043e\u0434\u0430\u043d baggage briefcase business disabled enabled job on slash suitcase',
    WorkOutline:
        '\u0411\u0430\u0433\u0430\u0436 \u043f\u043e\u0440\u0442\u0444\u0435\u043b\u044c \u0431\u0438\u0437\u043d\u0435\u0441 \u0440\u0430\u0431\u043e\u0442\u0430 \u0447\u0435\u043c\u043e\u0434\u0430\u043d baggage briefcase business job suitcase',
    WorkspacePremium:
        '\u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043a\u0430\u0446\u0438\u044f \u0441\u0442\u0435\u043f\u0435\u043d\u044c \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u043a\u043e\u043c\u043c\u0435\u0440\u0446\u0438\u044f \u0433\u0430\u0440\u0430\u043d\u0442\u0438\u044f \u043c\u0435\u0434\u0430\u043b\u044c \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u0442\u044c \u043b\u0435\u043d\u0442\u0430 \u043f\u0440\u043e\u0432\u0435\u0440\u043a\u0430 certification degree ecommerce guarantee medal permit ribbon verification',
    Workspaces:
        '\u043a\u0440\u0443\u0433\u0438 \u0441\u043e\u0442\u0440\u0443\u0434\u043d\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0442\u043e\u0447\u043a\u0430 \u0437\u0430\u043f\u043e\u043b\u043d\u0435\u043d\u043d\u044b\u0439 \u0433\u0440\u0443\u043f\u043f\u0430 \u043a\u043e\u043d\u0442\u0443\u0440 \u043a\u043e\u043c\u0430\u043d\u0434\u0430 circles collaboration dot filled group outline team',
    WrapText:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442 \u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0440\u0435\u0434\u0430\u043a\u0442\u043e\u0440 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u044f \u0442\u0430\u0431\u043b\u0438\u0446\u0430 \u0442\u0438\u043f \u0437\u0430\u043f\u0438\u0441\u044b\u0432\u0430\u0442\u044c \u043f\u0438\u0448\u0443 arrow doc editing editor spreadsheet type write writing',
    WrongLocation:
        '\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u0417\u0430\u043a\u0440\u044b\u0442\u044c \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u043d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0432\u044b\u0445\u043e\u0434 \u043a\u0430\u0440\u0442\u044b \u043d\u0435\u0442 \u0448\u0442\u044b\u0440\u044c \u043c\u0435\u0441\u0442\u043e \u043f\u043e\u043a\u0438\u0434\u0430\u0442\u044c \u0443\u0434\u0430\u043b\u044f\u0442\u044c \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 cancel close destination direction exit maps no pin place quit remove stop',
    Wysiwyg:
        '\u043a\u043e\u043c\u043f\u043e\u0437\u0438\u0442\u043e\u0440 \u0420\u0435\u0436\u0438\u043c \u044d\u043a\u0440\u0430\u043d \u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u043d\u043e\u0433\u043e \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0435\u043d\u0438\u044f \u0441\u0438\u0441\u0442\u0435\u043c\u0430 \u0442\u0435\u043a\u0441\u0442 \u041f\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0432\u0438\u0434\u0438\u043c\u043e\u0441\u0442\u044c \u0412\u0435\u0431-\u0441\u0430\u0439\u0442 \u043e\u043a\u043d\u043e composer mode screen software system text view visibility website window',
    Yard: '\u0437\u0430\u0434\u043d\u0438\u0439 \u0434\u0432\u043e\u0440 \u0446\u0432\u0435\u0442\u043e\u043a \u0441\u0430\u0434 \u0434\u043e\u043c \u0434\u043e\u043c \u043f\u0440\u0438\u0440\u043e\u0434\u0430 \u043c\u0435\u043b\u043e\u0447\u044c \u0440\u0430\u0441\u0442\u0435\u043d\u0438\u044f backyard flower garden home house nature pettle plants',
    YouTube:
        '\u043c\u0430\u0440\u043a\u0430 \u043b\u043e\u0433\u043e\u0442\u0438\u043f \u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0435 \u0432\u0438\u0434\u0435\u043e brand logo social video',
    YoutubeSearchedFor:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0430 \u043d\u0430\u0437\u0430\u0434 \u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0438\u0441\u0442\u043e\u0440\u0438\u044f \u0432 \u0445\u043e\u0434\u0435 \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u0438\u044f \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0437\u043e\u0431\u043d\u043e\u0432\u0438\u0442\u044c \u0432\u043e\u0441\u0441\u0442\u0430\u043d\u043e\u0432\u0438\u0442\u044c \u0437\u0430\u0434\u043d\u0438\u0439 \u0445\u043e\u0434 \u0432\u0440\u0430\u0449\u0430\u0442\u044c \u0432\u0438\u0434\u0435\u0442\u044c \u0439\u0442 arrow backwards find glass history inprogress loading look magnifying refresh renew restore reverse rotate see yt',
    ZoomIn: '\u0431\u043e\u043b\u044c\u0448\u0435 \u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0440\u0430\u0441\u0442\u0438 \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u043b\u0443\u043f\u0430 \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u043f\u043b\u044e\u0441 \u0448\u043a\u0430\u043b\u0430 \u043f\u043e\u0438\u0441\u043a \u0432\u0438\u0434\u0435\u0442\u044c \u0440\u0430\u0437\u043c\u0435\u0440 bigger find glass grow look magnifier magnifying plus scale search see size',
    ZoomInMap:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0448\u0430\u0433 \u043c\u0435\u0441\u0442\u043e \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 arrows destination location maps move place stop',
    ZoomOut:
        '\u043d\u0430\u0439\u0442\u0438 \u0441\u0442\u0430\u043a\u0430\u043d \u0421\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u043b\u0443\u043f\u0430 \u0443\u0432\u0435\u043b\u0438\u0447\u0435\u043d\u0438\u0435 \u043c\u0438\u043d\u0443\u0441 \u043e\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u0448\u043a\u0430\u043b\u0430 \u043f\u043e\u0438\u0441\u043a \u0432\u0438\u0434\u0435\u0442\u044c \u0440\u0430\u0437\u043c\u0435\u0440 \u043c\u0435\u043d\u044c\u0448\u0435 find glass look magnifier magnifying minus negative scale search see size smaller',
    ZoomOutMap:
        '\u0441\u0442\u0440\u0435\u043b\u043a\u0438 \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0438\u044f \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043a\u0430\u0440\u0442\u044b \u0448\u0430\u0433 \u043c\u0435\u0441\u0442\u043e \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 arrows destination location maps move place stop',
};
