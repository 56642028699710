import { useEffect } from 'react';

import { useStaff } from 'hooks/api/staff/useStaffData';

import * as Style from './Staff.styled';
import { Filters } from './StaffFilters/index';
import { StaffList } from './StaffList/index';

export const StaffPage = (): JSX.Element => {
    const { users, getUsers } = useStaff();

    useEffect(() => {
        getUsers();
    }, [users, getUsers]);

    return (
        <Style.EmployeesPageWrapper>
            <Style.EmployeesPageContent>
                <Filters />
                <StaffList />
            </Style.EmployeesPageContent>
        </Style.EmployeesPageWrapper>
    );
};
